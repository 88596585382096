// DealCountSinceLastActivityBarCard.js
import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import BarCard from "../BarCard";
import { CHART_COLORS } from "../../constants";
import { useDealCountSinceLastActivity } from "../../../hooks/useDealCountSinceLastActivity";
import { PipelineSelect } from "../../../Components/PipelineSelect";

/**
 * DealCountSinceLastActivityBarCard Component
 * Displays deal count data based on last activity in a BarCard.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function DealCountSinceLastActivityBarCard({
  accountID,
  toast,
  selectedYear,
  selectedQuarter,
  selectedSegment,
}) {
  const { data, loading, error, selectedPipelineId, setSelectedPipelineId } =
    useDealCountSinceLastActivity(
      accountID,
      selectedYear,
      selectedQuarter,
      selectedSegment,
      toast
    );

  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Text>Loading deal statistics...</Text>
      </Box>
    );
  }

  // Create the controls component with pipeline selection
  const controls = (
    <Box width="200px">
      <PipelineSelect
        accountId={accountID}
        selectedPipelineId={selectedPipelineId}
        onPipelineChange={setSelectedPipelineId}
        onStageChange={() => {
          /* intentionally left blank */
        }}
        isRequired={false}
        hideStageSelect={true}
      />
    </Box>
  );

  // Add colors to the datasets
  const barData =
    data && data.datasets
      ? {
          ...data,
          datasets: data.datasets.map((dataset) => ({
            ...dataset,
            backgroundColor: CHART_COLORS.slice(0, data.labels?.length || 0),
          })),
        }
      : null;

  return (
    <Box pb={4} w="100%">
      <Flex justifyContent="flex-end" mb={4}>
        {controls}
      </Flex>
      {error || !data || !data.datasets ? (
        <Box textAlign="center" py={10}>
          <Text color="red.500">
            {error
              ? "Failed to load deal statistics."
              : "No deal data available."}
          </Text>
        </Box>
      ) : (
        <BarCard
          barData={barData}
          showLabels={false}
          useCurrencyFormat={false}
        />
      )}
    </Box>
  );
}
