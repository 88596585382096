import { useState } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook for extracting business information from a website URL
 * @returns {Object} Functions and state for business information extraction
 */
export const useBusinessExtraction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessToken, account_ID } = useAuthData();

  /**
   * Extract business information from a website URL
   * @param {string} websiteUrl - The website URL to extract information from
   * @param {string} accountId - Optional account ID, defaults to the authenticated user's account
   * @returns {Promise<Object>} Business information extraction result
   */
  const extractBusinessInformation = async (websiteUrl, accountId = null) => {
    if (!websiteUrl?.trim()) {
      setError("Website URL is required");
      return null;
    }

    // Use provided accountId or fall back to authenticated user's account
    const effectiveAccountId = accountId || account_ID;
    if (!effectiveAccountId) {
      setError("Account ID is required");
      return null;
    }

    // Sanitize and validate the URL
    let sanitizedUrl = websiteUrl.trim();

    // Add https:// if no protocol is specified
    if (!/^https?:\/\//i.test(sanitizedUrl)) {
      sanitizedUrl = `https://${sanitizedUrl}`;
    }

    try {
      // Validate the URL
      new URL(sanitizedUrl);
    } catch (e) {
      setError("Invalid URL format");
      return null;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Get access token from auth context
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.post(
        `${target}/api/accounts/${effectiveAccountId}/extract-business-information`,
        {
          account_id: parseInt(effectiveAccountId, 10),
          url: sanitizedUrl,
          timeout: 30, // Add reasonable timeout in seconds
          headers: {}, // Optional, can be empty
          additional_context: {}, // Optional, can be empty
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Check if extraction was successful
      if (
        response.data &&
        response.data.status === "success" &&
        response.data.data
      ) {
        return response.data.data;
      } else {
        throw new Error(
          response.data?.error || "Failed to extract business information"
        );
      }
    } catch (err) {
      console.error("Error extracting business information:", err);
      setError(
        err.response?.data?.error ||
          err.message ||
          "An error occurred during extraction"
      );
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Generate basic business information from a URL when the API fails
   * @param {string} websiteUrl - The website URL to generate information from
   * @returns {Object} Generated business information
   */
  const generateFallbackBusinessInfo = (websiteUrl) => {
    if (!websiteUrl) return null;

    try {
      // Generate basic information from the URL
      const url = new URL(
        websiteUrl.startsWith("http") ? websiteUrl : `https://${websiteUrl}`
      );
      const domain = url.hostname.replace(/^www\./i, "");
      const companyName =
        domain.split(".")[0].charAt(0).toUpperCase() +
        domain.split(".")[0].slice(1);

      return {
        offering: `${companyName}'s products and services`,
        customer_definition: `Customers interested in ${companyName}'s offerings`,
        business_description: `${companyName} is a company that provides solutions through ${domain}`,
        target_location: "Global",
        company_size: "All sizes",
      };
    } catch (e) {
      console.error("Error generating fallback business info:", e);
      return {
        offering: "Products and services",
        customer_definition: "Potential customers",
        business_description: "Business offering solutions",
        target_location: "Global",
        company_size: "All sizes",
      };
    }
  };

  return {
    extractBusinessInformation,
    generateFallbackBusinessInfo,
    isLoading,
    error,
  };
};
