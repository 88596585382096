// contexts/SidebarContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuthData } from "../auth-context";
import { useFetchVisibleRoutes } from "../views/lysto/hooks/useFetchVisibleRoutes";
import axios from "axios";

const STORAGE_KEY = "lysto-sidebar-expanded";

export const SidebarContext = createContext();

const getSavedState = () => {
  try {
    const savedState = localStorage.getItem(STORAGE_KEY);
    // If no saved state, default to expanded
    return savedState !== null ? JSON.parse(savedState) : true;
  } catch (error) {
    console.error("Error reading sidebar state from localStorage:", error);
    return true;
  }
};

const saveState = (state) => {
  try {
    // Only save state if it's different from what's already saved
    const currentSaved = localStorage.getItem(STORAGE_KEY);
    if (currentSaved === null || JSON.parse(currentSaved) !== state) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }
  } catch (error) {
    console.error("Error saving sidebar state to localStorage:", error);
  }
};

export const SidebarProvider = ({ children }) => {
  const { account_ID: accountID, getAccessToken } = useAuthData();
  const { visibleRoutes, loading, error, fetchVisibleRoutes } =
    useFetchVisibleRoutes();

  const [isExpanded, setIsExpanded] = useState(() => {
    return getSavedState();
  });

  const handleSetIsExpanded = (newState) => {
    const value =
      typeof newState === "function" ? newState(isExpanded) : newState;
    setIsExpanded(value);
    saveState(value);
  };

  // Function to toggle route visibility
  const toggleRouteVisibility = async (routeName) => {
    if (!accountID) return;

    try {
      // Update locally for immediate UI feedback
      const updatedVisibleRoutes = {
        ...visibleRoutes,
        [routeName]: !visibleRoutes[routeName],
      };

      // Ensure Settings is always visible or at least one route is visible
      if (routeName === "Settings" && !updatedVisibleRoutes.Settings) {
        // If trying to hide Settings, check if it's the only visible route
        const otherVisibleRoutes = Object.entries(updatedVisibleRoutes).filter(
          ([name, isVisible]) => name !== "Settings" && isVisible
        ).length;

        if (otherVisibleRoutes === 0) {
          console.warn(
            "Cannot hide Settings when it is the only visible route"
          );
          return; // Prevent hiding Settings when it's the only visible route
        }
      }

      // Send update to backend (implement this according to your API)
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
          : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.put(
        `${target}/api/accounts/${accountID}/admin/visible-routes/${routeName}`,
        { visible: !visibleRoutes[routeName] },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Refresh visible routes from server
      fetchVisibleRoutes();
    } catch (err) {
      console.error("Error toggling route visibility:", err);
    }
  };

  // Initialize visibleRoutes by fetching from the backend
  useEffect(() => {
    if (accountID) {
      fetchVisibleRoutes();
    }
  }, [accountID]);

  return (
    <SidebarContext.Provider
      value={{
        visibleRoutes,
        loading,
        error,
        isExpanded,
        setIsExpanded: handleSetIsExpanded,
        toggleRouteVisibility,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};
