import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Heading, Text, VStack, Spinner } from "@chakra-ui/react";

const AuthCallback = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [error, setError] = useState(null);
  const [urlCleaned, setUrlCleaned] = useState(false);
  const navigate = useNavigate();
  const { search, hash } = useLocation();

  useEffect(() => {
    // Check for code and state in both the search params and the hash
    const params = new URLSearchParams(search);
    const hashParams = new URLSearchParams(hash.replace("#/", ""));

    const errorParam = params.get("error") || hashParams.get("error");
    const errorDescription =
      params.get("error_description") || hashParams.get("error_description");
    const hasAuthCallback =
      params.get("code") ||
      params.get("state") ||
      hashParams.get("code") ||
      hashParams.get("state");

    // Handle organization membership error
    if (
      errorParam &&
      errorDescription?.includes("client requires organization membership")
    ) {
      setError(
        "Your account is not part of any organization. Please contact support or your admin."
      );
      return;
    }

    // If we're in the middle of processing a callback, don't trigger a new login
    if (hasAuthCallback) {
      // If code and state are in the URL before the hash, we need to handle this special case
      if (
        (params.get("code") || params.get("state")) &&
        hash.includes("/callback") &&
        !urlCleaned
      ) {
        // Clean up URL parameters and move to the proper route
        const newUrl = window.location.origin + window.location.pathname + hash;
        setUrlCleaned(true);

        // Use window.location.replace to force a full page reload with the cleaned URL
        // This ensures Auth0 processes the authentication properly
        window.location.replace(newUrl);
        return;
      }

      // If we're still processing the callback but URL is already cleaned, just wait
      return;
    }

    // Only proceed with navigation if we're not in the middle of cleaning up the URL
    if (!isLoading && !urlCleaned) {
      if (isAuthenticated) {
        // Clean up URL parameters
        window.history.replaceState({}, "", window.location.pathname);
        // Redirect to admin
        navigate("/admin");
      } else {
        loginWithRedirect();
      }
    }
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    navigate,
    search,
    hash,
    urlCleaned,
  ]);

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        backgroundColor="#f2f2f2"
        padding="20px"
      >
        <VStack spacing={4}>
          <Heading as="h2" size="lg">
            Login Issue
          </Heading>
          <Text fontSize="lg">{error}</Text>
          <Text fontSize="md">
            Please try logging in again or contact support if the issue
            persists.
          </Text>
        </VStack>
      </Box>
    );
  }

  if (isLoading || urlCleaned) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <VStack spacing={4}>
          <Spinner size="xl" />
          <Text fontSize="lg">Processing authentication...</Text>
        </VStack>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Text fontSize="lg">Redirecting to login...</Text>
    </Box>
  );
};

export default AuthCallback;
