import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { Video, Link as LinkIcon, ExternalLink } from "lucide-react";
import BaseTag from "../Shared/BaseTag";

const ExternalLinksSection = ({ meetingLink, recordingUrl }) => {
  // Determine which meeting platform is being used
  const isMeetingZoom = meetingLink?.includes("zoom.us");
  
  // If there's no meeting link or recording URL, don't render the section
  if (!meetingLink && !recordingUrl) {
    return null;
  }
  
  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" color="gray.700" mb={4}>
        External Links
      </Text>
      <Flex gap={4} flexWrap="wrap">
        {/* Show meeting link if available */}
        {meetingLink && (
          <a
            href={meetingLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
            role="button"
          >
            <BaseTag
              icon={Video}
              hoverIcon={ExternalLink}
              colorScheme={isMeetingZoom ? "purple" : "teal"}
              tooltip="Open Meeting Link"
            >
              <Text fontSize="sm" fontWeight="medium">
                {isMeetingZoom ? "Zoom" : "Google Meet"}
              </Text>
            </BaseTag>
          </a>
        )}
        {recordingUrl && (
          <a
            href={recordingUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
            role="button"
          >
            <BaseTag
              icon={LinkIcon}
              hoverIcon={ExternalLink}
              colorScheme="purple"
              tooltip="View Recording"
            >
              <Text fontSize="sm" fontWeight="medium">
                Recording
              </Text>
            </BaseTag>
          </a>
        )}
      </Flex>
    </Box>
  );
};

export default ExternalLinksSection;
