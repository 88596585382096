import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import {
  Box,
  VStack,
  useToast,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Alert,
  AlertIcon,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Select,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ReactComponent as LystoLogo } from "assets/img/lysto-logo-round.svg";
import OverviewSection from "./OverviewSection";
import EmailDetailsSection from "./EmailDetailsSection";
import MessageInput from "./MessageInput";
import AnnotationModal from "./AnnotationModal";
import ReferenceActivitySection from "./ReferenceActivitySection";
import DealActivity from "../Deals/DealActivity";
import { FaPaperPlane, FaMagic, FaPencilAlt } from "react-icons/fa";
import { useQueryClient } from '@tanstack/react-query';
import { useAuthData } from "../../../auth-context";
import axios from "axios";

// Template options for email generation - matching EmailComposeDetail.jsx
const TEMPLATE_OPTIONS = [
  { value: "general", label: "General Message" },
  { value: "introduction", label: "Introduction" },
  { value: "follow_up", label: "Email Follow-up" },
  { value: "meeting_follow_up", label: "Meeting Follow-up" },
  { value: "pricing", label: "Pricing Information" },
  { value: "proposal", label: "Proposal" },
  { value: "meeting_request", label: "Meeting Request" },
  { value: "thank_you", label: "Thank You" },
];

// Helper function to format date for display
const formatDate = (dateString) => {
  if (!dateString) return "Unknown date";
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const MotionBox = motion(Box);

// Cache to store user names to avoid redundant API calls
const userNameCache = new Map();

// Custom hook to fetch user name by ID
const useUserName = (userId, accountId) => {
  const [userName, setUserName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const userData = useAuthData();

  useEffect(() => {
    // If no userId provided, don't fetch
    if (!userId) return;
    
    // Special case for user ID 6 (Kyle) during feature development
    if (String(userId) === "6") {
      setUserName("Kyle Archie");
      return;
    }
    
    // If it's the current user, use their name
    if (userData && String(userId) === String(userData.user_ID)) {
      setUserName(userData.name);
      return;
    }

    // Check if we have the username in cache
    const cacheKey = `${accountId}-${userId}`;
    if (userNameCache.has(cacheKey)) {
      setUserName(userNameCache.get(cacheKey));
      return;
    }

    const fetchUserName = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // Use the target from env if available, otherwise use the default URL
        const target = process.env.REACT_APP_API_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
        
        // Get access token
        const accessToken = await userData.getAccessToken();
        
        // Try two approaches for fetching user data
        
        // Approach 1: First try to get all users and find the matching one
        const response = await axios.get(
          `${target}/api/accounts/${accountId}/get-users`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        
        // Find the user with matching ID - try multiple approaches
        let user = null;
        
        if (response.data && Array.isArray(response.data)) {
          // Try direct ID match
          user = response.data.find(u => String(u.ID) === String(userId));
          
          // If not found, try with user_id field
          if (!user) {
            user = response.data.find(u => u.user_id && String(u.user_id) === String(userId));
          }
          
          // Try case-insensitive as a last resort
          if (!user) {
            user = response.data.find(u => 
              String(u.ID).toLowerCase() === String(userId).toLowerCase() || 
              (u.user_id && String(u.user_id).toLowerCase() === String(userId).toLowerCase())
            );
          }
        }
        
        // Approach 2: If user not found through the list, try a direct user lookup if available
        if (!user) {
          try {
            // Note: This endpoint might not exist in your API. If it doesn't, this will catch and continue
            const userResponse = await axios.get(
              `${target}/api/accounts/${accountId}/users/${userId}`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            
            if (userResponse.data) {
              user = userResponse.data;
            }
          } catch (userErr) {
            // Continue with list results, don't throw
          }
        }
        
        let name;
        if (user) {
          // Try all possible name fields
          name = user.Name || user.name || user.email || user.Email || `User ${userId}`;
        } else {
          name = `User ${userId}`;
        }
        
        // Update state and cache
        setUserName(name);
        userNameCache.set(cacheKey, name);
      } catch (err) {
        setError(err);
        
        const fallbackName = `User ${userId}`;
        setUserName(fallbackName); // Fallback to showing the ID
        userNameCache.set(cacheKey, fallbackName); // Cache the fallback name too
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchUserName();
  }, [userId, accountId, userData]);

  return { userName, isLoading, error };
};

const LoadingOverlay = ({ status, willCreateNewThread }) => {
  if (status === "sending") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          animate={{
            scale: [1, 1.1, 1],
            opacity: [1, 0.7, 1]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            filter="drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium">Sending your email...</Text>
      </Center>
    );
  }

  if (status === "success") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", duration: 0.5 }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            filter="drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium">Email sent successfully!</Text>
        {willCreateNewThread ? (
          <Text fontSize="sm" mt={2}>Created as a new conversation thread</Text>
        ) : (
          <Text fontSize="sm" mt={2}>Redirecting to inbox...</Text>
        )}
      </Center>
    );
  }

  if (status === "error") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          animate={{ 
            rotate: [-5, 5, -5, 0],
          }}
          transition={{ 
            duration: 0.5,
            ease: "easeInOut",
          }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            opacity={0.7}
            filter="drop-shadow(0 0 8px rgba(255, 0, 0, 0.3))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium" color="red.200">Failed to send email</Text>
      </Center>
    );
  }

  return null;
};

const EmailFollowupDetail = ({
  item,
  onSendMessage,
  onAddAnnotation,
  onGoBack,
  onGenerateEmail,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const userData = useAuthData();
  // Add a ref to track if we've received content from generation
  const generatedContentRef = React.useRef(false);
  // Add a new ref to track the current content for timeout handling
  const appliedContentRef = useRef('');

  // Initialize state with content and recipients from the item
  const [message, setMessage] = useState(item?.content || "");
  const [emailSubject, setEmailSubject] = useState(item?.subject || "");
  const [originalSubject, setOriginalSubject] = useState(item?.subject || "");
  const [emailRecipients, setEmailRecipients] = useState(item?.email_recipients || []);
  const [selectedSender, setSelectedSender] = useState(null);
  const [guidingPrompt, setGuidingPrompt] = useState("");
  // Add template selection - initialize based on item.task_type (follow-up or reply)
  const [selectedTemplate, setSelectedTemplate] = useState(
    item?.task_type === "follow-up" ? "follow_up" : "general"
  );
  const [isAnnotationModalOpen, setIsAnnotationModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isArchiving] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [sendStatus, setSendStatus] = useState(""); // 'sending', 'success', or 'error'
  const [hasGeneratedEmail, setHasGeneratedEmail] = useState(false);
  const [willCreateNewThread, setWillCreateNewThread] = useState(false);
  const [isSendConfirmOpen, setIsSendConfirmOpen] = useState(false);
  // Add a new state to track reasons for creating a new thread
  const [newThreadReasons, setNewThreadReasons] = useState([]);
  const [hasMeetingActivity, setHasMeetingActivity] = useState(false);
  const [recentActivities, setRecentActivities] = useState([]);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [selectedReferenceActivity, setSelectedReferenceActivity] = useState(null);
  const [quillKey, setQuillKey] = useState(Date.now());

  // Get the original sender email from the conversation history
  const originalSenderEmail = item.sender_email || '';
  
  // Get user_id from the item, which may be used for checking original sender
  const userId = item.user_id;

  // Prefetch activities data
  useEffect(() => {
    if (item?.account_id && item?.deal_id) {
      queryClient.prefetchQuery({
        queryKey: ['dealActivities', item.account_id, item.deal_id],
        queryFn: () => null,
        staleTime: 0
      });
    }
  }, [item?.account_id, item?.deal_id, queryClient]);

  // Add a safe logging helper to avoid linter errors in production
  const debugLog = (message, data) => {
    // Only log in development environment or when using a debugger
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(`[EmailFollowupDetail] ${message}`, data);
    }
  };

  // Add a new helper function to find the most relevant activity based on content
  const findMostRelevantActivity = (activities, currentItem) => {
    if (!activities || activities.length === 0) {
      return null;
    }
    
    // Default to most recent (first in the sorted list)
    let bestMatch = activities[0];
    let bestScore = 0;
    
    // If current item has a subject, try to find a match
    if (currentItem.subject) {
      debugLog("Looking for relevant activity based on subject", { currentSubject: currentItem.subject });
      
      for (const activity of activities) {
        let score = 0;
        
        // Check subject similarity
        if (activity.subject && currentItem.subject) {
          const normalizedItemSubject = currentItem.subject.toLowerCase().replace(/^re:\s*/i, '').trim();
          const normalizedActivitySubject = activity.subject.toLowerCase().replace(/^re:\s*/i, '').trim();
          
          // Direct match after removing Re: (high score)
          if (normalizedActivitySubject === normalizedItemSubject) {
            score += 10;
          } 
          // Partial match (lower score)
          else if (normalizedActivitySubject.includes(normalizedItemSubject) ||
                   normalizedItemSubject.includes(normalizedActivitySubject)) {
            score += 5;
          }
        }
        
        // Additional scoring factors
        if (activity.email_recipients && currentItem.email_recipients) {
          // See if any recipients match
          const activityEmails = new Set(activity.email_recipients.map(r => r.email.toLowerCase()));
          const itemEmails = new Set(currentItem.email_recipients.map(r => r.email.toLowerCase()));
          
          // Count email matches
          let matchCount = 0;
          for (const email of itemEmails) {
            if (activityEmails.has(email)) {
              matchCount++;
            }
          }
          
          // Add score based on percentage of matching emails
          if (matchCount > 0) {
            score += 3 * (matchCount / Math.max(activityEmails.size, itemEmails.size));
          }
        }
        
        // Update best match if this activity has a higher score
        if (score > bestScore) {
          bestScore = score;
          bestMatch = activity;
          
          debugLog("Found better activity match", { 
            score, 
            activityId: activity.id,
            subject: activity.subject 
          });
        }
      }
    }
    
    return bestMatch;
  };

  // Add a new helper function to fetch activities directly from the API
  const fetchActivitiesFromApi = async (accountId, dealId, accessToken) => {
    debugLog("Attempting to fetch activities directly from API", { accountId, dealId });
    
    if (!accountId || !dealId || !accessToken) {
      debugLog("Missing required data for API fetch", { accountId, dealId, hasToken: !!accessToken });
      return [];
    }
    
    try {
      // Use the target from env if available, otherwise use the default URL
      const target = process.env.REACT_APP_API_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      
      // Fetch activities from 90 days ago to now
      const startDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
      const endDate = new Date().toISOString().split('T')[0];
      
      debugLog("Fetching activities with date range", { startDate, endDate });
      
      const response = await axios.get(
        `${target}/api/accounts/${accountId}/deals/${dealId}/activities?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      if (response.data && Array.isArray(response.data)) {
        debugLog("Successfully fetched activities from API", { count: response.data.length });
        return response.data;
      } else {
        debugLog("API returned non-array data", response.data);
        return [];
      }
      
    } catch (error) {
      debugLog("Error fetching activities from API", error.message || error);
      // Just return empty array on error - don't throw
      return [];
    }
  };

  // Update fetchRecentEmailActivities to use the API fetch function
  useEffect(() => {
    const fetchRecentEmailActivities = async () => {
      if (!item?.deal_id || !item?.account_id) return;
      
      setIsLoadingActivities(true);
      let foundMeetingActivity = false; // Flag to track if a meeting activity is found

      // Add logging to see what we're working with
      debugLog("Fetch activities - item data:", { 
        deal_id: item.deal_id, 
        account_id: item.account_id, 
        hasActivitiesProp: !!item.activities,
        activitiesCount: item.activities?.length || 0,
        hasDealsProperty: !!item.deal,
        hasDealsActivities: !!item.deal?.activities,
        hasGeneratedEmail
      });
      
      try {
        // First check if we need to fetch activities from the deal object
        let dealActivities = [];

        // Check if item has activities
        if (item.activities && Array.isArray(item.activities) && item.activities.length > 0) {
          dealActivities = item.activities;
          debugLog("Found activities in item:", dealActivities.length);
        }
        // Check if we have activities in the item.deal object
        else if (item.deal?.activities && Array.isArray(item.deal.activities)) {
          dealActivities = item.deal.activities;
          debugLog("Found activities in item.deal:", dealActivities.length);
        }
        // If no activities found, try to fetch from API
        else {
          debugLog("No activities found in item or item.deal, attempting API fetch", null);
          
          // Try to get userData and accessToken
          if (userData && userData.getAccessToken) {
            try {
              const accessToken = await userData.getAccessToken();
              dealActivities = await fetchActivitiesFromApi(item.account_id, item.deal_id, accessToken);
              
              debugLog("Fetched activities from API:", { count: dealActivities.length });
            } catch (tokenError) {
              debugLog("Failed to get access token for activities fetch", tokenError.message || tokenError);
            }
          } else {
            debugLog("Cannot fetch activities - no user data or token function", null);
          }
        }
        
        // First, check for any meeting activity
        foundMeetingActivity = dealActivities.some(
          activity => activity.type?.toLowerCase() === 'meeting' || activity.activity_type?.toLowerCase() === 'meeting'
        );

        debugLog("Found meeting activity:", foundMeetingActivity);

        // Filter for email activities - look for multiple indicators that this is an email
        const emailActivities = dealActivities
          .filter(activity => {
            // Check different properties that might indicate this is an email
            const isEmailType = activity.activity_type?.toLowerCase() === 'email' || 
              activity.type?.toLowerCase() === 'email';
            
            // Check if activity has email data
            const hasEmailData = activity.activity_data?.data?.subject || 
              activity.activity_data?.data?.body ||
              activity.activity_data?.data?.recipients;
            
            // Check if activity has email-related properties
            const hasEmailProps = activity.subject || 
              activity.recipients || 
              (activity.to && activity.to.includes('@'));
            
            // Look at description for email indicators
            const hasEmailDesc = activity.description && 
              (activity.description.includes('Email:') || 
               activity.description.includes('Subject:') || 
               activity.description.includes('To:'));
            
            const isEmail = isEmailType || hasEmailData || hasEmailProps || hasEmailDesc;
            
            // Skip activities with a draft status
            const isDraft = activity.status === 'draft' || activity.is_draft === true;
            
            return isEmail && !isDraft;
          })
          .sort((a, b) => {
            // Sort by date descending
            const dateA = new Date(a.created_at || a.date || a.activity_date || 0);
            const dateB = new Date(b.created_at || b.date || b.activity_date || 0);
            return dateB - dateA;
          });
        
        // Add an activity index for display purposes and ensure activity_id is set
        emailActivities.forEach((activity, index) => {
          activity.displayIndex = index + 1;
          // Make sure each activity has an id and activity_id for selection tracking
          if (!activity.activity_id && activity.id) {
            activity.activity_id = activity.id;
          } else if (!activity.id && activity.activity_id) {
            activity.id = activity.activity_id;
          } else if (!activity.id && !activity.activity_id) {
            // Generate a temporary id if none exists
            activity.id = `temp-id-${index}`;
            activity.activity_id = activity.id;
          }
          
          // Ensure subject is defined
          if (!activity.subject && activity.activity_data?.data?.subject) {
            activity.subject = activity.activity_data.data.subject;
          }
        });
        
        debugLog("Filtered email activities:", {
          count: emailActivities.length,
          first: emailActivities.length > 0 ? {
            type: emailActivities[0].activity_type || emailActivities[0].type,
            subject: emailActivities[0].subject,
            id: emailActivities[0].activity_id || emailActivities[0].id,
            date: emailActivities[0].created_at || emailActivities[0].date
          } : 'none'
        });
        
        // Create a mock activity from the current inbox item
        // We'll always need this, whether we have activities or not
        const currentItemActivity = {
          activity_id: item.activity_id || item.id,
          id: item.id,
          activity_type: "email",
          type: "email",
          subject: item.subject,
          created_at: item.created_at || item.date,
          displayIndex: 0, // Mark as the current one
          email_recipients: item.email_recipients,
          body: item.content || "No content"
        };
        
        // Only add current item if it's not already in the list
        const currentItemInList = emailActivities.some(a => 
          a.id === currentItemActivity.id || a.activity_id === currentItemActivity.activity_id
        );
        
        // If the current item is not in the list, add it as the first item
        const updatedActivities = currentItemInList 
          ? emailActivities 
          : [currentItemActivity, ...emailActivities];
        
        debugLog("Final activities list:", { 
          initialCount: emailActivities.length,
          finalCount: updatedActivities.length,
          addedCurrentItem: !currentItemInList
        });
        
        setRecentActivities(updatedActivities);
        setHasMeetingActivity(foundMeetingActivity);
        
        // Only set a reference activity if none is currently selected or if we're starting fresh
        if (!selectedReferenceActivity || (updatedActivities.length > 0 && !hasGeneratedEmail)) {
          if (updatedActivities.length > 0) {
            // Default to the current item if available, otherwise find most relevant
            const relevantActivity = updatedActivities[0].displayIndex === 0 
              ? updatedActivities[0] 
              : findMostRelevantActivity(updatedActivities, item);
            
            debugLog("Selected relevant activity as reference:", {
              subject: relevantActivity.subject,
              date: relevantActivity.created_at || relevantActivity.date,
              activity_id: relevantActivity.activity_id || relevantActivity.id,
              isSelected: !!selectedReferenceActivity,
              isCurrentItem: relevantActivity.displayIndex === 0
            });
            
            setSelectedReferenceActivity(relevantActivity);
            
            // Apply data from this activity
            applyDataFromActivity(relevantActivity);
          } else {
            // Fallback case - should never happen now that we always add the current item
            debugLog("Fallback: Creating reference activity from item", {
              subject: item.subject,
              id: item.id,
              recipientsCount: item.email_recipients?.length || 0
            });
            
            setRecentActivities([currentItemActivity]);
            setSelectedReferenceActivity(currentItemActivity);
          }
        } else {
          debugLog("Keeping existing reference activity", {
            id: selectedReferenceActivity.id,
            subject: selectedReferenceActivity.subject
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error in fetchRecentEmailActivities:", error);
        // Display error message to user
        toast({
          title: "Error Loading Activities",
          description: "There was a problem retrieving email history",
          status: "error",  
          duration: 5000,
          isClosable: true,
        });
        setRecentActivities([]);
      } finally {
        setIsLoadingActivities(false);
      }
    };
    
    fetchRecentEmailActivities();
  }, [item, hasGeneratedEmail, toast, userData]);

  // Effect to determine if a new thread should be created
  useEffect(() => {
    if (!selectedReferenceActivity) {
      setWillCreateNewThread(true); // No reference = new thread
    } else if (selectedReferenceActivity.activity_type?.toLowerCase() !== 'email') {
      setWillCreateNewThread(true); // Referencing non-email = new thread
    } else {
      // Referencing an email - default to replying in same thread
      setWillCreateNewThread(false); 
    }
  }, [selectedReferenceActivity]); // Depend on the prop passed from parent

  // Function to apply data from a selected activity
  const applyDataFromActivity = (activity) => {
    if (!activity) {
      debugLog("applyDataFromActivity: No activity provided", null);
      return;
    }
    
    debugLog("applyDataFromActivity: Applying data from activity:", {
      type: activity.activity_type || activity.type,
      subject: activity.subject,
      hasRecipients: !!activity.recipients,
      recipientsCount: activity.recipients?.length || 0,
      hasEmailData: !!activity.activity_data?.data,
      id: activity.activity_id || activity.id
    });
    
    // Extract subject from the activity (check multiple potential sources)
    let extractedSubject = null;
    
    // Check activity_data.data first (most structured)
    if (activity.activity_data?.data?.subject) {
      extractedSubject = activity.activity_data.data.subject;
      debugLog("Subject found in activity_data.data:", extractedSubject);
    } 
    // Then check direct subject property
    else if (activity.subject) {
      extractedSubject = activity.subject;
      debugLog("Subject found in direct property:", extractedSubject);
    }
    // Try to extract from description if present
    else if (activity.description) {
      const subjectMatch = activity.description.match(/Subject:\s*([^\n]+)/i);
      if (subjectMatch && subjectMatch[1]) {
        extractedSubject = subjectMatch[1].trim();
        debugLog("Subject extracted from description:", extractedSubject);
      }
    }
    
    // Apply subject if found
    if (extractedSubject) {
      const reSubject = extractedSubject.startsWith('Re:') 
        ? extractedSubject 
        : `Re: ${extractedSubject}`;
      debugLog("Setting email subject to:", reSubject);
      setEmailSubject(reSubject);
      setOriginalSubject(reSubject);
    } else {
      debugLog("No subject found in activity", null);
    }
    
    // Extract recipients from the activity (check multiple potential sources)
    let extractedRecipients = [];
    
    // Check activity_data.data.recipients first (most structured)
    if (activity.activity_data?.data?.recipients && Array.isArray(activity.activity_data.data.recipients)) {
      extractedRecipients = activity.activity_data.data.recipients.map(r => ({
        name: r.name || '',
        email: r.email,
        recipient_type: r.recipient_type || r.type || 'to',
      }));
      debugLog("Recipients found in activity_data.data:", extractedRecipients.length);
    } 
    // Then check direct recipients property
    else if (activity.recipients && Array.isArray(activity.recipients)) {
      extractedRecipients = activity.recipients.map(r => ({
        name: r.name || '',
        email: r.email,
        recipient_type: r.recipient_type || r.type || 'to',
      }));
      debugLog("Recipients found in direct property:", extractedRecipients.length);
    }
    // Then check email_recipients property (used by our data model)
    else if (activity.email_recipients && Array.isArray(activity.email_recipients)) {
      extractedRecipients = activity.email_recipients.map(r => ({
        name: r.name || '',
        email: r.email,
        recipient_type: r.recipient_type || r.type || 'to',
      }));
      debugLog("Recipients found in email_recipients property:", extractedRecipients.length);
    }
    // Check for to/cc/bcc properties
    else if (activity.to && activity.to.includes('@')) {
      extractedRecipients.push({
        name: activity.to_name || '',
        email: activity.to,
        recipient_type: 'to',
      });
      debugLog("Recipient found in 'to' property", null);
      
      // Add CC recipients if available
      if (activity.cc && activity.cc.includes('@')) {
        extractedRecipients.push({
          name: activity.cc_name || '',
          email: activity.cc,
          recipient_type: 'cc',
        });
        debugLog("Recipient found in 'cc' property", null);
      }
      
      // Add BCC recipients if available
      if (activity.bcc && activity.bcc.includes('@')) {
        extractedRecipients.push({
          name: activity.bcc_name || '',
          email: activity.bcc,
          recipient_type: 'bcc',
        });
        debugLog("Recipient found in 'bcc' property", null);
      }
    }
    
    // Apply recipients if we found any
    if (extractedRecipients.length > 0) {
      debugLog("Setting email recipients:", extractedRecipients.length);
      setEmailRecipients(extractedRecipients);
    } else {
      debugLog("No recipients found in activity", null);
      
      // If no recipients found in activity, but we have them in the item, use those
      if (item.email_recipients && item.email_recipients.length > 0) {
        debugLog("Using email recipients from item:", item.email_recipients.length);
        setEmailRecipients(item.email_recipients);
      }
    }
  };

  const handleSelectActivity = (activity) => {
    debugLog("handleSelectActivity called with:", {
      activityId: activity.activity_id || activity.id,
      type: activity.activity_type || activity.type,
      subject: activity.subject
    });

    // Make sure we're not re-selecting the same activity
    if (selectedReferenceActivity && 
        (selectedReferenceActivity.id === activity.id || 
         selectedReferenceActivity.activity_id === activity.activity_id)) {
      debugLog("Skipping selection - same activity already selected", null);
      return;
    }

    setSelectedReferenceActivity(activity);
    
    // Apply data from this activity - this will update subject and recipients
    applyDataFromActivity(activity);
    
    // Determine if selecting this activity will create a new thread
    const willCreate = activity.activity_type?.toLowerCase() !== 'email';
    setWillCreateNewThread(willCreate);
    
    debugLog("Activity selection complete", { 
      subject: activity.subject,
      willCreateNewThread: willCreate 
    });
    
    toast({
      title: "Activity Reference Changed",
      description: `Using context from ${activity.activity_type || 'activity'} from ${formatDate(activity.created_at || activity.date)}`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleClearActivity = () => {
    setSelectedReferenceActivity(null);
    
    // Explicitly set willCreateNewThread to true when clearing the reference
    setWillCreateNewThread(true);

    // Clear subject but keep any recipients that might have been added
    setEmailSubject("");
    
    toast({
      title: "Activity Reference Cleared",
      description: "No longer referencing a previous activity",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  // Handle navigation after successful send
  useEffect(() => {
    let timer;
    if (sendStatus) {
      setShowLoadingOverlay(true);
      if (sendStatus === 'success') {
        timer = setTimeout(() => {
          // Only navigate back if we're not creating a new thread
          if (!willCreateNewThread) {
            onGoBack();
          } else {
            // If creating a new thread, just hide the overlay but stay on the page
            setShowLoadingOverlay(false);
            // Reset send status after showing success
            setSendStatus("");
            toast({
              title: "New Thread Created",
              description: "Your email has been sent as a new conversation thread.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
        }, 1500);
      }
    } else {
      setShowLoadingOverlay(false);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [sendStatus, onGoBack, willCreateNewThread]);

  // Update state when item changes - with memoization
  const updateStateFromItem = useCallback(() => {
    if (item && !hasGeneratedEmail) {
      if (!(item.task_type === 'reply' && item.task_subtype === 'email')) {
        setMessage(item.content || "");
      } else {
        setMessage("");
      }
      setEmailSubject(item.subject || "");
      setOriginalSubject(item.subject || "");
      setEmailRecipients(item.email_recipients || []);
      setWillCreateNewThread(false); // Reset when item changes
    }
  }, [item, hasGeneratedEmail]);

  useEffect(() => {
    if (!hasGeneratedEmail) {
      updateStateFromItem();
    } else {
      // Skipping updateStateFromItem because generated email exists
    }
  }, [item, hasGeneratedEmail, updateStateFromItem]);

  // Check if the email will create a new thread
  useEffect(() => {
    // Check for both reply and followup emails (both can create new threads)
    if ((item.task_type === 'reply' || item.task_type === 'follow-up') && item.task_subtype === 'email') {
      // Check if subject has changed or if sender is not in recipients
      const subjectChanged = emailSubject !== originalSubject && originalSubject.trim() !== "";
      
      // Check if the selected sender's email is different from the original sender and not in recipients
      const senderNotInRecipients = selectedSender && (() => {
        // Check if the selectedSender matches any of these conditions:
        // 1. Selected sender email is the same as the original sender email
        const isSameAsOriginalSender = selectedSender.send_as_email === originalSenderEmail;
        
        // 2. Selected sender is in the recipients list
        const isInRecipients = emailRecipients.some(recipient => 
          recipient.email && recipient.email.toLowerCase() === selectedSender.send_as_email.toLowerCase()
        );
        
        // 3. Selected sender's user_id matches the item's user_id (they are the same user)
        const isSameUser = selectedSender.send_as_user_id === userId;
        
        // Return true if the sender is NOT in the original conversation 
        // (i.e., none of the above conditions are met)
        return !(isSameAsOriginalSender || isInRecipients || isSameUser);
      })();
      
      // Track the reasons for creating a new thread
      const reasons = [];
      if (subjectChanged) {
        reasons.push("subject line has been changed");
      }
      if (senderNotInRecipients && selectedSender) {
        reasons.push(`sender (${selectedSender.send_as_email}) was not part of the original conversation`);
      }
      
      setNewThreadReasons(reasons);
      setWillCreateNewThread(subjectChanged || senderNotInRecipients);
    } else {
      // For non-email tasks, don't show the alert
      setNewThreadReasons([]);
      setWillCreateNewThread(false);
    }
  }, [emailSubject, originalSubject, selectedSender, emailRecipients, originalSenderEmail, userId, item.task_type, item.task_subtype]);

  const toggleAnnotationModal = () => {
    setIsAnnotationModalOpen((prev) => !prev);
  };

  const handleSend = () => {
    if (!selectedSender) {
      toast({
        title: "Sender Required",
        description: "Please select an email account to send from.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    // Open the send confirmation modal
    setIsSendConfirmOpen(true);
  };

  const confirmSend = async () => {
    // Close the confirmation modal
    setIsSendConfirmOpen(false);
    
    setIsSending(true);
    setSendStatus("sending");
    try {
      // Call the onSendMessage prop with the necessary data
      await onSendMessage({
        content: message,
        subject: emailSubject,
        email_recipients: emailRecipients,
        send_as_email: selectedSender.send_as_email,
        send_as_user_id: selectedSender.send_as_user_id,
        deal_id: item?.deal_id,
        template_type: selectedTemplate, // Pass template type if needed by send API
        reference_activity_id: selectedReferenceActivity?.activity_id,
        is_new_thread: willCreateNewThread,
      });
      
      // If successful:
      setSendStatus("success");
      toast({
        title: "Message Sent",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // If error:
      setSendStatus("error");
      toast({
        title: "Failed to Send",
        description: error.message || "An error occurred while sending.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      // Always reset sending state
      setIsSending(false);
    }
  };

  const handleEmailSubjectChange = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleEmailRecipientsChange = (recipients) => {
    setEmailRecipients(recipients);
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleGenerateEmail = async () => {
    if (!item?.deal_id) {
      toast({
        title: "Missing Deal Information",
        description: "Deal information is required to generate an email.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsGenerating(true);
    setHasGeneratedEmail(true); // Mark that generation has been attempted
    try {
      let activityContext = null;
      // Determine activity context based on template and selected activity
      if (selectedTemplate === 'meeting_follow_up' && selectedReferenceActivity?.activity_type?.toLowerCase() === 'meeting') {
        activityContext = selectedReferenceActivity; // Use the meeting itself
      } else if (selectedTemplate === 'follow_up' && selectedReferenceActivity?.activity_type?.toLowerCase() === 'email') {
        activityContext = selectedReferenceActivity; // Use the email itself for follow-up
      } // Add other conditions if needed
      
      // Call the onGenerateEmail prop with the selected template, not based on task_type
      const generatedData = await onGenerateEmail(selectedTemplate, guidingPrompt, activityContext);
      
      // --- HTML Cleaning Logic ---
      let cleanedContent = generatedData.content || '';
      if (cleanedContent) {
        // 1. Trim whitespace
        cleanedContent = cleanedContent.trim();

        // 2. Replace intermediate paragraph tags with <br>
        // Ensures we match </p>, optional whitespace (including newline), then <p>
        cleanedContent = cleanedContent.replace(/<\/p>\s*<p>/gi, '<br>');

        // 3. Remove the initial <p> tag if it exists
        if (cleanedContent.toLowerCase().startsWith('<p>')) {
          cleanedContent = cleanedContent.slice(3);
        }

        // 4. Remove the final </p> tag if it exists
        if (cleanedContent.toLowerCase().endsWith('</p>')) {
          cleanedContent = cleanedContent.slice(0, -4);
        }

        // 5. Wrap the result in a single <p> tag
        cleanedContent = `<p>${cleanedContent}</p>`;

        // 6. Collapse multiple <br>s (optional cleanup)
        cleanedContent = cleanedContent.replace(/(<br\s*\/?>\s*){2,}/gi, '<br>');

        // 7. Final trim
        cleanedContent = cleanedContent.trim();
      }
      // --- End HTML Cleaning Logic ---

      // Reset the key only if content actually changes significantly
      if (cleanedContent !== message) { // Compare with existing state
        setQuillKey(Date.now());
      }

      // Process the response if successful (using cleaned content)
      if (cleanedContent) {
        setMessage(cleanedContent);
        appliedContentRef.current = cleanedContent;
        generatedContentRef.current = true;

        // Update subject ONLY if we are NOT replying to a specific email thread
        const isReplyingToEmailThread = selectedReferenceActivity?.activity_type?.toLowerCase() === 'email';
        if (!isReplyingToEmailThread && generatedData.subject) {
          setEmailSubject(generatedData.subject);
        }

        if (generatedData.email_recipients?.length > 0) {
          const formattedRecipients = generatedData.email_recipients.map(r => ({ ...r, recipient_type: r.recipient_type || r.type || 'to' }));
          formattedRecipients.forEach(r => delete r.type);
          setEmailRecipients(formattedRecipients);
        }

        // Only show success toast if content was actually generated
        toast({ title: "Email Generated", status: "success", duration: 3000, isClosable: true });

      } else {
        // Handle case where generation resulted in empty content after cleaning
        toast({ 
          title: "Generated Content Empty", 
          description: "The generated email content was empty after processing.", 
          status: "warning", 
          duration: 4000, 
          isClosable: true 
        });
      }
    } catch (error) {
      console.error("Error during email generation/handling:", error);
      toast({
        title: "Generation Failed",
        description: error.message || "Could not generate email content.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsGenerating(false); // Ensure loading state is turned off
    }
  };

  // Get owner ID from the item
  const ownerId = useMemo(() => 
    item?.assigned_user_id || item?.user_id || item?.owner_id,
    [item?.assigned_user_id, item?.user_id, item?.owner_id]
  );
  
  // Fetch owner name using our custom hook
  useUserName(
    ownerId, 
    userData?.account_ID
  );

  useEffect(() => {
    // message state updated
  }, [message]);

  // Update selected template based on task type
  useEffect(() => {
    // Set template based on task_type unless user has manually changed it
    if (item?.task_type === "follow-up" && !hasGeneratedEmail) {
      setSelectedTemplate("follow_up");
    } else if (item?.task_type === "reply" && !hasGeneratedEmail) {
      setSelectedTemplate("general");
    }
  }, [item?.task_type, hasGeneratedEmail]);

  // Early return if no item
  if (!item) {
    return null;
  }

  return (
    <>
      <Box
        bg="transparent"
        p={0}
        borderRadius="md"
        boxShadow="none"
        mb={4}
        height="100%"
      >
        <VStack spacing={6} align="stretch" height="100%">
          {/* Card 1: Overview and Email Composition */}
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <VStack spacing={6} align="stretch">
              {/* Overview Section */}
              <Box>
                <Text fontSize="md" fontWeight="semibold" mb={3}>
                  Overview
                </Text>
                <Box>
                  <OverviewSection
                    account={item.account_name}
                    taskType={
                      item.task_subtype === "email"
                        ? "Email Follow-up"
                        : item.task_subtype
                    }
                    champion={item.champion || "N/A"}
                    lastEngagementDate={item.last_activity_date}
                    dealName={item.deal_name}
                    dealId={item.deal_id}
                    owner={null}
                    showOwner={false}
                  />
                </Box>
              </Box>

              {/* Email Composition Section */}
              <Box>
                <VStack spacing={4} align="stretch">                  
                  <Text fontSize="md" fontWeight="semibold">
                    Compose Email
                  </Text>
                  
                  <ReferenceActivitySection
                    recentActivities={recentActivities}
                    selectedReferenceActivity={selectedReferenceActivity}
                    onSelectActivity={handleSelectActivity}
                    onClearActivity={handleClearActivity}
                    isLoadingActivities={isLoadingActivities}
                  />
                  
                  {willCreateNewThread && (
                    <Alert status="info" borderRadius="md">
                      <AlertIcon />
                      <Box>
                        <Text fontWeight="semibold">Creating a new email thread</Text>
                        {newThreadReasons.length > 0 && (
                          <Text mt={1}>
                            Reason{newThreadReasons.length > 1 ? 's' : ''}: {newThreadReasons.join(', ')}.
                          </Text>
                        )}
                      </Box>
                    </Alert>
                  )}
                  
                  <Box mt={0}>
                    <EmailDetailsSection
                      emailSubject={emailSubject}
                      onSubjectChange={handleEmailSubjectChange}
                      recipients={emailRecipients}
                      onRecipientsChange={handleEmailRecipientsChange}
                      accountID={item.account_id}
                      selectedSender={selectedSender}
                      onSenderChange={setSelectedSender}
                      originalSenderEmail={originalSenderEmail}
                    />
                  </Box>

                  <MessageInput
                    key={quillKey}
                    message={message}
                    setMessage={setMessage}
                    isSending={isSending}
                  />
                </VStack>
              </Box>

              {/* Template Selection and Action Row */}
              <VStack spacing={4} align="stretch">
                {/* Remove the divider */}
                {/* <Divider my={1} /> */}
                
                {/* AI Generation Controls */}
                <HStack spacing={4} align="flex-end">
                  <FormControl flex="1">
                    <FormLabel fontSize="sm" fontWeight="medium">
                      Email Template
                    </FormLabel>
                    <Select
                      value={selectedTemplate}
                      onChange={handleTemplateChange}
                      size="md"
                      borderWidth="1px"
                      borderColor="gray.300"
                      borderRadius="lg"
                    >
                      {TEMPLATE_OPTIONS.map(option => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.value === "meeting_follow_up" && !hasMeetingActivity} // Disable meeting follow-up if no meeting activity
                        >
                          {option.label}{option.value === "meeting_follow_up" && !hasMeetingActivity ? " (No meetings found)" : ""}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl flex="2">
                    <FormLabel fontSize="sm" fontWeight="medium">
                      Additional Instructions
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="gray.500"
                      >
                        <FaPencilAlt />
                      </InputLeftElement>
                      <Input
                        type="text"
                        value={guidingPrompt}
                        onChange={(e) => setGuidingPrompt(e.target.value)}
                        placeholder="E.g., 'Make it formal', 'Include pricing', 'Reference last meeting'"
                        size="md"
                        borderWidth="1px"
                        borderColor="gray.300"
                        borderRadius="lg"
                      />
                    </InputGroup>
                  </FormControl>

                  {/* Generate Button */}
                  <Button
                    leftIcon={<FaMagic />}
                    colorScheme="purple"
                    onClick={handleGenerateEmail}
                    isLoading={isGenerating}
                    loadingText="Generating"
                    isDisabled={isSending || isGenerating || isArchiving || !item?.deal_id}
                    size="md"
                    title="Generate email content"
                    borderRadius="md"
                  >
                    Generate
                  </Button>

                  {/* Send Button */}
                  <Button
                    leftIcon={<FaPaperPlane />}
                    colorScheme="blue"
                    onClick={handleSend}
                    isLoading={isSending}
                    loadingText="Sending"
                    isDisabled={!message.trim() || isSending || isGenerating || isArchiving}
                    size="md"
                    title="Send this email"
                    borderRadius="md"
                  >
                    Send
                  </Button>
                </HStack>
              </VStack>
            </VStack>
          </Box>

          {/* Card 2: Related Activities */}
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <VStack spacing={6} align="stretch">
              <DealActivity 
                accountID={item.account_id} 
                dealID={item.deal_id}
                title="Related Activities"
              />
            </VStack>
          </Box>
        </VStack>
      </Box>

      {/* Loading Overlay Modal */}
      <Modal 
        isOpen={showLoadingOverlay} 
        closeOnOverlayClick={false} 
        isCentered
      >
        <ModalOverlay backdropFilter="blur(4px)" bg="blackAlpha.700" />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalBody>
            <LoadingOverlay status={sendStatus} willCreateNewThread={willCreateNewThread} />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Send Confirmation Modal */}
      <Modal isOpen={isSendConfirmOpen} onClose={() => setIsSendConfirmOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Send</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to send this email?
            {willCreateNewThread && (
              <Alert status="info" mt={3} borderRadius="md">
                <AlertIcon />
                <Box>
                  <Text>
                    {selectedReferenceActivity 
                      ? `This email will start a new thread because it references a non-email activity (${selectedReferenceActivity.activity_type}).`
                      : "This email will start a new conversation thread."
                    }
                  </Text>
                  {newThreadReasons.length > 0 && (
                    <Text mt={1} fontSize="sm">
                      Reason{newThreadReasons.length > 1 ? 's' : ''}: {newThreadReasons.join(', ')}.
                    </Text>
                  )}
                </Box>
              </Alert>
            )}
            {!willCreateNewThread && selectedReferenceActivity && (
              <Alert status="success" mt={3} borderRadius="md">
                  <AlertIcon />
                  <Text>
                    This email will reply in the existing thread from {formatDate(selectedReferenceActivity.created_at || selectedReferenceActivity.date)}.
                  </Text>
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={() => setIsSendConfirmOpen(false)}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={confirmSend} isLoading={isSending}>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Make sure AnnotationModal does not throw error and renders only when needed */}
      {isAnnotationModalOpen && (
        <AnnotationModal
          isOpen={isAnnotationModalOpen}
          onClose={toggleAnnotationModal}
          onSubmit={onAddAnnotation}
        />
      )}
    </>
  );
};

EmailFollowupDetail.propTypes = {
  item: PropTypes.object.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onAddAnnotation: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onGenerateEmail: PropTypes.func.isRequired,
};

export default EmailFollowupDetail;