// /src/components/shared/BaseTag.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon, Text, Portal, Box, useColorModeValue } from '@chakra-ui/react';

const BaseTag = ({ 
  label,
  icon: IconComponent,
  hoverIcon: HoverIconComponent,
  colorScheme = "gray",
  onClick,
  tooltip,
  children,
  cursor,
  onMouseEnter: onMouseEnterProp,
  onMouseLeave: onMouseLeaveProp,
  ...props 
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  
  const tooltipBg = useColorModeValue("gray.700", "white");
  const tooltipTextColor = useColorModeValue("white", "gray.700");

  const handleMouseEnter = (e) => {
    setIsHovered(true);
    if (onMouseEnterProp) onMouseEnterProp(e);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(false);
    if (onMouseLeaveProp) onMouseLeaveProp(e);
  };

  const handleMouseMove = (e) => {
    setMousePos({ x: e.clientX, y: e.clientY });
  };

  return (
    <>
      <Flex
        align="center"
        bg={`${colorScheme}.50`}
        color={`${colorScheme}.700`}
        px={3}
        py={2}
        borderRadius="md"
        borderWidth="1px"
        borderColor={`${colorScheme}.200`}
        cursor={cursor || (onClick ? "pointer" : "default")}
        role={HoverIconComponent || onClick ? "group" : undefined}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        _hover={{
          bg: `${colorScheme}.100`,
          transform: onClick ? 'translateY(-1px)' : 'none',
          borderColor: `${colorScheme}.300`,
          transition: 'all 0.2s ease-in-out',
        }}
        transition="all 0.2s ease-in-out"
        {...props}
      >
        {IconComponent && (
          <Flex position="relative" mr={2}>
            <Icon
              as={IconComponent}
              boxSize={4}
              transition="all 0.2s"
              opacity={HoverIconComponent ? 1 : undefined}
              _groupHover={{ opacity: HoverIconComponent ? 0 : undefined }}
            />
            {HoverIconComponent && (
              <Icon
                as={HoverIconComponent}
                boxSize={4}
                position="absolute"
                top="0"
                left="0"
                transition="all 0.2s"
                opacity={0}
                _groupHover={{ 
                  opacity: 1,
                  color: `${colorScheme}.800`
                }}
              />
            )}
          </Flex>
        )}
        {children || (
          <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
            {label}
          </Text>
        )}
      </Flex>
      {isHovered && tooltip && (
        <Portal>
          <Box
            position="fixed"
            left={mousePos.x + 10}
            top={mousePos.y + 10}
            bg={tooltipBg}
            color={tooltipTextColor}
            px="8px"
            py="4px"
            fontSize="sm"
            borderRadius="md"
            zIndex="tooltip"
            pointerEvents="none"
            boxShadow="sm"
          >
            {tooltip}
          </Box>
        </Portal>
      )}
    </>
  );
};

BaseTag.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.elementType,
  hoverIcon: PropTypes.elementType,
  colorScheme: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  cursor: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default BaseTag;