import { useMemo } from "react";
import { useIntegrations } from "./useIntegrations";
import { useAuthData } from "auth-context";

export function useEmailSender() {
  const { sortedIntegrations, isLoading } = useIntegrations();
  const { user_ID: currentUserID } = useAuthData();

  // Memoize the Gmail integrations filtering logic
  const availableSenders = useMemo(() => {
    if (!sortedIntegrations?.length) return [];

    return sortedIntegrations
      .filter(
        (integration) =>
          integration.type === "gmail" &&
          integration.connection?.expiration &&
          new Date(integration.connection.expiration * 1000) > new Date()
      )
      .map((integration) => ({
        email: integration.connection.email,
        user_id: integration.connection.user_id,
        is_current_user: integration.connection.user_id === currentUserID,
      }));
  }, [sortedIntegrations, currentUserID]);

  // Memoize these arrays themselves to prevent recreating them on each render
  const personalAccounts = useMemo(
    () => availableSenders.filter((sender) => sender.is_current_user),
    [availableSenders]
  );

  const teamAccounts = useMemo(
    () => availableSenders.filter((sender) => !sender.is_current_user),
    [availableSenders]
  );

  // Memoize selector functions to prevent recreation - these no longer need to be regenerated
  // since they now just return the memoized arrays
  const getCurrentUserSenders = useMemo(
    () => () => personalAccounts,
    [personalAccounts]
  );

  const getTeamSenders = useMemo(() => () => teamAccounts, [teamAccounts]);

  return {
    isLoading,
    availableSenders,
    getCurrentUserSenders,
    getTeamSenders,
  };
}
