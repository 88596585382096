import React from "react";
import {
  Box,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  Heading,
  Card,
  CardBody,
  Button,
  Icon,
  Flex,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { useIntegrations } from "../hooks/useIntegrations";
import { IntegrationsModal } from "./IntegrationsModal";
import ConnectionCard from "./ConnectionCard";
import { useAuthData } from "auth-context";
import { FaPlus, FaLink, FaUser, FaUsers } from "react-icons/fa";

const IntegrationsSection = ({
  integrationView, // Received from parent
  isModalOpen, // Received from parent
  onModalOpen, // Received from parent
  onModalClose, // Received from parent
}) => {
  const { isLoading, error, sortedIntegrations, initiateConnection } =
    useIntegrations();
  const { user_ID: currentUserId } = useAuthData();

  if (isLoading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="lg" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10} color="red.500">
        <Text>Error loading integrations: {error}</Text>
      </Box>
    );
  }

  // Categorize integrations by owner and permission type
  const myPersonalIntegrations = sortedIntegrations.filter(
    (item) => 
      item.connection.user_id === currentUserId && 
      item.connection.permission_type === "personal"
  );
  
  const mySharedIntegrations = sortedIntegrations.filter(
    (item) => 
      item.connection.user_id === currentUserId && 
      item.connection.permission_type === "shared"
  );
  
  const teamPersonalIntegrations = sortedIntegrations.filter(
    (item) => 
      item.connection.user_id !== currentUserId && 
      item.connection.permission_type === "personal"
  );
  
  const teamSharedIntegrations = sortedIntegrations.filter(
    (item) => 
      item.connection.user_id !== currentUserId && 
      item.connection.permission_type === "shared"
  );

  // Check if I have any integrations at all
  const haveMyIntegrations = myPersonalIntegrations.length > 0 || mySharedIntegrations.length > 0;
  
  // Check if team has any integrations at all
  const haveTeamIntegrations = teamPersonalIntegrations.length > 0 || teamSharedIntegrations.length > 0;

  // Should show my integrations sections
  const showMyIntegrations = integrationView === "all" || integrationView === "my";
  
  // Should show team integrations sections
  const showTeamIntegrations = integrationView === "all" || integrationView === "team";

  // Empty state card component
  const EmptyStateCard = ({ type }) => {
    const isPersonal = type === 'myPersonal' || type === 'myShared';
    const title = {
      'myPersonal': "You don't have any personal integrations yet",
      'myShared': "You don't have any shared integrations yet",
      'teamPersonal': "Your team doesn't have any personal integrations yet",
      'teamShared': "Your team doesn't have any shared integrations yet",
    };
    
    const icon = {
      'myPersonal': FaUser,
      'myShared': FaUsers,
      'teamPersonal': FaUser,
      'teamShared': FaUsers,
    };
    
    return (
      <Card
        variant="outline"
        borderRadius="lg"
        boxShadow="sm"
        borderColor="gray.200"
        bg="white"
        height="150px"
        _hover={{
          boxShadow: "md",
          borderColor: "blue.200",
          transition: "all 0.2s ease-in-out",
        }}
      >
        <CardBody>
          <Flex direction="column" justify="center" align="center" height="100%">
            <Icon as={icon[type]} boxSize={6} color="gray.400" mb={3} />
            <Text fontWeight="medium" color="gray.600" mb={2}>
              {title[type]}
            </Text>
            {isPersonal && (
              <Button
                leftIcon={<FaPlus />}
                size="sm"
                colorScheme="blue"
                variant="outline"
                onClick={onModalOpen}
                mt={2}
              >
                Add Integration
              </Button>
            )}
          </Flex>
        </CardBody>
      </Card>
    );
  };

  // Section header with icon
  const SectionHeader = ({ title, icon, count }) => (
    <HStack spacing={2} mb={4}>
      <Icon as={icon} color="blue.500" />
      <Heading size="md">{title}</Heading>
      <Text mt={.5} color="gray.700" fontSize="sm" fontWeight="semibold">
        ({count})
      </Text>
    </HStack>
  );

  // Empty state when no integrations match the current filter
  const NoFilteredResults = () => {
    if (integrationView === "my" && !haveMyIntegrations) {
      return (
        <Box textAlign="center" py={8}>
          <Icon as={FaLink} boxSize={8} color="gray.400" mb={4} />
          <Heading size="md" mb={2} color="gray.700">No Integrations Yet</Heading>
          <Text color="gray.600" mb={6}>
            You haven&apos;t set up any integrations yet. Get started by adding your first integration.
          </Text>
          <Button
            leftIcon={<FaPlus />}
            colorScheme="blue"
            onClick={onModalOpen}
            size="md"
          >
            Add Integration
          </Button>
        </Box>
      );
    }
    
    if (integrationView === "team" && !haveTeamIntegrations) {
      return (
        <Box textAlign="center" py={8}>
          <Icon as={FaUsers} boxSize={8} color="gray.400" mb={4} />
          <Heading size="md" mb={2} color="gray.700">No Team Integrations</Heading>
          <Text color="gray.600">
            No one else in your organization has set up integrations yet.
          </Text>
        </Box>
      );
    }
    
    if (integrationView === "all" && !haveMyIntegrations && !haveTeamIntegrations) {
      return (
        <Box textAlign="center" py={8}>
          <Icon as={FaLink} boxSize={8} color="gray.400" mb={4} />
          <Heading size="md" mb={2} color="gray.700">No Integrations Yet</Heading>
          <Text color="gray.600" mb={6}>
            No integrations have been set up yet. Get started by adding your first integration.
          </Text>
          <Button
            leftIcon={<FaPlus />}
            colorScheme="blue"
            onClick={onModalOpen}
            size="md"
          >
            Add Integration
          </Button>
        </Box>
      );
    }
    
    return null;
  };

  return (
    <VStack spacing={6} align="stretch">
      {/* Handle Loading State */} 
      {isLoading && ( 
        <Box textAlign="center" py={10}> 
          <Spinner size="lg" /> 
        </Box> 
      )} 

      {/* Handle Error State */} 
      {!isLoading && error && ( 
        <Box textAlign="center" py={10} color="red.500"> 
          <Text>Error loading integrations: {error}</Text> 
        </Box> 
      )} 

      {/* Render Main Content Only if Not Loading and No Error */} 
      {!isLoading && !error && ( 
        // Add bottom padding to this container 
        <Box pb={8}> 
          {(!haveMyIntegrations && !haveTeamIntegrations) || 
           (integrationView === "my" && !haveMyIntegrations) || 
           (integrationView === "team" && !haveTeamIntegrations) ? ( 
            <NoFilteredResults /> 
          ) : ( 
            <VStack spacing={6} align="stretch"> 
              {/* My Personal Integrations Section */} 
              {showMyIntegrations && (myPersonalIntegrations.length > 0 || integrationView === "my") && ( 
                <Box> 
                  <SectionHeader 
                    title="My Personal Integrations" 
                    icon={FaUser} 
                    count={myPersonalIntegrations.length} 
                  /> 
                  <Text color="gray.600" fontSize="sm" mb={4}> 
                    These integrations are only accessible by you. 
                  </Text> 
                  {myPersonalIntegrations.length > 0 ? ( 
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}> 
                      {myPersonalIntegrations.map((item) => ( 
                        <ConnectionCard 
                          key={`${item.type}-${item.connection.email}`} 
                          connection={item.connection} 
                          type={item.type} 
                        /> 
                      ))} 
                    </SimpleGrid> 
                  ) : ( 
                    <EmptyStateCard type="myPersonal" /> 
                  )} 
                </Box> 
              )} 
               
              {/* My Shared Integrations Section */} 
              {showMyIntegrations && (mySharedIntegrations.length > 0 || integrationView === "my") && ( 
                <Box> 
                  <SectionHeader 
                    title="My Shared Integrations" 
                    icon={FaUsers} 
                    count={mySharedIntegrations.length} 
                  /> 
                  <Text color="gray.600" fontSize="sm" mb={4}> 
                    These integrations are accessible by your entire organization. 
                  </Text> 
                  {mySharedIntegrations.length > 0 ? ( 
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}> 
                      {mySharedIntegrations.map((item) => ( 
                        <ConnectionCard 
                          key={`${item.type}-${item.connection.email}`} 
                          connection={item.connection} 
                          type={item.type} 
                        /> 
                      ))} 
                    </SimpleGrid> 
                  ) : ( 
                    <EmptyStateCard type="myShared" /> 
                  )} 
                </Box> 
              )} 

              {/* Divider Logic Refined */} 
              {(integrationView === 'all' || integrationView === 'my') && haveMyIntegrations && 
               (integrationView === 'all' || integrationView === 'team') && haveTeamIntegrations && ( 
                <Divider my={2} /> 
              )} 
               
              {/* Team Personal Integrations Section */} 
              {showTeamIntegrations && (teamPersonalIntegrations.length > 0 || integrationView === "team") && ( 
                <Box> 
                  <SectionHeader 
                    title="Team Personal Integrations" 
                    icon={FaUser} 
                    count={teamPersonalIntegrations.length} 
                  /> 
                  <Text color="gray.600" fontSize="sm" mb={4}> 
                    Personal integrations from other members of your organization. 
                  </Text> 
                  {teamPersonalIntegrations.length > 0 ? ( 
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}> 
                      {teamPersonalIntegrations.map((item) => ( 
                        <ConnectionCard 
                          key={`${item.type}-${item.connection.email}`} 
                          connection={item.connection} 
                          type={item.type} 
                        /> 
                      ))} 
                    </SimpleGrid> 
                  ) : ( 
                    <EmptyStateCard type="teamPersonal" /> 
                  )} 
                </Box> 
              )} 

              {/* Team Shared Integrations Section */} 
              {showTeamIntegrations && (teamSharedIntegrations.length > 0 || integrationView === "team") && ( 
                <Box> 
                  <SectionHeader 
                    title="Team Shared Integrations" 
                    icon={FaUsers} 
                    count={teamSharedIntegrations.length} 
                  /> 
                  <Text color="gray.600" fontSize="sm" mb={4}> 
                    Shared integrations that other members of your organization have connected. 
                  </Text> 
                  {teamSharedIntegrations.length > 0 ? ( 
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}> 
                      {teamSharedIntegrations.map((item) => ( 
                        <ConnectionCard 
                          key={`${item.type}-${item.connection.email}`} 
                          connection={item.connection} 
                          type={item.type} 
                        /> 
                      ))} 
                    </SimpleGrid> 
                  ) : ( 
                    <EmptyStateCard type="teamShared" /> 
                  )} 
                </Box> 
               )} 
            </VStack> 
          )} 
        </Box> 
      )} 

      {/* Modal - Rendered outside the main content flow */} 
      <IntegrationsModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        onInitiateConnection={initiateConnection}
      />
    </VStack>
  );
};

export default IntegrationsSection;
