import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  VStack,
  Text,
  HStack,
  Flex,
  Badge,
  Button,
  useColorModeValue,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { FaChevronDown, FaLink, FaRegListAlt, FaReply } from 'react-icons/fa';
import DOMPurify from 'dompurify';
// Import the email processing function
import { processEmailContent } from '../Shared/extractEmailContent';

// Helper function for debugging - consistent with parent component
const debugLog = (message, data) => {
  // Only log in development environment
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(`[ReferenceActivity] ${message}`, data);
  }
};

// Helper component for recipient display
const RecipientList = ({ type, emails }) => {
  const labelColor = useColorModeValue('gray.600', 'gray.400');
  if (!emails || emails.length === 0) return null;

  return (
    <Text fontSize="sm">
      <Text as="span" fontWeight="semibold" color={labelColor}>
        {type}:
      </Text>{' '}
      {emails.join(', ')}
    </Text>
  );
};

// Helper function to format date with time
const formatDateTime = (dateString) => {
  if (!dateString) return "Unknown date/time";
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const ReferenceActivitySection = ({ 
  selectedReferenceActivity, 
  recentActivities,
  onSelectActivity, 
  onClearActivity,
  isLoadingActivities
}) => {
// Style variables
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const labelColor = useColorModeValue('gray.600', 'gray.400');
  const dateColor = useColorModeValue('gray.500', 'gray.400');
  const refBadgeBg = useColorModeValue('blue.50', 'blue.800');
  const refBadgeColor = useColorModeValue('blue.600', 'blue.200');
  const contentBgColor = useColorModeValue('white', 'gray.800'); // Add content background color
  
  // Add state to ensure content is only rendered client-side where DOMPurify is available
  const [isClient, setIsClient] = useState(false);
  
  // Log when component receives props update 
  useEffect(() => {
    debugLog("ReferenceActivitySection props updated:", { 
      hasSelectedActivity: !!selectedReferenceActivity,
      selectedActivityId: selectedReferenceActivity?.activity_id || selectedReferenceActivity?.id || 'none',
      recentActivitiesCount: recentActivities.length,
      isLoadingActivities
    });
    
    if (recentActivities.length > 0) {
      debugLog("First activity in list:", {
        id: recentActivities[0].activity_id || recentActivities[0].id,
        type: recentActivities[0].activity_type || recentActivities[0].type,
        subject: recentActivities[0].subject
      });
    }
  }, [selectedReferenceActivity, recentActivities, isLoadingActivities]);
  
  // Set isClient to true when component mounts
  useEffect(() => {
    setIsClient(true);
  }, []);

  // Extract activity data safely
  const activityTitle = selectedReferenceActivity?.title || selectedReferenceActivity?.subject || "No Title/Subject";
  // Get the raw body content
  const rawActivityBody = selectedReferenceActivity?.activity_data?.data?.body || 
    selectedReferenceActivity?.body || 
    ""; // Default to empty string
  const activityDate = selectedReferenceActivity?.created_at || 
    selectedReferenceActivity?.date || 
    selectedReferenceActivity?.activity_date;

  // Process the content using the imported function
  const processedActivityContent = useMemo(() => {
      // Only process if we have content and are on the client
      if (isClient && rawActivityBody) {
          try {
              // Sanitize *before* processing if needed, or ensure processEmailContent sanitizes
              const processed = processEmailContent(rawActivityBody);
              // Sanitize the final output for safety when using dangerouslySetInnerHTML
              return DOMPurify.sanitize(processed);
          } catch (error) {
              debugLog("Error processing or sanitizing email content:", error);
              return "<p>Error displaying content.</p>"; // Fallback for safety
          }
      }
      return ""; // Return empty string if not client or no body
  }, [rawActivityBody, isClient]);

  // Helper to get a concise title for the dropdown
  const getActivityMenuItemTitle = (activity) => {
    const type = activity.activity_type || activity.type || 'Activity';
    const title = activity.title || activity.subject || 'Untitled';
    // Truncate long titles
    const shortTitle = title.length > 40 ? title.substring(0, 37) + '...' : title;
    
    let prefix = '';
    if (activity.displayIndex !== undefined) {
      prefix = `#${activity.displayIndex} `;
    }
    
    // For the current email being replied to, add a special label
    if (activity.displayIndex === 0 || (activity.id === selectedReferenceActivity?.id && !activity.displayIndex)) {
      return `${type}: ${shortTitle} (Current Email)`;
    }
    
    return `${prefix}${type}: ${shortTitle}`;
  };
  
  // Log when no activities are found
  useEffect(() => {
    if (recentActivities.length === 0 && !isLoadingActivities) {
      debugLog("No activities available to display", null);
    }
  }, [recentActivities, isLoadingActivities]);

  if (!selectedReferenceActivity) {
    return (
      <Box 
        p={4} 
        bg={bgColor}
        borderRadius="md" 
        borderWidth="1px" 
        borderColor={borderColor}
        borderStyle="dashed"
        mb={4}
      >
        <Flex align="center" justify="space-between">
          <HStack spacing={2}>
            <FaLink color={labelColor} />
            <Text color="gray.600" fontSize="sm" fontWeight="medium">Reply to an existing email thread (Optional)</Text>
          </HStack>
          
          <Menu>
            <MenuButton
              as={Button}
              size="sm"
              variant="outline"
              rightIcon={<FaChevronDown size={10} />}
              leftIcon={<FaRegListAlt />}
              isLoading={isLoadingActivities}
            >
              Browse Email Threads {recentActivities.length > 0 ? `(${recentActivities.length})` : ''}
            </MenuButton>
            <MenuList zIndex={10}>
              {recentActivities.length > 0 ? (
                recentActivities.map((activity, index) => (
                  <MenuItem
                    key={activity.id || index}
                    onClick={() => {
                      debugLog("Selected activity from dropdown:", {
                        id: activity.activity_id || activity.id,
                        type: activity.activity_type || activity.type,
                        subject: activity.subject
                      });
                      onSelectActivity(activity);
                    }}
                  >
                    <Flex align="center" width="100%">
                      <Text fontWeight="medium" isTruncated maxWidth="250px">
                        {getActivityMenuItemTitle(activity)}
                      </Text>
                      <Spacer />
                      <Text fontSize="xs" color="gray.500">
                        {formatDateTime(activity.created_at || activity.date || activity.activity_date)}
                      </Text>
                    </Flex>
                  </MenuItem>
                ))
              ) : (
                <MenuItem isDisabled>No recent email activities found</MenuItem>
              )}
            </MenuList>
          </Menu>
        </Flex>
        
        {recentActivities.length === 0 && !isLoadingActivities && (
          <Alert status="info" mt={4} borderRadius="md">
            <AlertIcon />
            <Box>
              <Text fontWeight="medium">No email activities found</Text>
              <Text fontSize="sm">This deal doesn&apos;t have any email activities to reference.</Text>
            </Box>
          </Alert>
        )}
      </Box>
    );
  }
  
  return (
    <Box 
      p={4} 
      bg={bgColor} 
      borderRadius="md" 
      borderWidth="1px" 
      borderColor={borderColor}
      position="relative"
    >
      <VStack align="stretch" spacing={3}>
        <Flex align="center" justify="space-between">
          <Badge
            bg={refBadgeBg}
            color={refBadgeColor}
            px={2}
            py={1}
            borderRadius="md"
            display="inline-flex"
            alignItems="center"
            variant="subtle"
          >
            <Box as={FaReply} mr={1.5} />
            Replying to
          </Badge>
          
          <Menu>
            <MenuButton
              as={Button}
              size="xs"
              variant="ghost"
              rightIcon={<FaChevronDown size={8} />}
              isDisabled={recentActivities.length <= 1 && selectedReferenceActivity?.displayIndex === 0}
            >
              Change message
            </MenuButton>
            <MenuList zIndex={10} fontSize="sm">
              {recentActivities.length > 0 ? (
                <>
                  {recentActivities.map((activity, index) => (
                    <MenuItem
                      key={activity.id || index}
                      onClick={() => {
                        debugLog("Changed reference activity:", {
                          from: selectedReferenceActivity?.id,
                          to: activity.id,
                          subject: activity.subject,
                          type: activity.activity_type || activity.type
                        });
                        onSelectActivity(activity);
                      }}
                      isDisabled={activity.id === selectedReferenceActivity.id}
                    >
                      <Flex align="center" width="100%">
                        <Text fontWeight={activity.displayIndex === 0 ? "bold" : "medium"} isTruncated maxWidth="250px">
                           {getActivityMenuItemTitle(activity)}
                        </Text>
                        <Spacer />
                        <Text fontSize="xs" color="gray.500">
                           {formatDateTime(activity.created_at || activity.date || activity.activity_date)}
                        </Text>
                      </Flex>
                    </MenuItem>
                  ))}
                  <MenuDivider />
                  <MenuItem onClick={() => {
                    debugLog("Clearing reference activity", { 
                      previousId: selectedReferenceActivity.id,
                      subject: selectedReferenceActivity.subject
                    });
                    onClearActivity();
                  }}>
                    <Text>Start a new thread</Text>
                  </MenuItem>
                </>
              ) : (
                <MenuItem onClick={onClearActivity}>
                  <Text>Start a new thread</Text>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Flex>
        
        {/* Display Selected Activity Details */}
        <Box 
          p={3} // Reduced padding
          borderRadius="md" 
          borderWidth="1px" 
          borderColor={borderColor} 
          bg={contentBgColor}
          sx={{
            'p, div': {
              fontSize: 'sm',
              marginBottom: '0.4rem',
              '&:last-child': { marginBottom: 0 }
            },
            'a': { color: 'blue.500', textDecoration: 'underline' },
            'blockquote': { 
              borderLeft: '2px solid', 
              borderColor: 'gray.300', 
              pl: 2, 
              ml: 1, 
              color: 'gray.500', 
              fontSize: 'xs'
            },
            'br': { display: 'block', content: '""', marginTop: '0.2rem' },
            'div + div': { marginTop: '0.4rem' },
            'ul, ol': {
              paddingLeft: '1.5rem',
              marginBottom: '0.5rem'
            },
            'li': {
              marginBottom: '0.25rem'
            }
          }}
        >
          <VStack align="stretch" spacing={1}>
            {/* Header Info (Simplified) */}
            <HStack justify="space-between">
               <Text fontSize="xs" fontWeight="medium" color={labelColor} isTruncated maxWidth="70%">
                  {activityTitle}
               </Text>
               <Text fontSize="xs" color={dateColor} whiteSpace="nowrap">
                  {formatDateTime(activityDate)}
               </Text>
            </HStack>
             
            {/* Render the processed content */}
            {isClient ? (
               <Box 
                 dangerouslySetInnerHTML={{ __html: processedActivityContent }} 
                 minHeight="30px"
               />
            ) : (
               <Text fontSize="sm" color="gray.500">Loading content...</Text>
            )}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

ReferenceActivitySection.propTypes = {
  selectedReferenceActivity: PropTypes.object,
  recentActivities: PropTypes.array.isRequired,
  onSelectActivity: PropTypes.func.isRequired,
  onClearActivity: PropTypes.func.isRequired,
  isLoadingActivities: PropTypes.bool
};

RecipientList.propTypes = {
  type: PropTypes.string.isRequired,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ReferenceActivitySection; 