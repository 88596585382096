// src/components/Inbox/EmailDetailsSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Input, VStack, Flex } from '@chakra-ui/react';
import EditableEmailRecipients from './EditableEmailRecipients';
import EmailSenderSelect from '../Components/EmailSenderSelect';

const EmailDetailsSection = ({ 
  emailSubject, 
  onSubjectChange, 
  recipients, 
  onRecipientsChange, 
  accountID,
  selectedSender,
  onSenderChange,
  originalSenderEmail,
  actionButtons
}) => (
  <VStack spacing={4} align="stretch">
    <Flex>
      <Input
        value={emailSubject}
        onChange={onSubjectChange}
        placeholder="Enter email subject"
        variant="outline"
        bg="white"
        borderRadius="md"
        borderWidth="1px"
        borderColor="gray.300"
        _hover={{ borderColor: 'gray.400' }}
        _focus={{ borderColor: 'gray.500', boxShadow: 'outline' }}
        height="40px"
        fontSize="sm"
        fontWeight="medium"
        _placeholder={{ color: 'gray.500', fontWeight: 'normal' }}
        mr={actionButtons ? 2 : 0}
      />
      {actionButtons && (
        <Box>
          {actionButtons}
        </Box>
      )}
    </Flex>
    <Box
      borderWidth="1px"
      borderColor="gray.300"
      borderRadius="md"
      p={4}
    >
      <VStack spacing={4} align="stretch">
        <EditableEmailRecipients
          recipients={recipients}
          onChange={onRecipientsChange}
          accountID={accountID}
          senderComponent={
            <EmailSenderSelect
              selectedEmail={selectedSender ? `${selectedSender.send_as_email}|${selectedSender.send_as_user_id}` : ''}
              onSenderChange={onSenderChange}
              isRequired={true}
              recipients={recipients}
              originalSenderEmail={originalSenderEmail}
            />
          }
        />
      </VStack>
    </Box>
  </VStack>
);

EmailDetailsSection.propTypes = {
  emailSubject: PropTypes.string.isRequired,
  onSubjectChange: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.oneOf(['to', 'cc', 'bcc'])
  })).isRequired,
  onRecipientsChange: PropTypes.func.isRequired,
  accountID: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  selectedSender: PropTypes.shape({
    send_as_email: PropTypes.string,
    send_as_user_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  onSenderChange: PropTypes.func.isRequired,
  originalSenderEmail: PropTypes.string,
  actionButtons: PropTypes.node
};

export default EmailDetailsSection;