// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Contacts/index.jsx
import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Button,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  PopoverFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  CheckboxGroup,
  Checkbox,
  HStack,
  VStack,
  useColorModeValue,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthData } from "auth-context";
import { FiPlus, FiFilter } from "react-icons/fi";
import { ChevronDown, ChevronUp } from "lucide-react";
import ContactsTable from "./ContactsTable";
import PaginationControls from "../Shared/PaginationControls";
import PageHeader from "../Shared/PageHeader";
import TableSkeleton from "../Shared/TableSkeleton";
import useFetchContacts from "./hooks/useFetchContacts";
import { useDebounce } from 'use-debounce';
import { useFilterState } from "../hooks/useFilterState";
import CreateContactModal from "./CreateContactModal";

const ITEMS_PER_PAGE = 10;

const Contacts = () => {
  // -------------------- Utils & Helper Functions --------------------
  const isActive = (dateString) => {
    if (!dateString) return false;
    const lastActivityDate = new Date(dateString);
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return lastActivityDate >= twoWeeksAgo;
  };

  const getColumnType = (contacts, columnName) => {
    if (!contacts || contacts.length === 0) return null;
    if (columnName === 'amount') return 'number';
    const sampleValue = contacts.find(contact => contact[columnName] != null)?.[columnName];
    if (columnName.toLowerCase().includes('date')) return 'date';
    if (typeof sampleValue === 'number' || (typeof sampleValue === 'string' && !isNaN(sampleValue))) return 'number';
    return 'option';
  };

  const getColumnValues = (contacts, columnName) => {
    const frequencyMap = contacts.reduce((acc, contact) => {
      const value = contact[columnName];
      if (value) {
        acc[value] = (acc[value] || 0) + 1;
      } else {
        acc["None"] = (acc["None"] || 0) + 1;
      }
      return acc;
    }, {});

    const entries = Object.entries(frequencyMap);
    const nonNoneEntries = entries.filter(([value]) => value !== "None");
    
    if (nonNoneEntries.length < 2 || !nonNoneEntries.some(([, count]) => count >= 3)) {
      return [];
    }

    return entries
      .filter(([value, count]) => value === "None" ? count >= 3 : true)
      .sort((a, b) => {
        const countDiff = b[1] - a[1];
        if (countDiff !== 0) return countDiff;
        if (a[0] === "None") return 1;
        if (b[0] === "None") return -1;
        return a[0].localeCompare(b[0]);
      })
      .map(([value, count]) => ({
        value,
        count,
        label: `${value} (${count})`
      }));
  };

  const getColumnRange = (contacts, columnName) => {
    const values = contacts.map(contact => contact[columnName]).filter(value => typeof value === 'number');
    if (values.length === 0) return null;
    const uniqueValues = new Set(values);
    if (uniqueValues.size < 3) return null;
    return {
      min: Math.min(...values),
      max: Math.max(...values)
    };
  };

  const titleCase = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // -------------------- Hooks & State --------------------
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  
  // Add a ref to track manual navigation vs URL change
  const isManualNavigation = useRef(false);
  
  // Get current page from URL query param or default to 1
  const getInitialPage = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const initialPage = page ? parseInt(page, 10) : 1;
    return initialPage;
  };
  
  const [currentPage, setCurrentPage] = useState(getInitialPage);
  const [sortConfig, setSortConfig] = useFilterState("contacts-sort-config", { 
    key: 'last_activity_date', 
    direction: 'descending' 
  });
  const [currentStatusFilter] = useFilterState("contacts-status-filter", "all");
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dynamicFilters, setDynamicFilters] = useFilterState("contacts-dynamic-filters", {});
  const [expandedSections, setExpandedSections] = useState({});
  const [loadingSections, setLoadingSections] = useState({});
  
  // Refs to track previous values for determining if filters actually changed
  const prevSortConfigRef = useRef(sortConfig);
  const prevSearchQueryRef = useRef("");
  const prevStatusFilterRef = useRef(currentStatusFilter);
  const prevDynamicFiltersRef = useRef({});
  const isInitialMount = useRef(true);
  const hasInitializedUrl = useRef(false);

  const selectedBg = useColorModeValue("blue.500", "blue.300");
  const selectedTextColor = useColorModeValue("white", "gray.800");
  const unselectedBg = useColorModeValue("white", "gray.600");
  const unselectedTextColor = useColorModeValue("gray.700", "gray.200");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.500");

  // Define updatePageParam with manual navigation flag
  const updatePageParam = useCallback((page) => {
    isManualNavigation.current = true;
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [location.search, location.pathname, navigate]);

  // -------------------- Data Fetching & Processing --------------------
  const { contacts, isLoading, isError } = useFetchContacts(
    accountID,
    debouncedSearchQuery,
    "Lead",
    currentStatusFilter
  );

  const enhancedContacts = useMemo(() => {
    if (!contacts) return [];
    return contacts.map(contact => ({
      ...contact,
      isActive: contact.isActive ?? isActive(contact.last_activity_date),
    }));
  }, [contacts]);

  const searchSuggestions = useMemo(() => {
    if (!enhancedContacts) return [];
    return enhancedContacts
      .filter(contact => 
        contact.name && 
        contact.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      )
      .map(contact => ({
        id: contact.id,
        label: contact.name
      }));
  }, [enhancedContacts, debouncedSearchQuery]);

  const filterableColumns = useMemo(() => {
    if (!contacts || contacts.length === 0) return { date: [], number: [], option: [] };
    
    const columns = { date: [], number: [], option: [] };
    const blacklist = [
      "crm account id",
      "user id",
      "user name",
      "user last name",
      "user email",
      "description",
      "company email",
      "company website"
    ];
    
    Object.keys(contacts[0]).forEach(column => {
      if (column.toLowerCase().includes('id')) return;
      if (blacklist.includes(column.toLowerCase())) return;
      
      const type = getColumnType(contacts, column);
      
      if (type === 'date') {
        const dateFrequencyMap = contacts.reduce((acc, contact) => {
          const value = contact[column];
          if (value) {
            const date = new Date(value);
            const monthYear = `${date.getMonth()}-${date.getFullYear()}`;
            acc[monthYear] = (acc[monthYear] || 0) + 1;
          }
          return acc;
        }, {});

        const hasFrequentDates = Object.values(dateFrequencyMap).some(count => count >= 3);
        if (hasFrequentDates) {
          columns.date.push({
            name: column,
            label: titleCase(column.replace(/_/g, " "))
          });
        }
      } else if (type === 'number') {
        const range = getColumnRange(contacts, column);
        if (range) {
          columns.number.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ")),
            range
          });
        }
      } else {
        const values = getColumnValues(contacts, column);
        if (values.length > 0) {
          columns.option.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ")),
            values
          });
        }
      }
    });
    
    return columns;
  }, [contacts]);

  // -------------------- Filter & Sort Logic --------------------
  const filteredContacts = useMemo(() => {
    if (!enhancedContacts) return [];
    
    let filtered = enhancedContacts;

    if (currentStatusFilter !== "all") {
      filtered = filtered.filter((contact) => 
        currentStatusFilter === "active" ? contact.isActive : !contact.isActive
      );
    }

    if (debouncedSearchQuery.trim()) {
      const searchLower = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter((contact) => 
        contact.name?.toLowerCase().includes(searchLower) ||
        contact.email?.toLowerCase().includes(searchLower)
      );
    }

    Object.entries(dynamicFilters).forEach(([column, filter]) => {
      if (!filter) return;

      const type = getColumnType(contacts, column);
      
      if (type === 'date' && filter.startDate && filter.endDate) {
        const start = new Date(filter.startDate);
        const end = new Date(filter.endDate);
        filtered = filtered.filter((contact) => {
          const value = contact[column];
          return value ? (new Date(value) >= start && new Date(value) <= end) : false;
        });
      }
      
      if (type === 'number' && (filter.min !== undefined || filter.max !== undefined)) {
        filtered = filtered.filter((contact) => {
          const value = contact[column];
          if (!value) return false;
          if (filter.min !== undefined && value < filter.min) return false;
          if (filter.max !== undefined && value > filter.max) return false;
          return true;
        });
      }
      
      if (type === 'option' && filter.values && filter.values.length > 0) {
        filtered = filtered.filter((contact) => {
          const value = contact[column];
          return filter.values.includes(value || "None");
        });
      }
    });

    return filtered;
  }, [enhancedContacts, currentStatusFilter, debouncedSearchQuery, dynamicFilters, contacts]);

  const hasActiveFilters = useMemo(() => {
    return Object.values(dynamicFilters).some(filter => {
      if (!filter) return false;
      if (filter.startDate || filter.endDate) return true;
      if (filter.min !== undefined || filter.max !== undefined) return true;
      if (filter.values && filter.values.length > 0) return true;
      return false;
    });
  }, [dynamicFilters]);

  const sortedContacts = useMemo(() => {
    if (!filteredContacts) return [];
    if (!sortConfig.key) return filteredContacts;

    const sorted = [...filteredContacts].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === 'last_activity_date') {
        if (!aValue && !bValue) return 0;
        if (!aValue) return 1;
        if (!bValue) return -1;
        return new Date(bValue) - new Date(aValue);
      }

      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      }
      return 0;
    });

    if (sortConfig.direction === 'ascending') {
      sorted.reverse();
    }
    return sorted;
  }, [filteredContacts, sortConfig]);

  const totalCount = useMemo(() => filteredContacts.length, [filteredContacts]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalCount / ITEMS_PER_PAGE) || 1;
  }, [totalCount]);

  const paginatedContacts = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return sortedContacts.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [sortedContacts, currentPage]);

  // -------------------- Handlers & User Actions --------------------
  const handleDynamicFilterChange = useCallback((column, value) => {
    setDynamicFilters(prev => ({
      ...prev,
      [column]: value
    }));
  }, [setDynamicFilters]);

  const handleResetFilters = useCallback(() => {
    setDynamicFilters({});
  }, [setDynamicFilters]);

  const toggleSection = useCallback((sectionName) => {
    setExpandedSections(prev => {
      const newExpanded = { ...prev, [sectionName]: !prev[sectionName] };
      if (newExpanded[sectionName]) {
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: true }));
        setTimeout(() => {
          setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
        }, 300);
      } else {
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
      }
      return newExpanded;
    });
  }, []);

  const handleViewDetails = useCallback((contact) => {
    // Store current location (including page number) when navigating to detail
    const referrer = {
      pathname: location.pathname,
      search: `?page=${currentPage}` // Explicitly set the page parameter
    };
    
    navigate(`/admin/contacts/${contact.id}`, {
      state: { referrer }
    });
  }, [navigate, location, currentPage]);

  const handlePreviousPage = useCallback(() => {
    const newPage = Math.max(currentPage - 1, 1);
    setCurrentPage(newPage);
    updatePageParam(newPage);
  }, [currentPage, updatePageParam]);

  // This handler needs totalPages, so it's defined after totalPages is calculated
  const handleNextPage = useCallback(() => {
    const newPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(newPage);
    updatePageParam(newPage);
  }, [currentPage, totalPages, updatePageParam]);

  const handlePageSelect = useCallback((pageNum) => {
    setCurrentPage(pageNum);
    updatePageParam(pageNum);
  }, [updatePageParam, currentPage]);

  const handleSearchChange = useCallback((newQuery) => {
    setSearchQuery(newQuery);
    // Don't reset page here - will be handled by the effect
  }, []);

  const handleSuggestionSelect = useCallback((itemId) => {
    const selectedContact = contacts?.find(contact => contact.id === itemId);
    if (selectedContact) {
      // Store current location when navigating to detail
      const referrer = {
        pathname: location.pathname,
        search: `?page=${currentPage}` // Explicitly set the page parameter
      };
      
      navigate(`/admin/contacts/${selectedContact.id}`, {
        state: { referrer }
      });
    } else {
      toast({
        title: 'Contact not found.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [contacts, navigate, toast, location, currentPage]);

  const handleSort = useCallback((key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        return {
          key,
          direction: prevState.direction === 'ascending' ? 'descending' : 'ascending',
        };
      } else {
        return { key, direction: 'ascending' };
      }
    });
  }, [setSortConfig]);

  const handleCreateContact = useCallback(() => {
    setIsCreateModalOpen(true);
  }, []);

  // -------------------- Effects --------------------
  // Sync the URL page parameter with the current page state when URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlPage = params.get('page');
    
    // Only update from URL if this is not a manual navigation
    if (isManualNavigation.current) {
      // Reset the flag after processing
      isManualNavigation.current = false;
      return;
    }
    
    if (urlPage) {
      const parsedPage = parseInt(urlPage, 10);
      if (parsedPage !== currentPage && !isNaN(parsedPage) && parsedPage >= 1) {
        setCurrentPage(parsedPage);
      }
    } else if (currentPage !== 1) {
      // If no page param in URL but we have a current page, update URL
      updatePageParam(currentPage);
    }
  }, [location.search, location.key, currentPage, updatePageParam]); // Add location.key to dependencies

  // Add an effect to initialize the URL on mount
  useEffect(() => {
    // Only run once on first render
    if (!hasInitializedUrl.current) {
      hasInitializedUrl.current = true;
      
      // Set the URL parameter on initial mount if it's not already set
      const params = new URLSearchParams(location.search);
      if (!params.has('page')) {
        updatePageParam(currentPage);
      }
    }
  }, [location.search, updatePageParam, currentPage]);

  // Only reset pagination when filters actually change, not on initial mount
  useEffect(() => {
    // Skip resetting page on initial mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      prevSortConfigRef.current = sortConfig;
      prevSearchQueryRef.current = debouncedSearchQuery;
      prevStatusFilterRef.current = currentStatusFilter;
      prevDynamicFiltersRef.current = dynamicFilters;
      return;
    }

    // Check if any filters have actually changed - don't check filteredContacts length
    const filtersChanged = 
      prevSortConfigRef.current.key !== sortConfig.key ||
      prevSortConfigRef.current.direction !== sortConfig.direction ||
      prevSearchQueryRef.current !== debouncedSearchQuery ||
      prevStatusFilterRef.current !== currentStatusFilter;
    
    // Check if dynamicFilters have changed by comparing with previous values
    const dynamicFiltersChanged = !areDynamicFiltersEqual(prevDynamicFiltersRef.current, dynamicFilters);
    
    // Update refs
    prevSortConfigRef.current = sortConfig;
    prevSearchQueryRef.current = debouncedSearchQuery;
    prevStatusFilterRef.current = currentStatusFilter;
    prevDynamicFiltersRef.current = dynamicFilters;
    
    // Only reset page if filters actually changed (not from back navigation)
    if (filtersChanged || dynamicFiltersChanged) {
      // Use manual navigation to avoid race condition
      isManualNavigation.current = true;
      setCurrentPage(1);
      const params = new URLSearchParams(location.search);
      params.set('page', '1');
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
  }, [sortConfig, debouncedSearchQuery, currentStatusFilter, dynamicFilters, navigate, location.pathname]);

  // Helper function to compare dynamic filters
  const areDynamicFiltersEqual = (prevFilters, currentFilters) => {
    const prevKeys = Object.keys(prevFilters);
    const currentKeys = Object.keys(currentFilters);
    
    // If keys length differs, filters are different
    if (prevKeys.length !== currentKeys.length) {
      return false;
    }
    
    // Check if all keys are the same
    if (!prevKeys.every(key => currentKeys.includes(key))) {
      return false;
    }
    
    // Check if all values are the same
    return prevKeys.every(key => {
      const prev = prevFilters[key];
      const curr = currentFilters[key];
      
      if (!prev && !curr) return true;
      if (!prev || !curr) return false;
      
      // Compare values based on type of filter
      if (prev.values && curr.values) {
        // For option filters, compare arrays
        if (prev.values.length !== curr.values.length) return false;
        return prev.values.every(v => curr.values.includes(v));
      }
      
      // For date filters
      if (prev.startDate !== curr.startDate) return false;
      if (prev.endDate !== curr.endDate) return false;
      
      // For number filters
      if (prev.min !== curr.min) return false;
      if (prev.max !== curr.max) return false;
      
      return true;
    });
  };

  // -------------------- Render --------------------
  if (isLoading) {
    return (
      <Box px={4}>
        <TableSkeleton rowCount={10} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box p={4} color="red.500">
        <Text>Failed to load contacts. Please try again later.</Text>
      </Box>
    );
  }

  return (
    <Box px={4}>
      <PageHeader
        title="Contacts"
        searchPlaceholder="Search contacts..."
        onSearchChange={handleSearchChange}
        searchSuggestions={searchSuggestions}
        searchQuery={searchQuery}
        showSearch={true}
        onSuggestionSelect={handleSuggestionSelect}
        additionalControls={
          <Popover 
            placement="bottom-end" 
            isOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          >
            <PopoverTrigger>
              <Button
                leftIcon={<Icon as={FiFilter} />}
                rightIcon={<Icon as={isFilterOpen ? ChevronUp : ChevronDown} />}
                variant="outline"
                bg="white"
                borderRadius="md"
                borderColor="gray.300"
                color={useColorModeValue("gray.700", "gray.200")}
                _hover={{
                  bg: "gray.50",
                }}
                onClick={() => setIsFilterOpen(true)}
              >
                Filter Contacts {hasActiveFilters && `(${Object.values(dynamicFilters).filter(Boolean).length})`}
              </Button>
            </PopoverTrigger>
            <PopoverContent 
              width="400px" 
              maxH="calc(100vh - var(--chakra-space-48))"
              position="relative"
              boxShadow="lg"
              mt={2}
              display="flex"
              flexDirection="column"
            >
              <PopoverHeader 
                fontWeight="semibold" 
                position="sticky"
                top={0}
                bg="white"
                zIndex={1}
                borderBottomWidth="1px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text>Advanced Filters</Text>
                {isLoading && <Spinner size="sm" ml={2} />}
                <Text fontSize="sm" color="gray.500">
                  {filteredContacts.length} of {contacts.length} contacts
                </Text>
              </PopoverHeader>
              <PopoverBody 
                flex="1" 
                overflowY="auto"
                py={4}
              >
                <VStack spacing={4} align="stretch">
                  {filterableColumns.date && filterableColumns.date.length > 0 && (
                    <Box borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
                      <FormControl mb={4}>
                        <FormLabel fontWeight="medium">Filter Date By</FormLabel>
                        <HStack wrap="wrap" spacing={2}>
                          {filterableColumns.date.map((column) => (
                            <Button
                              key={column.name}
                              size="sm"
                              bg={dynamicFilters[column.name] ? selectedBg : unselectedBg}
                              color={dynamicFilters[column.name] ? selectedTextColor : unselectedTextColor}
                              _hover={{
                                bg: dynamicFilters[column.name] ? selectedBg : buttonHoverBg,
                                color: dynamicFilters[column.name] ? selectedTextColor : 'inherit',
                              }}
                              borderWidth="1px"
                              borderColor="gray.300"
                              borderRadius="md"
                              onClick={() => {
                                if (dynamicFilters[column.name]) {
                                  const newFilters = { ...dynamicFilters };
                                  delete newFilters[column.name];
                                  setDynamicFilters(newFilters);
                                } else {
                                  handleDynamicFilterChange(column.name, {});
                                }
                              }}
                            >
                              {column.label}
                            </Button>
                          ))}
                        </HStack>
                      </FormControl>

                      <VStack spacing={4} align="stretch">
                        {filterableColumns.date.map((column) => (
                          dynamicFilters[column.name] && (
                            <FormControl key={column.name}>
                              <FormLabel fontWeight="medium">Select Range for {column.label}</FormLabel>
                              <Stack direction="row">
                                <Input
                                  type="date"
                                  value={dynamicFilters[column.name]?.startDate || ''}
                                  onChange={(e) => handleDynamicFilterChange(column.name, {
                                    ...dynamicFilters[column.name],
                                    startDate: e.target.value
                                  })}
                                  bg="white"
                                />
                                <Input
                                  type="date"
                                  value={dynamicFilters[column.name]?.endDate || ''}
                                  onChange={(e) => handleDynamicFilterChange(column.name, {
                                    ...dynamicFilters[column.name],
                                    endDate: e.target.value
                                  })}
                                  bg="white"
                                />
                              </Stack>
                            </FormControl>
                          )
                        ))}
                      </VStack>
                    </Box>
                  )}

                  {filterableColumns.option && filterableColumns.option.length > 0 && (
                    filterableColumns.option.map((column) => (
                      <FormControl key={column.name}>
                        <Button
                          variant="unstyled"
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() => toggleSection(column.name)}
                          mb={2}
                          _hover={{ bg: "gray.50" }}
                          p={2}
                          borderRadius="md"
                        >
                          <FormLabel fontWeight="medium" mb={0}>
                            {column.label}
                            {dynamicFilters[column.name]?.values?.length > 0 && (
                              <Text as="span" ml={2} color="gray.500" fontSize="sm">
                                ({dynamicFilters[column.name].values.length} filter{dynamicFilters[column.name].values.length === 1 ? '' : 's'})
                              </Text>
                            )}
                          </FormLabel>
                          <Icon
                            as={expandedSections[column.name] ? ChevronUp : ChevronDown}
                            boxSize={5}
                          />
                        </Button>
                        {expandedSections[column.name] && (
                          loadingSections[column.name] ? (
                            <Box p={4} display="flex" justifyContent="center" alignItems="center">
                              <Spinner />
                            </Box>
                          ) : (
                            <Box 
                              maxH="150px"
                              overflowY="auto"
                              borderWidth="1px"
                              borderColor="gray.200"
                              borderRadius="md"
                              bg="gray.50"
                              _hover={{ borderColor: "gray.300" }}
                              sx={{
                                '&::-webkit-scrollbar': {
                                  width: '8px',
                                  borderRadius: '8px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                  borderRadius: '8px',
                                },
                              }}
                            >
                              <CheckboxGroup 
                                value={dynamicFilters[column.name]?.values || []}
                                onChange={(values) => handleDynamicFilterChange(column.name, { values })}
                              >
                                <Stack spacing={2} p={3}>
                                  {column.values.map((item) => (
                                    <Checkbox key={item.value} value={item.value}>
                                      {item.label}
                                    </Checkbox>
                                  ))}
                                </Stack>
                              </CheckboxGroup>
                            </Box>
                          )
                        )}
                      </FormControl>
                    ))
                  )}
                </VStack>
              </PopoverBody>
              <PopoverFooter
                position="sticky"
                bottom={0}
                bg="white"
                zIndex={1}
                borderTopWidth="1px"
                py={3}
              >
                <HStack justify="flex-end" spacing={2}>
                  <Button size="sm" onClick={handleResetFilters}>Reset</Button>
                  <Button size="sm" colorScheme="blue" onClick={() => setIsFilterOpen(false)}>Apply</Button>
                </HStack>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        }
        searchRightElement={
            <Button
              leftIcon={<Icon as={FiPlus} />}
              colorScheme="blue"
              borderRadius="md"
              onClick={handleCreateContact}
            >
              Create Contact
            </Button>
        }
      />

      <CreateContactModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />

      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
          {paginatedContacts.length === 0 ? (
                <Text textAlign="center" py={8} color="gray.500">
                  No contacts available.
                </Text>
              ) : (
                <ContactsTable
                  contacts={paginatedContacts}
                  onViewDetails={handleViewDetails}
                  onSort={handleSort}
                  sortConfig={sortConfig}
                  searchQuery={debouncedSearchQuery}
                />
              )}
            </Box>
          </SimpleGrid>

      {!isLoading && (
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              onPrevious={handlePreviousPage}
          onNext={handleNextPage}
              disableNext={currentPage >= totalPages}
              disablePrevious={currentPage === 1}
              onPageSelect={handlePageSelect}
            />
      )}
    </Box>
  );
};

export default Contacts;