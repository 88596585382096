// src/views/lysto/Meetings/MeetingAnalysis.js
import React from "react";
import {
  Box,
  VStack,
  Flex,
  Center,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import { useParams } from "react-router-dom";
import useFetchMeetingAnalysis from "../hooks/useFetchMeetingAnalysis";
import MeetingDetail from "./MeetingDetail";

const MeetingAnalysis = () => {
  const { meetingID } = useParams();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const { meetingAnalysis, loading, error } = useFetchMeetingAnalysis(
    accountID,
    meetingID
  );

  // Add debugging for the raw response
  // console.log('Raw meeting analysis:', meetingAnalysis);

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center h="100vh">
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          borderRadius="md"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Error Loading Meeting
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            {error.message || error}
          </AlertDescription>
        </Alert>
      </Center>
    );
  }

  if (!meetingAnalysis) {
    return (
      <Center h="100vh">
        <Alert
          status="warning"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          borderRadius="md"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Meeting Not Found
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            The requested meeting analysis could not be found.
          </AlertDescription>
        </Alert>
      </Center>
    );
  }

  // Extract the eventAnalysis from the response
  const analysisData = meetingAnalysis.eventAnalysis || meetingAnalysis;

  // Add debugging for the extracted data
  // console.log('Extracted analysis data:', analysisData);

  // Normalize the data structure
  const normalizedAnalysis = {
    ...analysisData,
    // Ensure required fields have default values and proper types
    account_id: Number(analysisData.account_id || 0),
    deal_id: Number(analysisData.deal_id || 0),
    attendees: analysisData.attendees || [],
    title: analysisData.title || "Untitled Meeting",
    meeting_link: analysisData.meeting_link || analysisData.location || "",
    recording_url: analysisData.recording_url || "",
    transcript_text: analysisData.transcript_text || "",
    ai_summary: analysisData.ai_summary || "",
    date: analysisData.date || null,
    timezone: analysisData.timezone || "UTC",
    duration: analysisData.duration || "",
  };

  // Add debugging for the normalized data
  // console.log('Normalized analysis:', normalizedAnalysis);

  return (
    <VStack align="stretch" spacing={8} px={4}>
      <Flex>
        <Box flex={1} mr={8}>
          <MeetingDetail meetingAnalysis={normalizedAnalysis} />
        </Box>
      </Flex>
    </VStack>
  );
};

export default MeetingAnalysis;
