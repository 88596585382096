import React, { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { 
  Box, 
  Center, 
  Text,
  Alert,
  AlertIcon,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import DealDetail from "./DealDetail";
import GoBackHeader from "../Shared/GoBackHeader";
import { useFetchDealById } from "../hooks/useFetchDealById";
import PageHeader from "../Shared/PageHeader";
import useFetchDealSlackChannel from "../hooks/useFetchDealSlackChannel";
import useFetchDealActivities from "../hooks/useFetchDealActivities";

const LoadingSkeleton = () => (
  <VStack spacing={6} align="stretch">
    <Box bg="white" p={6} borderRadius="md" boxShadow="sm" borderWidth="1px" borderColor="gray.200">
      <VStack spacing={6} align="stretch">
        <Skeleton height="24px" width="150px" />
        <Skeleton height="60px" />
        <Skeleton height="100px" />
        <Skeleton height="150px" />
      </VStack>
    </Box>
    <Box bg="white" p={6} borderRadius="md" boxShadow="sm" borderWidth="1px" borderColor="gray.200">
      <VStack spacing={6} align="stretch">
        <Skeleton height="200px" />
      </VStack>
    </Box>
  </VStack>
);

const DealDetailsPage = () => {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";

  // Use custom hook to fetch deal with React Query
  const { data: deal, isLoading, isError, error } = useFetchDealById(accountID, dealId);
  
  // Fetch connected Slack channels for the deal
  const { channels: connectedSlackChannels } = useFetchDealSlackChannel(
    accountID, 
    dealId
  );
  
  // Set start date to January 1, 2022
  const startDate = '2022-01-01';
  // Set end date to current date in YYYY-MM-DD format
  const endDate = new Date().toISOString().split('T')[0];
  
  // Fetch activities to check for email activity
  const { data: activities } = useFetchDealActivities(
    accountID, 
    dealId,
    startDate,
    endDate
  );
  
  // Check if there are any email activities
  const hasEmailActivity = Array.isArray(activities) && activities.some(
    activity => activity.activity_type === 'Email'
  );

  // Save current location to session storage for use when returning from email compose
  useEffect(() => {
    sessionStorage.setItem("dealDetailsPreviousLocation", JSON.stringify({
      pathname: location.pathname,
      search: location.search,
      dealId: dealId
    }));
  }, [location.pathname, location.search, dealId]);

  const handleGoBack = () => {
    // Get the referrer state if it exists (we might have stored the previous location)
    const referrer = location.state?.referrer;
    
    if (referrer && referrer.pathname) {
      // Navigate to the stored location, preserving query parameters
      navigate(referrer);
    } else {
      // Fall back to browser history if no referrer
      navigate(-1);
    }
  };

  const noop = () => undefined;

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSkeleton />;
    }

    if (isError) {
      return (
        <Center mt={8}>
          <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Error Loading Deal
            </Text>
            <Text fontSize="sm">
              {error?.message || 'Failed to load deal details. Please try again later.'}
            </Text>
          </Alert>
        </Center>
      );
    }

    if (!deal) {
      return (
        <Center mt={8}>
          <Alert status="warning" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Deal Not Found
            </Text>
            <Text fontSize="sm">
              The requested deal could not be found. It may have been deleted or you may not have permission to view it.
            </Text>
          </Alert>
        </Center>
      );
    }

    return <DealDetail deal={deal} />;
  };

  return (
    <Box px={4}>
      {/* Main Header with Avatar */}
      <PageHeader
        title="Deals"
        segmentOptions={[]}
        selectedSegment=""
        onSegmentChange={noop}
        onSearchChange={noop}
        showSearch={false}
        isDetailView
      />

      {/* Go Back Header with Deal Title */}
      <Box mb={6}>
        <GoBackHeader
          onGoBack={handleGoBack}
          title={deal?.deal_name || "Deal Details"}
          deal={deal}
          connectedSlackChannels={connectedSlackChannels || []}
          hasEmailActivity={hasEmailActivity}
        />
      </Box>

      {/* Deal Detail Component */}
      {renderContent()}
    </Box>
  );
};

export default DealDetailsPage;
