// src/views/lysto/Contacts/ContactDetailsPage.jsx

import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { 
  Box, 
  Center, 
  Text,
  Alert,
  AlertIcon,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import GoBackHeader from "../Shared/GoBackHeader";
import ContactDetail from "./ContactDetail";
import PageHeader from "../Shared/PageHeader";
import EditContactModal from "./EditContactModal";

const LoadingSkeleton = () => (
  <VStack spacing={6} align="stretch">
    <Box bg="white" p={6} borderRadius="md" boxShadow="sm" borderWidth="1px" borderColor="gray.200">
      <VStack spacing={6} align="stretch">
        <Skeleton height="24px" width="150px" />
        <Skeleton height="60px" />
        <Skeleton height="100px" />
        <Skeleton height="150px" />
      </VStack>
    </Box>
    <Box bg="white" p={6} borderRadius="md" boxShadow="sm" borderWidth="1px" borderColor="gray.200">
      <VStack spacing={6} align="stretch">
        <Skeleton height="200px" />
      </VStack>
    </Box>
  </VStack>
);

const ContactDetailsPage = () => {
  const { contactId } = useParams();
  // Convert contactId to a number for consistent query keys
  const numericContactId = Number(contactId);
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const { getAccessToken } = useAuthData();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { data: contact, isLoading, error } = useQuery({
    queryKey: ['contact', accountID, numericContactId],
    queryFn: async () => {
      if (!accountID || !numericContactId) {
        throw new Error('Account ID and Contact ID are required');
      }

      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Use numericContactId in the URL to ensure consistency
      const response = await axios.get(
        `${target}/api/accounts/${accountID}/contacts/${numericContactId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.data) {
        throw new Error(`Contact with ID ${numericContactId} not found`);
      }

      return response.data;
    },
    enabled: Boolean(accountID) && Boolean(numericContactId),
  });

  const handleGoBack = () => {
    // Get the referrer state if it exists (we might have stored the previous location)
    const referrer = location.state?.referrer;
    
    if (referrer && referrer.pathname) {
      // Navigate to the stored location, preserving query parameters
      // Use replace:true to avoid adding to browser history stack
      // Make sure to use the full referrer object to preserve both pathname and search
      navigate({
        pathname: referrer.pathname,
        search: referrer.search
      }, { replace: true });
    } else {
      // Fall back to browser history if no referrer
      navigate(-1);
    }
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const noop = () => undefined;

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSkeleton />;
    }

    if (error) {
      return (
        <Center mt={8}>
          <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Error Loading Contact
            </Text>
            <Text fontSize="sm">
              {typeof error === 'string' ? error : error.message || 'Failed to load contact details. Please try again later.'}
            </Text>
          </Alert>
        </Center>
      );
    }

    if (!contact) {
      return (
        <Center mt={8}>
          <Alert status="warning" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Contact Not Found
            </Text>
            <Text fontSize="sm">
              The requested contact could not be found. It may have been deleted or you may not have permission to view it.
            </Text>
          </Alert>
        </Center>
      );
    }

    return <ContactDetail contact={contact} />;
  };

  return (
    <Box px={4}>
      {/* Main Header with Avatar */}
      <PageHeader
        title="Contacts"
        segmentOptions={[]}
        selectedSegment=""
        onSegmentChange={noop}
        onSearchChange={noop}
        showSearch={false}
        isDetailView
      />

      {/* Go Back Header with Contact Title */}
      <Box mb={2}>
        <GoBackHeader
          onGoBack={handleGoBack}
          title={contact?.name || "Contact Details"}
          contact={contact}
          onEdit={handleOpenEditModal}
        />
      </Box>

      {/* Edit Contact Modal */}
      {contact && (
        <EditContactModal
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          contact={contact}
        />
      )}

      {/* Render the content */}
      {renderContent()}
    </Box>
  );
};

export default ContactDetailsPage;
