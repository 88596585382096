import PropTypes from 'prop-types';
import {
  Box,
  VStack,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { 
  FaDollarSign, 
  FaClipboardList, 
  FaClock,
  FaTag,
  FaFlag,
  FaPercent,
  FaSitemap,
  FaCheckCircle,
  FaTrophy,
  FaCrown,
  FaBuilding,
  FaExpandAlt,
  FaChartLine,
  FaSlack,
} from 'react-icons/fa';
import { useAuthData } from 'auth-context';
import { OverviewTag } from '../Shared/Tags';
import DealSummarySection from './DealSummarySection';
import DealActivity from './DealActivity';
import DealContactsSection from './DealContactsSection';
import DealMetricsSection from './DealMetricsSection';
import { parseISO, format, isAfter, addDays } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import BaseTag from '../Shared/BaseTag';
import { useNavigate } from 'react-router-dom';
import useFetchDealScore from '../hooks/useFetchDealScore';
import useFetchDealSlackChannel from '../hooks/useFetchDealSlackChannel';

const DealDetail = ({ deal }) => {
  const userData = useAuthData();
  const userAccountID = userData?.account_ID;
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Fetch deal score
  const { data: dealScoreData } = useFetchDealScore(
    deal.account_id,
    deal.id
  );

  // Fetch connected Slack channel
  const { channels: connectedSlackChannels, error: slackChannelError } = useFetchDealSlackChannel(
    userAccountID, 
    deal.id
  );
  
  const connectedSlackChannel = !slackChannelError && connectedSlackChannels && connectedSlackChannels.length > 0 
    ? connectedSlackChannels[0] 
    : null;

  // Fetch users to get owner information
  const { data: usersData } = useQuery({
    queryKey: ['users', userAccountID],
    queryFn: async () => {
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      const response = await axios.get(
        `${target}/api/accounts/${userAccountID}/get-users`
      );
      return response.data;
    },
    enabled: !!userAccountID,
  });

  const dealOwner = usersData?.users?.find(user => user.id === deal.user_id);
  
  // Get the user's time zone - moved up before it's used
  const getUserTimeZone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  
  // Updated to show email when name is missing
  const ownerDisplayName = deal.user_id 
    ? (dealOwner 
        ? (dealOwner.name || dealOwner.last_name 
            ? `${dealOwner.name || ''} ${dealOwner.last_name || ''}`.trim()
            : dealOwner.email || 'User')
        : deal.user_email 
            ? deal.user_email
            : (deal.user_name || deal.user_last_name
                ? `${deal.user_name || ''} ${deal.user_last_name || ''}`.trim()
                : 'User'))
    : 'Unassigned';

  const formattedLastActivityDate = deal.last_activity_date 
    ? format(parseISO(deal.last_activity_date), 'MMMM do, yyyy')
    : null;
  
  const formattedCloseDate = deal.close_date
    ? (() => {
        // Parse the date from the backend
        const parsedDate = parseISO(deal.close_date);
        
        // Since the backend stores at midnight UTC which shows as previous day in Eastern time,
        // add a day to the date to show the correct date in user's timezone
        const adjustedDate = new Date(parsedDate);
        adjustedDate.setDate(adjustedDate.getDate() + 1);

        // Format the adjusted date in the user's timezone
        const formatted = format(adjustedDate, 'MMMM do, yyyy');
        return formatted;
      })()
    : null;

  // Format summary updated timestamp if available
  const formattedSummaryUpdatedAt = deal.deal_summary?.updated_at
    ? format(parseISO(deal.deal_summary.updated_at), 'MMM do, yyyy h:mm a')
    : null;

  // Format summary date range if available
  const formattedSummaryDateRange = deal.deal_summary?.start_date && deal.deal_summary?.end_date
    ? `${format(parseISO(deal.deal_summary.start_date), 'MMM do, yyyy')} - ${format(parseISO(deal.deal_summary.end_date), 'MMM do, yyyy')}`
    : null;

  // Calculate next update time based on 8-hour intervals starting at 8AM UTC
  const calculateNextUpdateTime = (baseDate) => {
    // Create a copy of the date to avoid modifying the original
    const date = new Date(baseDate);
    
    // Convert to UTC for calculations
    const currentHourUTC = date.getUTCHours();
    const currentMinutesUTC = date.getUTCMinutes();
    const currentSecondsUTC = date.getUTCSeconds();
    
    // Create a new Date object with the UTC time
    let nextUpdate = new Date(Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    ));
    
    // Determine the next update slot (8AM, 4PM, or 12AM UTC)
    if (currentHourUTC < 8) {
      // Next update is at 8AM UTC today
      nextUpdate.setUTCHours(8, 0, 0, 0);
    } else if (currentHourUTC < 16) {
      // Next update is at 4PM UTC today
      nextUpdate.setUTCHours(16, 0, 0, 0);
    } else {
      // Next update is at 12AM UTC (midnight) tomorrow
      nextUpdate = addDays(nextUpdate, 1);
      nextUpdate.setUTCHours(0, 0, 0, 0);
    }
    
    // If we're exactly at an update time or just past it by a tiny amount,
    // we need to move to the next slot to avoid scheduling an immediate update
    if (
      (currentHourUTC === 8 && currentMinutesUTC === 0 && currentSecondsUTC === 0) ||
      (currentHourUTC === 16 && currentMinutesUTC === 0 && currentSecondsUTC === 0) ||
      (currentHourUTC === 0 && currentMinutesUTC === 0 && currentSecondsUTC === 0)
    ) {
      if (currentHourUTC === 8) {
        nextUpdate.setUTCHours(16, 0, 0, 0);
      } else if (currentHourUTC === 16) {
        nextUpdate = addDays(nextUpdate, 1);
        nextUpdate.setUTCHours(0, 0, 0, 0);
      } else { // currentHourUTC === 0
        nextUpdate.setUTCHours(8, 0, 0, 0);
      }
    }
    
    // Format the time with the user's time zone
    const formattedTime = format(nextUpdate, 'MMM do, yyyy h:mm a');
    const timeZone = getUserTimeZone();
    
    return { 
      nextUpdate,
      nextUpdateTime: formattedTime,
      timeZone
    };
  };

  // Calculate next update time and tooltip message based on activity vs summary dates
  const calculateNextUpdateInfo = () => {
    if (!deal.deal_summary?.updated_at) return { nextUpdateTime: null, tooltipMessage: null };
    
    const lastSummaryUpdate = parseISO(deal.deal_summary.updated_at);
    let nextUpdateInfo = null;
    let tooltipMessage = null;
    
    // Check if there's a more recent activity than the summary update
    if (deal.last_activity_date) {
      const lastActivityDate = parseISO(deal.last_activity_date);
      
      if (isAfter(lastActivityDate, lastSummaryUpdate)) {
        // If there's a more recent activity, calculate next update from that
        nextUpdateInfo = calculateNextUpdateTime(lastActivityDate);
        tooltipMessage = `Next update: ${nextUpdateInfo.nextUpdateTime} (${nextUpdateInfo.timeZone})`;
      } else {
        // If summary has the latest info
        tooltipMessage = "Summary contains the latest information";
      }
    } else {
      // If no activity data, calculate based on summary update
      nextUpdateInfo = calculateNextUpdateTime(lastSummaryUpdate);
      tooltipMessage = `Next update: ${nextUpdateInfo.nextUpdateTime} (${nextUpdateInfo.timeZone})`;
    }
    
    return { 
      nextUpdateTime: nextUpdateInfo ? nextUpdateInfo.nextUpdateTime : null, 
      tooltipMessage 
    };
  };
  
  const { nextUpdateTime, tooltipMessage } = calculateNextUpdateInfo();

  // Check if the user's account ID is 4 (handles both string and number types)
  const shouldShowSections = Number(userAccountID) !== 4;
  
  const handleViewAccountDetails = (accountId) => {
    navigate(`/admin/accounts/${accountId}`);
  };

  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      {/* Modal for likelihood to close details */}
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex align="center">
              <Box mr={2} p={1}>
                <FaChartLine color="teal" size={16} />
              </Box>
              <Text>Win Forecast Details</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {dealScoreData && (
              <VStack align="stretch" spacing={4}>
                <Box>
                  <Text fontWeight="bold" mb={1} fontSize="sm">Forecast Score</Text>
                  <Flex align="center">
                    <Box 
                      w="100%" 
                      h="12px" 
                      bg="gray.200" 
                      borderRadius="full" 
                      mr={3}
                    >
                      <Box 
                        h="100%" 
                        w={`${dealScoreData.score * 100}%`} 
                        bg={(dealScoreData.score * 100) > 70 ? "green.400" : (dealScoreData.score * 100) > 40 ? "orange.400" : "red.400"} 
                        borderRadius="full"
                      />
                    </Box>
                    <Text fontWeight="bold" fontSize="sm">{(dealScoreData.score * 100).toFixed(0)}%</Text>
                  </Flex>
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={1} fontSize="sm">What this means</Text>
                  <Text fontSize="sm" color="gray.600">
                    This score represents the AI&apos;s assessment of how likely this deal is to close successfully, 
                    based on historical data, engagement metrics, and deal characteristics.
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={1} fontSize="sm">Rationale</Text>
                  <Text fontSize="sm">{dealScoreData.rationale || "No rationale provided"}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={1} fontSize="sm">Last Updated</Text>
                  <Text fontSize="sm">{dealScoreData.updated_at ? format(parseISO(dealScoreData.updated_at), 'MMMM do, yyyy') : "Unknown"}</Text>
                </Box>
                {dealScoreData.created_at && dealScoreData.created_at !== dealScoreData.updated_at && (
                  <Box>
                    <Text fontWeight="bold" mb={1} fontSize="sm">First Calculated</Text>
                    <Text fontSize="sm">{format(parseISO(dealScoreData.created_at), 'MMMM do, yyyy')}</Text>
                  </Box>
                )}
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <VStack spacing={6} align="stretch" height="100%">
        {/* Card 1: Overview and Contacts */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <Box>
              <Text fontSize="md" fontWeight="semibold" mb={3}>
                Overview
              </Text>
              <Flex gap={3} flexWrap="wrap">
                <OverviewTag
                  icon={FaCrown}
                  label="Owner"
                  value={ownerDisplayName}
                  colorScheme="pink"
                  deal={deal}
                  fieldToFocus="owner_id"
                />
                {deal.crm_account_id && deal.crm_account_name && (
                  <BaseTag
                    icon={FaBuilding}
                    hoverIcon={FaExpandAlt}
                    onClick={() => handleViewAccountDetails(deal.crm_account_id)}
                    tooltip="View Account Details"
                    colorScheme="blue"
                  >
                    <Text fontSize="sm" fontWeight="medium">
                      <Text as="span" fontWeight="bold">Account: </Text>
                      {deal.crm_account_name || 'Unnamed Account'}
                    </Text>
                  </BaseTag>
                )}
                {connectedSlackChannel && (
                  <BaseTag
                    icon={FaSlack}
                    tooltip="Connected Slack Channel"
                    colorScheme="teal"
                  >
                    <Text fontSize="sm" fontWeight="medium">
                      <Text as="span" fontWeight="bold">Slack: </Text>
                      #{connectedSlackChannel.metadata.name}
                    </Text>
                  </BaseTag>
                )}
                {dealScoreData && (
                  <BaseTag
                    icon={FaChartLine}
                    hoverIcon={FaExpandAlt}
                    onClick={onOpen}
                    tooltip="View Win Forecast Details"
                    colorScheme={(dealScoreData.score * 100) > 70 ? "green" : (dealScoreData.score * 100) > 40 ? "orange" : "red"}
                  >
                    <Text fontSize="sm" fontWeight="medium">
                      <Text as="span" fontWeight="bold">Win forecast: </Text>
                      {(dealScoreData.score * 100).toFixed(0)}%
                    </Text>
                  </BaseTag>
                )}
                {deal.amount && (
                  <OverviewTag
                    icon={FaDollarSign}
                    label="Amount"
                    value={`$${deal.amount}`}
                    colorScheme="purple"
                    deal={deal}
                    fieldToFocus="amount"
                  />
                )}
                {deal.stage_name && (
                  <OverviewTag
                    icon={FaClipboardList}
                    label="Stage"
                    value={deal.stage_name}
                    colorScheme="orange"
                    deal={deal}
                    fieldToFocus="stage_id"
                  />
                )}
                {deal.pipeline_name && (
                  <OverviewTag
                    icon={FaSitemap}
                    label="Pipeline"
                    value={deal.pipeline_name}
                    colorScheme="blue"
                  />
                )}
                {deal.probability && (
                  <OverviewTag
                    icon={FaPercent}
                    label="Probability"
                    value={`${deal.probability}%`}
                    colorScheme="green"
                    deal={deal}
                    fieldToFocus="probability"
                  />
                )}
                {formattedCloseDate && (
                  <OverviewTag
                    icon={FaClock}
                    label="Close Date"
                    value={formattedCloseDate}
                    colorScheme="cyan"
                    deal={deal}
                    fieldToFocus="close_date"
                  />
                )}
                {formattedLastActivityDate && (
                  <OverviewTag
                    icon={FaClock}
                    label="Last Activity"
                    value={formattedLastActivityDate}
                    colorScheme="teal"
                  />
                )}
                {deal.lead_source && (
                  <OverviewTag
                    icon={FaTag}
                    label="Source"
                    value={deal.lead_source}
                    colorScheme="blue"
                    deal={deal}
                    fieldToFocus="lead_source"
                  />
                )}
                {deal.priority && (
                  <OverviewTag
                    icon={FaFlag}
                    label="Priority"
                    value={deal.priority}
                    colorScheme="red"
                    deal={deal}
                    fieldToFocus="priority"
                  />
                )}
                {deal.is_closed && (
                  <OverviewTag
                    icon={FaCheckCircle}
                    label="Status"
                    value="Closed"
                    colorScheme="gray"
                  />
                )}
                {deal.is_won && (
                  <OverviewTag
                    icon={FaTrophy}
                    label="Outcome"
                    value="Won"
                    colorScheme="yellow"
                  />
                )}
              </Flex>
            </Box>

            {/* Deal Metrics Section */}
            {shouldShowSections && (
              <DealMetricsSection 
                accountId={Number(deal.account_id) || 7}
                dealId={Number(deal.id) || 2208567}
                startDate={deal.deal_summary?.start_date || deal.created_at || undefined}
                endDate={new Date().toISOString()}
              />
            )}

            {shouldShowSections && (
              <>
                <DealContactsSection contacts={deal.contacts || []} />
              </>
            )}
          </VStack>
        </Box>

        {/* Card 2: Deal Summary */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <DealSummarySection
            summary={deal.deal_summary?.summary}
            shortSummary={deal.deal_summary?.short_summary}
            updatedAt={formattedSummaryUpdatedAt}
            nextUpdateTime={nextUpdateTime}
            tooltipMessage={tooltipMessage}
            dateRange={formattedSummaryDateRange}
          />
        </Box>

        {/* Card 3: Deal Activity */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <DealActivity 
              accountID={deal.account_id} 
              dealID={deal.id}
              title="Deal Activities"
            />
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

DealDetail.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_id: PropTypes.number.isRequired,
    amount: PropTypes.string,
    stage_name: PropTypes.string,
    pipeline_name: PropTypes.string,
    probability: PropTypes.number,
    close_date: PropTypes.string,
    last_activity_date: PropTypes.string,
    lead_source: PropTypes.string,
    priority: PropTypes.string,
    is_closed: PropTypes.bool,
    is_won: PropTypes.bool,
    user_id: PropTypes.number,
    extra_fields: PropTypes.objectOf(PropTypes.string),
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
      })
    ),
    accounts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      })
    ),
    deal_summary: PropTypes.shape({
      summary: PropTypes.string,
      short_summary: PropTypes.string,
      updated_at: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      created_at: PropTypes.string,
    }),
    deal_score: PropTypes.shape({
      id: PropTypes.number,
      account_id: PropTypes.number,
      deal_id: PropTypes.number,
      score: PropTypes.number,
      rationale: PropTypes.string,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
    }),
  }).isRequired,
};

export default DealDetail;
