import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import { FaClock, FaTasks, FaUser } from 'react-icons/fa';
import { parseISO, formatDistanceToNow } from 'date-fns';
import { OverviewTag } from '../Shared/Tags';
import { useNavigate } from 'react-router-dom';

const OverviewSection = ({ 
  taskType = 'Task', 
  champion = null,
  lastEngagementDate = null,
  dealName = null,
  dealId = null,
  showOwner = true
}) => {
  const navigate = useNavigate();
  
  const formattedDate = lastEngagementDate 
    ? formatDistanceToNow(parseISO(lastEngagementDate), { addSuffix: true })
    : 'N/A';

  const handleDealClick = () => {
    // Navigate to deal details page using react-router
    navigate(`/admin/deals/${dealId}`);
  };

  return (
    <Box>
      <Flex gap={3} flexWrap="wrap">
        {dealName && dealId && (
          <OverviewTag
            label="Deal"
            value={dealName}
            colorScheme="blue"
            onClick={handleDealClick}
          />
        )}
        <OverviewTag
          icon={FaTasks}
          label="Task Type"
          value={taskType || 'Task'}
          colorScheme="green"
        />
        {showOwner && champion && (
          <OverviewTag
            icon={FaUser}
            label="Champion"
            value={champion}
            colorScheme="purple"
          />
        )}
        {lastEngagementDate && (
          <OverviewTag
            icon={FaClock}
            label="Last Activity"
            value={formattedDate}
            colorScheme="orange"
          />
        )}
      </Flex>
    </Box>
  );
};

OverviewSection.propTypes = {
  taskType: PropTypes.string,
  champion: PropTypes.string,
  lastEngagementDate: PropTypes.string,
  dealName: PropTypes.string,
  dealId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showOwner: PropTypes.bool
};

export default OverviewSection; 