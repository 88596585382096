import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Text,
  Icon,
  HStack,
  Tooltip,
  useColorModeValue,
  Button,
  Flex,
  IconButton,
  Tag,
  TagLabel,
  Table,
  Tbody,
  Tr,
  Td,
} from '@chakra-ui/react';
import { 
  CalendarDays, 
  ChevronLeft, 
  ChevronRight, 
  Video,
  ExternalLink,
  MapPin,
} from 'lucide-react';
import { DateTime } from 'luxon';
import HoverBox from '../Shared/HoverBox';
import { AttendeeTag } from './TagComponents';

const UpNextCard = ({ meetings, onViewDetails, currentIndex, setCurrentIndex }) => {
  // No meetings to display, return null
  if (!meetings || meetings.length === 0) return null;

  // State to track the current time for real-time updates
  const [currentTime, setCurrentTime] = useState(DateTime.now());
  
  // Set up an interval to update the current time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(DateTime.now());
    }, 1000);
    
    // Clean up the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  // Process meetings to include only those happening today
  const processedMeetings = useMemo(() => {
    // Filter meetings to only include those happening today
    const todayMeetings = meetings.filter(meeting => {
      const meetingTime = DateTime.fromISO(meeting.startTime);
      // Check if the meeting is happening today
      return meetingTime.hasSame(currentTime, 'day');
    });

    if (todayMeetings.length === 0) return [];

    // Create a set to track which meetings to include
    const meetingsToInclude = new Set();
    
    // Add all meetings with the same start time to the set
    todayMeetings.forEach(meeting => {
      meetingsToInclude.add(meeting.id);
    });
    
    // For each meeting, check if any other meetings overlap with its duration
    todayMeetings.forEach(meeting => {
      const meetingStart = DateTime.fromISO(meeting.startTime);
      const meetingEnd = meeting.endTime ? 
        DateTime.fromISO(meeting.endTime) : 
        meetingStart.plus({ minutes: meeting.duration || 30 });
      
      todayMeetings.forEach(otherMeeting => {
        if (meeting.id === otherMeeting.id) return; // Skip self
        
        const otherStart = DateTime.fromISO(otherMeeting.startTime);
        const otherEnd = otherMeeting.endTime ? 
          DateTime.fromISO(otherMeeting.endTime) : 
          otherStart.plus({ minutes: otherMeeting.duration || 30 });
        
        // Check if meetings overlap:
        // 1. Other meeting starts during this meeting
        // 2. Other meeting ends during this meeting
        // 3. Other meeting completely contains this meeting
        if ((otherStart >= meetingStart && otherStart < meetingEnd) || 
            (otherEnd > meetingStart && otherEnd <= meetingEnd) ||
            (otherStart <= meetingStart && otherEnd >= meetingEnd)) {
          meetingsToInclude.add(otherMeeting.id);
          meetingsToInclude.add(meeting.id);
        }
      });
    });
    
    // Return only the meetings that should be included, sorted by start time
    return todayMeetings
      .filter(meeting => meetingsToInclude.has(meeting.id))
      .sort((a, b) => {
        const timeA = DateTime.fromISO(a.startTime);
        const timeB = DateTime.fromISO(b.startTime);
        return timeA < timeB ? -1 : 1;
      });
  }, [meetings, currentTime]);
  
  // If no valid meetings after processing, return null
  if (processedMeetings.length === 0) return null;
  
  // Ensure currentIndex is valid for the processed meetings array
  const safeCurrentIndex = Math.min(currentIndex, processedMeetings.length - 1);
  if (safeCurrentIndex !== currentIndex && setCurrentIndex) {
    setCurrentIndex(safeCurrentIndex);
  }
  
  const currentMeeting = processedMeetings[safeCurrentIndex];
  
  // Calculate time until meeting
  const getTimeUntilMeeting = () => {
    const meetingTime = DateTime.fromISO(currentMeeting.startTime);
    const diffMinutes = Math.round(meetingTime.diff(currentTime, 'minutes').minutes);
    const diffSeconds = Math.round(meetingTime.diff(currentTime, 'seconds').seconds);
    
    if (diffMinutes < 0) {
      // Meeting has started
      if (diffMinutes > -10) {
        // Less than 10 minutes after start
        return `Started ${Math.abs(diffMinutes)} minute${Math.abs(diffMinutes) === 1 ? '' : 's'} ago`;
      } else {
        // More than 10 minutes after start - don't show card
        return null;
      }
    } else if (diffMinutes === 0) {
      // Less than a minute remaining, show seconds
      if (diffSeconds <= 0) {
        return "Starting now";
      } else {
        return `Starting in ${diffSeconds} second${diffSeconds === 1 ? '' : 's'}`;
      }
    } else if (diffMinutes < 60) {
      return `Starting in ${diffMinutes} minute${diffMinutes === 1 ? '' : 's'}`;
    } else {
      const hours = Math.floor(diffMinutes / 60);
      const mins = diffMinutes % 60;
      return `Starting in ${hours} hour${hours === 1 ? '' : 's'}${mins > 0 ? ` ${mins} min` : ''}`;
    }
  };

  // If meeting is more than 10 minutes past, don't show the card
  const timeStatus = getTimeUntilMeeting();
  if (timeStatus === null) return null;

  // Get meeting link and determine meeting type
  const meetingLink = currentMeeting.location?.includes('http') ? 
    currentMeeting.location : 
    (currentMeeting.hangoutLink || null);
    
  const isZoom = currentMeeting.location?.toLowerCase().includes('zoom') || 
    currentMeeting.description?.toLowerCase().includes('zoom');
  
  const isMeet = currentMeeting.hangoutLink || 
    currentMeeting.location?.toLowerCase().includes('meet.google') ||
    currentMeeting.description?.toLowerCase().includes('google meet');

  const isTeams = currentMeeting.location?.toLowerCase().includes('teams.microsoft') || 
    currentMeeting.description?.toLowerCase().includes('microsoft teams');
    
  // Check if it's a physical location
  const isPhysicalLocation = currentMeeting.location && 
    !meetingLink && 
    !isZoom && 
    !isMeet && 
    !isTeams;
    
  // Create Google Maps link for physical locations
  const googleMapsLink = isPhysicalLocation ? 
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(currentMeeting.location)}` : 
    null;

  const getMeetingType = () => {
    if (isZoom) return 'Zoom';
    if (isMeet) return 'Google Meet';
    if (isTeams) return 'Microsoft Teams';
    if (isPhysicalLocation) return 'Physical Location';
    return 'Meeting';
  };

  // Get meeting status tag color
  const getStatusColor = () => {
    const meetingTime = DateTime.fromISO(currentMeeting.startTime);
    const diffMinutes = Math.round(meetingTime.diff(currentTime, 'minutes').minutes);
    
    if (diffMinutes < 0) {
      // Meeting has started
      return "green";
    } else if (diffMinutes < 5) {
      // Less than 5 minutes away
      return "red";
    } else if (diffMinutes < 30) {
      // Between 5 and 30 minutes away
      return "orange";
    } else {
      // More than 30 minutes away
      return "blue";
    }
  };

  // Colors and styles
  const headerTextColor = useColorModeValue("gray.700", "white");

  return (
    <Box
      mb={6}
      bg="white"
      borderWidth="1px"
      borderRadius="lg"
      borderColor="gray.200"
      boxShadow="sm"
      transition="box-shadow 0.2s"
      _hover={{ boxShadow: "md" }}
      overflow="hidden"
    >
      {/* Card Header with Status Tag */}
      <Flex 
        bg={useColorModeValue("gray.50", "gray.700")} 
        px={10} 
        py={3}
        borderBottomWidth="1px"
        borderBottomColor="gray.200"
        justify="space-between"
        align="center"
      >
        <Flex align="center">
          <Text fontSize={{ sm: "10px", lg: "12px" }} color={headerTextColor} textTransform="uppercase" fontWeight="semibold">Up Next</Text>
          
          {/* Pagination controls if multiple meetings - moved to header */}
          {processedMeetings.length > 1 && (
            <HStack spacing={1} ml={3} display="flex">
              <IconButton
                icon={<ChevronLeft size={14} color="currentColor" />}
                variant="ghost"
                size="xs"
                isDisabled={safeCurrentIndex === 0}
                onClick={() => setCurrentIndex(prev => Math.max(0, prev - 1))}
                aria-label="Previous meeting"
                colorScheme="blue"
              />
              <Text fontSize="xs" color="gray.700" whiteSpace="nowrap" fontWeight="semibold">
                {safeCurrentIndex + 1} of {processedMeetings.length}
              </Text>
              <IconButton
                icon={<ChevronRight size={14} color="currentColor" />}
                variant="ghost"
                size="xs"
                isDisabled={safeCurrentIndex === processedMeetings.length - 1}
                onClick={() => setCurrentIndex(prev => Math.min(processedMeetings.length - 1, prev + 1))}
                aria-label="Next meeting"
                colorScheme="blue"
              />
            </HStack>
          )}
        </Flex>
        
        <Tag size="sm" colorScheme={getStatusColor()} borderRadius="full" px={3}>
          <TagLabel fontWeight="semibold">{timeStatus}</TagLabel>
        </Tag>
      </Flex>

      {/* Card Body - Table Layout */}
      <Box px={10} py={4}>
        <Table variant="simple" width="100%">
          <Tbody>
            <Tr>
              {/* Meeting Title with Calendar Icon - Match index.jsx styling */}
              <Td minWidth="200px" maxWidth="35%" width="auto" py={3} pl={0}>
                <HoverBox
                  icon={CalendarDays}
                  hoverIcon={ExternalLink}
                  onClick={() => onViewDetails(currentMeeting)}
                  tooltipLabel="View Details"
                  width="100%"
                  tooltipProps={{
                    placement: "top",
                    hasArrow: true,
                    gutter: 8
                  }}
                >
                  <Box width="100%" display="flex" alignItems="center">
                    <Text isTruncated fontSize="md" color="gray.800">
                      {currentMeeting.title}
                    </Text>
                  </Box>
                </HoverBox>
              </Td>

              {/* Meeting Time - Add new column */}
              <Td minWidth="150px" maxWidth="20%" width="auto" py={3} pl={2}>
                {(() => {
                  const startTime = DateTime.fromISO(currentMeeting.startTime);
                  const endTime = DateTime.fromISO(currentMeeting.endTime) || 
                    startTime.plus({ minutes: currentMeeting.duration || 30 });
                  
                  return (
                    <Box>
                      <Text fontSize="md" color="gray.700">
                        {startTime.toFormat('h:mm a')} - {endTime.toFormat('h:mm a')}
                      </Text>
                    </Box>
                  );
                })()}
              </Td>

              {/* Attendees - Show individual OverviewTags */}
              <Td minWidth="120px" maxWidth="20%" width="auto" py={3} pl={2}>
                <Flex wrap="wrap" gap={2} align="center" height="100%" justify="center">
                  {currentMeeting.attendees?.length > 0 && (() => {
                    // Sort attendees: organizer first, then by response status
                    const sortedAttendees = [...(currentMeeting.attendees || [])].sort((a, b) => {
                      // Check if either is an organizer
                      const aIsOrganizer = a.organizer || a.email === currentMeeting.organizerEmail;
                      const bIsOrganizer = b.organizer || b.email === currentMeeting.organizerEmail;
                      
                      // Organizer comes first
                      if (aIsOrganizer && !bIsOrganizer) return -1;
                      if (!aIsOrganizer && bIsOrganizer) return 1;
                      
                      // Then sort by response status
                      const getStatusPriority = (status) => {
                        const statusLower = (status || '').toLowerCase();
                        if (statusLower === 'accepted') return 1;
                        if (statusLower === 'tentative') return 2;
                        if (statusLower === 'declined') return 3;
                        return 4; // needsAction/pending
                      };
                      
                      const aPriority = getStatusPriority(a.response);
                      const bPriority = getStatusPriority(b.response);
                      
                      return aPriority - bPriority;
                    });
                    
                    // Display the first 5 sorted attendees
                    return sortedAttendees.slice(0, 5).map((att, index) => {
                      if (!att.email) return null;
                      const response_status = att.response?.toLowerCase() || 'needsAction';
                      const name = att.name || att.email?.split('@')[0];
                      const displayName = name.charAt(0).toUpperCase() + name.slice(1);
                      
                      // Check if this attendee is the organizer
                      const isOrganizer = att.organizer || att.email === currentMeeting.organizerEmail;
                      
                      return (
                        <AttendeeTag
                          key={`${att.email}-${index}`}
                          attendee={{
                            ...att,
                            response_status: response_status,
                            name: displayName,
                            email: att.email,
                            organizer: isOrganizer // Set organizer flag
                          }}
                        />
                      );
                    });
                  })()}
                  {currentMeeting.attendees?.length > 5 && (
                    <Tooltip label={`+${currentMeeting.attendees.length - 5} more attendees`} hasArrow>
                      <Flex 
                        justify="center"
                        align="center"
                        bg="gray.100"
                        color="gray.600"
                        fontWeight="bold"
                        fontSize="xs"
                        w="24px"
                        h="24px"
                        borderRadius="full"
                      >
                        +{currentMeeting.attendees.length - 5}
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
              </Td>

              {/* Join Button or Navigate Button */}
              <Td width="auto" py={3} textAlign="right" pr={0}>
                {meetingLink && (
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => window.open(meetingLink, '_blank')}
                    borderRadius="md"
                    px={3}
                    h="38px"
                    fontWeight="semibold"
                    leftIcon={<Icon as={Video} boxSize={4} />}
                  >
                    Join {getMeetingType()}
                  </Button>
                )}
                
                {isPhysicalLocation && (
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => window.open(googleMapsLink, '_blank')}
                    borderRadius="md"
                    px={3}
                    h="38px"
                    fontWeight="semibold"
                    leftIcon={<Icon as={MapPin} boxSize={4} />}
                  >
                    Navigate to Meeting
                  </Button>
                )}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default UpNextCard; 