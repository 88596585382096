import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to fetch Google integration statuses for Gmail and Calendar
 * @returns {Object} - Contains integration status data, connected users, loading state, and error state
 */
export function useGoogleIntegrations() {
  const { getAccessToken } = useAuthData();
  const { account_ID: accountID, user_ID: currentUserID } = useAuthData();

  const fetchGoogleStatus = async () => {
    if (!accountID) {
      return {
        gmail: false,
        calendar: false,
        connectedUsers: {
          gmail: [],
          calendar: [],
        },
      };
    }

    const accessToken = await getAccessToken();
    const target =
      process.env.REACT_APP_API_BASE_URL ||
      "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

    try {
      const response = await axios.get(
        `${target}/api/accounts/${accountID}/google/status`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Extract Gmail status and connected users from watchInfos
      const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp
      const validGmailIntegrations =
        response.data.gmail_integrations?.filter(
          (info) => info.expiration > currentTime
        ) || [];

      // Get unique connected Gmail users
      const gmailUsers = validGmailIntegrations.map((info) => ({
        userId: info.user_id,
        email: info.email,
        accountId: info.account_id,
        connectedSince:
          info.created_at !== "0001-01-01T00:00:00Z"
            ? info.created_at
            : new Date().toISOString(),
      }));

      const validCalendarIntegrations =
        response.data.calendar_integrations?.filter(
          (info) => new Date(info.expires_at) > new Date()
        ) || [];

      // Get unique connected Calendar users
      const calendarUsers = validCalendarIntegrations.map((conn) => ({
        userId: conn.user_id,
        email: conn.email || "Team member", // Address might be empty in the new format
        accountId: conn.account_id,
        connectedSince: conn.created_at,
      }));

      // Check if current user is connected
      const isCurrentUserGmailConnected = validGmailIntegrations.some(
        (info) => info.user_id === currentUserID
      );
      const isCurrentUserCalendarConnected = validCalendarIntegrations.some(
        (info) => info.user_id === currentUserID
      );

      return {
        gmail: isCurrentUserGmailConnected,
        calendar: isCurrentUserCalendarConnected,
        connectedUsers: {
          gmail: gmailUsers,
          calendar: calendarUsers,
        },
      };
    } catch (error) {
      return {
        gmail: false,
        calendar: false,
        connectedUsers: {
          gmail: [],
          calendar: [],
        },
      };
    }
  };

  const {
    data: integrationStatus = {
      gmail: false,
      calendar: false,
      connectedUsers: {
        gmail: [],
        calendar: [],
      },
    },
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["googleIntegrations", accountID],
    queryFn: fetchGoogleStatus,
    enabled: !!accountID,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 10 * 60 * 1000, // Cache data for 10 minutes
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnMount: false, // Don't refetch when component mounts if data is in cache
  });

  return {
    integrationStatus,
    isLoading,
    isError: !!error,
    refetch,
  };
}

export default useGoogleIntegrations;
