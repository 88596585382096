import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to update a contact.
 * @param {string} accountID - The account ID.
 * @returns {Object} - Contains the mutation function and states.
 */
export function useUpdateContact(accountID) {
  const { getAccessToken } = useAuthData();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, contactData }) => {
      if (!accountID || !contactId) {
        throw new Error("Account ID and Contact ID are required");
      }

      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.put(
        `${target}/api/accounts/${accountID}/contacts/${contactId}`,
        contactData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    },
    onSuccess: (data, variables) => {
      // Ensure that contactId is consistently a number in the cache keys
      const numericContactId = Number(variables.contactId);
      const contactKey = ["contact", accountID, numericContactId];

      // Invalidate related queries
      queryClient.invalidateQueries({ queryKey: ["contacts", accountID] });
      queryClient.invalidateQueries({ queryKey: contactKey });
      queryClient.invalidateQueries({
        queryKey: ["contact-activities", accountID, numericContactId],
      });
      queryClient.invalidateQueries({
        queryKey: ["contact-emails", accountID, numericContactId],
      });
      queryClient.invalidateQueries({
        queryKey: ["contact-calendar-events", accountID, numericContactId],
      });

      // Update the contact data in the cache immediately
      queryClient.setQueryData(contactKey, data);
    },
  });
}

export default useUpdateContact;
