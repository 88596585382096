// src/views/lysto/hooks/useFetchMeetingAnalysis.js
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

const useFetchMeetingAnalysis = (accountID, meetingID) => {
  const { getAccessToken } = useAuthData();
  const [meetingAnalysis, setMeetingAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMeetingData = async () => {
      if (!accountID || !meetingID) {
        setLoading(false);
        setError("Account ID or Meeting ID missing.");
        return;
      }
      try {
        setLoading(true);
        setError(null);
        const accessToken = await getAccessToken();
        const baseUrl =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
            : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${baseUrl}/api/accounts/${accountID}/events/${meetingID}/analysis`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        // Extract eventAnalysis from the response
        const { eventAnalysis } = response.data;
        if (!eventAnalysis) {
          throw new Error("No event analysis data found in response");
        }

        // Ensure attendees is always an array
        if (
          eventAnalysis.attendees &&
          !Array.isArray(eventAnalysis.attendees)
        ) {
          eventAnalysis.attendees = [];
        }

        setMeetingAnalysis(eventAnalysis);
      } catch (err) {
        console.error("Error fetching meeting data:", err);
        setError(
          err.response?.data?.message ||
            err.message ||
            "Failed to fetch meeting data."
        );
        setMeetingAnalysis(null); // Ensure meetingAnalysis is null on error
      } finally {
        setLoading(false);
      }
    };

    fetchMeetingData();
  }, [accountID, meetingID, getAccessToken]);

  return { meetingAnalysis, loading, error };
};

export default useFetchMeetingAnalysis;
