import React from 'react';
import PropTypes from 'prop-types';
import { Box, VStack, Flex, Text } from '@chakra-ui/react';
import { 
  FaEnvelope, 
  FaBriefcase, 
  FaFilter, 
  FaFlag,
  FaPhone,
  FaMapMarker,
  FaLinkedin,
  FaBuilding,
  FaCalendar,
  FaStar,
  FaExpandAlt,
  FaCrown,
} from 'react-icons/fa';
import { ExternalLink } from 'lucide-react';
import BaseTag from '../Shared/BaseTag';
import { OverviewTag } from '../Shared/Tags';
import ContactDealsSection from './ContactDealsSection';
import ContactActivity from './ContactActivity';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const ContactDetail = ({ contact }) => {
  const formattedCreatedDate = contact.created_date 
    ? format(new Date(contact.created_date), 'MMMM do, yyyy')
    : null;
  
  const navigate = useNavigate();

  const handleViewAccountDetails = (accountId) => {
    navigate(`/admin/accounts/${accountId}`);
  };

  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      <VStack spacing={6} align="stretch" height="100%">
        {/* Combined Overview, Deals, and Accounts Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            {/* Overview Section */}
            <Box>
              <Text fontSize="md" fontWeight="semibold" mb={3}>
                Overview
              </Text>
              <Flex gap={3} flexWrap="wrap">
                {contact.priority && (
                  <OverviewTag
                    icon={FaStar}
                    label="Priority"
                    value={contact.priority}
                    colorScheme="red"
                    contact={contact}
                    fieldToFocus="priority"
                  />
                )}
                {contact.owner_name && (
                  <OverviewTag
                    icon={FaCrown}
                    label="Owner"
                    value={contact.owner_name}
                    colorScheme="pink"
                    contact={contact}
                    fieldToFocus="owner_id"
                  />
                )}
                {contact.crm_account_id && contact.crm_account_name && (
                  <BaseTag
                    icon={FaBuilding}
                    hoverIcon={FaExpandAlt}
                    onClick={() => handleViewAccountDetails(contact.crm_account_id)}
                    tooltip="View Account Details"
                    colorScheme="blue"
                  >
                    <Text fontSize="sm" fontWeight="medium">
                      <Text as="span" fontWeight="bold">Account: </Text>
                      {contact.crm_account_name || 'Unnamed Account'}
                    </Text>
                  </BaseTag>
                )}
                {contact.email && (
                  <OverviewTag
                    icon={FaEnvelope}
                    label="Email"
                    value={contact.email}
                    colorScheme="green"
                    contact={contact}
                    fieldToFocus="email"
                  />
                )}
                {contact.title && (
                  <OverviewTag
                    icon={FaBriefcase}
                    label="Title"
                    value={contact.title}
                    colorScheme="purple"
                    contact={contact}
                    fieldToFocus="title"
                  />
                )}
                {contact.source && (
                  <OverviewTag
                    icon={FaFilter}
                    label="Source"
                    value={contact.source}
                    colorScheme="orange"
                  />
                )}
                {contact.status && (
                  <OverviewTag
                    icon={FaFlag}
                    label="Status"
                    value={contact.status}
                    colorScheme="teal"
                  />
                )}
                {contact.phone && (
                  <OverviewTag
                    icon={FaPhone}
                    label="Phone"
                    value={contact.phone}
                    colorScheme="blue"
                    contact={contact}
                    fieldToFocus="phone"
                  />
                )}
                {(contact.city || contact.state || contact.country) && (
                  <OverviewTag
                    icon={FaMapMarker}
                    label="Location"
                    value={[contact.city, contact.state, contact.country].filter(Boolean).join(', ')}
                    colorScheme="yellow"
                    contact={contact}
                    fieldToFocus="city"
                  />
                )}
                {contact.linkedIn && (
                  <a
                    href={contact.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <BaseTag
                      icon={FaLinkedin}
                      hoverIcon={ExternalLink}
                      colorScheme="linkedin"
                      tooltip="Open LinkedIn Profile"
                    >
                      <Text fontSize="sm" fontWeight="medium">
                        LinkedIn
                      </Text>
                    </BaseTag>
                  </a>
                )}
                {contact.department && (
                  <OverviewTag
                    icon={FaBuilding}
                    label="Department"
                    value={contact.department}
                    colorScheme="cyan"
                    contact={contact}
                    fieldToFocus="department"
                  />
                )}
                {formattedCreatedDate && (
                  <OverviewTag
                    icon={FaCalendar}
                    label="Created"
                    value={formattedCreatedDate}
                    colorScheme="gray"
                  />
                )}
              </Flex>
            </Box>
            <ContactDealsSection deals={contact.deals || []} associatedDeals={contact.associated_deals || []} />
          </VStack>
        </Box>

        {/* Activity Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <ContactActivity accountId={contact.account_id} contactId={contact.id} />
        </Box>
      </VStack>
    </Box>
  );
};

ContactDetail.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_id: PropTypes.number.isRequired,
    crm_account_id: PropTypes.number,
    crm_account_name: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    department: PropTypes.string,
    type: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
    priority: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    linkedIn: PropTypes.string,
    deals: PropTypes.array,
    associated_deals: PropTypes.array,
    accounts: PropTypes.array,
    created_date: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default ContactDetail;