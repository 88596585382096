// MeetingDisplay.jsx
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  VStack,
  Text,
  useColorModeValue,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import { ActivityPropType } from './types';
import { Video, ExternalLink, ArrowRight } from 'lucide-react';
import BaseTag from '../Shared/BaseTag';

const formatDateAsCalendar = (timeString) => {
  // Parse the date part manually, ignoring the trailing 'Z'
  const [datePart] = timeString.split('T');
  const [year, month, day] = datePart.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  const weekday = date.toLocaleDateString('en-US', { weekday: 'long' });
  const monthShort = date.toLocaleDateString('en-US', { month: 'short' });
  return `${weekday} ${monthShort} ${day}, ${year}`;
};

const formatTimeAsCalendar = (timeString, timezone) => {
  // Create a proper Date object from the ISO string
  const date = new Date(timeString);
  
  // If the timezone is UTC, convert to user's local timezone
  if (timezone === 'UTC') {
    // Use browser's date formatting with user's local timezone
    const formatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short'
    });
    return formatter.format(date);
  }
  
  // For non-UTC timezones, use the existing behavior
  // Remove trailing 'Z' if present and parse time components
  const timePart = timeString.split('T')[1].replace('Z', '');
  const [hours, minutes] = timePart.split(':').map(Number);
  const isPM = hours >= 12;
  const hour12 = hours % 12 || 12;
  const minuteStr = minutes.toString().padStart(2, '0');
  let formattedTime = `${hour12}:${minuteStr} ${isPM ? 'PM' : 'AM'}`;

  if (timezone) {
    if (timezone.includes('New_York') || timezone.includes('Eastern')) {
      formattedTime += ' ET';
    } else if (timezone.includes('Los_Angeles') || timezone.includes('Pacific')) {
      formattedTime += ' PT';
    } else if (timezone.includes('Chicago') || timezone.includes('Central')) {
      formattedTime += ' CT';
    } else if (timezone.includes('Denver') || timezone.includes('Mountain')) {
      formattedTime += ' MT';
    } else if (timezone.includes('/')) {
      const cityPart = timezone.split('/')[1].replace('_', ' ');
      formattedTime += ` (${cityPart})`;
    }
  }
  return formattedTime;
};

const MeetingDisplay = ({ activity }) => {
  const navigate = useNavigate();

  // Add null check for activity_data with informative fallback
  if (!activity?.activity_data?.data) {
    return (
      <Box p={4} bg="gray.50" borderRadius="md" borderWidth="1px" borderColor="gray.200">
        <Text color="gray.500">Meeting data not available</Text>
      </Box>
    );
  }

  const meetingData = activity.activity_data.data;
  
  // Format date using the start_time (still keeping date part as it is)
  const formattedDate = useMemo(() => {
    if (!meetingData.start_time) return 'N/A';
    return formatDateAsCalendar(meetingData.start_time);
  }, [meetingData.start_time]);

  // Format time directly from start_time (ignoring UTC)
  const formattedTime = useMemo(() => {
    if (!meetingData.start_time) return 'N/A';
    return formatTimeAsCalendar(meetingData.start_time, meetingData.timezone);
  }, [meetingData.start_time, meetingData.timezone]);

  // Format the meeting time range in a consistent way, directly treating the times as timezone specified
  const formattedTimeRange = useMemo(() => {
    if (!meetingData.start_time || !meetingData.end_time) return 'N/A';
    const startFormatted = formatTimeAsCalendar(meetingData.start_time, meetingData.timezone);
    const endFormatted = formatTimeAsCalendar(meetingData.end_time, meetingData.timezone);
    
    // Don't display UTC as the timezone name if we've already converted to local time
    let timezoneName = '';
    if (meetingData.timezone && meetingData.timezone !== 'UTC') {
      timezoneName = meetingData.timezone.includes('/')
        ? meetingData.timezone.split('/')[1].replace('_', ' ')
        : meetingData.timezone;
    }
    
    return `${startFormatted} - ${endFormatted}${timezoneName ? ' ' + timezoneName : ''}`;
  }, [meetingData.start_time, meetingData.end_time, meetingData.timezone]);

  // Deduplicate and sort participants
  const participants = useMemo(() => {
    const uniqueParticipants = Array.from(new Set(
      meetingData.participants.map(p => p.email)
    )).sort();
    return uniqueParticipants;
  }, [meetingData.participants]);

  // Style variables
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const labelColor = useColorModeValue('gray.600', 'gray.400');
  const contentBgColor = useColorModeValue('white', 'gray.800');
  const dateColor = useColorModeValue('gray.500', 'gray.400');
  const tagBg = useColorModeValue('white', 'gray.800');
  const tagColor = useColorModeValue('black', 'white');
  const tagHoverColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <Box p={4} bg={bgColor} borderRadius="md" borderWidth="1px" borderColor={borderColor}>
      <VStack align="stretch" spacing={4}>
        {/* Meeting Header */}
        <VStack align="stretch" spacing={2}>
          {/* Title and Date */}
          <HStack align="center" spacing={2}>
            <Text fontWeight="semibold">
              {activity.title || 'Untitled Meeting'}
            </Text>
            <Spacer />
            <Text fontSize="sm" color={dateColor}>
              {formattedDate} at {formattedTime}
            </Text>
          </HStack>

          {/* Organizer */}
          <Text>
            <Text as="span" fontWeight="semibold" color={labelColor}>
              Organizer:
            </Text>{' '}
            {(() => {
              if (meetingData.organizer_display_name && meetingData.organizer_display_name.trim() !== "") {
                const name = meetingData.organizer_display_name.trim();
                return name.charAt(0).toUpperCase() + name.slice(1);
              }
              if (meetingData.organizer_email && meetingData.organizer_email.trim() !== "") {
                const email = meetingData.organizer_email.trim();
                const localPart = email.split('@')[0];
                return localPart.charAt(0).toUpperCase() + localPart.slice(1);
              }
              if (activity.user_email && activity.user_email.trim() !== "") {
                const email = activity.user_email.trim();
                const localPart = email.split('@')[0];
                return localPart.charAt(0).toUpperCase() + localPart.slice(1);
              }
              return 'Unknown Organizer';
            })()}
          </Text>

          {/* Time */}
          <Text>
            <Text as="span" fontWeight="semibold" color={labelColor}>
              Time:
            </Text>{' '}
            {formattedTimeRange}
          </Text>

          {/* Location */}
          {meetingData.location && (
            <Text>
              <Text as="span" fontWeight="semibold" color={labelColor}>
                Location:
              </Text>{' '}
              {meetingData.location}
            </Text>
          )}

          {/* Participants Section */}
          <Box>
            <Text>
              <Text as="span" fontWeight="semibold" color={labelColor}>
                Participants ({participants.length}):
              </Text>{' '}
              {participants.join(', ')}
            </Text>
          </Box>

          {/* Meeting Controls */}
          <HStack spacing={4} pt={1}>
            {meetingData.is_recorded && meetingData.meeting_url && (
              <a 
                href={meetingData.meeting_url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
                role="button"
              >
                <BaseTag
                  icon={Video}
                  hoverIcon={ExternalLink}
                  colorScheme="gray"
                  tooltip="View Recording"
                  bg={tagBg}
                  color={tagColor}
                  _hover={{
                    color: tagHoverColor,
                  }}
                >
                  <Text fontSize="sm" fontWeight="medium">
                    Recording
                  </Text>
                </BaseTag>
              </a>
            )}
            {activity.activity_id && (
              <BaseTag
                icon={ArrowRight}
                colorScheme="gray"
                tooltip="View Meeting Details"
                onClick={() => navigate(`/admin/meetings/${activity.activity_id}`)}
                bg={tagBg}
                color={tagColor}
                cursor="pointer"
                _hover={{
                  color: tagHoverColor,
                  bg: 'gray.100',
                  transform: 'translateY(-1px)',
                  transition: 'all 0.2s ease-in-out'
                }}
              >
                <Text fontSize="sm" fontWeight="medium">
                  View Details
                </Text>
              </BaseTag>
            )}
          </HStack>
        </VStack>

        {/* Notes Section */}
        {activity.description && (
          <Box 
            p={4}
            borderRadius="md"
            borderWidth="1px"
            borderColor={borderColor}
            bg={contentBgColor}
          >
            <Text fontWeight="semibold" color={labelColor} mb={3}>
              Notes:
            </Text>
            <Box 
              dangerouslySetInnerHTML={{ __html: activity.description }}
              sx={{
                'p, div': {
                  marginBottom: '0.5rem',
                  '&:last-child': {
                    marginBottom: 0
                  }
                },
                'a': {
                  color: 'blue.500',
                  textDecoration: 'underline'
                }
              }}
            />
          </Box>
        )}
      </VStack>
    </Box>
  );
};

MeetingDisplay.propTypes = {
  activity: ActivityPropType,
};

export default MeetingDisplay;