// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Spark/index.jsx
import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  SimpleGrid,
  Alert,
  AlertIcon,
  AlertDescription,
  useToast,
  Skeleton,
  SkeletonText,
  HStack,
  VStack,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useColorModeValue,
  Tooltip,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FiUpload } from 'react-icons/fi';
import { useAuthData } from 'auth-context';
import PageHeader from '../Shared/PageHeader';
import useFetchSparks from '../hooks/useFetchSparks';
import useCreateDealFromSpark from '../hooks/useCreateDealFromSpark';
import SparkCard from './SparkCard';
import PaginationControls from '../Shared/PaginationControls';
import { useFilterState } from '../hooks/useFilterState';
import { useDebounce } from 'use-debounce';
import { usePipelines } from '../hooks/usePipelines';

// Define constants for pagination
const ITEMS_PER_PAGE = 2;  // Number of items to display per page

const PageHeaderSkeleton = () => (
  <Box w="100%" mb={6}>
    {/* Header */}
    <Flex
      flexDirection="row"
      mb="4"
      align="center"
      justify="space-between"
      w="100%"
    >
      <Skeleton height="36px" width="120px" />
      
      {/* Profile Skeleton */}
      <HStack spacing={3} p={2}>
        <Skeleton height="32px" width="32px" borderRadius="3xl" />
        <Skeleton height="20px" width="120px" display={{ base: "none", md: "block" }} />
      </HStack>
    </Flex>

    {/* Controls Container */}
    <Flex
      direction={{ base: "column", md: "row" }}
      gap={{ base: 3, md: 6 }}
      justify="space-between"
      align={{ base: "stretch", md: "center" }}
      w="100%"
      mt={{ base: 2, md: 0 }}
    >
      {/* Segment Controls Skeleton */}
      <HStack spacing={0}>
        <Skeleton height="40px" width="120px" borderRadius="md" />
        <Skeleton height="40px" width="120px" borderRadius="md" ml="-1px" />
      </HStack>
      
      {/* Additional Controls Skeleton */}
      <HStack spacing={4} justify="flex-end">
        <Skeleton height="40px" width="150px" borderRadius="md" />
        {/* Import Button Skeleton */}
        <Skeleton height="40px" width="100px" borderRadius="md" />
      </HStack>
    </Flex>
  </Box>
);

const SparkCardSkeleton = () => (
  <Box
    bg="white"
    p={6}
    borderRadius="md"
    boxShadow="sm"
    borderWidth="1px"
    borderColor="gray.200"
    height="100%"
  >
    <VStack spacing={4} align="stretch">
      {/* Header */}
      <HStack justify="space-between">
        <Skeleton height="24px" width="200px" />
        <HStack spacing={2}>
          <Skeleton height="32px" width="70px" />
          <Skeleton height="32px" width="90px" />
        </HStack>
      </HStack>
      
      {/* Subject line */}
      <Skeleton height="20px" width="80%" />
      
      {/* Tags */}
      <HStack spacing={4}>
        <Skeleton height="24px" width="120px" borderRadius="full" />
        <Skeleton height="24px" width="150px" borderRadius="full" />
        <Skeleton height="24px" width="130px" borderRadius="full" />
      </HStack>
      
      {/* AI Recommendation */}
      <Box>
        <Skeleton height="20px" width="150px" mb={2} />
        <SkeletonText noOfLines={2} spacing={2} />
      </Box>
      
      {/* Previous Messages */}
      <Box>
        <Skeleton height="20px" width="150px" mb={2} />
        <VStack spacing={3} align="stretch">
          <SkeletonText noOfLines={3} spacing={2} />
          <SkeletonText noOfLines={3} spacing={2} />
          <SkeletonText noOfLines={3} spacing={2} />
        </VStack>
      </Box>
    </VStack>
  </Box>
);

const Spark = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const toast = useToast();

  // State for filtering and pagination
  const [currentFilter, setCurrentFilter] = useFilterState("spark-filter", "recommended");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useFilterState("spark-search-query", "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const [selectedPipeline, setSelectedPipeline] = useFilterState("spark-pipeline", null);
  const [initialLoad, setInitialLoad] = useState(true);

  // Add state to track if user has explicitly selected "All Pipelines"
  const [userSelectedAllPipelines, setUserSelectedAllPipelines] = useState(false);

  // State for import modal
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  // Fetch pipelines
  const { 
    pipelines, 
    isPipelinesLoading 
  } = usePipelines(accountID, selectedPipeline);

  // After pipelines are loaded, select the first pipeline if available and none is selected
  useEffect(() => {
    if (!isPipelinesLoading && pipelines?.length > 0 && selectedPipeline === null && initialLoad && !userSelectedAllPipelines) {
      // If there are pipelines and none is selected, select the first one for better initial experience
      setSelectedPipeline(pipelines[0].id);
      setInitialLoad(false);
    }
  }, [pipelines, isPipelinesLoading, selectedPipeline, initialLoad, userSelectedAllPipelines]);

  // Deal creation mutation
  const createDealMutation = useCreateDealFromSpark();

  // Fetch sparks with the updated hook that handles search
  const { 
    sparks, 
    loading, 
    error, 
    totalCount,
    hasReachedEnd,
    hasMore,
    handleIgnoreSpark,
    refetch
  } = useFetchSparks(
    accountID, 
    userID, 
    currentPage, 
    ITEMS_PER_PAGE,
    currentFilter,
    debouncedSearchQuery,
    selectedPipeline
  );

  // Retry fetching when pipelines are loaded on initial load
  useEffect(() => {
    if (!isPipelinesLoading && selectedPipeline !== null && error) {
      refetch();
    }
  }, [selectedPipeline, isPipelinesLoading, error, refetch]);

  // Reset page when filter, search query, or pipeline changes
  useEffect(() => {
    setCurrentPage(1);
  }, [currentFilter, debouncedSearchQuery, selectedPipeline]);

  // Calculate total pages based on the API's reported total count
  const totalPages = useMemo(() => {
    const pages = Math.ceil(totalCount / ITEMS_PER_PAGE) || 1;
    return pages;
  }, [totalCount, ITEMS_PER_PAGE]);

  // Scroll to top when page changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Handle pagination
  const handleNextPage = () => {
    if (currentPage >= totalPages || !hasMore) return;
    setCurrentPage(prev => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage <= 1) return;
    setCurrentPage(prev => prev - 1);
  };

  const handlePageSelect = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  // Generate search suggestions based on recipients
  const searchSuggestions = useMemo(() => {
    if (!debouncedSearchQuery || !sparks) return [];
    
    const suggestions = new Set();
    const searchLower = debouncedSearchQuery.toLowerCase();
    
    sparks.forEach(spark => {
      spark.activities?.forEach(activity => {
        if (!activity.ActivityData) return;
        
        try {
          const activityData = typeof activity.ActivityData === 'string' 
            ? JSON.parse(activity.ActivityData) 
            : activity.ActivityData;

          const recipients = activityData.data?.recipients || [];
          recipients.forEach(recipient => {
            if (recipient.name?.toLowerCase().includes(searchLower)) {
              suggestions.add(JSON.stringify({ 
                id: `${spark.crm_account_id}-${recipient.email}`,
                label: `${recipient.name} (${recipient.email})`
              }));
            } else if (recipient.email?.toLowerCase().includes(searchLower)) {
              suggestions.add(JSON.stringify({ 
                id: `${spark.crm_account_id}-${recipient.email}`,
                label: `${recipient.email} (${recipient.name || 'No name'})`
              }));
            }
          });
        } catch (e) {
          console.error('Error parsing activity data:', e);
        }
      });

      if (spark.crm_domain?.toLowerCase().includes(searchLower)) {
        suggestions.add(JSON.stringify({ 
          id: `domain-${spark.crm_account_id}`,
          label: `Domain: ${spark.crm_domain}`
        }));
      }
      if (spark.reason?.toLowerCase().includes(searchLower)) {
        suggestions.add(JSON.stringify({ 
          id: `reason-${spark.crm_account_id}`,
          label: `Reason: ${spark.reason}`
        }));
      }
    });

    return Array.from(suggestions).map(s => JSON.parse(s));
  }, [sparks, debouncedSearchQuery]);

  // Segment options for the filter
  const segmentOptions = [
    { label: "Recommended", value: "recommended" },
    { label: "Recent", value: "all" },
  ];

  // Handle segment change
  const handleSegmentChange = (newSegment) => {
    setCurrentFilter(newSegment);
    setCurrentPage(1);
  };

  // Handle search change
  const handleSearchChange = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
    // Page reset is handled by the useEffect
  };

  // Handle deal creation
  const handleAddDeal = async (formData, sparkData) => {
    try {
      await createDealMutation.mutateAsync({
        accountId: accountID,
        userId: userID,
        formData: {
          ...formData,
          accountName: formData.accountName || '',
          accountDomain: formData.accountDomain || '',
          accountDescription: formData.accountDescription || '',
          industry: formData.industry || '',
          city: formData.city || '',
          state: formData.state || '',
          country: formData.country || '',
          address: formData.address || '',
          linkedIn: formData.linkedIn || '',
          contacts: formData.contacts || [],
          dealName: formData.dealName || '',
          dealSource: formData.dealSource || '',
          stage: formData.stage || 'new_lead',
          dealDescription: formData.dealDescription || '',
          dealPriority: formData.dealPriority || 'medium',
          pipeline_id: selectedPipeline || (sparkData.pipeline_id ? parseInt(sparkData.pipeline_id, 10) : null),
        },
        sparkData: {
          ...sparkData,
          crm_account_id: parseInt(sparkData.crm_account_id, 10) || null,
          pipeline_id: selectedPipeline || (sparkData.pipeline_id ? parseInt(sparkData.pipeline_id, 10) : null),
        }
      });

    } catch (error) {
      console.error('Error creating deal:', error);
      toast({
        title: "Error creating deal",
        description: error.message || "An unexpected error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Precompute color values for better performance
  const bgColorValue = useColorModeValue("white", "navy.900");
  const borderColorValue = useColorModeValue("gray.300", "gray.500");
  const hoverBgValue = useColorModeValue("gray.50", "gray.400");
  const textColorValue = useColorModeValue("gray.700", "white");
  const disabledColorValue = useColorModeValue("gray.400", "gray.500");

  // Pipeline dropdown component
  const PipelineDropdown = useMemo(() => {
    if (isPipelinesLoading) {
      return (
        <Skeleton height="40px" width="150px" borderRadius="md" />
      );
    }

    const pipelineOptions = pipelines || [];
    
    // If there are no pipelines, show a disabled button
    if (pipelineOptions.length === 0) {
      return (
        <Tooltip label="No pipelines available for this account" placement="top">
          <Box as="span" display="inline-block">
            <Button
              rightIcon={<ChevronDownIcon />}
              bg={bgColorValue}
              borderColor={borderColorValue}
              borderWidth="1px"
              color={disabledColorValue}
              variant="outline"
              borderRadius="md"
              isDisabled
            >
              No Pipelines
            </Button>
          </Box>
        </Tooltip>
      );
    }
    
    const selectedPipelineObj = pipelineOptions.find(p => p.id === selectedPipeline);

    return (
      <Tooltip label="Filter sparks by pipeline" placement="top">
        <Box as="span" display="inline-block">
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              bg={bgColorValue}
              borderColor={borderColorValue}
              borderWidth="1px"
              _hover={{ bg: hoverBgValue }}
              _active={{ bg: hoverBgValue }}
              color={textColorValue}
              variant="outline"
              borderRadius="md"
            >
              {selectedPipelineObj ? selectedPipelineObj.label : 'All Pipelines'}
            </MenuButton>
            <MenuList>
              <MenuItem
                key="all-pipelines"
                onClick={() => {
                  setCurrentPage(1);
                  setSelectedPipeline(null);
                  setUserSelectedAllPipelines(true);
                }}
              >
                All Pipelines
              </MenuItem>
              {pipelineOptions.map((pipeline) => (
                <MenuItem
                  key={pipeline.id}
                  onClick={() => {
                    setCurrentPage(1);
                    setSelectedPipeline(pipeline.id);
                  }}
                >
                  {pipeline.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
      </Tooltip>
    );
  }, [pipelines, selectedPipeline, isPipelinesLoading, bgColorValue, borderColorValue, disabledColorValue, hoverBgValue, textColorValue]);

  // Handler for opening the file dialog
  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handler for file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Here you would process the file - for now just show a success message
      toast({
        title: "File received",
        description: `${file.name} has been uploaded. Processing...`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsImportModalOpen(false);
    }
  };

  // Handler for drag and drop
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      
      // Here you would process the file - for now just show a success message
      toast({
        title: "File received",
        description: `${file.name} has been uploaded. Processing...`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsImportModalOpen(false);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  // Import modal component
  const ImportModal = () => (
    <Modal isOpen={isImportModalOpen} onClose={() => setIsImportModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            p={10}
            borderWidth="2px"
            borderRadius="md"
            borderStyle="dashed"
            borderColor="gray.300"
            textAlign="center"
            cursor="pointer"
            _hover={{ borderColor: "blue.500" }}
            onClick={handleFileInputClick}
          >
            <VStack spacing={4}>
              <Icon as={FiUpload} boxSize={12} color="gray.400" />
              <Text fontWeight="medium">
                Drag and drop a spreadsheet file here, or click to select
              </Text>
              <Text fontSize="sm" color="gray.500">
                Supported formats: .csv, .xlsx, .xls
              </Text>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".csv,.xlsx,.xls"
                onChange={handleFileChange}
              />
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleFileInputClick}>
            Select File
          </Button>
          <Button variant="ghost" onClick={() => setIsImportModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  // Show loading skeleton if no data is available yet
  if (loading && sparks.length === 0) {
    return (
      <Box px={4}>
        <PageHeaderSkeleton />
        <SimpleGrid columns={{ base: 1, md: 1, lg: 2, xl: 2 }} spacing={6}>
          <SparkCardSkeleton />
          <SparkCardSkeleton />
        </SimpleGrid>
        
        {/* Import Modal - still needed while loading */}
        <ImportModal />
      </Box>
    );
  }

  // Show error message if there's an error, but not for initial load with no pipeline selected
  if (error && !(initialLoad && selectedPipeline === null)) {
    return (
      <Box px={6}>
        <PageHeader
          title="Spark"
          segmentOptions={segmentOptions}
          selectedSegment={currentFilter}
          onSegmentChange={handleSegmentChange}
          searchPlaceholder="Search sparks..."
          onSearchChange={handleSearchChange}
          searchQuery={searchQuery}
          searchSuggestions={searchSuggestions}
          showSearch={true}
          additionalControls={
            <HStack spacing={4}>
              {PipelineDropdown}
            </HStack>
          }
          searchRightElement={
            <Button
              leftIcon={<Icon as={FiUpload} />}
              colorScheme="blue"
              borderRadius="md"
              onClick={() => setIsImportModalOpen(true)}
            >
              Import
            </Button>
          }
        />
        
        {/* Import Modal */}
        <ImportModal />
        
        <Alert status="error" mt={4}>
          <AlertIcon />
          <VStack align="stretch" w="100%">
            <AlertDescription>
              {error}
            </AlertDescription>
            {selectedPipeline === null && pipelines?.length > 0 && (
              <Box mt={2}>
                <Text>Try selecting a specific pipeline instead of &quot;All Pipelines&quot;.</Text>
              </Box>
            )}
          </VStack>
        </Alert>
      </Box>
    );
  }

  // Render message when no sparks are found
  const renderNoSparksMessage = () => {
    // If we're loading, don't show no results message
    if (loading && sparks.length === 0) {
      return (
        <VStack spacing={4} my={8} align="center">
          <Skeleton height="50px" width="80%" borderRadius="md" />
          <Skeleton height="50px" width="60%" borderRadius="md" />
        </VStack>
      );
    }
    
    // If we're on page 1 and there's no data, it means there are no sparks for this pipeline
    // Only consider hasReachedEnd flag if we're on a page greater than 1
    const noDataForPipeline = currentPage === 1 && sparks.length === 0;
    const reachedEndOfData = currentPage > 1 && hasReachedEnd && sparks.length === 0;
    
    // Different message based on the current state
    const message = reachedEndOfData 
      ? "You've reached the end of available deals."
      : `No potential deals found matching your criteria${selectedPipeline ? ' for this pipeline' : ''}.`;
      
    return (
      <Alert status={reachedEndOfData ? "warning" : "info"} borderRadius="md">
        <AlertIcon />
        <VStack align="stretch" w="100%">
          <AlertDescription>
            {message}
          </AlertDescription>
          {selectedPipeline === null && pipelines?.length > 0 && noDataForPipeline && (
            <Box mt={2}>
              <Text>Try selecting a specific pipeline to see deals.</Text>
            </Box>
          )}
        </VStack>
      </Alert>
    );
  };

  return (
    <Box px={4} pb={24} transition="all 0.2s">
      <PageHeader
        title="Spark"
        segmentOptions={segmentOptions}
        selectedSegment={currentFilter}
        onSegmentChange={handleSegmentChange}
        searchPlaceholder="Search sparks..."
        onSearchChange={handleSearchChange}
        searchQuery={searchQuery}
        searchSuggestions={searchSuggestions}
        showSearch={true}
        additionalControls={
          <HStack spacing={4}>
            {PipelineDropdown}
          </HStack>
        }
        searchRightElement={
          <Button
            leftIcon={<Icon as={FiUpload} />}
            colorScheme="blue"
            borderRadius="md"
            onClick={() => setIsImportModalOpen(true)}
          >
            Import
          </Button>
        }
      />

      {/* Import Modal */}
      <ImportModal />

      {(sparks.length === 0) ? (
        renderNoSparksMessage()
      ) : (
        <SimpleGrid 
          columns={{ base: 1, md: 1, lg: 2, xl: 2 }} 
          spacing={6}
        >
          {sparks.map((spark, index) => {
            // Create a deep copy of the spark object to safely modify nested properties
            const processedSpark = {
              ...spark,
              recipients: spark.recipients || [],
              activities: spark.activities?.map(activity => {
                if (!activity.classification) return activity;
                
                return {
                  ...activity,
                  classification: {
                    ...activity.classification,
                    // Ensure entities_involved is always an array, even if it's null in the API response
                    entities_involved: activity.classification.entities_involved || []
                  }
                };
              }) || []
            };
            
            return (
              <SparkCard
                key={`${currentPage}-${index}`}
                spark={processedSpark}
                onAddDeal={handleAddDeal}
                onIgnore={handleIgnoreSpark}
                isCreatingDeal={createDealMutation.isLoading}
                currentPage={currentPage}
                isPageLoading={loading}
              />
            );
          })}
        </SimpleGrid>
      )}

      {/* Show pagination controls when there are sparks or we have total count */}
      {(sparks.length > 0 || totalCount > 0) && (
        <Box mt={6} mb={8}>
          <PaginationControls
            currentPage={currentPage}
            totalPages={totalPages}
            onPrevious={handlePreviousPage}
            onNext={handleNextPage}
            disableNext={currentPage === totalPages || !hasMore}
            disablePrevious={currentPage === 1}
            onPageSelect={handlePageSelect}
          />
        </Box>
      )}
    </Box>
  );
};

export default Spark;