import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Custom hook to fetch account based on the account ID.
 * @param {string} accountID - The account ID to fetch account for.
 * @param {string} crmAccountId - The CRM account ID to fetch.
 * @returns {Object} - Contains account data, loading state, and error state.
 */
const fetchAccount = async (
  accountID,
  crmAccountId,
  getAccessToken,
  userSub
) => {
  if (!accountID || !crmAccountId || !userSub) {
    throw new Error("Missing required parameters");
  }
  const token = await getAccessToken();
  const target =
    process.env.REACT_APP_API_BASE_URL ||
    "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
  const response = await axios.get(
    `${target}/api/accounts/${accountID}/users/${userSub}/crm-accounts/${crmAccountId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.data) {
    throw new Error(`Account with ID ${crmAccountId} not found`);
  }
  const accountData = response.data;
  accountData.id = Number(accountData.id);
  return accountData;
};

const useFetchAccountById = (accountID, crmAccountId) => {
  const { getAccessToken } = useAuthData();
  const { user } = useAuth0();
  return useQuery({
    queryKey: ["account", accountID, crmAccountId],
    queryFn: () =>
      fetchAccount(accountID, crmAccountId, getAccessToken, user?.sub),
    enabled: Boolean(accountID && crmAccountId && user?.sub),
    staleTime: 0,
  });
};

export default useFetchAccountById;
