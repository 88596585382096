// SettingsView.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Spinner,
  Text,
  Button,
  Grid,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import IntegrationsSection from "./IntegrationsSection";
import CRMSection from "./CRMSection";
import SmartComposeSection from "./SmartComposeSection";
import PageHeader from "../Shared/PageHeader";
import { useAuthData } from "auth-context";
import { MdAdd } from "react-icons/md";
import { useFilterState } from "../hooks/useFilterState";
import PipelineCard from "../Pipelines/PipelineCard";
import CreatePipelineModal from "../Pipelines/CreatePipelineModal";
import { useEnhancedPipelines } from "../hooks/useEnhancedPipelines";
import { useDisclosure } from "@chakra-ui/react";

export default function SettingsView() {
  // 1. Call ALL hooks unconditionally at the top
  const userData = useAuthData();
  const userID = userData?.user_ID;
  const accountID = userData?.account_ID;

  const [selectedSection, setSelectedSection] = useFilterState(
    "settings-section",
    "integrations"
  );
  const [isLoading, setIsLoading] = useState(true); // Initial loading state

  const { 
    isOpen: isPipelineModalOpen, 
    onOpen: onPipelineModalOpen, 
    onClose: onPipelineModalClose 
  } = useDisclosure();
  const { 
    isOpen: isIntegrationModalOpen, 
    onOpen: onIntegrationModalOpen, 
    onClose: onIntegrationModalClose 
  } = useDisclosure();

  const { 
    pipelines, 
    isLoading: pipelinesLoading, 
    error: pipelinesError 
  } = useEnhancedPipelines(accountID); // Call hook even if accountID might be initially null
  
  const [pipelinesSegment, setPipelinesSegment] = useState("all");
  const [integrationView, setIntegrationView] = useFilterState(
    "integration-view",
    "all"
  );

  // Initial segment options - will be potentially updated by useEffect
  const [segmentOptions, setSegmentOptions] = useState([
    { label: "Pipelines", value: "pipelines" },
    { label: "Integrations", value: "integrations" },
    { label: "CRM", value: "crm" },
    { label: "Smart Compose", value: "smart-compose" },
  ]);

  const privilegedUserIDs = [6, 8];

  // 2. useEffects also called unconditionally after all hooks
  useEffect(() => {
    // This effect now only handles *side effects* based on userID
    // It assumes isLoading state is managed separately or derived
    if (userID !== undefined && userID !== null) {
      if (!privilegedUserIDs.includes(userID)) {
        // If the user is not privileged, restrict the segment options
        setSegmentOptions([
          { label: "Pipelines", value: "pipelines" },
          { label: "Integrations", value: "integrations" },
        ]);
      }
      // Set loading to false only after user data check is complete
      setIsLoading(false); 
    }
  }, [userID]); // Dependency remains userID

  // 3. Conditional returns *after* all hook calls
  // Determine final loading state based on initial check AND user data arrival
  const finalIsLoading = isLoading || userID === undefined; // Consider undefined initially as loading

  if (finalIsLoading) { // Use derived loading state
    return (
      <Box textAlign="center" p={6}>
        <Spinner size="lg" />
        <Text mt={4} fontSize="md" color="gray.500">
          Loading settings...
        </Text>
      </Box>
    );
  }

  // UserID should exist here if not loading, but double-check for robustness
  if (!userID) {
    return (
      <Box textAlign="center" p={6}>
        <Text fontSize="md" color="gray.500">
          Unable to load user data.
        </Text>
      </Box>
    );
  }

  // 4. Define derived state and components *after* hooks and conditional returns
  
  // Define pipelines segment options
  const PIPELINES_SEGMENTS = [
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Archived", value: "archived" },
  ];

  // Define control components outside of conditional rendering logic
  const pipelineControls = (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        variant="outline"
        bg="white"
        borderRadius="md"
        borderColor="gray.300"
        _hover={{ bg: "gray.50" }}
        size="md"
      >
        {pipelinesSegment === "all"
          ? "All Pipelines"
          : pipelinesSegment === "active"
          ? "Active"
          : "Archived"}
      </MenuButton>
      <MenuList>
        {PIPELINES_SEGMENTS.map((segment) => (
          <MenuItem
            key={segment.value}
            onClick={() => setPipelinesSegment(segment.value)}
          >
            {segment.label === "All"
              ? "All Pipelines"
              : segment.label === "Active"
              ? "Active"
              : "Archived"}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );

  const integrationControls = (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        variant="outline"
        bg="white"
        borderRadius="md"
        borderColor="gray.300"
        _hover={{ bg: "gray.50" }}
        size="md"
      >
        {integrationView === "all"
          ? "All Integrations"
          : integrationView === "my"
          ? "My Integrations"
          : "Team Integrations"}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => setIntegrationView("all")}>
          All Integrations
        </MenuItem>
        <MenuItem onClick={() => setIntegrationView("my")}>
          My Integrations
        </MenuItem>
        <MenuItem onClick={() => setIntegrationView("team")}>
          Team Integrations
        </MenuItem>
      </MenuList>
    </Menu>
  );

  // Define a stable wrapper for left controls to pass to PageHeader
  const leftControlsWrapper = (
    <Box> {/* Stable wrapper element */}
      {selectedSection === "pipelines" ? pipelineControls : 
       selectedSection === "integrations" ? integrationControls : 
       null}
    </Box>
  );

  // Filter pipelines based on segment only 
  const filteredPipelines =
    pipelines?.filter((pipeline) => {
      const isActive =
        pipeline.is_active === true || pipeline.is_active === "true";
      const pipelineStatus = isActive ? "active" : "archived";
      return pipelinesSegment === "all" || pipelineStatus === pipelinesSegment;
    }) || [];

  const renderPipelineContent = () => {
    // Always return a consistent Box structure
    return (
      <Box>
        {pipelinesLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={12}>
            <Spinner size="xl" color="blue.500" thickness="4px" />
            <Text ml={4}>Loading pipelines...</Text>
          </Box>
        ) : pipelinesError ? (
          <Box p={4} color="red.500">
            <Text>Failed to load pipelines. Please try again later.</Text>
          </Box>
        ) : filteredPipelines?.length === 0 ? (
          <VStack spacing={4} align="center" justify="center" py={12}>
            {pipelinesSegment !== "archived" ? (
              <>
                <Text fontSize="lg" color="gray.500">
                  No pipelines found. Create your first pipeline to get started.
                </Text>
                <Button leftIcon={<MdAdd />} colorScheme="blue" onClick={onPipelineModalOpen}>
                  Create Pipeline
                </Button>
              </>
            ) : (
              <Text fontSize="lg" color="gray.500">
                No archived pipelines found.
              </Text>
            )}
          </VStack>
        ) : (
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={6}
            mb={20}
          >
            {filteredPipelines?.map((pipeline) => (
              <PipelineCard key={pipeline.id} pipeline={pipeline} />
            ))}
          </Grid>
        )}
      </Box>
    );
  };

  // Define remaining components/functions needed for the final return
  const renderRightControls = () => {
    if (selectedSection === "pipelines" && pipelinesSegment !== "archived") {
      return (
        <Button
          leftIcon={<MdAdd />} 
          colorScheme="blue" 
          onClick={onPipelineModalOpen} 
          borderRadius="md" 
          size="md"
        >
          Create Pipeline
        </Button>
      );
    }

    if (selectedSection === "integrations") {
      return (
        <Button
          leftIcon={<MdAdd />}
          colorScheme="blue"
          onClick={onIntegrationModalOpen} // Use lifted state opener
          borderRadius="md"
          size="md"
        >
          New Integration
        </Button>
      );
    }

    return null;
  };

  // 5. Final unconditional return statement
  return (
    <Box px={4}>
      <PageHeader
        title="Settings"
        segmentOptions={segmentOptions.length > 1 ? segmentOptions : []}
        selectedSegment={selectedSection}
        onSegmentChange={setSelectedSection}
        showSearch={false}
        // Pass the stable wrapper element to PageHeader
        additionalControls={leftControlsWrapper}
        searchRightElement={renderRightControls()}
      />

      <Box mt={8}>
        {/* Render all sections, control visibility with display prop */}
        <Box display={selectedSection === "pipelines" ? "block" : "none"}> 
          {renderPipelineContent()} 
        </Box> 
        <Box display={selectedSection === "integrations" ? "block" : "none"}> 
          <IntegrationsSection 
            integrationView={integrationView} 
            isModalOpen={isIntegrationModalOpen} 
            onModalOpen={onIntegrationModalOpen} 
            onModalClose={onIntegrationModalClose} 
          /> 
        </Box> 
        {privilegedUserIDs.includes(userID) && ( 
          <> 
            <Box display={selectedSection === "crm" ? "block" : "none"}> 
              <CRMSection /> 
            </Box> 
            <Box display={selectedSection === "smart-compose" ? "block" : "none"}> 
              <SmartComposeSection /> 
            </Box> 
          </> 
        )} 
      </Box>

      {/* Conditional rendering of Modal */} 
      {selectedSection === "pipelines" && (
        <CreatePipelineModal
          isOpen={isPipelineModalOpen}
          onClose={onPipelineModalClose}
          accountID={accountID}
        />
      )}
      {/* Note: IntegrationsModal is rendered inside IntegrationsSection */} 
    </Box>
  );
}
