import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Text, VStack, Spinner, Button } from "@chakra-ui/react";

const AutoLogin = () => {
  const { isAuthenticated, isLoading, logout } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectAttempts, setRedirectAttempts] = useState(0);
  const [showManualLogin, setShowManualLogin] = useState(false);

  // Track redirect attempts and show manual login option if there are too many
  useEffect(() => {
    if (redirectAttempts > 2) {
      setShowManualLogin(true);
    }
  }, [redirectAttempts]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    // Check if we're in the middle of an Auth0 callback
    const searchParams = new URLSearchParams(window.location.search);
    const hasAuthParams = searchParams.has("code") || searchParams.has("state");

    // Check if the URL has hash parameters that might indicate we're in a callback
    const hasHashCallback = location.hash.includes("/callback");

    // If we have auth parameters in the URL, we should go to the callback handler
    if (hasAuthParams) {
      // If we're at the root with auth params, redirect to the callback route
      if (location.pathname === "/" && !hasHashCallback) {
        navigate("/callback");
        return;
      }
    }

    // Introduce a delay to allow isAuthenticated to update correctly.
    const timer = setTimeout(() => {
      if (isAuthenticated) {
        // If authenticated, redirect to /admin
        navigate("/admin");
      } else {
        // Check localStorage for a potential redirect loop
        const lastRedirect = localStorage.getItem("last_auth_redirect");
        const now = Date.now();

        if (lastRedirect && now - parseInt(lastRedirect) < 10000) {
          // If we've redirected in the last 10 seconds, increment attempts
          setRedirectAttempts((prev) => prev + 1);

          if (redirectAttempts > 2) {
            setShowManualLogin(true);
            return;
          }
        }

        // Store timestamp of this redirect
        localStorage.setItem("last_auth_redirect", now.toString());
        // Navigate to auth callback
        navigate("/auth/callback");
      }
    }, 500); // Increased delay to 500ms

    // Cleanup the timer in case the component unmounts before delay completes
    return () => clearTimeout(timer);
  }, [isLoading, isAuthenticated, navigate, location, redirectAttempts]);

  const handleManualLogin = () => {
    // Clear any problematic auth state
    localStorage.removeItem("auth0.is.authenticated");

    // Perform a complete logout to clean up any potential token issues
    logout({
      logoutParams: {
        returnTo: window.location.origin + "/#/auth/callback",
      },
    });
  };

  if (showManualLogin) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <VStack spacing={5}>
          <Text fontSize="lg">
            We&apos;ve detected a potential authentication issue.
          </Text>
          <Text fontSize="md">
            This may be caused by conflicting login sessions across tabs or
            domains.
          </Text>
          <Button colorScheme="blue" onClick={handleManualLogin}>
            Log In Again
          </Button>
        </VStack>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <VStack spacing={4}>
          <Spinner size="xl" />
          <Text fontSize="lg">Loading authentication...</Text>
        </VStack>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Text fontSize="lg">Redirecting...</Text>
    </Box>
  );
};

export default AutoLogin;
