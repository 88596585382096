import React, { useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Select,
  VStack,
  Tooltip,
  FormErrorMessage,
  Spinner,
} from '@chakra-ui/react';
import { usePipelines } from '../hooks/usePipelines';

/**
 * A reusable component for pipeline and stage selection
 * @param {Object} props - Component props
 * @param {string} props.accountId - Account ID
 * @param {string} props.selectedPipelineId - Selected pipeline ID
 * @param {string} props.selectedStageId - Selected stage ID
 * @param {Function} props.onPipelineChange - Pipeline change handler
 * @param {Function} props.onStageChange - Stage change handler
 * @param {boolean} props.isRequired - Whether the fields are required
 * @param {boolean} props.isDisabled - Whether the component is disabled
 * @param {Object} props.errors - Form errors object
 * @param {boolean} props.hideStageSelect - Whether to hide the stage selection
 * @returns {JSX.Element} Pipeline selection component
 */
export const PipelineSelect = ({
  accountId,
  selectedPipelineId,
  selectedStageId,
  onPipelineChange,
  onStageChange,
  isRequired = false,
  isDisabled = false,
  errors = {},
  hideStageSelect = false,
}) => {
  const {
    pipelines,
    pipelineStages,
    isPipelinesLoading,
    isStagesLoading,
    hasSinglePipeline,
    pipelinesError,
    stagesError,
  } = usePipelines(accountId, selectedPipelineId);

  // Automatically select the pipeline if there's only one
  useEffect(() => {
    if (hasSinglePipeline && pipelines?.[0]?.id && !selectedPipelineId) {
      onPipelineChange(pipelines[0].id);
    }
  }, [hasSinglePipeline, pipelines, selectedPipelineId, onPipelineChange]);

  // Handle pipeline change
  const handlePipelineChange = (e) => {
    const newPipelineId = e.target.value;
    onPipelineChange(newPipelineId);
    // Reset stage when pipeline changes
    onStageChange('');
  };

  // Handle stage change
  const handleStageChange = (e) => {
    const newStageId = e.target.value;
    onStageChange(newStageId);
  };

  return (
    <VStack spacing={hideStageSelect ? 0 : 4} align="stretch" width="100%">
      {/* Pipeline Selection */}
      {pipelines && (
        <FormControl 
          isRequired={isRequired}
          isInvalid={!!errors.pipeline_id}
          isDisabled={isDisabled || isPipelinesLoading}
        >
          {!hideStageSelect && <FormLabel>Pipeline</FormLabel>}
          <Select
            name="pipeline_id"
            value={selectedPipelineId}
            onChange={handlePipelineChange}
            size={hideStageSelect ? "sm" : "md"}
            placeholder={isPipelinesLoading ? 'Loading pipelines...' : 'Select pipeline'}
          >
            {pipelines?.map((pipeline) => (
              <option key={pipeline.id} value={pipeline.id}>
                {pipeline.label}
              </option>
            ))}
          </Select>
          {errors.pipeline_id && (
            <FormErrorMessage>{errors.pipeline_id}</FormErrorMessage>
          )}
          {pipelinesError && (
            <FormErrorMessage>Error loading pipelines</FormErrorMessage>
          )}
        </FormControl>
      )}

      {/* Stage Selection - Only show if hideStageSelect is false */}
      {!hideStageSelect && (
        <FormControl
          isRequired={isRequired}
          isInvalid={!!errors.stage_id}
          isDisabled={isDisabled || !selectedPipelineId || isStagesLoading}
        >
          <FormLabel>Stage</FormLabel>
          <Tooltip
            hasArrow
            label="Please select a pipeline first"
            isDisabled={!!selectedPipelineId}
            placement="top"
          >
            <Select
              name="stage_id"
              value={selectedStageId}
              onChange={handleStageChange}
              placeholder={isStagesLoading ? 'Loading stages...' : 'Select stage'}
            >
              {pipelineStages?.map((stage) => (
                <option key={stage.id} value={stage.id}>
                  {stage.name}
                </option>
              ))}
            </Select>
          </Tooltip>
          {errors.stage_id && (
            <FormErrorMessage>{errors.stage_id}</FormErrorMessage>
          )}
          {stagesError && (
            <FormErrorMessage>Error loading stages</FormErrorMessage>
          )}
        </FormControl>
      )}

      {/* Loading Indicator */}
      {(isPipelinesLoading || isStagesLoading) && (
        <Spinner size="sm" />
      )}
    </VStack>
  );
}; 