// src/hooks/useFetchAccounts.js

import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to fetch accounts based on the account ID.
 * @param {string} accountID - The account ID to fetch accounts for.
 * @param {string} accountType - The type of accounts to fetch (e.g., 'Lead').
 * @returns {Object} - Contains accounts data, loading state, and error state.
 */
const useFetchAccounts = (accountID, accountType = "Lead") => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuthData();

  useEffect(() => {
    const fetchAccounts = async () => {
      if (!accountID) {
        setLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessToken();

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${target}/api/accounts/${accountID}/crm-accounts`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              account_type: accountType,
            },
          }
        );

        setAccounts(response.data || []); // Set to empty array if response.data is falsy
        setError(null);
      } catch (error) {
        console.error("Error fetching accounts:", error);
        setAccounts([]); // Set accounts to empty array on error
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [accountID, accountType, getAccessToken]);

  // Standardize return properties to match component expectations
  return { accounts, isLoading: loading, isError: error };
};

export default useFetchAccounts;
