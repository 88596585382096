import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "auth-context";

const useRemoveContactFromDeal = () => {
  const { getAccessToken } = useAuthData();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ accountId, dealId, contactId }) => {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.delete(
        `${target}/api/accounts/${accountId}/deals/${dealId}/contacts/${contactId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: (_, { accountId, dealId }) => {
      // Invalidate all relevant queries
      queryClient.invalidateQueries(["deal", accountId, dealId]);
      queryClient.invalidateQueries(["dealContacts", accountId, dealId]);
      queryClient.invalidateQueries(["deals", accountId]);
      // Also invalidate the specific deal query
      queryClient.invalidateQueries(["deal", dealId]);
    },
  });
};

export default useRemoveContactFromDeal;
