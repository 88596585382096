import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { 
  Box, 
  Center, 
  Text,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import GoBackHeader from "../Shared/GoBackHeader";
import EmailComposeDetail from "./EmailComposeDetail";
import PageHeader from "../Shared/PageHeader";
import { useFetchDealById } from "../hooks/useFetchDealById";
import { useFetchDealActivities } from "../hooks/useFetchDealActivities";
import { generateEmailApiCall } from "../api/emailService";
import axios from 'axios';

const EmailComposePage = () => {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const userID = userData?.user_ID || "";
  const { getAccessToken } = useAuthData();
  const [dealWithActivities, setDealWithActivities] = useState(null);
  const [selectedReferenceActivity, setSelectedReferenceActivity] = useState(null);

  // Get deal data from location state or fetch it
  const dealFromState = location.state?.dealData;
  
  // Use custom hook to fetch deal with React Query if not provided in state
  const { data: fetchedDeal, isLoading: isLoadingDeal, isError, error } = useFetchDealById(
    accountID, 
    dealId, 
    { enabled: !dealFromState } // Only fetch if deal not provided in state
  );

  // Fetch deal activities
  const { data: dealActivities, isLoading: isLoadingActivities } = useFetchDealActivities(
    accountID,
    dealId,
    // Add default dates (last 90 days)
    new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    new Date().toISOString().split('T')[0],
    { enabled: true } // Always fetch activities
  );

  // Combine deal and activities data
  useEffect(() => {
    const baseDeal = dealFromState || fetchedDeal;
    if (baseDeal) {
      // Prepare a new object with all deal properties
      const updatedDeal = { ...baseDeal };
      
      // Add activities array if it exists
      if (dealActivities && Array.isArray(dealActivities) && dealActivities.length > 0) {
        // Process each activity to ensure it has the right format for email detection
        const processedActivities = dealActivities.map(activity => {
          // Ensure each activity has normalized format to help with email detection
          const processedActivity = { ...activity };
          
          // If it's an email activity, make sure key fields are at the top level
          if (activity.activity_type?.toLowerCase() === 'email' && activity.activity_data?.data) {
            // Add email-specific fields at top level for easier detection
            if (activity.activity_data.data.subject) {
              processedActivity.subject = activity.activity_data.data.subject;
            }
            
            if (Array.isArray(activity.activity_data.data.recipients)) {
              processedActivity.recipients = activity.activity_data.data.recipients;
            }
            
            if (activity.activity_data.data.body) {
              processedActivity.body = activity.activity_data.data.body;
            }
            
            if (activity.activity_data.data.sender_email) {
              processedActivity.from = activity.activity_data.data.sender_email;
            }
          }
          
          return processedActivity;
        });
        
        updatedDeal.activities = processedActivities;
      } else {
        // No mock data, just set an empty activities array
        updatedDeal.activities = [];
      }
      
      setDealWithActivities(updatedDeal);
    }
  }, [dealFromState, fetchedDeal, dealActivities]);

  const handleGoBack = () => {
    // Use browser history to go back instead of direct navigation
    // This maintains proper navigation stack
    navigate(-1);
  };

  const handleSendMessage = async (messageData) => {
    // Ensure we have necessary IDs
    if (!accountID || !userID || !dealId || !getAccessToken) {
      console.error("Missing necessary data for sending email:", { accountID, userID, dealId });
      throw new Error("Missing configuration to send email.");
    }

    try {
      const accessToken = await getAccessToken();
      const targetUrl = process.env.REACT_APP_API_BASE_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      const apiUrl = `${targetUrl}/api/accounts/${accountID}/users/${userID}/deals/${dealId}/send-email`;

      // Construct payload - include all necessary fields from messageData
      const payload = {
        message_content: messageData.content,
        subject: messageData.subject,
        email_recipients: messageData.email_recipients,
        send_as_email: messageData.send_as_email,
        send_as_user_id: messageData.send_as_user_id,
        effective_user_id: userID, // Assume sender is the current user
        is_new_thread: messageData.is_new_thread || false, // Include the flag
        reference_activity_id: messageData.reference_activity_id, // Include reference activity ID
        // Add other potential fields if needed by backend
        deal_id: Number(dealId),
        type: "message", // Assuming type is message
        identity: "agent", // Assuming identity is agent
      };

      // Remove null/undefined fields from payload
      Object.keys(payload).forEach(key => payload[key] == null && delete payload[key]);
      
      const response = await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      // Optional: Invalidate queries or update local state based on response
      return { success: true, data: response.data }; // Return success

    } catch (error) {
      console.error("Error sending email:", error.response?.data || error.message);
      const errorMsg = error.response?.data?.message || error.message || "Failed to send email.";
      // Re-throw the error so the detail component can display toast
      throw new Error(errorMsg); 
    }
  };

  const handleGenerateEmail = async (emailType, guidingPrompt, activityContextOverride = null) => {
    // Ensure we have the necessary data from auth context and params
    if (!accountID || !userID || !dealId  || !getAccessToken) {
      console.error("Missing necessary data for generating email:", { accountID, userID, dealId });
      // Return an error object similar to how the API call would
      return {
        content: "<p>Error: Missing necessary user or configuration data.</p>",
        subject: "Configuration Error",
        email_recipients: [],
        isError: true,
      };
    }

    try {
      // Determine which activity context to use
      let activityId = null;
      let activityType = null;

      // If it's a meeting follow-up and a specific meeting context is provided, use that
      if (emailType === 'meeting_follow_up' && activityContextOverride) {
        activityId = activityContextOverride.activity_id;
        activityType = activityContextOverride.activity_type;
      } 
      // Otherwise, use the selectedReferenceActivity from state (existing behavior)
      else if (selectedReferenceActivity) { 
        activityId = selectedReferenceActivity.activity_id;
        activityType = selectedReferenceActivity.activity_type;
      }

      // Call the centralized API function
      const result = await generateEmailApiCall({
        accountID,
        userID,
        dealId,
        emailType,
        guidingPrompt,
        getAccessToken,
        // Pass determined activity context
        activityId, 
        activityType,
        // Pass simplified deal context instead of the full object
        existingDealData: {
          deal_id: dealWithActivities?.id,
          deal_name: dealWithActivities?.deal_name || dealWithActivities?.name,
          // Extract only necessary contact info (e.g., emails)
          contacts: dealWithActivities?.contacts?.map(c => ({ email: c.email, name: c.name || c.full_name })) || [],
          // Potentially add other relevant fields needed by the API, but avoid large/nested objects
          // e.g., account_name: dealWithActivities?.account_name
        }
      });
      
      // If API didn't provide recipients, attempt to extract from contacts
      // Note: API response recipients should take precedence if available
      if (!result.email_recipients || result.email_recipients.length === 0) {
          if (dealWithActivities?.contacts && dealWithActivities.contacts.length > 0) {
            result.email_recipients = dealWithActivities.contacts
              .filter(c => c.email)
              .map(c => ({ 
                name: c.name || c.full_name || '', 
                email: c.email, 
                type: 'to' // Defaulting to 'to'
              }));
          }
      }

      return result; // Return the processed result from the utility function

    } catch (error) {
      console.error("Error generating email in EmailComposePage:", error);
      // Display an error toast notification
      toast({
        title: "Failed to Generate Email",
        description: error.message || "An unexpected error occurred. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // Re-throw the error so the caller knows it failed and doesn't update the UI with error content
      throw error;
    }
  };

  const noop = () => undefined;

  const isLoading = isLoadingDeal || isLoadingActivities;

  if (isLoading && !dealWithActivities) {
    return (
      <Box px={4}>
        <PageHeader
          title="Compose Email"
          showSearch={false}
          isDetailView
        />
        <Box mb={8}>
          <GoBackHeader
            onGoBack={handleGoBack}
            title="Loading..."
          />
        </Box>
        <Center p={8}>
          <Text>Loading deal information...</Text>
        </Center>
      </Box>
    );
  }

  if (isError || (!dealWithActivities && !isLoading)) {
    return (
      <Box px={4}>
        <PageHeader
          title="Compose Email"
          showSearch={false}
          isDetailView
        />
        <Box mb={6}>
          <GoBackHeader
            onGoBack={handleGoBack}
            title="Error"
          />
        </Box>
        <Center mt={8}>
          <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              {isError ? "Error Loading Deal" : "Deal Not Found"}
            </Text>
            <Text fontSize="sm">
              {isError 
                ? (error?.message || 'Failed to load deal details. Please try again later.')
                : "The requested deal could not be found. It may have been deleted or you may not have permission to view it."
              }
            </Text>
          </Alert>
        </Center>
      </Box>
    );
  }

  return (
    <Box px={4}>
      {/* Main Header */}
      <PageHeader
        title="Compose Email"
        segmentOptions={[]}
        selectedSegment=""
        onSegmentChange={noop}
        onSearchChange={noop}
        showSearch={false}
        isDetailView
      />

      {/* Go Back Header with Deal Title */}
      <Box mb={6}>
        <GoBackHeader
          onGoBack={handleGoBack}
          title={`Email for ${dealWithActivities?.deal_name || dealWithActivities?.name || 'Deal'}`}
        />
      </Box>

      {/* Email Compose Detail Component - Wrapped directly */}
      <EmailComposeDetail
        deal={dealWithActivities}
        onSendMessage={handleSendMessage}
        onGoBack={handleGoBack}
        onGenerateEmail={handleGenerateEmail}
        // Pass down state and setter for selected activity
        selectedReferenceActivity={selectedReferenceActivity}
        setSelectedReferenceActivity={setSelectedReferenceActivity}
      />
    </Box>
  );
};

export default EmailComposePage; 