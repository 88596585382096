import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  FormErrorMessage,
  Box,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Text,
  Spinner,
  Select,
  SimpleGrid,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useAuthData } from 'auth-context';
import axios from 'axios';
import useFetchAccounts from '../hooks/useFetchAccounts';
import useFetchDeals from '../hooks/useFetchDeals';
import { SearchIcon } from '@chakra-ui/icons';
import { useDebounce } from 'use-debounce';

const CreateContactModal = ({ isOpen, onClose }) => {
  const toast = useToast();
  const { getAccessToken } = useAuthData();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const { accounts } = useFetchAccounts(accountID, "Lead");
  const { deals: existingDeals, isLoading: isLoadingDeals } = useFetchDeals(accountID);

  // Search refs
  const dealSearchContainerRef = useRef(null);
  const dealSuggestionRef = useRef(null);

  // Deal search state
  const [dealSearch, setDealSearch] = useState('');
  const [debouncedDealSearch] = useDebounce(dealSearch, 300);
  const [showDealSuggestions, setShowDealSuggestions] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    email: '',
    phone: '',
    title: '',
    linkedin: '',
    city: '',
    state: '',
    country: '',
    crm_account_id: '',
    deal_id: '',
    priority: 'Standard',
    type: 'Lead'
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Filter deals based on search
  const filteredDeals = React.useMemo(() => {
    if (!existingDeals) return [];
    if (!debouncedDealSearch) return existingDeals;
    return existingDeals.filter(deal => 
      deal.name?.toLowerCase().includes(debouncedDealSearch.toLowerCase()) ||
      deal.stage_name?.toLowerCase().includes(debouncedDealSearch.toLowerCase())
    );
  }, [existingDeals, debouncedDealSearch]);

  const handleDealSelect = (deal) => {
    setDealSearch(deal.name);
    setShowDealSuggestions(false);
    setFormData(prev => ({
      ...prev,
      deal_id: deal.id,
      crm_account_id: deal.crm_account_id
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'First name is required';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';

      const dataToSubmit = {
        ...formData,
        crm_account_id: formData.crm_account_id ? Number(formData.crm_account_id) : null,
        deal_id: formData.deal_id ? Number(formData.deal_id) : null,
      };

      await axios.post(
        `${target}/api/accounts/${accountID}/contacts`,
        dataToSubmit,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      toast({
        title: 'Contact created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onClose();
      // Reset form
      setFormData({
        name: '',
        last_name: '',
        email: '',
        phone: '',
        title: '',
        linkedin: '',
        city: '',
        state: '',
        country: '',
        crm_account_id: '',
        deal_id: '',
        priority: 'Standard',
        type: 'Lead'
      });
    } catch (error) {
      console.error('Error creating contact:', error);
      toast({
        title: 'Error creating contact',
        description: error.response?.data?.message || 'An error occurred while creating the contact',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>Create New Contact</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text fontSize="sm" color="gray.600" mb={2}>
              Enter the details for the new contact.
            </Text>

            {/* Deal Association Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Deal Association
              </Text>
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Associated Deal</FormLabel>
                  <Box position="relative" className="deal-search-container" ref={dealSearchContainerRef}>
                    <InputGroup>
                      <Input
                        value={dealSearch}
                        onChange={(e) => { setDealSearch(e.target.value); setShowDealSuggestions(true); }}
                        placeholder="Search by deal name or stage"
                        onFocus={() => {
                          setShowDealSuggestions(true);
                          setTimeout(() => {
                            dealSuggestionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
                          }, 300);
                        }}
                      />
                      <InputRightElement>
                        {isLoadingDeals ? <Spinner size="sm" color="gray.400" /> : <SearchIcon color="gray.500" />}
                      </InputRightElement>
                    </InputGroup>
                    {showDealSuggestions && (
                      <List
                        ref={dealSuggestionRef}
                        position="absolute"
                        top="100%"
                        left={0}
                        right={0}
                        bg="white"
                        boxShadow="lg"
                        borderRadius="md"
                        maxH="200px"
                        overflowY="auto"
                        zIndex={2000}
                        border="1px solid"
                        borderColor="gray.200"
                        mt={1}
                      >
                        {isLoadingDeals ? (
                          <ListItem p={4} textAlign="center"><Spinner size="sm" /></ListItem>
                        ) : filteredDeals.length === 0 ? (
                          <ListItem p={4} color="gray.500">{dealSearch ? "No deals found" : "Type to search deals"}</ListItem>
                        ) : (
                          filteredDeals.map(deal => (
                            <ListItem
                              key={deal.id}
                              p={3}
                              cursor="pointer"
                              _hover={{ bg: 'gray.50' }}
                              onClick={() => handleDealSelect(deal)}
                              transition="background-color 0.2s"
                            >
                              <Text fontWeight="medium">{deal.name}</Text>
                              <VStack spacing={1} align="start">
                                {deal.stage_name && (<Text fontSize="sm" color="gray.500">Stage: {deal.stage_name}</Text>)}
                                {accounts?.find(a => a.id === deal.crm_account_id)?.name && (
                                  <Text fontSize="sm" color="gray.500">
                                    Account: {accounts.find(a => a.id === deal.crm_account_id).name}
                                  </Text>
                                )}
                              </VStack>
                            </ListItem>
                          ))
                        )}
                      </List>
                    )}
                  </Box>
                </FormControl>
              </VStack>
            </Box>

            {/* Required Information Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Required Information
              </Text>
              <VStack spacing={4}>
                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl isRequired isInvalid={!!errors.name}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Enter first name"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.last_name}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      placeholder="Enter last name"
                    />
                    <FormErrorMessage>{errors.last_name}</FormErrorMessage>
                  </FormControl>
                </SimpleGrid>

                <FormControl isRequired isInvalid={!!errors.email}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter email"
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
              </VStack>
            </Box>

            {/* Contact Details Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4}>
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Contact Details
              </Text>
              <VStack spacing={4}>
                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Enter phone number"
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Title</FormLabel>
                    <Input
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      placeholder="Enter job title"
                    />
                  </FormControl>
                </SimpleGrid>

                <FormControl>
                  <FormLabel>LinkedIn</FormLabel>
                  <Input
                    name="linkedin"
                    value={formData.linkedin}
                    onChange={handleInputChange}
                    placeholder="Enter LinkedIn profile URL"
                  />
                </FormControl>
              </VStack>
            </Box>

            {/* Location Information Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4}>
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Location Information
              </Text>
              <VStack spacing={4}>
                <SimpleGrid columns={3} spacing={4} width="100%">
                  <FormControl>
                    <FormLabel>City</FormLabel>
                    <Input
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      placeholder="Enter city"
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>State</FormLabel>
                    <Input
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      placeholder="Enter state"
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Country</FormLabel>
                    <Input
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      placeholder="Enter country"
                    />
                  </FormControl>
                </SimpleGrid>
              </VStack>
            </Box>

            {/* Additional Information Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4}>
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Additional Information
              </Text>
              <VStack spacing={4}>
                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl>
                    <FormLabel>Priority</FormLabel>
                    <Select
                      name="priority"
                      value={formData.priority}
                      onChange={handleInputChange}
                      placeholder="Select priority"
                    >
                      <option value="VIP">VIP</option>
                      <option value="Standard">Standard</option>
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                    >
                      <option value="Lead">Lead</option>
                      <option value="Other">Other</option>
                    </Select>
                  </FormControl>
                </SimpleGrid>
              </VStack>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Create Contact
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

CreateContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateContactModal; 