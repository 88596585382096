// src/components/Inbox/InboxDetailsPage.jsx
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Center,
  Text,
  useToast,
  Alert,
  AlertIcon,
  Skeleton,
  VStack,
  Flex,
  HStack,
  Button,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import EmailFollowupDetail from "./EmailFollowupDetail";
import CallFollowupDetail from "./CallFollowupDetail";
import GoBackHeader from "../Shared/GoBackHeader";
import { useInboxItem } from "../hooks/useInboxItem";
import PageHeader from "../Shared/PageHeader";
import { useEffect, useCallback, useState } from "react";
import { FaClock, FaBan } from "react-icons/fa";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";

// Mapping of task_subtype to corresponding detail components
const DETAIL_VIEW_COMPONENTS = {
  email: EmailFollowupDetail,
  call: CallFollowupDetail,
  meeting: EmailFollowupDetail,
  // Add more mappings here as needed
};

const LoadingSkeleton = () => (
  <VStack spacing={6} align="stretch">
    <Box
      bg="white"
      p={6}
      borderRadius="md"
      boxShadow="sm"
      borderWidth="1px"
      borderColor="gray.200"
    >
      <VStack spacing={6} align="stretch">
        <Skeleton height="24px" width="150px" />
        <Skeleton height="60px" />
        <Skeleton height="100px" />
        <Skeleton height="150px" />
      </VStack>
    </Box>
    <Box
      bg="white"
      p={6}
      borderRadius="md"
      boxShadow="sm"
      borderWidth="1px"
      borderColor="gray.200"
    >
      <VStack spacing={6} align="stretch">
        <Skeleton height="200px" />
      </VStack>
    </Box>
  </VStack>
);

const InboxDetailsPage = () => {
  const { inboxId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const userID = userData?.user_ID || "";
  const target =
    process.env.REACT_APP_NODE_ENV === "local"
      ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
      : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

  const queryClient = useQueryClient();

  // Add state for tracking loading of snooze/block actions
  const [isSnoozing, setIsSnoozing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);

  // Pre-compute all color mode values at the root level (not inside conditionals)
  const buttonBgColor = useColorModeValue("white", "gray.700");
  const buttonBorderColor = useColorModeValue("gray.300", "gray.500");
  const buttonTextColor = useColorModeValue("#56595E", "white");
  const buttonHoverBgColor = useColorModeValue("gray.50", "gray.600");

  const {
    data: item,
    loading,
    error,
    handleAddAnnotation,
    handleArchiveToggle,
    handleSendMessage,
    handleMarkAsDone,
    handleGenerateEmail,
  } = useInboxItem(inboxId, accountID, userID, target);

  // Memoize navigation function
  const handleGoBack = useCallback(() => {
    // Get the referrer state and filter state if they exist
    const referrer = location.state?.referrer;
    const filterState = location.state?.filterState;

    if (referrer && referrer.pathname) {
      // Navigate to the stored location, preserving query parameters
      // Use replace:true to avoid adding to browser history stack
      navigate(
        {
          pathname: referrer.pathname,
          search: referrer.search,
        },
        {
          replace: true,
          state: {
            from: "detail",
            filterState, // Pass back the original filter state
          },
        }
      );
    } else {
      // Fall back to browser history if no referrer
      navigate("/admin/inbox", {
        replace: true,
        state: {
          from: "detail",
          filterState, // Still try to pass filter state if available
        },
      });
    }
  }, [navigate, location.state]);

  // Memoize send function - now handleSendMessage is properly initialized
  const handleSend = useCallback(
    async (messageData) => {
      try {
        await handleSendMessage(messageData);

        // Get the filter state from location if available
        const filterState = location.state?.filterState;

        // Only navigate back if not creating a new thread
        if (!messageData.is_new_thread) {
          navigate("/admin/inbox", {
            replace: true,
            state: {
              from: "detail",
              filterState, // Pass back the original filter state
            },
          });
        } else {
          // Stay on current page for new threads
          toast({
            title: "New Thread Created",
            description:
              "Your email has been sent as a new conversation thread.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Failed to send message",
          description:
            error.response?.data?.message ||
            error.message ||
            "An error occurred while sending the message.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [navigate, handleSendMessage, toast, location.state]
  );

  // Memoize generate email function
  const handleGenerate = useCallback(
    async (emailType, guidingPrompt = "") => {
      try {
        const result = await handleGenerateEmail(emailType, {
          additional_context: {
            guiding_prompt: guidingPrompt,
          },
        });
        return result;
      } catch (error) {
        toast({
          title: "Failed to generate email",
          description:
            error.response?.data?.message ||
            error.message ||
            "An error occurred while generating the email.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        throw error;
      }
    },
    [handleGenerateEmail, toast]
  );

  useEffect(() => {
    return () => {
      // Component will unmount
    };
  }, []);

  const handleAnnotation = async (annotation) => {
    try {
      await handleAddAnnotation(annotation);
      // Toast is handled in EmailFollowupDetail.jsx
    } catch (error) {
      toast({
        title: "Failed to add note",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while adding the note.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleArchive = async () => {
    try {
      await handleArchiveToggle();
      toast({
        title: `Thread ${
          item.status === "archived" ? "unarchived" : "archived"
        } successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed to update archive status",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while updating the archive status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTaskDone = async (id) => {
    try {
      await handleMarkAsDone(id);
      // Toast is handled in EmailFollowupDetail.jsx
    } catch (error) {
      toast({
        title: "Failed to mark as done",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while marking as done.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const noop = () => undefined;

  // Determine the title based on task type and subject
  const getTitle = () => {
    if (!item) return "Loading...";

    if (item.task_subtype === "call") {
      return "Call Follow-up Details";
    }

    // For email follow-ups, use the title field from the backend response
    if (item.task_subtype === "email" && item.title) {
      return item.title;
    }

    return item.emailSubject || "Email Follow-up Details";
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingSkeleton />;
    }

    if (error) {
      return (
        <Center mt={8}>
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Error Loading Item
            </Text>
            <Text fontSize="sm">
              {typeof error === "string"
                ? error
                : error.message ||
                  "Failed to load inbox item details. Please try again later."}
            </Text>
          </Alert>
        </Center>
      );
    }

    if (!item) {
      return (
        <Center mt={8}>
          <Alert
            status="warning"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Item Not Found
            </Text>
            <Text fontSize="sm">
              The requested inbox item could not be found. It may have been
              deleted or you may not have permission to view it.
            </Text>
          </Alert>
        </Center>
      );
    }

    // Select the appropriate detail component
    const DetailComponent = DETAIL_VIEW_COMPONENTS[item.task_subtype];

    if (!DetailComponent) {
      return (
        <Center mt={8}>
          <Alert
            status="warning"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              Unsupported Type
            </Text>
            <Text fontSize="sm">
              Unsupported task type: {item.task_subtype || "unknown"}
            </Text>
          </Alert>
        </Center>
      );
    }

    return (
      <DetailComponent
        item={item}
        onArchiveToggle={handleArchive}
        onSendMessage={handleSend}
        onAddAnnotation={handleAnnotation}
        onTaskDone={handleTaskDone}
        onGoBack={handleGoBack}
        onGenerateEmail={handleGenerate}
      />
    );
  };

  // Show the snooze confirmation modal
  const handleSnoozeClick = () => {
    setIsSnoozeModalOpen(true);
  };

  // Show the block confirmation modal
  const handleBlockClick = () => {
    setIsBlockModalOpen(true);
  };

  // Handle snooze function after confirmation
  const handleSnoozeTask = async () => {
    setIsSnoozeModalOpen(false);
    if (!item || isSnoozing) return;

    setIsSnoozing(true);
    try {
      const accessToken = await userData.getAccessToken();

      await axios.post(
        `${target}/api/accounts/${userData.account_ID}/users/${userData.user_ID}/tasks/${item.id}/update-status`,
        {
          status: "snoozed",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast({
        title: "Task Snoozed",
        description: "The task has been snoozed for 7 days.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Invalidate queries to refresh the inbox
      queryClient.invalidateQueries(["inbox"]);

      // Navigate back to inbox after snoozing
      handleGoBack();
    } catch (error) {
      toast({
        title: "Failed to Snooze",
        description:
          error.message || "An error occurred while snoozing the task.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSnoozing(false);
    }
  };

  // Handle block function after confirmation
  const handleBlockTask = async () => {
    setIsBlockModalOpen(false);
    if (!item || isBlocking) return;

    setIsBlocking(true);
    try {
      const accessToken = await userData.getAccessToken();

      await axios.post(
        `${target}/api/accounts/${userData.account_ID}/users/${userData.user_ID}/tasks/${item.id}/update-status`,
        {
          status: "blocked",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast({
        title: "Task Blocked",
        description:
          "The task has been blocked. No further follow-ups will be created for this deal.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Invalidate queries to refresh the inbox
      queryClient.invalidateQueries(["inbox"]);

      // Navigate back to inbox after blocking
      handleGoBack();
    } catch (error) {
      toast({
        title: "Failed to Block",
        description:
          error.message || "An error occurred while blocking the task.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsBlocking(false);
    }
  };

  return (
    <Box px={4}>
      {/* Main Header with Avatar */}
      <PageHeader
        title="Inbox"
        segmentOptions={[]}
        selectedSegment=""
        onSegmentChange={noop}
        onSearchChange={noop}
        showSearch={false}
        isDetailView
      />

      {/* Go Back Header with Dynamic Title */}
      <Box mb={2}>
        <Flex justify="space-between" align="center">
          <GoBackHeader onGoBack={handleGoBack} title={getTitle()} />
          {item && item.task_subtype === "email" && (
            <HStack spacing={3} alignItems="center" marginTop="-16px">
              <Button
                leftIcon={<FaClock />}
                variant="outline"
                size="sm"
                height="38px"
                onClick={handleSnoozeClick}
                isLoading={isSnoozing}
                loadingText="Snoozing..."
                bg={buttonBgColor}
                borderRadius="md"
                borderColor={buttonBorderColor}
                color={buttonTextColor}
                _hover={{
                  bg: buttonHoverBgColor,
                }}
                sx={{
                  "&:hover svg": {
                    color: "yellow.500",
                  },
                }}
              >
                Snooze
              </Button>
              <Button
                leftIcon={<FaBan />}
                variant="outline"
                size="sm"
                height="38px"
                onClick={handleBlockClick}
                isLoading={isBlocking}
                loadingText="Blocking..."
                bg={buttonBgColor}
                borderRadius="md"
                borderColor={buttonBorderColor}
                color={buttonTextColor}
                _hover={{
                  bg: buttonHoverBgColor,
                }}
                sx={{
                  "&:hover svg": {
                    color: "red.500",
                  },
                }}
              >
                Block
              </Button>
            </HStack>
          )}
        </Flex>
      </Box>

      {/* Render the content */}
      {renderContent()}

      {/* Snooze Confirmation Modal */}
      <Modal
        isOpen={isSnoozeModalOpen}
        onClose={() => setIsSnoozeModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Snooze</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Are you sure you want to snooze this task?</Text>
            <Text fontSize="sm" color="gray.600">
              This task will be removed from your inbox and reappear after 7
              days.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => setIsSnoozeModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              colorScheme="yellow"
              onClick={handleSnoozeTask}
              isLoading={isSnoozing}
            >
              Snooze
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Block Confirmation Modal */}
      <Modal
        isOpen={isBlockModalOpen}
        onClose={() => setIsBlockModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Block</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Are you sure you want to block this task?</Text>
            <Text fontSize="sm" color="gray.600">
              This will permanently prevent any future tasks from being created
              for this deal for all team members.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => setIsBlockModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleBlockTask}
              isLoading={isBlocking}
            >
              Block
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InboxDetailsPage;
