import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

export const useUpdateDeal = () => {
  const { getAccessToken } = useAuthData();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ accountId, dealId, dealData }) => {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.put(
        `${target}/api/accounts/${accountId}/deals/${dealId}`,
        dealData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    },
    onSuccess: (data, variables) => {
      // Invalidate relevant queries to trigger refetch
      queryClient.invalidateQueries(["deals", variables.accountId]);
      queryClient.invalidateQueries([
        "deal",
        variables.accountId,
        variables.dealId,
      ]);
    },
  });
};

export default useUpdateDeal;
