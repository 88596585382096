import React, { useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Icon,
  Collapse,
  useColorModeValue,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Badge,
  Wrap,
  WrapItem,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronDown, ChevronUp, Trash2, MoreHorizontal } from "lucide-react";
import ActivityContent from "./ActivityContent";
import ActivityTitle from "./ActivityTitle";
import { getActivityConfig, formatDate, formatTime } from "./utils";
import { ActivityPropType } from "./types";
import { useDeleteActivity } from "../hooks/useDeleteActivity";
import PropTypes from "prop-types";

// Array of predefined color schemes for deals
const DEAL_COLORS = [
  "blue", "green", "purple", "orange", "pink", "cyan", "teal", 
  "red", "yellow"
];

// Function to generate a consistent color for a deal based on its ID
const getDealColorScheme = (dealId) => {
  if (!dealId) return "gray";
  
  // Convert dealId to string and sum character codes to get a consistent number
  let sum = 0;
  const dealIdStr = String(dealId);
  for (let i = 0; i < dealIdStr.length; i++) {
    sum += dealIdStr.charCodeAt(i);
  }
  
  // Use the sum to pick a color from the array
  return DEAL_COLORS[sum % DEAL_COLORS.length];
};

// Store deal color mappings for consistent colors
const dealColorCache = new Map();

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, isDeleting }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Delete Activity</ModalHeader>
      <ModalBody>
        Are you sure you want to delete this activity? This action cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" mr={3} onClick={onClose}>
          Cancel
        </Button>
        <Button 
          colorScheme="red" 
          onClick={onConfirm}
          isLoading={isDeleting}
        >
          Delete
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const ActivityItem = ({ activity, hideMoreButton = false, showDealLabels = true }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { icon, color } = getActivityConfig(activity);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteActivity = useDeleteActivity();

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  
  // Account activity color - used for account-level activities (dealId = 0)
  const accountActivityColor = useColorModeValue("gray.400", "gray.600");

  // Determine which deals to show badges for
  const getDealsToShow = () => {
    const dealsArray = [];
    
    // If the activity has a deal_name, include it
    if (activity.deal_name) {
      dealsArray.push({
        deal_id: activity.deal_id,
        deal_name: activity.deal_name
      });
    }
    
    // If there are associated deals from multiple sources, include those too
    if (activity.associated_deals && activity.associated_deals.length > 0) {
      activity.associated_deals.forEach(deal => {
        // Only add if not already in the list
        if (!dealsArray.some(d => d.deal_id === deal.deal_id)) {
          dealsArray.push(deal);
        }
      });
    }
    
    // If still no deals and we have a deal_id, create a generic deal entry
    if (dealsArray.length === 0 && activity.deal_id) {
      dealsArray.push({
        deal_id: activity.deal_id,
        deal_name: `Deal #${activity.deal_id}`
      });
    }
    
    // If this is a direct account activity (no deal), show that
    if (dealsArray.length === 0) {
      dealsArray.push({
        deal_id: 0,
        deal_name: "Account Activity"
      });
    }
    
    return dealsArray;
  };

  // Ensure activity has required props for the components
  const normalizedActivity = {
    ...activity,
    // Set default values for required props to prevent errors
    status: activity.status || 'completed',
    timestamp: activity.timestamp || activity.created_at || activity.activity_date,
    // Convert string account_id to number if needed
    account_id: typeof activity.account_id === 'string' ? parseInt(activity.account_id, 10) : (activity.account_id || 0),
    // Ensure user_id is always defined as a number
    user_id: typeof activity.user_id === 'string' ? parseInt(activity.user_id, 10) : (activity.user_id || 0),
    // Ensure activity_data has expected structure
    activity_data: activity.activity_data ? {
      ...activity.activity_data,
      // Normalize activity_type to 'email' for now to prevent type errors
      activity_type: (activity.activity_type?.toLowerCase() === 'meeting') ? 'email' : (activity.activity_data.activity_type || 'email')
    } : { activity_type: 'email' },
    // For Slack Digest activities, always use 'Lysto' as the user
    user_name: activity.activity_type?.toLowerCase() === 'slack digest' 
      ? 'Lysto' 
      : (!activity.user_name || activity.user_name.trim() === '') 
        ? (activity.user_email ? activity.user_email.split('@')[0] : '') 
        : activity.user_name,
    user_last_name: activity.activity_type?.toLowerCase() === 'slack digest'
      ? ''
      : (!activity.user_last_name || activity.user_last_name.trim() === '') 
        ? (activity.user_email && (!activity.user_name || activity.user_name.trim() === '') ? '' : activity.user_last_name) 
        : activity.user_last_name,
    // Store the full email for use as a fallback
    user_email: activity.user_email || ''
  };

  // Updated helper functions:

  // formatDateAsCalendar: removed second parameter 'timezone' as it's unused
  const formatDateAsCalendar = (timeString) => {
    // Parse the date part manually, ignoring the trailing 'Z'
    const [datePart] = timeString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    const weekday = date.toLocaleDateString('en-US', { weekday: 'long' });
    const monthShort = date.toLocaleDateString('en-US', { month: 'short' });
    return `${weekday} ${monthShort} ${day}, ${year}`;
  };

  // formatTimeAsCalendar: remove unused 'seconds' by destructuring only hours and minutes
  const formatTimeAsCalendar = (timeString, timezone) => {
    // Create a proper Date object from the ISO string
    const date = new Date(timeString);
    
    // If the timezone is UTC, convert to user's local timezone
    if (timezone === 'UTC') {
      // Use browser's date formatting with user's local timezone
      const formatter = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZoneName: 'short'
      });
      return formatter.format(date);
    }
    
    // For non-UTC timezones, use the existing behavior
    // Remove trailing 'Z' if present and parse time components
    const timePart = timeString.split('T')[1].replace('Z', '');
    const [hours, minutes] = timePart.split(':').map(Number);
    const isPM = hours >= 12;
    const hour12 = hours % 12 || 12;
    const minuteStr = minutes.toString().padStart(2, '0');
    let formattedTime = `${hour12}:${minuteStr} ${isPM ? 'PM' : 'AM'}`;

    if (timezone) {
      if (timezone.includes('New_York') || timezone.includes('Eastern')) {
        formattedTime += ' ET';
      } else if (timezone.includes('Los_Angeles') || timezone.includes('Pacific')) {
        formattedTime += ' PT';
      } else if (timezone.includes('Chicago') || timezone.includes('Central')) {
        formattedTime += ' CT';
      } else if (timezone.includes('Denver') || timezone.includes('Mountain')) {
        formattedTime += ' MT';
      } else if (timezone.includes('/')) {
        const cityPart = timezone.split('/')[1].replace('_', ' ');
        formattedTime += ` (${cityPart})`;
      }
    }
    return formattedTime;
  };

  // Updated helper function to parse meeting times (removed unused 'timezone' parameter)
  const parseMeetingTime = (timeString) => {
    // Extract date and time parts ignoring the 'Z' at the end
    const [datePart, timePart] = timeString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes] = timePart.replace('Z', '').split(':').map(Number);
    
    // Create a date object with the parsed components
    // Subtract 1 from month because JavaScript months are 0-indexed
    return new Date(year, month - 1, day, hours, minutes);
  };

  // Update getDisplayDateTime to use the helper function for consistent time parsing
  const getDisplayDateTime = () => {
    // For meetings, check if it's upcoming or past
    if (normalizedActivity.activity_type === 'Meeting' && normalizedActivity?.activity_data?.data?.start_time) {
      const meetingData = normalizedActivity.activity_data.data;
      
      // Use our custom parser that ignores UTC designation
      const startTime = parseMeetingTime(meetingData.start_time);
      const now = new Date();
      
      // If the meeting is in the future (upcoming), use created_at date
      if (startTime > now) {
        return {
          date: formatDate(normalizedActivity.created_at),
          time: formatTime(normalizedActivity.created_at)
        };
      }
      
      // For past meetings, continue using the meeting start time
      return {
        date: formatDateAsCalendar(meetingData.start_time),
        time: formatTimeAsCalendar(meetingData.start_time, meetingData.timezone)
      };
    }
    
    // For other activity types or if start_time is not available, use completed_date_time
    return {
      date: formatDate(normalizedActivity.completed_date_time),
      time: formatTime(normalizedActivity.completed_date_time)
    };
  };

  // Replace with a single function call that uses the date and time
  const { date, time } = getDisplayDateTime();
  const formattedDateTime = `${date} at ${time}`;
  
  // Get color for each deal, ensuring consistency with dealColorCache
  const getDealColor = (dealId) => {
    // For account-level activities, use the accountActivityColor
    if (dealId === 0) return accountActivityColor;
    
    // Convert dealId to number if it's a string
    const normalizedDealId = typeof dealId === 'string' ? parseInt(dealId, 10) : dealId;
    
    if (!dealColorCache.has(normalizedDealId)) {
      // Ensure we're storing with a consistent key type
      dealColorCache.set(normalizedDealId, getDealColorScheme(normalizedDealId));
    }
    
    const color = dealColorCache.get(normalizedDealId);
    return color;
  };

  const handleDelete = async () => {
    try {
      await deleteActivity.mutateAsync({
        accountId: normalizedActivity.account_id,
        dealId: normalizedActivity.deal_id,
        activityId: normalizedActivity.id,
      });
      onClose();
    } catch (error) {
      console.error('Error deleting activity:', error);
    }
  };

  const handleClick = (e) => {
    // Prevent expansion when clicking menu items
    if (!e.target.closest('.activity-menu')) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <Box width="100%">
      <Box
        width="100%"
        p={3}
        bg={bgColor}
        boxShadow="sm"
        borderRadius="md"
        borderWidth="1px"
        borderColor={borderColor}
        transition="all 0.2s"
        cursor="pointer"
        onClick={handleClick}
        _hover={{
          borderColor: "gray.300",
          bg: hoverBg,
        }}
      >
        <HStack spacing={3} width="100%" justify="space-between" align="flex-start">
          <Icon as={icon} color={color} boxSize={6} mt={1} flexShrink={0} />

          <VStack align="start" flex="1" minW="0" spacing={0}>
            <ActivityTitle activity={normalizedActivity} />
            <Text fontSize="xs" color="gray.500" mt={1}>
              {formattedDateTime}
            </Text>
          </VStack>

          <HStack spacing={2} align="center" flexShrink={0} minW="auto">
            {/* Deal badges only shown when multiple deals are present */}
            {showDealLabels && getDealsToShow().length > 0 && (
              <Wrap spacing={1} justify="flex-end" maxW="220px" flexShrink={0}>
                {getDealsToShow().map((deal, index) => {
                  // Explicitly handle the deal ID, ensuring it's a number where possible
                  const dealId = deal.deal_id === 0 ? 0 : (typeof deal.deal_id === 'string' ? 
                    parseInt(deal.deal_id, 10) : deal.deal_id);
                  
                  // Get color for this specific deal
                  const colorScheme = dealId === 0 ? accountActivityColor : getDealColor(dealId);
                  
                  // Truncate very long deal names for display
                  const displayName = deal.deal_name.length > 25 
                    ? `${deal.deal_name.substring(0, 22)}...` 
                    : deal.deal_name;
                  
                  return (
                    <WrapItem key={`${dealId}-${index}`} flexShrink={0}>
                      <Tooltip 
                        label={dealId === 0 
                          ? "Direct account activity" 
                          : `Deal: ${deal.deal_name}`
                        } 
                        placement="top"
                        hasArrow
                      >
                        <Badge
                          colorScheme={colorScheme}
                          fontSize="xs" 
                          fontWeight="normal"
                          px={1.5}
                          py={0.5}
                          borderRadius="full"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          maxW="160px"
                          textTransform="none"
                          height="20px"
                          lineHeight="1.2"
                          display="flex"
                          alignItems="center"
                          transition="max-width 0.2s"
                          _hover={{
                            maxW: "220px",
                            zIndex: 2
                          }}
                        >
                          {displayName}
                        </Badge>
                      </Tooltip>
                    </WrapItem>
                  );
                })}
              </Wrap>
            )}
            
            <Icon
              as={isExpanded ? ChevronUp : ChevronDown}
              boxSize={5}
              color="gray.500"
            />
            
            {!hideMoreButton && (
              <Menu placement="top-end">
                <MenuButton
                  as={IconButton}
                  icon={<MoreHorizontal size={16} />}
                  variant="ghost"
                  size="sm"
                  className="activity-menu"
                  onClick={(e) => e.stopPropagation()}
                  opacity={0.6}
                  _hover={{ opacity: 1 }}
                />
                <MenuList onClick={(e) => e.stopPropagation()}>
                  <MenuItem
                    icon={<Trash2 size={16} />}
                    color="red.500"
                    onClick={onOpen}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
        </HStack>
      </Box>

      <Collapse in={isExpanded} animateOpacity>
        <Box mt={2}>
          <ActivityContent activity={normalizedActivity} />
        </Box>
      </Collapse>

      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDelete}
        isDeleting={deleteActivity.isLoading}
      />
    </Box>
  );
};

ActivityItem.propTypes = {
  activity: ActivityPropType,
  hideMoreButton: PropTypes.bool,
  showDealLabels: PropTypes.bool,
};

export default ActivityItem;