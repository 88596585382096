import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to connect a deal to a Slack channel
 * @returns {Object} - Contains the mutation function and states
 */
function useConnectDealToSlackChannel() {
  const { getAccessToken } = useAuthData();

  return useMutation({
    mutationFn: async ({ accountId, dealId, userId, channelId }) => {
      if (!accountId || !dealId || !userId || !channelId) {
        throw new Error("Missing required parameters");
      }

      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.post(
        `${target}/api/accounts/${accountId}/users/${userId}/deals/${dealId}/slack/channel`,
        { channel_id: channelId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    },
  });
}

export default useConnectDealToSlackChannel;
