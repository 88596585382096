import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  Flex,
  Button,
  HStack,
  FormControl,
  FormLabel,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  Badge,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from '@chakra-ui/react';
import { 
  FaSlack, 
  FaPaperPlane, 
  FaMagic,
  FaPencilAlt
} from 'react-icons/fa';
import DealActivity from '../Deals/DealActivity';
import OverviewSection from '../Components/OverviewSection';
import LoadingOverlay from '../Components/LoadingOverlay';
import MessageInput from '../Inbox/MessageInput';

// Predefined template options for Slack messages
const TEMPLATE_OPTIONS = [
  { value: 'status_update', label: 'Status Update' },
  { value: 'question', label: 'Ask a Question' },
  { value: 'announcement', label: 'Announcement' },
  { value: 'meeting_recap', label: 'Meeting Recap' },
  { value: 'follow_up', label: 'Follow Up' },
];

const SlackMessageDetail = ({ 
  deal, 
  onSendMessage, 
  onGoBack, 
  onGenerateMessage 
}) => {
  const toast = useToast();

  // State for message composition
  const [message, setMessage] = useState('');
  const [guidingPrompt, setGuidingPrompt] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('status_update');
  const [isSending, setIsSending] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [sendStatus, setSendStatus] = useState(null);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [isSendConfirmOpen, setIsSendConfirmOpen] = useState(false);
  const [editorKey, setEditorKey] = useState(Date.now());

  // Get connected Slack channel
  const connectedChannel = deal?.slack_channel || null;

  // Handle navigation after successful send
  useEffect(() => {
    let timer;
    if (sendStatus) {
      setShowLoadingOverlay(true);
      if (sendStatus === 'success') {
        timer = setTimeout(() => {
          onGoBack();
        }, 1500);
      }
    } else {
      setShowLoadingOverlay(false);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [sendStatus, onGoBack]);

  // Handle sending the message
  const handleSend = () => {
    if (!message.trim()) {
      toast({
        title: "Message Required",
        description: "Please add a message to send to the Slack channel.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!connectedChannel) {
      toast({
        title: "Slack Channel Required",
        description: "This deal doesn&apos;t have a connected Slack channel.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    // Open the send confirmation modal
    setIsSendConfirmOpen(true);
  };

  const confirmSend = async () => {
    // Close the confirmation modal
    setIsSendConfirmOpen(false);
    
    setIsSending(true);
    setSendStatus("sending");
    try {
      // Call the onSendMessage function passed from parent
      await onSendMessage({
        content: message,
        deal_id: deal?.id,
        channel_id: connectedChannel.id,
        template_type: selectedTemplate,
      });
      setSendStatus("success");
      toast({
        title: "Message Sent",
        description: "Your message has been sent to Slack successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      setSendStatus("error");
      toast({
        title: "Failed to Send",
        description: error.message || "An error occurred while sending the message to Slack.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleGenerateMessage = async () => {
    if (!deal || !deal.id) {
      toast({
        title: "Deal Information Required",
        description: "Deal information is required to generate a message.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsGenerating(true);
    try {
      // Use the selected template and guiding prompt
      const generatedMessage = await onGenerateMessage(selectedTemplate, guidingPrompt);
      
      if (generatedMessage?.content) {
        setMessage(generatedMessage.content.trim());
        setEditorKey(Date.now());

        toast({
          title: "Message Generated",
          description: "A new Slack message has been generated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error("No content received from message generation");
      }
    } catch (error) {
      toast({
        title: "Failed to Generate Message",
        description: error.message || "An error occurred while generating the message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <>
      <Box
        bg="transparent"
        p={0}
        borderRadius="md"
        mb={4}
        height="100%"
      >
        <VStack spacing={6} align="stretch" height="100%">
          {/* Card 1: Overview and Slack Message Composition */}
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <VStack spacing={6} align="stretch">
              {/* Overview Section */}
              <Box>
                <Text fontSize="md" fontWeight="semibold" mb={3}>
                  Overview
                </Text>
                <Box>
                  <OverviewSection
                    taskType="Slack Message"
                    champion={deal?.champion || "N/A"}
                    lastEngagementDate={deal?.last_activity_date}
                    dealName={deal?.deal_name || deal?.name}
                    dealId={deal?.id}
                    showOwner={false}
                  />
                </Box>
              </Box>

              {/* Message Composition Section */}
              <Box>
                <VStack spacing={4} align="stretch">
                  <Flex align="center" justify="space-between" mb={2}>
                    <Text fontSize="md" fontWeight="semibold">
                      Compose Slack Message
                    </Text>
                  </Flex>
                  
                  {/* Slack Channel Info moved below title */}
                  {connectedChannel && (
                    <Box mb={3}>
                      <Flex align="center">
                        <FaSlack color="#4A154B" style={{ marginRight: '8px' }} />
                        <Text fontSize="sm" fontWeight="medium" mr={2}>Sending to:</Text>
                        <Badge colorScheme="purple" px={2} py={1} borderRadius="md">
                          #{connectedChannel.metadata?.name || connectedChannel.name || connectedChannel.name_normalized || 'channel'}
                        </Badge>
                      </Flex>
                    </Box>
                  )}

                  {!connectedChannel && (
                    <Alert status="warning" mb={4}>
                      <AlertIcon />
                      <Text>This deal doesn&apos;t have a connected Slack channel. Please connect a Slack channel in the deal settings.</Text>
                    </Alert>
                  )}
                  
                  {/* Replace Textarea with MessageInput */}
                  <MessageInput 
                    key={editorKey} 
                    message={message} 
                    setMessage={setMessage} 
                    isSending={isSending}
                  />
                </VStack>
              </Box>

              {/* Template Selection and Action Row */}
              <VStack spacing={4} align="stretch">
                
                {/* AI Generation Controls - Using HStack and FormControl like EmailComposeDetail */}
                <HStack spacing={4} align="flex-end">
                  <FormControl flex="1">
                    <FormLabel fontSize="sm" fontWeight="medium">
                      Message Template
                    </FormLabel>
                    {/* Updated to use Chakra Select */}
                    <Select 
                      value={selectedTemplate}
                      onChange={handleTemplateChange}
                      size="md"
                      borderWidth="1px"
                      borderColor="gray.300"
                      borderRadius="lg"
                    >
                      {TEMPLATE_OPTIONS.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  
                  <FormControl flex="2">
                    <FormLabel fontSize="sm" fontWeight="medium">
                      Additional Instructions
                    </FormLabel>
                    {/* Updated to use Chakra Input with Icon */}
                    <InputGroup>
                       <InputLeftElement
                        pointerEvents="none"
                        color="gray.500"
                      >
                        <FaPencilAlt />
                      </InputLeftElement>
                      <Input
                        type="text"
                        value={guidingPrompt}
                        onChange={(e) => setGuidingPrompt(e.target.value)}
                        placeholder="E.g., Include data points, Reference last meeting"
                        size="md"
                        borderWidth="1px"
                        borderColor="gray.300"
                        borderRadius="lg"
                        paddingLeft="2.5rem"
                      />
                    </InputGroup>
                  </FormControl>
                  
                  <Button
                    leftIcon={<FaMagic />}
                    colorScheme="purple"
                    onClick={handleGenerateMessage}
                    isLoading={isGenerating}
                    loadingText="Generating"
                    isDisabled={isSending || isGenerating || !deal?.id}
                    size="md"
                    title="Generate message content"
                    borderRadius="md"
                  >
                    Generate
                  </Button>
                  
                  <Button
                    leftIcon={<FaPaperPlane />}
                    colorScheme="blue"
                    onClick={handleSend}
                    isLoading={isSending}
                    loadingText="Sending"
                    isDisabled={!message.trim() || isSending || isGenerating || !connectedChannel}
                    size="md"
                    title="Send this message"
                    borderRadius="md"
                  >
                    Send
                  </Button>
                </HStack>
              </VStack>

            </VStack>
          </Box>

          {/* Card 2: Related Activities */}
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <Box>
              <DealActivity 
                accountID={deal?.account_id} 
                dealID={deal?.id}
                title="Related Activities"
              />
            </Box>
          </Box>
        </VStack>
      </Box>

      {/* Loading Overlay Modal */}
      <Modal 
        isOpen={showLoadingOverlay} 
        closeOnOverlayClick={false} 
        isCentered
      >
        <ModalOverlay backdropFilter="blur(4px)" bg="blackAlpha.700" />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalBody>
            <LoadingOverlay status={sendStatus} />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Send Confirmation Modal */}
      <Modal isOpen={isSendConfirmOpen} onClose={() => setIsSendConfirmOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Send</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to send this message to the <b>#{connectedChannel?.metadata?.name || connectedChannel?.name || 'selected'}</b> Slack channel?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={() => setIsSendConfirmOpen(false)}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={confirmSend} isLoading={isSending}>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

SlackMessageDetail.propTypes = {
  deal: PropTypes.object.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onGenerateMessage: PropTypes.func.isRequired,
};

export default SlackMessageDetail; 