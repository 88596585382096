import React, { useMemo } from "react";
import { VStack, Card, Text, Box, SimpleGrid } from "@chakra-ui/react";
import DealCountSinceLastActivityBarCard from "./DealCountSinceLastActivityBarCard";
import DealCountByStageAndSourceBarCard from "./DealCountByStageAndSourceBarCard";
import UnpaidDealsTable from "../Sales Efficiency/UnpaidDealsTable";
import StatCard from "../StatCard";
import { MdAttachMoney, MdPayments, MdPendingActions } from "react-icons/md";

const PRIMARY_COLOR = "#1A5BF6";

// Helper function to filter deals by segment
const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  const filteredDeals = deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) {
      return false;
    }
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
  
  return filteredDeals;
};

// Helper function to calculate growth percentage
const calculateGrowth = (current, previous) => {
  if (!previous) return 0;
  return ((current - previous) / previous) * 100;
};

const SalesProcess = ({ 
  accountID, 
  toast, 
  selectedYear, 
  selectedQuarter,
  selectedSegment,
  deals 
}) => {
  const metrics = useMemo(() => {
    const calculatePeriodMetrics = (periodDeals) => {
      if (!periodDeals) {
        return {
          totalWonValue: 0,
          totalPaidValue: 0,
          totalUnpaidValue: 0,
          wonCount: 0,
          paidCount: 0,
          unpaidCount: 0
        };
      }

      // Filter deals by segment
      const filteredDeals = filterDealsBySegment(periodDeals, selectedSegment);

      // IMPORTANT: Deal stage filtering
      // - Won deals are identified by stage names "Closed Won", "Closed-Won", "Close Won" or the flags is_closed + is_won
      // - Paid deals are identified by stage names "Client ( Closed- Paid)", "Closed Paid" or the Date_Time_Closed_Paid__c field
      // - A deal can be won without being paid, but paid deals might not be in the won deals collection
      
      // Get won deals
      const wonDeals = filteredDeals.filter(deal => {
        // Use multiple methods to determine if a deal is won, matching the SalesEfficiencyGrid approach
        const isWonByFlag = deal.is_closed && deal.is_won;
        const isWonByStage = deal.stage_name && (
          deal.stage_name === "Closed-Won" ||
          deal.stage_name === "Close Won" ||
          deal.stage_name === "Closed Won" ||
          deal.stage_name === "Closed-Paid" ||
          deal.stage_name === "Close Paid" ||
          deal.stage_name === "Closed Paid" ||
          deal.stage_name === "Client ( Closed- Paid)"
        );
        
        // Also check for the Date_Time_Closed_Won__c field if it exists
        const isWonByDate = !!deal.extra_fields?.Date_Time_Closed_Won__c;
        
        return isWonByFlag || isWonByStage || isWonByDate;
      });
      
      const totalWonValue = wonDeals.reduce((sum, deal) => 
        sum + (Number(deal.amount) || 0), 0
      );

      // Split won deals into paid and unpaid
      const paidDeals = filteredDeals.filter(deal => {
        // Check by stage name - using a more inclusive list like we did for won deals
        const isPaidByStage = deal.stage_name && (
          deal.stage_name === "Client ( Closed- Paid)" ||
          deal.stage_name === "Closed Paid" ||
          deal.stage_name === "Closed-Paid" ||
          deal.stage_name === "Close Paid"
        );
        
        // Also check by paid date field if it exists
        const isPaidByDate = !!deal.extra_fields?.Date_Time_Closed_Paid__c;
        
        return isPaidByStage || isPaidByDate;
      });
      
      const totalPaidValue = paidDeals.reduce((sum, deal) => 
        sum + (Number(deal.amount) || 0), 0
      );

      const unpaidDeals = wonDeals.filter(deal => {
        // Check stage name first - use the same criteria we used for paid deals
        const isPaidByStage = deal.stage_name && (
          deal.stage_name === "Client ( Closed- Paid)" ||
          deal.stage_name === "Closed Paid" ||
          deal.stage_name === "Closed-Paid" ||
          deal.stage_name === "Close Paid"
        );
        
        // Also check by paid date field
        const isPaidByDate = !!deal.extra_fields?.Date_Time_Closed_Paid__c;
        
        // Deal is unpaid if it's not paid by stage or date
        return !(isPaidByStage || isPaidByDate);
      });
      
      const totalUnpaidValue = unpaidDeals.reduce((sum, deal) => 
        sum + (Number(deal.amount) || 0), 0
      );

      return {
        totalWonValue,
        totalPaidValue,
        totalUnpaidValue,
        wonCount: wonDeals.length,
        paidCount: paidDeals.length,
        unpaidCount: unpaidDeals.length
      };
    };

    const currentMetrics = calculatePeriodMetrics(deals?.currentPeriod);
    const prevMetrics = calculatePeriodMetrics(deals?.prevPeriod);

    // Calculate growth percentages
    const wonValueGrowth = calculateGrowth(currentMetrics.totalWonValue, prevMetrics.totalWonValue);
    const paidValueGrowth = calculateGrowth(currentMetrics.totalPaidValue, prevMetrics.totalPaidValue);
    const unpaidValueGrowth = calculateGrowth(currentMetrics.totalUnpaidValue, prevMetrics.totalUnpaidValue);

    return {
      ...currentMetrics,
      wonValueGrowth,
      paidValueGrowth,
      unpaidValueGrowth
    };
  }, [deals, selectedSegment]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <VStack spacing={6} align="stretch">
      <Box pb={4}>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} gap="20px">
          <StatCard
            icon={MdAttachMoney}
            title={`Closed Won Value (${metrics.wonCount})`}
            value={formatCurrency(metrics.totalWonValue)}
            growth={metrics.wonValueGrowth}
            timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
            primaryColor={PRIMARY_COLOR}
          />
          <StatCard
            icon={MdPayments}
            title={`Closed Paid Value (${metrics.paidCount})`}
            value={formatCurrency(metrics.totalPaidValue)}
            growth={metrics.paidValueGrowth}
            timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
            primaryColor={PRIMARY_COLOR}
          />
          <StatCard
            icon={MdPendingActions}
            title={`Unpaid Won Value (${metrics.unpaidCount})`}
            value={formatCurrency(metrics.totalUnpaidValue)}
            growth={metrics.unpaidValueGrowth}
            timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
            primaryColor={PRIMARY_COLOR}
          />
        </SimpleGrid>
      </Box>

      <Card p="20px">
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Unpaid Deals
        </Text>
        <UnpaidDealsTable 
          deals={deals?.currentPeriod} 
          selectedSegment={selectedSegment}
        />
      </Card>

      <Card p="20px">
        <DealCountByStageAndSourceBarCard 
          accountID={accountID}
          toast={toast}
          selectedYear={selectedYear}
          selectedQuarter={selectedQuarter}
          selectedSegment={selectedSegment}
        />
      </Card>

      <Card p="20px">
        <DealCountSinceLastActivityBarCard 
          accountID={accountID}
          toast={toast}
          selectedYear={selectedYear}
          selectedQuarter={selectedQuarter}
          selectedSegment={selectedSegment}
        />
      </Card>
    </VStack>
  );
};

export default SalesProcess; 