import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to initiate Slack authentication
 * @returns {Object} - Contains function to initiate Slack auth and loading/error states
 */
export function useSlackIntegration() {
  const {
    getAccessToken,
    account_ID: accountID,
    user_ID: userID,
  } = useAuthData();

  const initiateSlackAuth = async () => {
    if (!accountID || !userID) {
      console.warn(
        "Missing required accountID or userID for Slack integration"
      );
      throw new Error("Missing account or user information");
    }

    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Get the auth URL from the API
      const response = await axios.get(
        `${target}/api/accounts/${accountID}/users/${userID}/slack/auth`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Check if we got a valid auth URL
      if (!response.data || !response.data.auth_url) {
        throw new Error("Failed to get Slack authorization URL");
      }

      // Now open a popup window with the auth URL directly
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const popupWidth = 500;
      const popupHeight = 600;
      const left = screenWidth / 2 - popupWidth / 2;
      const top = screenHeight / 2 - popupHeight / 2;

      // Open the popup with the auth URL directly
      const authUrl = response.data.auth_url;
      const features = `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`;
      const popupWindow = window.open(authUrl, "slackAuthWindow", features);
      if (popupWindow) {
        try {
          popupWindow.opener = null; // prevent reverse tabnabbing
        } catch (e) {
          console.error("Failed to nullify popupWindow.opener", e);
        }
      }

      // Delay the check by 300ms to allow the popup window to initialize
      setTimeout(() => {
        if (!popupWindow || popupWindow.closed) {
          console.warn(
            "Popup was blocked or closed immediately. Falling back to redirect."
          );
          const returnUrl = window.location.href;
          localStorage.setItem("slackReturnUrl", returnUrl);
          window.location.href = authUrl;
        } else {
          // Set up polling to detect when the popup closes
          const pollTimer = setInterval(() => {
            try {
              if (popupWindow.closed) {
                clearInterval(pollTimer);
                if (window.handleSlackAuthComplete) {
                  window.handleSlackAuthComplete();
                }
              }
            } catch (e) {
              // If we can't access the popup's properties, just clean up
              clearInterval(pollTimer);
            }
          }, 500);
        }
      }, 300);

      return { success: true };
    } catch (error) {
      console.error("Error initiating Slack integration:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      throw error;
    }
  };

  const {
    mutateAsync: startSlackAuth,
    isLoading,
    error,
    isError,
  } = useMutation({
    mutationFn: initiateSlackAuth,
  });

  return {
    startSlackAuth,
    isLoading,
    error,
    isError,
  };
}

export default useSlackIntegration;
