import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Progress,
  HStack,
  Icon,
  Box,
  useToast,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  FaEnvelope,
  FaCalendarAlt,
  FaUser,
  FaUsers,
  FaWpforms,
  FaSlack,
  FaLock,
} from "react-icons/fa";

const googleSteps = [
  { id: "service", title: "Choose Service" },
  { id: "permissions", title: "Data Permissions" },
  { id: "account", title: "Google Account" },
];
const typeformSteps = [
  { id: "service", title: "Choose Service" },
  { id: "permissions", title: "Data Permissions" },
  { id: "typeform", title: "Typeform Integration" },
];
const slackSteps = [
  { id: "service", title: "Choose Service" },
  { id: "slack", title: "Slack Integration" },
];

const ServiceButton = ({ icon, title, isSelected, onClick, color }) => (
  <Button
    height="120px"
    width="100%"
    onClick={onClick}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    gap={3}
    bg={isSelected ? `${color}.50` : "white"}
    border="1px solid"
    borderColor={isSelected ? `${color}.200` : "gray.200"}
    _hover={{
      bg: `${color}.50`,
      borderColor: `${color}.300`,
      transform: "translateY(-2px)",
      boxShadow: "md",
    }}
    transition="all 0.2s ease-in-out"
    rounded="lg"
  >
    <Icon
      as={icon}
      boxSize={8}
      color={isSelected ? `${color}.500` : `${color}.400`}
      transition="all 0.2s"
    />
    <Text
      color={isSelected ? `${color}.700` : "gray.600"}
      fontWeight={isSelected ? "medium" : "normal"}
    >
      {title}
    </Text>
  </Button>
);

export const IntegrationsModal = ({
  isOpen,
  onClose,
  onInitiateConnection,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [integrationData, setIntegrationData] = useState({
    service: "",
    permissionType: "",
  });
  const toast = useToast();

  const steps =
    integrationData.service === "typeform"
      ? typeformSteps
      : integrationData.service === "slack"
      ? slackSteps
      : googleSteps;

  // Reset modal state when it closes
  const resetModal = () => {
    setCurrentStep(0);
    setIntegrationData({
      service: "",
      permissionType: "",
    });
  };

  // Handle modal close and reset
  const handleClose = () => {
    onClose();
    resetModal();
  };

  // Reset modal when it's closed (either by user or system)
  useEffect(() => {
    if (!isOpen) {
      resetModal();
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIntegrationData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateStep = () => {
    const requiredFields = {
      service: ["service"],
      permissions: ["permissionType"],
      account: [],
      typeform: [],
      slack: [],
    };

    const currentFields = requiredFields[steps[currentStep].id];
    const missingFields = currentFields.filter(
      (field) => !integrationData[field]
    );

    if (missingFields.length > 0) {
      toast({
        title: "Required Fields Missing",
        description: `Please make a selection before proceeding.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep()) {
      if (currentStep === steps.length - 1) {
        if (integrationData.service === "slack") {
          onInitiateConnection(integrationData.service);
        } else {
          onInitiateConnection(
            integrationData.service,
            integrationData.permissionType
          );
        }
        handleClose();
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const renderServiceSelection = () => (
    <VStack spacing={6} align="stretch">
      <Text fontSize="sm" color="gray.600">
        Choose which service you&apos;d like to integrate with Lysto.
      </Text>

      <FormControl isRequired>
        <FormLabel mb={4}>Service</FormLabel>
        <SimpleGrid 
          columns={{ base: 1, sm: 2 }} 
          spacing={5}
          width="100%"
        >
          <ServiceButton
            icon={FaEnvelope}
            title="Gmail"
            isSelected={integrationData.service === "gmail"}
            onClick={() =>
              handleInputChange({ target: { name: "service", value: "gmail" } })
            }
            color="blue"
          />
          <ServiceButton
            icon={FaCalendarAlt}
            title="Google Calendar"
            isSelected={integrationData.service === "calendar"}
            onClick={() =>
              handleInputChange({
                target: { name: "service", value: "calendar" },
              })
            }
            color="purple"
          />
          <ServiceButton
            icon={FaWpforms}
            title="Typeform"
            isSelected={integrationData.service === "typeform"}
            onClick={() =>
              handleInputChange({
                target: { name: "service", value: "typeform" },
              })
            }
            color="orange"
          />
          <ServiceButton
            icon={FaSlack}
            title="Slack"
            isSelected={integrationData.service === "slack"}
            onClick={() =>
              handleInputChange({
                target: { name: "service", value: "slack" },
              })
            }
            color="green"
          />
        </SimpleGrid>
      </FormControl>
    </VStack>
  );

  const renderPermissionsSelection = () => (
    <VStack spacing={6} align="stretch">
      <Text fontSize="sm" color="gray.600">
        Choose how you want to use this integration.
      </Text>

      <FormControl isRequired>
        <FormLabel mb={4}>Permission Type</FormLabel>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
          <Box
            border="1px solid"
            borderColor={integrationData.permissionType === "personal" ? "blue.200" : "gray.200"}
            borderRadius="lg"
            bg={integrationData.permissionType === "personal" ? "blue.50" : "white"}
            p={5}
            cursor="pointer"
            onClick={() =>
              handleInputChange({
                target: { name: "permissionType", value: "personal" },
              })
            }
            _hover={{
              borderColor: "blue.300",
              bg: "blue.50",
              transform: "translateY(-2px)",
              boxShadow: "md",
            }}
            transition="all 0.2s ease-in-out"
          >
            <VStack align="flex-start" spacing={2}>
              <HStack>
                <Icon as={FaUser} boxSize={6} color="blue.500" />
                <Text fontWeight="bold" fontSize="lg">Personal Account</Text>
              </HStack>
              <Text color="gray.500" fontSize="sm">
                Ideal for your personal work email or calendar.
              </Text>
            </VStack>
          </Box>
          
          <Box
            border="1px solid"
            borderColor={integrationData.permissionType === "shared" ? "blue.200" : "gray.200"}
            borderRadius="lg"
            bg={integrationData.permissionType === "shared" ? "blue.50" : "white"}
            p={5}
            cursor="pointer"
            onClick={() =>
              handleInputChange({
                target: { name: "permissionType", value: "shared" },
              })
            }
            _hover={{
              borderColor: "blue.300",
              bg: "blue.50",
              transform: "translateY(-2px)",
              boxShadow: "md",
            }}
            transition="all 0.2s ease-in-out"
          >
            <VStack align="flex-start" spacing={2}>
              <HStack>
                <Icon as={FaUsers} boxSize={6} color="blue.500" />
                <Text fontWeight="bold" fontSize="lg">Shared Account</Text>
              </HStack>
              <Text color="gray.500" fontSize="sm">
                Ideal for team or department accounts that multiple people need to access.
              </Text>
            </VStack>
          </Box>
        </SimpleGrid>
      </FormControl>

      {/* Show detailed explanation after selection */}
      {integrationData.permissionType && (
        <Box
          mt={0}
          p={3}
          bg={integrationData.permissionType === "personal" ? "blue.50" : "blue.50"}
          borderRadius="md"
          borderWidth="1px"
          borderColor={integrationData.permissionType === "personal" ? "blue.200" : "blue.200"}
        >
          <VStack align="start" spacing={2}>
            <HStack>
              <Icon as={FaLock} boxSize={4} color="blue.500" />
              <Text fontWeight="bold" fontSize="md">Data Permissions</Text>
            </HStack>
            
            <HStack align="flex-start" spacing={3} pl={1}>
              <Text color="gray.600" fontSize="sm">
                {integrationData.permissionType === "personal" 
                  ? "Only you will be able to manage importing data from this account into the CRM." 
                  : "Any user in your organization can manage data import from this account into the CRM."}
              </Text>
            </HStack>
          </VStack>
        </Box>
      )}
    </VStack>
  );

  const renderAccountSelection = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>
        Click continue to select your Google account.
      </Text>

      <Text>
        You&apos;ll be prompted to select and authorize your Google account in
        the next step.
      </Text>
    </VStack>
  );

  const renderTypeformIntegration = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>
        Click continue to connect your Typeform account.
      </Text>
      <Text>
        You&apos;ll be prompted to authorize your Typeform account in the next
        step.
      </Text>
    </VStack>
  );

  const renderSlackIntegration = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>
        Click continue to connect your Slack workspace.
      </Text>
      <Text>
        You&apos;ll be prompted to authorize your Slack workspace in the next
        step.
      </Text>
    </VStack>
  );

  const renderStepContent = () => {
    if (currentStep === 0) {
      return renderServiceSelection();
    }

    if (integrationData.service === "typeform") {
      if (currentStep === 1) {
        return renderPermissionsSelection();
      }
      if (currentStep === 2) {
        return renderTypeformIntegration();
      }
    } else if (integrationData.service === "slack") {
      return renderSlackIntegration();
    } else {
      if (currentStep === 1) {
        return renderPermissionsSelection();
      }
      if (currentStep === 2) {
        return renderAccountSelection();
      }
    }
    return null;
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent maxH="90vh">
        <ModalHeader p={0}>
          <Box px={6} pt={6} pb={4}>
            <Text mb={4}>{steps[currentStep].title}</Text>
            <Progress
              value={(currentStep + 1) * (100 / steps.length)}
              size="sm"
              colorScheme="blue"
              borderRadius="full"
              width="100%"
            />
          </Box>
        </ModalHeader>

        <ModalBody overflowY="auto">{renderStepContent()}</ModalBody>

        <ModalFooter>
          <HStack spacing={4}>
            <Button variant="ghost" onClick={handleClose}>
              Cancel
            </Button>
            {currentStep > 0 && <Button onClick={handleBack}>Back</Button>}
            <Button colorScheme="blue" onClick={handleNext}>
              {currentStep === steps.length - 1 ? "Continue" : "Next"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
