import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  VStack,
  Text,
  Flex,
  Center,
  Spinner,
  useToast,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { ListTodo, Clock, Copy } from 'lucide-react';
import { OverviewTag } from '../Shared/Tags';
import { useNavigate, useLocation } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns';
import ExternalLinksSection from './ExternalLinksSection';
import MeetingNotes from './MeetingNotes';
import AttendeesSection from './AttendeesSection';
import CallRecordingPlayer from './CallRecordingPlayer';
import { useFetchMeetingDeals } from '../hooks/useFetchMeetingDeals';
import DealTag from "../Contacts/DealTag";
import GoBackHeader from "../Shared/GoBackHeader";
import PageHeader from "../Shared/PageHeader";

const MeetingDetailHeader = ({ lastActivity }) => (
  <Box mb={0}>
    <Text fontSize="md" fontWeight="semibold" color="gray.700" mb={4}>
      Overview
    </Text>
    <Flex gap={4} flexWrap="wrap">
      <OverviewTag
        icon={ListTodo}
        label="Type"    
        value="Meeting"
        colorScheme="green"
      />
      <OverviewTag
        icon={Clock}
        label="Last Activity"
        value={lastActivity}
        colorScheme="orange"
      />
    </Flex>
  </Box>
);

const MeetingDealsSection = ({ deals, onViewDetails, dealsError }) => (
  <Box>
    <Text fontSize="md" fontWeight="semibold" color="gray.700" mb={4}>
      Deals
    </Text>
    {dealsError ? (
      <Text color="red.500">{dealsError}</Text>
    ) : !deals || deals.length === 0 ? (
      <Text color="gray.500">No deals associated with this meeting.</Text>
    ) : (
      <Flex gap={4} flexWrap="wrap">
        {deals.map((deal) => (
          <DealTag
            key={deal.id}
            dealName={deal.deal_name}
            onClick={() => onViewDetails(deal)}
          />
        ))}
      </Flex>
    )}
  </Box>
);

const MeetingDetail = ({ meetingAnalysis }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const [audioPlaybackSpeed, setAudioPlaybackSpeed] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  // Extract next steps from AI summary with improved pattern matching
  const extractNextSteps = (aiSummary) => {
    if (!aiSummary) return null;
    
    // Try to find Next Steps, Action Items, or similar sections
    const patterns = [
      /(?:Next Steps|Action Items|Action Points|Follow-up Actions|To-Do|Tasks):([\s\S]*?)(?:\n\n|\n$|$)/i,
      /(?:Next Steps|Action Items|Action Points|Follow-up Actions|To-Do|Tasks)[\s-]*\n([\s\S]*?)(?:\n\n|\n$|$)/i
    ];
    
    let nextStepsText = null;
    
    for (const pattern of patterns) {
      const match = aiSummary.match(pattern);
      if (match && match[1]) {
        nextStepsText = match[1].trim();
        break;
      }
    }
    
    // Fallback: Look for bullet points at the end of the summary
    if (!nextStepsText) {
      const bulletPointsMatch = aiSummary.match(/(\n\s*[-•*]\s+[^\n]+(?:\n\s*[-•*]\s+[^\n]+)*)$/);
      if (bulletPointsMatch && bulletPointsMatch[1]) {
        nextStepsText = bulletPointsMatch[1].trim();
      }
    }
    
    // Standardize bullet points formatting
    if (nextStepsText) {
      // Replace any bullet point format with a standardized format
      nextStepsText = nextStepsText.replace(/^[ \t]*[-•*][ \t]*/gm, '* ');
      
      // Remove empty bullet points (lines with just a bullet and no content)
      nextStepsText = nextStepsText.replace(/^\*\s*$/gm, '').trim();
      
      // Remove any consecutive newlines to eliminate empty space from removed bullets
      nextStepsText = nextStepsText.replace(/\n{2,}/g, '\n');
      
      // Ensure there are no empty bullets anywhere in the text
      nextStepsText = nextStepsText.split('\n')
        .filter(line => line.trim() !== '' && line.trim() !== '*')
        .map(line => line.trim())
        .join('\n');
    }
    
    return nextStepsText;
  };

  // Extract next steps from the AI summary - moved to the top before any conditional returns
  const nextSteps = useMemo(() => {
    return meetingAnalysis?.ai_summary ? extractNextSteps(meetingAnalysis?.ai_summary) : null;
  }, [meetingAnalysis?.ai_summary]);

  // Only fetch deals if we have valid IDs
  const shouldFetchDeals = Boolean(meetingAnalysis?.account_id) && Boolean(meetingAnalysis?.deal_id);

  const { data: deals = [], isLoading, error: dealsError } = useFetchMeetingDeals(
    shouldFetchDeals ? meetingAnalysis?.account_id : null,
    shouldFetchDeals ? meetingAnalysis?.deal_id : null
  );

  if (!meetingAnalysis) {
    return <Text>No meeting data found.</Text>;
  }

  if (isLoading && shouldFetchDeals) {
    return (
      <Center py={8}>
        <Spinner size="xl" />
      </Center>
    );
  }

  const lastActivity = meetingAnalysis.date 
    ? formatDistanceToNow(new Date(meetingAnalysis.date), { addSuffix: true })
    : 'N/A';

  const handleViewDetails = (deal) => {
    navigate(`/admin/deals/${deal.id}`);
  };

  // Audio player controls
  const handlePlayPause = () => {
    setAudioIsPlaying(!audioIsPlaying);
  };

  const handleSeek = (time) => {
    setAudioCurrentTime(time);
  };

  const handleAudioError = (err) => {
    console.error("Audio Error:", err);
  };

  const handlePlaybackSpeedChange = (speed) => {
    setAudioPlaybackSpeed(speed);
  };

  const handleSearchQueryChange = (query) => {
    setSearchQuery(query)
  }

  const handleGoBack = () => {
    navigate('/admin/meetings', { state: { previousPage: location.state?.previousPage || 1 } });
  };

  const noop = () => undefined;

  // Function to handle copying text to clipboard
  const handleCopyToClipboard = (text, label) => {
    if (!text) return;
    
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: `${label} copied to clipboard`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      },
      (err) => {
        console.error('Failed to copy text: ', err);
        toast({
          title: 'Failed to copy text',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    );
  };

  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      {/* Main Header */}
      <PageHeader
        title="Meetings"
        segmentOptions={[]}
        selectedSegment=""
        onSegmentChange={noop}
        onSearchChange={noop}
        showSearch={false}
        isDetailView
      />

      {/* Go Back Header */}
      <Box mb={2}>
        <GoBackHeader
          onGoBack={handleGoBack}
          title={meetingAnalysis?.title || "Meeting Details"}
        />
      </Box>

      <VStack spacing={6} align="stretch" height="100%">
        {/* Card 1: Overview, External Links, Attendees, and Deals */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <MeetingDetailHeader lastActivity={lastActivity} />
            
            <ExternalLinksSection 
              meetingLink={meetingAnalysis.meeting_link || (meetingAnalysis.location?.includes('https://zoom.us/') ? meetingAnalysis.location : undefined)}
              recordingUrl={meetingAnalysis.recording_url}
            />

            <AttendeesSection attendees={meetingAnalysis.attendees} />

            <MeetingDealsSection 
              deals={deals} 
              onViewDetails={handleViewDetails}
              dealsError={dealsError}
            />
          </VStack>
        </Box>

        {/* Card for Next Steps (if they exist) */}
        {nextSteps && (
          <Box
            width="100%"
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <Flex justify="space-between" align="center" mb={4}>
              <HStack spacing={2}>
                <Text fontSize="md" fontWeight="semibold" color="gray.700">
                  Next Steps
                </Text>
                <IconButton
                  aria-label="Copy next steps"
                  icon={<Copy size={16} />}
                  size="sm"
                  variant="ghost"
                  onClick={() => handleCopyToClipboard(nextSteps, "Next Steps")}
                />
              </HStack>
            </Flex>
            <MeetingNotes notes={nextSteps} />
          </Box>
        )}

        {/* Card 2: AI Summary */}
        {meetingAnalysis.ai_summary && (
          <Box
            width="100%"
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <Flex justify="space-between" align="center" mb={4}>
              <HStack spacing={2}>
                <Text fontSize="md" fontWeight="semibold" color="gray.700">
                  Meeting Notes
                </Text>
                <IconButton
                  aria-label="Copy meeting notes"
                  icon={<Copy size={16} />}
                  size="sm"
                  variant="ghost"
                  onClick={() => handleCopyToClipboard(meetingAnalysis.ai_summary, "Meeting Notes")}
                />
              </HStack>
            </Flex>
            <MeetingNotes notes={meetingAnalysis.ai_summary} />
          </Box>
        )}

         {/* Card 3: Transcript */}
        {meetingAnalysis?.transcript_text && (
          <Box
            width="100%"
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <CallRecordingPlayer
              transcript={meetingAnalysis.transcript_text}
              audioUrl={meetingAnalysis.recording_url}
              currentTime={audioCurrentTime}
              duration={meetingAnalysis.duration || 0}
              isPlaying={audioIsPlaying}
              onPlayPause={handlePlayPause}
              onSeek={handleSeek}
              onError={handleAudioError}
              playbackSpeed={audioPlaybackSpeed}
              onPlaybackSpeedChange={handlePlaybackSpeedChange}
              searchQuery={searchQuery}
              onSearchQueryChange={handleSearchQueryChange}
              onSpeakerSegmentClick={handleSeek}
              onCopy={() => handleCopyToClipboard(meetingAnalysis.transcript_text, "Transcript")}
            />
          </Box>
        )}
      </VStack>
    </Box>
  );
};

MeetingDetailHeader.propTypes = {
  lastActivity: PropTypes.string.isRequired,
};

MeetingDetail.propTypes = {
  meetingAnalysis: PropTypes.shape({
    ID: PropTypes.number,
    account_id: PropTypes.number,
    user_id: PropTypes.number,
    audio_id: PropTypes.any,
    attendees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        event_id: PropTypes.number,
        account_id: PropTypes.number,
        user_id: PropTypes.number,
        additional_guests: PropTypes.number,
        comment: PropTypes.string,
        display_name: PropTypes.string,
        email: PropTypes.string,
        attendee_id: PropTypes.string,
        optional: PropTypes.bool,
        organizer: PropTypes.bool,
        resource: PropTypes.bool,
        response_status: PropTypes.string,
        self: PropTypes.bool,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
      })
    ),
    deal_id: PropTypes.number,
    title: PropTypes.string,
    meeting_link: PropTypes.string,
    recording_url: PropTypes.string,
    transcript_text: PropTypes.string,
    ai_summary: PropTypes.string,
    date: PropTypes.string,
    timezone: PropTypes.string,
    duration: PropTypes.string,
    // Additional props for transcript analysis
    transcript_analysis: PropTypes.shape({
      speaker_segments: PropTypes.arrayOf(
        PropTypes.shape({
          speaker: PropTypes.string,
          start_time: PropTypes.number,
          end_time: PropTypes.number,
        })
      ),
      speakers: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

MeetingDetailHeader.propTypes = {
  lastActivity: PropTypes.string.isRequired,
};

MeetingDealsSection.propTypes = {
  deals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      deal_name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onViewDetails: PropTypes.func.isRequired,
};

export default MeetingDetail;