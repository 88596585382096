import React from 'react';
import { Text } from '@chakra-ui/react';
import { getActivityConfig } from './utils';
import { ActivityPropType } from './types';

const ActivityTitle = ({ activity }) => {
  // Handle both regular activities and Spark activities
  const activityType = activity.activity_type || activity.activity_data?.activity_type;
  const { category } = getActivityConfig({ ...activity, activity_type: activityType });
  
  // Get title from either direct title or email subject
  const title = activity.title || activity.activity_data?.data?.subject || "";

  // Get sender info with improved fallbacks
  const getSenderInfo = () => {
    // For booking activities, use the customer name from the properties
    if (activity.activity_type?.toLowerCase() === 'booking') {
      try {
        const bookingDetails = JSON.parse(activity.description);
        if (bookingDetails && bookingDetails.properties) {
          // Use customer property directly if we need it
          return bookingDetails.properties.customer || `created a new booking`;
        }
      } catch (error) {
        return title;
      }
    }
    
    // For Slack Digest activities, always use "Lysto" as the user
    if (activity.activity_type?.toLowerCase() === 'slack digest') {
      return "Lysto";
    }
    
    // For email activities
    if (category === "email") {
      // Try activity_data first (Spark format)
      const senderName = activity.activity_data?.data?.sender_name;
      
      // If we have a non-empty sender name from activity data
      if (senderName && senderName.trim() !== '') {
        return senderName;
      }
      
      // Try user_name and user_last_name only if they're not empty strings
      const hasName = activity.user_name && activity.user_name.trim() !== '';
      const hasLastName = activity.user_last_name && activity.user_last_name.trim() !== '';
      
      if (hasName || hasLastName) {
        return [
          hasName ? activity.user_name.charAt(0).toUpperCase() + activity.user_name.slice(1) : '',
          hasLastName ? activity.user_last_name.charAt(0).toUpperCase() + activity.user_last_name.slice(1) : ''
        ]
          .filter(Boolean)
          .join(" ");
      }
      
      // Use sender_email from activity_data if available
      const senderEmail = activity.activity_data?.data?.sender_email;
      if (senderEmail && senderEmail.trim() !== '') {
        // Return the full email, not just the part before @ 
        return senderEmail;
      }
      
      // Use user_email as final fallback for email activities
      if (activity.user_email && activity.user_email.trim() !== '') {
        return activity.user_email;
      }
    }
    
    // For meeting activities, use organizer details if available (capitalized)
    if (category === "meeting") {
      const meetingData = activity.activity_data?.data || activity.activity_data;
      if (meetingData) {
        if (meetingData.organizer_display_name && meetingData.organizer_display_name.trim() !== "") {
          const name = meetingData.organizer_display_name.trim();
          return name.charAt(0).toUpperCase() + name.slice(1);
        }
        if (meetingData.organizer_email && meetingData.organizer_email.trim() !== "") {
          // Return the full email instead of just the local part
          return meetingData.organizer_email.trim();
        }
      }
    }
    
    // For non-email activities fallback using user_name and user_last_name
    const hasName = activity.user_name && activity.user_name.trim() !== '';
    const hasLastName = activity.user_last_name && activity.user_last_name.trim() !== '';
    
    if (hasName || hasLastName) {
      return [
        hasName ? activity.user_name.charAt(0).toUpperCase() + activity.user_name.slice(1) : '',
        hasLastName ? activity.user_last_name.charAt(0).toUpperCase() + activity.user_last_name.slice(1) : ''
      ]
        .filter(Boolean)
        .join(" ");
    }

    // Final fallback: if user_email exists, use the full email address
    if (activity.user_email && activity.user_email.trim() !== "") {
      return activity.user_email.trim();
    }

    // Ultimate fallback
    return "Unknown User";
  };

  const displayName = getSenderInfo();
  
  // Get recording/transcript info
  const isRecorded = activity.activity_data?.data?.is_recorded;
  const hasTranscript = Boolean(activity.activity_data?.data?.transcript);

  // Add helper function to parse meeting times, consistent with our display logic
  const parseMeetingTime = (timeString) => {
    // Extract date and time parts ignoring the 'Z' at the end
    const [datePart, timePart] = timeString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes] = timePart.replace('Z', '').split(':').map(Number);
    
    // Create a date object with the parsed components
    // We need to subtract 1 from month because JavaScript months are 0-indexed
    return new Date(year, month - 1, day, hours, minutes);
  };

  // Update getBaseText to check if the meeting is upcoming using consistent time parsing
  const getBaseText = () => {
    if (activity.activity_type?.toLowerCase() === 'booking') {
      try {
        const bookingDetails = JSON.parse(activity.description);
        if (bookingDetails && bookingDetails.properties) {
          // Don't destructure variables we don't use
          return `created a new booking`;
        }
      } catch (error) {
        return title;
      }
    }
    switch (category) {
      case "email":
        return "sent an email";
      case "call":
        return `made a call${hasTranscript ? " (transcribed)" : ""}`;
      case "meeting":
        // Check if the meeting is upcoming
        if (activity?.activity_data?.data?.start_time) {
          // Use our custom parser that ignores UTC designation
          const startTime = parseMeetingTime(activity.activity_data.data.start_time);
          const now = new Date();
          
          // If meeting is in the future, use "scheduled" instead of "attended"
          if (startTime > now) {
            return `${isRecorded ? "scheduled a recorded" : "scheduled a"} meeting`;
          }
        }
        // For past meetings or if start_time is missing, use the original text
        return `${isRecorded ? "recorded" : "attended"} a meeting`;
      case "slack digest":
        return "created a channel summary";
      case "task":
        return "created a task";
      default:
        return "performed an activity";
    }
  };

  const renderTitle = () => {
    const baseText = getBaseText();
    if (activity.activity_type?.toLowerCase() === 'booking') {
      try {
        const bookingDetails = JSON.parse(activity.description);
        if (bookingDetails && bookingDetails.properties) {
          const { guests, total_price, booking_id } = bookingDetails.properties;
          
          // Format price to hide .00 for whole numbers
          const price = parseFloat(total_price);
          const formattedPrice = price % 1 === 0 ? price.toString() : price.toFixed(2);
          
          return (
            <>
              {baseText}: <Text as="span" fontWeight="medium">{guests} guests for £{formattedPrice} (Booking #{booking_id})</Text>
            </>
          );
        }
      } catch (error) {
        return <>{baseText}</>;
      }
    }
    if (!title) return baseText;
    return (
      <>
        {baseText}: <Text as="span" fontWeight="medium">{title}</Text>
      </>
    );
  };

  return (
    <Text>
      <Text as="span" fontWeight="semibold">
        {displayName}
      </Text>{" "}
      {renderTitle()}
    </Text>
  );
};

ActivityTitle.propTypes = {
  activity: ActivityPropType,
};

export default ActivityTitle;