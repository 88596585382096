import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthProvider } from "./auth-context";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./views/lysto/hooks/queryClient";
import AutoLogin from "./views/auth/signIn/login";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import PrivateRoute from "components/PrivateRoutes";
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import AuthCallback from "./views/auth/components/AuthCallback";
import LogoutCallback from "./views/auth/components/LogoutCallback";
import { SidebarProvider } from "./contexts/SidebarContext";
import DynamicRedirect from "./components/DynamicRedirect";

// Stored in Github Secrets
const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0_client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
const target =
  process.env.REACT_APP_AUTH_CALLBACK_URL ||
  "https://localhost:3000/#/auth/callback";

const root = createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={auth0_domain}
    clientId={auth0_client_id}
    authorizationParams={{
      audience: "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net",
      redirect_uri: target,
      scope:
        "openid profile email read:current_user read:user_idp_tokens read:calendar offline_access",
    }}
    cacheLocation="localstorage"
    useRefreshTokens={true}
  >
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <SidebarProvider>
            <HashRouter>
              <Routes>
                <Route path="/" element={<AutoLogin />} />
                <Route path="/auth" element={<AuthLayout />}>
                  <Route path="callback" element={<AuthCallback />} />
                  <Route path="logout" element={<LogoutCallback />} />
                  <Route
                    path="*"
                    element={<Navigate to="/auth/callback" replace />}
                  />
                </Route>

                {/* Add a direct route for /callback to handle Auth0 redirects */}
                <Route path="/callback" element={<AuthCallback />} />

                {/* Redirect /admin to appropriate route based on visible routes */}
                <Route path="/admin" element={<DynamicRedirect />} />

                <Route
                  path="/admin/*"
                  element={
                    <PrivateRoute>
                      <AdminLayout />
                    </PrivateRoute>
                  }
                />

                {/* Redirect all unknown routes to the dynamic default path */}
                <Route path="*" element={<DynamicRedirect />} />
              </Routes>
            </HashRouter>
          </SidebarProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </AuthProvider>
  </Auth0Provider>
);
