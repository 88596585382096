import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  FormErrorMessage,
  Select,
  Box,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useAuthData } from 'auth-context';
import useFetchAccounts from '../hooks/useFetchAccounts';
import { useUpdateContact } from '../hooks/useUpdateContact';

const EditContactModal = ({ isOpen, onClose, contact, initialFocusField }) => {
  const toast = useToast();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const { accounts, isLoading: isLoadingAccounts } = useFetchAccounts(accountID, "Lead");
  const updateContact = useUpdateContact(accountID);
  const inputRefs = React.useRef({});

  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    email: '',
    phone: '',
    title: '',
    linkedIn: '',
    city: '',
    state: '',
    country: '',
    priority: '',
    type: '',
    crm_account_id: '',
    department: '',
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (contact) {
      setFormData({
        name: contact.name || '',
        last_name: contact.last_name || '',
        email: contact.email || '',
        phone: contact.phone || '',
        title: contact.title || '',
        linkedIn: contact.linkedIn || '',
        city: contact.city || '',
        state: contact.state || '',
        country: contact.country || '',
        priority: contact.priority || '',
        type: contact.type || '',
        crm_account_id: contact.crm_account_id || '',
        department: contact.department || '',
      });
    }
  }, [contact]);

  // Initialize refs for all fields
  useEffect(() => {
    inputRefs.current = {
      name: React.createRef(),
      last_name: React.createRef(),
      email: React.createRef(),
      phone: React.createRef(),
      title: React.createRef(),
      linkedIn: React.createRef(),
      city: React.createRef(),
      state: React.createRef(),
      country: React.createRef(),
      priority: React.createRef(),
      type: React.createRef(),
      department: React.createRef(),
      crm_account_id: React.createRef(),
    };
  }, []);

  // Handle focus when modal opens
  useEffect(() => {
    if (isOpen && initialFocusField && inputRefs.current[initialFocusField]) {
      // Small delay to ensure the modal is fully rendered
      setTimeout(() => {
        const input = inputRefs.current[initialFocusField];
        if (input.current) {
          input.current.focus();
          
          // If it's an input, select all text
          if (input.current.tagName === 'INPUT') {
            input.current.select();
          }
        }
      }, 100);
    }
  }, [isOpen, initialFocusField]);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'First name is required';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      // Call the updateContact mutation; its onSuccess will update the cache correctly
      await updateContact.mutateAsync({
        contactId: contact.id,
        contactData: {
          ...formData,
          crm_account_id: formData.crm_account_id ? Number(formData.crm_account_id) : null,
        },
      });

      toast({
        title: 'Contact updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      console.error('Error updating contact:', error);
      toast({
        title: 'Error updating contact',
        description: error.response?.data?.message || 'An error occurred while updating the contact',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="xl"
      initialFocusRef={initialFocusField ? inputRefs.current[initialFocusField] : undefined}
    >
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>Edit Contact</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text fontSize="sm" color="gray.600" mb={2}>
              Update the contact details below.
            </Text>

            {/* Account Association Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Account Association
              </Text>
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Associated Account</FormLabel>
                  <Select
                    name="crm_account_id"
                    value={formData.crm_account_id}
                    onChange={handleInputChange}
                    placeholder="Select an account (optional)"
                    isDisabled={isLoadingAccounts}
                  >
                    {[...accounts]
                      .sort((a, b) => a.name?.localeCompare(b.name))
                      .map((account) => (
                        <option key={account.id} value={account.id}>
                          {account.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </VStack>
            </Box>

            {/* Required Information Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Required Information
              </Text>
              <VStack spacing={4}>
                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl isRequired isInvalid={!!errors.name}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Enter first name"
                      ref={inputRefs.current.name}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.last_name}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      placeholder="Enter last name"
                      ref={inputRefs.current.last_name}
                    />
                    <FormErrorMessage>{errors.last_name}</FormErrorMessage>
                  </FormControl>
                </SimpleGrid>

                <FormControl isRequired isInvalid={!!errors.email}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter email"
                    ref={inputRefs.current.email}
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
              </VStack>
            </Box>

            {/* Contact Details Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4}>
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Contact Details
              </Text>
              <VStack spacing={4}>
                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Enter phone number"
                      ref={inputRefs.current.phone}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Title</FormLabel>
                    <Input
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      placeholder="Enter job title"
                      ref={inputRefs.current.title}
                    />
                  </FormControl>
                </SimpleGrid>

                <FormControl>
                  <FormLabel>LinkedIn URL</FormLabel>
                  <Input
                    name="linkedIn"
                    value={formData.linkedIn}
                    onChange={handleInputChange}
                    placeholder="Enter LinkedIn profile URL"
                    type="url"
                    ref={inputRefs.current.linkedIn}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Department</FormLabel>
                  <Input
                    name="department"
                    value={formData.department}
                    onChange={handleInputChange}
                    placeholder="Enter department"
                    ref={inputRefs.current.department}
                  />
                </FormControl>
              </VStack>
            </Box>

            {/* Location Information Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4}>
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Location Information
              </Text>
              <VStack spacing={4}>
                <SimpleGrid columns={3} spacing={4} width="100%">
                  <FormControl>
                    <FormLabel>City</FormLabel>
                    <Input
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      placeholder="Enter city"
                      ref={inputRefs.current.city}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>State / Province / Region</FormLabel>
                    <Input
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      placeholder="Enter state, province, or region"
                      ref={inputRefs.current.state}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Country</FormLabel>
                    <Input
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      placeholder="Enter country"
                      ref={inputRefs.current.country}
                    />
                  </FormControl>
                </SimpleGrid>
              </VStack>
            </Box>

            {/* Additional Information Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4}>
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Additional Information
              </Text>
              <VStack spacing={4}>
                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl>
                    <FormLabel>Priority</FormLabel>
                    <Select
                      name="priority"
                      value={formData.priority}
                      onChange={handleInputChange}
                      placeholder="Select priority"
                      ref={inputRefs.current.priority}
                    >
                      <option value="VIP">VIP</option>
                      <option value="Standard">Standard</option>
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                      placeholder="Select type"
                      ref={inputRefs.current.type}
                    >
                      <option value="Lead">Lead</option>
                      <option value="Other">Other</option>
                    </Select>
                  </FormControl>
                </SimpleGrid>
              </VStack>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={updateContact.isPending}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

EditContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_id: PropTypes.number.isRequired,
    crm_account_id: PropTypes.number,
    crm_account_name: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    department: PropTypes.string,
    type: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    linkedIn: PropTypes.string,
    deals: PropTypes.array,
    accounts: PropTypes.array,
    created_date: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
  initialFocusField: PropTypes.string,
};

export default EditContactModal; 