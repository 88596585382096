import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";
import { useGoogleIntegrations } from "./useGoogleIntegrations";
import { useToast } from "@chakra-ui/react";
import { useSlackIntegration } from "./useSlackIntegration";

const target =
  process.env.REACT_APP_NODE_ENV === "local"
    ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
    : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

export const useIntegrations = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const { getAccessTokenSilently } = useAuth0();
  const [connectingService, setConnectingService] = useState(null);
  const toast = useToast();
  const { integrationStatus, refetch: refetchGoogleStatus } =
    useGoogleIntegrations();
  const { startSlackAuth } = useSlackIntegration();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [integrations, setIntegrations] = useState({
    gmail_integrations: [],
    calendar_integrations: [],
    typeform_integrations: [],
    slack_integrations: [],
  });

  // Check for Slack authentication return
  useEffect(() => {
    const handleSlackReturn = async () => {
      // Check if we're returning from Slack auth
      const slackReturnUrl = localStorage.getItem("slackReturnUrl");
      if (slackReturnUrl && window.location.href.includes("code=")) {
        // Clear the return URL
        localStorage.removeItem("slackReturnUrl");

        // Set connecting service to handle the completion
        setConnectingService("slack");

        // Small timeout to ensure state is set
        setTimeout(() => {
          handleAuthenticationComplete();

          // Navigate back to the return URL
          window.location.href = slackReturnUrl;
        }, 500);
      }
    };

    handleSlackReturn();
  }, []);

  useEffect(() => {
    const checkConnectionStatus = async () => {
      if (!accountID || !userID) return;

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        // Only check status if there's a connecting service
        if (connectingService) {
          // Use 'google' as the service for both gmail and calendar
          const service = ["gmail", "calendar"].includes(connectingService)
            ? "google"
            : connectingService;

          try {
            const response = await axios.get(
              `${target}/api/accounts/${accountID}/${service}/status`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );

            if (!response.data.connected) {
              // Service not connected
            }
          } catch (statusError) {
            // Handle 404 errors gracefully - the endpoint might not be implemented yet
            if (statusError.response?.status === 404) {
              // Status endpoint not found - normal if backend hasn't implemented API yet
            } else {
              // Other error checking connection status
            }
            // Don't rethrow - we want to continue even if this fails
          }
        }
      } catch (err) {
        // Error checking connection status
      }
    };

    checkConnectionStatus();
  }, [
    accountID,
    userID,
    getAccessTokenSilently,
    integrationStatus,
    connectingService,
  ]);

  useEffect(() => {
    const fetchIntegrations = async () => {
      if (!userData?.account_ID) return;

      // Check if we have cached data and it's not expired
      const cachedData = localStorage.getItem("integrationsCache");
      const cachedTimestamp = localStorage.getItem(
        "integrationsCacheTimestamp"
      );

      // If we have cached data and it's less than 10 minutes old, use it
      if (cachedData && cachedTimestamp) {
        const now = Date.now();
        const cacheAge = now - parseInt(cachedTimestamp, 10);
        const tenMinutesInMs = 10 * 60 * 1000;

        if (cacheAge < tenMinutesInMs) {
          try {
            const parsedCache = JSON.parse(cachedData);
            setIntegrations(parsedCache);
            setIsLoading(false);
            return;
          } catch (e) {
            // If parsing fails, proceed with fetching fresh data
            console.warn("Failed to parse cached integrations data");
          }
        }
      }

      try {
        setIsLoading(true);
        // Get the authentication token
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        // Fetch Google integrations with authentication token
        const googleResponse = await axios.get(
          `${target}/api/accounts/${userData.account_ID}/google/status`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const googleData = googleResponse.data;

        // Fetch Typeform integrations with authentication token
        const typeformResponse = await axios.get(
          `${target}/api/accounts/${userData.account_ID}/typeform/status`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const typeformData = typeformResponse.data;

        // Fetch Slack integrations with authentication token
        let slackData = { slack_integrations: [] };
        try {
          const slackResponse = await axios.get(
            `${target}/api/accounts/${userData.account_ID}/slack/status`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          slackData = slackResponse.data;
        } catch (slackError) {
          console.warn("Error fetching Slack integrations:", slackError);
          // Continue with other integrations even if Slack fails
        }

        const combinedData = {
          ...googleData,
          typeform_integrations: typeformData.typeform_integrations || [],
          slack_integrations: slackData.slack_integrations || [],
        };

        // Cache the results
        localStorage.setItem("integrationsCache", JSON.stringify(combinedData));
        localStorage.setItem(
          "integrationsCacheTimestamp",
          Date.now().toString()
        );

        setIntegrations(combinedData);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching integrations:", err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    if (userData?.account_ID) {
      fetchIntegrations();
    }
  }, [userData?.account_ID, getAccessTokenSilently]);

  const handleAuthenticationComplete = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "read:calendar",
        },
      });

      // Different API endpoint if we're checking Slack status
      const statusEndpoint =
        connectingService === "slack"
          ? `${target}/api/accounts/${accountID}/slack/status`
          : `${target}/api/accounts/${accountID}/google/status`;

      // Fetch the latest integrations status
      let currentIntegrations = {};
      try {
        const response = await axios.get(statusEndpoint, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        currentIntegrations = response.data;
        // For Slack, we need to adapt the structure to match what the rest of the code expects
        if (connectingService === "slack") {
          currentIntegrations = {
            slack_integrations: response.data.slack_integrations || [],
          };
        }
      } catch (statusError) {
        // Handle 404 for Slack gracefully - treat as empty integrations
        if (
          connectingService === "slack" &&
          statusError.response?.status === 404
        ) {
          console.warn(
            "Slack status endpoint not found, treating as empty integrations"
          );
          currentIntegrations = { slack_integrations: [] };

          // Create a placeholder integration based on the current user
          // This is temporary until the backend implements the proper endpoint
          currentIntegrations.slack_integrations.push({
            user_id: parseInt(userID),
            email: "Slack Workspace",
            workspace_name: "Slack Workspace",
            created_at: new Date().toISOString(),
            account_id: parseInt(accountID),
          });
        } else {
          // For other errors, rethrow
          throw statusError;
        }
      }

      if (connectingService === "gmail" || connectingService === "calendar") {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await refetchGoogleStatus();

        const currentEmails =
          currentIntegrations?.[`${connectingService}_integrations`]?.map(
            (integration) => integration.email
          ) || [];
        const previousEmails =
          integrations?.[`${connectingService}_integrations`]?.map(
            (integration) => integration.email
          ) || [];

        const hasNewEmail = currentEmails.some(
          (email) => !previousEmails.includes(email)
        );

        if (!hasNewEmail) {
          throw new Error(
            "No new integration was detected. Please try again and select a different Google account."
          );
        }
      } else if (connectingService === "slack") {
        // Check if we have any Slack integrations
        const hasSlackIntegrations =
          currentIntegrations?.slack_integrations?.length > 0;

        if (!hasSlackIntegrations) {
          throw new Error(
            "No Slack integration was detected. Please try again."
          );
        }
      } else {
        // Check if we have any integrations in the response
        const hasIntegrations =
          currentIntegrations?.gmail_integrations?.length > 0 ||
          currentIntegrations?.calendar_integrations?.length > 0 ||
          currentIntegrations?.slack_integrations?.length > 0;

        if (!hasIntegrations) {
          throw new Error(
            "No active integrations found. Please try connecting again."
          );
        }
      }

      // Update our integrations state with the latest data
      // For Slack we need to merge with existing integrations
      if (connectingService === "slack") {
        setIntegrations((prev) => ({
          ...prev,
          slack_integrations: currentIntegrations.slack_integrations,
        }));
      } else {
        setIntegrations(currentIntegrations);
      }

      toast({
        title: "Connected successfully",
        description: `New account has been connected to Lysto.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      console.error("Error verifying connection:", err);
      toast({
        title: "Connection failed",
        description: err.message,
        status: "error",
        duration: 7000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setConnectingService(null);
    }
  };

  const initiateConnection = async (service, permissionType) => {
    if (!accountID || !userID) return;

    try {
      setConnectingService(service);

      if (service === "slack") {
        // Set up global handler for when the Slack auth popup closes
        window.handleSlackAuthComplete = () => {
          handleAuthenticationComplete();
        };

        // For Slack, we use the specialized hook without permissionType
        await startSlackAuth();
        return;
      }

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "read:calendar",
        },
      });

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const popupWidth = 500;
      const popupHeight = 600;
      const left = screenWidth / 2 - popupWidth / 2;
      const top = screenHeight / 2 - popupHeight / 2;

      const features = `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`;
      const popupWindow = window.open("", "authWindow", features);
      if (popupWindow) {
        try {
          popupWindow.opener = null; // prevent reverse tabnabbing
        } catch (e) {
          console.error("Failed to nullify popupWindow.opener", e);
        }
      }
      if (!popupWindow) {
        throw new Error(
          "Popup was blocked. Please allow popups for this site."
        );
      }

      const response = await axios.get(
        `${target}/api/accounts/${accountID}/users/${userID}/${service}/auth?prompt=select_account&access_type=offline&permission_type=${permissionType}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const authURL = response.data.auth_url;

      if (authURL) {
        const finalAuthURL = new URL(authURL);
        finalAuthURL.searchParams.set("prompt", "select_account");
        finalAuthURL.searchParams.set("access_type", "offline");
        finalAuthURL.searchParams.delete("approval_prompt");
        popupWindow.location.href = finalAuthURL.toString();
      }

      const pollTimer = setInterval(() => {
        if (popupWindow.closed) {
          clearInterval(pollTimer);
          handleAuthenticationComplete();
        }
      }, 500);
    } catch (err) {
      console.error("Error Authenticating:", err);
      toast({
        title: "Connection failed",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setConnectingService(null);
    }
  };

  const sortedIntegrations = [
    ...(integrations.gmail_integrations || []).map((integration) => ({
      type: "gmail",
      connection: {
        email: integration.email,
        expiration: integration.expiration,
        permission_type: integration.permission_type,
        created_at: integration.created_at,
        user_id: integration.user_id,
      },
    })),
    ...(integrations.calendar_integrations || []).map((integration) => ({
      type: "calendar",
      connection: {
        email: integration.email,
        expires_at: integration.expires_at,
        permission_type: integration.permission_type,
        created_at: integration.created_at,
        user_id: integration.user_id,
      },
    })),
    ...(integrations.typeform_integrations || []).map((integration) => ({
      type: "typeform",
      connection: {
        email: integration.email,
        permission_type: integration.permission_type,
        created_at: integration.created_at,
        user_id: integration.user_id,
      },
    })),
    ...(integrations.slack_integrations || []).map((integration) => ({
      type: "slack",
      connection: {
        email:
          integration.email ||
          integration.workspace_name ||
          "Slack Integration",
        permission_type: integration.permission_type || "personal",
        created_at: integration.created_at,
        user_id: integration.user_id,
        status: integration.status
      },
    })),
  ].sort((a, b) => {
    const aActive =
      a.type === "gmail"
        ? new Date(a.connection.expiration * 1000) > new Date()
        : a.type === "typeform"
        ? true // Typeform is always active if we have a record
        : a.type === "slack" 
        ? a.connection.status === "active" // Use status field for Slack
        : new Date(a.connection.expires_at) > new Date();
    const bActive =
      b.type === "gmail"
        ? new Date(b.connection.expiration * 1000) > new Date()
        : b.type === "typeform"
        ? true // Typeform is always active if we have a record
        : b.type === "slack"
        ? b.connection.status === "active" // Use status field for Slack
        : new Date(b.connection.expires_at) > new Date();
    return bActive - aActive;
  });

  return {
    isLoading,
    error,
    sortedIntegrations,
    initiateConnection,
    connectingService,
  };
};
