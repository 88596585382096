import React from 'react';
import PropTypes from 'prop-types';
import { Center, Box, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

// Create a logo component as a placeholder (you'll need to update this with your actual logo)
const LystoLogo = (props) => (
  <Box {...props}>
    <svg viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="40" fill="currentColor" />
      <text x="50" y="55" fontFamily="Arial" fontSize="16" fill="white" textAnchor="middle">
        LYSTO
      </text>
    </svg>
  </Box>
);

const MotionBox = motion(Box);

const LoadingOverlay = ({ status }) => {
  if (status === "sending") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          animate={{
            scale: [1, 1.1, 1],
            opacity: [1, 0.7, 1]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            filter="drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium">Sending your message...</Text>
      </Center>
    );
  }

  if (status === "success") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", duration: 0.5 }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            filter="drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium">Message sent successfully!</Text>
        <Text fontSize="sm" mt={2}>Redirecting to deal details...</Text>
      </Center>
    );
  }

  if (status === "error") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          animate={{ 
            rotate: [-5, 5, -5, 0],
          }}
          transition={{ 
            duration: 0.5,
            ease: "easeInOut",
          }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            opacity={0.7}
            filter="drop-shadow(0 0 8px rgba(255, 0, 0, 0.3))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium" color="red.200">Failed to send message</Text>
      </Center>
    );
  }

  return null;
};

LoadingOverlay.propTypes = {
  status: PropTypes.oneOf(['sending', 'success', 'error']),
};

export default LoadingOverlay; 