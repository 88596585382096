// src/components/Shared/PageHeader.jsx

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Heading,
  Icon,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  ButtonGroup,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  Text,
  Link,
  HStack,
  Portal,
  InputGroup,
  Input,
  InputLeftElement,
  List,
  ListItem,
} from "@chakra-ui/react";
import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import { MdSettings, MdLogout } from "react-icons/md";
import PropTypes from "prop-types";
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const PageHeader = ({
  title,
  segmentOptions,
  selectedSegment,
  onSegmentChange,
  searchPlaceholder = "Search...",
  onSearchChange,
  searchSuggestions = [],
  searchQuery = "",
  onSuggestionSelect = null,
  showSearch = false,
  additionalControls = null,
  isDetailView = false,
  searchRightElement = null,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const userData = useAuthData();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  
  // Add state for tooltip
  const [hoveredOption, setHoveredOption] = useState(null);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTimerRef = useRef(null);
  
  // Check if we should show segment options
  const shouldShowSegmentOptions = segmentOptions && segmentOptions.length > 1;
  
  // Log when the component renders or re-renders
  useEffect(() => {
    // console.log('[PageHeader DEBUG] PageHeader rendered with:', {
    //   title, 
    //   selectedSegment,
    //   segmentOptions: segmentOptions ? segmentOptions.map(opt => ({ label: opt.label, value: opt.value })) : null,
    //   timestamp: new Date().toISOString()
    // });
  }, [title, selectedSegment, segmentOptions]);
  
  // Log when selected segment changes
  useEffect(() => {
    // console.log('[PageHeader DEBUG] Selected segment changed:', {
    //   selectedSegment,
    //   timestamp: new Date().toISOString()
    // });
  }, [selectedSegment]);

  // Clean up the timer when component unmounts
  useEffect(() => {
    return () => {
      if (tooltipTimerRef.current) {
        clearTimeout(tooltipTimerRef.current);
      }
    };
  }, []);

  // Theme Colors
  const searchBg = useColorModeValue("white", "gray.600");
  const searchBorderColor = useColorModeValue("gray.300", "gray.500");
  const suggestionBg = useColorModeValue("white", "gray.700");
  const suggestionBorderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.100", "gray.600");
  const highlightBg = "blue.500";
  const highlightColor = "white";
  const popoverBg = useColorModeValue("white", "gray.700");
  const popoverBorderColor = useColorModeValue("gray.200", "gray.600");
  
  // Button group colors
  const selectedBg = useColorModeValue("blue.500", "blue.300");
  const selectedTextColor = useColorModeValue("white", "gray.800");
  const unselectedBg = useColorModeValue("white", "gray.600");
  const unselectedTextColor = useColorModeValue("gray.700", "gray.200");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.500");

  // Add tooltip colors
  const tooltipBg = useColorModeValue("gray.700", "white");
  const tooltipTextColor = useColorModeValue("white", "gray.700");

  const handleMouseEnter = (option) => {
    setHoveredOption(option);
    // Clear any existing timer
    if (tooltipTimerRef.current) {
      clearTimeout(tooltipTimerRef.current);
    }
    // Set a new timer to show the tooltip after 2 seconds
    tooltipTimerRef.current = setTimeout(() => {
      setShowTooltip(true);
    }, 1000);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
    setShowTooltip(false);
    // Clear the timer when mouse leaves
    if (tooltipTimerRef.current) {
      clearTimeout(tooltipTimerRef.current);
      tooltipTimerRef.current = null;
    }
  };

  const handleMouseMove = (e) => {
    setMousePos({ x: e.clientX, y: e.clientY });
  };

  const getTooltipLabel = (option) => {
    let tooltipLabel = option.tooltip || option.label;
    
    // Add default tooltips for common filter options
    if (!option.tooltip) {
      if (option.value === "all") {
        tooltipLabel = "Show all items";
      } else if (option.value === "active") {
        tooltipLabel = "Show items with activity in the past two weeks";
      } else if (option.value === "inactive") {
        tooltipLabel = "Show items with no activity in the past two weeks";
      }
    }
    
    return tooltipLabel;
  };
  
  // Custom handler for segment change to add logging
  const handleSegmentChange = (value) => {
    if (onSegmentChange) {
      onSegmentChange(value);
    }
  };

  const renderHighlightText = (text, query) => {
    if (!text) return '';
    if (!query) return text;
    const escapedQuery = escapeRegExp(query);
    const regex = new RegExp(`(${escapedQuery})`, "gi");
    const parts = text.split(regex);

    return (
      <Box as="span">
        {parts.map((part, index) =>
          regex.test(part) ? (
            <Box
              as="mark"
              bg={highlightBg}
              color={highlightColor}
              key={index}
              px={1}
              borderRadius="sm"
              whiteSpace="nowrap"
              display="inline"
            >
              {part}
            </Box>
          ) : (
            part
          )
        )}
      </Box>
    );
  };

  const handleSuggestionClick = (suggestion) => {
    if (onSuggestionSelect) {
      onSuggestionSelect(suggestion);
      setShowSuggestions(false);
    }
  };

  const handleBlur = () => {
    // Delay hiding suggestions to allow click events to register
    setTimeout(() => setShowSuggestions(false), 200);
  };

  const handleFocus = () => {
    if (searchQuery && searchSuggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  // If there's only one segment option and no search or additional controls,
  // we only need to show the profile section
  const showOnlyProfile = !shouldShowSegmentOptions && !showSearch && !additionalControls && !searchRightElement;
  
  if (showOnlyProfile) {
    return (
      <Box w="100%" mb={isDetailView ? 0 : 6}>
        {/* Header with only title and profile */}
        <Flex
          flexDirection="row"
          mb="4"
          align="center"
          justify="space-between"
          w="100%"
        >
          <Heading
            size="lg"
            color={useColorModeValue("gray.700", "white")}
            mb={0}
            flex="1"
            isTruncated
          >
            {title}
          </Heading>

          {/* Profile Component */}
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <Box
                as="button"
                display="flex"
                alignItems="center"
                cursor="pointer"
                borderRadius="lg"
                p={2}
                _hover={{ bg: hoverBg }}
              >
                <Avatar
                  size="sm"
                  name={userData?.name}
                  bg="#1A5BF6"
                  color="white"
                  borderRadius="xl"
                  mr={{ base: 0, md: 3 }}
                  fontWeight="700"
                />
                <Text 
                  fontWeight="medium" 
                  color={useColorModeValue("gray.700", "white")}
                  display={{ base: "none", md: "block" }}
                >
                  {userData?.name}
                </Text>
                <ChevronDownIcon 
                  display={{ base: "none", md: "block" }}
                  ml={1}
                  color={useColorModeValue("gray.500", "gray.400")} 
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent
              bg={popoverBg}
              borderColor={popoverBorderColor}
              boxShadow="lg"
              _focus={{ outline: "none" }}
              minW="160px"
              maxW="280px"
              w="auto"
            >
              <PopoverBody py={3}>
                <VStack spacing={2} align="stretch">
                  <Box px={3} py={2}>
                    <Text fontWeight="500">{userData?.name}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {userData?.email}
                    </Text>
                  </Box>
                  <Link
                    px={3}
                    py={2}
                    borderRadius="lg"
                    _hover={{ bg: hoverBg }}
                    onClick={() => navigate('/admin/settings')}
                    cursor="pointer"
                    display="block"
                    w="100%"
                  >
                    <HStack spacing={3}>
                      <Icon as={MdSettings} boxSize={5} />
                      <Text fontWeight="500">Settings</Text>
                    </HStack>
                  </Link>
                  <Link
                    px={3}
                    py={2}
                    borderRadius="md"
                    color="red.500"
                    _hover={{ bg: hoverBg }}
                    onClick={() => logout({ returnTo: window.location.origin })}
                    cursor="pointer"
                    display="block"
                    w="100%"
                  >
                    <HStack spacing={3}>
                      <Icon as={MdLogout} boxSize={5} />
                      <Text fontWeight="500">Log Out</Text>
                    </HStack>
                  </Link>
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Box>
    );
  }

  return (
    <Box w="100%" mb={isDetailView ? 0 : 6}>
      {/* Header */}
      <Flex
        flexDirection="row"
        mb="4"
        align="center"
        justify="space-between"
        w="100%"
      >
        <Heading
          size="lg"
          color={useColorModeValue("gray.700", "white")}
          mb={0}
          flex="1"
          isTruncated
        >
          {title}
        </Heading>

        {/* Profile Component */}
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <Box
              as="button"
              display="flex"
              alignItems="center"
              cursor="pointer"
              borderRadius="lg"
              p={2}
              _hover={{ bg: hoverBg }}
            >
              <Avatar
                size="sm"
                name={userData?.name}
                bg="#1A5BF6"
                color="white"
                borderRadius="xl"
                mr={{ base: 0, md: 3 }}
                fontWeight="700"
              />
              <Text 
                fontWeight="medium" 
                color={useColorModeValue("gray.700", "white")}
                display={{ base: "none", md: "block" }}
              >
                {userData?.name}
              </Text>
              <ChevronDownIcon 
                display={{ base: "none", md: "block" }}
                ml={1}
                color={useColorModeValue("gray.500", "gray.400")} 
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent
            bg={popoverBg}
            borderColor={popoverBorderColor}
            boxShadow="lg"
            _focus={{ outline: "none" }}
            minW="160px"
            maxW="280px"
            w="auto"
          >
            <PopoverBody py={3}>
              <VStack spacing={2} align="stretch">
                <Box px={3} py={2}>
                  <Text fontWeight="500">{userData?.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {userData?.email}
                  </Text>
                </Box>
                <Link
                  px={3}
                  py={2}
                  borderRadius="lg"
                  _hover={{ bg: hoverBg }}
                  onClick={() => navigate('/admin/settings')}
                  cursor="pointer"
                  display="block"
                  w="100%"
                >
                  <HStack spacing={3}>
                    <Icon as={MdSettings} boxSize={5} />
                    <Text fontWeight="500">Settings</Text>
                  </HStack>
                </Link>
                <Link
                  px={3}
                  py={2}
                  borderRadius="md"
                  color="red.500"
                  _hover={{ bg: hoverBg }}
                  onClick={() => logout({ returnTo: window.location.origin })}
                  cursor="pointer"
                  display="block"
                  w="100%"
                >
                  <HStack spacing={3}>
                    <Icon as={MdLogout} boxSize={5} />
                    <Text fontWeight="500">Log Out</Text>
                  </HStack>
                </Link>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>

      {/* Controls Container */}
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={{ base: 3, md: 6 }}
        justify="space-between"
        align={{ base: "stretch", md: "center" }}
        w="100%"
        mt={{ base: 2, md: 0 }}
      >
        {/* Left Controls: Segment Options and Filter Button */}
        <Flex gap={4} align="center">
          {shouldShowSegmentOptions && segmentOptions.length <= 3 ? (
            <ButtonGroup isAttached variant="outline" spacing={0}>
              {segmentOptions.map((option) => {
                const isSelected = selectedSegment === option.value;
                
                return (
                  <Button
                    key={option.value}
                    onClick={() => handleSegmentChange(option.value)}
                    bg={isSelected ? selectedBg : unselectedBg}
                    color={isSelected ? selectedTextColor : unselectedTextColor}
                    _hover={{
                      bg: isSelected ? selectedBg : buttonHoverBg,
                      color: isSelected ? selectedTextColor : "inherit",
                    }}
                    _active={{
                      bg: isSelected ? selectedBg : buttonHoverBg,
                      color: isSelected ? selectedTextColor : "inherit",
                    }}
                    borderRadius="md"
                    minW="100px"
                    borderWidth="1px"
                    borderColor={useColorModeValue("gray.300", "gray.500")}
                    onMouseEnter={() => handleMouseEnter(option)}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleMouseMove}
                  >
                    {option.label}
                  </Button>
                );
              })}
            </ButtonGroup>
          ) : shouldShowSegmentOptions ? (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                variant="outline"
                bg={unselectedBg}
                borderRadius="md"
                borderColor={useColorModeValue("gray.300", "gray.500")}
                _hover={{ bg: buttonHoverBg }}
                color={unselectedTextColor}
              >
                {segmentOptions.find(opt => opt.value === selectedSegment)?.label || "Select Option"}
              </MenuButton>
              <MenuList>
                {segmentOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => {
                      handleSegmentChange(option.value);
                    }}
                    onMouseEnter={() => handleMouseEnter(option)}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleMouseMove}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          ) : null}
          
          {additionalControls && <Box>{additionalControls}</Box>}
        </Flex>

        {/* Right Controls */}
        <Flex 
          justify={{ base: "flex-start", md: "flex-end" }}
          align="center"
          gap={4}
        >
          {/* Search Bar */}
          {showSearch && (
            <Box position="relative" w={{ base: "100%", md: "224px" }}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={SearchIcon} color="gray.400" />
                </InputLeftElement>
                <Input
                  placeholder={searchPlaceholder}
                  value={searchQuery}
                  onChange={(e) => onSearchChange && onSearchChange(e.target.value)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  bg={searchBg}
                  borderColor={searchBorderColor}
                  _hover={{ borderColor: "gray.400" }}
                  _focus={{ borderColor: "blue.500", boxShadow: "none" }}
                  borderRadius="md"
                />
              </InputGroup>
              
              {/* Search suggestions */}
              {showSuggestions && searchSuggestions.length > 0 && searchQuery && (
                <Box
                  position="absolute"
                  top="100%"
                  left={0}
                  right={0}
                  mt={1}
                  bg={suggestionBg}
                  borderWidth="1px"
                  borderColor={suggestionBorderColor}
                  borderRadius="md"
                  boxShadow="md"
                  zIndex={10}
                  maxH="250px"
                  overflowY="auto"
                >
                  <List spacing={0}>
                    {searchSuggestions.map((suggestion) => (
                      <ListItem
                        key={suggestion.id}
                        px={3}
                        py={2}
                        _hover={{ bg: hoverBg }}
                        cursor="pointer"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {renderHighlightText(suggestion.label, searchQuery)}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Box>
          )}
          
          {/* Additional right element */}
          {searchRightElement && (
            <Box>{searchRightElement}</Box>
          )}
        </Flex>
      </Flex>
      
      {/* Portal-based tooltip */}
      {showTooltip && hoveredOption && (
        <Portal>
          <Box
            position="fixed"
            left={mousePos.x + 10}
            top={mousePos.y + 10}
            bg={tooltipBg}
            color={tooltipTextColor}
            px="8px"
            py="4px"
            fontSize="sm"
            borderRadius="md"
            zIndex="tooltip"
            pointerEvents="none"
            boxShadow="sm"
          >
            {getTooltipLabel(hoveredOption)}
          </Box>
        </Portal>
      )}
    </Box>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  segmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
    })
  ),
  selectedSegment: PropTypes.string,
  onSegmentChange: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  onSearchChange: PropTypes.func,
  searchSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  searchQuery: PropTypes.string,
  onSuggestionSelect: PropTypes.func,
  showSearch: PropTypes.bool,
  additionalControls: PropTypes.node,
  isDetailView: PropTypes.bool,
  searchRightElement: PropTypes.node,
};

export default PageHeader;
