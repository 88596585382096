import React from "react";
import { Box, VStack, Text, Spinner, Alert, AlertIcon } from "@chakra-ui/react";
import ApexCharts from "react-apexcharts";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../constants";
import { usePipelineAnalysisData } from "../../../hooks/usePipelineAnalysisData";
import { processPipelineData } from "./pipelineAnalysis";

const WaterfallChart = ({ data, title }) => {
  if (!data || data.length === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        No pipeline data available for the selected time period.
      </Alert>
    );
  }

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "20%",
        colors: {
          ranges: [
            {
              from: -1000000,
              to: 0,
              color: "#FF4560",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val >= 0 ? `$${Math.abs(val)}K` : `-$${Math.abs(val)}K`;
      },
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      background: {
        enabled: true,
        foreColor: "#304758",
        padding: 4,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
      },
    },
    colors: [PRIMARY_COLOR],
    xaxis: {
      categories: data.map((d) => d.label),
      labels: {
        style: {
          fontSize: "12px",
          fontWeight: 500,
        },
        rotate: -45,
        rotateAlways: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `$${val}K`;
        },
      },
      title: {
        text: "Amount ($K)",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return `$${Math.abs(val)}K`;
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
      padding: {
        bottom: 15,
      },
    },
  };

  return (
    <Box
      bg="white"
      p={6}
      borderRadius="lg"
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.200"
    >
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        {title}
      </Text>
      <ApexCharts
        options={chartOptions}
        series={[
          {
            name: "Pipeline Flow",
            data: data.map((d) => d.value),
          },
        ]}
        type="bar"
        height={400}
      />
    </Box>
  );
};

const MonthlyChangesChart = ({
  monthlyData,
  selectedYear,
  selectedSegment = "All",
}) => {
  if (!monthlyData?.months?.length || !monthlyData?.series?.length) {
    return (
      <Alert status="info">
        <AlertIcon />
        No monthly changes data available for the selected time period.
      </Alert>
    );
  }

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "60%",
        dataLabels: {
          position: "center",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val !== 0 ? `$${Math.abs(val.toFixed(1))}K` : "";
      },
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      background: {
        enabled: true,
        foreColor: "#304758",
        padding: 4,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
      },
    },
    colors: [
      PRIMARY_COLOR,
      "#63B3ED", // Light blue
      "#FC8181", // Light red
      "#48BB78", // Green
      SECONDARY_COLOR,
      "#E53E3E", // Red
      "#2B6CB0", // Dark blue
    ],
    xaxis: {
      categories: monthlyData.months,
      labels: {
        style: {
          fontSize: "12px",
          fontWeight: 500,
        },
      },
      title: {
        text: `${selectedYear}`,
        offsetY: 20,
        style: {
          fontSize: "14px",
          fontWeight: 600,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `$${val}K`;
        },
      },
      title: {
        text: "Amount ($K)",
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetY: -8,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return `$${Math.abs(val.toFixed(1))}K`;
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
      padding: {
        bottom: 15,
      },
    },
  };

  return (
    <Box
      bg="white"
      p={6}
      borderRadius="lg"
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.200"
      mt={6}
    >
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        Changes by Month ({selectedYear}
        {selectedSegment !== "All" ? ` - ${selectedSegment}` : ""})
      </Text>
      <Text fontSize="sm" color="gray.500" mb={4}>
        Monthly breakdown of pipeline changes
      </Text>
      <ApexCharts
        options={chartOptions}
        series={monthlyData.series}
        type="bar"
        height={400}
      />
    </Box>
  );
};

const PipelineAnalysisGrid = ({
  accountId,
  selectedYear,
  selectedQuarter,
  selectedPeriod = "year",
  selectedSegment = "All",
}) => {
  const {
    data: dealHistoryData,
    isLoading,
    isError,
    error,
  } = usePipelineAnalysisData({
    accountId,
    selectedYear,
    selectedPeriod,
    selectedQuarter,
    selectedSegment,
  });

  const pipelineAnalysis = React.useMemo(() => {
    if (!dealHistoryData || !Array.isArray(dealHistoryData)) {
      return null;
    }

    if (dealHistoryData.length === 0) {
      return null;
    }

    const analysis = processPipelineData(
      dealHistoryData,
      selectedYear,
      selectedPeriod,
      selectedQuarter
    );

    return analysis;
  }, [dealHistoryData, selectedYear, selectedPeriod, selectedQuarter]);

  if (isLoading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" color={PRIMARY_COLOR} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        Error loading pipeline data: {error?.message || "Unknown error"}
      </Alert>
    );
  }

  if (
    !pipelineAnalysis ||
    !pipelineAnalysis.pipelineFlowData?.length ||
    !pipelineAnalysis.monthlyChanges?.months?.length
  ) {
    return (
      <Alert status="info">
        <AlertIcon />
        No pipeline data available for the selected time period.
      </Alert>
    );
  }

  return (
    <VStack spacing={6} align="stretch" width="100%">
      <WaterfallChart
        data={pipelineAnalysis.pipelineFlowData}
        title={`Pipeline Flow Analysis (${selectedYear}${
          selectedSegment !== "All" ? ` - ${selectedSegment}` : ""
        })`}
      />
      <MonthlyChangesChart
        monthlyData={pipelineAnalysis.monthlyChanges}
        selectedYear={selectedYear}
        selectedSegment={selectedSegment}
      />
    </VStack>
  );
};

export default PipelineAnalysisGrid;
