import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Box,
  Text,
  SimpleGrid,
  IconButton,
  useToast,
  Select,
  HStack,
  Checkbox,
  Skeleton,
  Stack,
  Collapse,
  Flex,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { MdAdd, MdDelete, MdWarning, MdDragIndicator } from 'react-icons/md';
import { useEmailSender } from '../hooks/useEmailSender';
import { useEnhancedPipelines } from '../hooks/useEnhancedPipelines';
import { useAuthData } from '../../../auth-context';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const EditPipelineModal = ({ isOpen, onClose, pipeline }) => {
  const toast = useToast();
  const { getCurrentUserSenders, getTeamSenders } = useEmailSender();
  const [isLoading, setIsLoading] = useState(false);
  const initCompletedRef = useRef(false);
  const expandedStagesRef = useRef({});
  const [stageToDelete, setStageToDelete] = useState(null);
  const [stageDealCount, setStageDealCount] = useState(0);
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const { 
    isOpen: isUnsavedChangesAlertOpen, 
    onOpen: onUnsavedChangesAlertOpen, 
    onClose: onUnsavedChangesAlertClose 
  } = useDisclosure();
  const cancelRef = useRef();
  const queryClient = useQueryClient();
  
  // Get account ID from auth context
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  
  // Get the getStageDealCount function from useEnhancedPipelines
  const { getStageDealCount } = useEnhancedPipelines(accountID);

  // Store the original form data to detect changes
  const originalFormDataRef = useRef(null);

  // Get personal and team email accounts
  const personalAccounts = React.useMemo(() => {
    return getCurrentUserSenders() || [];
  }, [getCurrentUserSenders]);
  
  const teamAccounts = React.useMemo(() => {
    return getTeamSenders() || [];
  }, [getTeamSenders]);

  // All available email accounts (for matching against saved accounts)
  const allAvailableAccounts = React.useMemo(() => {
    return [...personalAccounts, ...teamAccounts];
  }, [personalAccounts, teamAccounts]);

  const [formData, setFormData] = useState({
    pipelineName: '',
    offering: '',
    customerDefinition: '',
    stages: [],
    selectedEmailAccounts: [],
    businessDescription: '',
    websiteUrl: '',
    targetCustomerSize: '',
    targetCustomerLocation: '',
    is_active: true, // Default to true for new pipelines
  });

  // Create a convenience function to get/set expandedStages without triggering re-renders
  const toggleStage = (index) => {
    expandedStagesRef.current[index] = !expandedStagesRef.current[index];
    // Force a re-render
    forceUpdate();
  };

  // Add a forceUpdate function using useState
  const [, forceRender] = useState({});
  const forceUpdate = () => {
    forceRender({});
  };

  // Handle drag and drop reordering
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // If dropped in the same place, do nothing
    if (sourceIndex === destinationIndex) {
      return;
    }

    const newStages = Array.from(formData.stages);
    const [reorderedItem] = newStages.splice(sourceIndex, 1);
    newStages.splice(destinationIndex, 0, reorderedItem);

    setFormData(prev => ({ ...prev, stages: newStages }));

    // Update expanded state references to match the new order
    const newExpandedStages = {};
    newStages.forEach((stage, index) => {
      // Find the original index of the stage before reordering
      const originalIndex = formData.stages.findIndex(s => s === stage);
      if (originalIndex !== -1 && expandedStagesRef.current[originalIndex]) {
        newExpandedStages[index] = true;
      }
    });
    expandedStagesRef.current = newExpandedStages;
    // No need to force update here, setFormData will trigger re-render
  };

  // Replace the useEffect for expandedStages
  useEffect(() => {
    if (pipeline && isOpen && !initCompletedRef.current) {  // Only run once when the modal opens
      // Debug log to inspect the structure of pipeline.stages
      // console.log('Pipeline stages data:', pipeline.stages);
      
      // Process email accounts to ensure they have the proper structure
      let processedEmailAccounts = [];
      // Check for both emailAccounts (old field name) and emailIntegrations (new field name from API)
      if (pipeline.emailIntegrations?.length) {
        processedEmailAccounts = pipeline.emailIntegrations.map(savedAccount => {
          // Try to find a matching account in the available accounts to get the most up-to-date properties
          const matchingAccount = allAvailableAccounts.find(
            availableAccount => availableAccount.email === savedAccount.email
          );

          if (matchingAccount) {
            // If we find a matching account, use its properties but preserve the saved userId
            return {
              email: matchingAccount.email,
              userId: savedAccount.userId || savedAccount.user_id || matchingAccount.userId || matchingAccount.user_id,
            };
          } else {
            // If no matching account is found, use the saved account as is, ensuring userId exists
            return {
              email: savedAccount.email,
              userId: savedAccount.userId || savedAccount.user_id || '',
            };
          }
        });
      } else if (pipeline.emailAccounts?.length) {
        // Fallback for older data structure if it exists
        processedEmailAccounts = pipeline.emailAccounts.map(savedAccount => {
          // Same processing as above
          const matchingAccount = allAvailableAccounts.find(
            availableAccount => availableAccount.email === savedAccount.email
          );

          if (matchingAccount) {
            return {
              email: matchingAccount.email,
              userId: savedAccount.userId || savedAccount.user_id || matchingAccount.userId || matchingAccount.user_id,
            };
          } else {
            return {
              email: savedAccount.email,
              userId: savedAccount.userId || savedAccount.user_id || '',
            };
          }
        });
      }

      const initialFormData = {
        pipelineName: pipeline.pipelineName || '',
        offering: pipeline.offering || '',
        customerDefinition: pipeline.customerDefinition || '',
        stages: pipeline.stages?.map(stage => ({
          name: stage.name || '',
          description: stage.description || '',
          passingCriteria: stage.passing_criteria || stage.passingCriteria || '',
          triggerType: stage.triggerType || 'qa',
          triggerConfig: stage.triggerConfig || {},
          id: stage.id || null, // Make sure to include the stage ID if available
          // Also preserve backend-specific fields
          probability: stage.probability,
          isClosed: stage.is_closed,
          isWon: stage.is_won,
          isActive: stage.is_active !== undefined ? stage.is_active : true,
        })) || [],
        // Use the processed email accounts
        selectedEmailAccounts: processedEmailAccounts,
        businessDescription: pipeline.businessDescription || pipeline.businessDefinition || '',
        websiteUrl: pipeline.websiteUrl || '',
        targetCustomerSize: pipeline.targetCustomerSize || pipeline.qualificationCriteria?.companySize || '',
        targetCustomerLocation: pipeline.targetCustomerLocation || pipeline.qualificationCriteria?.location || '',
        is_active: pipeline.is_active !== undefined ? pipeline.is_active : true, // Store the is_active state
      };
      
      setFormData(initialFormData);
      // Store the original form data for comparison
      originalFormDataRef.current = JSON.stringify(initialFormData);
      
      // Reset expanded stages when modal opens
      expandedStagesRef.current = {};
      
      // Mark initialization as complete
      initCompletedRef.current = true;
    }
    
    // Reset the init flag when modal closes
    if (!isOpen) {
      initCompletedRef.current = false;
    }
  }, [pipeline, allAvailableAccounts, isOpen]);

  // Check if there are unsaved changes
  const hasUnsavedChanges = () => {
    if (!originalFormDataRef.current) return false;
    
    const currentFormData = JSON.stringify(formData);
    return currentFormData !== originalFormDataRef.current;
  };

  // Handle modal close with confirmation if there are unsaved changes
  const handleModalClose = () => {
    if (hasUnsavedChanges()) {
      onUnsavedChangesAlertOpen();
    } else {
      closeModal();
    }
  };

  // Close modal without confirmation
  const closeModal = () => {
    // Reset expanded stages when modal is closed
    expandedStagesRef.current = {};
    initCompletedRef.current = false;
    onClose();
  };

  const handleInputChange = (e, section, index) => {
    const { name, value } = e.target;
    
    if (section === 'stages') {
      const newStages = [...formData.stages];
      newStages[index] = { ...newStages[index], [name]: value };
      setFormData(prev => ({ ...prev, stages: newStages }));
    } else if (section === 'targetCustomer') {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const addStage = () => {
    const newStages = [
      ...formData.stages,
      {
        name: '',
        description: '',
        passingCriteria: '',
        triggerType: 'qa',
        triggerConfig: {},
        id: null, // New stages don't have an ID yet
      },
    ];
    setFormData(prev => ({
      ...prev,
      stages: newStages,
    }));
    
    // Automatically expand the newly added stage
    const newIndex = newStages.length - 1;
    expandedStagesRef.current[newIndex] = true;
    forceUpdate();
  };

  // Check if a stage has deals before deleting
  const checkAndRemoveStage = async (index) => {
    // Don't allow removing the last stage
    if (formData.stages.length <= 1) {
      toast({
        title: "Cannot remove stage",
        description: "A pipeline must have at least one stage.",
        status: "warning",
        duration: 3000,
      });
      return;
    }
    
    const stage = formData.stages[index];
    
    // If the stage doesn't have an ID (it's a new stage), we can remove it directly
    if (!stage.id || !pipeline?.id) {
      removeStage(index);
      return;
    }
    
    // Check if the stage has deals
    try {
      setIsLoading(true);
      const dealCount = await getStageDealCount(pipeline.id, stage.id);
      setStageDealCount(dealCount);
      
      if (dealCount > 0) {
        // Store the stage index to delete
        setStageToDelete(index);
        
        // If there's only one stage and it has deals, show a special warning
        if (formData.stages.length === 1) {
          toast({
            title: "Cannot remove stage",
            description: `This stage has ${dealCount} deals. You need to create another stage first so these deals can be moved.`,
            status: "error",
            duration: 5000,
          });
          setIsLoading(false);
          return;
        }
        
        // Open the warning dialog
        onAlertOpen();
      } else {
        // No deals, safe to remove
        removeStage(index);
      }
    } catch (error) {
      console.error("Error checking stage deals:", error);
      toast({
        title: "Error checking stage deals",
        description: "Could not verify if this stage has deals. Please try again.",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Confirm stage deletion after warning
  const confirmRemoveStage = () => {
    if (stageToDelete !== null) {
      removeStage(stageToDelete);
      setStageToDelete(null);
      onAlertClose();
    }
  };

  const removeStage = (index) => {
    // Remove the stage
    setFormData(prev => ({
      ...prev,
      stages: prev.stages.filter((_, i) => i !== index),
    }));
    
    // Update expanded stages after removal
    const newExpandedStages = {};
    // Copy over the expanded state for stages before the removed one
    Object.keys(expandedStagesRef.current).forEach(stageIndex => {
      const stageIdx = parseInt(stageIndex);
      if (stageIdx < index) {
        newExpandedStages[stageIdx] = expandedStagesRef.current[stageIdx];
      } else if (stageIdx > index) {
        // Shift down the expanded state for stages after the removed one
        newExpandedStages[stageIdx - 1] = expandedStagesRef.current[stageIdx];
      }
    });
    expandedStagesRef.current = newExpandedStages;
    forceUpdate();
  };

  const handleSubmit = async () => {
    // Validate that we have a pipeline to update
    if (!pipeline || !pipeline.id) {
      toast({
        title: 'Pipeline not found',
        description: 'The pipeline you are trying to update does not exist.',
        status: 'error',
        duration: 3000,
      });
      onClose();
      return;
    }

    // Validate required fields
    if (!formData.pipelineName || !formData.offering || !formData.customerDefinition) {
      toast({
        title: 'Missing required fields',
        description: 'Please fill in all required fields.',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    // Validate that all stages have names
    const unnamedStages = formData.stages.filter(stage => !stage.name.trim());
    if (unnamedStages.length > 0) {
      toast({
        title: 'Unnamed stages',
        description: 'All stages must have a name.',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    // --- Start Update Process ---
    setIsLoading(true);
    let pipelineUpdateSuccessful = false;
    let stageProcessingSuccessful = false;
    let reorderSuccessful = false;

    try {
      const accessToken = await userData.getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Prepare email accounts
      const selectedEmailAccounts = formData.selectedEmailAccounts.map(account => ({
        email: account.email,
        userId: parseInt(account.userId || account.user_id, 10) || 0,
      }));

      // 1. Update pipeline details
      // console.log('Submitting pipeline details update...');
      const pipelineData = {
        pipelineName: formData.pipelineName,
        offering: formData.offering,
        customerDefinition: formData.customerDefinition,
        businessDescription: formData.businessDescription,
        targetCustomerSize: formData.targetCustomerSize,
        targetCustomerLocation: formData.targetCustomerLocation,
        emailIntegrations: selectedEmailAccounts.length > 0 ? selectedEmailAccounts : [],
        is_active: pipeline.is_active !== undefined ? pipeline.is_active : true, // Preserve original active status if editing
        // display_order needs to be handled carefully if it exists on pipeline level
      };

      await axios.put(
        `${target}/api/accounts/${accountID}/pipelines/${pipeline.id}`,
        pipelineData,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      // console.log('Pipeline details update successful.');
      pipelineUpdateSuccessful = true;

      // 2. Process stages (update, add, delete existing ones)
      // console.log('Processing stage updates/creations/deletions...');
      // Initialize stagesWithUpdatedIds with the current state *before* the conditional block
      let stagesWithUpdatedIds = [...formData.stages];
      
      if (pipeline.stages) {
        const existingStageIds = pipeline.stages.map(stage => stage.id).filter(Boolean);
        const updatedStageIdsInForm = formData.stages.map(stage => stage.id).filter(Boolean);
        const deletedStageIds = existingStageIds.filter(id => !updatedStageIdsInForm.includes(id));

        // Delete stages removed in the form
        if (deletedStageIds.length > 0) {
          // console.log('Deleting stages:', deletedStageIds);
          await Promise.all(
            deletedStageIds.map(stageId =>
              axios.delete(
                `${target}/api/accounts/${accountID}/pipelines/${pipeline.id}/stages/${stageId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } }
              )
            )
          );
          // console.log('Stages deleted successfully.');
        }

        // Update or create stages present in the form
        // console.log('Updating/creating stages...');
        await Promise.all(
          formData.stages.map(async (stage, index) => {
            const stageData = {
              name: stage.name,
              description: stage.description || '',
              passing_criteria: stage.passingCriteria || '',
              // display_order: index, // We handle order separately via the new endpoint
              is_active: stage.isActive !== undefined ? stage.isActive : true,
              probability: stage.probability, // Preserve if they exist
              is_closed: stage.isClosed,
              is_won: stage.isWon,
            };
            
            // console.log(`Processing stage: ${stage.name || 'New Stage'} (ID: ${stage.id})`);
            
            if (stage.id) {
              // Update existing stage
              // console.log(`Updating stage ${stage.id} with data:`, stageData);
              await axios.put(
                `${target}/api/accounts/${accountID}/pipelines/${pipeline.id}/stages/${stage.id}`,
                stageData,
                { headers: { Authorization: `Bearer ${accessToken}` } }
              );
            } else {
              // Create new stage
              // console.log('Creating new stage with data:', stageData);
              const response = await axios.post(
                `${target}/api/accounts/${accountID}/pipelines/${pipeline.id}/stages`,
                stageData,
                { headers: { Authorization: `Bearer ${accessToken}` } }
              );
              // Capture the new ID from the response and update the temporary array
              if (response.data && response.data.id) {
                // console.log(`New stage created with ID: ${response.data.id}`);
                // Find the corresponding stage in the temporary array and update its ID
                // Matching by reference might be tricky, match by properties like name if needed,
                // but since we map over the original array, the index should correspond.
                stagesWithUpdatedIds[index] = { ...stagesWithUpdatedIds[index], id: response.data.id };
              } else {
                console.warn('Stage created, but no ID was returned in the response.');
              }
            }
          })
        );
        // console.log('Stage updates/creations successful.');
      }
      stageProcessingSuccessful = true;

      // ** Crucially, wait for the state update to potentially reflect before reading it for reordering **
      // However, state updates might be async. Reading `stagesWithUpdatedIds` directly is safer here.
 
      // Map current stage order from the *updated* array to get IDs
      const finalOrderedStageIds = stagesWithUpdatedIds
            .map(formStage => {
              // Now formStage.id should exist even for newly created stages
              return formStage.id;
            })
            .filter(Boolean); // Filter out any null/undefined IDs
 
      // console.log('Final ordered stage IDs for reorder API:', finalOrderedStageIds);
      
      // 3. Update stage order using the new endpoint if there are stages with IDs
      if (finalOrderedStageIds.length > 0) {
        // console.log('Calling stage reorder endpoint...');
        const targetReorder =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        await axios.put(
            `${targetReorder}/api/accounts/${accountID}/pipelines/${pipeline.id}/stages/order`,
            { ids: finalOrderedStageIds }, 
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        // console.log('Stage reorder successful.');
        reorderSuccessful = true; 
      } else {
          // console.log('Skipping reorder call as there are no stages with IDs or stages list is empty.');
          reorderSuccessful = true; // Consider it successful if no reorder was needed
      }


      // --- Final Success ---
      toast({
        title: 'Pipeline updated',
        description: 'Pipeline and stages have been successfully updated and reordered.',
        status: 'success',
        duration: 3000,
      });

      // --- Final Query Invalidation ---
      // console.log('Invalidating all relevant queries after successful update and reorder...');
      queryClient.invalidateQueries({ queryKey: ["pipelines", accountID] });
      queryClient.invalidateQueries({ queryKey: ["pipelineStages", accountID, pipeline.id] });
      queryClient.invalidateQueries({ queryKey: ["allPipelineStages", accountID] }); // Ensure overview/other views update
      queryClient.invalidateQueries({ queryKey: ["allPipelineDealCounts", accountID] });

      // Reset original form data reference to prevent unsaved changes warning
      originalFormDataRef.current = JSON.stringify(formData); 
      
      // Update the main formData state with the new IDs before proceeding
      // Use a functional update to ensure we're working with the latest state
      setFormData(prev => ({ ...prev, stages: stagesWithUpdatedIds }));
      // console.log('Form data updated with new stage IDs:', stagesWithUpdatedIds);
      
      closeModal(); // Close modal on full success

    } catch (error) {
      console.error('Error during pipeline update process:', error);
      let errorTitle = 'Error updating pipeline';
      let errorDescription = error.response?.data?.message || error.message || 'An unexpected error occurred';

      if (pipelineUpdateSuccessful && stageProcessingSuccessful && !reorderSuccessful) {
        errorTitle = 'Error reordering stages';
        errorDescription = `Pipeline details and stages were saved, but failed to update stage order. ${errorDescription}`;
      } else if (pipelineUpdateSuccessful && !stageProcessingSuccessful) {
        errorTitle = 'Error processing stages';
        errorDescription = `Pipeline details saved, but failed to update/create/delete stages. ${errorDescription}`;
      }
      
      toast({
        title: errorTitle,
        description: errorDescription,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Get the next stage name for the warning message
  const getNextStageName = () => {
    if (stageToDelete === null || stageToDelete >= formData.stages.length - 1) {
      return "the previous stage";
    }
    
    const nextStage = formData.stages[stageToDelete + 1];
    return nextStage.name || `Stage ${stageToDelete + 2}`;
  };

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={handleModalClose} 
        size="xl"
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>Edit Pipeline</ModalHeader>
          <ModalBody>
            <VStack spacing={6} align="stretch">
              <Text fontSize="sm" color="gray.600" mb={2}>
                Update the pipeline details below.
              </Text>

              {/* Required Information - Pipeline Name */}
              <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
                <Text fontSize="sm" fontWeight="medium" mb={4}>
                  Pipeline Details
                </Text>
                <VStack spacing={4}>
                  <FormControl isRequired>
                    <FormLabel>Pipeline Name</FormLabel>
                    <Input
                      name="pipelineName"
                      value={formData.pipelineName}
                      onChange={handleInputChange}
                      placeholder="Enter pipeline name"
                    />
                  </FormControl>
                </VStack>
              </Box>

              {/* Business Information Box */}
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <Text fontSize="sm" fontWeight="medium" mb={4}>
                  Business Information
                </Text>
                <VStack spacing={4}>
                  <FormControl isRequired>
                    <FormLabel>Offering</FormLabel>
                    <Textarea
                      name="offering"
                      value={formData.offering}
                      onChange={handleInputChange}
                      placeholder="Describe your offering"
                      minH="100px"
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Business Description</FormLabel>
                    <Textarea
                      name="businessDescription"
                      value={formData.businessDescription}
                      onChange={handleInputChange}
                      placeholder="Define your business for AI analysis"
                      minH="100px"
                    />
                  </FormControl>
                </VStack>
              </Box>

              {/* Customer Information Box */}
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <Text fontSize="sm" fontWeight="medium" mb={4}>
                  Customer Information
                </Text>
                <VStack spacing={4}>
                  <FormControl isRequired>
                    <FormLabel>Customer Definition</FormLabel>
                    <Textarea
                      name="customerDefinition"
                      value={formData.customerDefinition}
                      onChange={handleInputChange}
                      placeholder="How would you describe an ideal customer? "
                    />
                  </FormControl>

                  <SimpleGrid columns={2} spacing={4} width="100%">
                    <FormControl>
                      <FormLabel>Company Size</FormLabel>
                      <Input
                        name="targetCustomerSize"
                        value={formData.targetCustomerSize}
                        onChange={handleInputChange}
                        placeholder="e.g., 50-200 employees"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Target Location</FormLabel>
                      <Input
                        name="targetCustomerLocation"
                        value={formData.targetCustomerLocation}
                        onChange={handleInputChange}
                        placeholder="e.g., North America"
                      />
                    </FormControl>
                  </SimpleGrid>
                </VStack>
              </Box>

              {/* Email Accounts Section */}
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <Text fontSize="sm" fontWeight="medium" mb={4}>
                  Email Accounts
                </Text>
                <VStack spacing={4} align="stretch">
                  {isLoading ? (
                    <Stack spacing={3}>
                      <Skeleton height="40px" />
                      <Skeleton height="40px" />
                      <Skeleton height="40px" />
                    </Stack>
                  ) : (
                    <>
                      {/* Personal Accounts */}
                      {personalAccounts.length > 0 && (
                        <Box>
                          <Text fontSize="sm" fontWeight="medium" mb={2}>
                            Personal Accounts
                          </Text>
                          <SimpleGrid columns={2} spacing={4}>
                            {personalAccounts.map(account => (
                              <FormControl key={account.email}>
                                <Checkbox
                                  isChecked={formData.selectedEmailAccounts.some(
                                    selected => selected.email === account.email
                                  )}
                                  onChange={(e) => {
                                    // console.log(`PERSONAL: ${account.email} checkbox changed to: ${e.target.checked}`);
                                    // console.log('Current selectedEmailAccounts before change:', JSON.stringify(formData.selectedEmailAccounts));
                                    
                                    setFormData(prev => {
                                      const newSelectedAccounts = e.target.checked
                                        ? [...prev.selectedEmailAccounts, {
                                            email: account.email,
                                            userId: account.userId || account.user_id,
                                          }]
                                        : prev.selectedEmailAccounts.filter(
                                            a => a.email !== account.email
                                          );
                                      // console.log('PERSONAL: New selected accounts after change:', JSON.stringify(newSelectedAccounts));
                                      return {
                                        ...prev,
                                        selectedEmailAccounts: newSelectedAccounts,
                                      };
                                    });
                                  }}
                                >
                                  {account.email}
                                </Checkbox>
                              </FormControl>
                            ))}
                          </SimpleGrid>
                        </Box>
                      )}

                      {/* Team Accounts */}
                      {teamAccounts.length > 0 && (
                        <Box>
                          <Text fontSize="sm" fontWeight="medium" mb={2}>
                            Team Accounts
                          </Text>
                          <SimpleGrid columns={2} spacing={4}>
                            {teamAccounts.map(account => (
                              <FormControl key={account.email}>
                                <Checkbox
                                  isChecked={formData.selectedEmailAccounts.some(
                                    selected => selected.email === account.email
                                  )}
                                  onChange={(e) => {
                                    // console.log(`TEAM: ${account.email} checkbox changed to: ${e.target.checked}`);
                                    // console.log('Current selectedEmailAccounts before change:', JSON.stringify(formData.selectedEmailAccounts));
                                    
                                    setFormData(prev => {
                                      const newSelectedAccounts = e.target.checked
                                        ? [...prev.selectedEmailAccounts, {
                                            email: account.email,
                                            userId: account.userId || account.user_id,
                                          }]
                                        : prev.selectedEmailAccounts.filter(
                                            a => a.email !== account.email
                                          );
                                      // console.log('TEAM: New selected accounts after change:', JSON.stringify(newSelectedAccounts));
                                      return {
                                        ...prev,
                                        selectedEmailAccounts: newSelectedAccounts,
                                      };
                                    });
                                  }}
                                >
                                  {account.email}
                                </Checkbox>
                              </FormControl>
                            ))}
                          </SimpleGrid>
                        </Box>
                      )}

                      {personalAccounts.length === 0 && teamAccounts.length === 0 && (
                        <Text color="gray.500">
                          No email accounts available. Please connect your email accounts in Settings.
                        </Text>
                      )}
                    </>
                  )}
                </VStack>
              </Box>

              {/* Pipeline Stages Section */}
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <HStack justify="space-between" mb={4}>
                  <Text fontSize="sm" fontWeight="medium">
                    Pipeline Stages
                  </Text>
                  <Button
                    leftIcon={<MdAdd />}
                    size="sm"
                    onClick={addStage}
                    colorScheme="blue"
                    variant="ghost"
                  >
                    Add Stage
                  </Button>
                </HStack>
                <VStack spacing={4} align="stretch">
                  {/* Wrap stages list with DragDropContext */}
                  <DragDropContext onDragEnd={onDragEnd}>
                    {/* Define the droppable area */}
                    <Droppable droppableId="pipelineStages">
                      {(provided) => (
                        <VStack
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          spacing={4}
                          align="stretch"
                        >
                          {formData.stages.map((stage, index) => (
                            // Make each stage draggable
                            <Draggable
                              key={stage.id || `new-stage-${index}`} // Use a stable key
                              draggableId={stage.id ? stage.id.toString() : `new-stage-${index}`}
                              index={index}
                            >
                              {(providedDraggable, snapshot) => (
                                <Box
                                  ref={providedDraggable.innerRef}
                                  {...providedDraggable.draggableProps}
                                  borderWidth="1px"
                                  borderRadius="md"
                                  position="relative"
                                  overflow="hidden"
                                  bg={snapshot.isDragging ? 'blue.50' : 'white'}
                                  boxShadow={snapshot.isDragging ? 'lg' : 'none'}
                                >
                                  {/* Stage Header */}
                                  <Flex 
                                    p={4} 
                                    justify="space-between" 
                                    align="center" 
                                    bg={expandedStagesRef.current[index] ? "gray.50" : "transparent"}
                                    borderBottomWidth={expandedStagesRef.current[index] ? "1px" : "0px"} 
                                    borderColor="gray.200"
                                  >
                                    {/* Drag Handle, Index, and Name */}
                                    <HStack flex={1} mr={2} spacing={3} align="center">
                                      {/* Drag Handle */}
                                      <Box 
                                        {...providedDraggable.dragHandleProps} 
                                        aria-label="Drag stage"
                                        cursor="grab"
                                        _hover={{ color: 'blue.500' }}
                                      >
                                        <Icon as={MdDragIndicator} />
                                      </Box>
                                      {/* Index and Name */}
                                      <Text fontWeight="medium" noOfLines={1}>
                                        <Text as="span" color="gray.500" fontSize="sm" mr={2}>#{index + 1}</Text> 
                                        {stage.name || 'Unnamed Stage'}
                                      </Text>
                                    </HStack>
                                     
                                    {/* Action Buttons */}
                                    <HStack spacing={1}>
                                      {/* Expand/Collapse Button */}
                                      <IconButton
                                        icon={expandedStagesRef.current[index] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                        variant="ghost" 
                                        size="sm"
                                        onClick={() => toggleStage(index)}
                                        aria-label={expandedStagesRef.current[index] ? "Collapse" : "Expand"}
                                      />
                                      {/* Delete Button */}
                                      <IconButton
                                        icon={<MdDelete />}
                                        variant="ghost"
                                        colorScheme="red"
                                        size="sm"
                                        onClick={() => checkAndRemoveStage(index)}
                                        aria-label="Remove Stage"
                                        isDisabled={formData.stages.length <= 1}
                                        isLoading={isLoading && stageToDelete === index}
                                      />
                                    </HStack>
                                  </Flex>

                                  {/* Stage Content (Collapsible) */}
                                  <Collapse in={Boolean(expandedStagesRef.current[index])}>
                                    <Box p={4} pt={2} bg="white">
                                      {/* Stage form fields remain the same */}
                                      <SimpleGrid columns={2} spacing={4} width="100%">
                                        <FormControl isRequired>
                                          <FormLabel>Name</FormLabel>
                                          <Input
                                            name="name"
                                            value={stage.name}
                                            onChange={(e) => handleInputChange(e, 'stages', index)}
                                            placeholder="Enter stage name"
                                          />
                                        </FormControl>

                                        <FormControl>
                                          <FormLabel>Trigger Type</FormLabel>
                                          <Select
                                            name="triggerType"
                                            value={stage.triggerType}
                                            onChange={(e) => handleInputChange(e, 'stages', index)}
                                          >
                                            <option value="qa">Q&A</option>
                                            <option value="zapier">Zapier</option>
                                            <option value="segment">Segment</option>
                                          </Select>
                                        </FormControl>
                                      </SimpleGrid>

                                      <FormControl mt={4}>
                                        <FormLabel>Description</FormLabel>
                                        <Textarea
                                          name="description"
                                          value={stage.description}
                                          onChange={(e) => handleInputChange(e, 'stages', index)}
                                          placeholder="Describe this stage"
                                        />
                                      </FormControl>

                                      {stage.triggerType === 'qa' && (
                                        <FormControl mt={4}>
                                          <FormLabel>Passing Criteria</FormLabel>
                                          <Textarea
                                            name="passingCriteria"
                                            value={stage.passingCriteria}
                                            onChange={(e) => handleInputChange(e, 'stages', index)}
                                            placeholder="Define criteria to pass this stage"
                                          />
                                        </FormControl>
                                      )}
                                    </Box>
                                  </Collapse>
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {/* Placeholder for droppable area */}
                          {provided.placeholder}
                        </VStack>
                      )}
                    </Droppable>
                  </DragDropContext>
                </VStack>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleModalClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Alert Dialog for Stage Deletion Warning */}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Flex align="center">
                <Icon as={MdWarning} color="orange.500" mr={2} />
                Delete Stage with Deals
              </Flex>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                This stage contains <strong>{stageDealCount} {stageDealCount === 1 ? 'deal' : 'deals'}</strong>.
              </Text>
              <Text mt={2}>
                Deleting this stage will move all these deals to <strong>{getNextStageName()}</strong>.
              </Text>
              <Text mt={4}>
                Are you sure you want to continue?
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmRemoveStage} ml={3}>
                Delete Stage
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Alert Dialog for Unsaved Changes Warning */}
      <AlertDialog
        isOpen={isUnsavedChangesAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onUnsavedChangesAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Flex align="center">
                <Icon as={MdWarning} color="orange.500" mr={2} />
                Unsaved Changes
              </Flex>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                You have unsaved changes. Are you sure you want to close without saving?
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onUnsavedChangesAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={closeModal} ml={3}>
                Discard Changes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EditPipelineModal; 