import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  FormErrorMessage,
  Select,
  NumberInput,
  NumberInputField,
  Box,
  Text,
  SimpleGrid,
  ModalCloseButton,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useAuthData } from 'auth-context';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';

const EditAccountModal = ({ isOpen, onClose, account, initialFocusField }) => {
  const toast = useToast();
  const { getAccessToken } = useAuthData();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const queryClient = useQueryClient();
  const inputRefs = React.useRef({});

  const [formData, setFormData] = useState({
    name: '',
    website: '',
    website_root: '',
    email: '',
    linkedIn: '',
    city: '',
    state: '',
    country: '',
    address: '',
    size: null,
    type: 'Lead',
    source_system: 'internal',
    enrichment: null,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (account) {
      setFormData({
        name: account.name || '',
        website: account.website || '',
        website_root: account.website_root || '',
        email: account.email || '',
        linkedIn: account.linkedIn || '',
        city: account.city || '',
        state: account.state || '',
        country: account.country || '',
        address: account.address || '',
        size: account.size ?? null,
        type: account.type || 'Lead',
        source_system: account.source_system || 'internal',
        enrichment: account.enrichment || null,
      });
    }
  }, [account]);

  // Initialize refs for all fields
  useEffect(() => {
    inputRefs.current = {
      name: React.createRef(),
      website: React.createRef(),
      email: React.createRef(),
      linkedIn: React.createRef(),
      city: React.createRef(),
      state: React.createRef(),
      country: React.createRef(),
      address: React.createRef(),
      size: React.createRef(),
      type: React.createRef(),
    };
  }, []);

  // Handle focus when modal opens
  useEffect(() => {
    if (isOpen && initialFocusField && inputRefs.current[initialFocusField]) {
      // Small delay to ensure the modal is fully rendered
      setTimeout(() => {
        const input = inputRefs.current[initialFocusField];
        if (input.current) {
          input.current.focus();
          
          // If it's an input, select all text
          if (input.current.tagName === 'INPUT') {
            input.current.select();
          }
        }
      }, 100);
    }
  }, [isOpen, initialFocusField]);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Account name is required';
    }
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (formData.website) {
      try {
        // Check if the URL has a protocol, if not prepend https://
        let websiteUrl = formData.website;
        if (!/^https?:\/\//i.test(websiteUrl)) {
          websiteUrl = `https://${websiteUrl}`;
        }
        
        const url = new URL(websiteUrl);
        if (!url.protocol || !url.host) {
          newErrors.website = 'Invalid website URL format';
        } else {
          setFormData(prev => ({
            ...prev,
            website: websiteUrl,
            website_root: `${url.protocol}//${url.host}`
          }));
        }
      } catch {
        newErrors.website = 'Invalid website URL format';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleNumberInputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value === '' ? null : Number(value)
    }));
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';

      await axios.put(
        `${target}/api/accounts/${accountID}/crm-accounts/${account.id}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      await queryClient.invalidateQueries({ queryKey: ['account', accountID, account.id], exact: true });
      await queryClient.refetchQueries({ queryKey: ['account', accountID, account.id], exact: true });

      toast({
        title: 'Account updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      console.error('Error updating account:', error);
      toast({
        title: 'Error updating account',
        description: error.response?.data?.message || 'An error occurred while updating the account',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="xl"
      initialFocusRef={initialFocusField ? inputRefs.current[initialFocusField] : undefined}
    >
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>Edit Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text fontSize="sm" color="gray.600" mb={2}>
              Update the account details below.
            </Text>

            {/* Required Fields Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Required Information
              </Text>
              <VStack spacing={4}>
                <FormControl isRequired isInvalid={!!errors.name}>
                  <FormLabel>Account Name</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter account name"
                    ref={inputRefs.current.name}
                  />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>

                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl isInvalid={!!errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                      ref={inputRefs.current.email}
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                      ref={inputRefs.current.type}
                    >
                      <option value="Lead">Lead</option>
                      <option value="Customer">Customer</option>
                      <option value="Partner">Partner</option>
                      <option value="Prospect">Prospect</option>
                      <option value="Other">Other</option>
                    </Select>
                  </FormControl>
                </SimpleGrid>
              </VStack>
            </Box>

            {/* Contact Information Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4}>
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Contact Information
              </Text>
              <VStack spacing={4}>
                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl isInvalid={!!errors.website}>
                    <FormLabel>Website</FormLabel>
                    <Input
                      name="website"
                      value={formData.website}
                      onChange={handleInputChange}
                      placeholder="Enter website URL"
                      ref={inputRefs.current.website}
                    />
                    <FormErrorMessage>{errors.website}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormLabel>LinkedIn</FormLabel>
                    <Input
                      name="linkedIn"
                      value={formData.linkedIn}
                      onChange={handleInputChange}
                      placeholder="Enter LinkedIn company URL"
                      ref={inputRefs.current.linkedIn}
                    />
                  </FormControl>
                </SimpleGrid>
              </VStack>
            </Box>

            {/* Location Information Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4}>
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Location Information
              </Text>
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Address</FormLabel>
                  <Input
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    placeholder="Enter address"
                    ref={inputRefs.current.address}
                  />
                </FormControl>

                <SimpleGrid columns={3} spacing={4} width="100%">
                  <FormControl>
                    <FormLabel>City</FormLabel>
                    <Input
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      placeholder="Enter city"
                      ref={inputRefs.current.city}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>State</FormLabel>
                    <Input
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      placeholder="Enter state"
                      ref={inputRefs.current.state}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Country</FormLabel>
                    <Input
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      placeholder="Enter country"
                      ref={inputRefs.current.country}
                    />
                  </FormControl>
                </SimpleGrid>

                <FormControl>
                  <FormLabel>Company Size</FormLabel>
                  <NumberInput
                    value={formData.size ?? ''}
                    onChange={(value) => handleNumberInputChange('size', value === '' ? null : parseInt(value))}
                    min={0}
                  >
                    <NumberInputField placeholder="Enter company size" ref={inputRefs.current.size} />
                  </NumberInput>
                </FormControl>
              </VStack>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

EditAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    website: PropTypes.string,
    email: PropTypes.string,
    linkedIn: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    address: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
  }).isRequired,
  initialFocusField: PropTypes.string,
};

export default EditAccountModal; 