import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

export const useDeleteActivity = () => {
  const queryClient = useQueryClient();
  const { getAccessToken } = useAuthData();

  return useMutation({
    mutationFn: async ({ accountId, dealId, activityId }) => {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      const response = await axios.delete(
        `${target}/api/accounts/${accountId}/deals/${dealId}/activities/${activityId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: (_, { accountId, dealId }) => {
      // Invalidate all queries that might contain the activity data
      queryClient.invalidateQueries(["deal", accountId, dealId]);
      queryClient.invalidateQueries(["dealActivities", accountId, dealId]);
      queryClient.invalidateQueries(["activities", accountId, dealId]);
      queryClient.invalidateQueries(["deals", accountId]);
      // Also invalidate account-level activities
      queryClient.invalidateQueries(["accountActivities", accountId]);
      // Invalidate inbox-related queries
      queryClient.invalidateQueries(["inbox", accountId]);
      queryClient.invalidateQueries(["inboxItem", accountId, dealId]);
      queryClient.invalidateQueries(["emailFollowup", accountId, dealId]);
    },
  });
};
