import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to fetch Slack channel information for a specific deal
 * @param {string} accountId - The account ID
 * @param {string} dealId - The deal ID
 * @returns {Object} - Contains the channel data, loading state, and error state
 */
function useFetchDealSlackChannel(accountId, dealId) {
  const { getAccessToken } = useAuthData();

  const fetchDealSlackChannel = async () => {
    if (!accountId || !dealId) {
      console.warn("Missing required parameters for useFetchDealSlackChannel");
      return { channels: [] };
    }

    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.get(
        `${target}/api/accounts/${accountId}/deals/${dealId}/slack/channel`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return {
        channels: response.data.channels || [],
      };
    } catch (error) {
      // If 404, it means no Slack channel is connected to this deal
      if (error.response?.status === 404) {
        return { channels: [] };
      }
      console.error("Error fetching deal Slack channel:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      // Return empty channels array instead of throwing
      return { 
        channels: [],
        error: {
          message: error.message,
          status: error.response?.status
        }
      };
    }
  };

  const {
    data = { channels: [] },
    isLoading,
    error,
  } = useQuery({
    queryKey: ["dealSlackChannel", accountId, dealId],
    queryFn: fetchDealSlackChannel,
    enabled: !!accountId && !!dealId,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  return {
    channels: data.channels,
    isLoading,
    error,
  };
}

export default useFetchDealSlackChannel;
