// src/utils/pipelineAnalysis.js
import { parseISO, format } from "date-fns";

// Constants for data processing
const AMOUNT_SCALE_FACTOR = 1000;
const DEFAULT_PIPELINE_STATE = {
  openPipeline: 0,
  newPipeline: 0,
  closeDatePulledIn: 0,
  closeDatePushedOut: 0,
  dealSizeIncreased: 0,
  dealSizeDecreased: 0,
  closedLost: 0,
  closedWon: 0,
  closedPaid: 0,
};

/**
 * Safely parse and scale a numeric value
 * @param {any} value - Value to parse
 * @param {boolean} shouldScale - Whether to scale the value
 * @returns {number} Parsed and optionally scaled value
 */
const safeParseAmount = (value, shouldScale = false) => {
  const parsed = Number(value) || 0;
  const scaled = shouldScale ? parsed / AMOUNT_SCALE_FACTOR : parsed;
  return Number(scaled.toFixed(2));
};

/**
 * Validate deal history data
 * @param {Array} dealHistory - Raw deal history data
 * @returns {boolean} Whether the data is valid
 */
const isValidDealHistory = (dealHistory) => {
  return (
    Array.isArray(dealHistory) &&
    dealHistory.length > 0 &&
    dealHistory.every(
      (deal) =>
        deal &&
        typeof deal === "object" &&
        "created_date" in deal &&
        "amount" in deal
    )
  );
};

/**
 * Calculate the initial pipeline value
 * @param {Array} deals - Deals to process
 * @param {Date} periodStart - Start of the selected period (quarter/year)
 * @returns {number} Initial pipeline value
 */
const calculateInitialPipeline = (deals, periodStart) => {
  if (!Array.isArray(deals) || !periodStart) return 0;

  return deals.reduce((sum, deal) => {
    try {
      const dealDate = parseISO(deal.created_date);
      // Include deals created before period start that weren't closed
      if (
        dealDate < periodStart &&
        deal.stage_name !== "Closed-Paid" &&
        deal.stage_name !== "Closed Lost" &&
        deal.stage_name !== "closedlost"
      ) {
        return sum + safeParseAmount(deal.amount);
      }
    } catch (error) {
      console.warn("Error processing deal for initial pipeline:", error);
    }
    return sum;
  }, 0);
};

/**
 * Check if a stage change occurred to any of the target stages
 * @param {Object} change - Change object
 * @param {string|Array} targetStage - Target stage(s) to check
 * @returns {boolean} Whether the stage changed to any of the target stages
 */
const isStageTransition = (change, targetStage) => {
  // Handle both single string and array of possible target stages
  const targetStages = Array.isArray(targetStage) ? targetStage : [targetStage];
  return (
    targetStages.includes(change.stage_name) &&
    !targetStages.includes(change.prev_stage_name)
  );
};

/**
 * Filter deals based on the selected segment using No_of_Locations__c
 * @param {Array} deals - Array of deals to filter
 * @param {string} segment - Selected segment (SMB, Mid-Market, Enterprise, or All)
 * @returns {Array} Filtered deals array
 */
const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter((deal) => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;

    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

/**
 * Processes deal history data into pipeline flow and monthly changes format
 * @param {Array} dealHistory - Raw deal history data from API
 * @param {number} selectedYear - Selected year for filtering
 * @param {string} selectedPeriod - Selected period for filtering ('year' or 'quarter')
 * @param {number} selectedQuarter - Selected quarter (1-4) when selectedPeriod is 'quarter'
 * @param {string} selectedSegment - Selected segment for filtering
 * @returns {Object} Processed pipeline analysis data
 */
export const processPipelineData = (
  dealHistory,
  selectedYear,
  selectedPeriod = "year",
  selectedQuarter = null,
  selectedSegment = "All"
) => {
  // Validate input data
  if (!isValidDealHistory(dealHistory)) {
    console.warn("Invalid or empty deal history data");
    return {
      pipelineFlowData: [],
      monthlyChanges: { months: [], series: [] },
    };
  }

  try {
    // Filter deals by segment first
    const segmentFilteredDeals = filterDealsBySegment(
      dealHistory,
      selectedSegment
    );

    let periodStart, periodEnd;

    // Set period start and end based on selection
    if (selectedPeriod === "quarter" && selectedQuarter) {
      periodStart = new Date(selectedYear, (selectedQuarter - 1) * 3, 1);
      periodEnd = new Date(selectedYear, selectedQuarter * 3, 0);
    } else {
      periodStart = new Date(selectedYear, 0, 1);
      periodEnd = new Date(selectedYear, 11, 31);
    }

    // Get deals at period start and calculate initial pipeline
    const initialPipeline = calculateInitialPipeline(
      segmentFilteredDeals,
      periodStart
    );

    // Initialize pipeline flow with actual values
    let pipelineFlow = {
      ...DEFAULT_PIPELINE_STATE,
      openPipeline: initialPipeline,
    };

    // Process changes chronologically within the selected period
    const periodChanges = segmentFilteredDeals.filter((change) => {
      try {
        const changeDate = parseISO(change.created_date);
        return changeDate >= periodStart && changeDate <= periodEnd;
      } catch (error) {
        console.warn("Error filtering period changes:", error);
        return false;
      }
    });

    // Process changes and update pipeline flow
    periodChanges
      .sort((a, b) => new Date(a.created_date) - new Date(b.created_date))
      .forEach((change) => {
        try {
          const amount = safeParseAmount(change.amount);
          const prevAmount = safeParseAmount(change.prev_amount);
          const amountChange = amount - prevAmount;

          if (isStageTransition(change, ["Closed Lost", "closedlost"])) {
            pipelineFlow.closedLost += amount;
          } else if (isStageTransition(change, "Closed-Paid")) {
            pipelineFlow.closedPaid += amount;
          } else if (isStageTransition(change, ["Closed Won", "closedwon"])) {
            pipelineFlow.closedWon += amount;
          } else {
            if (!prevAmount && amount > 0) {
              pipelineFlow.newPipeline += amount;
            } else if (change.close_date !== change.prev_close_date) {
              const newDate = new Date(change.close_date);
              const oldDate = change.prev_close_date
                ? new Date(change.prev_close_date)
                : new Date();
              if (newDate < oldDate) {
                pipelineFlow.closeDatePulledIn += amount;
              } else {
                pipelineFlow.closeDatePushedOut += amount;
              }
            } else if (amountChange !== 0) {
              if (amountChange > 0) {
                pipelineFlow.dealSizeIncreased += amountChange;
              } else {
                pipelineFlow.dealSizeDecreased += Math.abs(amountChange);
              }
            }
          }
        } catch (error) {
          console.warn("Error processing change:", error);
        }
      });

    // Create waterfall chart data with all values properly scaled
    const pipelineFlowData = [
      {
        label: "New Deals\nCreated",
        value: safeParseAmount(pipelineFlow.newPipeline, true),
      },
      {
        label: "Close Date\nMoved Earlier",
        value: safeParseAmount(pipelineFlow.closeDatePulledIn, true),
      },
      {
        label: "Close Date\nMoved Later",
        value: safeParseAmount(pipelineFlow.closeDatePushedOut, true),
      },
      {
        label: "Deal Value\nIncreased",
        value: safeParseAmount(pipelineFlow.dealSizeIncreased, true),
      },
      {
        label: "Deal Value\nDecreased",
        value: -safeParseAmount(pipelineFlow.dealSizeDecreased, true),
      },
      {
        label: "Deals\nLost",
        value: -safeParseAmount(pipelineFlow.closedLost, true),
      },
      {
        label: "Deals\nWon",
        value: safeParseAmount(pipelineFlow.closedWon, true),
      },
      {
        label: "Deals\nPaid",
        value: safeParseAmount(pipelineFlow.closedPaid || 0, true),
      },
    ];

    // Process monthly changes
    const monthlyChanges = processMonthlyChanges(
      dealHistory,
      selectedYear,
      selectedPeriod,
      selectedQuarter
    );

    return {
      pipelineFlowData,
      monthlyChanges,
    };
  } catch (error) {
    console.error("Error processing pipeline data:", error);
    return {
      pipelineFlowData: [],
      monthlyChanges: { months: [], series: [] },
    };
  }
};

/**
 * Process changes by month for the selected year
 * @param {Array} dealHistory - Raw deal history data
 * @param {number} selectedYear - Selected year for filtering
 * @param {string} selectedPeriod - Selected period for filtering ('year' or 'quarter')
 * @param {number} selectedQuarter - Selected quarter (1-4) when selectedPeriod is 'quarter'
 * @param {string} selectedSegment - Selected segment for filtering
 * @returns {Object} Monthly changes data
 */
const processMonthlyChanges = (
  dealHistory,
  selectedYear,
  selectedPeriod = "year",
  selectedQuarter = null,
  selectedSegment = "All"
) => {
  try {
    // Initialize months based on period
    let months;
    if (selectedPeriod === "quarter" && selectedQuarter) {
      // For quarters, only show the 3 months of the selected quarter
      const quarterStartMonth = (selectedQuarter - 1) * 3;
      months = Array.from({ length: 3 }, (_, i) => {
        const date = new Date(selectedYear, quarterStartMonth + i);
        return format(date, "MMM");
      });
    } else {
      // For year view, show all 12 months
      months = Array.from({ length: 12 }, (_, i) => {
        const date = new Date(selectedYear, i);
        return format(date, "MMM");
      });
    }

    // Initialize monthly data structure with defaults
    const monthlyData = months.reduce((acc, month) => {
      acc[month] = { ...DEFAULT_PIPELINE_STATE };
      return acc;
    }, {});

    // Process changes chronologically within the selected period
    const periodStart =
      selectedPeriod === "quarter" && selectedQuarter
        ? new Date(selectedYear, (selectedQuarter - 1) * 3, 1)
        : new Date(selectedYear, 0, 1);

    const periodEnd =
      selectedPeriod === "quarter" && selectedQuarter
        ? new Date(selectedYear, selectedQuarter * 3, 0)
        : new Date(selectedYear, 11, 31);

    // Filter by segment first
    const segmentFilteredDeals = filterDealsBySegment(
      dealHistory,
      selectedSegment
    );

    segmentFilteredDeals
      .filter((change) => {
        try {
          const changeDate = parseISO(change.created_date);
          return changeDate >= periodStart && changeDate <= periodEnd;
        } catch (error) {
          console.warn("Error filtering change by period:", error);
          return false;
        }
      })
      .sort((a, b) => new Date(a.created_date) - new Date(b.created_date))
      .forEach((change) => {
        try {
          const date = parseISO(change.created_date);
          const month = format(date, "MMM");
          const amount = safeParseAmount(change.amount);
          const prevAmount = safeParseAmount(change.prev_amount);
          const amountChange = amount - prevAmount;

          // Handle stage transitions
          if (isStageTransition(change, ["Closed Lost", "closedlost"])) {
            monthlyData[month].closedLost -= amount;
          } else if (isStageTransition(change, "Closed-Paid")) {
            monthlyData[month].closedPaid += amount;
          } else if (isStageTransition(change, ["Closed Won", "closedwon"])) {
            monthlyData[month].closedWon += amount;
          } else {
            // Handle other changes
            if (!prevAmount && amount > 0) {
              monthlyData[month].created += amount;
            } else if (change.close_date !== change.prev_close_date) {
              const newDate = new Date(change.close_date);
              const oldDate = change.prev_close_date
                ? new Date(change.prev_close_date)
                : new Date();
              if (newDate < oldDate) {
                monthlyData[month].closeDatePulledIn += amount;
              } else {
                monthlyData[month].closeDatePushedOut -= amount;
              }
            } else if (amountChange > 0) {
              monthlyData[month].dealSizeIncreased += amountChange;
            } else if (amountChange < 0) {
              monthlyData[month].dealSizeDecreased -= Math.abs(amountChange);
            }
          }
        } catch (error) {
          console.warn("Error processing monthly change:", error);
        }
      });

    // Format and scale data for chart
    return {
      months: months,
      series: [
        {
          name: "Created",
          data: months.map((m) =>
            Number(safeParseAmount(monthlyData[m].created, true).toFixed(2))
          ),
        },
        {
          name: "Close Date Pulled In",
          data: months.map((m) =>
            Number(
              safeParseAmount(monthlyData[m].closeDatePulledIn, true).toFixed(2)
            )
          ),
        },
        {
          name: "Close Date Pushed Out",
          data: months.map((m) =>
            Number(
              safeParseAmount(monthlyData[m].closeDatePushedOut, true).toFixed(
                2
              )
            )
          ),
        },
        {
          name: "Deal Size Increased",
          data: months.map((m) =>
            Number(
              safeParseAmount(monthlyData[m].dealSizeIncreased, true).toFixed(2)
            )
          ),
        },
        {
          name: "Deal Size Decreased",
          data: months.map((m) =>
            Number(
              safeParseAmount(monthlyData[m].dealSizeDecreased, true).toFixed(2)
            )
          ),
        },
        {
          name: "Closed Lost",
          data: months.map((m) =>
            Number(safeParseAmount(monthlyData[m].closedLost, true).toFixed(2))
          ),
        },
        {
          name: "Closed Won",
          data: months.map((m) =>
            Number(safeParseAmount(monthlyData[m].closedWon, true).toFixed(2))
          ),
        },
        {
          name: "Closed Paid",
          data: months.map((m) =>
            Number(safeParseAmount(monthlyData[m].closedPaid, true).toFixed(2))
          ),
        },
      ],
    };
  } catch (error) {
    console.error("Error processing monthly changes:", error);
    return {
      months: [],
      series: [],
    };
  }
};
