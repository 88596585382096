import { useState } from "react";
import { useAuthData } from "auth-context";
import axios from "axios";

const useCreateAccount = () => {
  const { getAccessToken } = useAuthData();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = (formData) => {
    const newErrors = {};

    if (!formData.name?.trim()) {
      newErrors.name = "Account name is required";
    }
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (formData.website) {
      try {
        // If user enters a URL without protocol, prepend https://
        let websiteUrl = formData.website;
        if (!/^https?:\/\//i.test(websiteUrl)) {
          websiteUrl = `https://${websiteUrl}`;
        }
        
        const url = new URL(websiteUrl);
        if (!url.host) {
          newErrors.website = "Invalid website URL format";
        }
      } catch {
        newErrors.website = "Invalid website URL format";
      }
    }
    if (formData.size && isNaN(Number(formData.size))) {
      newErrors.size = "Size must be a number";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const createAccount = async (formData) => {
    if (!validateForm(formData)) {
      return { success: false, errors };
    }

    setIsSubmitting(true);
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Format the data according to backend expectations
      const requestData = {
        // Required non-pointer field
        name: formData.name,

        // Optional pointer fields - use null for empty values
        website: formData.website || null,
        website_root: null, // Will be set below if website is valid
        email: formData.email || null,
        linkedIn: formData.linkedin || null, // Note the capital 'I' to match backend
        phone: formData.phone || null,
        city: formData.city || null,
        state: formData.state || null,
        country: formData.country || null,
        address: formData.address || null,
        size: formData.size ? Number(formData.size) : null,
        type: formData.type || null,
        description: formData.description || null,
        industry: formData.industry || null,
        stage: formData.stage ? Number(formData.stage) : null,
        enrichment: null, // We don't handle enrichment in the form

        // These will be handled by the backend
        source_system: "internal",
        source_crm_account_id: null,
        account_id: null,
        id: null,
        created_at: null,
        updated_at: null,
        last_activity_date: null,
      };

      // If website is valid, set website_root
      if (formData.website) {
        try {
          // If user enters a URL without protocol, prepend https://
          let websiteUrl = formData.website;
          if (!/^https?:\/\//i.test(websiteUrl)) {
            websiteUrl = `https://${websiteUrl}`;
            // Update the original website value with the protocol
            requestData.website = websiteUrl;
          }
          
          const url = new URL(websiteUrl);
          requestData.website_root = `${url.protocol}//${url.host}`;
        } catch {
          // If URL is invalid, website_root will remain null
        }
      }

      const response = await axios.post(
        `${target}/api/accounts/${accountID}/crm-accounts`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error creating account:", error);
      return {
        success: false,
        error:
          error.response?.data?.error ||
          "An error occurred while creating the account",
      };
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    createAccount,
    isSubmitting,
    errors,
    setErrors,
  };
};

export default useCreateAccount;
