import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
  Text,
  InputGroup,
  InputLeftElement,
  Box,
  SimpleGrid,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  List,
  ListItem,
  IconButton,
  Badge,
  Flex,
} from '@chakra-ui/react';
import { useAuthData } from 'auth-context';
import useUpdateDeal from '../hooks/useUpdateDeal';
import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FaTrash, FaSlack } from 'react-icons/fa';
import useRemoveContactFromDeal from '../hooks/useRemoveContactFromDeal';
import useFetchSlackChannels from '../hooks/useFetchSlackChannels';
import useConnectDealToSlackChannel from '../hooks/useConnectDealToSlackChannel';
import useFetchDealSlackChannel from '../hooks/useFetchDealSlackChannel';

// Custom hook for deleting a deal
const useDeleteDeal = () => {
  const { getAccessToken } = useAuthData();
  
  return useMutation({
    mutationFn: async ({ accountId, dealId }) => {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      const response = await axios.delete(
        `${target}/api/accounts/${accountId}/deals/${dealId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    }
  });
};

const EditDealModal = ({ isOpen, onClose, deal, initialFocusField }) => {
  const toast = useToast();
  const userData = useAuthData();
  const { account_ID, user_ID,getAccessToken } = userData;
  // Convert account_ID to number for comparison
  const accountIdNumber = Number(account_ID);
  const priorityOptions = accountIdNumber === 7 ? ["PMS", "PM", "PO", "Ad-hoc", "Content", "Other"] : ["Standard", "VIP"];
  const updateDealMutation = useUpdateDeal();
  const [errors, setErrors] = useState({});
  const inputRefs = React.useRef({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteDealDialogOpen, setIsDeleteDealDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const cancelRef = useRef();
  const deleteDealRef = useRef();
  const removeContactMutation = useRemoveContactFromDeal();
  const deleteDealMutation = useDeleteDeal();
  const { channels, hasIntegration, isLoading: isLoadingSlack } = useFetchSlackChannels(account_ID, user_ID);
  const connectSlackChannelMutation = useConnectDealToSlackChannel();
  const [previousSlackChannelId, setPreviousSlackChannelId] = useState('');
  const { channels: connectedChannels, isLoading: isLoadingDealSlack } = useFetchDealSlackChannel(account_ID, deal?.id);

  // Initialize refs for all fields
  useEffect(() => {
    inputRefs.current = {
      deal_name: React.createRef(),
      pipeline_name: React.createRef(),
      stage_id: React.createRef(),
      owner_id: React.createRef(),
      amount: React.createRef(),
      close_date: React.createRef(),
      probability: React.createRef(),
      priority: React.createRef(),
      lead_source: React.createRef(),
      lead_source_description: React.createRef(),
      type: React.createRef(),
      description: React.createRef(),
      slack_channel: React.createRef(),
    };
  }, []);

  // Handle focus when modal opens
  useEffect(() => {
    if (isOpen && initialFocusField && inputRefs.current[initialFocusField]) {
      // Small delay to ensure the modal is fully rendered
      setTimeout(() => {
        const input = inputRefs.current[initialFocusField];
        if (input.current) {
          input.current.focus();
          
          // If it's an input, select all text
          if (input.current.tagName === 'INPUT') {
            input.current.select();
          }
        }
      }, 100);
    }
  }, [isOpen, initialFocusField]);

  const [formData, setFormData] = useState({
    deal_name: '',
    lead_source: '',
    lead_source_description: '',
    priority: '',
    amount: '',
    close_date: '',
    probability: '',
    stage_id: '',
    stage_name: '',
    description: '',
    type: '',
    is_closed: false,
    is_won: false,
    source_system: 'manual',
    extra_fields: {},
    owner_id: null,
    user_id: null,
    user_name: '',
    user_last_name: '',
    user_email: '',
    slack_channel_id: '',
  });

  // Fetch pipeline stages
  const { data: pipelineStages } = useQuery({
    queryKey: ['pipelineStages', account_ID, deal?.pipeline_id],
    queryFn: async () => {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      const response = await axios.get(
        `${target}/api/accounts/${account_ID}/pipelines/${deal?.pipeline_id}/stages`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data;
      return Array.isArray(data) ? data : (data.stages || []);
    },
    enabled: !!account_ID && !!deal?.pipeline_id,
  });

  // Fetch users
  const { data: usersData } = useQuery({
    queryKey: ['users', account_ID],
    queryFn: async () => {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      const response = await axios.get(
        `${target}/api/accounts/${account_ID}/get-users`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    },
    enabled: !!account_ID,
  });

  // Update deal owner mutation
  const updateDealOwnerMutation = useMutation({
    mutationFn: async ({ accountId, dealId, userId }) => {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      const response = await axios.put(
        `${target}/api/accounts/${accountId}/deals/${dealId}/owner`,
        { user_id: parseInt(userId) },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast({
        title: 'Deal Owner Updated',
        description: 'The deal owner has been successfully updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: 'Error',
        description: error.message || 'Failed to update deal owner',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (deal) {
      const slackChannelId = deal.slack_channel_id || '';
      setFormData({
        deal_name: deal.deal_name || '',
        lead_source: deal.lead_source || '',
        lead_source_description: deal.lead_source_description || '',
        priority: deal.priority || '',
        amount: deal.amount?.toString() || '',
        close_date: deal.close_date ? deal.close_date.substring(0,10) : '',
        probability: deal.probability ? deal.probability.toString() : '',
        stage_id: deal.stage_id ? parseInt(deal.stage_id) : '',
        stage_name: deal.stage_name || '',
        description: deal.description || '',
        type: deal.type || '',
        is_closed: deal.is_closed || false,
        is_won: deal.is_won || false,
        source_system: deal.source_system || 'manual',
        extra_fields: deal.extra_fields || {},
        owner_id: deal.user_id || null,
        user_id: deal.user_id || null,
        user_name: deal.user_name || '',
        user_last_name: deal.user_last_name || '',
        user_email: deal.user_email || '',
        slack_channel_id: slackChannelId,
      });
      setPreviousSlackChannelId(slackChannelId);
    }
  }, [deal]);

  // Update form data when connectedChannels changes
  useEffect(() => {
    if (connectedChannels.length > 0 && deal) {
      const connectedChannel = connectedChannels[0];
      const channelId = connectedChannel?.metadata?.id || connectedChannel?.channel_id;
      if (channelId) {
        setFormData(prev => ({
          ...prev,
          slack_channel_id: channelId
        }));
        setPreviousSlackChannelId(channelId);
      }
    }
  }, [connectedChannels, deal]);

  // Reset focused field when modal closes
  useEffect(() => {
    if (isOpen && initialFocusField) {
      setErrors({});
    }
  }, [isOpen, initialFocusField]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Special handling for owner_id/user_id
    if (name === 'owner_id') {
      const selectedUser = usersData?.users?.find(user => user.id === parseInt(value));
      
      // Make sure we always have the user email even if names are missing
      const userName = selectedUser?.name || '';
      const userLastName = selectedUser?.last_name || '';
      const userEmail = selectedUser?.email || '';
      
      setFormData(prev => ({
        ...prev,
        owner_id: value ? parseInt(value) : null,
        user_id: value ? parseInt(value) : null,
        user_name: userName,
        user_last_name: userLastName,
        user_email: userEmail
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      // First update the deal
      await updateDealMutation.mutateAsync({
        accountId: account_ID,
        dealId: deal.id,
        dealData: {
          ...formData,
          close_date: formData.close_date ? new Date(formData.close_date).toISOString() : null,
          probability: formData.probability ? parseFloat(formData.probability) : null,
          amount: formData.amount === '' ? null : formData.amount,
          stage_id: parseInt(formData.stage_id) || deal.stage_id,
          crm_account_id: deal.crm_account_id,
          pipeline_id: deal.pipeline_id,
          stage_name: formData.stage_name,
          user_id: formData.user_id,
          user_name: formData.user_name,
          user_last_name: formData.user_last_name,
          user_email: formData.user_email,
          slack_channel_id: formData.slack_channel_id || null
        },
      });

      // Update deal owner if changed and not being set to unassigned
      if (formData.owner_id !== deal.owner_id && formData.user_id) {
        await updateDealOwnerMutation.mutateAsync({
          accountId: account_ID,
          dealId: deal.id,
          userId: formData.user_id
        });
      }

      // Connect to Slack channel if changed and not empty
      if (formData.slack_channel_id && formData.slack_channel_id !== previousSlackChannelId) {
        await connectSlackChannelMutation.mutateAsync({
          accountId: account_ID,
          dealId: deal.id,
          userId: user_ID,
          channelId: formData.slack_channel_id
        });
        
        // Show success toast for Slack connection
        toast({
          title: 'Deal Connected to Slack',
          description: 'This deal has been successfully connected to the Slack channel.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }

      onClose();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to update deal',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleContactDelete = async () => {
    try {
      // Validate that the contact exists in the deal
      const contactExists = deal.contacts?.some(contact => contact.id === selectedContact.id);
      if (!contactExists) {
        toast({
          title: 'Error',
          description: 'This contact is no longer associated with the deal. The page will refresh.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsDeleteDialogOpen(false);
        setSelectedContact(null);
        // Refresh the deal data
        window.location.reload();
        return;
      }

      await removeContactMutation.mutateAsync({
        accountId: account_ID,
        dealId: deal.id,
        contactId: selectedContact.id,
      });

      // Close the dialog first
      setIsDeleteDialogOpen(false);
      setSelectedContact(null);

      // Show success message
      toast({
        title: 'Contact Removed',
        description: 'Contact has been removed from the deal.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

    } catch (error) {
      // Show more detailed error message
      let errorMessage = 'Failed to remove contact';
      if (error.response?.data?.error) {
        if (error.response.data.code === 'CONTACT_NOT_FOUND') {
          errorMessage = 'This contact is no longer associated with the deal. The page will refresh.';
          // Close dialog and refresh page after error
          setIsDeleteDialogOpen(false);
          setSelectedContact(null);
          setTimeout(() => window.location.reload(), 2000);
        } else {
          errorMessage = error.response.data.error;
        }
      } else if (error.message) {
        errorMessage = error.message;
      }

      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDealDelete = async () => {
    try {
      await deleteDealMutation.mutateAsync({
        accountId: account_ID,
        dealId: deal.id,
      });

      // Close the dialog first
      setIsDeleteDealDialogOpen(false);

      // Show success message
      toast({
        title: 'Deal Deleted',
        description: 'The deal has been successfully deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Close the edit modal and trigger any necessary refreshes
      onClose();
      // Clear location hash and refresh the deals list
      window.location.hash = '';
      window.location.assign(window.location.origin + '/#/admin/deals');
      setTimeout(() => { window.location.reload(); }, 100);

    } catch (error) {
      // Show error message
      let errorMessage = 'Failed to delete deal';
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }

      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderContactsSection = () => (
    <Box borderWidth="1px" borderRadius="lg" p={4}>
      <Text fontSize="sm" fontWeight="medium" mb={4}>
        Associated Contacts
      </Text>
      <VStack spacing={4} align="stretch">
        {deal.contacts && deal.contacts.length > 0 ? (
          <List spacing={2}>
            {deal.contacts.map((contact) => (
              <ListItem 
                key={contact.id}
                p={2}
                borderWidth="1px"
                borderRadius="md"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <VStack align="start" spacing={0}>
                  <Text fontWeight="medium">
                    {contact.name} {contact.last_name}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {contact.email}
                  </Text>
                </VStack>
                <IconButton
                  icon={<FaTrash />}
                  variant="ghost"
                  colorScheme="red"
                  size="sm"
                  aria-label="Remove contact"
                  onClick={() => {
                    setSelectedContact(contact);
                    setIsDeleteDialogOpen(true);
                  }}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Text color="gray.500">No contacts associated with this deal</Text>
        )}
      </VStack>
    </Box>
  );

  // Render Slack channels section
  const renderSlackSection = () => {
    // Don't show section if account has no Slack integration
    if (!hasIntegration) return null;
    
    // Get the connected channel metadata if available
    const connectedChannel = connectedChannels.length > 0 ? connectedChannels[0] : null;
    const hasConnectedChannel = !!connectedChannel;
    
    return (
      <Box borderWidth="1px" borderRadius="lg" p={4}>
        <Text fontSize="sm" fontWeight="medium" mb={4} display="flex" alignItems="center">
          <FaSlack style={{ marginRight: '8px' }} /> Slack Integration
        </Text>
        <VStack spacing={4} align="stretch">
          <FormControl>
            <FormLabel>Connect to Slack Channel</FormLabel>
            <Flex alignItems="center" mb={2}>
              {hasConnectedChannel && (
                <Badge colorScheme="green" mr={2} textTransform="none">
                  Currently connected to #{connectedChannel.metadata.name}
                </Badge>
              )}
            </Flex>
            <Select
              name="slack_channel_id"
              value={formData.slack_channel_id}
              onChange={handleInputChange}
              placeholder="Select a Slack channel"
              isDisabled={isLoadingSlack || connectSlackChannelMutation.isLoading || isLoadingDealSlack}
              ref={inputRefs.current.slack_channel}
            >
              <option value="">None</option>
              {channels.map((channel) => {
                // Check if this channel is already connected to this deal
                const isAlreadyConnected = hasConnectedChannel && 
                  connectedChannel.metadata.id === channel.id && 
                  formData.slack_channel_id === channel.id;
                
                return (
                  <option 
                    key={channel.id} 
                    value={channel.id}
                    disabled={isAlreadyConnected}
                  >
                    #{channel.name} ({channel.num_members} members)
                    {isAlreadyConnected ? ' (Already connected)' : ''}
                  </option>
                );
              })}
            </Select>
            <Text fontSize="xs" color="gray.500" mt={1}>
              Deal updates will be posted to this channel
            </Text>
          </FormControl>
        </VStack>
      </Box>
    );
  };

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        size="xl"
        initialFocusRef={initialFocusField ? inputRefs.current[initialFocusField] : undefined}
      >
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>Edit Deal</ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text fontSize="sm" color="gray.600" mb={2}>
                Update the deal details below.
              </Text>

              {/* Required Fields Section */}
              <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
                <Text fontSize="sm" fontWeight="medium" mb={4}>
                  Required Information
                </Text>
                <VStack spacing={4}>
                  <SimpleGrid columns={2} spacing={4} width="100%">
                    <FormControl isRequired>
                      <FormLabel>Deal Name</FormLabel>
                      <Input
                        name="deal_name"
                        value={formData.deal_name}
                        onChange={handleInputChange}
                        placeholder="Enter deal name"
                        ref={inputRefs.current.deal_name}
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Pipeline</FormLabel>
                      <Input
                        value={deal.pipeline_name || 'Pipeline'}
                        isDisabled
                        _disabled={{ opacity: 0.7 }}
                        ref={inputRefs.current.pipeline_name}
                      />
                    </FormControl>
                  </SimpleGrid>

                  <FormControl isRequired>
                    <FormLabel>Stage</FormLabel>
                    <Tooltip
                      hasArrow
                      label="Please select a pipeline first"
                      isDisabled={!!deal.pipeline_id}
                      placement="top"
                    >
                      <Select
                        name="stage_id"
                        value={formData.stage_id}
                        onChange={handleInputChange}
                        placeholder="Select stage"
                        isDisabled={!deal.pipeline_id}
                        ref={inputRefs.current.stage_id}
                      >
                        {pipelineStages?.map((stage) => (
                          <option key={stage.id} value={stage.id}>
                            {stage.name}
                          </option>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </VStack>
              </Box>

              {/* Deal Details Section */}
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <Text fontSize="sm" fontWeight="medium" mb={4}>
                  Deal Details
                </Text>
                <VStack spacing={4}>
                  <FormControl>
                    <FormLabel>Deal Owner</FormLabel>
                    <Select
                      name="owner_id"
                      value={formData.user_id || ''}
                      onChange={handleInputChange}
                      ref={inputRefs.current.owner_id}
                    >
                      <option value="">Unassigned</option>
                      {usersData?.users?.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name || user.last_name 
                            ? `${user.name || ''} ${user.last_name || ''}`.trim() 
                            : user.email}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <SimpleGrid columns={2} spacing={4} width="100%">
                    <FormControl>
                      <FormLabel>Amount</FormLabel>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none" color="gray.500">
                          $
                        </InputLeftElement>
                        <Input
                          name="amount"
                          type="text"
                          value={formData.amount}
                          onChange={handleInputChange}
                          placeholder="Enter amount"
                          ref={inputRefs.current.amount}
                        />
                      </InputGroup>
                    </FormControl>

                    <FormControl>
                      <FormLabel>Close Date</FormLabel>
                      <Input
                        name="close_date"
                        type="date"
                        value={formData.close_date}
                        onChange={handleInputChange}
                        ref={inputRefs.current.close_date}
                      />
                    </FormControl>
                  </SimpleGrid>

                  <FormControl>
                    <FormLabel>Priority</FormLabel>
                    <Select
                      name="priority"
                      value={formData.priority}
                      onChange={handleInputChange}
                      placeholder="Select priority"
                      ref={inputRefs.current.priority}
                    >
                      {priorityOptions.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Lead Source</FormLabel>
                    <Select
                      name="lead_source"
                      value={formData.lead_source}
                      onChange={handleInputChange}
                      placeholder="Select lead source"
                      ref={inputRefs.current.lead_source}
                    >
                      <option value="Direct Contact">Direct Contact</option>
                      <option value="Email">Email</option>
                      <option value="Website">Website</option>
                      <option value="Referral">Referral</option>
                      <option value="Events">Event</option>
                      <option value="Other">Other</option>
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Lead Source Description</FormLabel>
                    <Input
                      name="lead_source_description"
                      value={formData.lead_source_description}
                      onChange={handleInputChange}
                      placeholder="Enter lead source details"
                      ref={inputRefs.current.lead_source_description}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Deal Description</FormLabel>
                    <Input
                      name="description"
                      as="textarea"
                      value={formData.description}
                      onChange={handleInputChange}
                      placeholder="Enter deal description"
                      minH="100px"
                      ref={inputRefs.current.description}
                    />
                  </FormControl>
                </VStack>
              </Box>

              {/* Slack Channel Section */}
              {renderSlackSection()}

              {/* Add Contacts Section */}
              {renderContactsSection()}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button 
              leftIcon={<FaTrash />}
              colorScheme="red" 
              variant="ghost" 
              mr="auto"
              onClick={() => setIsDeleteDealDialogOpen(true)}
            >
              Delete Deal
            </Button>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={updateDealMutation.isLoading || connectSlackChannelMutation.isLoading}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Contact Confirmation Dialog */}
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove Contact
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to remove {selectedContact?.name} {selectedContact?.last_name} from this deal?
              This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleContactDelete}
                ml={3}
                isLoading={removeContactMutation.isLoading}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Delete Deal Confirmation Dialog */}
      <AlertDialog
        isOpen={isDeleteDealDialogOpen}
        leastDestructiveRef={deleteDealRef}
        onClose={() => setIsDeleteDealDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Deal
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text mb={4}>
                Are you sure you want to delete &ldquo;{deal?.deal_name}&rdquo;?
              </Text>
              <Text fontWeight="bold" color="red.500">
                This action cannot be undone. All deal data will be permanently removed.
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={deleteDealRef} onClick={() => setIsDeleteDealDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDealDelete}
                ml={3}
                isLoading={deleteDealMutation.isLoading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

EditDealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    crm_account_id: PropTypes.number.isRequired,
    pipeline_id: PropTypes.number.isRequired,
    stage_id: PropTypes.number.isRequired,
    stage_name: PropTypes.string,
    deal_name: PropTypes.string,
    lead_source: PropTypes.string,
    priority: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    close_date: PropTypes.string,
    probability: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
    type: PropTypes.string,
    is_closed: PropTypes.bool,
    is_won: PropTypes.bool,
    source_system: PropTypes.string,
    extra_fields: PropTypes.object,
    owner_id: PropTypes.number,
    slack_channel_id: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    })),
  }).isRequired,
  initialFocusField: PropTypes.string,
};

export default EditDealModal; 