import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Button,
  HStack,
  Icon,
  Spinner,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FaPaperPlane, FaExpandAlt } from 'react-icons/fa';
import { useEmailSender } from '../hooks/useEmailSender';
import BaseTag from '../Shared/BaseTag';

const EmailSenderSelect = ({ 
  selectedEmail, 
  onSenderChange,
}) => {
  const { 
    isLoading, 
    getCurrentUserSenders, 
    getTeamSenders 
  } = useEmailSender();

  // Get current user and team senders
  const currentUserSenders = getCurrentUserSenders();
  const teamSenders = getTeamSenders();

  // Parse selected email and user ID
  const [selectedEmailAddress, selectedUserId] = selectedEmail ? selectedEmail.split('|') : [];

  // Set default sender on mount if none selected
  useEffect(() => {
    if (!selectedEmail && currentUserSenders.length > 0) {
      const defaultSender = currentUserSenders[0];
      onSenderChange({
        send_as_email: defaultSender.email,
        send_as_user_id: defaultSender.user_id
      });
    }
  }, [selectedEmail, currentUserSenders, onSenderChange]);

  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const menuHoverBg = useColorModeValue('gray.50', 'gray.600');

  if (isLoading) {
    return (
      <Flex align="center">
        <HStack spacing={2} color="gray.500">
          <Spinner size="sm" />
          <Text fontSize="sm" fontWeight="medium">Loading...</Text>
        </HStack>
      </Flex>
    );
  }

  if (currentUserSenders.length === 0 && teamSenders.length === 0) {
    return (
      <Flex align="center">
        <Text fontSize="sm" fontWeight="medium" color="gray.500">
          No email accounts available
        </Text>
      </Flex>
    );
  }

  const selectedSender = selectedEmailAddress ? {
    email: selectedEmailAddress,
    user_id: parseInt(selectedUserId, 10)
  } : null;

  return (
    <Box>
      {selectedSender ? (
        <BaseTag
          icon={FaPaperPlane}
          hoverIcon={FaExpandAlt}
          tooltip={selectedEmailAddress}
          colorScheme="blue"
          height="32px"
        >
          <Menu>
            <MenuButton
              as={Button}
              variant="unstyled"
              rightIcon={<ChevronDownIcon style={{ marginTop: '2px' }} />}
              height="auto"
              padding={0}
              minWidth="auto"
              fontWeight="medium"
              fontSize="sm"
              display="flex"
              alignItems="center"
            >
              {selectedEmailAddress}
            </MenuButton>
            <MenuList 
              maxH="300px" 
              overflowY="auto"
              borderColor={borderColor}
              boxShadow="sm"
              py={2}
            >
              {currentUserSenders.length > 0 && (
                <MenuGroup title="Your Email Accounts" fontSize="sm">
                  {currentUserSenders.map((sender) => (
                    <MenuItem
                      key={`${sender.email}-${sender.user_id}`}
                      onClick={() => onSenderChange({
                        send_as_email: sender.email,
                        send_as_user_id: sender.user_id
                      })}
                      bg={selectedEmailAddress === sender.email ? menuHoverBg : 'transparent'}
                      _hover={{ bg: menuHoverBg }}
                      fontSize="sm"
                    >
                      <HStack spacing={2}>
                        <Icon as={FaPaperPlane} color="gray.500" />
                        <Text fontWeight="medium">{sender.email}</Text>
                      </HStack>
                    </MenuItem>
                  ))}
                </MenuGroup>
              )}
              
              {teamSenders.length > 0 && (
                <MenuGroup title="Team Email Accounts" mt={2} fontSize="sm">
                  {teamSenders.map((sender) => (
                    <MenuItem
                      key={`${sender.email}-${sender.user_id}`}
                      onClick={() => onSenderChange({
                        send_as_email: sender.email,
                        send_as_user_id: sender.user_id
                      })}
                      bg={selectedEmailAddress === sender.email ? menuHoverBg : 'transparent'}
                      _hover={{ bg: menuHoverBg }}
                      fontSize="sm"
                    >
                      <HStack spacing={2}>
                        <Icon as={FaPaperPlane} color="gray.500" />
                        <Text fontWeight="medium">{sender.email}</Text>
                      </HStack>
                    </MenuItem>
                  ))}
                </MenuGroup>
              )}
            </MenuList>
          </Menu>
        </BaseTag>
      ) : (
        <Text fontSize="sm" fontWeight="medium" color="gray.500">
          Select sender email
        </Text>
      )}
    </Box>
  );
};

EmailSenderSelect.propTypes = {
  selectedEmail: PropTypes.string,
  onSenderChange: PropTypes.func.isRequired,
};

export default EmailSenderSelect; 