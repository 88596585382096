import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  FormErrorMessage,
  Select,
  NumberInput,
  NumberInputField,
  Box,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import useCreateAccount from '../hooks/useCreateAccount';

const CreateAccountModal = ({ isOpen, onClose, onSuccess }) => {
  const toast = useToast();
  const { createAccount, isSubmitting, errors, setErrors } = useCreateAccount();

  const initialFormState = {
    name: '',
    website: '',
    email: '',
    linkedin: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    address: '',
    size: '',
    type: 'Lead',
    // Keep these in state but hide from UI
    description: '',
    industry: '',
    stage: 1,
  };

  const [formData, setFormData] = useState(initialFormState);

  const resetForm = () => {
    setFormData(initialFormState);
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleNumberInputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value === '' ? '' : value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async () => {
    const result = await createAccount(formData);
    
    if (result.success) {
      toast({
        title: 'Account created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      resetForm();
      if (onSuccess && result.data) {
        onSuccess(result.data);
      }
      onClose();
    } else {
      toast({
        title: 'Error creating account',
        description: result.error || 'Failed to create account',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Close handler that resets form
  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>Create New Account</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text fontSize="sm" color="gray.600" mb={2}>
              Enter the details for the new account.
            </Text>

            {/* Required Fields Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Required Information
              </Text>
              <VStack spacing={4}>
                <FormControl isRequired isInvalid={!!errors.name}>
                  <FormLabel>Account Name</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter account name"
                  />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>

                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl isInvalid={!!errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                    >
                      <option value="Lead">Lead</option>
                      <option value="Customer">Customer</option>
                      <option value="Partner">Partner</option>
                      <option value="Prospect">Prospect</option>
                      <option value="Other">Other</option>
                    </Select>
                  </FormControl>
                </SimpleGrid>
              </VStack>
            </Box>

            {/* Contact Information Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4}>
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Contact Information
              </Text>
              <VStack spacing={4}>
                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl isInvalid={!!errors.website}>
                    <FormLabel>Website</FormLabel>
                    <Input
                      name="website"
                      value={formData.website}
                      onChange={handleInputChange}
                      placeholder="Enter website URL"
                    />
                    <FormErrorMessage>{errors.website}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Enter phone number"
                    />
                  </FormControl>
                </SimpleGrid>

                <FormControl>
                  <FormLabel>LinkedIn</FormLabel>
                  <Input
                    name="linkedin"
                    value={formData.linkedin}
                    onChange={handleInputChange}
                    placeholder="Enter LinkedIn URL"
                  />
                </FormControl>
              </VStack>
            </Box>

            {/* Location Information Section */}
            <Box borderWidth="1px" borderRadius="lg" p={4}>
              <Text fontSize="sm" fontWeight="medium" mb={4}>
                Location Information
              </Text>
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Address</FormLabel>
                  <Input
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    placeholder="Enter address"
                  />
                </FormControl>

                <SimpleGrid columns={3} spacing={4} width="100%">
                  <FormControl>
                    <FormLabel>City</FormLabel>
                    <Input
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      placeholder="Enter city"
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>State</FormLabel>
                    <Input
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      placeholder="Enter state"
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Country</FormLabel>
                    <Input
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      placeholder="Enter country"
                    />
                  </FormControl>
                </SimpleGrid>

                <FormControl isInvalid={!!errors.size}>
                  <FormLabel>Company Size</FormLabel>
                  <NumberInput
                    value={formData.size}
                    onChange={(value) => handleNumberInputChange('size', value)}
                    min={0}
                  >
                    <NumberInputField placeholder="Enter company size" />
                  </NumberInput>
                  <FormErrorMessage>{errors.size}</FormErrorMessage>
                </FormControl>
              </VStack>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Create Account
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

CreateAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default CreateAccountModal; 