// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Inbox/index.jsx
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  FormControl,
  FormLabel,
  Stack,
  Checkbox,
  CheckboxGroup,
  Icon,
  VStack, 
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { FiFilter, FiChevronUp, FiChevronDown } from "react-icons/fi";
import { useAuthData } from "../../../auth-context";
import { useNavigate, useLocation } from "react-router-dom";
import { useFilterState } from "../hooks/useFilterState";
import { useDebounce } from "use-debounce";
import { useFetchInbox } from "../hooks/useFetchInbox";
import { useFetchFilterOptions } from "../hooks/useFetchFilterOptions";
import InboxTable from "./InboxTable";
import TableSkeleton from "../Shared/TableSkeleton";
import PageHeader from "../Shared/PageHeader";
import PaginationControls from "../Shared/PaginationControls";

const ITEMS_PER_PAGE = 10;

const Inbox = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const userID = userData?.user_ID || "";
  const role = userData?.role || "";
  const navigate = useNavigate();
  const location = useLocation();
  
  // Ref to track manual navigation
  const isManualNavigation = useRef(false);
  // Track if we're on initial mount
  const isInitialMount = useRef(true);
  // Track previous filter values
  const prevSelectedSegmentRef = useRef("");
  const prevSearchQueryRef = useRef("");
  const prevTaskTypeFilterRef = useRef([]);
  const prevTaskSubtypeFilterRef = useRef([]);
  const prevTaskOwnerFilterRef = useRef([]);
  const prevSortConfigRef = useRef({ key: '', direction: '' });
  
  // Ref to track segment changes that need processing
  const segmentChangeRef = useRef(null);
  
  // Get current page from URL query param or default to 1
  const getInitialPage = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const initialPage = page ? parseInt(page, 10) : 1;
    return initialPage;
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(getInitialPage);

  // Sorting state
  const [selectedSegment, setSelectedSegment] = useFilterState('inbox-segment-filter', 'my_tasks');
  const [searchQuery, setSearchQuery] = useFilterState('inbox-search-query', '');
  const [sortConfig, setSortConfig] = useState({
    key: 'last_activity_date',
    direction: 'descending'
  });

  // Filter states
  const [taskTypeFilter, setTaskTypeFilter] = useFilterState('inbox-tasktype-filter', []);
  const [taskSubtypeFilter, setTaskSubtypeFilter] = useFilterState('inbox-subtype-filter', []);
  const [taskOwnerFilter, setTaskOwnerFilter] = useFilterState('inbox-owner-filter', []);
  const [expandedSections, setExpandedSections] = useFilterState('inbox-expanded-sections', {
    taskType: true,
    taskSubtype: true,
    taskOwner: true
  });
  
  // Filter popover state
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Debounce search query
  const [debouncedSearchQuery] = useDebounce(searchQuery, 800);

  // Determine the status based on selected segment
  const currentStatus = useMemo(() => {
    // No longer using status filters, just use the segment-based status
    return selectedSegment === 'completed' ? 'completed' : 'to do';
  }, [selectedSegment]);

  // Fetch inbox items
  const {
    combinedItems: inboxItems,
    loading: isFetching,
    error: fetchError,
    totalCount
  } = useFetchInbox(
    accountID,
    userID,
    role,
    selectedSegment, // Pass the selected segment
    currentPage,
    10,
    debouncedSearchQuery.trim(),
    sortConfig,
    currentStatus, // Pass the status based on segment or filters
    taskOwnerFilter, // Pass the task owner filter
    taskTypeFilter, // Pass the task type filter
    taskSubtypeFilter // Pass the task subtype filter
  );

  // Fetch filter options from the new endpoint
  const {
    filterOptions: apiFilterOptions,
    loading: filterOptionsLoading
  } = useFetchFilterOptions(accountID, userID, selectedSegment, taskTypeFilter, taskSubtypeFilter, taskOwnerFilter);

  // Effect to log segment changes specifically in Inbox
  useEffect(() => {
    // Store the segment change for processing
    segmentChangeRef.current = selectedSegment;
    
    // Process segment change in a microtask to allow React to batch updates
    // This prevents multiple re-renders and request cancellations
    const timer = setTimeout(() => {
      // Reset sort config
      setSortConfig({
        key: 'last_activity_date',
        direction: 'descending'
      });
      
      // Clear task owner filter when switching segments
      setTaskOwnerFilter([]);
      
      // Reset page to 1 when changing segments
      setCurrentPage(1);
      
      // Reset the ref
      segmentChangeRef.current = null;
    }, 0);
    
    return () => clearTimeout(timer);
  }, [selectedSegment]);

  // Extract unique values for filters
  const filterOptions = useMemo(() => {
    // Use the filter options from the API if available
    if (apiFilterOptions) {
      return {
        taskTypes: apiFilterOptions.task_types?.map(type => ({
          ...type,
          label: type.label.charAt(0).toUpperCase() + type.label.slice(1)
        })) || [],
        taskSubtypes: apiFilterOptions.task_subtypes?.map(subtype => ({
          ...subtype,
          label: subtype.label.charAt(0).toUpperCase() + subtype.label.slice(1)
        })) || [],
        statuses: apiFilterOptions.statuses?.map(status => ({
          ...status,
          label: status.label.charAt(0).toUpperCase() + status.label.slice(1)
        })) || [],
        taskOwners: apiFilterOptions.user_ids || []
      };
    }

    // Fallback to the old method if API data is not available
    if (!inboxItems || inboxItems.length === 0) {
      return { taskTypes: [], taskSubtypes: [], statuses: [], taskOwners: [] };
    }

    const taskTypes = [...new Set(inboxItems.map(item => item.task_type?.toLowerCase()).filter(Boolean))];
    const taskSubtypes = [...new Set(inboxItems.map(item => item.task_subtype?.toLowerCase()).filter(Boolean))];
    
    // Extract unique task owners
    const taskOwners = [...new Set(inboxItems
      .filter(item => item.user_id)
      .map(item => ({ id: item.user_id, name: item.user_name || `User ${item.user_id}` }))
      .map(JSON.stringify))]
      .map(JSON.parse)
      .filter((owner, index, self) => 
        index === self.findIndex(o => o.id === owner.id)
      );
    
    return {
      taskTypes: taskTypes.map(type => ({
        value: type,
        label: type.charAt(0).toUpperCase() + type.slice(1),
        count: inboxItems.filter(item => item.task_type?.toLowerCase() === type).length
      })),
      taskSubtypes: taskSubtypes.map(subtype => ({
        value: subtype,
        label: subtype.charAt(0).toUpperCase() + subtype.slice(1),
        count: inboxItems.filter(item => item.task_subtype?.toLowerCase() === subtype).length
      })),
      taskOwners: taskOwners.map(owner => ({
        value: owner.id.toString(),
        label: owner.name,
        count: inboxItems.filter(item => item.user_id === owner.id).length
      })),
      statuses: [] // Empty since we're controlling status via segments
    };
  }, [inboxItems, apiFilterOptions]);

  // Filter inbox items based on current filters
  const filteredInboxItems = useMemo(() => {
    if (!inboxItems || inboxItems.length === 0) return [];
    
    return inboxItems.filter(item => {
      // Filter by user ID for "my_tasks" segment is now handled by the API
      
      // Apply task type filter
      if (taskTypeFilter.length > 0 && item.task_type && !taskTypeFilter.includes(item.task_type.toLowerCase())) {
        return false;
      }
      
      // Apply task subtype filter
      if (taskSubtypeFilter.length > 0 && item.task_subtype && !taskSubtypeFilter.includes(item.task_subtype.toLowerCase())) {
        return false;
      }
      
      // Task owner filtering is now handled by the API
      
      return true;
    });
  }, [inboxItems, taskTypeFilter, taskSubtypeFilter]);

  // Calculate pagination
  const totalPages = useMemo(() => {
    return Math.max(1, Math.ceil(totalCount / ITEMS_PER_PAGE));
  }, [totalCount]);

  // Search suggestions
  const searchSuggestions = useMemo(() => {
    if (!inboxItems) return [];
    return inboxItems.map(item => ({
      label: item.subject || "",
      id: item.id,
    }));
  }, [inboxItems]);

  // Sort inbox items
  const sortedInboxItems = useMemo(() => {
    if (!filteredInboxItems.length) return [];
    
    const sorted = [...filteredInboxItems];
    sorted.sort((a, b) => {
      let aValue = a[sortConfig.key] || '';
      let bValue = b[sortConfig.key] || '';
      
      if (sortConfig.key === 'last_activity_date') {
        aValue = aValue ? new Date(aValue).getTime() : 0;
        bValue = bValue ? new Date(bValue).getTime() : 0;
      }
      
      if (sortConfig.direction === 'ascending') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
    
    return sorted;
  }, [filteredInboxItems, sortConfig.key, sortConfig.direction]);

  const handleSort = (key) => {
    setSortConfig(prevConfig => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === 'ascending' ? 'descending' : 'ascending'
        };
      }
      return { key, direction: 'descending' };
    });
  };

  // Update URL when page changes
  const updatePageParam = useCallback((page) => {
    isManualNavigation.current = true;
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [location.search, location.pathname, navigate]);

  // Sync URL with page state
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlPage = params.get('page');
    
    // Only update from URL if this is not a manual navigation
    if (isManualNavigation.current) {
      // Reset the flag after processing
      isManualNavigation.current = false;
      return;
    }
    
    if (urlPage) {
      const parsedPage = parseInt(urlPage, 10);
      if (parsedPage !== currentPage && !isNaN(parsedPage) && parsedPage >= 1) {
        setCurrentPage(parsedPage);
      }
    } else if (currentPage !== 1) {
      // If no page param in URL but we have a current page, update URL
      updatePageParam(currentPage);
    }
  }, [location.search, currentPage, updatePageParam]);

  const handlePreviousPage = () => {
    const newPage = Math.max(currentPage - 1, 1);
    setCurrentPage(newPage);
    updatePageParam(newPage);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      updatePageParam(newPage);
    }
  };
  
  const handlePageSelect = (pageNum) => {
    setCurrentPage(pageNum);
    updatePageParam(pageNum);
  };

  /**
   * Handles the navigation to the inbox item details page.
   * @param {Object} item - The inbox item object.
   */
  const handleViewDetails = useCallback(
    (item) => {
      // Store current location, page, and all filter states
      const referrer = {
        pathname: location.pathname,
        search: `?page=${currentPage}` // Store the current page as a query param
      };
      
      // Store all the current filter states to restore when coming back
      const filterState = {
        taskOwnerFilter,
        taskTypeFilter,
        taskSubtypeFilter,
        selectedSegment,
        searchQuery,
        sortConfig
      };
      
      navigate(`/admin/inbox/${item.id}`, {
        state: { 
          referrer,
          filterState  // Store all filters
        }
      });
    },
    [navigate, location, currentPage, taskOwnerFilter, taskTypeFilter, taskSubtypeFilter, selectedSegment, searchQuery, sortConfig]
  );

  /**
   * Handles suggestion selection by navigating to the selected item's detail view.
   * @param {string|number} itemId - The ID of the selected inbox item.
   */
  const handleSuggestionSelect = useCallback(
    (itemId) => {
      // Store current location, page, and all filter states
      const referrer = {
        pathname: location.pathname,
        search: `?page=${currentPage}` // Store the current page as a query param
      };
      
      // Store all the current filter states to restore when coming back
      const filterState = {
        taskOwnerFilter,
        taskTypeFilter,
        taskSubtypeFilter,
        selectedSegment,
        searchQuery,
        sortConfig
      };
      
      navigate(`/admin/inbox/${itemId}`, {
        state: { 
          referrer,
          filterState  // Store all filters
        }
      });
    },
    [navigate, location, currentPage, taskOwnerFilter, taskTypeFilter, taskSubtypeFilter, selectedSegment, searchQuery, sortConfig]
  );

  const segmentOptions = [
    { label: "My Tasks", value: "my_tasks", tooltip: "Show tasks assigned to your user ID with status 'to do'" },
    { label: "All Tasks", value: "all_tasks", tooltip: "Show all tasks with status 'to do' regardless of user" },
    { label: "Completed", value: "completed", tooltip: "Show all completed tasks" },
  ];

  // Handle filter changes
  const handleFilterChange = (filterType, value) => {
    // Preserve the detail navigation state if it exists
    const isFromDetail = location.state?.from === 'detail';
    
    // Reset to first page whenever filters change, but use manual navigation flag
    isManualNavigation.current = true;
    setCurrentPage(1);
    const params = new URLSearchParams(location.search);
    params.set('page', '1');
    
    // When navigating, preserve the state that we're from a detail view
    // This prevents filter comparison from triggering unwanted page resets
    const navigationState = isFromDetail ? { from: 'detail', filterStateRestored: true } : {};
    navigate(`${location.pathname}?${params.toString()}`, { 
      replace: true,
      state: navigationState
    });
    
    switch(filterType) {
      case 'taskType':
        setTaskTypeFilter(value);
        break;
      case 'taskSubtype':
        setTaskSubtypeFilter(value);
        break;
      case 'taskOwner':
        setTaskOwnerFilter(value);
        break;
      default:
        break;
    }
  };

  // Reset all filters
  const handleResetFilters = () => {
    // Preserve the detail navigation state if it exists
    const isFromDetail = location.state?.from === 'detail';
    
    setTaskTypeFilter([]);
    setTaskSubtypeFilter([]);
    setTaskOwnerFilter([]);
    
    // Use manual navigation flag when resetting page
    isManualNavigation.current = true;
    setCurrentPage(1);
    const params = new URLSearchParams(location.search);
    params.set('page', '1');
    
    // When navigating, preserve the state that we're from a detail view
    const navigationState = isFromDetail ? { from: 'detail', filterStateRestored: true } : {};
    navigate(`${location.pathname}?${params.toString()}`, { 
      replace: true,
      state: navigationState
    });
  };

  // Toggle expanded sections
  const toggleSection = (sectionName) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionName]: !prev[sectionName]
    }));
  };

  // Reset to first page if filters or search changes, but only if they actually changed
  useEffect(() => {
    // Skip resetting page on initial mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      prevSelectedSegmentRef.current = selectedSegment;
      prevSearchQueryRef.current = debouncedSearchQuery;
      prevTaskTypeFilterRef.current = taskTypeFilter;
      prevTaskSubtypeFilterRef.current = taskSubtypeFilter;
      prevTaskOwnerFilterRef.current = taskOwnerFilter;
      prevSortConfigRef.current = sortConfig;
      return;
    }

    // Check if we're returning from a detail view (which would have a page in the URL)
    const isReturningFromDetailView = location.state?.from === 'detail';
    const isRestoringFilterState = location.state?.filterStateRestored;
    
    // Skip filter change detection during filter restoration
    if (isReturningFromDetailView && !isRestoringFilterState) {
      return;
    }
    
    // Check if any filters have actually changed
    const selectedSegmentChanged = prevSelectedSegmentRef.current !== selectedSegment;
    const searchQueryChanged = prevSearchQueryRef.current !== debouncedSearchQuery;
    const taskTypeFilterChanged = !arraysEqual(prevTaskTypeFilterRef.current, taskTypeFilter);
    const taskSubtypeFilterChanged = !arraysEqual(prevTaskSubtypeFilterRef.current, taskSubtypeFilter);
    const taskOwnerFilterChanged = !arraysEqual(prevTaskOwnerFilterRef.current, taskOwnerFilter);
    const sortConfigChanged = 
      prevSortConfigRef.current.key !== sortConfig.key || 
      prevSortConfigRef.current.direction !== sortConfig.direction;
    
    const filtersChanged = 
      selectedSegmentChanged ||
      searchQueryChanged ||
      taskTypeFilterChanged ||
      taskSubtypeFilterChanged ||
      taskOwnerFilterChanged ||
      sortConfigChanged;
    
    // Update refs
    prevSelectedSegmentRef.current = selectedSegment;
    prevSearchQueryRef.current = debouncedSearchQuery;
    prevTaskTypeFilterRef.current = taskTypeFilter;
    prevTaskSubtypeFilterRef.current = taskSubtypeFilter;
    prevTaskOwnerFilterRef.current = taskOwnerFilter;
    prevSortConfigRef.current = sortConfig;
    
    // Only reset page if filters actually changed and we're not returning from a detail view
    if (filtersChanged && !isReturningFromDetailView) {
      // Use manual navigation to avoid race condition
      isManualNavigation.current = true;
      setCurrentPage(1);
      const params = new URLSearchParams(location.search);
      params.set('page', '1');
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
  }, [selectedSegment, debouncedSearchQuery, taskTypeFilter, taskSubtypeFilter, taskOwnerFilter, sortConfig, navigate, location]);

  // Helper function to compare arrays
  const arraysEqual = (a, b) => {
    // If both are exactly the same object reference, they're equal
    if (a === b) return true;
    
    // Handle undefined and null cases
    if (a == null && b == null) return true;
    if (a == null || b == null) return false;
    
    // Handle case where one might be an array and another might be something else
    // This can happen due to JSON serialization/deserialization
    const isAArray = Array.isArray(a);
    const isBArray = Array.isArray(b);
    
    if (!isAArray && !isBArray) {
      // If neither is an array, compare directly
      return a === b;
    } else if (!isAArray || !isBArray) {
      // If only one is an array, they're not equal
      // Unless one is an empty array and the other is null/undefined
      // which we've already handled above
      return false;
    }
    
    // Both are arrays now
    
    // Handle empty arrays - consider [] equivalent to undefined/null for filter purposes
    const isAEmpty = a.length === 0;
    const isBEmpty = b.length === 0;
    
    if (isAEmpty && isBEmpty) return true;
    
    // Regular array comparison
    if (a.length !== b.length) return false;
    
    // Sort both arrays to ensure consistent comparison
    // Convert all items to string for consistent comparison in case of mixed types
    const sortedA = [...a].map(String).sort();
    const sortedB = [...b].map(String).sort();
    
    for (let i = 0; i < sortedA.length; i++) {
      if (sortedA[i] !== sortedB[i]) return false;
    }
    
    return true;
  };

  // Calculate active filters count
  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (taskTypeFilter.length > 0) count++;
    if (taskSubtypeFilter.length > 0) count++;
    if (taskOwnerFilter.length > 0) count++;
    return count;
  }, [taskTypeFilter, taskSubtypeFilter, taskOwnerFilter]);

  // Effect to restore filter state when returning from detail view
  useEffect(() => {
    if (location.state?.from === 'detail' && location.state?.filterState) {
      const { 
        taskOwnerFilter: storedOwnerFilter,
        taskTypeFilter: storedTypeFilter,
        taskSubtypeFilter: storedSubtypeFilter,
        selectedSegment: storedSegment,
        searchQuery: storedQuery,
        sortConfig: storedSortConfig
      } = location.state.filterState;
      
      // Only update if the stored values are different from current values
      // This helps prevent unnecessary state updates and re-renders
      
      if (storedOwnerFilter && !arraysEqual(storedOwnerFilter, taskOwnerFilter)) {
        setTaskOwnerFilter(storedOwnerFilter);
      }
      
      if (storedTypeFilter && !arraysEqual(storedTypeFilter, taskTypeFilter)) {
        setTaskTypeFilter(storedTypeFilter);
      }
      
      if (storedSubtypeFilter && !arraysEqual(storedSubtypeFilter, taskSubtypeFilter)) {
        setTaskSubtypeFilter(storedSubtypeFilter);
      }
      
      if (storedSegment && storedSegment !== selectedSegment) {
        setSelectedSegment(storedSegment);
      }
      
      if (storedQuery && storedQuery !== searchQuery) {
        setSearchQuery(storedQuery);
      }
      
      if (storedSortConfig && 
         (storedSortConfig.key !== sortConfig.key || 
          storedSortConfig.direction !== sortConfig.direction)) {
        setSortConfig(storedSortConfig);
      }
      
      // Clear the state to prevent re-applying on further navigation
      window.history.replaceState(
        {
          ...window.history.state,
          state: {
            ...location.state,
            filterStateRestored: true  // Mark as restored
          }
        },
        ''
      );
    }
  }, [location.state, 
      taskOwnerFilter, setTaskOwnerFilter,
      taskTypeFilter, setTaskTypeFilter, 
      taskSubtypeFilter, setTaskSubtypeFilter,
      selectedSegment, setSelectedSegment,
      searchQuery, setSearchQuery,
      sortConfig, setSortConfig,
      arraysEqual]);

  return (
    <Box px={4}>
      <PageHeader
        title="Inbox"
        segmentOptions={segmentOptions}
        selectedSegment={selectedSegment}
        onSegmentChange={setSelectedSegment}
        searchPlaceholder="Search inbox..."
        onSearchChange={setSearchQuery}
        searchSuggestions={searchSuggestions.map((s, index) => ({ id: index, label: s.label }))}
        searchQuery={searchQuery}
        showSearch={true}
        onSuggestionSelect={handleSuggestionSelect}
        additionalControls={
          <Popover 
            placement="bottom-end" 
            isOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          >
            <PopoverTrigger>
              <Button 
                leftIcon={<Icon as={FiFilter} />} 
                rightIcon={<Icon as={isFilterOpen ? FiChevronUp : FiChevronDown} />}
                variant="outline" 
                bg="white"
                borderRadius="md"
                borderColor="gray.300"
                color={useColorModeValue("gray.700", "gray.200")}
                _hover={{
                  bg: "gray.50",
                }}
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              >
                Filters {activeFiltersCount > 0 && `(${activeFiltersCount})`}
              </Button>
            </PopoverTrigger>
            <PopoverContent 
              width="400px" 
              maxH="calc(100vh - var(--chakra-space-48))"
              boxShadow="lg"
              mt={2}
              display="flex"
              flexDirection="column"
            >
              <PopoverHeader 
                fontWeight="semibold" 
                position="sticky"
                top={0}
                bg="white"
                zIndex={1}
                borderBottomWidth="1px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text>Task Filters</Text>
                {isFetching && <Spinner size="sm" ml={2} />}
                <Text fontSize="sm" color="gray.500">
                  {filteredInboxItems.length} of {totalCount} tasks
                </Text>
              </PopoverHeader>
              <PopoverBody 
                flex="1" 
                overflowY="auto"
                py={4}
              >
                <VStack spacing={4} align="stretch">
                  {/* Task Type Filter */}
                  {filterOptions.taskTypes.length > 0 && (
                    <FormControl>
                      <Button
                        variant="unstyled"
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => toggleSection('taskType')}
                        mb={2}
                        _hover={{ bg: "gray.50" }}
                        p={2}
                        borderRadius="md"
                      >
                        <FormLabel fontWeight="medium" mb={0}>
                          Task Type
                          {taskTypeFilter.length > 0 && (
                            <Text as="span" ml={2} color="gray.500" fontSize="sm">
                              ({taskTypeFilter.length} filter{taskTypeFilter.length === 1 ? '' : 's'})
                            </Text>
                          )}
                        </FormLabel>
                        <Box display="flex" alignItems="center">
                          {filterOptionsLoading && <Spinner size="xs" mr={2} />}
                          <Icon
                            as={expandedSections.taskType ? FiChevronUp : FiChevronDown}
                            boxSize={5}
                          />
                        </Box>
                      </Button>
                      {expandedSections.taskType && (
                        <Box 
                          maxH="150px"
                          overflowY="auto"
                          pl={2}
                          pr={2}
                          pb={2}
                          borderWidth="1px"
                          borderRadius="md"
                          bg="gray.50"
                        >
                          <CheckboxGroup
                            value={taskTypeFilter}
                            onChange={(values) => handleFilterChange('taskType', values)}
                          >
                            <Stack spacing={1} pt={2}>
                              {filterOptions.taskTypes.length > 0 ? (
                                filterOptions.taskTypes.map((option) => (
                                  <Checkbox key={option.value} value={option.value}>
                                    {option.label} ({option.count})
                                  </Checkbox>
                                ))
                              ) : (
                                <Text color="gray.500" fontSize="sm" py={1}>
                                  No task types available with current filters
                                </Text>
                              )}
                            </Stack>
                          </CheckboxGroup>
                        </Box>
                      )}
                    </FormControl>
                  )}

                  {/* Task Subtype Filter */}
                  {filterOptions.taskSubtypes.length > 0 && (
                    <FormControl>
                      <Button
                        variant="unstyled"
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => toggleSection('taskSubtype')}
                        mb={2}
                        _hover={{ bg: "gray.50" }}
                        p={2}
                        borderRadius="md"
                      >
                        <FormLabel fontWeight="medium" mb={0}>
                          Task Subtype
                          {taskSubtypeFilter.length > 0 && (
                            <Text as="span" ml={2} color="gray.500" fontSize="sm">
                              ({taskSubtypeFilter.length} filter{taskSubtypeFilter.length === 1 ? '' : 's'})
                            </Text>
                          )}
                        </FormLabel>
                        <Box display="flex" alignItems="center">
                          {filterOptionsLoading && <Spinner size="xs" mr={2} />}
                          <Icon
                            as={expandedSections.taskSubtype ? FiChevronUp : FiChevronDown}
                            boxSize={5}
                          />
                        </Box>
                      </Button>
                      {expandedSections.taskSubtype && (
                        <Box 
                          maxH="150px"
                          overflowY="auto"
                          pl={2}
                          pr={2}
                          pb={2}
                          borderWidth="1px"
                          borderRadius="md"
                          bg="gray.50"
                        >
                          <CheckboxGroup
                            value={taskSubtypeFilter}
                            onChange={(values) => handleFilterChange('taskSubtype', values)}
                          >
                            <Stack spacing={1} pt={2}>
                              {filterOptions.taskSubtypes.map((option) => (
                                <Checkbox key={option.value} value={option.value}>
                                  {option.label} ({option.count})
                                </Checkbox>
                              ))}
                            </Stack>
                          </CheckboxGroup>
                        </Box>
                      )}
                    </FormControl>
                  )}

                  {/* Task Owner Filter - Only show for all_tasks and completed segments */}
                  {(selectedSegment === 'all_tasks' || selectedSegment === 'completed') && 
                   filterOptions.taskOwners && filterOptions.taskOwners.length > 0 && (
                    <FormControl>
                      <Button
                        variant="unstyled"
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => toggleSection('taskOwner')}
                        mb={2}
                        _hover={{ bg: "gray.50" }}
                        p={2}
                        borderRadius="md"
                      >
                        <FormLabel fontWeight="medium" mb={0}>
                          Task Owner
                          {Array.isArray(taskOwnerFilter) && taskOwnerFilter.length > 0 && (
                            <Text as="span" ml={2} color="gray.500" fontSize="sm">
                              ({taskOwnerFilter.length} filter{taskOwnerFilter.length === 1 ? '' : 's'})
                            </Text>
                          )}
                        </FormLabel>
                        <Box display="flex" alignItems="center">
                          {filterOptionsLoading && <Spinner size="xs" mr={2} />}
                          <Icon
                            as={expandedSections.taskOwner ? FiChevronUp : FiChevronDown}
                            boxSize={5}
                          />
                        </Box>
                      </Button>
                      {expandedSections.taskOwner && (
                        <Box 
                          maxH="150px"
                          overflowY="auto"
                          pl={2}
                          pr={2}
                          pb={2}
                          borderWidth="1px"
                          borderRadius="md"
                          bg="gray.50"
                        >
                          <CheckboxGroup
                            value={taskOwnerFilter}
                            onChange={(values) => handleFilterChange('taskOwner', values)}
                          >
                            <Stack spacing={1} pt={2}>
                              {filterOptions.taskOwners.map((option) => (
                                <Checkbox key={option.value} value={option.value}>
                                  {option.label} ({option.count})
                                </Checkbox>
                              ))}
                            </Stack>
                          </CheckboxGroup>
                        </Box>
                      )}
                    </FormControl>
                  )}
                </VStack>
              </PopoverBody>
              <PopoverFooter 
                display="flex" 
                justifyContent="flex-end"
                borderTopWidth="1px"
                p={3}
                position="sticky"
                bottom={0}
                bg="white"
                zIndex={1}
              >
                <Button 
                  size="sm" 
                  colorScheme="blue" 
                  variant="ghost" 
                  onClick={handleResetFilters}
                  isDisabled={activeFiltersCount === 0}
                >
                  Reset Filters
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        }
      />

      {isFetching || filterOptionsLoading ? (
        <TableSkeleton rowCount={10} />
      ) : fetchError ? (
        <Text>Failed to load inbox items. Please try again later. {fetchError?.message || String(fetchError)}</Text>
      ) : (
        <>
          <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
              {filteredInboxItems.length === 0 ? (
                <Text textAlign="center" py={8} color="gray.500">
                  No inbox items available.
                </Text>
              ) : (
                <InboxTable
                  inboxItems={sortedInboxItems}
                  onViewDetails={handleViewDetails}
                  onSort={handleSort}
                  sortConfig={sortConfig}
                  searchQuery={debouncedSearchQuery}
                />
              )}
            </Box>
          </SimpleGrid>
          {filteredInboxItems.length > 0 && (
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              onPrevious={handlePreviousPage}
              onNext={handleNextPage}
              onPageSelect={handlePageSelect}
              disablePrevious={currentPage === 1}
              disableNext={currentPage >= totalPages}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Inbox;