import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSidebar } from "../contexts/SidebarContext";
import { getDefaultRedirectPath } from "../views/lysto/hooks/useFetchVisibleRoutes";

/**
 * Component that dynamically redirects to the appropriate route based on visible routes
 * If Settings is the only visible route, it redirects to "/admin/settings"
 * Otherwise, it falls back to "/admin/inbox"
 */
function DynamicRedirect() {
  const { visibleRoutes, loading } = useSidebar();
  const [redirectPath, setRedirectPath] = useState(null);

  useEffect(() => {
    // Once loading is complete and visibleRoutes is available, determine the redirect path
    if (!loading) {
      const path = getDefaultRedirectPath(visibleRoutes);
      setRedirectPath(path);
    }
  }, [visibleRoutes, loading]);

  // While loading, render nothing (or optionally a loading indicator)
  if (loading || redirectPath === null) {
    return null;
  }

  // Once we have determined the path, redirect to it
  return <Navigate to={redirectPath} replace />;
}

export default DynamicRedirect;
