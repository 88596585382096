// src/components/Inbox/MessageInput.jsx
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, VStack, HStack, IconButton, Portal, useColorModeValue } from '@chakra-ui/react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import HardBreak from '@tiptap/extension-hard-break';
import {
  FaBold, FaItalic, FaUnderline, FaStrikethrough,
  FaListOl, FaListUl, FaLink, FaUnlink, FaUndo, FaRedo,
  FaHeading, FaQuoteLeft, FaEraser
} from 'react-icons/fa';

// Custom Toolbar Component using Chakra UI
const Toolbar = ({ editor }) => {
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });

  const tooltipBg = useColorModeValue("gray.700", "white");
  const tooltipTextColor = useColorModeValue("white", "gray.700");

  if (!editor) {
    return null;
  }

  const setLink = () => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  };

  return (
    <>
      <HStack
        p={2}
        borderBottomWidth="1px"
        borderColor="gray.300"
        spacing={1}
        wrap="wrap"
        bg="gray.50"
        borderTopRadius="lg" // Match container radius
        onMouseMove={(e) => setTooltipPos({ x: e.clientX, y: e.clientY })}
      >
        <IconButton
          icon={<FaBold />}
          aria-label="Bold"
          size="sm"
          variant={editor.isActive('bold') ? 'solid' : 'ghost'}
          colorScheme={editor.isActive('bold') ? 'blue' : 'gray'}
          onClick={() => editor.chain().focus().toggleBold().run()}
          isDisabled={!editor.can().chain().focus().toggleBold().run()}
          onMouseEnter={() => setTooltipText('Bold')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaItalic />}
          aria-label="Italic"
          size="sm"
          variant={editor.isActive('italic') ? 'solid' : 'ghost'}
          colorScheme={editor.isActive('italic') ? 'blue' : 'gray'}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          isDisabled={!editor.can().chain().focus().toggleItalic().run()}
          onMouseEnter={() => setTooltipText('Italic')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaUnderline />}
          aria-label="Underline"
          size="sm"
          variant={editor.isActive('underline') ? 'solid' : 'ghost'}
          colorScheme={editor.isActive('underline') ? 'blue' : 'gray'}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          isDisabled={!editor.can().chain().focus().toggleUnderline().run()}
          onMouseEnter={() => setTooltipText('Underline')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaStrikethrough />}
          aria-label="Strikethrough"
          size="sm"
          variant={editor.isActive('strike') ? 'solid' : 'ghost'}
          colorScheme={editor.isActive('strike') ? 'blue' : 'gray'}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          isDisabled={!editor.can().chain().focus().toggleStrike().run()}
          onMouseEnter={() => setTooltipText('Strikethrough')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaListUl />}
          aria-label="Bullet List"
          size="sm"
          variant={editor.isActive('bulletList') ? 'solid' : 'ghost'}
          colorScheme={editor.isActive('bulletList') ? 'blue' : 'gray'}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          isDisabled={!editor.can().chain().focus().toggleBulletList().run()}
          onMouseEnter={() => setTooltipText('Bullet List')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaListOl />}
          aria-label="Ordered List"
          size="sm"
          variant={editor.isActive('orderedList') ? 'solid' : 'ghost'}
          colorScheme={editor.isActive('orderedList') ? 'blue' : 'gray'}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          isDisabled={!editor.can().chain().focus().toggleOrderedList().run()}
          onMouseEnter={() => setTooltipText('Ordered List')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaLink />}
          aria-label="Set Link"
          size="sm"
          variant={editor.isActive('link') ? 'solid' : 'ghost'}
          colorScheme={editor.isActive('link') ? 'blue' : 'gray'}
          onClick={setLink}
          onMouseEnter={() => setTooltipText('Set Link')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaUnlink />}
          aria-label="Unset Link"
          size="sm"
          variant={'ghost'}
          onClick={() => editor.chain().focus().unsetLink().run()}
          isDisabled={!editor.isActive('link')}
          onMouseEnter={() => setTooltipText('Unset Link')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaUndo />}
          aria-label="Undo"
          size="sm"
          variant={'ghost'}
          onClick={() => editor.chain().focus().undo().run()}
          isDisabled={!editor.can().chain().focus().undo().run()}
          onMouseEnter={() => setTooltipText('Undo')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaRedo />}
          aria-label="Redo"
          size="sm"
          variant={'ghost'}
          onClick={() => editor.chain().focus().redo().run()}
          isDisabled={!editor.can().chain().focus().redo().run()}
          onMouseEnter={() => setTooltipText('Redo')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaHeading size="0.9em" />}
          aria-label="Heading 2"
          size="sm"
          variant={editor.isActive('heading', { level: 2 }) ? 'solid' : 'ghost'}
          colorScheme={editor.isActive('heading', { level: 2 }) ? 'blue' : 'gray'}
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          isDisabled={!editor.can().chain().focus().toggleHeading({ level: 2 }).run()}
          onMouseEnter={() => setTooltipText('Heading 2')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaQuoteLeft />}
          aria-label="Blockquote"
          size="sm"
          variant={editor.isActive('blockquote') ? 'solid' : 'ghost'}
          colorScheme={editor.isActive('blockquote') ? 'blue' : 'gray'}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          isDisabled={!editor.can().chain().focus().toggleBlockquote().run()}
          onMouseEnter={() => setTooltipText('Blockquote')}
          onMouseLeave={() => setTooltipText('')}
        />
        <IconButton
          icon={<FaEraser />}
          aria-label="Clear Formatting"
          size="sm"
          variant={'ghost'}
          onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}
          isDisabled={!editor.can().chain().focus().unsetAllMarks().run() && !editor.can().chain().focus().clearNodes().run()}
          onMouseEnter={() => setTooltipText('Clear Formatting')}
          onMouseLeave={() => setTooltipText('')}
        />
      </HStack>
      {tooltipText && (
        <Portal>
          <Box
            position="fixed"
            left={tooltipPos.x + 10} // Offset slightly from cursor
            top={tooltipPos.y + 10} // Offset slightly from cursor
            bg={tooltipBg}
            color={tooltipTextColor}
            px="8px"
            py="4px"
            fontSize="sm"
            borderRadius="md"
            zIndex="tooltip" // Ensure it's above other elements
            pointerEvents="none" // Prevent tooltip from blocking mouse events
            boxShadow="sm"
          >
            {tooltipText}
          </Box>
        </Portal>
      )}
    </>
  );
};

Toolbar.propTypes = {
  editor: PropTypes.object,
};


const MessageInput = ({ 
  message = '', 
  setMessage, 
  isSending = false 
}) => {

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        hardBreak: false,
        heading: {
          levels: [2, 3],
        },
        paragraph: {
          HTMLAttributes: {
             // Add any specific attributes if needed
          },
        },
         // Ensure list items handle paragraphs correctly
         listItem: {
            HTMLAttributes: {
                // class: 'list-item', // Example class if needed
            },
        },
        // Add Link extension explicitly if not default in your StarterKit version or for configuration
         link: {
           openOnClick: false, // Don't open links on click in the editor
           autolink: true, // Automatically detect links
           HTMLAttributes: {
             target: '_blank', // Open links in new tab
             rel: 'noopener noreferrer nofollow', // Security attributes
           },
         },
         strike: {}, // Ensure strikethrough is enabled via StarterKit if possible, else add explicitly
      }),
      Underline, // Add the Underline extension here
      HardBreak.extend({
        addKeyboardShortcuts() {
          return {
            // Make Enter insert a hard break
            'Enter': () => this.editor.commands.setHardBreak(),
            // Ensure Shift+Enter also inserts a hard break (redundant but explicit)
            'Shift-Enter': () => this.editor.commands.setHardBreak(),
          }
        },
      }),
    ],
    content: message, // Initial content
    editable: !isSending,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      // Check if editor content is considered empty by Tiptap
      const isEmpty = editor.isEmpty;
      // Update state only if content changed and it's not just the default empty state
      setMessage(isEmpty ? '' : html);
    },
  });

  // Effect to update editor content when the 'message' prop changes externally
  useEffect(() => {
    if (editor && !editor.isFocused) {
      const isCurrentContentEmpty = editor.isEmpty;
      const currentContentHTML = editor.getHTML();
      
      if (message === '' && isCurrentContentEmpty) {
         return;
      }

      if (message !== currentContentHTML) {
        editor.commands.setContent(message, false);
      }
    }
  }, [message, editor]); // Rerun when message or editor instance changes

  // Effect to update the editable state when 'isSending' changes
   useEffect(() => {
    if (editor) {
      editor.setEditable(!isSending);
    }
  }, [isSending, editor]);

  return (
    <VStack spacing={0} align="stretch" flex="1"> {/* Reduced spacing */}
      <Box
        borderWidth="1px"
        borderColor="gray.300"
        borderRadius="lg"
        bg="white"
        position="relative"
        height="400px"
        display="flex" // Use flexbox for vertical layout
        flexDirection="column" // Stack toolbar and editor vertically
        overflow="hidden" // Keep overflow hidden on the main container
      >
        <Toolbar editor={editor} />
        <Box
          flex="1" // Allow editor content to fill remaining space
          overflowY="auto" // Enable scrolling within the editor area itself
        sx={{
            // Apply styles specifically to Tiptap's editor element
            '.ProseMirror': {
              padding: '12px',
              height: '100%', // Ensure it tries to fill the container
              minHeight: '300px', // Ensure minimum height
              outline: 'none', // Remove focus outline
              '&:focus': {
                 // Optional: add focus styles if needed
              },
              // Basic styling for paragraphs and lists
              p: {
                margin: 0, // Reset paragraph margin
                marginBottom: '0.5em', // Add some space between paragraphs
              },
              ul: {
                paddingLeft: '20px',
                margin: 0,
                marginBottom: '0.5em',
              },
              ol: {
                paddingLeft: '20px',
                margin: 0,
                marginBottom: '0.5em',
              },
              li: {
                 marginBottom: '0.2em', // Space between list items
              },
              // Ensure scrollbar styles are applied if needed within this box
            '&::-webkit-scrollbar': { 
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'gray.50',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'gray.300',
              borderRadius: '4px',
            },
            }
          }}
        >
          <EditorContent editor={editor} />
        </Box>
      </Box>
    </VStack>
  );
};

MessageInput.propTypes = {
  message: PropTypes.string,
  setMessage: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
};

export default MessageInput;
