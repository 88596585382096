// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Deals/index.jsx
import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  PopoverFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  CheckboxGroup,
  Checkbox,
  Icon,
  HStack,
  VStack,
  useColorModeValue,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Spinner,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Radio,
  RadioGroup,
  Heading,
  Center,
} from "@chakra-ui/react";
import { FiFilter, FiPlus, FiDownload } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthData } from "auth-context";
import DealsTable from "./DealsTable";
import PaginationControls from "../Shared/PaginationControls";
import PageHeader from "../Shared/PageHeader";
import TableSkeleton from "../Shared/TableSkeleton";
import useFetchDealsUnified from "../hooks/useFetchDealsUnified";
import { useDebounce } from "use-debounce";
import { useToast } from "@chakra-ui/react"; // Import useToast for notifications
import { useFilterState } from "../hooks/useFilterState";
import CreateDealModal from "./CreateDealModal";
import { ChevronDown, ChevronUp } from "lucide-react";
import useFetchDealMetricFilterOptions from "../hooks/useFetchDealMetricFilterOptions";
import useFetchContactExportFields from "../hooks/useFetchContactExportFields";

// Create separate icon components to avoid conditional hook issues
const ChevronUpIcon = ({ boxSize }) => <Icon as={ChevronUp} boxSize={boxSize} />;
const ChevronDownIcon = ({ boxSize }) => <Icon as={ChevronDown} boxSize={boxSize} />;
const FilterIcon = () => <Icon as={FiFilter} />;
const PlusIcon = () => <Icon as={FiPlus} />;

// Stage color mapping
const stageColorMap = {
  // Existing stages
  "MQL": "yellow.500",
  "SQL": "green.400",
  "Contract": "purple.500",
  "Onboarding": "blue.500",
  "Retention": "teal.500",
  "Demo": "cyan.500",
  "Discovery": "orange.500",
  "Negotiation": "blue.400",
  "Nurturing": "cyan.500",
  "Closed Lost": "red.500",
  "Lead": "orange.500",
  "Marketing Qualified Lead": "yellow.500",
  "Sales Qualified Lead": "green.400",
  "Discovery - BANT": "teal.500",
  "Meeting Scheduled": "blue.400",
  "Has Proposal": "cyan.500",
  "Contract Sent": "purple.500",
  "Deal Adjudication": "pink.500",
  "Nurture": "cyan.500",
  "On Hold": "gray.500",
  "Maybe Coaching? Circle Back": "blue.300",
  "Only Investment": "pink.500",
  "Closed Won": "green.600",
  "Closed-Paid": "green.500",
  "Client (Closed-Paid)": "green.500",
  "Lost": "red.500",
  "Qualified Out": "red.400",
  
  // New stages from your data
  "Preparation": "blue.400",
  "Interest": "yellow.400",
  "Proposal": "purple.400",
  "Closed": "green.500",
  "Inactive": "gray.400",
  "Content": "teal.400",
  "PO": "blue.300",
  "PM": "green.300",
  "Other": "gray.300"
};

// Priority color mapping
const priorityColorMap = {
  "PO": "blue.400",
  "PM": "green.400",
  "Content": "teal.400",
  "Other": "gray.400",
  "High": "red.400",
  "Medium": "orange.400",
  "Low": "yellow.400"
};

const ITEMS_PER_PAGE = 10;

const Deals = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const navigate = useNavigate();
  const location = useLocation(); // Add this to read URL params
  const toast = useToast(); // Initialize toast for notifications
  const selectedBg = useColorModeValue("blue.500", "blue.300");
  const selectedTextColor = useColorModeValue("white", "gray.800");
  const buttonTextColor = useColorModeValue("gray.700", "gray.200");
  
  // State for dynamic filters (selected values) - Keep this
  const [dynamicFilters, setDynamicFilters] = useFilterState("deals-dynamic-filters", {});

  // State for metric filter values - Keep this
  const [metricFilterValues, setMetricFilterValues] = useState({});

  // New state for metric filter drawer - Keep this
  const [metricFilter, setMetricFilter] = useFilterState("deals-metric-filter", []);

  // Filter states - Define all filters before they're used
  const [currentStatusFilter] = useFilterState("deals-status-filter", "all");
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);
  const [booleanFilters, setBooleanFilters] = useFilterState("deals-boolean-filters", {});

  // Sorting state
  const [sortConfig, setSortConfig] = useFilterState("deals-sort-config", { 
    key: 'last_activity_date', 
    direction: 'descending' 
  });

  // Determine if metric filter is active - moved this up to fix reference error
  const hasActiveMetricFilter = useMemo(() => {
    // Check if there's at least one valid filter
    if (Array.isArray(metricFilter)) {
      return metricFilter.some(filter => filter && filter.metric_key && filter.operator);
    }
    // For backwards compatibility with old single-object format
    return metricFilter && metricFilter.metric_key && metricFilter.operator;
  }, [metricFilter]);

  // Create an object with all current filter parameters for the API
  const currentFilters = useMemo(() => {
    const filters = {};
    
    // Add pipeline filter
    if (dynamicFilters.pipeline_options?.values?.length === 1) {
      filters.pipeline_id = dynamicFilters.pipeline_options.values[0];
    }
    
    // Add stage filter
    if (dynamicFilters.stage_options?.values?.length > 0) {
      filters.stage_name = dynamicFilters.stage_options.values.join(',');
    }
    
    // Add user filter
    if (dynamicFilters.user_options?.values?.length > 0) {
      filters.user_id = dynamicFilters.user_options.values.join(',');
    }
    
    // Add lead source filter
    if (dynamicFilters.lead_source_options?.values?.length > 0) {
      filters.lead_source = dynamicFilters.lead_source_options.values.join(',');
    }
    
    // Add priority filter
    if (dynamicFilters.priority_options?.values?.length > 0) {
      filters.priority = dynamicFilters.priority_options.values.join(',');
    }
    
    // Add amount range filter
    if (dynamicFilters.amount?.min !== undefined) {
      filters.min_amount = dynamicFilters.amount.min;
    }
    if (dynamicFilters.amount?.max !== undefined) {
      filters.max_amount = dynamicFilters.amount.max;
    }
    
    // Add date range filters
    for (const [key, filter] of Object.entries(dynamicFilters)) {
      if (filter?.startDate && filter?.endDate) {
        if (key === 'created_date' || key === 'deal_created_date') {
          filters.start_date = filter.startDate;
          filters.end_date = filter.endDate;
        }
      }
    }
    
    // Add boolean filters
    if (booleanFilters) {
      if (booleanFilters.is_closed === 'yes') filters.is_closed = 'true';
      if (booleanFilters.is_closed === 'no') filters.is_closed = 'false';
      if (booleanFilters.is_won === 'yes') filters.is_won = 'true';
      if (booleanFilters.is_won === 'no') filters.is_won = 'false';
    }
    
    // Add metric filters
    if (Array.isArray(metricFilter) && metricFilter.length > 0) {
      // Pass metric_filters as a query parameter with stringified JSON
      filters.metric_filters = JSON.stringify(metricFilter.filter(filter => 
        filter && filter.metric_key && filter.operator
      ));
    }
    
    return filters;
  }, [dynamicFilters, booleanFilters, metricFilter]);
  
  // Fetch unified filter options (includes fields and metrics) - now with current filters
  const {
    unifiedFilterOptions,
    isLoading: isLoadingUnifiedFilters, 
    isError: isErrorUnifiedFilters,
  } = useFetchDealMetricFilterOptions(accountID, currentFilters);
  
  // Get current page from URL query param or default to 1
  const getInitialPage = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    return page ? parseInt(page, 10) : 1;
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(getInitialPage);

  // REPLACE useFetchDeals and useSearchDeals with the unified hook
  const { 
    deals, 
    isLoading,
    isError,
    totalCount,
    hasMore,
    refetch
  } = useFetchDealsUnified(
    accountID, 
    currentPage, 
    ITEMS_PER_PAGE,
    searchQuery,              // Pass search query directly to the hook
    currentStatusFilter,      // Pass status filter directly to the hook
    metricFilter,             // Pass metric filters directly to the hook
    dynamicFilters,           // Pass dynamic filters directly to the hook
    booleanFilters,           // Pass boolean filters directly to the hook
    sortConfig                // Pass sort config
  );

  // Helper function to update page parameter in URL
  const updatePageParam = (page) => {
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  // Helper function to determine if a deal is active (has activity within last two weeks)
  const isActive = (dateString) => {
    if (!dateString) return false;
    const lastActivityDate = new Date(dateString);
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return lastActivityDate >= twoWeeksAgo;
  };

  // Enhance deals with isActive flag
  const enhancedDeals = useMemo(() => {
    return deals.map(deal => ({
      ...deal,
      isActive: isActive(deal.last_activity_date),
    }));
  }, [deals]);

  // Generate search suggestions based on deal names mapped to deal IDs
  const searchSuggestions = useMemo(() => {
    // Filter deals based on search query before mapping to suggestions
    return deals
      .filter(deal => 
        (deal.deal_name && deal.deal_name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())) ||
        // Add owner name to suggestions
        ((`${deal.user_name || ''} ${deal.user_last_name || ''}`.trim()) && 
         `${deal.user_name || ''} ${deal.user_last_name || ''}`.trim().toLowerCase().includes(debouncedSearchQuery.toLowerCase()))
      )
      .map(deal => ({ 
        id: deal.id, 
        label: deal.deal_name + (deal.user_name || deal.user_last_name ? ` (Owner: ${`${deal.user_name || ''} ${deal.user_last_name || ''}`.trim() || 'Unassigned'})` : '')
      }));
  }, [deals, debouncedSearchQuery]);

  // Handle sorting - update to reset pagination when sorting changes
  const handleSort = (key) => {
    setSortConfig((prevState) => {
      // Create the new sort configuration
      const newSortConfig = {
        key,
        direction: prevState.key === key 
          ? (prevState.direction === "ascending" ? "descending" : "ascending")
          : "ascending",
      };

      // Reset to page 1 when sort changes
      setCurrentPage(1);
      const params = new URLSearchParams(location.search);
      params.set('page', '1');
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });

      return newSortConfig;
    });
  };

  // Helper function to convert strings to Title Case
  const titleCase = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // Update hasActiveFilters calculation
  const hasActiveFilters = useMemo(() => {
    const hasDynamic = Object.entries(dynamicFilters).some(([key, filter]) => {
      if (!filter) return false;
      // Check existing date/number/option filters
      if (filter.startDate || filter.endDate) return true;
      if (filter.min !== undefined || filter.max !== undefined) {
        // Check if number range filter is actually active (not just default min/max)
        const range = unifiedFilterOptions?.fields?.amount_range; // Use API range if available
        if (key === 'amount' && range) {
          return filter.min > range.min || filter.max < range.max;
        }
        // Add checks for other potential number ranges if needed
      }
      if (filter.values && filter.values.length > 0) return true; // For checkbox groups

      // Check for simple value filters (like boolean dropdowns if implemented differently)
      if (typeof filter === 'string' && filter !== '') return true;

      return false;
    });

    const hasBoolean = Object.values(booleanFilters).some(val => val && val !== 'any');
    const hasSearch = searchQuery.trim() !== "";
    const hasStatus = currentStatusFilter !== "all";

    return hasDynamic || hasBoolean || hasActiveMetricFilter || hasSearch || hasStatus;
  }, [dynamicFilters, booleanFilters, hasActiveMetricFilter, unifiedFilterOptions, searchQuery, currentStatusFilter]);

  // Current deals with stage colors
  const currentDeals = useMemo(() => {
    // Add stage colors to the deals - the deals are already paginated from the API
    return enhancedDeals.map(deal => ({
      ...deal,
      stageColor: stageColorMap[deal.stage_name] || "gray.400",
      priorityColor: priorityColorMap[deal.priority] || "gray.400"
    }));
  }, [enhancedDeals]);

  // Calculate total pages based on the API's reported total count
  const totalPages = useMemo(() => {
    return Math.ceil(totalCount / ITEMS_PER_PAGE) || 1;
  }, [totalCount]);

  // Handles navigating to the previous page
  const handlePreviousPage = () => {
    if (currentPage <= 1) return;
    const newPage = Math.max(currentPage - 1, 1);
    setCurrentPage(newPage);
    updatePageParam(newPage);
  };

  // Handles navigating to the next page
  const handleNextPage = () => {
    if (currentPage >= totalPages || !hasMore) return;
    const newPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(newPage);
    updatePageParam(newPage);
  };

  const handlePageSelect = (pageNum) => {
    if (pageNum < 1 || pageNum > totalPages) return;
    setCurrentPage(pageNum);
    updatePageParam(pageNum);
  };

  // Sync the URL page parameter with the current page state when component mounts
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlPage = params.get('page');
    
    if (urlPage) {
      const parsedPage = parseInt(urlPage, 10);
      if (parsedPage !== currentPage && !isNaN(parsedPage) && parsedPage >= 1) {
        setCurrentPage(parsedPage);
      }
    } else if (currentPage !== 1) {
      // If no page param in URL but we have a current page, update URL
      updatePageParam(currentPage);
    }
  }, [location.search]); // Only run when URL changes

  // Reset page when filter changes
  useEffect(() => {
    setCurrentPage(1);
    const params = new URLSearchParams(location.search);
    params.set('page', '1');
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    // Keep existing dependencies
  }, [dynamicFilters, metricFilter, debouncedSearchQuery, currentStatusFilter, sortConfig, navigate]);

  /**
   * Handles the navigation to the deal details page.
   * @param {Object} deal - The deal object.
   */
  const handleViewDetails = useCallback(
    (deal) => {
      // Store current location (including page number) when navigating to detail
      navigate(`/admin/deals/${deal.id}`, {
        state: { referrer: { pathname: location.pathname, search: location.search } }
      });
    },
    [navigate, location]
  );

  /**
   * Handles suggestion selection by navigating to the selected deal's detail view.
   * @param {number|string} dealId - The ID of the selected deal.
   */
  const handleSuggestionSelect = useCallback(
    (dealId) => {
      const selectedDeal = deals.find(deal => deal.id === dealId);
      if (selectedDeal) {
        // Store current location when navigating to detail
        navigate(`/admin/deals/${selectedDeal.id}`, {
          state: { referrer: { pathname: location.pathname, search: location.search } }
        });
      } else {
        toast({
          title: 'Deal not found.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [navigate, deals, toast, location]
  );

  const handleSearchChange = (newQuery) => {
    setSearchQuery(newQuery);
  };

  // Modified to handle integrated filter approach
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  // Add new state for loading sections below the expandedSections state
  const [expandedSections, setExpandedSections] = useState({});

  // NEW: State to track loading status for each filter section
  const [loadingSections, setLoadingSections] = useState({});

  // Update the toggleSection function to handle dynamic sections with loader
  const toggleSection = (sectionName) => {
    setExpandedSections(prev => {
      const newExpanded = { ...prev, [sectionName]: !prev[sectionName] };
      if (newExpanded[sectionName]) {
        // Section is being expanded
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: true }));
        setTimeout(() => {
          setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
        }, 300);
      } else {
        // If collapsing, reset loading status immediately
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
      }
      return newExpanded;
    });
  };

  // Add this new handler for dynamic filters
  const handleDynamicFilterChange = (column, value) => {
    setDynamicFilters(prev => ({
      ...prev,
      [column]: value
    }));
  };

  // Update handleClearMetricFilter to ensure it doesn't conflict with handleResetFilters
  const handleClearMetricFilter = useCallback(() => {
    setMetricFilter([]); // Clear the applied metric filters

    // Reset the UI values in the metric filter section if metricFilterValues state exists
    if (unifiedFilterOptions?.metrics && metricFilterValues) {
      const initialValues = {};
       unifiedFilterOptions.metrics.metric_keys?.forEach(key => {
         const range = unifiedFilterOptions.metrics.metric_ranges?.[key];
         if (range) {
          initialValues[key] = {
              value: { min: range.min, max: range.max },
              count: { min: 0, max: range.max_count_per_deal || 10 },
              ...(range.max_unique_per_deal > 0 && { unique: { min: 0, max: range.max_unique_per_deal } })
            };
        }
      });
      setMetricFilterValues(initialValues);
    }
    
    // No toast here, let handleResetFilters show the main toast
  }, [setMetricFilter, setMetricFilterValues, unifiedFilterOptions, metricFilterValues]);

  // Transform metricFilterValues into proper metricFilter format for API - Update to use unified options
  const generateMetricFilters = useCallback(() => {
    if (!metricFilterValues || !unifiedFilterOptions?.metrics) return [];
    
    const filters = [];
    const metricsData = unifiedFilterOptions.metrics;
    Object.entries(metricFilterValues).forEach(([metricKey, values]) => {
      const range = metricsData.metric_ranges?.[metricKey];
      if (!range) return;
      
      // Check if value filter is active
      if (values.value && range.min !== undefined && range.max !== undefined && (values.value.min > range.min || values.value.max < range.max)) {
        if (values.value.min > range.min) {
          filters.push({ metric_key: metricKey, operator: ">=", value: values.value.min });
        }
        if (values.value.max < range.max) {
          filters.push({ metric_key: metricKey, operator: "<=", value: values.value.max });
        }
      }
      
      // Check if count filter is active
      const maxCount = range.max_count_per_deal ?? 10; // Use default if not provided
      if (values.count && (values.count.min > 0 || values.count.max < maxCount)) {
          if (values.count.min > 0) {
              filters.push({ metric_key: metricKey, operator: "count>=", value: values.count.min });
          }
          if (values.count.max < maxCount) {
              filters.push({ metric_key: metricKey, operator: "count<=", value: values.count.max });
          }
      }
      
      // Check if unique count filter is active
      const maxUnique = range.max_unique_per_deal;
      if (values.unique && maxUnique && maxUnique > 0 && (values.unique.min > 0 || values.unique.max < maxUnique)) {
        if (values.unique.min > 0) {
               filters.push({ metric_key: metricKey, operator: "unique_count_greater_than_or_equals", value: values.unique.min });
           }
           if (values.unique.max < maxUnique) {
               filters.push({ metric_key: metricKey, operator: "unique_count_less_than_or_equals", value: values.unique.max });
        }
      }
    });
    
    return filters;
  }, [metricFilterValues, unifiedFilterOptions]);

  // Update handleApplyFilters to apply all filters at once
  const handleApplyFilters = () => {
    // 1. Apply Metric Filters (generate and set them)
    const metricFiltersToApply = generateMetricFilters();
    setMetricFilter(metricFiltersToApply);

    // 2. Reset to page 1
    setCurrentPage(1);
    const params = new URLSearchParams(location.search);
    params.set('page', '1');
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    
    // 3. Close popover and show toast
    setIsFilterOpen(false);
    
    // 4. Force a refetch to apply all filters server-side
    refetch();
    
    // 5. Calculate number of active filters for the toast
    const filterCount = metricFiltersToApply.length +
                        Object.values(dynamicFilters).filter(f => f?.values?.length > 0 || f?.min !== undefined || f?.max !== undefined || (f?.startDate && f?.endDate)).length +
                        Object.values(booleanFilters).filter(v => v && v !== 'any').length;

    if (filterCount > 0) {
      toast({
        title: `${filterCount} Filter${filterCount !== 1 ? 's' : ''} Applied`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Filters Cleared",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Add state for export contacts modal
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [contactColumns, setContactColumns] = useState({});
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [exportFormat, setExportFormat] = useState("csv");
  const [isLoadingColumns, setIsLoadingColumns] = useState(false);
  
  // Fetch contact export fields using the new hook
  const { 
    data: exportFields, 
    isLoading: isLoadingExportFields,
    isError: isExportFieldsError,
    exportContacts
  } = useFetchContactExportFields(accountID);
  
  // Process contact fields for export
  const fetchContactColumns = useCallback(() => {
    if (exportFields) {
      // Process the export fields data for display
      const fieldCategories = {
        contact_fields: {
          title: "Contact Fields",
          items: exportFields.contact_fields || []
        },
        company_fields: {
          title: "Company Fields",
          items: exportFields.company_fields || []
        },
        activity_fields: {
          title: "Activity Fields",
          items: exportFields.activity_fields || []
        },
        deal_fields: {
          title: "Deal Fields",
          items: exportFields.deal_fields || []
        },
        date_fields: {
          title: "Date Fields",
          items: exportFields.date_fields || []
        }
      };
      
      setContactColumns(fieldCategories);
      
      // Set default selected columns - contact name, email, and phone
      const defaultKeys = ['name', 'email', 'phone'];
      const defaultColumns = defaultKeys.filter(key => 
        exportFields.contact_fields?.some(field => field.key === key)
      );
      
      setSelectedColumns(defaultColumns);
      setIsLoadingColumns(false);
    }
  }, [exportFields]);
  
  // Handle opening the export modal
  const handleOpenExportModal = useCallback(() => {
    setIsExportModalOpen(true);
    setIsLoadingColumns(true);
    fetchContactColumns();
  }, [fetchContactColumns]);
  
  // Handle exporting contacts
  const handleExportContacts = useCallback(() => {
    // Get deal IDs from deals already filtered by the server
    const dealIds = deals.map(deal => deal.id);
    
    // Start export process
    setIsLoadingExport(true);
    
    // Call the API to export contacts
    exportContacts(selectedColumns, dealIds, exportFormat.toLowerCase())
      .then(response => {        
        toast({
          title: "Export initiated",
          description: `Exporting ${deals.length} contacts as ${exportFormat.toUpperCase()}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        
        // Handle the response based on what we received
        // The response itself could be the file data
        if (response instanceof Blob) {
          // If the response is already a Blob, use it directly
          downloadFile(response);
        } else if (response && response.download_url) {
          // If there's a download URL in the response, trigger a file download by fetching that URL
          fetch(response.download_url, { credentials: 'include' })
            .then(res => res.blob())
            .then(blob => {
              downloadFile(blob);
            })
            .catch(error => {
              handleDownloadError(error);
            });
        } else {
          // Response might be the raw file data as an ArrayBuffer, string, etc.
          // Convert it to a blob with appropriate mime type
          const mimeType = getMimeType(exportFormat);
          const blob = new Blob([response], { type: mimeType });
          downloadFile(blob);
        }
        
        setIsExportModalOpen(false);
      })
      .catch(error => {
        console.error("Export error:", error);
        
        toast({
          title: "Export failed",
          description: "There was a problem exporting contacts. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingExport(false);
      });
  }, [selectedColumns, exportFormat, deals, exportContacts, toast]);
  
  // Helper function to determine MIME type based on export format
  const getMimeType = (format) => {
    switch (format.toLowerCase()) {
      case 'csv':
        return 'text/csv';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        return 'application/octet-stream';
    }
  };
  
  // Helper function to download a blob as a file
  const downloadFile = (blob) => {
    // Get the file extension based on the export format
    const ext = exportFormat.toLowerCase() === 'csv' ? 'csv' : 'xlsx';
    const fileName = `contacts_export.${ext}`;
    
    // Create blob URL and trigger download
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    link.style.display = 'none';
    document.body.appendChild(link);
    
    // Force the download by clicking the link
    link.click();
    
    // Clean up
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }, 100);
  };
  
  // Helper function to handle download errors
  const handleDownloadError = (error) => {
    console.error("Error downloading file:", error);
    toast({
      title: "Download failed",
      description: "There was a problem downloading the file. Please try again.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  
  // Toggle column selection
  const toggleColumnSelection = useCallback((columnKey) => {
    setSelectedColumns(prev => {
      if (prev.includes(columnKey)) {
        return prev.filter(key => key !== columnKey);
      } else {
        return [...prev, columnKey];
      }
    });
  }, []);
  
  // Function to select all columns
  const selectAllColumns = useCallback(() => {
    if (!exportFields) return;
    
    // Get all column keys from all categories
    const allKeys = [
      ...(exportFields.contact_fields || []),
      ...(exportFields.company_fields || []),
      ...(exportFields.activity_fields || []),
      ...(exportFields.deal_fields || []),
      ...(exportFields.date_fields || [])
    ].map(field => field.key);
    
    setSelectedColumns(allKeys);
  }, [exportFields]);
  
  // Function to unselect all columns
  const unselectAllColumns = useCallback(() => {
    setSelectedColumns([]);
  }, []);

  // Add state for export loading
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  // Initialize expandedSections based on unifiedFilterOptions
  useEffect(() => {
    if (unifiedFilterOptions) {
      const initialSections = {};
      // Add field options
      Object.keys(unifiedFilterOptions.fields || {}).forEach(key => {
        if (key.endsWith('_options') || key.endsWith('_range') || key === 'date_ranges' || key === 'boolean_fields') {
          initialSections[key] = false; // Initialize all sections as collapsed
          // Special handling for date_ranges object
          if (key === 'date_ranges') {
             Object.keys(unifiedFilterOptions.fields.date_ranges || {}).forEach(dateKey => {
               initialSections[dateKey] = false;
             });
          }
          // Special handling for boolean fields
          if (key === 'boolean_fields') {
            (unifiedFilterOptions.fields.boolean_fields || []).forEach(boolKey => {
               initialSections[boolKey] = false;
            });
          }
        }
      });
      // Add metric keys
      (unifiedFilterOptions.metrics?.metric_keys || []).forEach(key => {
        initialSections[key] = false;
      });
      setExpandedSections(initialSections);
    }
  }, [unifiedFilterOptions]); // Re-run when options load

  // Sync metricFilterValues with persistent metricFilter when options load or component mounts
  useEffect(() => {
    if (unifiedFilterOptions?.metrics && Array.isArray(metricFilter) && metricFilter.length > 0) {
      // Initialize metric filter values
      const updatedValues = { ...metricFilterValues };
      
      // Apply each persisted metric filter to the UI values
      metricFilter.forEach(filter => {
        const { metric_key, operator, value } = filter;
        const range = unifiedFilterOptions.metrics.metric_ranges?.[metric_key];
        
        if (!range) return;
        
        // Make sure the metric key exists in the values object
        if (!updatedValues[metric_key]) {
          updatedValues[metric_key] = {
            value: { min: range.min, max: range.max },
            count: { min: 0, max: range.max_count_per_deal || 10 },
            ...(range.max_unique_per_deal > 0 && { unique: { min: 0, max: range.max_unique_per_deal } })
          };
        }
        
        // Update the appropriate property based on the operator
        if (operator === ">=" || operator === "greater_than_or_equals") {
          updatedValues[metric_key].value.min = value;
        } else if (operator === "<=" || operator === "less_than_or_equals") {
          updatedValues[metric_key].value.max = value;
        } else if (operator === "count>=" || operator === "count_greater_than_or_equals") {
          updatedValues[metric_key].count.min = value;
        } else if (operator === "count<=" || operator === "count_less_than_or_equals") {
          updatedValues[metric_key].count.max = value;
        } else if (operator === "unique_count_greater_than_or_equals") {
          updatedValues[metric_key].unique.min = value;
        } else if (operator === "unique_count_less_than_or_equals") {
          updatedValues[metric_key].unique.max = value;
        }
      });
      
      // Update the state with the reconstructed values
      setMetricFilterValues(updatedValues);
    }
  }, [unifiedFilterOptions, metricFilter]);

  // Filter reset handler
  const handleResetFilters = () => {
    setDynamicFilters({});
    setBooleanFilters({}); // Reset boolean filters
    // Reset metric filters (existing logic)
    handleClearMetricFilter();
  };

  return (
    <Box px={4}>
      <PageHeader
        title="Deals"
        searchPlaceholder="Search deals..."
        onSearchChange={handleSearchChange}
        searchSuggestions={searchSuggestions}
        searchQuery={searchQuery}
        showSearch={true}
        onSuggestionSelect={handleSuggestionSelect}
        filteredCount={deals.length}
        hasActiveFilters={hasActiveFilters}
        additionalControls={
          <HStack spacing={2}>
            <Popover 
              placement="bottom-end" 
              isOpen={isFilterOpen}
              onClose={() => setIsFilterOpen(false)}
            >
              <PopoverTrigger>
                <Button
                  leftIcon={<FilterIcon />}
                  rightIcon={<Icon as={isFilterOpen ? ChevronUp : ChevronDown} boxSize={5} />}
                  variant="outline"
                  bg={(hasActiveFilters) ? selectedBg : "white"}
                  color={(hasActiveFilters) ? selectedTextColor : buttonTextColor}
                  borderRadius="md"
                  borderColor="gray.300"
                  _hover={{
                    bg: (hasActiveFilters) ? selectedBg : "gray.50",
                  }}
                  onClick={() => setIsFilterOpen(true)}
                >
                  Filter Deals {
                    (hasActiveFilters) &&
                    `(${
                      (Array.isArray(metricFilter) ? metricFilter.filter(f => f && f.metric_key).length : (metricFilter && metricFilter.metric_key ? 1 : 0)) +
                      Object.values(dynamicFilters).filter(f => f?.values?.length > 0 || f?.min !== undefined || f?.max !== undefined || (f?.startDate && f?.endDate)).length +
                      Object.values(booleanFilters).filter(v => v && v !== 'any').length
                    })`
                  }
                </Button>
              </PopoverTrigger>
              
              <PopoverContent 
                width="400px" 
                maxH="calc(100vh - var(--chakra-space-48))"
                position="relative"
                boxShadow="lg"
                mt={2}
                display="flex"
                flexDirection="column"
              >
                <PopoverHeader 
                  fontWeight="semibold" 
                  position="sticky"
                  top={0}
                  bg="white"
                  zIndex={1}
                  borderBottomWidth="1px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <HStack>
                   {(isLoading || isLoadingUnifiedFilters) && <Spinner size="sm" mr={2} />}
                  <Text fontSize="sm" color="gray.500">
                      {deals.length} of {totalCount} deals
                  </Text>
                  </HStack>
                  <Button 
                    size="sm" 
                    leftIcon={<Icon as={FiDownload} />} 
                    colorScheme="blue" 
                    variant="outline"
                    borderRadius="md"
                    borderColor="gray.300"
                    _hover={{ bg: "gray.50" }}
                    onClick={handleOpenExportModal}
                    isDisabled={deals.length === 0}
                  >
                    Export Contacts
                  </Button>
                </PopoverHeader>
                <PopoverBody 
                  flex="1" 
                  overflowY="auto"
                  py={4}
                >
                  {isLoadingUnifiedFilters ? (
                    <Center p={10}><Spinner /></Center>
                  ) : isErrorUnifiedFilters ? (
                    <Alert status="error">
                       <AlertIcon />
                       Error loading filter options.
                    </Alert>
                  ) : (
                  <VStack spacing={4} align="stretch">
                    {/* Date Filters - Use API date_ranges */}
                    {unifiedFilterOptions?.fields?.date_ranges && Object.keys(unifiedFilterOptions.fields.date_ranges).length > 0 && (
                      <Box borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
                        <FormControl>
                        <FormLabel fontWeight="medium">Filter Date By</FormLabel>
                          {Object.entries(unifiedFilterOptions.fields.date_ranges).map(([key]) => (
                             <Box key={key} mb={4}>
                               <FormLabel fontSize="sm" fontWeight="normal" mb={1}>{titleCase(key.replace(/_/g, " "))}</FormLabel>
                      <Stack direction="row">
                        <Input
                          type="date"
                                   name={`${key}-start`}
                                   value={dynamicFilters[key]?.startDate || ''}
                                   onChange={(e) => handleDynamicFilterChange(key, {
                                     ...dynamicFilters[key],
                                    startDate: e.target.value
                                  })}
                          bg="white"
                                   size="sm"
                                   max={dynamicFilters[key]?.endDate} // Prevent start date being after end date
                        />
                        <Input
                          type="date"
                                   name={`${key}-end`}
                                   value={dynamicFilters[key]?.endDate || ''}
                                   onChange={(e) => handleDynamicFilterChange(key, {
                                     ...dynamicFilters[key],
                                    endDate: e.target.value
                                  })}
                          bg="white"
                                   size="sm"
                                   min={dynamicFilters[key]?.startDate} // Prevent end date being before start date
                        />
                      </Stack>
                             </Box>
                        ))}
                        </FormControl>
                      </Box>
                    )}

                    {/* Metric Filters Section (Ensure correct structure) */}
                    {unifiedFilterOptions?.metrics?.metric_keys?.length > 0 && (
                      <Box borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
                         <FormControl>
                           <FormLabel fontWeight="medium" mb={4}>Metric Filters</FormLabel>
                          <VStack align="stretch" spacing={4}>
                             {unifiedFilterOptions.metrics.metric_keys.map(metricKey => {
                               const range = unifiedFilterOptions.metrics.metric_ranges?.[metricKey];
                              if (!range) return null;
                              
                              const metricLabel = metricKey.split('.').map(part => 
                                part.charAt(0).toUpperCase() + part.slice(1)
                              ).join(' ');
                              
                               // Determine if this specific metric filter is active
                                const isMetricActive = (
                                   (metricFilterValues[metricKey]?.value && range.min !== undefined && (metricFilterValues[metricKey].value.min > range.min || metricFilterValues[metricKey].value.max < range.max)) ||
                                   (metricFilterValues[metricKey]?.count && (metricFilterValues[metricKey].count.min > 0 || metricFilterValues[metricKey].count.max < (range.max_count_per_deal ?? 10))) ||
                                   (metricFilterValues[metricKey]?.unique && range.max_unique_per_deal && (metricFilterValues[metricKey].unique.min > 0 || metricFilterValues[metricKey].unique.max < range.max_unique_per_deal))
                                 );

                              return (
                                <Box key={metricKey}>
                                  <Button
                                    variant="unstyled"
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    onClick={() => toggleSection(metricKey)}
                                    mb={2}
                                    _hover={{ bg: "gray.100" }}
                                    p={2}
                                    borderRadius="md"
                                  >
                                    <FormLabel fontWeight="medium" mb={0}>
                                      {metricLabel}
                                       {isMetricActive ? (
                                         <Text as="span" ml={2} color="blue.500" fontSize="xs" fontWeight="bold">
                                           (Active)
                                        </Text>
                                      ) : null}
                                    </FormLabel>
                                    {expandedSections[metricKey] ? <ChevronUpIcon boxSize={5} /> : <ChevronDownIcon boxSize={5} />}
                                  </Button>
                                  
                                  {expandedSections[metricKey] && (
                                     loadingSections[metricKey] ? (
                                       <Center p={4}><Spinner size="sm" /></Center>
                                     ) : (
                                    <Box p={4} borderWidth="1px" borderRadius="md" bg="white">
                                         {/* Value Range Slider */}
                                         {range.min !== undefined && range.max !== undefined && range.min !== range.max && (
                                        <Box mb={4}>
                                          <Text fontSize="sm" mb={1}>Value Range</Text>
                                          <RangeSlider
                                            min={range.min}
                                            max={range.max}
                                               step={Math.max(0.01, (range.max - range.min) / 100)}
                                            value={[
                                                 metricFilterValues[metricKey]?.value?.min ?? range.min,
                                                 metricFilterValues[metricKey]?.value?.max ?? range.max
                                            ]}
                                            onChange={(values) => {
                                              setMetricFilterValues(prev => ({
                                                ...prev,
                                                   [metricKey]: { ...prev[metricKey], value: { min: values[0], max: values[1] } }
                                              }));
                                            }}
                                          >
                                               <RangeSliderTrack><RangeSliderFilledTrack /></RangeSliderTrack>
                                            <RangeSliderThumb index={0} />
                                            <RangeSliderThumb index={1} />
                                          </RangeSlider>
                                          <HStack justify="space-between" mt={2}>
                                                <Input type="number" size="sm" width="80px" textAlign="center" bg="white"
                                                   value={metricFilterValues[metricKey]?.value?.min ?? range.min}
                                                   onChange={(e) => setMetricFilterValues(prev => ({...prev, [metricKey]: {...prev[metricKey], value: {...(prev[metricKey]?.value || {}), min: parseFloat(e.target.value) || range.min }}}))}
                                                   min={range.min} max={metricFilterValues[metricKey]?.value?.max ?? range.max} step={Math.max(0.01, (range.max - range.min) / 100)}
                                                />
                                                <Input type="number" size="sm" width="80px" textAlign="center" bg="white"
                                                   value={metricFilterValues[metricKey]?.value?.max ?? range.max}
                                                   onChange={(e) => setMetricFilterValues(prev => ({...prev, [metricKey]: {...prev[metricKey], value: {...(prev[metricKey]?.value || {}), max: parseFloat(e.target.value) || range.max }}}))}
                                                    min={metricFilterValues[metricKey]?.value?.min ?? range.min} max={range.max} step={Math.max(0.01, (range.max - range.min) / 100)}
                                            />
                                          </HStack>
                                        </Box>
                                      )}

                                         {/* Count Per Deal Slider */}
                                         {range.max_count_per_deal > 0 && (
                                           <Box mb={4}>
                                            <Text fontSize="sm" mb={1}>Count Per Deal</Text>
                                          <RangeSlider
                                            min={0}
                                            max={range.max_count_per_deal}
                                            step={1}
                                            value={[
                                                 metricFilterValues[metricKey]?.count?.min ?? 0,
                                                 metricFilterValues[metricKey]?.count?.max ?? range.max_count_per_deal
                                            ]}
                                            onChange={(values) => {
                                              setMetricFilterValues(prev => ({
                                                ...prev,
                                                   [metricKey]: { ...prev[metricKey], count: { min: values[0], max: values[1] } }
                                              }));
                                            }}
                                          >
                                               <RangeSliderTrack><RangeSliderFilledTrack /></RangeSliderTrack>
                                            <RangeSliderThumb index={0} />
                                            <RangeSliderThumb index={1} />
                                          </RangeSlider>
                                          <HStack justify="space-between" mt={2}>
                                                <Input type="number" size="sm" width="80px" textAlign="center" bg="white"
                                                   value={metricFilterValues[metricKey]?.count?.min ?? 0}
                                                   onChange={(e) => setMetricFilterValues(prev => ({...prev, [metricKey]: {...prev[metricKey], count: {...(prev[metricKey]?.count || {}), min: parseInt(e.target.value) || 0 }}}))}
                                                    min={0} max={metricFilterValues[metricKey]?.count?.max ?? range.max_count_per_deal} step={1}
                                                 />
                                                 <Input type="number" size="sm" width="80px" textAlign="center" bg="white"
                                                   value={metricFilterValues[metricKey]?.count?.max ?? range.max_count_per_deal}
                                                    onChange={(e) => setMetricFilterValues(prev => ({...prev, [metricKey]: {...prev[metricKey], count: {...(prev[metricKey]?.count || {}), max: parseInt(e.target.value) || range.max_count_per_deal }}}))}
                                                    min={metricFilterValues[metricKey]?.count?.min ?? 0} max={range.max_count_per_deal} step={1}
                                            />
                                          </HStack>
                                        </Box>
                                      )}
                                      
                                         {/* Unique Count Slider */}
                                      {range.max_unique_per_deal > 0 && (
                                           <Box>
                                          <Text fontSize="sm" mb={1}>Unique Count</Text>
                                          <RangeSlider
                                            min={0}
                                            max={range.max_unique_per_deal}
                                            step={1}
                                            value={[
                                                 metricFilterValues[metricKey]?.unique?.min ?? 0,
                                                 metricFilterValues[metricKey]?.unique?.max ?? range.max_unique_per_deal
                                            ]}
                                            onChange={(values) => {
                                              setMetricFilterValues(prev => ({
                                                ...prev,
                                                   [metricKey]: { ...prev[metricKey], unique: { min: values[0], max: values[1] } }
                                              }));
                                            }}
                                          >
                                               <RangeSliderTrack><RangeSliderFilledTrack /></RangeSliderTrack>
                                            <RangeSliderThumb index={0} />
                                            <RangeSliderThumb index={1} />
                                          </RangeSlider>
                                          <HStack justify="space-between" mt={2}>
                                                 <Input type="number" size="sm" width="80px" textAlign="center" bg="white"
                                                    value={metricFilterValues[metricKey]?.unique?.min ?? 0}
                                                    onChange={(e) => setMetricFilterValues(prev => ({...prev, [metricKey]: {...prev[metricKey], unique: {...(prev[metricKey]?.unique || {}), min: parseInt(e.target.value) || 0 }}}))}
                                                    min={0} max={metricFilterValues[metricKey]?.unique?.max ?? range.max_unique_per_deal} step={1}
                                                  />
                                                  <Input type="number" size="sm" width="80px" textAlign="center" bg="white"
                                                    value={metricFilterValues[metricKey]?.unique?.max ?? range.max_unique_per_deal}
                                                    onChange={(e) => setMetricFilterValues(prev => ({...prev, [metricKey]: {...prev[metricKey], unique: {...(prev[metricKey]?.unique || {}), max: parseInt(e.target.value) || range.max_unique_per_deal }}}))}
                                                    min={metricFilterValues[metricKey]?.unique?.min ?? 0} max={range.max_unique_per_deal} step={1}
                                            />
                                          </HStack>
                                        </Box>
                                      )}

                                    </Box>
                                     )
                                  )}
                                </Box>
                              );
                            })}
                          </VStack>
                        </FormControl>
                      </Box>
                    )}

                    {/* NEW: Option Filters from API */}
                    {['pipeline_options', 'stage_options', 'user_options', 'lead_source_options', 'priority_options'].map(optionKey => {
                      const options = unifiedFilterOptions?.fields?.[optionKey];
                      const filterLabel = titleCase(optionKey.replace(/_options/g, "").replace(/_/g, " "));
                      const validOptions = (options || []).filter(item => ((item.count || 0) > 0));
                      if (validOptions.length <= 1) return null;

                      return (
                        <FormControl key={optionKey}>
                          <Button
                            variant="unstyled"
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            onClick={() => toggleSection(optionKey)}
                            mb={2}
                            _hover={{ bg: "gray.50" }}
                            p={2}
                            borderRadius="md"
                          >
                            <FormLabel fontWeight="medium" mb={0}>
                              {filterLabel}
                              {dynamicFilters[optionKey]?.values?.length > 0 && (
                                <Text as="span" ml={2} color="gray.500" fontSize="sm">
                                  ({dynamicFilters[optionKey].values.length})
                                </Text>
                              )}
                            </FormLabel>
                            {expandedSections[optionKey] ? <ChevronUpIcon boxSize={5} /> : <ChevronDownIcon boxSize={5} />}
                          </Button>
                          {expandedSections[optionKey] && (
                            loadingSections[optionKey] ? (
                              <Center p={4}><Spinner size="sm" /></Center>
                            ) : (
                              <Box
                                maxH="150px"
                                overflowY="auto"
                                borderWidth="1px"
                                borderColor="gray.200"
                                borderRadius="md"
                                bg="white"
                                _hover={{ borderColor: "gray.300" }}
                              >
                                <CheckboxGroup
                                  value={dynamicFilters[optionKey]?.values || []}
                                  onChange={(values) => handleDynamicFilterChange(optionKey, { values })}
                                >
                                  <Stack spacing={1} p={3}>
                                    {validOptions.map((item) => (
                                      <Checkbox key={item.value} value={item.value} size="sm">
                                        {item.label} {item.count != null && `(${item.count})`}
                                      </Checkbox>
                                    ))}
                                  </Stack>
                                </CheckboxGroup>
                              </Box>
                            )
                          )}
                        </FormControl>
                      );
                    })}

                    {/* NEW: Amount Filter */}
                    {unifiedFilterOptions?.fields?.amount_range && unifiedFilterOptions.fields.amount_range.max > unifiedFilterOptions.fields.amount_range.min && (
                       <FormControl key="amount">
                      <Button
                        variant="unstyled"
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                           onClick={() => toggleSection('amount')}
                        mb={2}
                        _hover={{ bg: "gray.50" }}
                        p={2}
                        borderRadius="md"
                      >
                        <FormLabel fontWeight="medium" mb={0}>
                             Amount
                             {(dynamicFilters['amount']?.min > unifiedFilterOptions.fields.amount_range.min || dynamicFilters['amount']?.max < unifiedFilterOptions.fields.amount_range.max) && (
                            <Text as="span" ml={2} color="gray.500" fontSize="sm">
                                 ({dynamicFilters['amount']?.min ?? unifiedFilterOptions.fields.amount_range.min} - {dynamicFilters['amount']?.max ?? unifiedFilterOptions.fields.amount_range.max})
                            </Text>
                          )}
                        </FormLabel>
                           {expandedSections['amount'] ? <ChevronUpIcon boxSize={5} /> : <ChevronDownIcon boxSize={5} />}
                      </Button>
                         {expandedSections['amount'] && (
                           loadingSections['amount'] ? (
                             <Center p={4}><Spinner size="sm" /></Center>
                           ) : (
                             <Box p={4} borderWidth="1px" borderRadius="md" bg="white">
                               <RangeSlider
                                 min={unifiedFilterOptions.fields.amount_range.min}
                                 max={unifiedFilterOptions.fields.amount_range.max}
                                 step={Math.max(1, Math.round((unifiedFilterOptions.fields.amount_range.max - unifiedFilterOptions.fields.amount_range.min) / 100))}
                                 value={[
                                   dynamicFilters['amount']?.min ?? unifiedFilterOptions.fields.amount_range.min,
                                   dynamicFilters['amount']?.max ?? unifiedFilterOptions.fields.amount_range.max
                                 ]}
                                 onChange={([min, max]) => handleDynamicFilterChange('amount', { min, max })}
                               >
                                 <RangeSliderTrack><RangeSliderFilledTrack /></RangeSliderTrack>
                                 <RangeSliderThumb index={0} />
                                 <RangeSliderThumb index={1} />
                               </RangeSlider>
                               <HStack justify="space-between" mt={2}>
                                 <Input
                                   type="number"
                                   size="sm" width="90px" textAlign="center" bg="white"
                                   value={dynamicFilters['amount']?.min ?? unifiedFilterOptions.fields.amount_range.min}
                                   onChange={(e) => handleDynamicFilterChange('amount', { ...dynamicFilters['amount'], min: parseFloat(e.target.value) || unifiedFilterOptions.fields.amount_range.min })}
                                   min={unifiedFilterOptions.fields.amount_range.min}
                                   max={dynamicFilters['amount']?.max ?? unifiedFilterOptions.fields.amount_range.max}
                                 />
                                 <Input
                                   type="number"
                                   size="sm" width="90px" textAlign="center" bg="white"
                                   value={dynamicFilters['amount']?.max ?? unifiedFilterOptions.fields.amount_range.max}
                                   onChange={(e) => handleDynamicFilterChange('amount', { ...dynamicFilters['amount'], max: parseFloat(e.target.value) || unifiedFilterOptions.fields.amount_range.max })}
                                   min={dynamicFilters['amount']?.min ?? unifiedFilterOptions.fields.amount_range.min}
                                   max={unifiedFilterOptions.fields.amount_range.max}
                                 />
                               </HStack>
                              </Box>
                            )
                          )}
                    </FormControl>
                    )}

                    {/* NEW: Boolean Filters */}
                    {unifiedFilterOptions?.fields?.boolean_fields?.length > 0 && (
                      <Box borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
                         <FormLabel fontWeight="medium" mb={2}>Status Flags</FormLabel>
                         <VStack spacing={3} align="stretch">
                           {unifiedFilterOptions.fields.boolean_fields.map(fieldKey => (
                             <FormControl key={fieldKey}>
                               <FormLabel fontSize="sm" fontWeight="normal">{titleCase(fieldKey.replace(/_/g, " "))}</FormLabel>
                               <RadioGroup
                                 value={booleanFilters[fieldKey] || 'any'}
                                 onChange={(value) => setBooleanFilters(prev => ({ ...prev, [fieldKey]: value }))}
                                 size="sm"
                               >
                                 <Stack direction="row" spacing={4}>
                                   <Radio value="any">Any</Radio>
                                   <Radio value="yes">Yes</Radio>
                                   <Radio value="no">No</Radio>
                                 </Stack>
                               </RadioGroup>
                             </FormControl>
                           ))}
                  </VStack>
                      </Box>
                    )}

                  </VStack>
                  )}
                </PopoverBody>
                <PopoverFooter
                  position="sticky"
                  bottom={0}
                  bg="white"
                  zIndex={1}
                  borderTopWidth="1px"
                  py={3}
                >
                  <HStack justify="space-between" spacing={2}>
                    <Button size="sm" variant="ghost" onClick={handleResetFilters}>Reset All</Button>
                    <HStack>
                      <Button size="sm" variant="outline" onClick={() => setIsFilterOpen(false)}>Cancel</Button>
                      <Button 
                        size="sm" 
                        colorScheme="blue" 
                        onClick={handleApplyFilters}
                      >
                        Apply
                      </Button>
                    </HStack>
                  </HStack>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </HStack>
        }
        searchRightElement={
          <Box>
            <Button
              leftIcon={<PlusIcon />}
              colorScheme="blue"
              borderRadius="md"
              onClick={() => setIsCreateModalOpen(true)}
            >
              Create Deal
            </Button>
          </Box>
        }
      />

      {/* Create Deal Modal */}
      <CreateDealModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        existingDeals={deals}
      />
      
      {/* Deals Table */}
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
          {isLoading ? (
            <TableSkeleton rowCount={10} />
          ) : isError ? (
            <Box py={8}>
              <Alert status="error">
                <AlertIcon />
                <Box>
                  <Text fontWeight="bold">Error loading deals</Text>
                  <Text>There was a problem fetching your deals. Please try adjusting your filters or try again later.</Text>
                </Box>
              </Alert>
            </Box>
          ) : currentDeals.length === 0 ? (
            <Box py={8}>
              <Alert status="info">
                <AlertIcon />
                <Box>
                  <Text fontWeight="bold">No matching deals</Text>
                  <Text>
                    No {currentStatusFilter === 'all' ? '' : currentStatusFilter} deals match your current filters.
                    {(hasActiveFilters) && ' Try adjusting or removing some filters.'}
                  </Text>
                </Box>
              </Alert>
            </Box>
          ) : (
            <DealsTable
              deals={currentDeals}
              onViewDetails={handleViewDetails}
              onSort={handleSort}
              sortConfig={sortConfig}
              searchQuery={debouncedSearchQuery}
            />
          )}
        </Box>
      </SimpleGrid>

      {/* Pagination Controls - Only show when there are deals */}
      {!isLoading && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
          disableNext={currentPage >= totalPages || !hasMore}
          disablePrevious={currentPage === 1}
          onPageSelect={handlePageSelect}
        />
      )}

      {/* Export Contacts Modal - Update Filter Summary */}
      <Modal isOpen={isExportModalOpen} onClose={() => setIsExportModalOpen(false)} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Contacts</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {/* Section 1: Filter Summary */}
            <Box mb={4} p={3} bg="gray.50" borderRadius="md">
              <Heading size="sm" mb={2}>Export Summary</Heading>
              <Text>Exporting contacts from {deals.length} deals</Text>
              {hasActiveFilters && (
                <VStack align="flex-start" spacing={1} mt={2}>
                  <Text fontSize="sm" fontWeight="medium" color="gray.700">
                    Applied filters:
                  </Text>
                  {currentStatusFilter !== "all" && (
                    <Text fontSize="sm" ml={2} color="gray.600">
                      • Status: {currentStatusFilter === "active" ? "Active deals" : "Inactive deals"}
                    </Text>
                  )}
                  {debouncedSearchQuery && (
                    <Text fontSize="sm" ml={2} color="gray.600">
                      • Search: &ldquo;{debouncedSearchQuery}&rdquo;
                    </Text>
                  )}

                  {/* Dynamic Filters Display */}
                  {Object.entries(dynamicFilters).map(([key, filter]) => {
                    if (!filter) return null;
                    
                    const label = key;
                    let filterDetail = "";

                    // Find the type based on unified options structure
                    const fieldOptions = unifiedFilterOptions?.fields;
                    let filterType = 'unknown';
                    if (fieldOptions?.date_ranges?.[key]) filterType = 'date';
                    else if (key === 'amount_range') filterType = 'number';
                    else if (key.endsWith('_options')) filterType = 'option';
                    else if (fieldOptions?.boolean_fields?.includes(key)) filterType = 'boolean';

                    if (filterType === 'date' && filter.startDate && filter.endDate) {
                      filterDetail = `${new Date(filter.startDate).toLocaleDateString()} to ${new Date(filter.endDate).toLocaleDateString()}`;
                    } else if (filterType === 'number' && (filter.min !== undefined || filter.max !== undefined)) {
                        const range = fieldOptions?.amount_range;
                        const minVal = filter.min ?? range?.min;
                        const maxVal = filter.max ?? range?.max;
                        filterDetail = `${minVal} to ${maxVal}`;
                    } else if (filterType === 'option' && filter.values?.length > 0) {
                        const optionsList = fieldOptions?.[key] || [];
                        // Try to show labels instead of just values/IDs
                        const selectedLabels = filter.values.map(val => {
                            const option = optionsList.find(o => String(o.value) === String(val));
                            return option?.label || val; // Fallback to value if label not found
                        });

                        if (selectedLabels.length === 1) filterDetail = selectedLabels[0];
                        else if (selectedLabels.length <= 3) filterDetail = selectedLabels.join(', ');
                        else filterDetail = `${selectedLabels.length} selected options`;
                    } else if (filterType === 'boolean' && filter.value !== undefined) {
                        filterDetail = filter.value ? 'Yes' : 'No';
                    }
                    
                    return filterDetail ? (
                      <Text key={key} fontSize="sm" ml={2} color="gray.600">
                        • {label}: {filterDetail}
                      </Text>
                    ) : null;
                  })}

                  {/* Metric Filters Display: updated arrow function for concise return */}
                  {Array.isArray(metricFilter) && metricFilter.length > 0 && (
                    <Text fontSize="sm" ml={2} color="gray.600">
                      • Metrics: {metricFilter.map(f => `${f.metric_key} ${f.operator} ${f.value}`).join(', ')}
                    </Text>
                  )}

                </VStack>
              )}
            </Box>

            {/* Section 2: Select Columns */}
            <Box mb={4}>
              <Heading size="sm" mb={3}>Select Columns to Export</Heading>
              {isLoadingColumns || isLoadingExportFields ? (
                <Center><Spinner /></Center>
              ) : isExportFieldsError ? (
                <Alert status="error">
                  <AlertIcon /> Error loading export fields.
                </Alert>
              ) : (
                <>
                  <HStack justify="space-between" mb={3}>
                    <Text fontSize="sm" color="gray.600">{selectedColumns.length} columns selected</Text>
                    <HStack>
                      <Button size="xs" onClick={selectAllColumns} variant="link">Select All</Button>
                      <Button size="xs" onClick={unselectAllColumns} variant="link">Unselect All</Button>
                    </HStack>
                  </HStack>
                  <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} maxH="300px" overflowY="auto" p={3} borderWidth="1px" borderRadius="md">
                    {Object.entries(contactColumns).map(([categoryKey, categoryData]) => (
                      <Box key={categoryKey}>
                        <Heading size="xs" mb={2} textTransform="capitalize">{categoryData.title}</Heading>
                        <VStack align="start" spacing={1}>
                          {categoryData.items.map(field => (
                            <Checkbox
                              key={field.key}
                              value={field.key}
                              isChecked={selectedColumns.includes(field.key)}
                              onChange={() => toggleColumnSelection(field.key)}
                              size="sm"
                            >
                              {field.label}
                            </Checkbox>
                          ))}
                        </VStack>
                      </Box>
                    ))}
                  </SimpleGrid>
                </>
              )}
            </Box>

            {/* Section 3: Select Format */}
            <FormControl>
              <FormLabel fontWeight="medium" fontSize="sm">Export Format</FormLabel>
              <RadioGroup onChange={setExportFormat} value={exportFormat}>
                <Stack direction="row">
                  <Radio value="csv">CSV</Radio>
                  <Radio value="xlsx">XLSX</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

          </ModalBody>

          <ModalFooter borderTopWidth="1px" pt={3}>
            <Button variant="ghost" mr={3} onClick={() => setIsExportModalOpen(false)}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleExportContacts}
              isLoading={isLoadingExport}
              isDisabled={selectedColumns.length === 0 || deals.length === 0 || isLoadingColumns || isLoadingExportFields || isExportFieldsError}
            >
              Export Contacts
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Deals;