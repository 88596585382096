import { useQuery } from "@tanstack/react-query";
import { useAuthData } from "../../../auth-context";

export const useDashboardUsers = (accountID) => {
  const { getAccessToken } = useAuthData();

  const fetchUsers = async () => {
    if (!accountID) return [];

    const accessToken = await getAccessToken();
    const apiUrl =
      process.env.REACT_APP_API_URL ||
      "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

    const response = await fetch(
      `${apiUrl}/api/accounts/${accountID}/get-users`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch users");
    }

    const data = await response.json();
    return data;
  };

  const {
    data: users = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["dashboardUsers", accountID],
    queryFn: fetchUsers,
    enabled: !!accountID,
  });

  return { users, loading: isLoading, error };
};
