import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";
import { useToast } from "@chakra-ui/react";

/**
 * Custom hook to fetch unified deal filter options for an account.
 * This hook provides filtering options for deal fields (stages, users, etc.) and metrics.
 *
 * @param {string} accountID - The account ID to fetch filter options for.
 * @param {Object} filters - Optional filters to apply for dynamic filter options
 * @returns {Object} - Contains unified filter options data, loading state, and error state.
 */
export function useFetchDealMetricFilterOptions(accountID, filters = {}) {
  const { getAccessToken } = useAuthData();
  const toast = useToast();

  // Define the default structure for the unified options
  const defaultOptions = {
    fields: {
      pipeline_options: [],
      stage_options: [],
      user_options: [],
      lead_source_options: [],
      priority_options: [],
      amount_range: null,
      date_ranges: {},
      boolean_fields: [],
    },
    metrics: {
      metric_keys: [],
      valid_operators: [],
      metric_ranges: {},
    },
    // Keep recommended_filters for potential future use or backward compatibility if needed
    recommended_filters: [],
  };

  const fetchUnifiedFilterOptions = async () => {
    if (!accountID) {
      console.warn(
        "Missing required accountID for useFetchDealMetricFilterOptions (Unified)"
      );
      // Return the default structure on missing ID
      return defaultOptions;
    }

    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Prepare URL with query parameters for filtering
      const url = new URL(
        `${target}/api/accounts/${accountID}/deals/unified-filter-options`
      );

      // Add filter parameters to query
      if (filters) {
        Object.entries(filters).forEach(([key, value]) => {
          if (value !== undefined && value !== null && value !== "") {
            url.searchParams.append(key, value);
          }
        });
      }

      // Make the API request with filters
      const response = await axios.get(url.toString(), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // *** Return the new structure with fallbacks ***
      return {
        fields: response.data.fields || defaultOptions.fields,
        metrics: response.data.metrics || defaultOptions.metrics,
        // Include recommended_filters if present in the response, otherwise default
        recommended_filters:
          response.data.recommended_filters ||
          defaultOptions.recommended_filters,
      };
    } catch (error) {
      console.error("Error fetching unified deal filter options:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });

      toast({
        title: "Error loading filters",
        description: `Could not load filter options: ${
          error.response?.data?.message || error.message
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      // Return default structure on error
      return defaultOptions;
    }
  };

  const {
    data = defaultOptions, // Use the default structure here
    isLoading: isLoadingUnified,
    isError: isErrorUnified,
    error: errorUnified,
    refetch: refetchUnified,
  } = useQuery({
    // Update queryKey to include filters to trigger refetch when filters change
    queryKey: ["dealUnifiedFilterOptions", accountID, JSON.stringify(filters)],
    queryFn: fetchUnifiedFilterOptions,
    enabled: !!accountID,
    staleTime: 10 * 60 * 1000, // 10 minutes
    cacheTime: 60 * 60 * 1000, // 1 hour
    // Ensure data is not reset to undefined on error, keep previous or default
    keepPreviousData: true,
  });

  return {
    // Rename the returned object key for clarity
    unifiedFilterOptions: data,
    isLoading: isLoadingUnified,
    isError: isErrorUnified,
    error: errorUnified,
    refetch: refetchUnified,
  };
}

export default useFetchDealMetricFilterOptions;
