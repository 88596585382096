import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to fetch Slack channels based on the account ID and user ID.
 * @param {string} accountID - The account ID to fetch Slack channels for.
 * @param {string|number} userId - The user ID to fetch Slack channels for.
 * @returns {Object} - Contains channels data, loading state, and error state.
 */
export function useFetchSlackChannels(accountID, userId) {
  const { getAccessToken } = useAuthData();

  const fetchSlackChannels = async () => {
    if (!accountID || !userId) {
      console.warn(
        "Missing required accountID or userId for useFetchSlackChannels"
      );
      return { channels: [], hasIntegration: false };
    }

    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.get(
        `${target}/api/accounts/${accountID}/users/${userId}/slack/channels`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return {
        channels: response.data.channels || [],
        hasIntegration: true,
      };
    } catch (error) {
      // If status is 404, it means no Slack integration found
      if (error.response?.status === 404) {
        return { channels: [], hasIntegration: false };
      }
      console.error("Error fetching Slack channels:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      throw error;
    }
  };

  const {
    data = { channels: [], hasIntegration: false },
    isLoading,
    error,
  } = useQuery({
    queryKey: ["slackChannels", accountID, userId],
    queryFn: fetchSlackChannels,
    enabled: !!accountID && !!userId,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  return {
    channels: data.channels,
    hasIntegration: data.hasIntegration,
    isLoading,
    error,
  };
}

export default useFetchSlackChannels;
