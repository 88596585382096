// src/components/Shared/PaginationControls.jsx

import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  HStack,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useSidebar } from "contexts/SidebarContext";
import { STYLE_CONSTANTS } from "components/sidebar/sidebarConstants";
import { useLocation } from "react-router-dom";

/**
 * PaginationControls Component
 * Provides Previous and Next buttons along with current page information.
 *
 * @param {Object} props - Component props
 * @param {number} props.currentPage - The current active page (1-based index)
 * @param {number} props.totalPages - Total number of pages
 * @param {Function} props.onPrevious - Function to call when Previous is clicked
 * @param {Function} props.onNext - Function to call when Next is clicked
 * @param {bool} props.disablePrevious - Whether Previous button should be disabled
 * @param {bool} props.disableNext - Whether Next button should be disabled
 * @param {Function} props.onPageSelect - Function to call when a page number is clicked
 * @returns {JSX.Element}
 */
const PaginationControls = ({
  currentPage,
  totalPages,
  onPrevious,
  onNext,
  disablePrevious = false,
  disableNext = false,
  onPageSelect = () => undefined
}) => {
  // Enhanced debug logging for pagination state
  const logPaginationState = useCallback(() => {
    // Log pagination state
  }, [currentPage, totalPages, disablePrevious, disableNext]);
  
  // Log changes to the props
  useEffect(() => {
    logPaginationState();
  }, [currentPage, totalPages, disablePrevious, disableNext, logPaginationState]);
  
  const { isExpanded } = useSidebar();
  const location = useLocation();
  
  // Check if we're in a detail view by looking for an ID in the URL
  const isDetailView = /\/\d+$/.test(location.pathname);
  
  // Only hide in detail views
  if (isDetailView) {
    return null;
  }
  
  // Enhanced handlers with logging
  const handlePrevious = () => {
    onPrevious();
  };
  
  const handleNext = () => {
    onNext();
  };
  
  const handlePageSelect = (pageNum) => {
    onPageSelect(pageNum);
  };
  
  // Helper function to generate page numbers to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    
    // Always show first page
    pageNumbers.push(1);
    
    // For a small number of pages, show all pages without ellipsis
    if (totalPages <= 7) {
      for (let i = 2; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    }
    
    // For more pages, show intelligent pagination with ellipsis
    
    // Calculate range around current page
    const showEllipsisStart = currentPage > 3;
    const showEllipsisEnd = currentPage < totalPages - 2;
    
    if (showEllipsisStart) {
      // Show ellipsis after first page
      pageNumbers.push('...');
    } else {
      // Otherwise show pages 2 and 3
      if (totalPages >= 2) pageNumbers.push(2);
      if (totalPages >= 3) pageNumbers.push(3);
    }
    
    // Always show pages around current page
    if (currentPage > 3 && currentPage < totalPages - 2) {
      pageNumbers.push(currentPage - 1);
      pageNumbers.push(currentPage);
      pageNumbers.push(currentPage + 1);
    }
    
    if (showEllipsisEnd) {
      // Show ellipsis before last page
      pageNumbers.push('...');
    } else {
      // Otherwise show second and third last pages
      if (totalPages >= 4) pageNumbers.push(totalPages - 2);
      if (totalPages >= 3) pageNumbers.push(totalPages - 1);
    }
    
    // Always show last page, unless it's already been added
    if (totalPages > 1 && pageNumbers[pageNumbers.length - 1] !== totalPages) {
      pageNumbers.push(totalPages);
    }
    
    // Remove duplicates that might occur at boundaries
    const uniquePageNumbers = [...new Set(pageNumbers)];
    return uniquePageNumbers;
  };

  // Calculate the left offset based on sidebar state
  const sidebarWidth = isExpanded ? STYLE_CONSTANTS.EXPANDED_WIDTH : STYLE_CONSTANTS.COLLAPSED_WIDTH;
  const leftOffset = `calc(50% + ${parseInt(sidebarWidth) / 2}px)`;

  // Determine if we should show navigation buttons
  const showNavButtons = totalPages > 3;

  const pageNumbers = getPageNumbers();

  return (
    <Box
      bg="white"
      p={2}
      borderRadius="md"
      boxShadow="lg"
      borderWidth="1px"
      borderColor="gray.200"
      position="fixed"
      bottom="4"
      left={leftOffset}
      transform="translateX(-50%)"
      zIndex={10}
      width={showNavButtons ? "auto" : "auto"}
      minW="fit-content"
      maxW="80vw"
      transition={STYLE_CONSTANTS.TRANSITION}
    >
      <HStack spacing={1} justify="space-between" align="center">
        {showNavButtons && (
          <Box width="40px">
            <IconButton
              icon={<ChevronLeftIcon boxSize={5} />}
              onClick={handlePrevious}
              isDisabled={disablePrevious}
              variant="ghost"
              aria-label="Previous page"
              colorScheme="gray"
              size="md"
              height="40px"
              borderRadius="md"
            />
          </Box>
        )}

        <HStack 
          spacing={1} 
          justify="center" 
          flex={showNavButtons ? 1 : undefined}
          maxW={showNavButtons ? "none" : undefined} 
          overflow="auto"
          css={{
            '&::-webkit-scrollbar': {
              height: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '2px',
            },
          }}
          px={2}
        >
          {pageNumbers.map((pageNum, index) => (
            pageNum === '...' ? (
              <Text key={`ellipsis-${index}`} color="gray.500" px={2}>
                {pageNum}
              </Text>
            ) : (
              <Button
                key={pageNum}
                onClick={() => handlePageSelect(pageNum)}
                variant={currentPage === pageNum ? 'solid' : 'ghost'}
                colorScheme={currentPage === pageNum ? 'blue' : 'gray'}
                size="md"
                height="40px"
                minW="40px"
                borderRadius="xl"
              >
                {pageNum}
              </Button>
            )
          ))}
        </HStack>

        {showNavButtons && (
          <Box width="40px">
            <IconButton
              icon={<ChevronRightIcon boxSize={5} />}
              onClick={handleNext}
              isDisabled={disableNext}
              variant="ghost"
              aria-label="Next page"
              colorScheme="gray"
              size="md"
              height="40px"
              borderRadius="md"
            />
          </Box>
        )}
      </HStack>
    </Box>
  );
};

// Define PropTypes for type checking
PaginationControls.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  disablePrevious: PropTypes.bool,
  disableNext: PropTypes.bool,
  onPageSelect: PropTypes.func,
};

export default PaginationControls;
