import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

export function useFetchFilterOptions(
  accountID,
  userID,
  selectedSegment,
  taskTypeFilter = [],
  taskSubtypeFilter = [],
  taskOwnerFilter = ""
) {
  const [filterOptions, setFilterOptions] = useState({
    task_types: [],
    task_subtypes: [],
    statuses: [],
    user_ids: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuthData();
  
  // Add refs to track request state
  const abortControllerRef = useRef(null);
  const isCurrentlyFetching = useRef(false);
  const requestIdRef = useRef(0);

  useEffect(() => {
    // If we already have an active request, abort it first
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    const controller = new AbortController();
    abortControllerRef.current = controller;
    const requestId = ++requestIdRef.current;

    const fetchFilterOptions = async () => {
      if (!accountID || !userID) {
        setLoading(false);
        return;
      }
      
      // Prevent multiple fetches happening at the same time
      if (isCurrentlyFetching.current) {
        return;
      }

      try {
        isCurrentlyFetching.current = true;
        setLoading(true);
        setError(null);

        const accessToken = await getAccessToken();
        const target =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
            : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const params = {};

        if (selectedSegment === "all_tasks") {
          params.role = "admin";
        }

        // Add status parameter based on the selected segment
        if (selectedSegment === "completed") {
          params.status = "completed";
        } else {
          // For "all_tasks" and "my_tasks", use "to do" status
          params.status = "to do";
        }

        // Add task_type filter if provided
        if (taskTypeFilter && taskTypeFilter.length > 0) {
          params.task_type = taskTypeFilter.join(",");
        }

        // Add task_subtype filter if provided
        if (taskSubtypeFilter && taskSubtypeFilter.length > 0) {
          params.task_subtype = taskSubtypeFilter.join(",");
        }

        // Add filter_by_user parameter if provided
        if (taskOwnerFilter) {
          params.filter_by_user = taskOwnerFilter;
        } else if (selectedSegment === "my_tasks") {
          // For "my_tasks" segment, always filter by the current user
          params.filter_by_user = userID;
        }

        const response = await axios.get(
          `${target}/api/accounts/${accountID}/users/${userID}/inbox/filter-options`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params,
            signal: controller.signal,
          }
        );

        // Only update state if this is still the current request
        if (requestId === requestIdRef.current) {
          if (response.data) {
            setFilterOptions(response.data);
          }
          setLoading(false);
        }
      } catch (err) {
        if (err.name === "AbortError" || err.name === "CanceledError") {
          return;
        }
        console.error("Error fetching filter options:", err);
        if (requestId === requestIdRef.current) {
          setError(err);
          setLoading(false);
        }
      } finally {
        isCurrentlyFetching.current = false;
      }
    };

    // Add a small delay to allow for batched state updates
    const timerId = setTimeout(() => {
      fetchFilterOptions();
    }, 20);

    return () => {
      clearTimeout(timerId);
      controller.abort();
      // Don't reset the request ID - only increment it
    };
  }, [
    accountID,
    userID,
    getAccessToken,
    selectedSegment,
    taskTypeFilter,
    taskSubtypeFilter,
    taskOwnerFilter,
  ]);

  return { filterOptions, loading, error };
}

export default useFetchFilterOptions;
