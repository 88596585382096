// src/hooks/useFetchDeals.js

import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";
import { useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";

/**
 * Custom hook to fetch deals based on the account ID with pagination support.
 * @param {string} accountID - The account ID to fetch deals for.
 * @param {number} pageNumber - The page number to fetch (default: 1).
 * @param {number} itemsPerPage - Number of items per page (default: 10).
 * @param {number} selectedYear - The selected year to filter deals.
 * @param {number} selectedQuarter - The selected quarter to filter deals (optional).
 * @param {object|array} metricFilters - Single filter object or array of filter objects for deal metrics (optional).
 * @param {object} sortConfig - Configuration for sorting the deals.
 * @param {string} sortConfig.key - The field to sort by.
 * @param {string} sortConfig.direction - The direction to sort (ascending or descending).
 * @returns {Object} - Contains deals data, loading state, error state, and pagination info.
 */
export function useFetchDeals(
  accountID,
  pageNumber = 1,
  itemsPerPage = 10,
  selectedYear,
  selectedQuarter,
  metricFilters = null,
  sortConfig = { key: "last_activity_date", direction: "descending" }
) {
  const { getAccessToken } = useAuthData();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [totalCount, setTotalCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const fetchDeals = async (page = pageNumber) => {
    if (!accountID) {
      console.warn("Missing required accountID for useFetchDeals");
      return { items: [], has_more: false, total_count: 0 };
    }

    try {
      const accessToken = await getAccessToken();

      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Calculate start and end dates based on year and quarter
      let startDate, endDate;
      if (selectedYear) {
        if (selectedQuarter) {
          // For specific quarter
          const quarterStartMonth = (selectedQuarter - 1) * 3;
          startDate = `${selectedYear}-${String(quarterStartMonth + 1).padStart(
            2,
            "0"
          )}-01`;
          endDate = `${selectedYear}-${String(quarterStartMonth + 3).padStart(
            2,
            "0"
          )}-${quarterStartMonth + 3 === 12 ? "31" : "30"}`;
        } else {
          // For entire year
          startDate = `${selectedYear}-01-01`;
          endDate = `${selectedYear}-12-31`;
        }
      }

      let response;
      let endpoint = "";

      // Use the filter-by-metrics endpoint when metric filters are provided
      if (metricFilters) {
        // Ensure metricFilters is an array
        const metricsArray = Array.isArray(metricFilters)
          ? metricFilters
          : metricFilters.metric_key
          ? [metricFilters]
          : [];

        // Clean up filters - remove empty string properties and ensure valid values
        const cleanedMetricsArray = metricsArray
          .filter((filter) => filter && filter.metric_key && filter.operator)
          .map((filter) => {
            // Create a new object with only valid properties
            const cleanedFilter = {
              metric_key: filter.metric_key,
              operator: filter.operator,
              // Ensure value is not null - use 0 as fallback
              value:
                filter.value !== null && filter.value !== undefined
                  ? filter.value
                  : 0,
            };

            // Only include non-empty optional parameters
            if (filter.time_range && filter.time_range.trim() !== "") {
              cleanedFilter.time_range = filter.time_range;
            }

            if (filter.min_count && filter.min_count > 1) {
              cleanedFilter.min_count = filter.min_count;
            }

            return cleanedFilter;
          });

        // Only use the metrics endpoint if we have valid filters
        if (cleanedMetricsArray.length > 0) {
          try {
            // For filter-by-metrics endpoint
            endpoint = `${target}/api/accounts/${accountID}/deals/filter-by-metrics`;
            const params = new URLSearchParams();
            params.append(
              "metric_filters",
              JSON.stringify(cleanedMetricsArray)
            );

            if (startDate) {
              params.append("start_date", startDate);
            }

            if (endDate) {
              params.append("end_date", endDate);
            }

            // Add pagination parameters
            params.append("page_number", page.toString());
            params.append("items_per_page", itemsPerPage.toString());

            // Add sorting parameters
            if (sortConfig && sortConfig.key) {
              params.append("sort_by", sortConfig.key);
              params.append(
                "sort_order",
                sortConfig.direction === "ascending" ? "asc" : "desc"
              );
            }

            response = await axios.get(endpoint, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              params,
            });
          } catch (filterError) {
            // If there's an error with the metric filter API, show a toast
            toast({
              title: "Filter error",
              description:
                filterError.response?.data?.message ||
                "There was an issue with your metric filters. Try a different filter combination.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });

            // Return empty response instead of throwing, so the UI can still render
            return { items: [], has_more: false, total_count: 0 };
          }
        } else {
          // Fallback to regular endpoint with basic params
          endpoint = `${target}/api/accounts/${accountID}/deals`;
          const params = {
            ...(startDate && { start_date: startDate }),
            ...(endDate && { end_date: endDate }),
            page_number: page,
            items_per_page: itemsPerPage,
            ...(sortConfig &&
              sortConfig.key && {
                sort_by: sortConfig.key,
                sort_order:
                  sortConfig.direction === "ascending" ? "asc" : "desc",
              }),
          };

          response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params,
          });
        }
      } else {
        // Standard deals endpoint with date filters
        endpoint = `${target}/api/accounts/${accountID}/deals`;
        const params = {
          ...(startDate && { start_date: startDate }),
          ...(endDate && { end_date: endDate }),
          page_number: page,
          items_per_page: itemsPerPage,
          ...(sortConfig &&
            sortConfig.key && {
              sort_by: sortConfig.key,
              sort_order: sortConfig.direction === "ascending" ? "asc" : "desc",
            }),
        };

        response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params,
        });
      }

      // Parse the response to extract deals and pagination data
      let deals = [];
      let responseHasMore = false;
      let responseTotalCount = 0;

      // API returns paginated response object with items, has_more, and total_count
      if (response.data && typeof response.data === "object") {
        if (Array.isArray(response.data.items)) {
          deals = response.data.items.map((deal) => ({
            ...deal,
            // Ensure we have both name properties for compatibility
            deal_name: deal.deal_name || deal.name,
            name: deal.name || deal.deal_name,
          }));
          responseHasMore = response.data.has_more || false;
          responseTotalCount = response.data.total_count || deals.length;
        } else if (Array.isArray(response.data)) {
          // Handle case where API returns array directly (for backwards compatibility)
          deals = response.data.map((deal) => ({
            ...deal,
            deal_name: deal.deal_name || deal.name,
            name: deal.name || deal.deal_name,
          }));
        } else {
          // Try to find a deeply nested property that contains the deals array
          for (const key in response.data) {
            if (response.data[key] && typeof response.data[key] === "object") {
              if (Array.isArray(response.data[key])) {
                deals = response.data[key].map((deal) => ({
                  ...deal,
                  deal_name: deal.deal_name || deal.name,
                  name: deal.name || deal.deal_name,
                }));
                break;
              } else {
                // One level deeper
                for (const nestedKey in response.data[key]) {
                  if (
                    response.data[key][nestedKey] &&
                    Array.isArray(response.data[key][nestedKey])
                  ) {
                    deals = response.data[key][nestedKey].map((deal) => ({
                      ...deal,
                      deal_name: deal.deal_name || deal.name,
                      name: deal.name || deal.deal_name,
                    }));
                    break;
                  }
                }
              }
            }
          }
          responseHasMore = false;
          responseTotalCount = deals.length;
        }
      }

      return {
        items: deals,
        has_more: responseHasMore,
        total_count: responseTotalCount,
      };
    } catch (error) {
      // Show a toast with the error information
      toast({
        title: "Failed to load deals",
        description:
          error.response?.data?.message ||
          "There was an issue retrieving deals data. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      // Return empty response instead of throwing, so the UI can still render
      return { items: [], has_more: false, total_count: 0 };
    }
  };

  const {
    data = { items: [], has_more: false, total_count: 0 },
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      "deals",
      accountID,
      pageNumber,
      itemsPerPage,
      selectedYear,
      selectedQuarter,
      JSON.stringify(metricFilters),
      sortConfig.key,
      sortConfig.direction,
    ],
    queryFn: () => fetchDeals(pageNumber),
    enabled: !!accountID,
  });

  // Update total count and has_more states when data changes
  useEffect(() => {
    if (data) {
      setTotalCount(data.total_count || 0);
      setHasMore(data.has_more || false);
    }
  }, [data]);

  // Prefetch next page if there are more results
  useEffect(() => {
    if (data?.has_more && !isLoading && accountID) {
      const nextPage = pageNumber + 1;

      queryClient.prefetchQuery({
        queryKey: [
          "deals",
          accountID,
          nextPage,
          itemsPerPage,
          selectedYear,
          selectedQuarter,
          JSON.stringify(metricFilters),
          sortConfig.key,
          sortConfig.direction,
        ],
        queryFn: () => fetchDeals(nextPage),
        staleTime: 5 * 60 * 1000,
      });
    }
  }, [
    data,
    isLoading,
    accountID,
    pageNumber,
    itemsPerPage,
    selectedYear,
    selectedQuarter,
    metricFilters,
    sortConfig,
    queryClient,
  ]);

  return {
    deals: data?.items || [],
    isLoading,
    error,
    refetch,
    totalCount,
    hasMore,
  };
}

export default useFetchDeals;
