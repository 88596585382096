import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import { FaClock, FaTasks, FaUser } from 'react-icons/fa';
import { parseISO, formatDistanceToNow } from 'date-fns';
import { OverviewTag, DealTag } from '../Shared/Tags';
import { useNavigate } from 'react-router-dom';
import useFetchDealActivities from '../hooks/useFetchDealActivities';
import { useAuthData } from '../../../auth-context';

const OverviewSection = ({ 
  taskType, 
  lastEngagementDate,
  dealName,
  dealId,
  owner = null,
  showOwner = true
}) => {
  const navigate = useNavigate();
  const { account_ID: accountID } = useAuthData();
  
  // Set start date to January 1, 2022
  const startDate = '2022-01-01';
  // Set end date to current date in YYYY-MM-DD format
  const endDate = new Date().toISOString().split('T')[0];
  
  // Only fetch activities if we have a dealId
  const { data: activities } = useFetchDealActivities(
    accountID,
    dealId,
    startDate,
    endDate
  );
  
  // Find the most recent activity date
  const mostRecentActivityDate = React.useMemo(() => {
    if (!activities || activities.length === 0) {
      return lastEngagementDate; // Fall back to the prop if no activities
    }
    
    // Sort activities in reverse chronological order and get the first one
    const sortedActivities = [...activities].sort((a, b) => {
      const dateA = new Date(a.activity_date);
      const dateB = new Date(b.activity_date);
      return dateB - dateA; // Most recent first
    });
    
    return sortedActivities[0]?.activity_date;
  }, [activities, lastEngagementDate]);
  
  const hasActivities = React.useMemo(() => {
    return (activities && activities.length > 0) || lastEngagementDate;
  }, [activities, lastEngagementDate]);
  
  const formattedDate = mostRecentActivityDate 
    ? formatDistanceToNow(parseISO(mostRecentActivityDate), { addSuffix: true })
    : 'N/A';

  const handleDealClick = () => {
    // Navigate to deal details page using react-router
    navigate(`/admin/deals/${dealId}`);
  };

  return (
    <Box>
      <Flex gap={3} flexWrap="wrap">
        {dealName && dealId && (
          <DealTag 
            deal={{ name: dealName, id: dealId }} 
            onClick={handleDealClick} 
          />
        )}
        <OverviewTag
          icon={FaTasks}
          label="Task Type"
          value={taskType || 'Followup'}
          colorScheme="green"
        />
        {showOwner && (
          <OverviewTag
            icon={FaUser}
            label="Owner"
            value={owner ? owner.name : 'Unassigned'}
            colorScheme="blue"
          />
        )}
        {hasActivities && (
          <OverviewTag
            icon={FaClock}
            label="Last Activity"
            value={formattedDate}
            colorScheme="orange"
          />
        )}
      </Flex>
    </Box>
  );
};

OverviewSection.propTypes = {
  account: PropTypes.string,
  taskType: PropTypes.string,
  lastEngagementDate: PropTypes.string,
  dealName: PropTypes.string,
  dealId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  owner: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string
  }),
  showOwner: PropTypes.bool
};

OverviewSection.defaultProps = {
  account: 'Unknown Account',
  taskType: 'Followup',
  lastEngagementDate: null,
  dealName: null,
  dealId: null,
  owner: null,
  showOwner: true
};

export default OverviewSection;