import React, { useMemo } from "react";
import { Box, VStack, Text, Spinner, Alert, AlertIcon } from "@chakra-ui/react";
import ApexCharts from "react-apexcharts";
import { PRIMARY_COLOR, CHART_COLORS } from "../../constants";
import usePipelineOverviewData, { processDealsData } from "../../../hooks/usePipelineOverviewData";

/**
 * CurrentPipelineChart Component
 * Displays the current deal stage of all deals in the pipeline in a bar chart
 */
const CurrentPipelineChart = ({ data, title }) => {
  if (!data || data.length === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        No pipeline data available.
      </Alert>
    );
  }

  // Use the predefined chart colors from constants instead of custom colors per stage
  const getChartColors = (stages) => {
    return stages.map((_, index) => CHART_COLORS[index % CHART_COLORS.length]);
  };

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "60%",
        distributed: true,
        dataLabels: {
          position: 'top',
        }
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -25, // Position labels above the bar
      style: {
        fontSize: "12px",
        colors: ["#000000"], // Black text
        fontWeight: "bold"
      },
      background: {
        enabled: true,
        foreColor: "#FFFFFF", // White background
        borderRadius: 4,
        borderWidth: 1,
        borderColor: "#E2E8F0",
        opacity: 0.9,
        padding: {
          left: 5,
          right: 5,
          top: 1,
          bottom: 1
        }
      },
    },
    colors: getChartColors(data),
    xaxis: {
      categories: data.map((d) => d.stageName),
      labels: {
        style: {
          fontSize: "12px",
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.round(val);
        },
      },
      title: {
        text: "Number of Deals",
      },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return Math.round(val);
        },
      },
      x: {
        formatter: function(val) {
          const stage = data.find(d => d.stageName === val);
          if (stage) {
            return `${val}: ${stage.count} deals`;
          }
          return val;
        }
      }
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    theme: {
      mode: 'light',
      palette: 'palette1',
      monochrome: {
        enabled: false,
        color: PRIMARY_COLOR,
        shadeTo: 'light',
        shadeIntensity: 0.65
      },
    }
  };

  return (
    <Box
      bg="white"
      p={6}
      borderRadius="lg"
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.200"
    >
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        {title}
      </Text>
      <Text fontSize="sm" color="gray.500" mb={4}>
        Current number of deals in each pipeline stage
      </Text>
      <ApexCharts
        options={chartOptions}
        series={[
          {
            name: "Deals",
            data: data.map((d) => d.count),
          },
        ]}
        type="bar"
        height={350}
      />
    </Box>
  );
};

/**
 * ConversionRatesChart Component
 * Displays the conversion rates between pipeline stages
 */
const ConversionRatesChart = ({ data, title }) => {
  if (!data || data.length === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        No conversion rate data available.
      </Alert>
    );
  }

  // Use a blue gradient for the funnel using the CHART_COLORS
  const getChartColors = (stages) => {
    return stages.map((_, index) => CHART_COLORS[index % CHART_COLORS.length]);
  };

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "60%",
        distributed: true,
        dataLabels: {
          position: 'top',
        }
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}%`;
      },
      offsetY: -25, // Position labels above the bar
      style: {
        fontSize: "12px",
        colors: ["#000000"], // Black text
        fontWeight: "bold"
      },
      background: {
        enabled: true,
        foreColor: "#FFFFFF", // White background
        padding: {
          left: 5,
          right: 5,
          top: 1,
          bottom: 1
        },
        borderRadius: 4,
        borderWidth: 1,
        borderColor: "#E2E8F0",
        opacity: 0.9
      },
    },
    colors: getChartColors(data),
    xaxis: {
      categories: data.map((d) => d.stageName),
      labels: {
        style: {
          fontSize: "12px",
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 105, // Allow a little space above 100%
      labels: {
        formatter: function (val) {
          return `${Math.round(val)}%`;
        },
      },
      title: {
        text: "Conversion Rate (%)",
      },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return `${Math.round(val)}%`;
        },
      },
      x: {
        formatter: function(val) {
          const stage = data.find(d => d.stageName === val);
          if (stage) {
            return `${val}: ${stage.count} deals (${stage.conversionRate}%)`;
          }
          return val;
        }
      }
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    theme: {
      mode: 'light',
      palette: 'palette1',
      monochrome: {
        enabled: false,
        color: PRIMARY_COLOR,
        shadeTo: 'light',
        shadeIntensity: 0.65
      },
    }
  };

  return (
    <Box
      bg="white"
      p={6}
      borderRadius="lg"
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.200"
      mt={6}
    >
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        {title}
      </Text>
      <Text fontSize="sm" color="gray.500" mb={4}>
        Percentage of deals that reach each stage in the pipeline. Deals in later stages are counted as having passed through earlier stages.
      </Text>
      <ApexCharts
        options={chartOptions}
        series={[
          {
            name: "Conversion Rate",
            data: data.map((d) => d.conversionRate),
          },
        ]}
        type="bar"
        height={350}
      />
    </Box>
  );
};

/**
 * PipelineOverviewGrid Component
 * Main component for the Pipeline Overview view
 */
const PipelineOverviewGrid = () => {
  const { data: dealsData, isLoading, error } = usePipelineOverviewData();

  const processedData = useMemo(() => {
    if (!dealsData) return { currentPipeline: [], conversionRates: [] };
    return processDealsData(dealsData);
  }, [dealsData]);

  if (isLoading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" color={PRIMARY_COLOR} />
        <Text mt={4}>Loading pipeline data...</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        Error loading pipeline data: {error?.message || "Unknown error"}
      </Alert>
    );
  }

  if (!dealsData || dealsData.length === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        No pipeline data available.
      </Alert>
    );
  }

  return (
    <VStack spacing={6} align="stretch" width="100%">
      <CurrentPipelineChart
        data={processedData.currentPipeline}
        title="Current Pipeline Graph"
      />
      <ConversionRatesChart
        data={processedData.conversionRates}
        title="Conversion Rates"
      />
    </VStack>
  );
};

export default PipelineOverviewGrid; 