import React from 'react';
import {
  Box,
  Flex,
  Text,
  IconButton,
  VStack,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import { MdMoreVert, MdEdit, MdArchive, MdUnarchive, MdDelete, MdRocketLaunch } from 'react-icons/md';
import { FaExpandAlt, FaCheckCircle, FaRegCircle, FaStream } from 'react-icons/fa';
import { OverviewTag } from '../Shared/Tags';
import HoverBox from '../Shared/HoverBox';
import EditPipelineModal from './EditPipelineModal';
import axios from 'axios';
import { useAuthData } from '../../../auth-context';
import { useQueryClient } from '@tanstack/react-query';

const PipelineCard = ({ pipeline }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const queryClient = useQueryClient();
  
  // Theme colors
  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const headingColor = useColorModeValue('gray.700', 'white');

  const handleArchive = async () => {
    try {
      const accessToken = await userData.getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      
      // Toggle the is_active property - opposite of current state
      const newIsActive = !isActive;
      
      // Call the API to update the pipeline
      await axios.put(
        `${target}/api/accounts/${accountID}/pipelines/${pipeline.id}`,
        { 
          is_active: newIsActive,
          // Include necessary fields to ensure other properties aren't lost
          pipelineName: pipeline.label, // Using label as pipelineName
          display_order: pipeline.display_order || 0, // Add display_order field
          offering: pipeline.offering || '',
          customerDefinition: pipeline.customerDefinition || '',
          businessDescription: pipeline.businessDescription || pipeline.businessDefinition || '',
          targetCustomerSize: pipeline.targetCustomerSize || pipeline.qualificationCriteria?.companySize || '',
          targetCustomerLocation: pipeline.targetCustomerLocation || pipeline.qualificationCriteria?.location || '',
          // Make sure we use the right property name for email accounts
          selectedEmailAccounts: pipeline.emailAccounts || []
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      // Invalidate the pipelines query to refresh the data
      queryClient.invalidateQueries(["pipelines", accountID]);
      
      toast({
        title: `Pipeline ${newIsActive ? 'unarchived' : 'archived'}`,
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Error updating pipeline',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    }
  };

  const handleDelete = async () => {
    try {
      const accessToken = await userData.getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      
      // Delete the pipeline
      await axios.delete(
        `${target}/api/accounts/${accountID}/pipelines/${pipeline.id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      // Invalidate the pipelines query to refresh the data
      queryClient.invalidateQueries(["pipelines", accountID]);
      
      toast({
        title: 'Pipeline deleted',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Error deleting pipeline',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    }
  };

  // Calculate pipeline metrics
  const totalDeals = pipeline.active_deals || 0;
  const totalStages = pipeline.stages?.length || 0;

  // Determine status based on is_active property - handle both boolean values and string representation
  // This is more robust and will handle both cases correctly
  const isActive = pipeline.is_active === true || pipeline.is_active === "true";
  
  // Debug log
  // console.log(`Pipeline ${pipeline.label} - is_active (raw): ${pipeline.is_active}, type: ${typeof pipeline.is_active}, isActive: ${isActive}, pipelineStatus: ${pipelineStatus}`);

  // Generate a default description if none is provided
  const description = pipeline.description || `Pipeline with ${totalStages} stages and ${totalDeals} active deals`;

  return (
    <Box
      height="fit-content"
      alignSelf="flex-start"
      bg={cardBg}
      p={8}
      borderRadius="md"
      boxShadow="sm"
      borderWidth="1px"
      borderColor={borderColor}
      _hover={{
        borderColor: "gray.300",
        boxShadow: "md",
      }}
      transition="all 0.2s"
      display="flex"
      flexDirection="column"
    >
      <VStack spacing={6} align="stretch" flex={1}>
        {/* Title Bar */}
        <Flex justify="space-between" align="center">
          <Box position="relative" flex={1} maxWidth="fit-content">
            <HoverBox
              icon={MdRocketLaunch}
              hoverIcon={FaExpandAlt}
              onClick={onOpen}
              tooltipLabel="Edit Pipeline"
            >
              <Flex align="center">
                <Text fontSize="lg" fontWeight="semibold" color={headingColor}>
                  {pipeline.label}
                </Text>
              </Flex>
            </HoverBox>
          </Box>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<MdMoreVert />}
              variant="ghost"
              size="sm"
              aria-label="More options"
            />
            <MenuList>
              <MenuItem icon={<MdEdit />} onClick={onOpen}>
                Edit
              </MenuItem>
              <MenuItem
                icon={isActive ? <MdArchive /> : <MdUnarchive />}
                onClick={handleArchive}
              >
                {isActive ? 'Archive' : 'Unarchive'}
              </MenuItem>
              <MenuItem
                icon={<MdDelete />}
                color="red.500"
                onClick={handleDelete}
                isDisabled={totalDeals > 0}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        {/* Description */}
        <Text fontSize="md" color={textColor} noOfLines={2}>
          {description}
        </Text>

        {/* Overview Tags */}
        <Flex gap={3} flexWrap="wrap">
          <OverviewTag
            icon={FaStream}
            label="Stages"
            value={`${totalStages} ${totalStages === 1 ? 'Stage' : 'Stages'}`}
            colorScheme="blue"
          />
          <OverviewTag
            icon={MdRocketLaunch}
            label="Active Deals"
            value={totalDeals.toString()}
            colorScheme="purple"
          />
          <OverviewTag
            icon={isActive ? FaCheckCircle : FaRegCircle}
            label="Status"
            value={isActive ? 'Active' : 'Archived'}
            colorScheme={isActive ? 'green' : 'gray'}
          />
        </Flex>

        {/* Pipeline Stages */}
        {totalStages > 0 && (
          <VStack spacing={4} align="stretch">
            <Text fontSize="md" fontWeight="semibold" color={headingColor}>
              Pipeline Stages
            </Text>
            <Flex gap={2} flexWrap="wrap">
              {pipeline.stages?.map((stage, index) => (
                <Tooltip 
                  key={stage.id || index} 
                  label={stage.description || `Stage ${index + 1}`}
                  placement="top"
                >
                  <Box>
                    <OverviewTag
                      icon={FaStream}
                      label={`Stage ${index + 1}`}
                      value={stage.name}
                      colorScheme={stage.is_closed ? (stage.is_won ? "green" : "red") : "gray"}
                    />
                  </Box>
                </Tooltip>
              ))}
            </Flex>
          </VStack>
        )}
      </VStack>

      <EditPipelineModal
        isOpen={isOpen}
        onClose={onClose}
        pipeline={{
          ...pipeline,
          pipelineName: pipeline.label,
          stages: pipeline.stages?.map(stage => ({
            ...stage,
            name: stage.name,
            description: stage.description || '',
            passingCriteria: '',
            triggerType: 'qa',
            triggerConfig: {},
          })) || [],
        }}
      />
    </Box>
  );
};

export default PipelineCard; 