// src/views/lysto/Inbox/InboxTable.jsx

import React from "react";
import { Box, Text, HStack, Portal, useColorModeValue } from "@chakra-ui/react";
import { MdMail, MdPhone, MdTrackChanges } from "react-icons/md";
import { FaExpandAlt } from "react-icons/fa";
import PropTypes from "prop-types";
import DataTable from "../Shared/DataTable";
import { TASK_MAP } from "./inboxMappings";
import { formatDistanceToNow } from "date-fns";
import HighlightText from "../Shared/HighlightText";
import { useNavigate } from "react-router-dom";
import HoverBox from "../Shared/HoverBox";

/**
 * Helper functions from your original implementation
 */
const removeDealFromSubject = (subject) => {
  if (!subject) return "";
  // Remove "For Deal " and common task medium phrases
  let result = subject.replace("For Deal ", "For ");
  // Remove "on Email" and "on Meeting" phrases
  result = result.replace(" on Email", "");
  result = result.replace(" on Meeting", "");
  return result;
};

/**
 * Truncates text to a specified length and adds ellipsis if necessary
 * @param {string} text - The text to truncate
 * @param {number} maxLength - Maximum allowed length
 * @returns {string} - Truncated text with ellipsis if needed
 */
const truncateText = (text, maxLength = 60) => {
  if (!text || text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
};

const formatTask = (taskType) => {
  if (!taskType) return "—";
  
  // Simplify task type display
  if (taskType.toLowerCase() === "reply") {
    return "Reply";
  } else if (taskType.toLowerCase() === "follow-up" || taskType.toLowerCase() === "follow up") {
    return "Followup";
  }
  
  // Capitalize first letter as fallback
  return taskType.charAt(0).toUpperCase() + taskType.slice(1).toLowerCase();
};

const InboxTable = ({ inboxItems, onViewDetails, onSort, sortConfig, searchQuery = "" }) => {
  const navigate = useNavigate();
  const [hoveredRecipient, setHoveredRecipient] = React.useState(null);
  const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 });
  
  // Helper function to capitalize the first letter of a name
  const capitalizeFirstName = (name) => {
    if (!name) return "";
    return name.split(" ")[0].charAt(0).toUpperCase() + name.split(" ")[0].slice(1);
  };
  
  // Function to handle mouse events for recipient tooltips
  const handleRecipientMouseEnter = (recipient, e) => {
    setHoveredRecipient(recipient);
    setMousePos({ x: e.clientX, y: e.clientY });
  };

  const handleRecipientMouseLeave = () => {
    setHoveredRecipient(null);
  };

  const handleRecipientMouseMove = (e) => {
    setMousePos({ x: e.clientX, y: e.clientY });
  };

  // Function to generate tooltip text for multiple recipients
  const generateMultipleRecipientsTooltip = (recipients) => {
    // Group recipients by type (to, cc, bcc)
    const groupedRecipients = recipients.reduce((acc, recipient) => {
      const type = recipient.recipient_type?.toLowerCase() || 'to';
      if (!acc[type]) acc[type] = [];
      acc[type].push(recipient);
      return acc;
    }, {});

    // Generate tooltip text for each group
    let tooltipText = '';
    
    if (groupedRecipients.to && groupedRecipients.to.length > 0) {
      tooltipText += 'TO:\n';
      groupedRecipients.to.forEach(r => {
        tooltipText += `• ${r.email}\n`;
      });
    }
    
    if (groupedRecipients.cc && groupedRecipients.cc.length > 0) {
      tooltipText += '\nCC:\n';
      groupedRecipients.cc.forEach(r => {
        tooltipText += `• ${r.email}\n`;
      });
    }
    
    if (groupedRecipients.bcc && groupedRecipients.bcc.length > 0) {
      tooltipText += '\nBCC:\n';
      groupedRecipients.bcc.forEach(r => {
        tooltipText += `• ${r.email}\n`;
      });
    }
    
    return tooltipText.trim();
  };

  // Get the appropriate icon based on task type and subtype
  const getTaskIcon = (row) => {
    const taskType = row.task_type?.toLowerCase() || '';
    
    // Return the appropriate icon based on task type
    if (taskType.includes('follow') && taskType === 'call') {
      return MdPhone;
    }
    return MdMail;
  };

  // Define the columns configuration
  const columns = [
    {
      key: "subject",
      label: "Task",
      width: "40%",
      minWidth: "350px",
      sortable: true,
      isFirst: true,
      render: (row) => {
        const formattedValue = removeDealFromSubject(row.subject);
        const truncatedValue = truncateText(formattedValue, 55);
        
        return (
          <HoverBox
            icon={getTaskIcon(row)}
            hoverIcon={FaExpandAlt}
            tooltipLabel="View Task Details"
            onClick={() => onViewDetails(row)}
          >
            <Text fontSize="md" isTruncated maxWidth="100%">
              <HighlightText text={truncatedValue} query={searchQuery} />
            </Text>
          </HoverBox>
        );
      },
    },
    {
      key: "deal_name",
      label: "Deal",
      width: "20%",
      minWidth: "180px",
      sortable: true,
      hasDot: false,
      render: (row) => {
        if (!row.deal_id) return <Text>-</Text>;
        
        return (
          <HoverBox
            icon={MdTrackChanges}
            hoverIcon={FaExpandAlt}
            tooltipLabel="View Deal Details"
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click event from firing
              navigate(`/admin/deals/${row.deal_id}`);
            }}
          >
            <Text fontSize="md" isTruncated maxWidth="100%">
              {truncateText(row.deal_name || "Unnamed Deal", 30)}
            </Text>
          </HoverBox>
        );
      },
    },
    {
      key: "email_recipients",
      label: "Recipients",
      width: "20%",
      minWidth: "180px",
      sortable: false,
      render: (row) => {
        const recipients = row.email_recipients || [];
        
        if (recipients.length === 0) return <Text>-</Text>;
        
        // Sort recipients: 'to' first, then 'cc', then 'bcc'
        const sortedRecipients = [...recipients].sort((a, b) => {
          const getTypePriority = (type) => {
            switch (type?.toLowerCase()) {
              case 'to': return 0;
              case 'cc': return 1;
              case 'bcc': return 2;
              default: return 3;
            }
          };
          return getTypePriority(a.recipient_type) - getTypePriority(b.recipient_type);
        });
        
        return (
          <HStack spacing={2} wrap="wrap">
            {sortedRecipients.slice(0, 3).map((recipient, index) => {
              const email = recipient.email;
              const recipientType = recipient.recipient_type?.toLowerCase() || 'to';
              const name = recipient.name || email?.split('@')[0];
              const displayName = capitalizeFirstName(name);
              
              // Create a composite recipient object for tooltip data
              const recipientData = {
                ...recipient,
                email,
                recipient_type: recipientType,
                displayName,
                tooltipText: `${recipientType.toUpperCase()} • ${email}`
              };
              
              return (
                <Box 
                  key={index}
                  onMouseEnter={(e) => handleRecipientMouseEnter(recipientData, e)}
                  onMouseLeave={handleRecipientMouseLeave}
                  onMouseMove={handleRecipientMouseMove}
                >
                  <Box
                    width="24px"
                    height="24px"
                    borderRadius="md"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="xs"
                    fontWeight="bold"
                    bg={
                      recipientType === 'to'
                        ? 'blue.50'
                        : recipientType === 'cc'
                        ? 'teal.50'
                        : recipientType === 'bcc'
                        ? 'purple.50'
                        : 'gray.50'
                    }
                    color={
                      recipientType === 'to'
                        ? 'blue.700'
                        : recipientType === 'cc'
                        ? 'teal.700'
                        : recipientType === 'bcc'
                        ? 'purple.700'
                        : 'gray.700'
                    }
                    borderWidth="1px"
                    borderColor={
                      recipientType === 'to'
                        ? 'blue.200'
                        : recipientType === 'cc'
                        ? 'teal.200'
                        : recipientType === 'bcc'
                        ? 'purple.200'
                        : 'gray.200'
                    }
                  >
                    {displayName.charAt(0).toUpperCase()}
                  </Box>
                </Box>
              );
            })}
            {recipients.length > 3 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg="gray.100"
                color="gray.600"
                fontWeight="bold"
                fontSize="xs"
                w="24px"
                h="24px"
                borderRadius="md"
                borderWidth="1px"
                borderColor="gray.200"
                onMouseEnter={(e) => {
                  // Create a composite object for the additional recipients
                  const additionalRecipients = sortedRecipients.slice(3);
                  handleRecipientMouseEnter({
                    isMultiple: true,
                    recipients: additionalRecipients,
                    tooltipText: generateMultipleRecipientsTooltip(additionalRecipients)
                  }, e);
                }}
                onMouseLeave={handleRecipientMouseLeave}
                onMouseMove={handleRecipientMouseMove}
                cursor="pointer"
              >
                +{recipients.length - 3}
              </Box>
            )}
          </HStack>
        );
      },
    },
    {
      key: "task_type",
      label: "Type",
      width: "20%",
      minWidth: "150px",
      sortable: true,
      hasDot: true,
      render: (row) => {
        const formattedTask = formatTask(row.task_type);
        const taskColorScheme = TASK_MAP[formattedTask]?.color || "gray";
        return (
          <Box display="flex" alignItems="center" fontSize="md" width="100%" overflow="hidden">
            <Box
              height="10px"
              width="10px"
              borderRadius="full"
              bg={`${taskColorScheme}.500`}
              mr={2}
              flexShrink={0}
            />
            <Text color="black" isTruncated maxWidth="calc(100% - 20px)" pr="8px">
              <HighlightText text={formattedTask} query={searchQuery} />
            </Text>
          </Box>
        );
      },
    },
    {
      key: "last_activity_date",
      label: "Last Activity",
      width: "15%",
      minWidth: "120px",
      sortable: true,
      format: (value) => value ? formatDistanceToNow(new Date(value), { addSuffix: true }) : "—",
    },
  ];

  // Get color for task indicators
  const getRowColor = (row, columnKey) => {
    if (columnKey === "task_type") {
      const formattedTask = formatTask(row.task_type);
      return `${TASK_MAP[formattedTask]?.color}.500` || "gray.500";
    }
    return "gray.500";
  };

  return (
    <>
      <DataTable
        data={inboxItems}
        columns={columns}
        onSort={onSort}
        sortConfig={sortConfig}
        searchQuery={searchQuery}
        onRowClick={onViewDetails}
        rowIcon={getTaskIcon}
        hoverIcon={FaExpandAlt}
        rowTooltip="View Task Details"
        getRowColor={getRowColor}
      />
      
      {hoveredRecipient && (
        <Portal>
          <Box
            position="fixed"
            left={mousePos.x + 10}
            top={mousePos.y + 10}
            bg={useColorModeValue("gray.700", "white")}
            color={useColorModeValue("white", "gray.700")}
            px="8px"
            py="4px"
            fontSize="sm"
            borderRadius="md"
            zIndex="tooltip"
            pointerEvents="none"
            boxShadow="sm"
            maxWidth="300px"
            whiteSpace={hoveredRecipient.isMultiple ? "pre-wrap" : "nowrap"}
          >
            {hoveredRecipient.tooltipText}
          </Box>
        </Portal>
      )}
    </>
  );
};

InboxTable.propTypes = {
  inboxItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(["ascending", "descending"]),
  }).isRequired,
  searchQuery: PropTypes.string,
};

export default InboxTable;