import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Box,
  List,
  ListItem,
  Icon,
  SimpleGrid,
  Spinner,
  Tooltip,
  Progress,
  HStack,
  Spacer,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  Collapse,
} from '@chakra-ui/react';
import { Search } from 'lucide-react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FaPlus, FaBuilding } from 'react-icons/fa';
import { useAuthData } from 'auth-context';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useCreateDeal from '../hooks/useCreateDeal';
import useCreateAccount from '../hooks/useCreateAccount';
import useFetchAccounts from '../hooks/useFetchAccounts';
import useFetchDeals from '../hooks/useFetchDeals';
import { useDebounce } from 'use-debounce';

const ServiceButton = ({ icon, title, description, isSelected, onClick, color }) => (
  <Button
    height="auto"
    minH="120px"  
    width="100%"
    onClick={onClick}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    gap={3}
    py={5}
    px={4}
    bg={isSelected ? `${color}.50` : "white"}
    border="1px solid"
    borderColor={isSelected ? `${color}.200` : "gray.200"}
    _hover={{
      bg: isSelected ? `${color}.50` : "gray.50",
      borderColor: isSelected ? `${color}.300` : "gray.300"
    }}
    transition="all 0.2s"
    rounded="lg"
    whiteSpace="normal"
  >
    <Icon as={icon} boxSize={5} color={isSelected ? `${color}.500` : "gray.400"} flexShrink={0} />
    <Box textAlign="center" width="100%">
      <Text
        color={isSelected ? `${color}.700` : "gray.700"}
        fontWeight="medium"
        fontSize="sm"
        mb={2}
      >
        {title}
      </Text>
      <Text
        color={isSelected ? `${color}.600` : "gray.500"}
        fontSize="xs"
        lineHeight="1.4"
        whiteSpace="normal"
        wordBreak="break-word"
      >
        {description}
      </Text>
    </Box>
  </Button>
);

ServiceButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired
};

const CreateDealModal = ({ isOpen, onClose, existingDeals = [] }) => {
  const { account_ID, getAccessToken } = useAuthData();
  const accountIdNumber = Number(account_ID);
  const priorityOptions = accountIdNumber === 7 ? ["PMS", "PM", "PO", "Ad-hoc", "Content", "Other"] : ["Standard", "VIP"];
  const toast = useToast();
  const createDealMutation = useCreateDeal();
  const { createAccount, isSubmitting: isCreatingAccount, errors: accountErrors, setErrors: setAccountErrors } = useCreateAccount();
  
  // Domain validation function
  const isValidDomain = (domain) => {
    if (!domain) return true; // Empty is valid (not required)
    // Simple domain validation regex
    const domainRegex = /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,}$/;
    // Allow URLs by extracting domain from URL
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
    
    return domainRegex.test(domain) || urlRegex.test(domain);
  };
  
  // Add domain validation state
  const [domainError, setDomainError] = useState('');
  
  // Multi-step states
  const [modalStep, setModalStep] = useState(0); // 0: Account Options, 1: Account Form (if new), 2: Deal Details, 3: Contact Information
  const [accountMode, setAccountMode] = useState(null); // 'new' or 'existing'
  
  // Account search state
  const [accountSearch, setAccountSearch] = useState('');
  const [debouncedAccountSearch] = useDebounce(accountSearch, 300);
  const [showAccountSuggestions, setShowAccountSuggestions] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  // Contact states
  const [currentContactIndex, setCurrentContactIndex] = useState(0);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [domainForContactSearch, setDomainForContactSearch] = useState('');
  const [hasAddedExistingContacts, setHasAddedExistingContacts] = useState(false);
  const [isFetchingContacts, setIsFetchingContacts] = useState(false);

  // Account form state
  const initialAccountFormState = {
    name: '',
    website: '',
    email: '',
    linkedin: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    address: '',
    size: '',
    type: 'Lead',
    description: '',
    industry: '',
    stage: 1,
  };
  const [accountFormData, setAccountFormData] = useState(initialAccountFormState);
  
  // Fetch accounts and deals
  const { accounts, isLoading: isLoadingAccounts } = useFetchAccounts(account_ID, 'Lead');
  const { deals: existingDealsFromAPI } = useFetchDeals(account_ID);
  
  // Filter accounts that don't have deals
  const accountsWithoutDeals = useMemo(() => {
    if (!accounts || !existingDealsFromAPI) return [];
    const accountsWithDeals = new Set(existingDealsFromAPI.map(deal => deal.crm_account_id));
    return accounts.filter(account => !accountsWithDeals.has(account.id));
  }, [accounts, existingDealsFromAPI]);

  // Filter accounts based on search
  const filteredAccounts = useMemo(() => {
    if (!debouncedAccountSearch) return accountsWithoutDeals;
    return accountsWithoutDeals.filter(account => 
      account.name?.toLowerCase().includes(debouncedAccountSearch.toLowerCase()) ||
      account.website_root?.toLowerCase().includes(debouncedAccountSearch.toLowerCase())
    );
  }, [accountsWithoutDeals, debouncedAccountSearch]);
  
  // Fetch users
  const { data: usersData } = useQuery({
    queryKey: ['users', account_ID],
    queryFn: async () => {
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      
      // Get access token for authorization
      const accessToken = await getAccessToken();
      
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      
      const response = await axios.get(
        `${target}/api/accounts/${account_ID}/get-users`,
        { headers }
      );
      return response.data;
    },
    enabled: !!account_ID,
  });

  // Form data state
  const [formData, setFormData] = useState({
    deal_name: '',
    lead_source: '',
    priority: '',
    pipeline_id: '',
    stage_id: '',
    stage_name: '',
    amount: '',
    close_date: '',
    probability: '',
    description: '',
    source_system: 'manual',
    source_deal_id: '',
    is_closed: false,
    is_won: false,
    type: '',
    extra_fields: {},
    user_id: null,
    user_name: '',
    user_last_name: '',
    user_email: '',
    contacts: [{ 
      firstName: '', 
      lastName: '', 
      email: '', 
      phone: '', 
      title: '', 
      type: '', 
      city: '', 
      state: '', 
      country: '', 
      linkedIn: '', 
      skipped: false 
    }]
  });

  // Fetch pipelines
  const { data: pipelines } = useQuery({
    queryKey: ['pipelines', account_ID],
    queryFn: async () => {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      const response = await axios.get(
        `${target}/api/accounts/${account_ID}/pipelines`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data.pipelines;
    },
    enabled: !!account_ID,
  });

  // Set default pipeline if there's only one
  React.useEffect(() => {
    if (pipelines?.length === 1) {
      setFormData(prev => ({
        ...prev,
        pipeline_id: pipelines[0].id
      }));
    }
  }, [pipelines]);

  // Fetch pipeline stages
  const { data: pipelineStages } = useQuery({
    queryKey: ['pipelineStages', account_ID, formData.pipeline_id],
    queryFn: async () => {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      const response = await axios.get(
        `${target}/api/accounts/${account_ID}/pipelines/${formData.pipeline_id}/stages`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data;
      return Array.isArray(data) ? data : (data.stages || []);
    },
    enabled: !!account_ID && !!formData.pipeline_id,
  });

  // Check if deal name already exists
  const isDealNameTaken = (name) => {
    return existingDeals.some(deal => 
      deal.deal_name?.toLowerCase() === name.toLowerCase()
    );
  };

  // Add refs for search container and suggestions
  const searchContainerRef = useRef(null);
  const suggestionListRef = useRef(null);

  // Function to handle search focus
  const handleSearchFocus = () => {
    setShowAccountSuggestions(true);
    // Use setTimeout to ensure the suggestions list is rendered before scrolling
    setTimeout(() => {
      if (searchContainerRef.current) {
        searchContainerRef.current.scrollIntoView({ 
          behavior: 'smooth', 
          block: 'center'
        });
      }
    }, 100);
  };

  const handleInputChange = (e, contactIndex) => {
    const { name, value, type, checked } = e.target;
    
    if (contactIndex !== undefined) {
      // Handle contact form fields
      setFormData(prev => {
        const updatedContacts = [...prev.contacts];
        if (name === 'firstName') {
          updatedContacts[contactIndex] = {
            ...updatedContacts[contactIndex],
            [name]: capitalizeName(value)
          };
        } else {
          updatedContacts[contactIndex] = {
            ...updatedContacts[contactIndex],
            [name]: value
          };
        }
        return {
          ...prev,
          contacts: updatedContacts
        };
      });
      return;
    }
    
    if (name === 'pipeline_id') {
      setFormData(prev => ({
        ...prev,
        [name]: parseInt(value),
        stage_id: '',
        stage_name: '',
      }));
    } else if (name === 'stage_id' && pipelineStages) {
      const numericValue = parseInt(value);
      const selectedStage = pipelineStages.find(stage => stage.id === numericValue);
      setFormData(prev => ({
        ...prev,
        [name]: numericValue,
        stage_name: selectedStage?.name || '',
        is_closed: selectedStage?.is_closed || false,
        is_won: selectedStage?.is_won || false,
      }));
    } else if (name === 'amount') {
      const numericValue = value.replace(/[^0-9.]/g, '');
      if (numericValue === '' || /^\d*\.?\d{0,2}$/.test(numericValue)) {
        setFormData(prev => ({
          ...prev,
          [name]: numericValue,
        }));
      }
    } else if (name === 'user_id') {
      const selectedUser = usersData?.users?.find(user => user.id === parseInt(value));
      setFormData(prev => ({
        ...prev,
        user_id: value ? parseInt(value) : null,
        user_name: selectedUser?.name || '',
        user_last_name: selectedUser?.last_name || '',
        user_email: selectedUser?.email || ''
      }));
    } else if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAccountInputChange = (e) => {
    const { name, value } = e.target;
    setAccountFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Domain validation for website field
    if (name === 'website') {
      if (value && !isValidDomain(value)) {
        setDomainError('Please enter a valid domain (e.g., example.com)');
      } else {
        setDomainError('');
        // Only set domain for contact search if it's valid
        setDomainForContactSearch(value);
      }
    }
    
    // Clear error when user starts typing
    if (accountErrors[name]) {
      setAccountErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleAccountNumberInputChange = (name, value) => {
    setAccountFormData(prev => ({
      ...prev,
      [name]: value === '' ? '' : value
    }));
    if (accountErrors[name]) {
      setAccountErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleNext = async () => {
    if (modalStep === 0) {
      if (!accountMode) {
        toast({
          title: 'Select account option',
          description: 'Please choose new or existing account',
          status: 'error',
          duration: 3000,
        });
        return;
      }
      if (accountMode === 'existing' && !selectedAccount) {
        toast({
          title: 'No account selected',
          description: 'Please choose an existing account',
          status: 'error',
          duration: 3000,
        });
        return;
      }
      if (accountMode === 'existing') {
        // Set default deal name to match account name when selecting existing account
        setFormData(prev => ({
          ...prev,
          deal_name: selectedAccount.name || ''
        }));
        setModalStep(2); // Skip account form for existing accounts
      } else {
        setModalStep(1); // Show account form for new accounts
      }
    } else if (modalStep === 1) {
      // Create new account
      if (!accountFormData.name) {
        toast({
          title: 'Account Name Required',
          description: 'Please enter a name for the account',
          status: 'error',
          duration: 3000,
        });
        return;
      }

      // Validate domain if provided
      if (accountFormData.website && !isValidDomain(accountFormData.website)) {
        toast({
          title: 'Invalid Domain',
          description: 'Please enter a valid domain format (e.g., example.com)',
          status: 'error',
          duration: 3000,
        });
        return;
      }

      const result = await createAccount(accountFormData);
      if (result.success) {
        setSelectedAccount(result.data);
        // Set default deal name to match the newly created account name
        setFormData(prev => ({
          ...prev,
          deal_name: result.data.name || accountFormData.name || ''
        }));
        setModalStep(2);
      } else {
        toast({
          title: 'Error creating account',
          description: result.error || 'Failed to create account',
          status: 'error',
          duration: 5000,
        });
      }
    } else if (modalStep === 2) {
      // Validate deal details
      if (!formData.deal_name) {
        toast({
          title: 'Deal Name Required',
          description: 'Please enter a name for this deal',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.pipeline_id) {
        toast({
          title: 'Pipeline Required',
          description: 'Please select a pipeline for this deal',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.stage_id) {
        toast({
          title: 'Stage Required',
          description: 'Please select a stage for this deal',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (isDealNameTaken(formData.deal_name)) {
        toast({
          title: 'Deal Name Already Exists',
          description: 'Please choose a different name for this deal',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      
      // Fetch contacts before moving to contacts step
      setHasAddedExistingContacts(false);
      
      // Only fetch if we have a valid domain to search for
      if (domainForContactSearch) {
        if (!isValidDomain(domainForContactSearch)) {
          toast({
            title: 'Invalid Domain',
            description: 'Please check the domain format before proceeding',
            status: 'error',
            duration: 3000,
          });
          return;
        }
        await fetchContactsForDomain();
      } else {
        // If no domain, make sure we have at least an empty contact form
        setFormData(prev => {
          // Only reset contacts if there are none or only default ones
          if (!prev.contacts.length || (prev.contacts.length === 1 && !prev.contacts[0].firstName && !prev.contacts[0].email)) {
            return {
              ...prev,
              contacts: [{ 
                firstName: '', 
                lastName: '', 
                email: '', 
                phone: '', 
                title: '', 
                type: '', 
                city: '', 
                state: '', 
                country: '', 
                linkedIn: '', 
                skipped: false,
                existing: false
              }]
            };
          }
          return prev;
        });
      }
      
      setModalStep(3); // Move to contact step
      setCurrentContactIndex(0); // Reset to first contact
    } else if (modalStep === 3) {
      // Validate current contact if not skipped
      const currentContact = formData.contacts[currentContactIndex];
      if (!currentContact.skipped && !currentContact.existing && (!currentContact.firstName || !currentContact.email)) {
        // Check if this is an empty contact form at the end
        const currentIsLastContact = currentContactIndex === formData.contacts.length - 1;
        const isEmpty = !currentContact.firstName && !currentContact.email && !currentContact.phone && !currentContact.title;
        
        // If it's the last contact and it's empty, we'll automatically skip it
        if (currentIsLastContact && isEmpty) {
          const updatedContacts = [...formData.contacts];
          updatedContacts[currentContactIndex] = {
            ...updatedContacts[currentContactIndex],
            skipped: true
          };
          setFormData(prev => ({
            ...prev,
            contacts: updatedContacts
          }));
          
          // Submit the form as this is the last contact
          handleSubmit();
          return;
        }
        
        // Otherwise, show an error for non-empty contacts with missing required fields
        if (!isEmpty) {
          toast({ 
            title: 'Required fields missing', 
            description: 'Please fill in all required fields for the current contact or skip this contact', 
            status: 'error', 
            duration: 3000 
          });
          return;
        }
      }

      // If there are more contacts to review, go to next contact
      if (currentContactIndex < formData.contacts.length - 1) {
        setCurrentContactIndex(prev => prev + 1);
        return;
      }

      // If we've reviewed all contacts, submit
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (modalStep === 3 && currentContactIndex > 0) {
      setCurrentContactIndex(prev => prev - 1);
    } else if (modalStep > 0) {
      setModalStep(prev => prev - 1);
    } else {
      onClose();
    }
  };

  const handleSubmit = async () => {
    // Validate required fields
    if (!selectedAccount) {
      toast({
        title: 'Account Required',
        description: 'Please select an account for this deal',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!formData.deal_name) {
      toast({
        title: 'Deal Name Required',
        description: 'Please enter a name for this deal',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!formData.pipeline_id) {
      toast({
        title: 'Pipeline Required',
        description: 'Please select a pipeline for this deal',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!formData.stage_id) {
      toast({
        title: 'Stage Required',
        description: 'Please select a stage for this deal',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (isDealNameTaken(formData.deal_name)) {
      toast({
        title: 'Deal Name Already Exists',
        description: 'Please choose a different name for this deal',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Send the request to the backend
      await createDealMutation.mutateAsync({
        accountId: account_ID,
        dealData: {
          deal: {
            crm_account_id: Number(selectedAccount.id),
            pipeline_id: formData.pipeline_id,
            stage_id: formData.stage_id,
            stage_name: formData.stage_name,
            deal_name: formData.deal_name,
            description: formData.description || null,
            amount: formData.amount === '' ? null : formData.amount,
            close_date: formData.close_date ? (() => {
              const date = new Date(formData.close_date);
              date.setHours(0, 0, 0, 0);
              return date.toISOString();
            })() : null,
            probability: formData.probability ? parseFloat(formData.probability) : null,
            lead_source: formData.lead_source || null,
            priority: formData.priority || null,
            source_system: 'manual',
            source_deal_id: '',
            is_closed: formData.is_closed || false,
            is_won: formData.is_won || false,
            user_id: formData.user_id || null,
            user_name: formData.user_name || null,
            user_last_name: formData.user_last_name || null,
            user_email: formData.user_email || null,
            company_email: selectedAccount.email || null,
            company_phone: selectedAccount.phone || null,
            company_city: selectedAccount.city || null,
            company_website: selectedAccount.website_root || null,
            extra_fields: formData.extra_fields || {},
          },
          contacts: formData.contacts
            .filter(contact => !contact.skipped)
            .map(contact => {
              if (!contact.firstName && !contact.email) return null;
              
              // Format contact to match SparkContact model with separate first_name and last_name
              const formattedContact = {
                name: contact.firstName || '',
                last_name: contact.lastName || '',
                email: contact.email,
              };
    
              // Add optional fields only if they exist
              if (contact.id && contact.existing) {
                formattedContact.id = contact.id;
              }
              
              if (contact.phone) {
                formattedContact.phone = contact.phone;
              }
              
              if (contact.title) {
                formattedContact.title = contact.title;
              }
              
              if (contact.type) {
                formattedContact.type = contact.type;
              }
              
              if (contact.city) {
                formattedContact.city = contact.city;
              }
              
              if (contact.state) {
                formattedContact.state = contact.state;
              }
              
              if (contact.country) {
                formattedContact.country = contact.country;
              }
              
              if (contact.linkedIn) {
                formattedContact.linkedin_url = contact.linkedIn;
              }
              
              return formattedContact;
            })
            .filter(Boolean) // Remove null entries
        },
      });

      // Success notification
      const contactsMessage = formData.contacts.length > 0 ? `with ${formData.contacts.length} contact${formData.contacts.length === 1 ? '' : 's'}` : '';
        
      toast({
        title: 'Deal Created',
        description: `The deal has been successfully created ${contactsMessage}.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Reset form data and close modal
      setFormData({
        deal_name: '',
        lead_source: '',
        priority: '',
        pipeline_id: '',
        stage_id: '',
        stage_name: '',
        amount: '',
        close_date: '',
        probability: '',
        description: '',
        source_system: 'manual',
        source_deal_id: '',
        is_closed: false,
        is_won: false,
        type: '',
        extra_fields: {},
        user_id: null,
        user_name: '',
        user_last_name: '',
        user_email: '',
        contacts: [{ 
          firstName: '', 
          lastName: '', 
          email: '', 
          phone: '', 
          title: '', 
          type: '', 
          city: '', 
          state: '', 
          country: '', 
          linkedIn: '', 
          skipped: false,
          existing: false
        }]
      });
      
      setAccountFormData(initialAccountFormState);
      setSelectedAccount(null);
      setAccountMode(null);
      setModalStep(0);
      setCurrentContactIndex(0);
      setHasAddedExistingContacts(false);
      setDomainForContactSearch('');

      onClose();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to create deal',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Helper function to capitalize names
  const capitalizeName = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Fetch contacts by domain - but keep it disabled by default
  const { refetch: refetchContacts } = useQuery({
    queryKey: ['contactsByDomain', account_ID, domainForContactSearch],
    queryFn: async () => {
      if (!domainForContactSearch) return { items: [] };
      
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      
      try {
        const response = await axios.get(
          `${target}/api/accounts/${account_ID}/contacts/by-domain?domain=${encodeURIComponent(domainForContactSearch)}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error('Error fetching contacts by domain:', error);
        return { items: [] };
      }
    },
    enabled: false, // Disabled by default - we'll manually trigger it
  });

  // Function to manually fetch contacts
  const fetchContactsForDomain = async () => {
    if (!domainForContactSearch) {
      return { items: [] };
    }
    
    // Validate domain before proceeding
    if (!isValidDomain(domainForContactSearch)) {
      toast({
        title: 'Invalid Domain',
        description: 'Please enter a valid domain before searching for contacts',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return { items: [] };
    }
    
    setIsFetchingContacts(true);
    try {
      const result = await refetchContacts();
      
      if (result.data?.items?.length > 0 && !hasAddedExistingContacts) {
        // Process contacts into our format
        const formattedContacts = result.data.items.map(contact => {
          // Split the name into first and last name
          const nameParts = (contact.name || '').split(' ');
          const firstName = nameParts[0] || '';
          const lastName = nameParts.slice(1).join(' ');
          
          return {
            id: contact.id, // Keep the original ID for reference
            firstName,
            lastName,
            email: contact.email || '',
            phone: contact.phone || '',
            title: contact.title || '',
            type: contact.type || '',
            city: contact.city || '',
            state: contact.state || '',
            country: contact.country || '',
            linkedIn: contact.linkedin || '',
            skipped: false,
            existing: true, // Mark as existing contact
          };
        });

        // Update the form data with these contacts (without adding empty contact)
        setFormData(prev => ({
          ...prev,
          contacts: formattedContacts
        }));
        
        // Mark that we've added these contacts
        setHasAddedExistingContacts(true);
        
        // Show a toast notification
        if (formattedContacts.length > 0) {
          toast({
            title: 'Contacts Found',
            description: `Found ${formattedContacts.length} existing contacts for this domain`,
            status: 'info',
            duration: 5000,
            isClosable: true,
          });
        }
      } else if (result.data?.items?.length === 0) {
        // If no contacts found, add one empty contact form for user to fill
        setFormData(prev => ({
          ...prev,
          contacts: [{ 
            firstName: '', 
            lastName: '', 
            email: '', 
            phone: '', 
            title: '', 
            type: '', 
            city: '', 
            state: '', 
            country: '', 
            linkedIn: '', 
            skipped: false,
            existing: false
          }]
        }));
      }
      
      return result.data;
    } catch (error) {
      console.error("Error fetching contacts:", error);
      return { items: [] };
    } finally {
      setIsFetchingContacts(false);
    }
  };

  // Reset contacts when account mode changes
  useEffect(() => {
    setHasAddedExistingContacts(false);
    setFormData(prev => ({
      ...prev,
      contacts: [{ 
        firstName: '', 
        lastName: '', 
        email: '', 
        phone: '', 
        title: '', 
        type: '', 
        city: '', 
        state: '', 
        country: '', 
        linkedIn: '', 
        skipped: false,
        existing: false
      }]
    }));
  }, [accountMode]);

  // Update domainForContactSearch when an existing account is selected
  useEffect(() => {
    if (selectedAccount && accountMode === 'existing') {
      const domain = selectedAccount.website_root || '';
      if (domain && !isValidDomain(domain)) {
        toast({
          title: 'Invalid Domain',
          description: 'The selected account has an invalid domain format. Contact fetching may not work properly.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }
      setDomainForContactSearch(domain);
    }
  }, [selectedAccount, accountMode]);

  const renderAccountStep = () => (
    <VStack spacing={6} align="stretch">
      <Text fontSize="sm" color="gray.600">Would you like to create a new account or use an existing one?</Text>
      <SimpleGrid columns={2} spacing={4}>
        <ServiceButton
          icon={FaPlus}
          title="New Account"
          description="Create a new account for this deal"
          isSelected={accountMode === 'new'}
          onClick={() => setAccountMode('new')}
          color="blue"
        />
        <ServiceButton
          icon={FaBuilding}
          title="Existing Account"
          description="Select an existing account without deals"
          isSelected={accountMode === 'existing'}
          onClick={() => setAccountMode('existing')}
          color="purple"
        />
      </SimpleGrid>
      {accountMode === 'existing' && (
        <FormControl>
          <FormLabel>Search Accounts</FormLabel>
          <Box position="relative" ref={searchContainerRef}>
            <InputGroup>
              <Input
                value={accountSearch}
                onChange={(e) => { setAccountSearch(e.target.value); setShowAccountSuggestions(true); }}
                placeholder="Search by account name or domain"
                onFocus={handleSearchFocus}
              />
              <InputRightElement>
                {isLoadingAccounts ? <Spinner size="sm" color="gray.400" /> : <Icon as={Search} color="gray.500" />}
              </InputRightElement>
            </InputGroup>
            {showAccountSuggestions && (
              <List
                ref={suggestionListRef}
                position="absolute"
                top="100%"
                left={0}
                right={0}
                bg="white"
                boxShadow="lg"
                borderRadius="md"
                maxH="200px"
                overflowY="auto"
                zIndex={2000}
                border="1px solid"
                borderColor="gray.200"
                mt={1}
              >
                {isLoadingAccounts ? (
                  <ListItem p={4} textAlign="center"><Spinner size="sm" /></ListItem>
                ) : filteredAccounts.length === 0 ? (
                  <ListItem p={4} color="gray.500">
                    {accountSearch 
                      ? "No accounts found without deals" 
                      : "Type to search accounts (only showing accounts without deals)"}
                  </ListItem>
                ) : (
                  filteredAccounts.map((account) => (
                    <ListItem
                      key={account.id}
                      p={3}
                      cursor="pointer"
                      _hover={{ bg: "gray.50" }}
                      onClick={() => {
                        setSelectedAccount(account);
                        setAccountSearch(account.name);
                        setShowAccountSuggestions(false);
                      }}
                      transition="background-color 0.2s"
                    >
                      <Text fontWeight="medium">{account.name}</Text>
                      {account.website_root && (
                        <Text fontSize="sm" color="gray.500">
                          {account.website_root}
                        </Text>
                      )}
                    </ListItem>
                  ))
                )}
              </List>
            )}
          </Box>
          {selectedAccount && (
            <Text fontSize="sm" color="gray.600" mt={1}>
              Selected: {selectedAccount.name}
            </Text>
          )}
        </FormControl>
      )}
    </VStack>
  );

  const renderAccountForm = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>
        Enter the details for the new account.
      </Text>

      {/* Required Fields Section */}
      <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
        <Text fontSize="sm" fontWeight="medium" mb={4}>
          Required Information
        </Text>
        <VStack spacing={4}>
          <FormControl isRequired isInvalid={!!accountErrors.name}>
            <FormLabel>Account Name</FormLabel>
            <Input
              name="name"
              value={accountFormData.name}
              onChange={handleAccountInputChange}
              placeholder="Enter account name"
            />
            <FormErrorMessage>{accountErrors.name}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!accountErrors.website || !!domainError}>
            <FormLabel>Company Domain (for associated contacts)</FormLabel>
            <Input
              name="website"
              value={accountFormData.website}
              onChange={handleAccountInputChange}
              placeholder="Enter company domain (e.g., example.com)"
            />
            <FormErrorMessage>{accountErrors.website || domainError}</FormErrorMessage>
          </FormControl>
        </VStack>
      </Box>

      {/* Contact Information Section */}
      <Box borderWidth="1px" borderRadius="lg" p={4}>
        <Text fontSize="sm" fontWeight="medium" mb={4}>
          Contact Information
        </Text>
        <VStack spacing={4}>
          <SimpleGrid columns={2} spacing={4} width="100%">
            <FormControl isInvalid={!!accountErrors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                value={accountFormData.email}
                onChange={handleAccountInputChange}
                placeholder="Enter email"
              />
              <FormErrorMessage>{accountErrors.email}</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                name="type"
                value={accountFormData.type}
                onChange={handleAccountInputChange}
              >
                <option value="Lead">Lead</option>
                <option value="Customer">Customer</option>
                <option value="Partner">Partner</option>
                <option value="Prospect">Prospect</option>
                <option value="Other">Other</option>
              </Select>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={2} spacing={4} width="100%">
            <FormControl>
              <FormLabel>Phone</FormLabel>
              <Input
                name="phone"
                value={accountFormData.phone}
                onChange={handleAccountInputChange}
                placeholder="Enter phone number"
              />
            </FormControl>

            <FormControl>
              <FormLabel>LinkedIn</FormLabel>
              <Input
                name="linkedin"
                value={accountFormData.linkedin}
                onChange={handleAccountInputChange}
                placeholder="Enter LinkedIn URL"
              />
            </FormControl>
          </SimpleGrid>
        </VStack>
      </Box>

      {/* Location Information Section */}
      <Box borderWidth="1px" borderRadius="lg" p={4}>
        <Text fontSize="sm" fontWeight="medium" mb={4}>
          Location Information
        </Text>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Address</FormLabel>
            <Input
              name="address"
              value={accountFormData.address}
              onChange={handleAccountInputChange}
              placeholder="Enter address"
            />
          </FormControl>

          <SimpleGrid columns={3} spacing={4} width="100%">
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input
                name="city"
                value={accountFormData.city}
                onChange={handleAccountInputChange}
                placeholder="Enter city"
              />
            </FormControl>

            <FormControl>
              <FormLabel>State</FormLabel>
              <Input
                name="state"
                value={accountFormData.state}
                onChange={handleAccountInputChange}
                placeholder="Enter state"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Country</FormLabel>
              <Input
                name="country"
                value={accountFormData.country}
                onChange={handleAccountInputChange}
                placeholder="Enter country"
              />
            </FormControl>
          </SimpleGrid>

          <FormControl isInvalid={!!accountErrors.size}>
            <FormLabel>Company Size</FormLabel>
            <NumberInput
              value={accountFormData.size}
              onChange={(value) => handleAccountNumberInputChange('size', value)}
              min={0}
            >
              <NumberInputField placeholder="Enter company size" />
            </NumberInput>
            <FormErrorMessage>{accountErrors.size}</FormErrorMessage>
          </FormControl>
        </VStack>
      </Box>
    </VStack>
  );

  const renderDealForm = () => (
    <VStack spacing={4} align="stretch">
      <FormControl isRequired>
        <FormLabel>Deal Name</FormLabel>
        <Input
          name="deal_name"
          value={formData.deal_name}
          onChange={handleInputChange}
          placeholder="Enter deal name"
        />
        {formData.deal_name && (
          <Text fontSize="xs" color="gray.500" mt={1}>
            Pre-filled with account name. You can modify it if needed.
          </Text>
        )}
      </FormControl>

      {pipelines?.length > 1 && (
        <FormControl isRequired>
          <FormLabel>Pipeline</FormLabel>
          <Select
            name="pipeline_id"
            value={formData.pipeline_id}
            onChange={handleInputChange}
            placeholder="Select pipeline"
          >
            {pipelines?.map((pipeline) => (
              <option key={pipeline.id} value={pipeline.id}>
                {pipeline.label}
              </option>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl isRequired>
        <FormLabel>Stage</FormLabel>
        <Tooltip
          hasArrow
          label="Please select a pipeline first"
          isDisabled={!!formData.pipeline_id}
          placement="top"
        >
          <Select
            name="stage_id"
            value={formData.stage_id}
            onChange={handleInputChange}
            placeholder="Select stage"
            isDisabled={!formData.pipeline_id}
          >
            {pipelineStages?.map((stage) => (
              <option key={stage.id} value={stage.id}>
                {stage.name}
              </option>
            ))}
          </Select>
        </Tooltip>
      </FormControl>

      <FormControl>
        <FormLabel>Deal Owner</FormLabel>
        <Select
          name="user_id"
          value={formData.user_id || ''}
          onChange={handleInputChange}
          placeholder="Select owner"
        >
          <option value="">Unassigned</option>
          {usersData?.users?.map((user) => (
            <option key={user.id} value={user.id}>
              {`${user.name || ''} ${user.last_name || ''}`.trim() || user.email}
            </option>
          ))}
        </Select>
      </FormControl>

      <SimpleGrid columns={2} spacing={4}>
        <FormControl>
          <FormLabel>Amount</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none" color="gray.500">
              $
            </InputLeftElement>
            <Input
              name="amount"
              type="text"
              value={formData.amount}
              onChange={handleInputChange}
              placeholder="Enter amount"
            />
          </InputGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Close Date</FormLabel>
          <Input
            name="close_date"
            type="date"
            value={formData.close_date}
            onChange={handleInputChange}
          />
        </FormControl>
      </SimpleGrid>

      <FormControl>
        <FormLabel>Priority</FormLabel>
        <Select
          name="priority"
          value={formData.priority}
          onChange={handleInputChange}
          placeholder="Select priority"
        >
          {priorityOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Lead Source</FormLabel>
        <Select
          name="lead_source"
          value={formData.lead_source}
          onChange={handleInputChange}
          placeholder="Select lead source"
        >
          <option value="Direct Contact">Direct Contact</option>
          <option value="Email">Email</option>
          <option value="Website">Website</option>
          <option value="Referral">Referral</option>
          <option value="Other">Other</option>
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Description</FormLabel>
        <Input
          name="description"
          as="textarea"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Enter deal description"
          minH="100px"
          pt={3}
          px={3}
          _placeholder={{ pt: 0 }}
        />
      </FormControl>
    </VStack>
  );

  const renderContactStep = () => {
    const totalContacts = formData.contacts?.length || 0;
    
    // If we're loading contacts, show a spinner
    if (isFetchingContacts) {
      return (
        <VStack spacing={4} align="center" justify="center" py={8}>
          <Spinner size="lg" color="blue.500" />
          <Text>Looking for contacts associated with this domain...</Text>
        </VStack>
      );
    }
    
    if (totalContacts === 0) {
      // No contacts yet, show a message and add contact button
      return (
        <VStack spacing={6} align="center" justify="center" py={8}>
          <Text color="gray.600">No contacts added yet</Text>
          <Button
            leftIcon={<FaPlus />}
            colorScheme="blue"
            onClick={() => {
              // Add a new empty contact form
              setFormData(prev => ({
                ...prev,
                contacts: [{ 
                  firstName: '', 
                  lastName: '', 
                  email: '', 
                  phone: '', 
                  title: '', 
                  type: '', 
                  city: '', 
                  state: '', 
                  country: '', 
                  linkedIn: '', 
                  skipped: false,
                  existing: false
                }]
              }));
              setCurrentContactIndex(0);
            }}
          >
            Add Contact
          </Button>
        </VStack>
      );
    }

    // Add safety check for currentContactIndex
    if (currentContactIndex >= totalContacts) {
      setCurrentContactIndex(0);
      return null;
    }

    const currentContact = formData.contacts[currentContactIndex];
    if (!currentContact) return null;
    
    return (
      <VStack spacing={4} align="stretch">
        <HStack justify="space-between" align="center" mb={2}>
          <Text fontSize="md" fontWeight="medium">
            Contact {currentContactIndex + 1} of {totalContacts}
          </Text>
          <Text 
            fontSize="sm" 
            color={currentContact.skipped ? "red.500" : "green.500"}
            fontWeight="medium"
          >
            {currentContact.skipped ? "Skipped" : "Included"}
          </Text>
        </HStack>

        {currentContact.existing && (
          <Box p={3} bg="blue.50" borderRadius="md" borderWidth="1px" borderColor="blue.200">
            <HStack mb={1}>
              <Icon as={FaBuilding} color="blue.500" />
              <Text fontWeight="medium" color="blue.700">Existing Contact</Text>
            </HStack>
            <Text fontSize="sm" color="blue.600">
              You can edit this contact&apos;s information if needed.
            </Text>
          </Box>
        )}

        {renderContactForm(currentContactIndex)}

        <HStack justify="center" pt={4}>
          <Button
            leftIcon={<FaPlus />}
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={() => {
              // Add a new empty contact form
              const newContact = { 
                firstName: '', 
                lastName: '', 
                email: '', 
                phone: '', 
                title: '', 
                type: '', 
                city: '', 
                state: '', 
                country: '', 
                linkedIn: '', 
                skipped: false,
                existing: false
              };
              
              // Create a copy of the contacts array
              const updatedContacts = [...formData.contacts];
              
              // Insert the new contact after the current position
              updatedContacts.splice(currentContactIndex + 1, 0, newContact);
              
              setFormData(prev => ({
                ...prev,
                contacts: updatedContacts
              }));
              
              // Move to the newly added contact
              setCurrentContactIndex(currentContactIndex + 1);
            }}
          >
            Add New Contact
          </Button>
        </HStack>
      </VStack>
    );
  };

  const renderContactForm = (contactIndex) => {
    const contact = formData.contacts[contactIndex] || { 
      firstName: '', 
      lastName: '', 
      email: '', 
      phone: '', 
      title: '', 
      type: '', 
      city: '', 
      state: '', 
      country: '', 
      linkedIn: '', 
      skipped: false,
      existing: false
    };

    return (
      <VStack spacing={4} align="stretch" key={contactIndex}>
        <Text fontSize="sm" color="gray.600">
          {contact.existing 
            ? `Contact information from ${domainForContactSearch}` 
            : "Enter contact information"}
        </Text>
        
        <FormControl isRequired>
          <FormLabel>First Name</FormLabel>
          <Input 
            name="firstName" 
            value={contact.firstName} 
            onChange={(e) => handleInputChange(e, contactIndex)} 
            placeholder="First name"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input 
            name="lastName" 
            value={contact.lastName} 
            onChange={(e) => handleInputChange(e, contactIndex)} 
            placeholder="Last name"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input 
            name="email" 
            type="email" 
            value={contact.email} 
            onChange={(e) => handleInputChange(e, contactIndex)} 
            placeholder="contact@example.com"
          />
        </FormControl>
        <Button 
          rightIcon={showAdditionalFields ? <ChevronUpIcon /> : <ChevronDownIcon />} 
          onClick={() => setShowAdditionalFields(!showAdditionalFields)} 
          variant="ghost" 
          size="sm" 
          width="fit-content"
        >
          {showAdditionalFields ? 'Hide' : 'Show'} Additional Fields
        </Button>
        <Collapse in={showAdditionalFields}>
          <VStack spacing={4} align="stretch" mt={4}>
            <FormControl>
              <FormLabel>Phone</FormLabel>
              <Input 
                name="phone" 
                value={contact.phone || ''} 
                onChange={(e) => handleInputChange(e, contactIndex)} 
                placeholder="Phone number"
                bg={contact.existing ? "gray.50" : "white"}
                isReadOnly={contact.existing}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input 
                name="title" 
                value={contact.title || ''} 
                onChange={(e) => handleInputChange(e, contactIndex)} 
                placeholder="Job title"
                bg={contact.existing ? "gray.50" : "white"}
                isReadOnly={contact.existing}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select 
                name="type" 
                value={contact.type || ''} 
                onChange={(e) => handleInputChange(e, contactIndex)} 
                placeholder="Select type"
                bg={contact.existing ? "gray.50" : "white"}
                isDisabled={contact.existing}
              >
                <option value="sales">Sales</option>
                <option value="marketing">Marketing</option>
                <option value="engineering">Engineering</option>
                <option value="finance">Finance</option>
                <option value="operations">Operations</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>LinkedIn Profile</FormLabel>
              <Input 
                name="linkedIn" 
                value={contact.linkedIn || ''} 
                onChange={(e) => handleInputChange(e, contactIndex)} 
                placeholder="LinkedIn profile URL"
                bg={contact.existing ? "gray.50" : "white"}
                isReadOnly={contact.existing}
              />
            </FormControl>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input 
                name="city" 
                value={contact.city || ''} 
                onChange={(e) => handleInputChange(e, contactIndex)} 
                placeholder="City"
                bg={contact.existing ? "gray.50" : "white"}
                isReadOnly={contact.existing}
              />
            </FormControl>
            <FormControl>
              <FormLabel>State / Province / Region</FormLabel>
              <Input 
                name="state" 
                value={contact.state || ''} 
                onChange={(e) => handleInputChange(e, contactIndex)} 
                placeholder="Enter state, province, or region"
                bg={contact.existing ? "gray.50" : "white"}
                isReadOnly={contact.existing}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Country</FormLabel>
              <Input 
                name="country" 
                value={contact.country || ''} 
                onChange={(e) => handleInputChange(e, contactIndex)} 
                placeholder="Country"
                bg={contact.existing ? "gray.50" : "white"}
                isReadOnly={contact.existing}
              />
            </FormControl>
          </VStack>
        </Collapse>
      </VStack>
    );
  };

  const renderStepContent = () => {
    if (modalStep === 0) return renderAccountStep();
    if (modalStep === 1) return renderAccountForm();
    if (modalStep === 2) return renderDealForm();
    if (modalStep === 3) return renderContactStep();
    return null;
  };

  const getStepTitle = () => {
    if (modalStep === 0) return "Account Options";
    if (modalStep === 1) return "Create New Account";
    if (modalStep === 2) return "Deal Details";
    if (modalStep === 3) return "Contact Information";
    return "";
  };

  const getProgressValue = () => {
    if (accountMode === 'new') {
      if (modalStep === 3) {
        // Calculate progress based on current contact and total contacts
        const totalContacts = formData.contacts?.length || 1;
        const contactProgress = (currentContactIndex + 1) / totalContacts;
        return 75 + (contactProgress * 25);
      }
      return (modalStep + 1) * 25;
    }
    
    if (modalStep === 3) {
      // Calculate progress for existing account path
      const totalContacts = formData.contacts?.length || 1;
      const contactProgress = (currentContactIndex + 1) / totalContacts;
      return 66.66 + (contactProgress * 33.33);
    }
    
    return (modalStep === 0 ? 33.33 : (modalStep === 2 ? 66.66 : 100));
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="xl"
      closeOnOverlayClick={!createDealMutation.isLoading && !isCreatingAccount}
      closeOnEsc={!createDealMutation.isLoading && !isCreatingAccount}
    >
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>
          <Text>{getStepTitle()}</Text>
          <Progress 
            value={getProgressValue()} 
            size="sm" 
            colorScheme="blue" 
            borderRadius="full" 
            width="100%"
            mt={4}
          />
        </ModalHeader>
        <ModalBody>
          {renderStepContent()}
        </ModalBody>
        <ModalFooter 
          borderTop="1px" 
          borderColor="gray.100"
        >
          <HStack width="100%" spacing={2}>
            <Button 
              variant="ghost" 
              onClick={onClose} 
              isDisabled={createDealMutation.isLoading || isCreatingAccount}
            >
              Cancel
            </Button>
            
            {modalStep > 0 && (
              <Button 
                onClick={handleBack} 
                isDisabled={createDealMutation.isLoading || isCreatingAccount}
              >
                Back
              </Button>
            )}

            <Spacer />
            
            {modalStep === 3 && (
              <Button
                variant={formData.contacts[currentContactIndex]?.skipped ? "solid" : "outline"}
                onClick={() => {
                  const updatedContacts = [...formData.contacts];
                  updatedContacts[currentContactIndex] = {
                    ...updatedContacts[currentContactIndex],
                    skipped: !updatedContacts[currentContactIndex].skipped
                  };
                  setFormData(prev => ({
                    ...prev,
                    contacts: updatedContacts
                  }));
                }}
                colorScheme="red"
                isDisabled={createDealMutation.isLoading || isCreatingAccount}
              >
                {formData.contacts[currentContactIndex]?.skipped ? "Include Contact" : "Skip Contact"}
              </Button>
            )}
            
            <Button 
              colorScheme="blue" 
              onClick={handleNext} 
              isLoading={
                (modalStep === 1 && isCreatingAccount) || 
                (modalStep === 3 && currentContactIndex === formData.contacts.length - 1 && createDealMutation.isLoading)
              }
              loadingText={modalStep === 1 ? "Creating Account" : (modalStep === 3 ? "Creating Deal" : "Next")}
              isDisabled={
                createDealMutation.isLoading ||
                isCreatingAccount ||
                (modalStep === 0 && accountMode === 'existing' && !selectedAccount)
              }
            >
              {modalStep === 3 
                ? (currentContactIndex === formData.contacts.length - 1 ? 'Create Deal' : 'Next Contact')
                : (modalStep === 2 ? 'Next' : (modalStep === 1 ? 'Next' : 'Next'))}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

CreateDealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  existingDeals: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    deal_name: PropTypes.string,
  })),
};

export default CreateDealModal; 