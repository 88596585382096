import React, { useCallback } from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { MdBusiness } from "react-icons/md";
import { FaExpandAlt, FaGlobe } from "react-icons/fa";
import { ExternalLink } from 'lucide-react';
import PropTypes from "prop-types";
import DataTable from "../Shared/DataTable";
import { formatDistanceToNow } from "date-fns";
import HighlightText from "../Shared/HighlightText";
import HoverBox from "../Shared/HoverBox";
import { Portal } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";

// Status color mapping
const statusColorMap = {
  active: "blue.500",
  inactive: "gray.500"
};

const AccountsTable = ({ accounts, onViewDetails, onSort, sortConfig, searchQuery }) => {
  // Add website renderer
  const renderWebsite = useCallback((row) => {
    const hasWebsite = row.website_root || row.website;
    
    // Custom HoverBox for website with teal color scheme
    const WebsiteHoverBox = ({ children, icon, hoverIcon, onClick, tooltipLabel }) => {
      const iconColor = useColorModeValue("teal.700", "teal.300");
      const hoverIconColor = useColorModeValue("teal.800", "teal.400");
      const iconBgColor = useColorModeValue("teal.50", "teal.700");
      const hoverIconBgColor = useColorModeValue("teal.100", "teal.800");
      const tooltipBg = useColorModeValue("gray.700", "white");
      const tooltipTextColor = useColorModeValue("white", "gray.700");
      
      const [isHovered, setIsHovered] = React.useState(false);
      const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 });
      
      const handleMouseEnter = () => setIsHovered(true);
      const handleMouseLeave = () => setIsHovered(false);
      const handleMouseMove = (e) => setMousePos({ x: e.clientX, y: e.clientY });
      
      return (
        <>
          <Box
            display="inline-flex"
            alignItems="center"
            borderRadius="lg"
            cursor="pointer"
            position="relative"
            transition="all 0.2s"
            role="group"
            width="100%"
            height="24px"
            _hover={{
              "& .default-icon": { opacity: 0 },
              "& .hover-icon": { opacity: 1 },
            }}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
          >
            {icon && (
              <Box
                className="default-icon"
                mr={2}
                position="absolute"
                transition="opacity 0.2s"
                opacity={1}
                flexShrink={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="md"
                bg={iconBgColor}
                width="24px"
                height="24px"
                left={0}
                top="50%"
                transform="translateY(-50%)"
                overflow="hidden"
                borderWidth="1px"
                borderColor="teal.200"
              >
                <Icon as={icon} color={iconColor} boxSize="16px" />
              </Box>
            )}
            {hoverIcon && (
              <Box
                className="hover-icon"
                mr={2}
                position="absolute"
                transition="opacity 0.2s"
                opacity={0}
                flexShrink={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="md"
                bg={hoverIconBgColor}
                width="24px"
                height="24px"
                left={0}
                top="50%"
                transform="translateY(-50%)"
                overflow="hidden"
                borderWidth="1px"
                borderColor="teal.300"
              >
                <Icon as={hoverIcon} color={hoverIconColor} boxSize="16px" />
              </Box>
            )}
            <Box
              as="span"
              display="flex"
              alignItems="center"
              textAlign="left"
              width="100%"
              pl={icon ? "32px" : "0"}
              pr="4px"
              height="100%"
            >
              {children}
            </Box>
          </Box>
          {isHovered && tooltipLabel && (
            <Portal>
              <Box
                position="fixed"
                left={mousePos.x + 10}
                top={mousePos.y + 10}
                bg={tooltipBg}
                color={tooltipTextColor}
                px="8px"
                py="4px"
                fontSize="sm"
                borderRadius="md"
                zIndex="tooltip"
                pointerEvents="none"
                boxShadow="sm"
              >
                {tooltipLabel}
              </Box>
            </Portal>
          )}
        </>
      );
    };

    if (hasWebsite) {
      const websiteUrl = row.website?.includes('http') ? 
        row.website : 
        (row.website_root ? `http://${row.website_root}` : null);
      
      if (websiteUrl) {
        return (
          <WebsiteHoverBox
            icon={FaGlobe}
            hoverIcon={ExternalLink}
            onClick={() => window.open(websiteUrl, '_blank', 'noopener,noreferrer')}
            tooltipLabel="Open Website"
          >
            <Text>{(row.website_root || row.website).replace(/^https?:\/\//i, '')}</Text>
          </WebsiteHoverBox>
        );
      }
    }

    return <Text color="gray.500">No website</Text>;
  }, []);

  // Define the columns configuration
  const columns = [
    {
      key: "name",
      label: "Name",
      width: "250px",
      sortable: true,
      isFirst: true,
      render: (row) => (
        <HoverBox
          icon={MdBusiness}
          hoverIcon={FaExpandAlt}
          onClick={() => onViewDetails(row)}
          tooltipLabel="View Account Details"
        >
          <HighlightText
            text={row.name || "—"}
            query={searchQuery}
          />
        </HoverBox>
      ),
    },
    {
      key: "website_root",
      label: "Website",
      width: "250px",
      sortable: true,
      render: renderWebsite
    },
    {
      key: "city",
      label: "City",
      width: "180px",
      sortable: true,
      render: (row) => (
        <Text color="black" isTruncated maxWidth="100%" fontSize="md">
          <HighlightText
            text={row.city || "—"}
            query={searchQuery}
          />
        </Text>
      ),
    },
    {
      key: "isActive",
      label: "Status",
      width: "150px",
      sortable: true,
      hasDot: true,
      render: (row) => (
        <Box display="flex" alignItems="center" fontSize="md">
          <Box
            height="10px"
            width="10px"
            borderRadius="full"
            bg={statusColorMap[row.isActive ? 'active' : 'inactive']}
            mr={2}
          />
          <Text color="black" isTruncated maxWidth="100%">
            <HighlightText
              text={row.isActive ? "Active" : "Inactive"}
              query={searchQuery}
            />
          </Text>
        </Box>
      ),
    },
    {
      key: "last_activity_date",
      label: "Last Activity",
      width: "200px",
      sortable: true,
      format: (value) =>
        value ? formatDistanceToNow(new Date(value), { addSuffix: true }) : "—",
    },
  ];

  // Get color for status indicators
  const getRowColor = (row, columnKey) => {
    if (columnKey === "isActive") {
      return statusColorMap[row.isActive ? 'active' : 'inactive'];
    }
    return "gray.400";
  };

  // Function to get row icon component
  const getRowIcon = () => MdBusiness;

  return (
    <Box p={4} bg="white" borderRadius="lg" shadow="sm">
      <DataTable
        data={accounts}
        columns={columns}
        onSort={onSort}
        sortConfig={sortConfig}
        searchQuery={searchQuery}
        onRowClick={onViewDetails}
        rowIcon={getRowIcon}
        hoverIcon={FaExpandAlt}
        rowTooltip="View Account Details"
        getRowColor={getRowColor}
      />
    </Box>
  );
};

AccountsTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(["ascending", "descending"]),
  }).isRequired,
  searchQuery: PropTypes.string,
};

AccountsTable.defaultProps = {
  searchQuery: "",
};

export default AccountsTable;