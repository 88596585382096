// src/views/lysto/Spark/hooks/useFetchSparks.js

import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";
import { useState, useEffect } from "react";

export function useFetchSparks(
  accountID,
  userID,
  pageNumber = 1,
  itemsPerPage = 2, // Now fetch just 2 items per page for immediate display
  filter = "all",
  searchQuery = "",
  pipelineId = null
) {
  const { getAccessToken } = useAuthData();
  const queryClient = useQueryClient(); // Add query client to prefetch next page
  const [actualTotalCount, setActualTotalCount] = useState(0);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  // Monitor pipeline changes specifically
  useEffect(() => {
    // Pipeline selection change effect
  }, [pipelineId]);

  // Monitor page changes
  useEffect(() => {
    // Page number change effect
  }, [pageNumber]);

  const fetchSparks = async (page = pageNumber) => {
    if (!accountID || !userID) {
      return { items: [], has_more: false, total_count: 0 };
    }

    const accessToken = await getAccessToken();
    const target =
      process.env.REACT_APP_API_BASE_URL ||
      "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

    // Determine which endpoint to use based on whether there's a search query
    const endpoint = searchQuery.trim()
      ? `/api/accounts/${accountID}/users/${userID}/spark/search`
      : `/api/accounts/${accountID}/users/${userID}/spark`;

    const params = {
      page_number: page,
      items_per_page: itemsPerPage,
      filter: filter.toLowerCase(),
      sort_by: filter === "all" ? "chronological" : "score",
      is_archived: false,
      is_blocked: false,
    };

    // Add query param if searching
    if (searchQuery.trim()) {
      params.query = searchQuery.trim();
    }

    // Only add pipeline_id to params if it has a valid value
    // This prevents sending null or undefined which might cause API issues
    if (pipelineId !== null && pipelineId !== undefined) {
      params.pipeline_id = pipelineId;
    }

    const response = await axios.get(`${target}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    // Calculate the total items we've seen so far
    const receivedItemsCount = response.data.items?.length || 0;
    const calculatedTotalSoFar = (page - 1) * itemsPerPage + receivedItemsCount;

    // If we got fewer items than requested and this isn't page 1, we've reached the end
    const reachedEnd = page > 1 && receivedItemsCount === 0;
    if (reachedEnd && page === pageNumber) {
      setHasReachedEnd(true);
    }

    // If we got any items, update our actual count
    if (receivedItemsCount > 0 && page === pageNumber) {
      const newActualCount = Math.max(actualTotalCount, calculatedTotalSoFar);
      setActualTotalCount(newActualCount);
    }

    return response.data;
  };

  // Main query to fetch current page
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      "sparks",
      accountID,
      userID,
      pageNumber,
      itemsPerPage,
      filter,
      searchQuery,
      pipelineId,
    ],
    queryFn: () => fetchSparks(pageNumber),
    enabled: Boolean(accountID && userID),
    staleTime: 30000, // Consider data stale after 30 seconds
    retry: (failureCount, error) => {
      // Retry logic - don't retry on 404 errors as they're likely intentional
      if (error?.response?.status === 404) {
        return false;
      }
      // For other errors, retry up to 2 times
      return failureCount < 2;
    },
  });

  // Prefetch next page
  useEffect(() => {
    if (data?.has_more && !isLoading && accountID && userID) {
      const nextPage = pageNumber + 1;

      // Prefetch the next page
      queryClient.prefetchQuery({
        queryKey: [
          "sparks",
          accountID,
          userID,
          nextPage,
          itemsPerPage,
          filter,
          searchQuery,
          pipelineId,
        ],
        queryFn: () => fetchSparks(nextPage),
        staleTime: 30000,
      });
    }
  }, [
    data,
    pageNumber,
    isLoading,
    accountID,
    userID,
    itemsPerPage,
    filter,
    searchQuery,
    pipelineId,
    queryClient,
  ]);

  // Log query results
  useEffect(() => {
    // Query state change effect
  }, [
    data,
    isLoading,
    error,
    actualTotalCount,
    hasReachedEnd,
    pageNumber,
    pipelineId,
  ]);

  // Use data directly from the API response, no need for client-side filtering
  // since we're using the search endpoint
  const filteredSparks = data?.items || [];

  const handleAddDeal = async (sparkId) => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/api/accounts/${accountID}/users/${userID}/spark/${sparkId}/convert`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      refetch();

      return { success: true };
    } catch (err) {
      throw new Error("Failed to convert spark to deal");
    }
  };

  const handleIgnoreSpark = async (sparkId) => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/api/accounts/${accountID}/users/${userID}/spark/${sparkId}/ignore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      refetch();

      return { success: true };
    } catch (err) {
      throw new Error("Failed to ignore spark");
    }
  };

  const markSparkAsRead = async (sparkId) => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/api/accounts/${accountID}/users/${userID}/spark/${sparkId}/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      refetch();

      return { success: true };
    } catch (err) {
      throw new Error("Failed to mark spark as read");
    }
  };

  const markAllSparksAsRead = async () => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/api/accounts/${accountID}/users/${userID}/spark/read-all`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      refetch();

      return { success: true };
    } catch (err) {
      throw new Error("Failed to mark all sparks as read");
    }
  };

  return {
    sparks: filteredSparks,
    loading: isLoading,
    error: error?.message,
    hasMore: data?.has_more || false,
    totalCount: data?.total_count || 0,
    actualCount: actualTotalCount,
    hasReachedEnd: hasReachedEnd,
    handleAddDeal,
    handleIgnoreSpark,
    markSparkAsRead,
    markAllSparksAsRead,
    refetch,
  };
}

export default useFetchSparks;
