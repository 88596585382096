// src/components/Shared/HoverBox.jsx

import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Icon,
  useColorModeValue,
  Portal
} from "@chakra-ui/react";

/**
 * HoverBox Component
 * Wraps children with a hover effect that changes the icon and provides a tooltip.
 *
 * @param {Object} props - Component props
 * @param {React.ElementType} props.icon - The default icon to display
 * @param {React.ElementType} props.hoverIcon - The icon to display on hover
 * @param {Function} props.onClick - Function to call when the HoverBox is clicked
 * @param {React.ReactNode} props.children - The content to display inside the HoverBox
 * @param {string} props.tooltipLabel - The tooltip text to display
 * @param {string} props.fullText - The full text to display in tooltip when text is truncated
 * @returns {JSX.Element}
 */
const HoverBox = ({
  children,
  icon = null,
  hoverIcon = null,
  onClick,
  tooltipLabel = "Hover action",
  fullText = null,
}) => {
  // Match OverviewTag colors - blue.700 for icon and blue.50 for background
  const iconColor = useColorModeValue("blue.700", "blue.300"); 
  const hoverIconColor = useColorModeValue("blue.800", "blue.400"); // Darker version of icon color
  const tooltipBg = useColorModeValue("gray.700", "white");
  const tooltipTextColor = useColorModeValue("white", "gray.700");
  const iconBgColor = useColorModeValue("blue.50", "blue.700"); // Match OverviewTag bg color
  const hoverIconBgColor = useColorModeValue("blue.100", "blue.800"); // Darker version of bg color

  // Added state to track hover and mouse position
  const [isHovered, setIsHovered] = React.useState(false);
  const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 });
  const [isTextTruncated, setIsTextTruncated] = React.useState(false);
  const textRef = useRef(null);

  // Check if text is truncated
  useEffect(() => {
    const checkIfTruncated = () => {
      if (textRef.current && fullText) {
        const isTruncated = textRef.current.scrollWidth > textRef.current.clientWidth;
        setIsTextTruncated(isTruncated);
      }
    };

    checkIfTruncated();
    // Also check on window resize
    window.addEventListener("resize", checkIfTruncated);
    return () => window.removeEventListener("resize", checkIfTruncated);
  }, [fullText]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleMouseMove = (e) => setMousePos({ x: e.clientX, y: e.clientY });

  // Determine what to show in the tooltip
  const tooltipContent = fullText && isTextTruncated ? fullText : tooltipLabel;
  const shouldShowTooltip = isHovered && (tooltipLabel || (fullText && isTextTruncated));

  return (
    <>
      <Box
        display="inline-flex"
        alignItems="center"
        borderRadius="lg"
        cursor="pointer"
        position="relative"
        transition="all 0.2s"
        role="group"
        width="100%"
        height="24px" // Set a fixed height to match the icon container
        _hover={{
          "& .default-icon": { opacity: 0 },
          "& .hover-icon": { opacity: 1 },
        }}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        {icon && (
          <Box
            className="default-icon"
            mr={2}
            position="absolute"
            transition="opacity 0.2s"
            opacity={1}
            flexShrink={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            bg={iconBgColor}
            width="24px"
            height="24px"
            left={0}
            top="50%"
            transform="translateY(-50%)"
            overflow="hidden"
            borderWidth="1px"
            borderColor="blue.200"
          >
            <Icon as={icon} color={iconColor} boxSize="16px" />
          </Box>
        )}
        {hoverIcon && (
          <Box
            className="hover-icon"
            mr={2}
            position="absolute"
            transition="opacity 0.2s"
            opacity={0}
            flexShrink={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            bg={hoverIconBgColor}
            width="24px"
            height="24px"
            left={0}
            top="50%"
            transform="translateY(-50%)"
            overflow="hidden"
            borderWidth="1px"
            borderColor="blue.300"
          >
            <Icon as={hoverIcon} color={hoverIconColor} boxSize="16px" />
          </Box>
        )}
        <Box
          as="span"
          display="flex"
          alignItems="center"
          textAlign="left"
          width="100%"
          pl={icon ? "32px" : "0"}
          pr="4px"
          height="100%"
          ref={textRef}
          overflow="hidden"
        >
          {children}
        </Box>
      </Box>
      {shouldShowTooltip && (
        <Portal>
          <Box
            position="fixed"
            left={mousePos.x + 10}
            top={mousePos.y + 10}
            bg={tooltipBg}
            color={tooltipTextColor}
            px="8px"
            py="4px"
            fontSize="sm"
            borderRadius="md"
            zIndex="tooltip"
            pointerEvents="none"
            boxShadow="sm"
            maxWidth="300px"
            overflowWrap="break-word"
          >
            {tooltipContent}
          </Box>
        </Portal>
      )}
    </>
  );
};

// Define PropTypes for type checking
HoverBox.propTypes = {
  icon: PropTypes.elementType,
  hoverIcon: PropTypes.elementType,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  tooltipLabel: PropTypes.string,
  fullText: PropTypes.string,
};

export default HoverBox;
