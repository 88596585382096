// src/components/Deals/DealSummarySection.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, VStack, Button, Collapse, Flex, HStack, IconButton, useToast } from '@chakra-ui/react';
import { FaCalendarAlt } from 'react-icons/fa';
import { Copy } from 'lucide-react';
import HoverBox from '../Shared/HoverBox';

/**
 * Helper function to safely parse JSON strings.
 * Returns the value of the specified key or a default message if parsing fails.
 */
const parseJSONField = (jsonString, key, defaultMessage) => {
  if (!jsonString) return defaultMessage;
  try {
    const parsed = JSON.parse(jsonString);
    return parsed[key] || defaultMessage;
  } catch (error) {
    console.error(`Error parsing JSON for key "${key}":`, error);
    return defaultMessage;
  }
};

const DealSummarySection = ({ 
  summary = null, 
  shortSummary = null, 
  updatedAt = null, 
  tooltipMessage = null, 
  dateRange = null 
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const toast = useToast();

  // Parse the summaries
  const parsedSummary = parseJSONField(summary, 'summary', 'No summary provided.');
  const parsedShortSummary = parseJSONField(shortSummary, 'short_summary', 'No summary provided.');

  const handleToggle = () => setIsExpanded(!isExpanded);
  
  // Function to handle copying text to clipboard
  const handleCopyToClipboard = (text, label) => {
    if (!text) return;
    
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: `${label} copied to clipboard`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      },
      (err) => {
        console.error('Failed to copy text: ', err);
        toast({
          title: 'Failed to copy text',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    );
  };

  return (
    <Box width="100%" overflowX="hidden">
      {/* Header with responsive layout */}
      <Flex 
        direction={{ base: "column", sm: "row" }} 
        justify="space-between" 
        align={{ base: "start", sm: "center" }} 
        mb={3}
        gap={2}
        width="100%"
      >
        <HStack spacing={2} flexWrap="wrap">
          <Text fontSize="md" fontWeight="semibold">
            Deal Summary
          </Text>
          <IconButton
            aria-label="Copy deal summary"
            icon={<Copy size={16} />}
            size="sm"
            variant="ghost"
            onClick={() => handleCopyToClipboard(isExpanded ? parsedSummary : parsedShortSummary, "Deal Summary")}
          />
        </HStack>
      </Flex>
      
      <VStack spacing={4} align="stretch" width="100%">
        {/* Full Summary */}
        <Collapse in={isExpanded} animateOpacity>
          <Text fontSize={{ base: "xs", sm: "sm" }} color="gray.700" whiteSpace="pre-wrap" overflowWrap="break-word" width="100%">
            {parsedSummary}
          </Text>
        </Collapse>

        {/* Short Summary */}
        {!isExpanded && (
          <Text fontSize={{ base: "xs", sm: "sm" }} color="gray.700" overflowWrap="break-word" width="100%">
            {parsedShortSummary}
          </Text>
        )}

        {/* Toggle Button and Combined Date/Update Info - Responsive Layout */}
        <Flex 
          direction={{ base: "column", sm: "row" }} 
          align={{ base: "start", sm: "center" }} 
          justify="space-between" 
          width="100%" 
          mt={2}
          gap={2}
        >
          <Box>
            <Button
              size="sm"
              onClick={handleToggle}
              variant="link"
              colorScheme="blue"
              height="auto"
              padding={0}
              minWidth="auto"
              fontSize={{ base: "xs", sm: "sm" }}
            >
              {isExpanded ? 'Show concise summary' : 'Show full summary'}
            </Button>
          </Box>
          
          {/* Combined date range and updated timestamp in single row */}
          <Box textAlign={{ base: "left", sm: "right" }} width={{ base: "100%", sm: "auto" }} maxWidth="100%">
            <HoverBox
              tooltipLabel={tooltipMessage || "No update information available"}
              onClick={() => {/* No action needed for display-only component */}}
            >
              <Flex 
                direction={{ base: "column", md: "row" }}
                gap={{ base: 1, md: 2 }} 
                fontSize={{ base: "2xs", sm: "xs" }}
                color="gray.500"
                alignItems={{ base: "flex-start", md: "center" }}
                flexWrap="wrap"
                width="100%"
              >
                {dateRange && (
                  <HStack spacing={1} flexShrink={0} maxWidth="100%">
                    <FaCalendarAlt size={10} />
                    <Text noOfLines={1} overflow="hidden" textOverflow="ellipsis" maxWidth="100%">
                      {dateRange}
                    </Text>
                  </HStack>
                )}
                {dateRange && updatedAt && (
                  <Text color="gray.400" display={{ base: "none", md: "inline" }}>•</Text>
                )}
                {updatedAt && (
                  <Text noOfLines={1} overflow="hidden" textOverflow="ellipsis" maxWidth="100%">
                    Updated {updatedAt}
                  </Text>
                )}
              </Flex>
            </HoverBox>
          </Box>
        </Flex>
      </VStack>
    </Box>
  );
};

DealSummarySection.propTypes = {
  summary: PropTypes.string,
  shortSummary: PropTypes.string,
  updatedAt: PropTypes.string,
  tooltipMessage: PropTypes.string,
  dateRange: PropTypes.string,
};

export default DealSummarySection;
