import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

export function useFetchDealActivities(accountID, dealID, startDate, endDate) {
  const { getAccessToken } = useAuthData();

  return useQuery({
    queryKey: ["dealActivities", accountID, dealID, startDate, endDate],
    queryFn: async () => {
      if (!accountID || !dealID) {
        return [];
      }

      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.get(
        `${target}/api/accounts/${accountID}/deals/${dealID}/activities`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }
      );

      // Process and filter the activities
      const processedActivities = (response.data || [])
        .filter((activity) => {
          // Filter out email activities with empty bodies
          if (activity.activity_type?.toLowerCase() === "email") {
            const emailBody = activity.activity_data?.data?.body;
            return emailBody && emailBody.trim() !== "";
          }
          // Keep all other types of activities
          return true;
        })
        .map((activity) => {
          // Normalize activity type
          const normalizedType = activity.activity_type
            ? activity.activity_type.charAt(0).toUpperCase() +
              activity.activity_type.slice(1).toLowerCase()
            : "Unknown";

          // Ensure all required fields are present
          const processedActivity = {
            ...activity,
            activity_type: normalizedType,
            owner_id: activity.owner_id || activity.user_id,
            created_by_id: activity.created_by_id || activity.user_id,
            is_internal: activity.is_internal || false,
            classification: {
              activity_purpose: activity.classification?.activity_purpose || "",
              communication_medium:
                activity.classification?.communication_medium || normalizedType,
              deal_stage: activity.classification?.deal_stage || "",
              key_action: activity.classification?.key_action || "",
              entities_involved:
                activity.classification?.entities_involved || [],
              status: activity.classification?.status || activity.status,
              email_to: activity.classification?.email_to || "",
              email_cc: activity.classification?.email_cc || "",
              email_bcc: activity.classification?.email_bcc || "",
              email_subject:
                activity.classification?.email_subject ||
                activity.activity_data?.data?.subject ||
                "",
              email_body:
                activity.classification?.email_body ||
                activity.activity_data?.data?.body ||
                "",
            },
            activity_data: activity.activity_data
              ? {
                  ...activity.activity_data,
                  activity_type: activity.activity_type.toLowerCase(),
                  data: {
                    ...activity.activity_data.data,
                    // Ensure all required fields are present for email type
                    ...(activity.activity_type.toLowerCase() === "email" && {
                      sender_email:
                        activity.activity_data.data.sender_email || "",
                      sender_name:
                        activity.activity_data.data.sender_name || "",
                      subject: activity.activity_data.data.subject || "",
                      body: activity.activity_data.data.body || "",
                      received_at:
                        activity.activity_data.data.received_at ||
                        activity.created_at,
                      recipients: Array.isArray(
                        activity.activity_data.data.recipients
                      )
                        ? activity.activity_data.data.recipients
                        : [],
                    }),
                    // Ensure all required fields are present for meeting type
                    ...(activity.activity_type.toLowerCase() === "meeting" && {
                      end_time: activity.activity_data.data.end_time || "",
                      start_time: activity.activity_data.data.start_time || "",
                      is_recorded:
                        activity.activity_data.data.is_recorded || false,
                      location: activity.activity_data.data.location || "",
                      meeting_id:
                        activity.activity_data.data.meeting_id || null,
                      organizer_display_name:
                        activity.activity_data.data.organizer_display_name ||
                        "",
                      organizer_email:
                        activity.activity_data.data.organizer_email || "",
                      participants: Array.isArray(
                        activity.activity_data.data.participants
                      )
                        ? activity.activity_data.data.participants
                        : [],
                      timezone:
                        activity.activity_data.data.timezone ||
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }),
                    // Ensure all required fields are present for call type
                    ...(activity.activity_type.toLowerCase() === "call" && {
                      summary: activity.activity_data.data.summary || "",
                      transcript: activity.activity_data.data.transcript || "",
                    }),
                  },
                  version: activity.activity_data.version || "1.0",
                }
              : null,
          };

          return processedActivity;
        });

      return processedActivities;
    },
    enabled: Boolean(accountID) && Boolean(dealID),
  });
}

export default useFetchDealActivities;
