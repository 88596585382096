// src/views/lysto/hooks/useInboxItem.js
import { useState, useEffect } from "react";
import { useAuthData } from "../../../auth-context";
import axios from "axios";
import { generateEmailApiCall } from "../api/emailService";

export function useInboxItem(inboxId, accountID, userID, target) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuthData();

  useEffect(() => {
    const fetchInboxItem = async () => {
      if (!inboxId || !accountID || !userID) {
        setError(new Error("Missing required parameters."));
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const accessToken = await getAccessToken();

        const response = await axios.get(
          `${target}/api/accounts/${accountID}/users/${userID}/inbox/${inboxId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.data) {
          throw new Error("No data received from API.");
        }

        // Determine task type based on response data
        const taskSubtype = determineTaskType(response.data);

        // Transform the data to match component expectations
        const transformedData = {
          ...response.data,
          task_subtype: taskSubtype,

          notes: response.data.notes || [],
          champion: response.data.champion || "N/A",
          taskType: response.data.task_type || "Followup",
          emailSubject: response.data.subject || "",
          leadName: response.data.sender_name || "Unknown Lead",
          contacts: response.data.contacts || [],
          tags: [],
        };

        setData(transformedData);
        setError(null);
      } catch (err) {
        setError(
          err.response?.data?.message ||
            err.message ||
            "Failed to fetch inbox item."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchInboxItem();
  }, [inboxId, accountID, userID, getAccessToken, target]);

  // Helper function to determine task type
  const determineTaskType = (data) => {
    // Check for task_subtype first as it's explicit
    if (data.task_subtype) {
      return data.task_subtype.toLowerCase();
    }

    // Check for task_type
    if (
      data.task_type === "follow-up" &&
      data.subject?.toLowerCase().includes("call follow-up")
    ) {
      return "call";
    }

    // Check for content structure indicating a call follow-up
    try {
      const parsedContent = JSON.parse(data.content);
      if (
        parsedContent &&
        parsedContent.content &&
        (parsedContent.content.includes("# Key Points to Address") ||
          parsedContent.content.includes("# Suggested Approach"))
      ) {
        return "call";
      }
    } catch (e) {
      // If parsing fails, continue with other checks
    }

    // Check for email-specific fields
    if (
      data.thread_id ||
      data.email_id ||
      data.email_recipients ||
      data.conversation_history?.some((msg) => msg.thread_id || msg.email_id)
    ) {
      return "email";
    }

    // Default to email if no other indicators are found
    return "email";
  };

  // Handle sending a message
  const handleSendMessage = async (messageData) => {
    const accessToken = await getAccessToken();
    // Use a simple URL without query parameters
    const apiUrl = `${target}/api/accounts/${accountID}/users/${userID}/tasks/${inboxId}/send-email`;

    const payload = {
      message_content: messageData.content,
      subject: messageData.subject,
      email_recipients: messageData.email_recipients,
      type: "message",
      identity: "agent",
      // Add new fields for Send As functionality
      send_as_user_id: messageData.send_as_user_id,
      send_as_email: messageData.send_as_email,
      effective_user_id: messageData.effective_user_id || userID, // Default to current user if not specified
      // Include is_new_thread directly in the payload
      is_new_thread: messageData.is_new_thread || false,
    };

    const response = await axios.post(apiUrl, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const sentMessage = {
      message_id: response.data.message_id || Date.now().toString(),
      identity: "agent",
      content: messageData.content,
      type: "message",
      status: "sent",
      date_sent: new Date().toISOString(),
      time_sent: new Date().toLocaleTimeString(),
      sender_email:
        messageData.send_as_email ||
        response.data.sender_email ||
        "current.user@example.com",
      sender_name: response.data.sender_name || "Current User",
    };

    setData((prev) => {
      // Check if prev is null or conversationHistory is undefined
      if (!prev || !prev.conversationHistory) {
        return {
          ...prev,
          conversationHistory: [sentMessage],
        };
      }

      // Normal case - append to existing conversation history
      return {
        ...prev,
        conversationHistory: [
          ...prev.conversationHistory.filter((msg) => msg.status !== "draft"),
          sentMessage,
        ],
      };
    });

    return response.data;
  };

  // Handle marking as done
  const handleMarkAsDone = async (id) => {
    const accessToken = await getAccessToken();
    const response = await axios.post(
      `${target}/api/accounts/${accountID}/users/${userID}/tasks/${inboxId}/update-status`,
      {
        activity_id: id,
        status: "completed",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      setData((prev) => ({
        ...prev,
        status: "completed",
      }));
    } else {
      throw new Error("Failed to mark as done");
    }
  };

  // Handle adding an annotation
  const handleAddAnnotation = async (annotation) => {
    const accessToken = await getAccessToken();

    const response = await axios.post(
      `${target}/api/accounts/${accountID}/users/${userID}/inbox/${inboxId}/annotations`,
      {
        content: annotation,
        type: "note",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const newNote = {
      id: response.data.id,
      content: annotation,
      created_at: new Date().toISOString(),
      type: "note",
    };

    setData((prev) => ({
      ...prev,
      notes: [...(prev.notes || []), newNote],
    }));

    return response.data;
  };

  // Handle generating an email
  const handleGenerateEmail = async (emailType = "followup", options = {}) => {
    // Ensure we have the deal_id from the fetched inbox item data
    if (!data?.deal_id) {
      console.error(
        "Cannot generate email: deal_id is missing from inbox item data."
      );
      throw new Error(
        "Deal ID is required to generate an email for this item."
      );
    }

    // Determine the effective email type (prioritize explicit over default/task_type)
    let effectiveEmailType =
      emailType !== "followup"
        ? emailType
        : data.task_type || data.taskType || "followup";

    if (effectiveEmailType.toLowerCase() === "follow-up") {
      effectiveEmailType = "followup"; // Normalize to backend expected value
    }

    try {
      // Call the centralized API function
      const result = await generateEmailApiCall({
        accountID,
        userID,
        dealId: data.deal_id, // Use deal_id from the hook's state
        emailType: effectiveEmailType,
        guidingPrompt: options.additional_context, // Pass additional context if provided
        target,
        getAccessToken,
        activityId: data.activity_id, // Pass activity_id from hook state
        activityType: data.task_subtype, // Pass task_subtype as activity_type
        existingDealData: data, // Pass the full inbox item data for context
      });

      // Update the hook's state with the generated content
      setData((prev) => {
        if (!prev) return null; // Should not happen if deal_id exists, but safety check

        // Determine recipients: Use API result first, fallback to existing data
        const finalRecipients =
          result.email_recipients && result.email_recipients.length > 0
            ? result.email_recipients
            : prev.email_recipients;

        return {
          ...prev,
          content: result.content, // Update content from API response
          subject: result.subject, // Update subject from API response
          email_recipients: finalRecipients,
        };
      });

      // Return the result for potential use by the calling component
      return result;
    } catch (error) {
      console.error("Error generating email in useInboxItem:", error);
      // Re-throw the error so the calling component knows generation failed
      throw error;
    }
  };

  return {
    data,
    loading,
    error,
    handleSendMessage,
    handleMarkAsDone,
    handleAddAnnotation,
    handleGenerateEmail,
  };
}
