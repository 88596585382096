// src/components/Contacts/hooks/useFetchContacts.js

import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useAuthData } from "../../../../auth-context";

const PREFETCH_SIZE = 1000;
const DISPLAY_SIZE = 10;

/**
 * Custom hook to fetch and search contacts data.
 * @param {number} accountID - The ID of the account.
 * @param {string} searchQuery - The search query string.
 * @param {string} contactType - The type of contacts to fetch (e.g., 'Lead').
 * @param {string} segment - The segment to filter contacts.
 * @param {number} pageNumber - The current page number.
 * @returns {Object} - { contacts, isLoading, isError, hasMore, totalCount, pageSize }
 */
const useFetchContacts = (
  accountID,
  searchQuery = "",
  contactType = "Lead",
  segment = "all",
  pageNumber = 1
) => {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { getAccessToken } = useAuthData();

  // Cache for storing prefetched batches (keyed by batch number)
  const contactsCache = useRef(new Map());

  // Calculate which batch contains the requested page
  const getBatchNumber = useCallback((page) => {
    return Math.ceil(page / (PREFETCH_SIZE / DISPLAY_SIZE));
  }, []);

  const fetchBatch = useCallback(
    async (batchNumber) => {
      setIsLoading(true);
      setIsError(false);
      try {
        const accessToken = await getAccessToken();
        const baseUrl =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
            : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
        const endpoint = searchQuery
          ? `/api/accounts/${accountID}/contacts/search`
          : `/api/accounts/${accountID}/contacts`;

        // Calculate the API page number based on the batch
        const apiPageNumber = batchNumber;

        const response = await axios.get(`${baseUrl}${endpoint}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            contact_type: contactType,
            page_number: apiPageNumber,
            items_per_page: PREFETCH_SIZE,
            ...(searchQuery && { query: searchQuery.trim() }),
            ...(segment !== "all" && { active: segment === "active" }),
          },
        });

        const newContacts = response.data?.items || [];
        // Store the new batch without clearing previous batches!
        contactsCache.current.set(batchNumber, newContacts);

        setHasMore(response.data?.has_more || false);
        setTotalCount(response.data?.total_count || 0);

        return newContacts;
      } catch (error) {
        setIsError(true);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [accountID, contactType, searchQuery, segment, getAccessToken]
  );

  // Clear the entire cache when query parameters change
  useEffect(() => {
    contactsCache.current.clear();
  }, [accountID, searchQuery, contactType, segment]);

  // Effect to handle fetching and aggregating batches
  useEffect(() => {
    const loadContacts = async () => {
      if (!accountID) return;

      const batchNumber = getBatchNumber(pageNumber);

      // Fetch the batch if it hasn't been fetched yet.
      if (!contactsCache.current.has(batchNumber)) {
        await fetchBatch(batchNumber);
      }

      // Aggregate all fetched batches in order.
      const aggregated = [];
      const batches = Array.from(contactsCache.current.keys()).sort(
        (a, b) => a - b
      );
      batches.forEach((bn) => {
        aggregated.push(...contactsCache.current.get(bn));
      });
      setContacts(aggregated);
    };

    loadContacts();
  }, [accountID, pageNumber, fetchBatch, getBatchNumber, segment]);

  // Prefetch next batch when nearing the end of the current batch
  useEffect(() => {
    const prefetchNextBatch = async () => {
      if (!accountID || !hasMore) return;

      const currentBatchNumber = getBatchNumber(pageNumber);
      const nextBatchNumber = currentBatchNumber + 1;

      if (
        pageNumber % (PREFETCH_SIZE / DISPLAY_SIZE) >=
          PREFETCH_SIZE / DISPLAY_SIZE - 2 &&
        !contactsCache.current.has(nextBatchNumber)
      ) {
        await fetchBatch(nextBatchNumber);
      }
    };

    prefetchNextBatch();
  }, [accountID, pageNumber, hasMore, getBatchNumber, fetchBatch, segment]);

  return {
    contacts,
    isLoading,
    isError,
    hasMore,
    totalCount,
    pageSize: DISPLAY_SIZE,
  };
};

export default useFetchContacts;
