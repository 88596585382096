import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Text, VStack, Spinner } from "@chakra-ui/react";

const LogoutCallback = () => {
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [hasLoggedOut, setHasLoggedOut] = useState(false);

  useEffect(() => {
    if (isLoading) return;

    if (isAuthenticated && !hasLoggedOut) {
      // Clean up URL parameters
      const params = new URLSearchParams(search);
      params.delete("code");
      params.delete("state");
      window.history.replaceState({}, "", window.location.pathname);

      // Trigger logout without hash routing in the returnTo URL
      logout({ returnTo: window.location.origin });
      setHasLoggedOut(true);
    }
  }, [logout, search, isAuthenticated, hasLoggedOut, isLoading]);

  // Fallback: if logout is complete (not authenticated) but still on this page, redirect after 3 seconds
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const timer = setTimeout(() => {
        navigate("/auth/callback");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isLoading, isAuthenticated, navigate]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <VStack spacing={4}>
        <Spinner size="xl" />
        <Text fontSize="lg">Logging out...</Text>
      </VStack>
    </Box>
  );
};

export default LogoutCallback;
