import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to fetch deal score based on the account ID and deal ID.
 * @param {string|number} accountID - The account ID.
 * @param {string|number} dealID - The deal ID.
 * @returns {Object} - Contains deal score data, loading state, and error state.
 */
export function useFetchDealScore(accountID, dealID) {
  const { getAccessToken } = useAuthData();

  return useQuery({
    queryKey: ["dealScore", accountID, dealID],
    queryFn: async () => {
      if (!accountID || !dealID) {
        return null;
      }

      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.get(
        `${target}/api/accounts/${accountID}/deals/${dealID}/score`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    },
    enabled: Boolean(accountID) && Boolean(dealID),
  });
}

export default useFetchDealScore;
