import React from "react";
import PropTypes from "prop-types";
import {
  VStack,
  Box,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Flex,
  HStack,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { ActivityItem } from "../Activities";
import { useFetchContactActivities } from "../hooks/useFetchContactActivities";

const ITEMS_PER_PAGE = 5;

const SimplePaginationControls = ({ currentPage, totalPages, onPrevious, onNext, onPageSelect }) => {
  // Helper function to generate page numbers to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    
    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      
      let start = Math.max(2, currentPage - 1);
      let end = Math.min(totalPages - 1, currentPage + 1);
      
      if (start > 2) {
        pageNumbers.push('...');
      }
      
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
      
      if (end < totalPages - 1) {
        pageNumbers.push('...');
      }
      
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  return (
    <Box width="100%" py={4}>
      <HStack spacing={1} justify="center" width="300px" margin="0 auto">
        <Box width="40px" textAlign="center">
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={onPrevious}
            isDisabled={currentPage === 1}
            aria-label="Previous page"
            size="sm"
          />
        </Box>
        
        <Box flex="1" textAlign="center">
          <HStack spacing={1} justify="center">
            {getPageNumbers().map((pageNum, index) => (
              pageNum === '...' ? (
                <Text key={`ellipsis-${index}`} color="gray.500" px={2} minWidth="32px">
                  {pageNum}
                </Text>
              ) : (
                <Button
                  key={pageNum}
                  onClick={() => onPageSelect(pageNum)}
                  variant={currentPage === pageNum ? 'solid' : 'ghost'}
                  colorScheme={currentPage === pageNum ? 'blue' : 'gray'}
                  size="sm"
                  minWidth="32px"
                >
                  {pageNum}
                </Button>
              )
            ))}
          </HStack>
        </Box>

        <Box width="40px" textAlign="center">
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={onNext}
            isDisabled={currentPage === totalPages}
            aria-label="Next page"
            size="sm"
          />
        </Box>
      </HStack>
    </Box>
  );
};

const ContactActivity = ({ accountId, contactId }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  
  // Set date range for activities (1 year back from today)
  const endDate = new Date().toISOString().split('T')[0];
  const startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    .toISOString().split('T')[0];
  
  const { data: activities, isLoading, isError } = useFetchContactActivities(
    accountId,
    contactId,
    startDate,
    endDate
  );

  // Sort activities in reverse chronological order
  const sortedActivities = React.useMemo(() => {
    if (!activities) return [];
    return [...activities].sort((a, b) => {
      // First try to use timestamp if available (used in associated_activities)
      if (a.timestamp && b.timestamp) {
        return new Date(b.timestamp) - new Date(a.timestamp);
      }
      
      // Then try activity_date (used in crm_account_activities)
      const dateA = a.activity_date ? new Date(a.activity_date) : null;
      const dateB = b.activity_date ? new Date(b.activity_date) : null;
      
      if (dateA && dateB && dateA.getTime() !== dateB.getTime()) {
        return dateB - dateA; // Most recent first
      }
      
      // If activity dates are the same or null, compare completed_date_time
      const completedA = a.completed_date_time ? new Date(a.completed_date_time) : null;
      const completedB = b.completed_date_time ? new Date(b.completed_date_time) : null;
      
      // Handle cases where completed_date_time might be null
      if (completedA && completedB) return completedB - completedA;
      if (!completedA && !completedB) return 0;
      if (!completedA) return 1;
      if (!completedB) return -1;
      
      // Fallback to created_at if available
      const createdAtA = a.created_at ? new Date(a.created_at) : null;
      const createdAtB = b.created_at ? new Date(b.created_at) : null;
      
      if (createdAtA && createdAtB) return createdAtB - createdAtA;
      
      return 0;
    });
  }, [activities]);

  // Calculate total pages
  const totalPages = React.useMemo(() => {
    return Math.ceil(sortedActivities.length / ITEMS_PER_PAGE) || 1;
  }, [sortedActivities.length]);

  // Get current page's activities
  const currentActivities = React.useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedActivities.slice(startIndex, endIndex);
  }, [sortedActivities, currentPage]);

  // Pagination handlers
  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(totalPages, prev + 1));
  };

  const handlePageSelect = (pageNum) => {
    setCurrentPage(pageNum);
  };

  if (isLoading) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>Contact Activities</Text>
        <Flex justify="center" align="center" minH="200px">
          <Spinner size="lg" />
        </Flex>
      </Box>
    );
  }

  if (isError) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>Contact Activities</Text>
        <Alert status="error">
          <AlertIcon />
          Error loading activities.
        </Alert>
      </Box>
    );
  }

  if (!activities?.length) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>Contact Activities</Text>
        <Text color="gray.500" textAlign="center" py={8}>
          No activities found for this contact.
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text fontSize="md" fontWeight="bold" mb={4}>Contact Activities</Text>
      
      {/* Activities List */}
      <VStack spacing={4} align="stretch" mb={4}>
        {currentActivities.map((activity) => (
          <ActivityItem 
            key={activity.id || activity.activity_id} 
            activity={activity}
            showDealLabels={false}
          />
        ))}
      </VStack>

      {/* Simple Pagination Controls */}
      {totalPages > 1 && (
        <SimplePaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
          onPageSelect={handlePageSelect}
        />
      )}
    </Box>
  );
};

ContactActivity.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ContactActivity;