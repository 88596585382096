import React, { useEffect } from "react";
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { ReactComponent as LystoLogo } from "assets/img/lysto-logo-round.svg";
import { NavItem, SidebarLinks } from "./Links";
import { STYLE_CONSTANTS, useSidebarColors } from "./sidebarConstants";
import { useSidebar } from "contexts/SidebarContext";

function Sidebar({ routes }) {
  const colors = useSidebarColors();
  const { isExpanded, setIsExpanded } = useSidebar();
  const [isLargerThan1200] = useMediaQuery("(min-width: 1200px)", {
    ssr: true,
    fallback: true // Assume large screen initially
  });

  useEffect(() => {
    // Only handle screen size changes, don't override initial state
    if (!isLargerThan1200) {
      setIsExpanded(false);
    }
  }, [isLargerThan1200, setIsExpanded]);

  const handleToggle = () => {
    if (isLargerThan1200) {
      setIsExpanded(!isExpanded);
    }
  };

  // Filter routes for the scrollable area, excluding Settings
  const scrollableRoutes = routes.filter(
    (route) => route.hidden === undefined || !route.hidden
  ).filter((route) => route.name !== "Settings");

  // Find the Settings route
  const settingsRoute = routes.find((route) => route.name === "Settings");

  return (
    <Box
      display="block"
      w="100%"
      position="fixed"
      minH="100%"
    >
      <Flex
        bg={colors.sidebarBg}
        transition={STYLE_CONSTANTS.TRANSITION}
        w={isExpanded ? STYLE_CONSTANTS.EXPANDED_WIDTH : STYLE_CONSTANTS.COLLAPSED_WIDTH}
        h="100vh"
        m="0px"
        minH="100%"
        boxShadow={colors.shadow}
        flexDirection="column"
        position="relative"
      >
        {/* Logo and Title */}
        <Flex 
          p={STYLE_CONSTANTS.SPACING.PADDING}
          pt="20px"
          mx={STYLE_CONSTANTS.SPACING.MARGIN}
          alignItems="center" 
          justifyContent={isExpanded ? "flex-start" : "center"}
          borderRadius={STYLE_CONSTANTS.BORDER_RADIUS}
          mb={2}
          cursor={isLargerThan1200 ? "pointer" : "default"}
          onClick={handleToggle}
          _hover={{ bg: isLargerThan1200 ? colors.hoverBg : "transparent" }}
          transition={STYLE_CONSTANTS.TRANSITION}
        >
          <Box w={STYLE_CONSTANTS.ICON_SIZE} h={STYLE_CONSTANTS.ICON_SIZE}>
            <LystoLogo width={STYLE_CONSTANTS.ICON_SIZE} height={STYLE_CONSTANTS.ICON_SIZE} />
          </Box>
          {isExpanded && (
            <Text 
              ml={STYLE_CONSTANTS.SPACING.MARGIN}
              fontSize={STYLE_CONSTANTS.FONT.SIZE.LARGE}
              fontWeight="bold" 
              color={colors.text}
            >
              Lysto
            </Text>
          )}
        </Flex>

        {/* Navigation Items */}
        <Box flexGrow={1}>
              <SidebarLinks routes={scrollableRoutes} isExpanded={isExpanded} />
        </Box>

        {/* Bottom Section */}
        <Box>
          {/* Settings Link */}
          {settingsRoute && (
              <SidebarLinks routes={[settingsRoute]} isExpanded={isExpanded} />
          )}

          {/* Collapse Button - Only show on desktop */}
          {isLargerThan1200 && (
            <Box mb={4}>
              <NavItem
                isActive={false}
                isExpanded={isExpanded}
                icon={
                  <Box
                    border="1.5px solid"
                    borderColor={colors.textSecondary}
                    borderRadius="lg"
                    w="24px"
                    h="24px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    _groupHover={{ borderColor: colors.textSecondary }}
                  >
                    {isExpanded ? <MdChevronLeft size={20} /> : <MdChevronRight size={22} />}
                  </Box>
                }
                tooltipLabel={isExpanded ? "Collapse" : "Expand"}
                onClick={handleToggle}
              />
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidebar;
