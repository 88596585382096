import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { HiUser } from 'react-icons/hi';
import { FaExpandAlt } from 'react-icons/fa';
import BaseTag from '../Shared/BaseTag';

/**
 * ContactTag Component
 * Displays a contact with hover effects and click functionality.
 */
const ContactTag = ({ contactName, onClick }) => (
  <BaseTag
    label={contactName || 'Unnamed Contact'}
    icon={HiUser}
    hoverIcon={FaExpandAlt}
    onClick={onClick}
    tooltip="View Contact Details"
  />
);

ContactTag.propTypes = {
  contactName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

/**
 * DealContactsSection Component
 * Displays a list of contacts associated with a deal.
 */
const DealContactsSection = ({ contacts = [] }) => {
  const navigate = useNavigate();

  const handleViewDetails = (contact) => {
    navigate(`/admin/contacts/${contact.id}`);
  };

  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={3}>
        Contacts
      </Text>
      {contacts.length === 0 ? (
        <Text color="gray.500">No contacts associated with this deal.</Text>
      ) : (
        <Wrap spacing={4}>
          {contacts.map((contact) => {
            const fullName = [contact.name, contact.last_name].filter(Boolean).join(" ");
            const displayName = fullName || contact.email || 'Unnamed Contact';
            return (
              <WrapItem key={contact.id}>
                <ContactTag
                  contactName={displayName}
                  onClick={() => handleViewDetails(contact)}
                />
              </WrapItem>
            );
          })}
        </Wrap>
      )}
    </Box>
  );
};

DealContactsSection.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

export default DealContactsSection; 