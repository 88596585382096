import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";
import { useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";

/**
 * Custom hook to fetch deals with server-side filtering using the unified endpoint.
 * @param {string} accountID - The account ID to fetch deals for.
 * @param {number} pageNumber - The page number to fetch (default: 1).
 * @param {number} itemsPerPage - Number of items per page (default: 10).
 * @param {string} searchQuery - Text to search for in deal names or related fields.
 * @param {string} statusFilter - Filter for deal status ("all", "active", "inactive").
 * @param {array} metricFilters - Array of filter objects for deal metrics.
 * @param {object} dynamicFilters - Object containing various dynamic filters.
 * @param {object} booleanFilters - Object containing boolean filters (yes/no/any).
 * @param {object} sortConfig - Configuration for sorting.
 * @returns {Object} - Contains deals data, loading state, error state, and pagination info.
 */
export function useFetchDealsUnified(
  accountID,
  pageNumber = 1,
  itemsPerPage = 10,
  searchQuery = "",
  statusFilter = "all",
  metricFilters = [],
  dynamicFilters = {},
  booleanFilters = {},
  sortConfig = { key: "last_activity_date", direction: "descending" }
) {
  const { getAccessToken } = useAuthData();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [totalCount, setTotalCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const fetchDeals = async (page = pageNumber) => {
    if (!accountID) {
      console.warn("Missing required accountID for useFetchDealsUnified");
      return { items: [], has_more: false, total_count: 0 };
    }

    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Build query params object
      const queryParams = new URLSearchParams();

      // Add pagination
      queryParams.append("page_number", page.toString());
      queryParams.append("items_per_page", itemsPerPage.toString());

      // Add search query if present
      if (searchQuery && searchQuery.trim() !== "") {
        queryParams.append("search", searchQuery.trim());
      }

      // Add status filter
      if (statusFilter && statusFilter !== "all") {
        queryParams.append("status", statusFilter);
      }

      // Add sorting
      if (sortConfig && sortConfig.key) {
        queryParams.append("sort_by", sortConfig.key);
        queryParams.append(
          "sort_order",
          sortConfig.direction === "ascending" ? "asc" : "desc"
        );
      }

      // Add field filters (checkboxes, options)
      Object.entries(dynamicFilters).forEach(([key, filter]) => {
        if (!filter) return;

        // Handle different filter types
        if (
          key.endsWith("_options") &&
          filter.values &&
          filter.values.length > 0
        ) {
          // Convert field_options to field_id or field_name as needed
          const fieldName = key.replace("_options", "");
          if (key === "user_options") {
            queryParams.append("user_id", filter.values.join(","));
          } else if (key === "pipeline_options") {
            queryParams.append("pipeline_id", filter.values.join(","));
          } else if (key === "stage_options") {
            queryParams.append("stage_name", filter.values.join(","));
          } else if (key === "lead_source_options") {
            queryParams.append("lead_source", filter.values.join(","));
          } else if (key === "priority_options") {
            queryParams.append("priority", filter.values.join(","));
          } else {
            // Generic handling for other options
            queryParams.append(fieldName, filter.values.join(","));
          }
        }
        // Handle amount range
        else if (
          key === "amount" &&
          (filter.min !== undefined || filter.max !== undefined)
        ) {
          if (filter.min !== undefined) {
            queryParams.append("min_amount", filter.min.toString());
          }
          if (filter.max !== undefined) {
            queryParams.append("max_amount", filter.max.toString());
          }
        }
        // Handle date ranges
        else if (filter.startDate && filter.endDate) {
          if (key === "created_at") {
            queryParams.append("start_date", filter.startDate);
            queryParams.append("end_date", filter.endDate);
          } else {
            // For other date fields, use field name as prefix
            queryParams.append(`${key}_start`, filter.startDate);
            queryParams.append(`${key}_end`, filter.endDate);
          }
        }
      });

      // Add boolean filters
      Object.entries(booleanFilters).forEach(([key, value]) => {
        if (value && value !== "any") {
          queryParams.append(key, value === "yes" ? "true" : "false");
        }
      });

      // Add metric filters (as JSON string)
      if (metricFilters && metricFilters.length > 0) {
        // Ensure metric filters are in array format
        const cleanedMetricFilters = Array.isArray(metricFilters)
          ? metricFilters.filter((f) => f && f.metric_key && f.operator)
          : metricFilters.metric_key && metricFilters.operator
          ? [metricFilters]
          : [];

        if (cleanedMetricFilters.length > 0) {
          queryParams.append(
            "metric_filters",
            JSON.stringify(cleanedMetricFilters)
          );
        }
      }

      // Use the unified endpoint with all parameters
      const endpoint = `${target}/api/accounts/${accountID}/deals/unified`;

      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: queryParams,
      });

      // Parse the response to extract deals and pagination data
      let deals = [];
      let responseHasMore = false;
      let responseTotalCount = 0;

      // API returns paginated response object with items, has_more, and total_count
      if (response.data && typeof response.data === "object") {
        if (Array.isArray(response.data.items)) {
          deals = response.data.items.map((deal) => ({
            ...deal,
            // Ensure we have both name properties for compatibility
            deal_name: deal.deal_name || deal.name,
            name: deal.name || deal.deal_name,
          }));
          responseHasMore = response.data.has_more || false;
          responseTotalCount = response.data.total_count || deals.length;
        } else if (Array.isArray(response.data)) {
          // Handle case where API returns array directly (for backwards compatibility)
          deals = response.data.map((deal) => ({
            ...deal,
            deal_name: deal.deal_name || deal.name,
            name: deal.name || deal.deal_name,
          }));
          responseHasMore = false;
          responseTotalCount = deals.length;
        }
      }

      return {
        items: deals,
        has_more: responseHasMore,
        total_count: responseTotalCount,
      };
    } catch (error) {
      console.error("Error fetching deals:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });

      // Show a toast with the error information
      toast({
        title: "Error loading deals",
        description:
          error.response?.status === 400
            ? "Invalid filter parameters. Try adjusting your filters."
            : "There was an issue loading your deals. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      // Return empty response instead of throwing, so the UI can still render
      return { items: [], has_more: false, total_count: 0 };
    }
  };

  // Calculate query key based on all filter parameters
  const queryKey = [
    "deals-unified",
    accountID,
    pageNumber,
    itemsPerPage,
    searchQuery,
    statusFilter,
    JSON.stringify(metricFilters),
    JSON.stringify(dynamicFilters),
    JSON.stringify(booleanFilters),
    sortConfig.key,
    sortConfig.direction,
  ];

  const {
    data = { items: [], has_more: false, total_count: 0 },
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: () => fetchDeals(pageNumber),
    enabled: !!accountID,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  // Update total count and has_more states when data changes
  useEffect(() => {
    if (data) {
      setTotalCount(data.total_count || 0);
      setHasMore(data.has_more || false);
    }
  }, [data]);

  // Prefetch next page if there are more results
  useEffect(() => {
    if (data?.has_more && !isLoading && accountID) {
      const nextPage = pageNumber + 1;
      queryClient.prefetchQuery({
        queryKey: [
          ...queryKey.slice(0, 2), // Keep the base and accountID
          nextPage, // Replace pageNumber with nextPage
          ...queryKey.slice(3), // Keep the rest of the parameters
        ],
        queryFn: () => fetchDeals(nextPage),
        staleTime: 5 * 60 * 1000,
      });
    }
  }, [data, isLoading, accountID, pageNumber, queryKey, queryClient]);

  return {
    deals: data?.items || [],
    isLoading,
    error,
    refetch,
    totalCount,
    hasMore,
  };
}

export default useFetchDealsUnified;
