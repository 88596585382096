import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to manage pipelines and pipeline stages
 * @param {string} accountId - The account ID to fetch pipelines for
 * @param {string} selectedPipelineId - Currently selected pipeline ID
 * @returns {Object} Pipeline data and related functions
 */
export const usePipelines = (accountId, selectedPipelineId) => {
  const { getAccessToken } = useAuthData();
  const queryClient = useQueryClient(); // Initialize query client

  // Fetch pipelines
  const {
    data: pipelines,
    isLoading: isPipelinesLoading,
    error: pipelinesError,
  } = useQuery({
    queryKey: ["pipelines", accountId],
    queryFn: async () => {
      if (!accountId) return [];
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      const response = await axios.get(
        `${target}/api/accounts/${accountId}/pipelines`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data.pipelines || [];
    },
    enabled: !!accountId,
  });

  // Fetch pipeline stages
  const {
    data: pipelineStages,
    isLoading: isStagesLoading,
    error: stagesError,
  } = useQuery({
    queryKey: ["pipelineStages", accountId, selectedPipelineId],
    queryFn: async () => {
      if (!accountId || !selectedPipelineId) return [];
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      const response = await axios.get(
        `${target}/api/accounts/${accountId}/pipelines/${selectedPipelineId}/stages`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data;
      return Array.isArray(data) ? data : data.stages || [];
    },
    enabled: !!accountId && !!selectedPipelineId,
  });

  // Mutation to update stage order
  const updateStageOrderMutation = useMutation({
    mutationFn: async ({ pipelineId, stageIds }) => {
      if (!accountId || !pipelineId || !stageIds || stageIds.length === 0) {
        throw new Error(
          "Missing required parameters for updating stage order."
        );
      }
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.put(
        `${target}/api/accounts/${accountId}/pipelines/${pipelineId}/stages/order`,
        { ids: stageIds }, // Send the ordered IDs in the request body
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json", // Ensure correct content type
          },
        }
      );
    },
    onSuccess: (_, variables) => {
      // Invalidate queries to refetch data after successful reorder
      queryClient.invalidateQueries({ queryKey: ["pipelines", accountId] });
      queryClient.invalidateQueries({
        queryKey: ["pipelineStages", accountId, variables.pipelineId],
      });
      // Potentially invalidate other related queries if necessary
      // queryClient.invalidateQueries({ queryKey: ["allPipelineStages", accountId] });
      // queryClient.invalidateQueries({ queryKey: ["allPipelineDealCounts", accountId] });
    },
    onError: (error) => {
      // Handle error (e.g., show a toast notification)
      console.error("Error updating stage order:", error);
      // Consider adding a user-facing error message here, e.g., using useToast
    },
  });

  // Helper function to get pipeline by ID
  const getPipelineById = (pipelineId) => {
    if (!pipelines) return null;
    return pipelines.find((pipeline) => pipeline.id === pipelineId);
  };

  // Helper function to get stage by ID
  const getStageById = (stageId) => {
    if (!pipelineStages) return null;
    return pipelineStages.find((stage) => stage.id === stageId);
  };

  return {
    // Data
    pipelines,
    pipelineStages,

    // Loading states
    isPipelinesLoading,
    isStagesLoading,
    isLoading: isPipelinesLoading || isStagesLoading,

    // Errors
    pipelinesError,
    stagesError,

    // Helper functions
    getPipelineById,
    getStageById,

    // Metadata
    hasSinglePipeline: pipelines?.length === 1,
    hasMultiplePipelines: pipelines?.length > 1,

    // Mutations
    updateStageOrder: updateStageOrderMutation.mutateAsync,
    isUpdatingStageOrder: updateStageOrderMutation.isPending,
    stageOrderError: updateStageOrderMutation.error,
  };
};
