import React, { useMemo } from 'react';
import { Box, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { ActivityPropType } from './types';

const BookingDisplay = ({ activity }) => {
  // Parse the booking details from activity.description JSON string
  const bookingDetails = useMemo(() => {
    try {
      return JSON.parse(activity.description);
    } catch (error) {
      return null;
    }
  }, [activity.description]);

  // Format price as British pounds
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2
    }).format(price);
  };

  let detailsContent = null;
  if (bookingDetails && bookingDetails.properties) {
    const { customer, guests, total_price, booking_id } = bookingDetails.properties;
    detailsContent = (
      <VStack align="start" spacing={1}>
        {customer && <Text><strong>Customer:</strong> {customer}</Text>}
        {(guests !== undefined) && <Text><strong>Guests:</strong> {guests}</Text>}
        {(total_price !== undefined) && <Text><strong>Total Price:</strong> {formatPrice(total_price)}</Text>}
        {booking_id && <Text><strong>Booking ID:</strong> {booking_id}</Text>}
      </VStack>
    );
  }

  const bgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box p={4} bg={bgColor} borderRadius="md" borderWidth="1px" borderColor={borderColor}>
      {detailsContent ? detailsContent : <Text>No booking details available</Text>}
    </Box>
  );
};

BookingDisplay.propTypes = {
  activity: ActivityPropType,
};

export default BookingDisplay; 