// src/views/lysto/Shared/Tags.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import BaseTag from './BaseTag';
import { MdTrackChanges } from 'react-icons/md';
import { FaExpandAlt, FaExternalLinkAlt, FaPencilAlt } from 'react-icons/fa';
import HoverBox from './HoverBox';
import EditDealModal from '../Deals/EditDealModal';
import EditContactModal from '../Contacts/EditContactModal';
import EditAccountModal from '../Accounts/EditAccountModal';

export const OverviewTag = ({ icon: IconComponent, label, value, colorScheme = "gray", deal, contact, account, fieldToFocus }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);
  const isWebsite = label === "Website" && value !== "N/A";
  const isEditable = deal || contact || account;
  
  const handleClick = () => {
    if (isEditable) {
      onOpen();
    }
  };

  // Only show tooltip if the tag is editable or it's a website link
  // For non-editable tags, don't show a tooltip to avoid redundancy
  const tooltipLabel = isEditable 
    ? (isHovered 
        ? <Flex align="center" gap={2}>
            <FaPencilAlt size={12} />
            <Text>Edit</Text>
          </Flex>
        : `${label}: ${value}`)
    : null; // Set to null for non-editable tags to prevent showing tooltip

  if (isWebsite) {
    return (
      <BaseTag
        colorScheme={colorScheme}
        icon={IconComponent}
        tooltip={`${label}: ${value}`}
      >
        <a 
          href={value.startsWith('http') ? value : `https://${value}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <HoverBox
            icon={IconComponent}
            hoverIcon={FaExternalLinkAlt}
            tooltipLabel="Open Website"
            onClick={(e) => e.preventDefault()}
          >
            <Text
              as="span"
              fontWeight="semibold"
              color="blue.500"
              _hover={{ textDecoration: 'underline' }}
            >
              {value}
            </Text>
          </HoverBox>
        </a>
      </BaseTag>
    );
  }

  return (
    <>
      <BaseTag
        colorScheme={colorScheme}
        icon={isHovered && isEditable ? FaPencilAlt : IconComponent}
        tooltip={tooltipLabel}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        cursor={isEditable ? 'pointer' : 'default'}
      >
        <Text fontSize="sm" fontWeight="medium">
          <Text as="span" fontWeight="bold">{label}: </Text>
          {value}
        </Text>
      </BaseTag>

      {deal && (
        <EditDealModal
          isOpen={isOpen}
          onClose={onClose}
          deal={deal}
          initialFocusField={fieldToFocus}
        />
      )}
      {contact && (
        <EditContactModal
          isOpen={isOpen}
          onClose={onClose}
          contact={contact}
          initialFocusField={fieldToFocus}
        />
      )}
      {account && (
        <EditAccountModal
          isOpen={isOpen}
          onClose={onClose}
          account={account}
          initialFocusField={fieldToFocus}
        />
      )}
    </>
  );
};

export const AttendeeTag = ({ attendee }) => {
  const getStatusStyles = () => {
    switch (attendee.response_status) {
      case 'accepted':
        return {
          colorScheme: 'green',
          statusText: 'Accepted'
        };
      case 'declined':
        return {
          colorScheme: 'red',
          statusText: 'Declined'
        };
      case 'tentative':
        return {
          colorScheme: 'purple',
          statusText: 'Tentative'
        };
      case 'needsAction':
      default:
        return {
          colorScheme: 'yellow',
          statusText: 'Pending'
        };
    }
  };

  const { colorScheme, statusText } = getStatusStyles();
  const role = attendee.organizer ? 'Organizer' : attendee.optional ? 'Optional' : 'Attendee';
  const displayName = attendee.name || attendee.email?.split('@')[0] || 'Unknown';
  const tooltipText = `${role} • ${statusText} • ${attendee.email}`;

  return (
    <BaseTag
      colorScheme={colorScheme}
      tooltip={tooltipText}
      mr={3}
      mb={3}
    >
      <Flex align="center">
        <Text fontSize="sm" fontWeight="medium">
          {displayName}
        </Text>
      </Flex>
    </BaseTag>
  );
};

export const DealTag = ({ deal, onClick }) => (
  <BaseTag
    icon={MdTrackChanges}
    hoverIcon={FaExpandAlt}
    colorScheme="gray"
    onClick={onClick}
    tooltip="View Deal Details"
  >
    <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
      {deal.name || 'Unnamed Deal'}
    </Text>
  </BaseTag>
);

OverviewTag.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  colorScheme: PropTypes.string,
  deal: PropTypes.object,
  contact: PropTypes.object,
  account: PropTypes.object,
  fieldToFocus: PropTypes.string,
};

AttendeeTag.propTypes = {
  attendee: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    response_status: PropTypes.string,
    organizer: PropTypes.bool,
    optional: PropTypes.bool,
  }).isRequired,
};

DealTag.propTypes = {
  deal: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default {
  OverviewTag,
  AttendeeTag,
  DealTag,
};