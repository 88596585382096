// src/hooks/useFetchInbox.js

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

// Define the default sort order
const DEFAULT_SORT_ORDER = "my_tasks";

export function useFetchInbox(
  accountID,
  userID,
  role,
  sortOrder = DEFAULT_SORT_ORDER,
  pageNumber = 1,
  itemsPerPage = 10,
  searchQuery = "",
  sortConfig = null,
  status = "to do",
  taskOwnerFilter = "",
  taskTypeFilter = [],
  taskSubtypeFilter = []
) {
  const [combinedItems, setCombinedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { getAccessToken } = useAuthData();

  // Cache for preloaded next page data
  const nextPageCache = useRef(null);
  const currentRequestId = useRef(0);
  const abortControllerRef = useRef(null);
  const isCurrentlyFetching = useRef(false);
  
  // Preserve the most recent request ID successfully completed
  const lastCompletedRequestId = useRef(0);

  // Helper function to fetch a specific page
  const fetchPage = async (page, signal) => {
    const accessToken = await getAccessToken();
    const target =
      process.env.REACT_APP_NODE_ENV === "local"
        ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
        : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

    // Sort by last_activity_date by default, with newest first
    let effectiveSortOrder = "newest";
    if (sortConfig && sortConfig.key === "last_activity_date") {
      effectiveSortOrder =
        sortConfig.direction === "descending" ? "newest" : "oldest";
    }

    const params = {
      page_number: page,
      items_per_page: itemsPerPage,
      sort_order: effectiveSortOrder,
    };

    // Only add status if it's not an array or if it's an empty array
    if (!Array.isArray(status) || status.length === 0) {
      params.status = status;
    }
    // If status is an array with values, pass it as a comma-separated string
    else if (Array.isArray(status) && status.length > 0) {
      params.status = status.join(",");
    }

    // Add role only if not "my_tasks"
    if (sortOrder !== "my_tasks") {
      params.role = role;
    }

    // Add filter_by_user parameter if "my_tasks" is selected
    if (sortOrder === "my_tasks") {
      params.filter_by_user = userID;
    }
    // Add filter_by_user parameter if task owner filter is provided for all_tasks or completed
    else if (
      (sortOrder === "all_tasks" || sortOrder === "completed") &&
      Array.isArray(taskOwnerFilter) && 
      taskOwnerFilter.length > 0
    ) {
      params.filter_by_user = taskOwnerFilter.join(",");
    }

    // Add task_type filter if provided
    if (taskTypeFilter && taskTypeFilter.length > 0) {
      params.task_type = taskTypeFilter.join(",");
    }

    // Add task_subtype filter if provided
    if (taskSubtypeFilter && taskSubtypeFilter.length > 0) {
      params.task_subtype = taskSubtypeFilter.join(",");
    }

    if (searchQuery && searchQuery.trim() !== "") {
      params.search = searchQuery.trim();
    }

    const response = await axios.get(
      `${target}/api/accounts/${accountID}/users/${userID}/inbox`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params,
        signal,
      }
    );

    return response.data;
  };

  useEffect(() => {
    // If we already have an active request, abort it first
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    const controller = new AbortController();
    abortControllerRef.current = controller;
    const requestId = ++currentRequestId.current;

    const fetchData = async () => {
      if (!accountID || !userID || !sortOrder || !pageNumber || !itemsPerPage) {
        setLoading(false);
        return;
      }

      // Prevent multiple fetches happening at the same time
      if (isCurrentlyFetching.current) {
        return;
      }

      try {
        isCurrentlyFetching.current = true;
        // Check if we have cached data for the current page
        let currentPageData;
        const hasCachedData = nextPageCache.current?.pageNumber === pageNumber;

        if (hasCachedData) {
          currentPageData = nextPageCache.current.data;
          nextPageCache.current = null;
        } else {
          // Only show loading state if we need to fetch
          setLoading(true);
          setError(null);
          currentPageData = await fetchPage(pageNumber, controller.signal);
        }

        // Only update state if this is still the most recent request
        if (requestId === currentRequestId.current) {
          const items = currentPageData?.items || [];
          const isLastPage =
            !currentPageData?.has_more && items.length < itemsPerPage;

          setCombinedItems(items);
          setHasMore(!isLastPage);
          setTotalCount(currentPageData?.total_count || 0);
          setError(null);
          lastCompletedRequestId.current = requestId;

          // If we used cached data, we can set loading to false immediately
          if (hasCachedData) {
            setLoading(false);
          }

          // Preload next page if there might be more data
          if (!isLastPage) {
            try {
              const nextPageData = await fetchPage(
                pageNumber + 1,
                controller.signal
              );
              // Only cache if this is still the most recent request
              if (requestId === currentRequestId.current) {
                nextPageCache.current = {
                  pageNumber: pageNumber + 1,
                  data: nextPageData,
                };
              }
            } catch (err) {
              // Silently handle preload errors
              console.warn("Error preloading next page:", err);
            } finally {
              // Set loading to false after the main request is complete
              if (!hasCachedData) {
                setLoading(false);
              }
            }
          } else if (!hasCachedData) {
            // If there's no next page to preload, set loading to false
            setLoading(false);
          }
        }
      } catch (err) {
        if (err.name === "AbortError" || err.name === "CanceledError") {
          return;
        }
        console.error("Error fetching inbox:", err);
        if (requestId === currentRequestId.current) {
          setError(err);
          setCombinedItems([]);
          setHasMore(false);
          setTotalCount(0);
          setLoading(false);
        }
      } finally {
        isCurrentlyFetching.current = false;
      }
    };

    // Add a small delay to allow for batched state updates
    const timerId = setTimeout(() => {
      fetchData();
    }, 10);

    return () => {
      clearTimeout(timerId);
      controller.abort();
      // Don't reset currentRequestId
    };
  }, [
    accountID,
    userID,
    role,
    sortOrder,
    pageNumber,
    itemsPerPage,
    searchQuery,
    sortConfig?.key,
    sortConfig?.direction,
    status,
    taskOwnerFilter,
    taskTypeFilter,
    taskSubtypeFilter,
    getAccessToken,
  ]);

  return { combinedItems, loading, error, hasMore, totalCount };
}

export default useFetchInbox;
