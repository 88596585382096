// src/components/Contacts/DealTag.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { MdTrackChanges } from 'react-icons/md';
import { FaExpandAlt } from 'react-icons/fa';
import BaseTag from '../Shared/BaseTag';

/**
 * DealTag Component
 * Styled to resemble OverviewTags with specific hover behavior.
 *
 * @param {Object} props
 * @param {string} props.dealName - Name of the deal.
 * @param {Function} props.onClick - Function to call when the tag is clicked.
 * @returns {JSX.Element}
 */
const DealTag = ({ dealName, onClick }) => (
  <BaseTag
    label={dealName || 'Unnamed Deal'}
    icon={MdTrackChanges}
    hoverIcon={FaExpandAlt}
    onClick={onClick}
    tooltip="View Deal Details"
  />
);

DealTag.propTypes = {
  dealName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DealTag;
