// src/views/lysto/Dashboard/components/Sales Performance/TopDealsChart.js

import React, { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import BarCard from "../BarCard";
import { CHART_COLORS } from "../../constants";

// Define month names and their corresponding numbers
const MONTHS = [
  { label: "Jan", value: "Jan", number: 0 },
  { label: "Feb", value: "Feb", number: 1 },
  { label: "Mar", value: "Mar", number: 2 },
  { label: "Apr", value: "Apr", number: 3 },
  { label: "May", value: "May", number: 4 },
  { label: "Jun", value: "Jun", number: 5 },
  { label: "Jul", value: "Jul", number: 6 },
  { label: "Aug", value: "Aug", number: 7 },
  { label: "Sep", value: "Sep", number: 8 },
  { label: "Oct", value: "Oct", number: 9 },
  { label: "Nov", value: "Nov", number: 10 },
  { label: "Dec", value: "Dec", number: 11 },
];

const isDateInYear = (dateStr, year) => {
  const date = new Date(dateStr);
  return date.getFullYear() === year;
};

const isDateInQuarter = (dateStr, year, quarter) => {
  const date = new Date(dateStr);
  return (
    date.getFullYear() === year &&
    Math.floor(date.getMonth() / 3) + 1 === quarter
  );
};

// Add helper function at file scope to check if a deal is won or paid based on stage name
const isWonOrPaidStage = (stageName) => {
  if (!stageName) return false;

  // List of stage names that represent won or paid deals
  const wonOrPaidStages = [
    "Closed-Won",
    "Close Won",
    "Closed Won",
    "Closed-Paid",
    "Close Paid",
    "Closed Paid",
    "Client ( Closed- Paid)", // Added new stage
  ];

  return wonOrPaidStages.some((stage) => stageName === stage);
};

const TopDealsChart = ({
  deals,
  timeFilter,
  setTimeFilter,
  selectedYear,
  selectedQuarter,
  selectedSegment,
  periodRange,
}) => {
  const [barData, setBarData] = useState(null);

  // Add logging for incoming data
  // console.log('TopDealsChart - Received props:', {
  //   dealsCount: deals?.length || 0,
  //   timeFilter,
  //   selectedYear,
  //   selectedQuarter,
  //   selectedSegment,
  //   periodRange,
  //   sampleDeals: deals?.slice(0, 3).map(d => ({
  //     id: d.id,
  //     name: d.deal_name,
  //     amount: d.amount,
  //     stage: d.stage_name,
  //     closeDate: d.close_date
  //   }))
  // });

  // Filter deals by segment
  const filterDealsBySegment = (deals) => {
    if (!selectedSegment || selectedSegment === "All") return deals;

    return deals.filter((deal) => {
      const locations = deal.extra_fields?.No_of_Locations__c;
      if (locations === null || locations === undefined) return false;

      switch (selectedSegment) {
        case "SMB":
          return locations >= 1 && locations <= 20;
        case "Mid-Market":
          return locations > 20 && locations <= 200;
        case "Enterprise":
          return locations > 200;
        default:
          return true;
      }
    });
  };

  // Set initial time filter based on selected period
  useEffect(() => {
    if (selectedQuarter) {
      setTimeFilter(`Q${selectedQuarter}`);
    } else {
      setTimeFilter("all_year");
    }
  }, [selectedQuarter, setTimeFilter]);

  useEffect(() => {
    // Filter deals based on the selected time filter
    const filterDeals = () => {
      // Log count of deals before any filtering
      // console.log('TopDealsChart - Total deals before filtering:', deals?.length || 0);

      // Filter by year first
      let filteredDeals = deals.filter((deal) =>
        isDateInYear(deal.close_date, selectedYear)
      );

      // Apply segment filter
      filteredDeals = filterDealsBySegment(filteredDeals);

      // If a specific month is selected
      if (timeFilter.length === 3) {
        // Month format: "Jan", "Feb", etc.
        const monthIndex = MONTHS.findIndex((m) => m.value === timeFilter);
        if (monthIndex !== -1) {
          filteredDeals = filteredDeals.filter((deal) => {
            const dealDate = new Date(deal.close_date);
            return dealDate.getMonth() === monthIndex;
          });
        }
      }
      // If a specific quarter is selected
      else if (timeFilter.startsWith("Q")) {
        const quarterNumber = parseInt(timeFilter[1]);
        filteredDeals = filteredDeals.filter((deal) =>
          isDateInQuarter(deal.close_date, selectedYear, quarterNumber)
        );
      }

      // MODIFIED: Include any deal that has an amount and isn't explicitly lost/cancelled
      const stageFiltered = filteredDeals.filter((deal) => {
        const stageName = deal.stage_name || "";

        // Exclude these stages
        const isExcluded =
          stageName.toLowerCase().includes("lost") ||
          stageName.toLowerCase().includes("cancel") ||
          stageName.toLowerCase().includes("withdrawn");

        // Explicitly include won/paid stages even if they contain excluded terms
        const isWonOrPaid = isWonOrPaidStage(stageName);

        return (!isExcluded || isWonOrPaid) && deal.amount;
      });

      // console.log('TopDealsChart - After stage and amount filter:', {
      //   count: stageFiltered.length,
      //   dealsBefore: beforeStageCount,
      //   stageBreakdown: filteredDeals.reduce((acc, deal) => {
      //     acc[deal.stage_name] = (acc[deal.stage_name] || 0) + 1;
      //     return acc;
      //   }, {}),
      //   amountMissing: filteredDeals.filter(d => !d.amount).length
      // });

      return stageFiltered;
    };

    const filteredDeals = filterDeals();

    // Sort deals by amount and get top 5
    const topDeals = filteredDeals
      .sort((a, b) => Number(b.amount) - Number(a.amount))
      .slice(0, 5);

    // console.log('TopDealsChart - Top deals after sorting:', {
    //   count: topDeals.length,
    //   deals: topDeals.map(d => ({
    //     id: d.id,
    //     name: d.deal_name,
    //     amount: d.amount,
    //     stage: d.stage_name
    //   }))
    // });

    // Only set chart data if we have deals
    if (topDeals.length > 0) {
      const chartData = {
        title: "Top 5 Deals by Value",
        // Format deal names to be more readable
        labels: topDeals.map((deal) => {
          const name = deal.deal_name.replace(/-$/, ""); // Remove trailing dash
          const amount = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(deal.amount);
          return `${name}\n${amount}`;
        }),
        datasets: [
          {
            label: "Deal Amount",
            data: topDeals.map((deal) => Number(deal.amount)),
            backgroundColor: CHART_COLORS.slice(0, topDeals.length),
          },
        ],
      };

      setBarData(chartData);
    }
  }, [
    deals,
    timeFilter,
    selectedYear,
    selectedQuarter,
    selectedSegment,
    periodRange,
  ]);

  // MODIFIED: Simplified render condition - just check if barData exists
  return (
    <Box pb={4} w="100%">
      {barData ? (
        <BarCard
          barData={barData}
          showLabels={false}
          useCurrencyFormat={true}
        />
      ) : (
        <Box textAlign="center" py={4}>
          <Text>No deals found in the selected time period</Text>
          <Text fontSize="sm" color="gray.500" mt={1}>
            Check browser console for debug information
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default TopDealsChart;
