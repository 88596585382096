import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  VStack,
  Text,
  Button,
  Flex,
  ButtonGroup,
  IconButton,
  Tooltip,
  useColorModeValue,
  Skeleton,
  SkeletonText,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Collapse,
  Icon,
  Portal,
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp, FaPlus, FaTimes, FaFire, FaCalendar, FaUser, FaThermometerHalf, FaExpandAlt } from 'react-icons/fa';
import { RiLightbulbFlashLine } from 'react-icons/ri';
import { ExternalLink } from 'lucide-react';
import { OverviewTag } from '../Shared/Tags';
import { format, isToday, isYesterday, isThisWeek, isThisMonth, differenceInYears } from 'date-fns';
import { ActivityItem } from '../Activities';
import AddDealModal from './AddDealModal';
import { useDealCreationModal } from '../hooks/useDealCreationModal';
import IgnoreActionModal from './IgnoreActionModal';

// Large version of HoverBox specifically for SparkCard
const LargeHoverBox = ({
  children,
  icon = null,
  hoverIcon = null,
  onClick,
  tooltipLabel = "Hover action",
}) => {
  // Match OverviewTag colors - blue.700 for icon and blue.50 for background
  const iconColor = useColorModeValue("blue.700", "blue.300"); 
  const hoverIconColor = useColorModeValue("blue.800", "blue.400");
  const tooltipBg = useColorModeValue("gray.700", "white");
  const tooltipTextColor = useColorModeValue("white", "gray.700");
  const iconBgColor = useColorModeValue("blue.50", "blue.700");
  const hoverIconBgColor = useColorModeValue("blue.100", "blue.800");

  // Added state to track hover and mouse position
  const [isHovered, setIsHovered] = React.useState(false);
  const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 });

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleMouseMove = (e) => setMousePos({ x: e.clientX, y: e.clientY });

  return (
    <>
      <Box
        display="inline-flex"
        alignItems="center"
        borderRadius="lg"
        cursor="pointer"
        position="relative"
        transition="all 0.2s"
        role="group"
        width="100%"
        height="32px" // Increased height from 24px to 32px
        _hover={{
          "& .default-icon": { opacity: 0 },
          "& .hover-icon": { opacity: 1 },
        }}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        {icon && (
          <Box
            className="default-icon"
            mr={3} // Increased margin
            position="absolute"
            transition="opacity 0.2s"
            opacity={1}
            flexShrink={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            bg={iconBgColor}
            width="32px" // Increased from 24px to 32px
            height="32px" // Increased from 24px to 32px
            left={0}
            top="50%"
            transform="translateY(-50%)"
            overflow="hidden"
            borderWidth="1px"
            borderColor="blue.200"
          >
            <Icon as={icon} color={iconColor} boxSize="20px" /> {/* Increased icon size from 16px to 20px */}
          </Box>
        )}
        {hoverIcon && (
          <Box
            className="hover-icon"
            mr={3} // Increased margin
            position="absolute"
            transition="opacity 0.2s"
            opacity={0}
            flexShrink={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            bg={hoverIconBgColor}
            width="32px" // Increased from 24px to 32px
            height="32px" // Increased from 24px to 32px
            left={0}
            top="50%"
            transform="translateY(-50%)"
            overflow="hidden"
            borderWidth="1px"
            borderColor="blue.300"
          >
            <Icon as={hoverIcon} color={hoverIconColor} boxSize="20px" /> {/* Increased icon size from 16px to 20px */}
          </Box>
        )}
        <Box
          as="span"
          display="flex"
          alignItems="center"
          textAlign="left"
          width="100%"
          pl={icon ? "40px" : "0"} // Increased padding from 32px to 40px
          pr="4px"
          height="100%"
          fontSize="md" // Slightly larger text
        >
          {children}
        </Box>
      </Box>
      {isHovered && tooltipLabel && (
        <Portal>
          <Box
            position="fixed"
            left={mousePos.x + 10}
            top={mousePos.y + 10}
            bg={tooltipBg}
            color={tooltipTextColor}
            px="8px"
            py="4px"
            fontSize="sm"
            borderRadius="md"
            zIndex="tooltip"
            pointerEvents="none"
            boxShadow="sm"
          >
            {tooltipLabel}
          </Box>
        </Portal>
      )}
    </>
  );
};

const SparkCard = ({ spark, onAddDeal, onIgnore, isCreatingDeal, currentPage, isPageLoading }) => {
  const [isActivitiesModalOpen, setIsActivitiesModalOpen] = useState(false);
  const [isAddDealModalOpen, setIsAddDealModalOpen] = useState(false);
  const [isIgnoreModalOpen, setIsIgnoreModalOpen] = useState(false);
  const [isActivitiesExpanded, setIsActivitiesExpanded] = useState(false);
  const { isLoading, setDealCreationData } = useDealCreationModal();
  
  useEffect(() => {
    setIsActivitiesModalOpen(false);
  }, [currentPage]);
  
  // If the page is currently loading, render a skeleton placeholder
  if(isPageLoading) {
    return (
      <Box
        bg="white"
        p={6}
        borderRadius="md"
        boxShadow="sm"
        borderWidth="1px"
        borderColor="gray.200"
        height="100%"
      >
        <VStack spacing={4} align="stretch">
          {/* Header */}
          <HStack justify="space-between">
            <Skeleton height="24px" width="200px" />
            <HStack spacing={2}>
              <Skeleton height="32px" width="70px" />
              <Skeleton height="32px" width="90px" />
            </HStack>
          </HStack>

          {/* Subject line */}
          <Skeleton height="20px" width="80%" />

          {/* Tags */}
          <HStack spacing={4}>
            <Skeleton height="24px" width="120px" borderRadius="full" />
            <Skeleton height="24px" width="150px" borderRadius="full" />
            <Skeleton height="24px" width="130px" borderRadius="full" />
          </HStack>

          {/* AI Recommendation */}
          <Box>
            <Skeleton height="20px" width="150px" mb={2} />
            <SkeletonText noOfLines={2} spacing={2} />
          </Box>

          {/* Previous Messages */}
          <Box>
            <Skeleton height="20px" width="150px" mb={2} />
            <VStack spacing={3} align="stretch">
              <SkeletonText noOfLines={3} spacing={2} />
              <SkeletonText noOfLines={3} spacing={2} />
              <SkeletonText noOfLines={3} spacing={2} />
            </VStack>
          </Box>
        </VStack>
      </Box>
    );
  }

  const getSparkDetails = (score) => {
    if (score >= 0.9) {
      return { colorScheme: "red", label: "Hot", icon: FaFire };
    } else if (score >= 0.7) {
      return { colorScheme: "orange", label: "Warm", icon: FaThermometerHalf };
    }
    return { colorScheme: "blue", label: "Cool", icon: FaThermometerHalf };
  };
  
  const getExternalCrmAccountId = () => {
    if (!spark.contacts || !Array.isArray(spark.contacts)) return null;
    const userDomain = spark.user_domain?.toLowerCase();
    const externalContact = spark.contacts.find(contact => {
      const recipientDomain = contact.email.split('@')[1]?.toLowerCase();
      const isGmail = recipientDomain === 'gmail.com' || recipientDomain === 'googlemail.com';
      return isGmail || recipientDomain !== userDomain;
    });
    return externalContact?.crm_account_id;
  };

  // Get all external contacts (including Gmail addresses)
  const getAllExternalContacts = () => {
    if (!spark.contacts || !Array.isArray(spark.contacts)) return [];
    const userDomain = spark.user_domain?.toLowerCase();
    return spark.contacts.filter(contact => {
      const recipientDomain = contact.email?.split('@')[1]?.toLowerCase();
      // Include all external emails, including Gmail
      return recipientDomain && recipientDomain !== userDomain;
    });
  };

  const handleOpenModal = async () => {
    if (!spark) {
      return;
    }
    
    try {
      // Get the email domain if available
      const emailDomain = senderEmail?.split('@')?.[1] || null;
      
      // Get the last activity for timestamp (using the same one used for formattedDate)
      const lastActivity = lastEmailActivity;
      
      // Prepare data to pass to the modal
      const modalData = {
        spark_id: spark.id,
        subject: spark.subject || "No Subject",
        spark_score: spark.spark_score,
        sender_email: senderEmail,
        sender_name: senderName,
        domain: emailDomain,
        last_activity_at: lastActivity?.activity_data?.data?.received_at || new Date().toISOString(),
        spark_description: spark.description || null,
        pipeline_id: spark.pipeline_id || null,
        companyName: companyName // Add companyName to the data passed to the modal
      };
      
      setIsAddDealModalOpen(true);
      // Store data in creation modal context if using hook, or pass directly as prop
      setDealCreationData?.(modalData);
    } catch (error) {
      console.error('Error opening modal:', error);
    }
  };
  
  const getFormattedDate = (timestamp) => {
    try {
      if (!timestamp) return 'No date';
      const date = new Date(timestamp);
      // Check if date is valid
      if (isNaN(date.getTime())) return 'Invalid date';
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  // Get relative time for grouping messages
  const getRelativeTimeGroup = (timestamp) => {
    try {
      if (!timestamp) return 'Unknown Date';
      const date = new Date(timestamp);
      const now = new Date();
      
      // Check if date is valid
      if (isNaN(date.getTime())) return 'Unknown Date';
      
      if (isToday(date)) return 'Today';
      if (isYesterday(date)) return 'Yesterday';
      if (isThisWeek(date)) return 'This Week';
      if (isThisMonth(date)) return 'This Month';
      
      // Check if it's last month
      const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1);
      if (date.getMonth() === lastMonth.getMonth() && date.getFullYear() === lastMonth.getFullYear()) {
        return 'Last Month';
      }
      
      // For dates within the current year
      if (date.getFullYear() === now.getFullYear()) {
        // Get month name
        const monthName = format(date, 'MMMM');
        return monthName;
      }
      
      // For dates in the previous year
      if (date.getFullYear() === now.getFullYear() - 1) {
        // Get month name with "Last" prefix
        const monthName = format(date, 'MMMM');
        return `Last ${monthName}`;
      }
      
      // For dates more than a year ago
      const yearsDiff = differenceInYears(now, date);
      if (yearsDiff <= 0) {
        // This should not happen with proper date handling, but just in case
        return format(date, 'MMMM yyyy');
      } else if (yearsDiff === 1) {
        return 'Last Year';
      } else {
        return `${yearsDiff} Years Ago`;
      }
    } catch (error) {
      console.error('Error calculating relative time:', error);
      return 'Unknown Date';
    }
  };

  const getDomainFromParticipants = (spark) => {
    try {
      // Get the user's domain to exclude it
      const userDomain = spark.user_domain?.toLowerCase();
      
      // Helper function to split and capitalize concatenated words
      const splitAndCapitalizeWords = (text) => {
        // First split by any existing separators
        let words = text.split(/[._-]/);
        
        // For each word, check for camelCase or concatenated words
        words = words.flatMap(word => {
          // Split camelCase (e.g., "halulaProperties" -> ["halula", "Properties"])
          const camelCaseWords = word.split(/(?=[A-Z])/);
          
          // Split concatenated lowercase words by looking for common word boundaries
          if (camelCaseWords.length === 1) {
            // Common business word patterns
            const commonWords = [
              // Property & Real Estate
              'properties', 'property', 'realty', 'estate', 'estates', 'homes', 'house', 'housing',
              // Travel & Hospitality
              'travels', 'travel', 'stays', 'stay', 'holiday', 'holidays', 'vacations', 'rentals',
              'hospitality', 'host', 'hosting', 'lodging', 'accommodations', 'getaways', 'retreats',
              'villas', 'cottages', 'apartments', 'rooms', 'suites', 'barn', 'barns',
              // Business Services
              'solutions', 'services', 'technologies', 'systems', 'group', 'consulting',
              'partners', 'associates', 'advisors', 'management', 'ventures',
              // Beauty & Wellness
              'beauty', 'wellness', 'spa', 'salon', 'cosmetics', 'skincare', 'care',
              // Common Joining Words
              'and', 'the', 'of', 'in', 'at', 'by', 'with',
              // Location Related
              'london', 'cornish', 'uk', 'british', 'england', 'scotland', 'wales', 'irish'
            ];

            // Try to match common words at the end first
            for (const commonWord of commonWords) {
              const pattern = new RegExp(`(.*?)(${commonWord})$`, 'i');
              const match = word.toLowerCase().match(pattern);
              if (match && match[1] && match[2]) {
                // Recursively split the first part if it's still a compound word
                const firstPart = splitAndCapitalizeWords(match[1]);
                const secondPart = match[2].charAt(0).toUpperCase() + match[2].slice(1).toLowerCase();
                return [firstPart, secondPart];
              }
            }

            // If no common word found at the end, try finding them at the start
            for (const commonWord of commonWords) {
              const pattern = new RegExp(`^(${commonWord})(.*?)`, 'i');
              const match = word.toLowerCase().match(pattern);
              if (match && match[1] && match[2]) {
                const firstPart = match[1].charAt(0).toUpperCase() + match[1].slice(1).toLowerCase();
                const secondPart = splitAndCapitalizeWords(match[2]);
                return [firstPart, secondPart];
              }
            }

            // If still no match, try to split by common patterns
            const commonPatterns = [
              /([a-z]+)(stay|host|travel|beauty|home|house|rental)s?/i,
              /([a-z]+)(and)([a-z]+)/i
            ];

            for (const pattern of commonPatterns) {
              const match = word.match(pattern);
              if (match) {
                return match.slice(1).filter(Boolean).map(part => 
                  part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
                );
              }
            }
          }
          
          return camelCaseWords;
        });
        
        // Clean and capitalize each word
        return words
          .flat() // Flatten nested arrays from recursive calls
          .filter(word => word.length > 0)
          .map(word => {
            // Special case for joining words to keep them lowercase unless at start
            const joiningWords = ['and', 'the', 'of', 'in', 'at', 'by', 'with'];
            if (joiningWords.includes(word.toLowerCase())) {
              return word.toLowerCase();
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
          .join(' ');
      };

      // First check CRM domain if available
      if (spark.crm_domain) {
        const domain = spark.crm_domain.split('.')[0];
        return splitAndCapitalizeWords(domain);
      }

      // Helper function to check if domain is Gmail
      const isGmailDomain = (domain) => {
        const gmailDomains = ['gmail.com', 'googlemail.com'];
        return gmailDomains.includes(domain.toLowerCase());
      };

      // Helper function to capitalize email username
      const capitalizeEmailUsername = (email) => {
        const username = email.split('@')[0];
        return splitAndCapitalizeWords(username);
      };

      // Helper function to get full name from activity
      const getFullNameFromActivity = (activity) => {
        const senderName = activity.activity_data?.data?.sender_name;
        if (!senderName || !senderName.trim()) return null;
        
        // Split and clean name parts
        const nameParts = senderName.split(' ').map(part => part.trim()).filter(Boolean);
        if (nameParts.length === 0) return null;
        
        // Return full name with proper capitalization
        return nameParts.map(part => 
          splitAndCapitalizeWords(part)
        ).join(' ');
      };

      // Find all external email activities (non-internal)
      const externalEmailActivities = spark.activities?.filter(activity => 
        activity.activity_type === 'email' && 
        !activity.is_internal &&
        activity.activity_data?.data?.sender_email
      ) || [];

      // Sort activities by received_at date to get the most recent first
      const sortedActivities = externalEmailActivities.sort((a, b) => {
        const dateA = new Date(a.activity_data?.data?.received_at || 0);
        const dateB = new Date(b.activity_data?.data?.received_at || 0);
        return dateB - dateA;
      });

      for (const activity of sortedActivities) {
        const senderEmail = activity.activity_data?.data?.sender_email;
        const senderDomain = senderEmail?.split('@')[1]?.toLowerCase();

        // For Gmail accounts, first try sender name
        if (senderDomain && isGmailDomain(senderDomain)) {
          // Try to get full name first
          const fullName = getFullNameFromActivity(activity);
          if (fullName) return fullName;

          // Use capitalized email username as fallback
          if (senderEmail) {
            return capitalizeEmailUsername(senderEmail);
          }
        }

        // For non-Gmail domains
        if (senderDomain && !isGmailDomain(senderDomain) && senderDomain !== userDomain) {
          const domain = senderDomain.split('.')[0];
          return domain.charAt(0).toUpperCase() + domain.slice(1);
        }
      }

      // Check for non-Gmail recipients if no sender found
      const nonGmailRecipient = spark.contacts?.find(contact => {
        const domain = contact.email?.split('@')[1]?.toLowerCase();
        return domain && domain !== userDomain && !isGmailDomain(domain);
      });

      if (nonGmailRecipient) {
        const domain = nonGmailRecipient.email.split('@')[1].split('.')[0];
        return domain.charAt(0).toUpperCase() + domain.slice(1);
      }

      return 'Unknown';
    } catch (error) {
      console.error('Error getting domain from participants:', error);
      return 'Unknown';
    }
  };

  // Add helper function to capitalize names
  const capitalizeName = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const handleAddDeal = (formData) => {
    // Get the CRM account ID from the external recipient
    const crmAccountId = getExternalCrmAccountId();
    
    // Get the most recent email activity for sender info
    const lastEmailActivity = spark.activities?.find(activity => 
      activity.activity_data?.activity_type === 'email'
    );

    const sparkData = {
      crm_account_id: crmAccountId,
      company_name: getDomainFromParticipants(spark),
      activities: spark.activities?.map(activity => ({
        id: activity.id,
        activity_type: activity.activity_data?.activity_type,
        activity_data: activity.activity_data,
        is_internal: activity.is_internal,
        classification: activity.classification,
        created_at: activity.created_at,
        updated_at: activity.updated_at
      })),
      user_domain: spark.user_domain,
      recipients: spark.recipients?.map(recipient => ({
        email: recipient.email,
        name: recipient.name,
        recipient_type: recipient.recipient_type,
        crm_account_id: recipient.crm_account_id
      })),
      thread_id: spark.thread_id,
      sender: lastEmailActivity ? {
        email: lastEmailActivity.activity_data?.data?.sender_email,
        name: lastEmailActivity.activity_data?.data?.sender_name
      } : null,
      subject: spark.subject,
      email_link: spark.email_link,
      spark_score: spark.spark_score,
      reason: spark.reason,
      // Include the pipeline_id from the spark data
      pipeline_id: spark.pipeline_id || null
    };
    
    onAddDeal(formData, sparkData);
    setIsAddDealModalOpen(false);
  };

  // Transform activities into the format expected by ActivityItem
  const transformedActivities = React.useMemo(() => {
    if (!spark?.activities) return [];

    return spark.activities.map(activity => {
      // Helper function to capitalize email username
      const getFormattedSenderName = (senderName, senderEmail) => {
        if (senderName) {
          // Split full name and capitalize each part
          const nameParts = senderName.split(' ');
          return nameParts.map(part => capitalizeName(part)).join(' ');
        }
        if (senderEmail) {
          const username = senderEmail.split('@')[0];
          return capitalizeName(username);
        }
        return 'Unknown Sender';
      };

      return {
        id: activity.id,
        account_id: activity.account_id,
        user_id: activity.user_id,
        owner_id: activity.user_id,
        user_name: capitalizeName(activity.user_name),
        user_last_name: capitalizeName(activity.user_last_name),
        user_email: activity.user_email,
        deal_id: activity.deal_id,
        activity_id: activity.activity_id,
        activity_type: activity.activity_data.activity_type,
        activity_date: activity.created_at,
        completed_date_time: activity.activity_data.data.received_at,
        status: "completed",
        title: activity.activity_data.data.subject,
        description: activity.activity_data.data.body,
        activity_data: {
          activity_type: activity.activity_data.activity_type,
          data: {
            body: activity.activity_data.data.body,
            received_at: activity.activity_data.data.received_at,
            recipients: activity.activity_data.data.recipients?.map(recipient => ({
              email: recipient.email,
              recipient_type: recipient.recipient_type || 'to',
              name: recipient.name ? capitalizeName(recipient.name) : undefined
            })) || [],
            sender_name: getFormattedSenderName(
              activity.activity_data.data.sender_name,
              activity.activity_data.data.sender_email
            ),
            sender_email: activity.activity_data.data.sender_email,
            subject: activity.activity_data.data.subject
          },
          version: activity.activity_data.version
        },
        source_system: "email",
        timestamp: activity.activity_data.data.received_at,
        created_at: activity.created_at || activity.activity_data.data.received_at,
        updated_at: activity.updated_at || activity.activity_data.data.received_at,
        is_internal: activity.is_internal,
        classification: activity.classification,
        email_link: spark.email_link,
        // Add timeGroup for grouping
        timeGroup: getRelativeTimeGroup(activity.activity_data.data.received_at)
      };
    });
  }, [spark?.activities]);

  // Group activities by time
  const groupedActivities = React.useMemo(() => {
    const groups = {};
    
    transformedActivities.forEach(activity => {
      const timeGroup = activity.timeGroup;
      if (!groups[timeGroup]) {
        groups[timeGroup] = [];
      }
      groups[timeGroup].push(activity);
    });
    
    // Sort groups by recency (Today, Yesterday, This Week, etc.)
    const timeGroupOrder = {
      'Today': 1,
      'Yesterday': 2,
      'This Week': 3,
      'This Month': 4,
      'Last Month': 5
    };
    
    // Month names in order from most recent to oldest
    const monthNames = [
      'December', 'November', 'October', 'September', 
      'August', 'July', 'June', 'May', 
      'April', 'March', 'February', 'January'
    ];
    
    return Object.entries(groups)
      .sort(([groupA], [groupB]) => {
        // First check if it's one of our predefined time periods
        const orderA = timeGroupOrder[groupA];
        const orderB = timeGroupOrder[groupB];
        
        // If both are predefined time periods, sort by their order
        if (orderA !== undefined && orderB !== undefined) {
          return orderA - orderB;
        }
        
        // If only one is a predefined time period, it comes first
        if (orderA !== undefined) return -1;
        if (orderB !== undefined) return 1;
        
        // If both are month names, sort by month order
        const monthIndexA = monthNames.indexOf(groupA);
        const monthIndexB = monthNames.indexOf(groupB);
        
        if (monthIndexA !== -1 && monthIndexB !== -1) {
          return monthIndexA - monthIndexB;
        }
        
        // If only one is a month name, it comes before year labels
        if (monthIndexA !== -1) return -1;
        if (monthIndexB !== -1) return 1;
        
        // If one is "Last Year", it comes before other year labels
        if (groupA === 'Last Year') return -1;
        if (groupB === 'Last Year') return 1;
        
        // For "X Years Ago" labels, extract the number and compare
        const yearsA = parseInt(groupA.match(/^(\d+)/)?.[1] || '999');
        const yearsB = parseInt(groupB.match(/^(\d+)/)?.[1] || '999');
        
        return yearsA - yearsB;
      });
  }, [transformedActivities]);

  // Show only first 3 activities in card
  const visibleActivities = transformedActivities.slice(0, 3);
  const hasMoreActivities = transformedActivities.length > 3;

  // Render activities grouped by time
  const renderActivitiesList = (activities, showAll = false) => {
    if (showAll) {
      // When showing all activities, group them by time
      return (
        <VStack spacing={6} align="stretch" width="100%">
          {groupedActivities.map(([timeGroup, groupActivities]) => (
            <Box key={timeGroup}>
              <Text fontSize="sm" fontWeight="medium" color="gray.600" mb={3}>
                {timeGroup}
              </Text>
              <VStack spacing={4} align="stretch">
                {groupActivities.map((activity) => (
                  <Box key={activity.id}>
                    <ActivityItem activity={activity} hideMoreButton={true} showDealLabels={false} />
                  </Box>
                ))}
              </VStack>
            </Box>
          ))}
        </VStack>
      );
    } else {
      // When showing limited activities (first 3), don't group them
      return (
        <VStack spacing={4} align="stretch" width="100%">
          {activities.map((activity) => (
            <Box key={activity.id}>
              <ActivityItem activity={activity} hideMoreButton={true} showDealLabels={false} />
            </Box>
          ))}
        </VStack>
      );
    }
  };

  // Get the most recent email activity
  const lastEmailActivity = spark.activities?.find(activity => 
    activity.activity_data?.activity_type === 'email'
  );

  const sparkDetails = getSparkDetails(spark.spark_score);
  const formattedDate = getFormattedDate(lastEmailActivity?.activity_data?.data?.received_at);
  const senderEmail = lastEmailActivity?.activity_data?.data?.sender_email;
  const senderName = lastEmailActivity?.activity_data?.data?.sender_name;
  const companyName = getDomainFromParticipants(spark);

  // Get the thread_id from the first activity
  const getThreadId = (spark) => {
    if (!spark?.activities?.length) return null;
    return spark.activities[0]?.activity_data?.data?.client_thread_id;
  };

  const handleIgnoreClick = () => {
    setIsIgnoreModalOpen(true);
  };

  return (
    <>
      <Box
        height="fit-content"
        alignSelf="flex-start"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="sm"
        borderWidth="1px"
        borderColor="gray.200"
        _hover={{
          borderColor: "gray.300",
          boxShadow: "md",
        }}
        transition="all 0.2s"
        display="flex"
        flexDirection="column"
      >
        <VStack spacing={6} align="stretch" flex={1}>
          {/* Title Bar */}
          <Flex justify="space-between" align="center">
            <Box position="relative" flex={1} maxWidth="fit-content">
              <LargeHoverBox
                icon={RiLightbulbFlashLine}
                hoverIcon={FaExpandAlt}
                onClick={() => setIsActivitiesModalOpen(true)}
                tooltipLabel="View All Messages"
              >
                <Flex align="center">
                  <Text fontSize="lg" fontWeight="semibold" color="gray.700">
                    {companyName} Deal
                  </Text>
                </Flex>
              </LargeHoverBox>
            </Box>
            <Flex align="center" gap={2} ml="auto">
              {spark.email_link && (
                <Tooltip 
                  label="View email thread in Gmail" 
                  placement="top"
                  hasArrow
                >
                  <IconButton
                    as="a"
                    href={spark.email_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={<ExternalLink size={18} />}
                    variant="ghost"
                    size="xs"
                    padding={0}
                    minW="auto"
                    height="auto"
                    color="gray.500"
                    _hover={{ color: "blue.500" }}
                    aria-label="View in Gmail"
                  />
                </Tooltip>
              )}
              <ButtonGroup size="sm" spacing={2}>
                <Button
                  leftIcon={<FaTimes />}
                  variant="ghost"
                  colorScheme="gray"
                  onClick={handleIgnoreClick}
                  size="sm"
                >
                  Ignore
                </Button>
                <Button
                  leftIcon={<FaPlus />}
                  variant="solid"
                  colorScheme="blue"
                  onClick={handleOpenModal}
                  size="sm"
                  isLoading={isCreatingDeal}
                >
                  Add Deal
                </Button>
              </ButtonGroup>
            </Flex>
          </Flex>

          {/* Subject */}
          {spark?.subject && (
            <Text fontSize="md" color="gray.700" fontWeight="medium">
              Subject: {spark.subject}
            </Text>
          )}

          {/* Overview Tags */}
          <Flex gap={3} flexWrap="wrap">
            <OverviewTag
              icon={sparkDetails.icon}
              label="Confidence"
              value={`${Math.round(spark.spark_score * 100)}%`}
              colorScheme={sparkDetails.colorScheme}
            />
            {senderEmail && (
              <OverviewTag
                icon={FaUser}
                label="From"
                value={senderName || senderEmail.split('@')[0]}
                colorScheme="purple"
                tooltip={`${senderName || senderEmail.split('@')[0]} <${senderEmail}>`}
              />
            )}
            <OverviewTag
              icon={FaCalendar}
              label="Last Activity"
              value={formattedDate}
              colorScheme="green"
            />
          </Flex>

          {/* AI Recommendation Section */}
          <VStack spacing={4} align="stretch">
            <Text fontSize="md" fontWeight="semibold" color="gray.700">
              AI Recommendation
            </Text>
            <Box
              bg={useColorModeValue("gray.50", "gray.700")}
              p={6}
              borderRadius="md"
            >
              <Text fontSize="sm" color="gray.600" lineHeight="tall">
                {spark.reason}
              </Text>
            </Box>
          </VStack>

          {/* Activities Section */}
          <VStack spacing={4} align="stretch">
            <Text fontSize="md" fontWeight="semibold" color="gray.700">
              Messages
            </Text>
            <VStack spacing={4} align="stretch">
              {/* Always show first 3 activities */}
              {renderActivitiesList(visibleActivities)}
              
              {/* When expanded, only show additional activities (not the first 3) */}
              <Collapse in={isActivitiesExpanded} animateOpacity>
                {groupedActivities.length > 0 && (
                  <VStack spacing={4} align="stretch" width="100%" pt={2}>
                    {groupedActivities.map(([timeGroup, groupActivities]) => {
                      // Filter out activities that are already shown in the first 3
                      const filteredActivities = groupActivities.filter(
                        activity => !visibleActivities.some(visibleActivity => visibleActivity.id === activity.id)
                      );
                      
                      // Only render this group if it has activities after filtering
                      if (filteredActivities.length === 0) return null;
                      
                      return (
                        <Box key={timeGroup}>
                          <Text fontSize="sm" fontWeight="medium" color="gray.600" mb={2}>
                            {timeGroup}
                          </Text>
                          <VStack spacing={3} align="stretch">
                            {filteredActivities.map((activity) => (
                              <Box key={activity.id}>
                                <ActivityItem activity={activity} hideMoreButton={true} showDealLabels={false} />
                              </Box>
                            ))}
                          </VStack>
                        </Box>
                      );
                    })}
                  </VStack>
                )}
              </Collapse>
              
              {hasMoreActivities && (
                <Flex justify="center" width="100%" mt={2}>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setIsActivitiesExpanded(!isActivitiesExpanded)}
                    rightIcon={isActivitiesExpanded ? <FaChevronUp /> : <FaChevronDown />}
                  >
                    {isActivitiesExpanded ? 'Show Less' : `Show ${transformedActivities.length - 3} More`}
                  </Button>
                </Flex>
              )}
            </VStack>
          </VStack>
        </VStack>
      </Box>

      {/* Activities Modal */}
      <Modal 
        isOpen={isActivitiesModalOpen} 
        onClose={() => setIsActivitiesModalOpen(false)}
        size="4xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent maxH="90vh">
          <ModalHeader p={8}>
            <Flex justify="space-between" align="center">
              <Text fontSize="2xl" fontWeight="bold">{companyName} Deal</Text>
              <ModalCloseButton position="static" />
            </Flex>
          </ModalHeader>
          <ModalBody px={8} pb={8}>
            <VStack spacing={6} align="stretch">
              {/* Subject */}
              {spark?.subject && (
                <Text fontSize="md" color="gray.700" fontWeight="medium">
                  Subject: {spark.subject}
                </Text>
              )}

              {/* Overview Tags */}
              <Box>
                <Text fontSize="md" fontWeight="semibold" mb={3}>
                  Overview
                </Text>
                <Flex gap={3} flexWrap="wrap">
                  <OverviewTag
                    icon={sparkDetails.icon}
                    label="Confidence"
                    value={`${Math.round(spark.spark_score * 100)}%`}
                    colorScheme={sparkDetails.colorScheme}
                  />
                  {senderEmail && (
                    <OverviewTag
                      icon={FaUser}
                      label="From"
                      value={senderName || senderEmail.split('@')[0]}
                      colorScheme="purple"
                      tooltip={`${senderName || senderEmail.split('@')[0]} <${senderEmail}>`}
                    />
                  )}
                  <OverviewTag
                    icon={FaCalendar}
                    label="Last Activity"
                    value={formattedDate}
                    colorScheme="green"
                  />
                </Flex>
              </Box>

              {/* AI Recommendation */}
              <VStack spacing={4} align="stretch">
                <Text fontSize="md" fontWeight="semibold" color="gray.700">
                  AI Recommendation
                </Text>
                <Box
                  bg={useColorModeValue("gray.50", "gray.700")}
                  p={6}
                  borderRadius="md"
                >
                  <Text fontSize="sm" color="gray.600" lineHeight="tall">
                    {spark.reason}
                  </Text>
                </Box>
              </VStack>

              {/* All Activities */}
              <VStack spacing={4} align="stretch">
                <Text fontSize="md" fontWeight="semibold" color="gray.700">
                  All Messages
                </Text>
                {renderActivitiesList(transformedActivities, true)}
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter borderTop="1px" borderColor="gray.100" p={8}>
            <ButtonGroup size="sm" spacing={3}>
              <Button
                leftIcon={<FaTimes />}
                variant="outline"
                colorScheme="gray"
                onClick={() => {
                  onIgnore();
                  setIsActivitiesModalOpen(false);
                }}
              >
                Ignore
              </Button>
              <Button
                leftIcon={<FaPlus />}
                variant="solid"
                colorScheme="blue"
                onClick={() => {
                  handleOpenModal();
                  setIsActivitiesModalOpen(false);
                }}
                isLoading={isCreatingDeal}
              >
                Add Deal
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AddDealModal
        isOpen={isAddDealModalOpen}
        onClose={() => setIsAddDealModalOpen(false)}
        onSubmit={handleAddDeal}
        data={{
          crm_account_id: spark.crm_account_id,
          external_contacts: getAllExternalContacts(),
          spark: {
            company_name: getDomainFromParticipants(spark),
            activities: spark.activities,
            user_domain: spark.user_domain,
            recipients: spark.recipients,
            thread_id: getThreadId(spark),
            sender: {
              email: lastEmailActivity?.activity_data?.data?.sender_email,
              name: lastEmailActivity?.activity_data?.data?.sender_name
            },
            subject: spark.subject,
            email_link: spark.email_link
          }
        }}
        isLoading={isLoading}
      />

      <IgnoreActionModal
        isOpen={isIgnoreModalOpen}
        onClose={() => setIsIgnoreModalOpen(false)}
        data={{
          crm_account_id: spark.crm_account_id,
        }}
      />
    </>
  );
};

SparkCard.propTypes = {
  spark: PropTypes.shape({
    thread_id: PropTypes.string,
    spark_score: PropTypes.number.isRequired,
    user_domain: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    subject: PropTypes.string,
    email_link: PropTypes.string,
    crm_account_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    recipients: PropTypes.arrayOf(PropTypes.shape({
      crm_account_id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      recipient_type: PropTypes.oneOf(['to', 'cc', 'bcc']).isRequired,
    })).isRequired,
    activities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      account_id: PropTypes.number.isRequired,
      user_id: PropTypes.number.isRequired,
      user_name: PropTypes.string.isRequired,
      user_last_name: PropTypes.string.isRequired,
      user_email: PropTypes.string.isRequired,
      deal_id: PropTypes.number.isRequired,
      activity_id: PropTypes.number.isRequired,
      activity_type: PropTypes.string.isRequired,
      activity_data: PropTypes.shape({
        activity_type: PropTypes.string.isRequired,
        data: PropTypes.shape({
          body: PropTypes.string.isRequired,
          client_thread_id: PropTypes.string.isRequired,
          received_at: PropTypes.string.isRequired,
          sender_email: PropTypes.string.isRequired,
          sender_name: PropTypes.string.isRequired,
          subject: PropTypes.string.isRequired,
        }).isRequired,
        version: PropTypes.string.isRequired,
      }).isRequired,
      classification: PropTypes.shape({
        activity_purpose: PropTypes.string.isRequired,
        communication_medium: PropTypes.string.isRequired,
        deal_stage: PropTypes.string.isRequired,
        key_action: PropTypes.string.isRequired,
        entities_involved: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.any),
          PropTypes.oneOf([null]),
        ]).isRequired,
        status: PropTypes.string.isRequired,
      }).isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      is_internal: PropTypes.bool.isRequired,
    })).isRequired,
  }).isRequired,
  onAddDeal: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  isCreatingDeal: PropTypes.bool,
  currentPage: PropTypes.number,
  isPageLoading: PropTypes.bool,
};

export default SparkCard;