import axios from "axios";

/**
 * Calls the backend API to generate email content based on deal context.
 *
 * @param {object} params - The parameters for the API call.
 * @param {string} params.accountID - The account ID.
 * @param {string} params.userID - The user ID.
 * @param {string | number} params.dealId - The deal ID.
 * @param {string} params.emailType - The type of email to generate (e.g., 'followup', 'introduction').
 * @param {string} [params.guidingPrompt] - Optional additional context or prompt.
 * @param {function} params.getAccessToken - Function to retrieve the auth token.
 * @param {string | number} [params.activityId] - Optional ID of the activity being referenced.
 * @param {string} [params.activityType] - Optional type of the activity being referenced.
 * @param {object} [params.existingDealData] - Optional existing deal data to extract fallback info like name.
 * @returns {Promise<object>} A promise resolving to an object containing { content, subject, email_recipients }.
 * @throws {Error} Throws an error if the API call fails or returns an error status.
 */
export const generateEmailApiCall = async ({
  accountID,
  userID,
  dealId,
  emailType,
  guidingPrompt,
  getAccessToken,
  activityId,
  activityType,
  existingDealData, // Optional: Pass deal data for fallback subject
}) => {
  // Ensure necessary IDs are present
  if (!accountID || !userID || !dealId || !getAccessToken) {
    console.error("Missing necessary data for generateEmail API call:", {
      accountID,
      userID,
      dealId,
      hasTokenFunc: !!getAccessToken,
    });
    throw new Error("Missing necessary information to generate email.");
  }

  try {
    const accessToken = await getAccessToken();
    if (!accessToken) {
      throw new Error("Failed to retrieve access token.");
    }

    // Construct payload
    const payload = {
      account_id: Number(accountID),
      user_id: Number(userID),
      deal_id: Number(dealId),
      email_type: emailType || "general", // Default to general
    };

    // Set additional_context: an object with guiding_prompt if prompt exists, else an empty object.
    payload.additional_context = guidingPrompt
      ? { guiding_prompt: guidingPrompt }
      : {};

    // Conditionally add activity fields if provided
    if (activityId) {
      payload.activity_id = Number(activityId);
    }
    if (activityType) {
      payload.activity_type = activityType;
    }

    const targetUrl =
      process.env.REACT_APP_API_BASE_URL ||
      "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
    // Construct the API endpoint URL
    const apiUrl = `${targetUrl}/api/accounts/${accountID}/users/${userID}/deals/${dealId}/generate-email`;

    // Make the API call
    const response = await axios.post(apiUrl, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      timeout: 60000, // 60 seconds timeout
      validateStatus: (status) => status < 500, // Consider <500 as potentially successful for processing
    });

    // Check for API-level errors indicated by status code
    if (response.status >= 400) {
      const errorMsg =
        response.data?.message ||
        response.data?.error ||
        `API Error (${response.status})`;
      console.error(
        `Error generating email via API: ${errorMsg}`,
        response.data
      );
      throw new Error(errorMsg);
    }

    // Check if we got a response but no data (handle potential 204 or empty 200)
    if (!response.data && response.status >= 200 && response.status < 300) {
      console.warn("Generate email API returned success status but no data.");
      return {
        content:
          "<p>Email generation request succeeded, but no content was returned. Please try again or contact support.</p>",
        subject: "Generated Email (No Content)",
        email_recipients: [], // Default to empty recipients
      };
    }

    // Process response data - accommodating different potential structures
    let emailData = {};
    if (typeof response.data === "object" && response.data !== null) {
      if (response.data.status === "success" && response.data.email) {
        emailData = response.data.email;
      } else if (response.data.status === "success" && response.data.content) {
        emailData = response.data; // Flattened success structure
      } else {
        emailData = response.data.email || response.data; // Look for nested or direct data
      }
    } else if (typeof response.data === "string") {
      // Handle case where only a string (content) might be returned
      emailData = { content: response.data };
    }

    // Ensure content exists, check common alternative fields
    if (!emailData.content) {
      if (emailData.body) emailData.content = emailData.body;
      else if (emailData.message) emailData.content = emailData.message;
      else if (emailData.text) emailData.content = emailData.text;
      else
        emailData.content =
          "<p>Unable to extract email content from the response.</p>"; // Fallback content
    }

    // Determine subject - Use API response or fallback
    const subject =
      emailData.subject ||
      `Regarding ${
        existingDealData?.deal_name || existingDealData?.name || "Deal"
      }`;

    // Format recipients if provided by the API
    let formattedRecipients = [];
    if (emailData.recipients) {
      if (Array.isArray(emailData.recipients.to)) {
        formattedRecipients = emailData.recipients.to.map((r) => ({
          ...r,
          type: "to",
        }));
      }
      if (Array.isArray(emailData.recipients.cc)) {
        formattedRecipients = [
          ...formattedRecipients,
          ...emailData.recipients.cc.map((r) => ({ ...r, type: "cc" })),
        ];
      }
      // Add BCC handling here if needed in the future
    }

    return {
      content: emailData.content,
      subject: subject,
      email_recipients: formattedRecipients,
    };
  } catch (error) {
    console.error("Error in generateEmailApiCall:", error);
    // Re-throw a user-friendly error or the original error for the caller to handle
    const errorMessage =
      error.response?.data?.message ||
      error.response?.data?.error ||
      error.message ||
      "An unexpected error occurred while generating the email.";
    throw new Error(errorMessage);
  }
};
