import React, { useState, useMemo } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Checkbox,
  Box,
  Text,
  useToast,
  Progress,
  IconButton,
  Stack,
  Skeleton,
} from '@chakra-ui/react';
import { MdAdd, MdDelete } from 'react-icons/md';
import { useEmailSender } from '../hooks/useEmailSender';
import { useAuthData } from '../../../auth-context';
import { useBusinessExtraction } from '../hooks/useBusinessExtraction';
import { useCreatePipeline } from '../hooks/useCreatePipeline';

const STEPS = [
  'Business Information',
  'Prospect Configuration',
  'Deal Stages',
];

const CreatePipelineModal = ({ isOpen, onClose, accountID }) => {
  const toast = useToast();
  const { getCurrentUserSenders, getTeamSenders } = useEmailSender();
  const [currentStep, setCurrentStep] = useState(0);
  const { 
    extractBusinessInformation, 
    generateFallbackBusinessInfo, 
    isLoading: isGeneratingDetails, 
    error: extractionError 
  } = useBusinessExtraction();
  const {
    createPipelineWithStages,
    isLoading,
    error: createPipelineError
  } = useCreatePipeline();
  
  // Get account ID from auth context if not provided as prop
  const userData = useAuthData();
  const effectiveAccountID = accountID || userData?.account_ID;

  const [formData, setFormData] = useState({
    // Business Information
    pipelineName: '',
    websiteUrl: '',
    offering: '',
    customerDefinition: '',
    businessDescription: '',
    targetCustomerSize: '',
    targetCustomerLocation: '',
    
    // Prospect Configuration
    selectedChannels: [],
    selectedEmailAccounts: [],
    
    // Lead Filtering
    filterRules: {
      minSenders: 2,
      minEmails: 3,
      excludedDomains: ['gmail.com', 'yahoo.com'],
      customRules: [],
    },
    
    // Deal Stages
    stages: [
      {
        name: 'Prospecting',
        description: 'Identifying and engaging leads to confirm initial interest in our product or service.',
        passingCriteria: 'There is confirmed interest from the lead in our product or service.',
        triggerType: 'qa',
        triggerConfig: {},
      },
      {
        name: 'Qualification',
        description: 'Determining whether the interested lead fits our criteria and has genuine buying potential.',
        passingCriteria: 'A demo or presentation meeting is scheduled and confirmed.',
        triggerType: 'qa',
        triggerConfig: {},
      },
      {
        name: 'Demo',
        description: 'Demonstrating our solution to clearly address the qualified prospect\'s needs.',
        passingCriteria: 'The demo has taken place, and notes or a summary capturing key points and next steps are recorded.',
        triggerType: 'qa',
        triggerConfig: {},
      },
      {
        name: 'Proposal',
        description: 'Presenting a formal offer outlining pricing, terms, and deliverables tailored to the prospect.',
        passingCriteria: 'A formal proposal or quote has been sent to and received by the prospect.',
        triggerType: 'qa',
        triggerConfig: {},
      },
      {
        name: 'Negotiation',
        description: 'Addressing prospect feedback or objections to reach agreement on proposal terms.',
        passingCriteria: 'Prospect has explicitly accepted terms, verbally or in writing, and is ready to move forward.',
        triggerType: 'qa',
        triggerConfig: {},
      },
      {
        name: 'Closing',
        description: 'Finalizing the sale by securing formal acceptance and commitment from the prospect.',
        passingCriteria: 'A signed agreement or formal commitment is received, finalizing the deal.',
        triggerType: 'qa',
        triggerConfig: {},
      },
      {
        name: 'Customer Success',
        description: 'Customer onboarding or implementation activities have begun, confirming active post-sale engagement.',
        passingCriteria: '',
        triggerType: 'qa',
        triggerConfig: {},
      },
    ],
  });

  const [errors, setErrors] = useState({});
  const [isManualEntry, setIsManualEntry] = useState(false);

  // Get email accounts once and memoize them
  const personalAccounts = useMemo(() => getCurrentUserSenders(), [getCurrentUserSenders]);
  const teamAccounts = useMemo(() => getTeamSenders(), [getTeamSenders]);

  // Validation functions for each step
  const validateBusinessInformation = () => {
    const newErrors = {};
    
    if (!formData.pipelineName.trim()) {
      newErrors.pipelineName = 'Pipeline name is required';
    }

    if (isManualEntry) {
      if (!formData.offering.trim()) {
        newErrors.offering = 'Offering is required';
      }
      if (!formData.customerDefinition.trim()) {
        newErrors.customerDefinition = 'Customer definition is required';
      }
      // The other fields are optional, so no validation needed
    } else if (!formData.websiteUrl.trim()) {
      newErrors.websiteUrl = 'Please enter a website URL or switch to manual entry';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateProspectConfiguration = () => {
    const newErrors = {};
    
    if (formData.selectedEmailAccounts.length === 0) {
      newErrors.selectedEmailAccounts = 'Please select at least one email account';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateDealStages = () => {
    const newErrors = {};
    
    if (formData.stages.length === 0) {
      newErrors.stages = 'At least one stage is required';
    }

    formData.stages.forEach((stage, index) => {
      if (!stage.name.trim()) {
        newErrors[`stage${index}Name`] = 'Stage name is required';
      }
      // Make passing criteria optional for the last stage
      if (stage.triggerType === 'qa' && !stage.passingCriteria.trim() && index < formData.stages.length - 1) {
        newErrors[`stage${index}PassingCriteria`] = 'Passing criteria is required for Q&A stages';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e, section, index) => {
    const { name, value, type, checked } = e.target;
    
    setFormData(prev => {
      if (section) {
        if (Array.isArray(prev[section])) {
          const newArray = [...prev[section]];
          newArray[index] = { ...newArray[index], [name]: type === 'checkbox' ? checked : value };
          return { ...prev, [section]: newArray };
        }
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [name]: type === 'checkbox' ? checked : value,
          },
        };
      }
      return {
        ...prev,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
  };

  const addStage = () => {
    setFormData(prev => ({
      ...prev,
      stages: [
        ...prev.stages,
        {
          name: '',
          description: '',
          passingCriteria: '',
          triggerType: 'qa',
          triggerConfig: {},
        },
      ],
    }));
  };

  const removeStage = (index) => {
    setFormData(prev => ({
      ...prev,
      stages: prev.stages.filter((_, i) => i !== index),
    }));
  };

  const handleNext = () => {
    let isValid = false;

    switch (currentStep) {
      case 0:
        isValid = validateBusinessInformation();
        break;
      case 1:
        isValid = validateProspectConfiguration();
        break;
      default:
        isValid = true;
    }

    if (isValid) {
      setCurrentStep(prev => prev + 1);
      setErrors({});
    } else {
      toast({
        title: 'Please fix the errors before continuing',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleSubmit = async () => {
    if (!validateDealStages()) {
      toast({
        title: 'Please fix the errors before submitting',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    // Validate required fields
    if (!formData.pipelineName || (isManualEntry && (!formData.offering || !formData.customerDefinition))) {
      toast({
        title: 'Missing required fields',
        description: 'Please fill in all required fields.',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    // Validate that all stages have names
    const unnamedStages = formData.stages.filter(stage => !stage.name.trim());
    if (unnamedStages.length > 0) {
      toast({
        title: 'Unnamed stages',
        description: 'All stages must have a name.',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    try {
      // Prepare email accounts with proper structure
      const selectedEmailAccounts = formData.selectedEmailAccounts.map(account => ({
        email: account.email,
        userId: parseInt(account.userId || account.user_id, 10) || 0,
      }));

      // Determine offerings and customer definition
      // If not using manual entry, we should have at least a website URL
      let effectiveOffering = formData.offering;
      let effectiveCustomerDefinition = formData.customerDefinition;
      let effectiveBusinessDescription = formData.businessDescription;
      let effectiveTargetCustomerSize = formData.targetCustomerSize;
      let effectiveTargetCustomerLocation = formData.targetCustomerLocation;
      
      if (!isManualEntry && formData.websiteUrl && (!effectiveOffering || !effectiveCustomerDefinition)) {
        // Use fallback generation for website URL
        const fallbackInfo = generateFallbackBusinessInfo(formData.websiteUrl);
        effectiveOffering = effectiveOffering || fallbackInfo.offering;
        effectiveCustomerDefinition = effectiveCustomerDefinition || fallbackInfo.customer_definition;
        effectiveBusinessDescription = effectiveBusinessDescription || fallbackInfo.business_description;
        effectiveTargetCustomerSize = effectiveTargetCustomerSize || fallbackInfo.company_size;
        effectiveTargetCustomerLocation = effectiveTargetCustomerLocation || fallbackInfo.target_location;
      }

      // Prepare pipeline data
      const pipelineData = {
        pipelineName: formData.pipelineName,
        offering: effectiveOffering,
        customerDefinition: effectiveCustomerDefinition,
        businessDescription: effectiveBusinessDescription || '',
        targetCustomerSize: effectiveTargetCustomerSize || '',
        targetCustomerLocation: effectiveTargetCustomerLocation || '',
        emailIntegrations: selectedEmailAccounts.length > 0 ? selectedEmailAccounts : [],
        is_active: true, // Default to true for new pipelines
      };

      // Create pipeline with stages using our hook
      const result = await createPipelineWithStages(
        pipelineData, 
        formData.stages, 
        effectiveAccountID
      );

      if (!result) {
        throw new Error(createPipelineError || 'Failed to create pipeline with stages');
      }

      // Success notification
      toast({
        title: 'Pipeline created',
        description: 'Pipeline and stages have been successfully created.',
        status: 'success',
        duration: 3000,
      });
      
      onClose();
    } catch (error) {
      console.error('Error creating pipeline:', error);
      toast({
        title: 'Error creating pipeline',
        description: error.message || 'An unexpected error occurred',
        status: 'error',
        duration: 5000,
      });
    }
  };

  // Function to handle business information extraction
  const handleExtractBusinessInformation = async () => {
    if (!formData.websiteUrl.trim()) {
      toast({
        title: "Website URL is required",
        status: "error",
        duration: 3000,
      });
      return;
    }

    // Try to extract business information
    const businessInfo = await extractBusinessInformation(formData.websiteUrl, effectiveAccountID);
    
    if (businessInfo) {
      // Update form data with extracted business information
      setFormData(prev => ({
        ...prev,
        offering: businessInfo.offering || prev.offering,
        customerDefinition: businessInfo.customer_definition || prev.customerDefinition,
        businessDescription: businessInfo.business_description || prev.businessDescription,
        targetCustomerLocation: businessInfo.target_location || prev.targetCustomerLocation,
        targetCustomerSize: businessInfo.company_size || prev.targetCustomerSize,
      }));

      // Switch to manual entry mode to show the extracted information to the user
      setIsManualEntry(true);

      toast({
        title: "Business information extracted successfully",
        description: "Review and edit the generated information before proceeding",
        status: "success",
        duration: 3000,
      });
    } else {
      // If extraction failed, use fallback generation
      toast({
        title: "Using fallback generation",
        description: extractionError || "Could not extract business information. Using basic information from the URL.",
        status: "warning",
        duration: 5000,
      });
      
      // Use fallback business info generation
      const fallbackInfo = generateFallbackBusinessInfo(formData.websiteUrl);
      
      setFormData(prev => ({
        ...prev,
        offering: fallbackInfo.offering || prev.offering,
        customerDefinition: fallbackInfo.customer_definition || prev.customerDefinition,
        businessDescription: fallbackInfo.business_description || prev.businessDescription,
        targetCustomerLocation: fallbackInfo.target_location || prev.targetCustomerLocation,
        targetCustomerSize: fallbackInfo.company_size || prev.targetCustomerSize,
      }));
      
      // Switch to manual entry to show the generated info
      setIsManualEntry(true);
    }
  };

  const renderBusinessInformation = () => (
    <VStack spacing={6} align="stretch">
      <FormControl isRequired isInvalid={!!errors.pipelineName}>
        <FormLabel>Pipeline Name</FormLabel>
        <Input
          name="pipelineName"
          value={formData.pipelineName}
          onChange={handleInputChange}
          placeholder="Enter pipeline name"
        />
        {errors.pipelineName && (
          <Text color="red.500" fontSize="sm" mt={1}>
            {errors.pipelineName}
          </Text>
        )}
      </FormControl>

      {!isManualEntry ? (
        <Box p={4} borderWidth="1px" borderRadius="md" borderStyle="dashed">
          <Text fontWeight="medium" mb={2}>Quick Setup - Generate from Website</Text>
          <Text fontSize="sm" color="gray.600" mb={4}>
            Enter your website URL and we&apos;ll automatically generate your pipeline details including offering, customer definition, business description, and target customer information
          </Text>
          <VStack spacing={4}>
            <FormControl isInvalid={!!errors.websiteUrl}>
              <Input
                placeholder="Enter website URL"
                value={formData.websiteUrl}
                onChange={(e) => setFormData(prev => ({ ...prev, websiteUrl: e.target.value }))}
              />
              {errors.websiteUrl && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.websiteUrl}
                </Text>
              )}
            </FormControl>
            <HStack width="100%" justify="space-between">
              <Button
                variant="link"
                onClick={() => setIsManualEntry(true)}
              >
                Or enter details manually
              </Button>
              <Button
                colorScheme="blue"
                onClick={handleExtractBusinessInformation}
                isLoading={isGeneratingDetails}
                loadingText="Generating..."
                isDisabled={!formData.websiteUrl}
              >
                Generate Details
              </Button>
            </HStack>
          </VStack>
        </Box>
      ) : (
        <VStack spacing={4} align="stretch">
          <FormControl isRequired isInvalid={!!errors.offering}>
            <FormLabel>Offering</FormLabel>
            <Textarea
              name="offering"
              value={formData.offering}
              onChange={handleInputChange}
              placeholder="Describe your product or service offering"
            />
            {errors.offering && (
              <Text color="red.500" fontSize="sm" mt={1}>
                {errors.offering}
              </Text>
            )}
          </FormControl>
          
          <FormControl isRequired isInvalid={!!errors.customerDefinition}>
            <FormLabel>Customer Definition</FormLabel>
            <Textarea
              name="customerDefinition"
              value={formData.customerDefinition}
              onChange={handleInputChange}
              placeholder="How would you describe an ideal customer?"
            />
            {errors.customerDefinition && (
              <Text color="red.500" fontSize="sm" mt={1}>
                {errors.customerDefinition}
              </Text>
            )}
          </FormControl>

          <FormControl>
            <FormLabel>Business Description</FormLabel>
            <Textarea
              name="businessDescription"
              value={formData.businessDescription}
              onChange={handleInputChange}
              placeholder="Describe your business"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Target Customer Size</FormLabel>
            <Input
              name="targetCustomerSize"
              value={formData.targetCustomerSize}
              onChange={handleInputChange}
              placeholder="E.g., SMB, Enterprise, 50-200 employees"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Target Customer Location</FormLabel>
            <Input
              name="targetCustomerLocation"
              value={formData.targetCustomerLocation}
              onChange={handleInputChange}
              placeholder="E.g., Global, North America, EMEA"
            />
          </FormControl>

          <Button
            variant="link"
            onClick={() => setIsManualEntry(false)}
          >
            Back to website generation
          </Button>
        </VStack>
      )}
    </VStack>
  );

  const renderProspectConfiguration = () => (
    <VStack spacing={6} align="stretch">
      <Box>
        <Text fontWeight="semibold" mb={4}>Email Accounts</Text>
        <Text fontSize="sm" color="gray.600" mb={4}>
          Select the email accounts that should feed into this pipeline
        </Text>
        
        {isLoading ? (
          <Stack spacing={3}>
            <Skeleton height="40px" />
            <Skeleton height="40px" />
            <Skeleton height="40px" />
          </Stack>
        ) : (
          <VStack spacing={4} align="stretch">
            {errors.selectedEmailAccounts && (
              <Text color="red.500" fontSize="sm">
                {errors.selectedEmailAccounts}
              </Text>
            )}
            {/* Personal Accounts Section */}
            {personalAccounts.length > 0 && (
              <Box>
                <Text fontSize="sm" fontWeight="medium" mb={2}>Personal Accounts</Text>
                <VStack spacing={3} align="stretch">
                  {personalAccounts.map(account => ( 
                    <Checkbox
                      key={account.email}
                      isChecked={formData.selectedEmailAccounts.some(
                        selected => selected.email === account.email
                      )}
                      onChange={(e) => {
                        setFormData(prev => ({
                          ...prev,
                          selectedEmailAccounts: e.target.checked
                            ? [...prev.selectedEmailAccounts, {
                                email: account.email,
                                userId: account.userId || account.user_id || 0,
                              }]
                            : prev.selectedEmailAccounts.filter(
                                a => a.email !== account.email
                              ),
                        }));
                      }}
                    >
                      {account.email}
                    </Checkbox>
                  ))}
                </VStack>
              </Box>
            )}

            {/* Team Accounts Section */}
            {teamAccounts.length > 0 && (
              <Box>
                <Text fontSize="sm" fontWeight="medium" mb={2}>Team Accounts</Text>
                <VStack spacing={3} align="stretch">
                  {teamAccounts.map(account => (
                    <Checkbox
                      key={account.email}
                      isChecked={formData.selectedEmailAccounts.some(
                        selected => selected.email === account.email
                      )}
                      onChange={(e) => {
                        setFormData(prev => ({
                          ...prev,
                          selectedEmailAccounts: e.target.checked
                            ? [...prev.selectedEmailAccounts, {
                                email: account.email,
                                userId: account.userId || account.user_id || 0,
                              }]
                            : prev.selectedEmailAccounts.filter(
                                a => a.email !== account.email
                              ),
                        }));
                      }}
                    >
                      {account.email}
                    </Checkbox>
                  ))}
                </VStack>
              </Box>
            )}

            {personalAccounts.length === 0 && teamAccounts.length === 0 && (
              <Text color="gray.500">No email accounts available. Please connect your email accounts in Settings.</Text>
            )}
          </VStack>
        )}
      </Box>
    </VStack>
  );

  const renderDealStages = () => (
    <VStack spacing={4} align="stretch">
      {formData.stages.map((stage, index) => (
        <Box
          key={index}
          p={4}
          borderWidth="1px"
          borderRadius="md"
          position="relative"
        >
          <HStack justify="space-between" mb={4}>
            <Text fontWeight="semibold">Stage {index + 1}</Text>
            {index > 0 && (
              <IconButton
                icon={<MdDelete />}
                variant="ghost"
                colorScheme="red"
                size="sm"
                onClick={() => removeStage(index)}
                aria-label="Remove Stage"
              />
            )}
          </HStack>
          
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={stage.name}
                onChange={(e) => handleInputChange(e, 'stages', index)}
                placeholder="Enter stage name"
              />
            </FormControl>
            
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={stage.description}
                onChange={(e) => handleInputChange(e, 'stages', index)}
                placeholder="Describe this stage"
              />
            </FormControl>
            
            <FormControl>
              <FormLabel>Trigger Type</FormLabel>
              <Select
                name="triggerType"
                value={stage.triggerType}
                onChange={(e) => handleInputChange(e, 'stages', index)}
              >
                <option value="qa">Q&A</option>
                <option value="zapier">Zapier</option>
                <option value="segment">Segment</option>
              </Select>
            </FormControl>
            
            {stage.triggerType === 'qa' ? (
              <FormControl>
                <FormLabel>Passing Criteria</FormLabel>
                <Textarea
                  name="passingCriteria"
                  value={stage.passingCriteria}
                  onChange={(e) => handleInputChange(e, 'stages', index)}
                  placeholder="Define criteria to pass this stage"
                />
              </FormControl>
            ) : (
              <Box
                p={4}
                bg="gray.50"
                borderRadius="md"
              >
                <Text fontSize="sm" color="gray.600">
                  {stage.triggerType === 'zapier' ? 
                    `Zapier integration coming soon! Contact us at support@lysto.ai to learn more about automating your pipeline with Zapier.` :
                    `Segment integration coming soon! Contact us at support@lysto.ai to learn more about connecting your Segment events to your pipeline.`}
                </Text>
              </Box>
            )}
          </VStack>
        </Box>
      ))}
      
      <Button
        leftIcon={<MdAdd />}
        onClick={addStage}
        variant="ghost"
        w="full"
      >
        Add Stage
      </Button>
    </VStack>
  );

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return renderBusinessInformation();
      case 1:
        return renderProspectConfiguration();
      case 2:
        return renderDealStages();
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxH="90vh">
        <ModalHeader>
          Create Pipeline - {STEPS[currentStep]}
          <Progress
            value={(currentStep / (STEPS.length - 1)) * 100}
            size="sm"
            mt={4}
          />
        </ModalHeader>
        
        <ModalBody overflowY="auto">
          {renderStep()}
        </ModalBody>
        
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              onClick={onClose}
              variant="ghost"
            >
              Cancel
            </Button>
            {currentStep > 0 && (
              <Button onClick={handleBack}>
                Back
              </Button>
            )}
            {currentStep < STEPS.length - 1 ? (
              <Button
                colorScheme="blue"
                onClick={handleNext}
              >
                Next
              </Button>
            ) : (
              <Button
                colorScheme="blue"
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                Create Pipeline
              </Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreatePipelineModal; 