import { useState } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

const CACHE_EXPIRATION = 60 * 60 * 1000; // 1 hour in milliseconds
let cachedRoutes = null;
let lastFetchTime = null;

// Helper function to determine the default redirect path based on visible routes
export const getDefaultRedirectPath = (visibleRoutes) => {
  if (!visibleRoutes) return "/admin/inbox";

  // Get visible route names (excluding hidden routes)
  const visibleRouteNames = Object.entries(visibleRoutes)
    .filter(([, isVisible]) => isVisible)
    .map(([name]) => name);

  // If Settings is the only visible route, redirect to settings
  if (visibleRouteNames.length === 1 && visibleRouteNames[0] === "Settings") {
    return "/admin/settings";
  }

  // Default case - redirect to inbox
  return "/admin/inbox";
};

export const useFetchVisibleRoutes = () => {
  const [visibleRoutes, setVisibleRoutes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessToken, account_ID: accountID } = useAuthData();

  const fetchVisibleRoutes = async () => {
    if (!accountID) {
      setLoading(false);
      setError(new Error("Account ID is missing."));
      return null;
    }

    // Check if we have cached data that hasn't expired
    if (
      cachedRoutes &&
      lastFetchTime &&
      Date.now() - lastFetchTime < CACHE_EXPIRATION
    ) {
      setVisibleRoutes(cachedRoutes);
      setLoading(false);
      setError(null);
      return cachedRoutes;
    }

    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
          : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.get(
        `${target}/api/accounts/${accountID}/admin/visible-routes`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const fetchedVisibleRoutes = response.data;

      // Update cache
      cachedRoutes = fetchedVisibleRoutes;
      lastFetchTime = Date.now();

      setVisibleRoutes(fetchedVisibleRoutes);
      setError(null);
      setLoading(false);
      return fetchedVisibleRoutes;
    } catch (err) {
      console.error("Error fetching visible routes:", err);
      setError(err);
      setLoading(false);
      return null;
    }
  };

  return {
    visibleRoutes,
    loading,
    error,
    fetchVisibleRoutes,
  };
};
