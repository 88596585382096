import { Icon } from "@chakra-ui/react";
import {
  MdSettings,
  MdEvent,
  MdEqualizer,
  MdAccountTree,
  MdHail,
  MdTrackChanges,
  MdInbox,
  MdCampaign,
  MdBusiness,
} from "react-icons/md";
import {
  RiAiGenerate,
  RiLightbulbFlashLine,
  RiRobot2Line,
} from "react-icons/ri";

// Admin Imports
import Reports from "views/lysto/Dashboard";
import Campaigns from "views/admin/campaigns";
import Automations from "views/admin/automations";
import Settings from "views/lysto/Settings";
import Leo from "views/lysto/Leo";
import Agents from "views/lysto/Agents";
import AgentDetail from "views/lysto/Agents/AgentDetail";

// Lysto Views
import Meetings from "views/lysto/Meetings";
import Deals from "views/lysto/Deals";
import DealDetailsPage from "views/lysto/Deals/DealDetailsPage";
import Contacts from "views/lysto/Contacts";
import ContactDetailsPage from "views/lysto/Contacts/ContactDetailsPage";
import Accounts from "views/lysto/Accounts";
import AccountDetailsPage from "views/lysto/Accounts/AccountDetailsPage";
import Spark from "views/lysto/Spark"; // Import Spark component
import EmailComposePage from "views/lysto/Inbox/EmailComposePage"; // Import EmailComposePage
import SlackMessagePage from "views/lysto/Messages/SlackMessagePage"; // Import SlackMessagePage
// import MeetingPrepPage from "views/lysto/Meetings/MeetingPrepPage";
import MeetingAnalysis from "views/lysto/Meetings/MeetingAnalysis";

// New Inbox Imports
import Inbox from "views/lysto/Inbox";
import InboxDetailsPage from "views/lysto/Inbox/InboxDetailsPage";
import EmailFollowupWrapper from 'views/lysto/Inbox/EmailFollowupWrapper'; // Import EmailFollowupWrapper

const routes_json = {
  routes: [
    { name: "Inbox" },
    { name: "InboxDetails" },
    { name: "Meetings" },
    { name: "MeetingDetails" },
    { name: "MeetingAnalysis" },
    { name: "Deals" },
    { name: "DealDetails" },
    { name: "EmailCompose" },
    { name: "SlackMessage" },
    { name: "Contacts" },
    { name: "ContactDetails" },
    { name: "Accounts" },
    { name: "AccountDetails" },
    { name: "Workflows" },
    { name: "Settings" },
    { name: "Leo" },
    { name: "Reports" },
    { name: "Spark" },
    { name: "Agents" },
    { name: "AgentDetail" },
  ],
};

const allowedRouteNames = routes_json.routes.map((route) => route.name);

const routes = [
  {
    name: "InboxDetails",
    layout: "/admin",
    path: "inbox/:inboxId",
    component: InboxDetailsPage,
    hidden: true,
  },
  {
    name: "InboxFollowupView", // Using a distinct name
    layout: "/admin",
    path: "inbox/:followupId", // Matches the nested route path
    component: EmailFollowupWrapper,
    hidden: true, 
  },
  {
    name: "Inbox",
    layout: "/admin",
    icon: <Icon as={MdInbox} width="20px" height="20px" color="inherit" />,
    path: "inbox", // Keep path without /*
    component: Inbox,
  },
  {
    name: "MeetingDetails",
    layout: "/admin",
    path: "meetings/:meetingID", // updated route here
    component: MeetingAnalysis, // updated component here
    hidden: true,
  },
  {
    //remove this route
    name: "MeetingAnalysis",
    layout: "/admin",
    path: "meetings/analysis/:meetingID",
    component: MeetingAnalysis,
    hidden: true,
  },
  {
    name: "Meetings",
    layout: "/admin",
    path: "meetings",
    icon: <Icon as={MdEvent} width="20px" height="20px" color="inherit" />,
    component: Meetings,
  },
  {
    name: "InboxFollowup",
    layout: "/admin",
    path: "inbox/follow-up/:dealId",
    component: Inbox,
    hidden: true,
  },
  {
    name: "DealDetails",
    layout: "/admin",
    path: "deals/:dealId",
    component: DealDetailsPage,
    hidden: true,
  },
  {
    name: "EmailCompose",
    layout: "/admin",
    path: "deals/:dealId/compose-email",
    component: EmailComposePage,
    hidden: true,
  },
  {
    name: "SlackMessage",
    layout: "/admin",
    path: "deals/:dealId/compose-slack",
    component: SlackMessagePage,
    hidden: true,
  },
  {
    name: "Deals",
    layout: "/admin",
    icon: (
      <Icon as={MdTrackChanges} width="20px" height="20px" color="inherit" />
    ),
    path: "deals",
    component: Deals,
  },
  {
    name: "ContactDetails",
    layout: "/admin",
    path: "contacts/:contactId",
    component: ContactDetailsPage,
    hidden: true,
  },
  {
    name: "Contacts",
    layout: "/admin",
    icon: <Icon as={MdHail} width="20px" height="20px" color="inherit" />,
    path: "contacts",
    component: Contacts,
  },
  {
    name: "AccountDetails",
    layout: "/admin",
    path: "accounts/:accountId",
    component: AccountDetailsPage,
    hidden: true,
  },
  {
    name: "Accounts",
    layout: "/admin",
    icon: <Icon as={MdBusiness} width="20px" height="20px" color="inherit" />,
    path: "accounts",
    component: Accounts,
  },
  {
    name: "Spark",
    layout: "/admin",
    icon: (
      <Icon
        as={RiLightbulbFlashLine}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "spark",
    component: Spark,
  },
  {
    name: "Campaigns",
    layout: "/admin",
    path: "campaigns",
    icon: <Icon as={MdCampaign} width="20px" height="20px" color="inherit" />,
    component: Campaigns,
  },
  {
    name: "Reports",
    layout: "/admin",
    path: "dashboard",
    icon: <Icon as={MdEqualizer} width="20px" height="20px" color="inherit" />,
    component: Reports,
  },
  {
    name: "Workflows",
    layout: "/admin",
    path: "workflows",
    icon: (
      <Icon as={MdAccountTree} width="20px" height="20px" color="inherit" />
    ),
    component: Automations,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "settings",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    component: Settings,
  },
  {
    name: "Leo",
    layout: "/admin",
    path: "leo",
    icon: <Icon as={RiAiGenerate} width="20px" height="20px" color="inherit" />,
    component: Leo,
  },
  {
    name: "AgentDetail",
    layout: "/admin",
    path: "agents/:agentId",
    component: AgentDetail,
    hidden: true,
  },
  {
    name: "Agents",
    layout: "/admin",
    icon: <Icon as={RiRobot2Line} width="20px" height="20px" color="inherit" />,
    path: "agents",
    component: Agents,
  },
].filter((route) => allowedRouteNames.includes(route.name));

export default routes;
