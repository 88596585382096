import React, { useState, useEffect } from "react";
import {
  HStack,
  Text,
  Box,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import lystoLogo from "assets/img/lysto-logo-simple.svg";
import styles from "../styles/Markdown.module.scss";

const useTypewriter = (text, speed = 20) => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentText, setCurrentText] = useState("");

  useEffect(() => {
    if (text !== currentText) {
      setDisplayedText("");
      setCurrentText(text);
    }

    let i = displayedText.length;
    const timer = setInterval(() => {
      if (i < text.length) {
        setDisplayedText(text.slice(0, i + 1));
        i++;
      } else {
        clearInterval(timer);
      }
    }, speed);

    return () => clearInterval(timer);
  }, [text, speed, currentText, displayedText]);

  return displayedText;
};

const AIResponse = ({ markdownText }) => {
  const typewriterText = useTypewriter(markdownText);

  if (!markdownText) {
    return <Text>No content available</Text>;
  }

  return (
    <HStack align="start" spacing={0} width="100%">
      <Box mt={5}>
        <Image
          src={lystoLogo}
          alt="Lysto Logo"
          boxSize="23px"
          objectFit="contain"
        />
      </Box>
      <Box
        bg="gray.100"
        borderRadius="md"
        border="1px"
        borderColor="gray.200"
        p={4}
        flex={1}
        ml={2}
      >
        <ReactMarkdown
          className={styles.markdown}
          remarkPlugins={[remarkGfm]}
          components={{
            p: ({ ...props }) => <Text mb={2} {...props} />,
            a: ({ ...props }) => <Text as="a" color="blue.500" {...props} />,
            ul: ({ ...props }) => <Box as="ul" pl={4} mb={2} {...props} />,
            ol: ({ ...props }) => <Box as="ol" pl={4} mb={2} {...props} />,
            li: ({ ...props }) => <Box as="li" mb={1} {...props} />,
            table: ({ ...props }) => (
              <Table variant="simple" mb={2} {...props} />
            ),
            thead: ({ ...props }) => <Thead {...props} />,
            tbody: ({ ...props }) => <Tbody {...props} />,
            tr: ({ ...props }) => <Tr {...props} />,
            th: ({ ...props }) => <Th {...props} />,
            td: ({ ...props }) => <Td {...props} />,
            pre: ({ ...props }) => <Box as="pre" p={2} {...props} />,
            code: ({ inline, className, children, ...props }) => {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <Box as="pre" p={2}>
                  <Box as="code" className={className} {...props}>
                    {children}
                  </Box>
                </Box>
              ) : (
                <Box as="code" className={className} {...props}>
                  {children}
                </Box>
              );
            },
          }}
        >
          {typewriterText}
        </ReactMarkdown>
      </Box>
    </HStack>
  );
};

export default AIResponse;
