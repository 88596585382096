import PropTypes from 'prop-types';

const StringOrNumberType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
]);

export const ActivityPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  account_id: PropTypes.number.isRequired,
  user_id: PropTypes.number.isRequired,
  user_name: PropTypes.string.isRequired,
  user_last_name: PropTypes.string.isRequired,
  user_email: PropTypes.string.isRequired,
  owner_id: StringOrNumberType.isRequired,
  created_by_id: StringOrNumberType,
  deal_id: PropTypes.number.isRequired,
  activity_id: PropTypes.number.isRequired,
  activity_type: PropTypes.oneOf(['Call', 'Email', 'Meeting', 'Task', 'call', 'email', 'meeting', 'task', 'booking', 'Slack Digest']).isRequired,
  activity_date: PropTypes.string.isRequired,
  completed_date_time: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  activity_data: PropTypes.shape({
    activity_type: PropTypes.oneOf(['email', 'Email']),
    data: PropTypes.shape({
      summary: PropTypes.string,
      transcript: PropTypes.string,
      body: PropTypes.string,
      received_at: PropTypes.string,
      recipients: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string.isRequired,
          recipient_type: PropTypes.string.isRequired,
        })
      ),
      sender_email: PropTypes.string,
      sender_name: PropTypes.string,
      subject: PropTypes.string,
      end_time: PropTypes.string,
      is_recorded: PropTypes.bool,
      location: PropTypes.string,
      meeting_id: PropTypes.number,
      organizer_display_name: PropTypes.string,
      organizer_email: PropTypes.string,
      participants: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string.isRequired,
          display_name: PropTypes.string.isRequired,
        })
      ),
      start_time: PropTypes.string,
      timezone: PropTypes.string,
    }).isRequired,
    version: PropTypes.string.isRequired,
  }).isRequired,
  source_system: PropTypes.string.isRequired,
  classification: PropTypes.shape({
    activity_purpose: PropTypes.string,
    communication_medium: PropTypes.string,
    deal_stage: PropTypes.string,
    key_action: PropTypes.string,
    entities_involved: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
    email_to: PropTypes.string,
    email_cc: PropTypes.string,
    email_bcc: PropTypes.string,
    email_subject: PropTypes.string,
    email_body: PropTypes.string,
  }).isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  is_internal: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
}).isRequired;