import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import EmailDisplay from './EmailDisplay';
import MeetingDisplay from './MeetingDisplay';
import CallDisplay from './CallDisplay';
import BookingDisplay from './BookingDisplay';
import SlackDigestDisplay from './SlackDigestDisplay';
import { ActivityPropType } from './types';

const ActivityContent = ({ activity }) => {
  const { activity_type } = activity;

  if (activity_type?.toLowerCase() === 'email') {
    return <EmailDisplay activity={activity} />;
  }

  if (activity_type?.toLowerCase() === 'meeting') {
    return <MeetingDisplay activity={activity} />;
  }

  if (activity_type?.toLowerCase() === 'call') {
    return <CallDisplay activity={activity} />;
  }

  if (activity_type?.toLowerCase() === 'booking') {
    return <BookingDisplay activity={activity} />;
  }
  
  if (activity_type?.toLowerCase() === 'slack digest') {
    return <SlackDigestDisplay activity={activity} />;
  }

  // Default content for other activity types
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      p={4}
      bg={bgColor}
      borderRadius="md"
      borderWidth="1px"
      borderColor={borderColor}
    >
      <Text whiteSpace="pre-wrap">
        {activity.description || 'No details available'}
      </Text>
    </Box>
  );
};

ActivityContent.propTypes = {
  activity: ActivityPropType,
};

export default ActivityContent;