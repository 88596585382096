// src/components/Inbox/EditableEmailRecipients.jsx

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Text,
  Flex,
  Tooltip,
  IconButton,
  Box,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
import { CloseIcon, AddIcon } from '@chakra-ui/icons';
import { FaUser, FaExpandAlt } from 'react-icons/fa';
import BaseTag from '../Shared/BaseTag';
import useFetchContacts from "../Contacts/hooks/useFetchContacts";

const EditableEmailRecipients = ({ 
  recipients = [], 
  onChange, 
  accountID,
  senderComponent 
}) => {
  const [sections, setSections] = useState({
    to: true,
    cc: false,
    bcc: false,
  });

  const [inputValues, setInputValues] = useState({
    to: '',
    cc: '',
    bcc: '',
  });

  const [isAdding, setIsAdding] = useState({
    to: false,
    cc: false,
    bcc: false,
  });

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRefs = {
    to: useRef(null),
    cc: useRef(null),
    bcc: useRef(null),
  };
  const suggestionRefs = useRef({});
  const [isSelectingSuggestion, setIsSelectingSuggestion] = useState(false);

  useEffect(() => {
    setSections({
      to: true,
      cc: recipients.some((r) => r.type === 'cc' || r.recipient_type === 'cc'),
      bcc: recipients.some((r) => r.type === 'bcc' || r.recipient_type === 'bcc'),
    });
  }, [recipients]);

  useEffect(() => {
    if (selectedIndex >= 0) {
      const selectedElement = suggestionRefs.current[selectedIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth'
        });
      }
    }
  }, [selectedIndex]);

  const { contacts: toSuggestions } = useFetchContacts(accountID, inputValues.to, "Lead", "all", 1);
  const { contacts: ccSuggestions } = useFetchContacts(accountID, inputValues.cc, "Lead", "all", 1);
  const { contacts: bccSuggestions } = useFetchContacts(accountID, inputValues.bcc, "Lead", "all", 1);

  const handleAdd = (type) => {
    if (!inputValues[type].trim()) return;

    const emails = inputValues[type]
      .split(',')
      .map((email) => {
        const emailParts = email.trim().match(/^(.*?)(?:<(.+?)>)?$/);
        const name = emailParts[2] ? emailParts[1].trim() : '';
        const emailAddress = emailParts[2]
          ? emailParts[2].trim()
          : emailParts[1].trim();
        return {
          email: emailAddress,
          name,
          recipient_type: type,
          type: type,
        };
      })
      .filter((email) => email.email);

    onChange([...recipients, ...emails]);
    setInputValues((prev) => ({ ...prev, [type]: '' }));
    setIsAdding((prev) => ({ ...prev, [type]: false }));
    setSections((prev) => ({
      ...prev,
      [type]: true,
    }));
  };

  const handleKeyDown = (e, type, suggestions) => {
    if (suggestions && suggestions.length > 0) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex(prev => 
            prev < suggestions.length - 1 ? prev + 1 : prev
          );
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex(prev => (prev > 0 ? prev - 1 : 0));
          break;
        case 'Enter':
          e.preventDefault();
          if (selectedIndex >= 0) {
            const selected = suggestions[selectedIndex];
            handleSuggestionSelect(type, selected);
          } else if (inputValues[type].trim()) {
            handleAdd(type);
          }
          break;
        case ',':
          if (!e.shiftKey) {
            e.preventDefault();
            handleAdd(type);
          }
          break;
        case 'Escape':
          setSelectedIndex(-1);
          inputRefs[type].current?.blur();
          break;
        default:
          setSelectedIndex(-1);
      }
    } else if (e.key === 'Enter' || (e.key === ',' && !e.shiftKey)) {
      e.preventDefault();
      handleAdd(type);
    }
  };

  const handleSuggestionSelect = (type, suggestion) => {
    const formattedRecipient = {
      email: suggestion.email,
      name: suggestion.name,
      recipient_type: type,
      type: type
    };
    onChange([...recipients, formattedRecipient]);
    setInputValues(prev => ({ ...prev, [type]: '' }));
    setSelectedIndex(-1);
  };

  const removeRecipient = (recipientToRemove) => {
    const updatedRecipients = recipients.filter(
      (r) => r !== recipientToRemove
    );
    onChange(updatedRecipients);
  };

  const renderRecipientBubble = (recipient, index) => {
    const tooltipLabel = recipient.name
      ? `${recipient.name} <${recipient.email}>`
      : recipient.email;

    return (
      <Box key={`${recipient.email}-${index}`} mr={2}>
        <BaseTag
          icon={FaUser}
          hoverIcon={FaExpandAlt}
          tooltip={tooltipLabel}
          colorScheme="blue"
          height="32px"
        >
          <Flex align="center">
            <Text fontSize="sm" fontWeight="medium" noOfLines={1} mr={2}>
              {recipient.name || recipient.email}
            </Text>
            <IconButton
              aria-label="Remove recipient"
              icon={<CloseIcon />}
              size="xs"
              variant="ghost"
              onClick={() => removeRecipient(recipient)}
            />
          </Flex>
        </BaseTag>
      </Box>
    );
  };

  const renderSection = (type, label) => {
    const recipientsForType = recipients.filter(
      (r) => r.recipient_type === type || r.type === type
    );

    let suggestionList = [];
    if (type === 'to') suggestionList = toSuggestions;
    else if (type === 'cc') suggestionList = ccSuggestions;
    else if (type === 'bcc') suggestionList = bccSuggestions;

    return (
      <Flex key={type} align="center" mr={4} flexWrap="wrap">
        <Text color="gray.600" mr={2}>
          {label}
        </Text>
        {recipientsForType.map((r, index) =>
          renderRecipientBubble(r, index)
        )}
        {isAdding[type] ? (
          <Popover
            isOpen={inputValues[type].trim().length > 0 && suggestionList.length > 0}
            autoFocus={false}
            placement="bottom-start"
            matchWidth
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Input
                ref={inputRefs[type]}
                value={inputValues[type]}
                variant="flushed"
                placeholder="Enter email addresses"
                onChange={(e) =>
                  setInputValues((prev) => ({
                    ...prev,
                    [type]: e.target.value,
                  }))
                }
                onKeyDown={(e) => handleKeyDown(e, type, suggestionList)}
                onBlur={() => {
                  setTimeout(() => {
                    if (!isSelectingSuggestion) {
                      if (inputValues[type].trim()) {
                        handleAdd(type);
                      } else {
                        setIsAdding(prev => ({ ...prev, [type]: false }));
                        setInputValues(prev => ({ ...prev, [type]: '' }));
                      }
                    }
                    setSelectedIndex(-1);
                  }, 200);
                }}
                width="180px"
                size="sm"
                mr={2}
                mt="2px"
                transition="all 0.2s ease-in-out"
                _focus={{
                  width: "190px"
                }}
                sx={{
                  '&::after': {
                    width: '100% !important',
                    transition: 'all 0.2s ease-in-out'
                  }
                }}
                autoFocus
              />
            </PopoverTrigger>
            <PopoverContent width="250px">
              <PopoverBody p={0}>
                <VStack align="stretch" spacing={0} maxH="200px" overflowY="auto">
                  {suggestionList.map((sugg, idx) => (
                    <Box
                      key={sugg.email + idx}
                      ref={el => suggestionRefs.current[idx] = el}
                      p={3}
                      cursor="pointer"
                      bg={selectedIndex === idx ? 'blue.50' : 'white'}
                      _hover={{ bg: 'gray.50' }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        setIsSelectingSuggestion(true);
                        handleSuggestionSelect(type, sugg);
                        setTimeout(() => setIsSelectingSuggestion(false), 100);
                      }}
                      transition="background-color 0.2s"
                    >
                      <Text fontWeight="medium">
                        {sugg.name || sugg.email}
                      </Text>
                      {sugg.name && (
                        <Text fontSize="sm" color="gray.500">
                          {sugg.email}
                        </Text>
                      )}
                    </Box>
                  ))}
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <IconButton
            aria-label={`Add ${label}`}
            icon={<AddIcon />}
            size="xs"
            onClick={() =>
              setIsAdding((prev) => ({ ...prev, [type]: true }))
            }
            variant="ghost"
            colorScheme="blue"
            ml={0}
          />
        )}
      </Flex>
    );
  };

  return (
    <VStack spacing={4} align="stretch">
      <Flex align="center" flexWrap="wrap" gap={4}>
        {senderComponent && (
          <Flex key="from" align="center">
            <Text color="gray.600" mr={2}>
              From:
            </Text>
            {senderComponent}
          </Flex>
        )}
        {renderSection('to', 'To:')}
        <Flex align="center" gap={4}>
          {!sections.cc ? (
            <Flex align="center">
              <Text color="gray.600" mr={2}>Cc:</Text>
              <Tooltip label="Add Cc">
                <IconButton
                  aria-label="Add Cc"
                  icon={<AddIcon />}
                  size="xs"
                  onClick={() => {
                    setSections(prev => ({ ...prev, cc: true }));
                    setIsAdding(prev => ({ ...prev, cc: true }));
                  }}
                  variant="ghost"
                  colorScheme="blue"
                />
              </Tooltip>
            </Flex>
          ) : renderSection('cc', 'Cc:')}
          
          {!sections.bcc ? (
            <Flex align="center">
              <Text color="gray.600" mr={2}>Bcc:</Text>
              <Tooltip label="Add Bcc">
                <IconButton
                  aria-label="Add Bcc"
                  icon={<AddIcon />}
                  size="xs"
                  onClick={() => {
                    setSections(prev => ({ ...prev, bcc: true }));
                    setIsAdding(prev => ({ ...prev, bcc: true }));
                  }}
                  variant="ghost"
                  colorScheme="blue"
                />
              </Tooltip>
            </Flex>
          ) : renderSection('bcc', 'Bcc:')}
        </Flex>
      </Flex>
    </VStack>
  );
};

EditableEmailRecipients.propTypes = {
  recipients: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    recipient_type: PropTypes.oneOf(['to', 'cc', 'bcc']).isRequired
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  accountID: PropTypes.number.isRequired,
  senderComponent: PropTypes.node
};

export default EditableEmailRecipients;
