// src/hooks/useFetchDealById.js

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to fetch deal based on the account ID.
 * @param {string} accountID - The account ID to fetch deal for.
 * @param {string} dealID - The deal ID to fetch.
 * @returns {Object} - Contains deal data, loading state, and error state.
 */
export function useFetchDealById(accountID, dealID) {
  const { getAccessToken } = useAuthData();

  return useQuery({
    queryKey: ["deal", accountID, dealID],
    queryFn: async () => {
      if (!accountID || !dealID) {
        return null;
      }

      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.get(
        `${target}/api/accounts/${accountID}/deals/${dealID}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.data) {
        throw new Error(`Deal with ID ${dealID} not found`);
      }

      return response.data;
    },
    enabled: Boolean(accountID) && Boolean(dealID),
  });
}

export default useFetchDealById;
