import { useState } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";
import { useQueryClient } from "@tanstack/react-query";

/**
 * Custom hook for creating pipelines and their stages
 * @returns {Object} Functions and state for pipeline creation
 */
export const useCreatePipeline = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessToken, account_ID } = useAuthData();
  const queryClient = useQueryClient();

  /**
   * Create a new pipeline
   * @param {Object} pipelineData - Pipeline data to create
   * @param {string} accountId - Optional account ID, defaults to the authenticated user's account
   * @returns {Promise<Object>} Created pipeline data
   */
  const createPipeline = async (pipelineData, accountId = null) => {
    // Use provided accountId or fall back to authenticated user's account
    const effectiveAccountId = accountId || account_ID;
    if (!effectiveAccountId) {
      setError("Account ID is required");
      return null;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Get access token from auth context
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Create the pipeline
      const response = await axios.post(
        `${target}/api/accounts/${effectiveAccountId}/pipelines`,
        pipelineData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Extract the pipeline ID
      let pipelineId = null;
      if (
        response.data &&
        response.data.pipeline &&
        response.data.pipeline.id
      ) {
        pipelineId = response.data.pipeline.id;
      } else {
        throw new Error("Pipeline was created but no ID was returned");
      }

      // Invalidate the pipelines query to refresh data
      queryClient.invalidateQueries(["pipelines", effectiveAccountId]);

      return { ...response.data, pipelineId };
    } catch (err) {
      console.error("Error creating pipeline:", err);
      setError(
        err.response?.data?.message ||
          err.message ||
          "An error occurred during pipeline creation"
      );
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Create stages for a pipeline
   * @param {number} pipelineId - ID of the pipeline to create stages for
   * @param {Array} stages - Array of stage data to create
   * @param {string} accountId - Optional account ID, defaults to the authenticated user's account
   * @returns {Promise<Array>} Created stages data
   */
  const createPipelineStages = async (pipelineId, stages, accountId = null) => {
    if (!pipelineId) {
      setError("Pipeline ID is required");
      return null;
    }

    if (!stages || stages.length === 0) {
      setError("At least one stage is required");
      return null;
    }

    // Use provided accountId or fall back to authenticated user's account
    const effectiveAccountId = accountId || account_ID;
    if (!effectiveAccountId) {
      setError("Account ID is required");
      return null;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Get access token from auth context
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Create stages for the pipeline
      const createdStages = await Promise.all(
        stages.map(async (stage, index) => {
          const stageData = {
            name: stage.name,
            description: stage.description || "",
            passing_criteria: stage.passingCriteria || "",
            display_order: index,
            is_active: true,
            probability: 0,
            is_closed: false,
            is_won: false,
          };

          try {
            const stageResponse = await axios.post(
              `${target}/api/accounts/${effectiveAccountId}/pipelines/${pipelineId}/stages`,
              stageData,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            return stageResponse.data;
          } catch (stageError) {
            console.error(`Error creating stage ${index + 1}:`, stageError);
            throw stageError;
          }
        })
      );

      // Invalidate the pipeline stages query to refresh data
      queryClient.invalidateQueries([
        "pipelineStages",
        effectiveAccountId,
        pipelineId,
      ]);
      queryClient.invalidateQueries(["allPipelineStages", effectiveAccountId]);

      return createdStages;
    } catch (err) {
      console.error("Error creating pipeline stages:", err);
      setError(
        err.response?.data?.message ||
          err.message ||
          "An error occurred during stage creation"
      );
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Create a complete pipeline with stages in one operation
   * @param {Object} pipelineData - Pipeline data to create
   * @param {Array} stages - Array of stage data to create
   * @param {string} accountId - Optional account ID, defaults to the authenticated user's account
   * @returns {Promise<Object>} Created pipeline with stages
   */
  const createPipelineWithStages = async (
    pipelineData,
    stages,
    accountId = null
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      // First create the pipeline
      const pipelineResult = await createPipeline(pipelineData, accountId);

      if (!pipelineResult || !pipelineResult.pipelineId) {
        throw new Error("Failed to create pipeline");
      }

      // Then create the stages
      const stagesResult = await createPipelineStages(
        pipelineResult.pipelineId,
        stages,
        accountId
      );

      if (!stagesResult) {
        throw new Error("Failed to create pipeline stages");
      }

      return {
        pipeline: pipelineResult.pipeline,
        stages: stagesResult,
      };
    } catch (err) {
      setError(err.message || "Failed to create pipeline with stages");
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createPipeline,
    createPipelineStages,
    createPipelineWithStages,
    isLoading,
    error,
  };
};
