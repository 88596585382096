// src/views/lysto/Dashboard/components/Sales Target/SalesTargetGrid.js

import React, { useMemo } from "react";
import { Box, SimpleGrid, VStack } from "@chakra-ui/react";
import StatCard from "../StatCard";
import { MdInsights, MdTrendingUp, MdPieChart } from "react-icons/md";
import { formatCurrencyValue } from "../../../utils/formatters";
import TargetComparisonCard from "./TargetComparisonCard";
import GapAnalysisGrid from "./GapAnalysisGrid";
import ClosedWonChart from "./ClosedWonChart";

const SalesTargetGrid = ({
  deals,
  selectedYear,
  selectedQuarter,
  salesTargets,
  selectedSegment,
}) => {
  const PRIMARY_COLOR = "#1A5BF6";
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentQuarter = Math.floor(currentMonth / 3);

  // Calculate metrics for the selected year and quarter
  const metrics = useMemo(() => {
    if (!deals?.currentPeriod || !deals?.prevPeriod) {
      return {
        year: {
          total: 0,
          target: 0,
          percentage: 0,
          growth: 0,
          pipeline: 0,
          pipelineGrowth: 0,
        },
        quarter: {
          total: 0,
          target: 0,
          percentage: 0,
          pipeline: 0,
          growth: 0,
        },
      };
    }

    // Filter deals by segment if selected
    const filterDealsBySegment = (dealsArray) => {
      if (!selectedSegment || selectedSegment === "All") return dealsArray;

      return dealsArray.filter((deal) => {
        const locations = deal.extra_fields?.No_of_Locations__c;
        if (locations === null || locations === undefined) return false;

        switch (selectedSegment) {
          case "SMB":
            return locations >= 1 && locations <= 20;
          case "Mid-Market":
            return locations > 20 && locations <= 200;
          case "Enterprise":
            return locations > 200;
          default:
            return true;
        }
      });
    };

    const currentPeriodDeals = filterDealsBySegment(deals.currentPeriod);
    const prevPeriodDeals = filterDealsBySegment(deals.prevPeriod);

    // Calculate year target based on monthly targets (only months 1-12)
    const yearTarget = Array.from({ length: 12 }, (_, i) => i + 1).reduce(
      (sum, month) => sum + (salesTargets[month] || 0),
      0
    );

    // Calculate quarter target based on monthly targets
    const getQuarterTarget = (quarter) => {
      const startMonth = (quarter - 1) * 3 + 1;
      return (
        (salesTargets[startMonth] || 0) +
        (salesTargets[startMonth + 1] || 0) +
        (salesTargets[startMonth + 2] || 0)
      );
    };

    const quarterTarget = selectedQuarter
      ? getQuarterTarget(selectedQuarter)
      : getQuarterTarget(currentQuarter + 1);

    // Selected Year Deals
    const yearDeals = currentPeriodDeals.filter((deal) => {
      const dealDate = new Date(deal.close_date);
      return dealDate.getFullYear() === selectedYear && deal.is_won;
    });

    // Quarter Deals
    const quarterDeals = yearDeals.filter((deal) => {
      const dealDate = new Date(deal.close_date);
      const dealQuarter = Math.floor(dealDate.getMonth() / 3);
      return selectedQuarter
        ? dealQuarter === selectedQuarter - 1
        : dealQuarter === currentQuarter;
    });

    // Calculate year metrics
    const yearTotal = yearDeals.reduce(
      (sum, deal) => sum + (Number(deal.amount) || 0),
      0
    );
    const yearPercentage = (yearTotal / yearTarget) * 100;

    // Calculate quarter metrics
    const quarterTotal = quarterDeals.reduce(
      (sum, deal) => sum + (Number(deal.amount) || 0),
      0
    );
    const quarterPercentage = (quarterTotal / quarterTarget) * 100;

    // Calculate pipeline values for selected year
    const pipelineDealsYear = currentPeriodDeals.filter((deal) => {
      const dealDate = new Date(deal.close_date);
      return !deal.is_closed && dealDate.getFullYear() === selectedYear;
    });
    const pipelineTotalYear = pipelineDealsYear.reduce(
      (sum, deal) => sum + (Number(deal.amount) || 0),
      0
    );

    // Calculate pipeline values for selected quarter
    const pipelineDealsQuarter = currentPeriodDeals.filter((deal) => {
      const dealDate = new Date(deal.close_date);
      const dealQuarter = Math.floor(dealDate.getMonth() / 3);
      return (
        !deal.is_closed &&
        dealDate.getFullYear() === selectedYear &&
        (selectedQuarter
          ? dealQuarter === selectedQuarter - 1
          : dealQuarter === currentQuarter)
      );
    });
    const pipelineTotalQuarter = pipelineDealsQuarter.reduce(
      (sum, deal) => sum + (Number(deal.amount) || 0),
      0
    );

    // Calculate previous year's metrics for YoY comparison
    const prevYearDeals = prevPeriodDeals.filter((deal) => {
      const dealDate = new Date(deal.close_date);
      return dealDate.getFullYear() === selectedYear - 1 && deal.is_won;
    });
    const prevYearTotal = prevYearDeals.reduce(
      (sum, deal) => sum + (Number(deal.amount) || 0),
      0
    );

    // Calculate previous quarter's metrics
    const prevQuarterDeals = prevPeriodDeals.filter((deal) => {
      const dealDate = new Date(deal.close_date);
      const dealQuarter = Math.floor(dealDate.getMonth() / 3);
      const targetQuarter = selectedQuarter === 1 ? 4 : selectedQuarter - 1;
      const targetYear =
        selectedQuarter === 1 ? selectedYear - 1 : selectedYear;
      return (
        dealDate.getFullYear() === targetYear &&
        dealQuarter === targetQuarter - 1 &&
        deal.is_won
      );
    });
    const prevQuarterTotal = prevQuarterDeals.reduce(
      (sum, deal) => sum + (Number(deal.amount) || 0),
      0
    );

    // Calculate previous year's pipeline total for YoY growth
    const pipelineDealsLastYear = prevPeriodDeals.filter((deal) => {
      const dealDate = new Date(deal.close_date);
      return !deal.is_closed && dealDate.getFullYear() === selectedYear - 1;
    });
    const pipelineTotalLastYear = pipelineDealsLastYear.reduce(
      (sum, deal) => sum + (Number(deal.amount) || 0),
      0
    );

    // Calculate growth percentages
    const yearOverYearGrowth =
      prevYearTotal > 0
        ? ((yearTotal - prevYearTotal) / prevYearTotal) * 100
        : 0;
    const pipelineYoYGrowth =
      pipelineTotalLastYear > 0
        ? ((pipelineTotalYear - pipelineTotalLastYear) /
            pipelineTotalLastYear) *
          100
        : 0;
    const quarterOverQuarterGrowth =
      prevQuarterTotal > 0
        ? ((quarterTotal - prevQuarterTotal) / prevQuarterTotal) * 100
        : 0;

    return {
      year: {
        total: yearTotal,
        target: yearTarget,
        percentage: yearPercentage,
        growth: yearOverYearGrowth,
        pipeline: pipelineTotalYear,
        pipelineGrowth: pipelineYoYGrowth,
      },
      quarter: {
        total: quarterTotal,
        target: quarterTarget,
        percentage: quarterPercentage,
        pipeline: pipelineTotalQuarter,
        growth: quarterOverQuarterGrowth,
      },
    };
  }, [
    deals,
    selectedYear,
    selectedQuarter,
    currentQuarter,
    salesTargets,
    selectedSegment,
  ]);

  // Define key to force update
  const chartKey = useMemo(
    () =>
      `target-chart-${selectedYear}-${
        selectedQuarter || "full"
      }-${selectedSegment}`,
    [selectedYear, selectedQuarter, selectedSegment]
  );

  const shouldShowQuarter =
    selectedQuarter || selectedYear === now.getFullYear();
  const displayQuarter = selectedQuarter || currentQuarter + 1;

  return (
    <VStack spacing={6} align="stretch">
      {/* Year Section */}
      {!selectedQuarter && (
        <Box pb={4}>
          <SimpleGrid columns={{ base: 1, sm: 1, md: 4 }} gap="20px">
            <StatCard
              icon={MdInsights}
              title={`Sales ${selectedYear}${
                selectedSegment && selectedSegment !== "All"
                  ? ` (${selectedSegment})`
                  : ""
              }`}
              value={formatCurrencyValue(metrics.year.total)}
              growth={metrics.year.growth}
              timeRange="YoY"
              primaryColor={PRIMARY_COLOR}
              tooltip={`Sales ${selectedYear}: ${formatCurrencyValue(
                metrics.year.total
              )} vs Target: ${formatCurrencyValue(metrics.year.target)}`}
            />
            <TargetComparisonCard
              value={metrics.year.percentage}
              target={formatCurrencyValue(metrics.year.target)}
              primaryColor={PRIMARY_COLOR}
              selectedSegment={selectedSegment}
            />
            <StatCard
              icon={MdTrendingUp}
              title={`Open Pipeline (${selectedYear})${
                selectedSegment && selectedSegment !== "All"
                  ? ` (${selectedSegment})`
                  : ""
              }`}
              value={formatCurrencyValue(metrics.year.pipeline)}
              growth={metrics.year.pipelineGrowth}
              timeRange="YoY"
              primaryColor={PRIMARY_COLOR}
              tooltip={`Pipeline ${selectedYear}: ${formatCurrencyValue(
                metrics.year.pipeline
              )} vs Last Year: ${formatCurrencyValue(
                metrics.year.pipeline -
                  (metrics.year.pipelineGrowth * metrics.year.pipeline) / 100
              )}`}
            />
            <GapAnalysisGrid
              salesGap={metrics.year.total - metrics.year.target}
              pipeGap={
                metrics.year.pipeline -
                (metrics.year.target - metrics.year.total)
              }
              primaryColor={PRIMARY_COLOR}
              selectedSegment={selectedSegment}
            />
          </SimpleGrid>
        </Box>
      )}

      {/* Quarter Section */}
      {shouldShowQuarter && (
        <Box pb={4}>
          <SimpleGrid columns={{ base: 1, sm: 1, md: 4 }} gap="20px">
            <StatCard
              icon={MdPieChart}
              title={`Q${displayQuarter} ${selectedYear}${
                selectedSegment && selectedSegment !== "All"
                  ? ` (${selectedSegment})`
                  : ""
              }`}
              value={formatCurrencyValue(metrics.quarter.total)}
              growth={metrics.quarter.growth}
              timeRange={selectedQuarter ? "QoQ" : "vs Target"}
              primaryColor={PRIMARY_COLOR}
              tooltip={`Q${displayQuarter} Sales: ${formatCurrencyValue(
                metrics.quarter.total
              )} vs Target: ${formatCurrencyValue(metrics.quarter.target)}`}
            />
            <TargetComparisonCard
              value={metrics.quarter.percentage}
              target={formatCurrencyValue(metrics.quarter.target)}
              primaryColor={PRIMARY_COLOR}
              selectedSegment={selectedSegment}
            />
            <StatCard
              icon={MdTrendingUp}
              title={`Q${displayQuarter} Pipeline${
                selectedSegment && selectedSegment !== "All"
                  ? ` (${selectedSegment})`
                  : ""
              }`}
              value={formatCurrencyValue(metrics.quarter.pipeline)}
              growth={metrics.quarter.growth}
              timeRange="QoQ"
              primaryColor={PRIMARY_COLOR}
              tooltip={`Q${displayQuarter} Pipeline: ${formatCurrencyValue(
                metrics.quarter.pipeline
              )}`}
            />
            <GapAnalysisGrid
              salesGap={metrics.quarter.total - metrics.quarter.target}
              pipeGap={
                metrics.quarter.pipeline -
                (metrics.quarter.target - metrics.quarter.total)
              }
              primaryColor={PRIMARY_COLOR}
              selectedSegment={selectedSegment}
            />
          </SimpleGrid>
        </Box>
      )}

      {/* Closed Won Chart */}
      <Box key={chartKey} pb={4}>
        <ClosedWonChart
          deals={deals}
          selectedYear={selectedYear}
          selectedQuarter={selectedQuarter}
          salesTargets={salesTargets}
          selectedSegment={selectedSegment}
        />
      </Box>
    </VStack>
  );
};

export default SalesTargetGrid;
