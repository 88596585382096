// /src/views/lysto/Meetings/TagComponents.jsx
import React from 'react';
import { Text, Box, Flex, Icon } from '@chakra-ui/react';
import { FaUser, FaCheck, FaQuestion, FaTimes, FaUserClock } from 'react-icons/fa';
import BaseTag from '../Shared/BaseTag';

export const OverviewTag = ({ icon: IconComponent, label, value, colorScheme }) => (
  <BaseTag
    colorScheme={colorScheme}
    icon={IconComponent}
    tooltip={null}
  >
    <Text fontSize="sm" fontWeight="medium">
      {label}: <Text as="span" fontWeight="semibold">{value}</Text>
    </Text>
  </BaseTag>
);

export const AttendeeTag = ({ attendee }) => {
    const getStatusStyles = () => {
      switch (attendee.response_status) {
        case 'accepted':
          return {
            icon: FaCheck,
            colorScheme: 'blue',
            statusText: 'Accepted'
          };
        case 'declined':
          return {
            icon: FaTimes,
            colorScheme: 'red',
            statusText: 'Declined'
          };
        case 'tentative':
          return {
            icon: FaUserClock,
            colorScheme: 'purple',
            statusText: 'Tentative'
          };
        case 'needsAction':
        default:
          return {
            icon: FaQuestion,
            colorScheme: 'gray',
            statusText: 'Pending'
          };
      }
    };
    const capitalizeFirstName = (name) => {
        if (!name) return "";
        return name.split(" ")[0].charAt(0).toUpperCase() + name.split(" ")[0].slice(1);
      };
  
    const { icon: StatusIcon, colorScheme, statusText } = getStatusStyles();
    const role = attendee.organizer
      ? 'Organizer'
      : attendee.optional
      ? 'Optional'
      : 'Attendee';
    
    const displayName = capitalizeFirstName(attendee.display_name || attendee.name || attendee.email?.split('@')[0] || 'Unknown');
  
    return (
      <BaseTag
        colorScheme={colorScheme}
        tooltip={`${role} • ${statusText} • ${attendee.email}`}
        mr={3}
        mb={0}
      >
        <Flex align="center" position="relative">
          <Icon as={FaUser} color={`${colorScheme}.500`} mr={2} />
          <Box
            position="absolute"
            bottom="-2px"
            left="-2px"
            bg="white"
            borderRadius="full"
            boxSize="12px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderWidth="1px"
            borderColor={`${colorScheme}.200`}
          >
            <Icon as={StatusIcon} boxSize="8px" color={`${colorScheme}.500`} />
          </Box>
          <Text fontSize="sm" fontWeight="medium">
            {displayName}
          </Text>
        </Flex>
      </BaseTag>
    );
  };