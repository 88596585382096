import { useState, useEffect } from "react";
import { useAuthData } from "auth-context";
import axios from "axios";

export const useFetchMeetingDeals = (accountId, dealId) => {
  const { getAccessToken } = useAuthData();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Reset state at the start of each fetch
      setError(null);
      setIsLoading(true);

      // Add validation for accountId and dealId
      if (!accountId || !dealId) {
        setIsLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessToken();
        const baseUrl =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
            : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${baseUrl}/api/accounts/${accountId}/deals/${dealId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response.data) {
          setData([response.data]);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching deal:", {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
          accountId,
          dealId,
        });

        // Provide more specific error messages
        if (error.response?.status === 404) {
          setError("Deal not found");
        } else if (
          error.response?.status === 401 ||
          error.response?.status === 403
        ) {
          setError("Not authorized to view this deal");
        } else {
          setError(`Failed to load deal information: ${error.message}`);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [accountId, dealId, getAccessToken]);

  return { data, isLoading, error };
};
