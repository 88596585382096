import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Center, Spinner, useToast } from '@chakra-ui/react';
import { useAuthData } from 'auth-context';
import axios from 'axios';
import EmailFollowupDetail from './EmailFollowupDetail';

const EmailFollowupWrapper = ({ onNavigateToInbox }) => {
  const { dealId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { account_ID, user_ID } = useAuthData();
  const queryClient = useQueryClient();
  
  const dealData = location.state || {};
  
  // Pre-fetch deal activities to avoid loading state
  useEffect(() => {
    if (account_ID && dealId) {
      const target = process.env.REACT_APP_API_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      const endpoint = `${target}/api/accounts/${account_ID}/deals/${dealId}/activities`;
      
      queryClient.prefetchQuery({
        queryKey: ['dealActivities', account_ID, dealId],
        queryFn: async () => {
          try {
            const response = await axios.get(endpoint);
            return response.data;
          } catch (error) {
            // Error fetching deal activities
            return [];
          }
        },
        staleTime: 60000, // 1 minute
      });
    }
  }, [account_ID, dealId, queryClient]);
  
  // Fetch the deal if not provided in state
  const { data: deal, isLoading } = useQuery({
    queryKey: ['deal', account_ID, dealId],
    queryFn: async () => {
      if (dealData.dealName && dealData.accountId) {
        // If we have basic data from the navigation state, use it
        return {
          deal_id: parseInt(dealId, 10),
          deal_name: dealData.dealName,
          account_id: dealData.accountId,
          task_type: 'follow-up',
          task_subtype: 'email',
          created_at: new Date().toISOString(),
          fromDeal: true
        };
      }
      
      // Fall back to fetching the deal
      const target = process.env.REACT_APP_API_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      try {
        const response = await axios.get(`${target}/api/accounts/${account_ID}/deals/${dealId}`);
        
        // Get any accounts or contacts from the deal to use as email recipients
        let emailRecipients = [];
        
        // Check for accounts in the deal
        if (response.data.accounts && response.data.accounts.length > 0) {
          emailRecipients = response.data.accounts.map(account => ({
            id: account.id,
            name: account.name || 'Unknown Account',
            email: account.email || '',
            type: 'account'
          }));
        }
        
        // Check for contacts in the deal
        if (response.data.contacts && response.data.contacts.length > 0) {
          const contactRecipients = response.data.contacts
            .filter(contact => contact.email) // Only include contacts with email
            .map(contact => ({
              id: contact.id,
              name: `${contact.name || ''} ${contact.last_name || ''}`.trim() || contact.email,
              email: contact.email,
              type: 'contact'
            }));
          
          emailRecipients = [...emailRecipients, ...contactRecipients];
        }
        
        // Create a properly formatted item object that EmailFollowupDetail expects
        return {
          id: parseInt(dealId, 10),
          deal_id: parseInt(dealId, 10),
          deal_name: response.data.name || response.data.deal_name,
          account_id: response.data.account_id,
          account_name: response.data.crm_account_name || response.data.account_name || 'Unknown Account',
          task_type: 'follow-up',
          task_subtype: 'email',
          created_at: new Date().toISOString(),
          fromDeal: true,
          subject: `RE: ${response.data.name || response.data.deal_name}`,
          email_recipients: emailRecipients,
          user_id: user_ID,
          assigned_user_id: response.data.user_id || user_ID,
          owner_id: response.data.user_id || user_ID,
          champion: response.data.champion,
          amount: response.data.amount,
          stage_name: response.data.stage_name,
          pipeline_name: response.data.pipeline_name,
          last_activity_date: response.data.last_activity_date
        };
      } catch (error) {
        // Error fetching deal information
        throw new Error("Failed to fetch deal information");
      }
    },
    enabled: !!account_ID && !!dealId,
  });
  
  const handleGoBack = () => {
    // Try to get the previous location from session storage
    try {
      const prevLocationStr = sessionStorage.getItem("dealDetailsPreviousLocation");
      if (prevLocationStr) {
        const prevLocation = JSON.parse(prevLocationStr);
        // Make sure we're going back to the same deal we came from
        if (prevLocation.dealId === dealId) {
          navigate(prevLocation.pathname + (prevLocation.search || ''));
          return;
        }
      }
    } catch (e) {
      // Error parsing previous location - fallback to default navigation
    }
    
    // Default fallback: go to inbox
    onNavigateToInbox();
  };
  
  const handleSendEmail = async (emailData) => {
    try {
      const target = process.env.REACT_APP_API_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      await axios.post(`${target}/api/accounts/${account_ID}/send-email`, {
        ...emailData,
        deal_id: dealId,
        account_id: account_ID,
        user_id: user_ID
      });
      
      toast({
        title: 'Email Sent',
        description: 'Your email has been successfully sent',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      
      // Navigate back to the deal detail page
      handleGoBack();
    } catch (error) {
      // Failed to send email
      toast({
        title: 'Email Failed',
        description: error.response?.data?.message || 'Failed to send email',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  const handleAddAnnotation = () => {
    // This is a placeholder implementation since annotation functionality
    // isn't required for this integration
    return Promise.resolve();
  };
  
  const handleGenerateEmail = async (type, prompt) => {
    try {
      const target = process.env.REACT_APP_API_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      const response = await axios.post(`${target}/api/accounts/${account_ID}/generate-email`, {
        deal_id: dealId,
        type,
        prompt,
        account_id: account_ID,
        user_id: user_ID
      });
      
      if (response.data?.content) {
        return response.data;
      }
      
      // Error generating email content
      return {
        content: `<p>Here is your generated email content for the deal: ${deal?.deal_name}</p><p>This is a follow-up regarding our previous discussion.</p>`,
        subject: `RE: ${deal?.deal_name}`,
        email_recipients: deal?.email_recipients || []
      };
    } catch (error) {
      // Error generating email content
      return {
        content: `<p>Here is your generated email content for the deal: ${deal?.deal_name}</p><p>This is a follow-up regarding our previous discussion.</p>`,
        subject: `RE: ${deal?.deal_name}`,
        email_recipients: deal?.email_recipients || []
      };
    }
  };
  
  if (isLoading) {
    return (
      <Center p={8}>
        <Spinner size="lg" color="blue.500" thickness="4px" />
      </Center>
    );
  }
  
  if (!deal) {
    return (
      <Center p={8} flexDirection="column">
        <div>No deal information available</div>
        <button 
          onClick={handleGoBack}
          style={{ 
            marginTop: '20px', 
            padding: '8px 16px',
            background: '#4299E1',
            color: 'white',
            borderRadius: '4px',
            border: 'none'
          }}
        >
          Go Back
        </button>
      </Center>
    );
  }
  
  return (
    <EmailFollowupDetail
      item={deal}
      onSendMessage={handleSendEmail}
      onAddAnnotation={handleAddAnnotation}
      onGoBack={handleGoBack}
      onGenerateEmail={handleGenerateEmail}
    />
  );
};

EmailFollowupWrapper.propTypes = {
  onNavigateToInbox: PropTypes.func.isRequired
};

export default EmailFollowupWrapper; 