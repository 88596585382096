// src/components/DealCountByStageAndSourceBarCard.js
import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import BarCard from "../BarCard";
import { CHART_COLORS } from "../../constants";
import { useDealCountByStageAndSource } from "../../../hooks/useDealCountByStageAndSource";
import { PipelineSelect } from "../../../Components/PipelineSelect";

/**
 * DealCountByStageAndSourceBarCard Component
 * Displays deal count data by stage and source in a BarCard.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function DealCountByStageAndSourceBarCard({
  accountID,
  toast,
  selectedYear,
  selectedQuarter,
  selectedSegment,
}) {
  const {
    data,
    loading,
    error,
    leadSourceOptions,
    selectedPipelineId,
    setSelectedPipelineId,
  } = useDealCountByStageAndSource(
    accountID,
    selectedYear,
    selectedQuarter,
    selectedSegment,
    toast
  );

  // Prevent rendering BarCard if data isn't ready or if there's an error
  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Text>Loading deal count statistics...</Text>
      </Box>
    );
  }

  // Create the controls component with both pipeline and lead source selection
  const controls = (
    <Box width="200px">
      <PipelineSelect
        accountId={accountID}
        selectedPipelineId={selectedPipelineId}
        onPipelineChange={setSelectedPipelineId}
        onStageChange={() => {
          /* intentionally left blank */
        }} // Not needed for this component
        isRequired={false}
        hideStageSelect={true}
      />
    </Box>
  );

  // Add colors to the datasets
  const barData = {
    ...data,
    datasets:
      data && data.datasets
        ? data.datasets.map((dataset) => ({
            ...dataset,
            backgroundColor: CHART_COLORS.slice(
              0,
              data.labels ? data.labels.length : 0
            ),
          }))
        : [],
  };

  return (
    <Box pb={4} w="100%">
      <Flex justifyContent="flex-end" mb={4}>
        {controls}
      </Flex>
      {error ||
      !data ||
      !data.datasets ||
      !leadSourceOptions ||
      leadSourceOptions.length === 0 ? (
        <Box textAlign="center" py={10}>
          <Text color="red.500">
            {error
              ? "Failed to load deal count statistics."
              : "No deal count data available."}
          </Text>
        </Box>
      ) : (
        <BarCard barData={barData} showLabels={false} />
      )}
    </Box>
  );
}
