import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to send a Slack message
 * @returns {Object} - Contains the mutation function and states
 */
function useSendSlackMessage() {
  const { getAccessToken } = useAuthData();

  return useMutation({
    mutationFn: async ({ accountId, dealId, userId, channelId, content, templateType }) => {
      if (!accountId || !dealId || !userId || !channelId || !content) {
        throw new Error("Missing required parameters for sending Slack message");
      }

      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.post(
        `${target}/api/accounts/${accountId}/users/${userId}/deals/${dealId}/slack/message`,
        { 
          channel_id: channelId,
          content: content,
          template_type: templateType || "status_update",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    },
  });
}

export default useSendSlackMessage; 