import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Portal,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  FaEnvelope,
  FaPercentage,
  FaComments,
  FaChartLine,
  FaPhone,
  FaVideo,
  FaChartBar,
  FaHandshake,
  FaExchangeAlt,
  FaCalendarAlt,
  FaBook,
  FaCalendarCheck,
  FaMousePointer,
  FaMoneyBillWave,
} from 'react-icons/fa';
import useFetchDealMetrics from '../hooks/useFetchDealMetrics';
import { format, parseISO } from 'date-fns';

/**
 * A custom tag component that displays metrics with a portal-based tooltip for date range
 */
const MetricTag = ({ icon, label, value, colorScheme, firstDate, lastDate }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  
  const tooltipBg = useColorModeValue("gray.700", "gray.900");
  const tooltipTextColor = useColorModeValue("white", "gray.100");
  
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleMouseMove = (e) => setMousePos({ x: e.clientX, y: e.clientY });
  
  // Format dates for display
  const formattedFirstDate = firstDate ? format(parseISO(firstDate), 'MMM d, yyyy') : null;
  const formattedLastDate = lastDate ? format(parseISO(lastDate), 'MMM d, yyyy') : null;
  
  // Create date range text
  const dateRangeText = formattedFirstDate && formattedLastDate
    ? `Data from ${formattedFirstDate} to ${formattedLastDate}`
    : null;
  
  return (
    <>
      <Flex
        align="center"
        bg={`${colorScheme}.50`}
        color={`${colorScheme}.700`}
        px={3}
        py={2}
        borderRadius="md"
        borderWidth="1px"
        borderColor={`${colorScheme}.200`}
        _hover={{
          bg: `${colorScheme}.100`,
          transform: "translateY(-1px)",
          boxShadow: "sm",
        }}
        height="40px"
        minWidth="150px"
        position="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        transition="all 0.2s ease"
      >
        <Icon as={icon} mr={2} transition="0.2s" />
        <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
          <Text as="span" fontWeight="bold">{label}:</Text> {value || 'N/A'}
        </Text>
      </Flex>
      
      {isHovered && dateRangeText && (
        <Portal>
          <Box
            position="fixed"
            left={mousePos.x + 10}
            top={mousePos.y + 10}
            bg={tooltipBg}
            color={tooltipTextColor}
            px="8px"
            py="4px"
            borderRadius="md"
            zIndex="tooltip"
            pointerEvents="none"
            boxShadow="lg"
            maxWidth="300px"
            overflowWrap="break-word"
          >
            <Flex align="center" fontSize="sm">
              <Icon as={FaCalendarAlt} mr={1} />
              <Text>{dateRangeText}</Text>
            </Flex>
          </Box>
        </Portal>
      )}
    </>
  );
};

MetricTag.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  colorScheme: PropTypes.string.isRequired,
  firstDate: PropTypes.string,
  lastDate: PropTypes.string,
};

/**
 * Component to display deal metrics in a summary format
 */
const DealMetricsSection = ({ accountId, dealId, startDate, endDate }) => {
  // Fetch regular deal metrics data (total counts)
  const { data: metricsData, error } = useFetchDealMetrics(
    accountId,
    dealId,
    {
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      viewType: "sum",  // Request summary values by default
      summary: true     // Request summary data
    }
  );

  // Fetch unique metrics data
  const { data: uniqueMetricsData } = useFetchDealMetrics(
    accountId,
    dealId,
    {
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      viewType: "sum", 
      summary: true,
      unique: true     // Request unique count data
    }
  );

  // Function to create metrics array from either summaries or metric_series
  const getMetricsToDisplay = () => {
    let metrics = [];
    
    if (metricsData) {
      // If we have summaries, use those
      if (metricsData.summaries && metricsData.summaries.length > 0) {
        metrics = [...metricsData.summaries];
        
        // Check if we have booking.created metric
        const bookingMetric = metricsData.summaries.find(
          metric => metric.metric_key.toLowerCase() === 'booking.created'
        );
        
        // If we have booking.created metric, add a separate booking count metric
        if (bookingMetric && bookingMetric.total_count !== undefined) {
          metrics.push({
            ...bookingMetric,
            metric_key: 'booking.count', // Custom key for booking count
            value: bookingMetric.total_count,
            display_value: bookingMetric.total_count
          });
        }
      }
      // If we have metric_series but no summaries, convert to a similar format
      else if (metricsData.metric_series && metricsData.metric_series.length > 0) {
        // Group metrics by key
        const metricsByKey = {};
        
        metricsData.metric_series.forEach(metric => {
          if (!metricsByKey[metric.metric_key]) {
            metricsByKey[metric.metric_key] = {
              metric_key: metric.metric_key,
              values: [],
              lastValue: null,
              timestamp: null,
              count: metric.count
            };
          }
          metricsByKey[metric.metric_key].values.push(metric.value);
          // Keep track of the latest value based on timestamp
          if (!metricsByKey[metric.metric_key].timestamp || 
              metric.timestamp > metricsByKey[metric.metric_key].timestamp) {
            metricsByKey[metric.metric_key].lastValue = metric.value;
            metricsByKey[metric.metric_key].timestamp = metric.timestamp;
            if (metric.count !== undefined) {
              metricsByKey[metric.metric_key].count = metric.count;
            }
          }
        });
        
        // Create summary-like objects
        metrics = Object.values(metricsByKey).map(metricGroup => {
          const values = metricGroup.values;
          const total = values.reduce((sum, val) => sum + val, 0);
          
          return {
            metric_key: metricGroup.metric_key,
            total_count: values.length,
            total_value: total,
            average_value: total / values.length,
            min_value: Math.min(...values),
            max_value: Math.max(...values),
            // Keep the original value from the latest metric point
            value: metricGroup.lastValue,
            // Include count directly from metric data
            count: metricGroup.count,
            // For debugging/visibility
            original_values: values
          };
        });
        
        // Check if we have booking.created metric
        const bookingMetric = metrics.find(
          metric => metric.metric_key.toLowerCase() === 'booking.created'
        );
        
        // If we have booking.created metric, add a separate booking count metric
        if (bookingMetric && bookingMetric.count !== undefined) {
          metrics.push({
            ...bookingMetric,
            metric_key: 'booking.count', // Custom key for booking count
            value: bookingMetric.count,
            display_value: bookingMetric.count
          });
        }
      }
    }
    
    // Process unique metrics if available
    if (uniqueMetricsData && uniqueMetricsData.is_unique_count) {
      let uniqueMetrics = [];
      
      // Process summaries first
      if (uniqueMetricsData.summaries && uniqueMetricsData.summaries.length > 0) {
        uniqueMetrics = uniqueMetricsData.summaries.map(metric => ({
          ...metric,
          metric_key: metric.metric_key.includes('Page Views') ? 'Unique Clicks' : `Unique ${metric.metric_key}`,
          is_unique: true
        }));
      } 
      // Process metric_series if summaries not available
      else if (uniqueMetricsData.metric_series && uniqueMetricsData.metric_series.length > 0) {
        const uniqueMetricsByKey = {};
        
        uniqueMetricsData.metric_series.forEach(metric => {
          const uniqueKey = metric.metric_key.includes('Page Views') ? 'Unique Clicks' : `Unique ${metric.metric_key}`;
          
          if (!uniqueMetricsByKey[uniqueKey]) {
            uniqueMetricsByKey[uniqueKey] = {
              metric_key: uniqueKey,
              values: [],
              lastValue: null,
              timestamp: null,
              count: metric.count,
              is_unique: true
            };
          }
          
          uniqueMetricsByKey[uniqueKey].values.push(metric.value);
          
          if (!uniqueMetricsByKey[uniqueKey].timestamp || 
              metric.timestamp > uniqueMetricsByKey[uniqueKey].timestamp) {
            uniqueMetricsByKey[uniqueKey].lastValue = metric.value;
            uniqueMetricsByKey[uniqueKey].timestamp = metric.timestamp;
            if (metric.count !== undefined) {
              uniqueMetricsByKey[uniqueKey].count = metric.count;
            }
          }
        });
        
        const processedUniqueMetrics = Object.values(uniqueMetricsByKey).map(metricGroup => {
          const values = metricGroup.values;
          const total = values.reduce((sum, val) => sum + val, 0);
          
          return {
            metric_key: metricGroup.metric_key,
            total_count: values.length,
            total_value: total,
            average_value: total / values.length,
            min_value: Math.min(...values),
            max_value: Math.max(...values),
            value: metricGroup.lastValue,
            count: metricGroup.count,
            original_values: values,
            is_unique: true
          };
        });
        
        uniqueMetrics = processedUniqueMetrics;
      }
      
      // Add unique metrics to the regular metrics
      metrics = [...metrics, ...uniqueMetrics];
    }
    
    return metrics;
  };

  // Function to get the appropriate icon for a metric
  const getMetricIcon = (metricKey) => {
    switch (metricKey.toLowerCase()) {
      case 'email_count':
        return FaEnvelope;
      case 'call_count':
        return FaPhone;
      case 'meeting_count':
        return FaVideo;
      case 'engagement_rate':
        return FaPercentage;
      case 'response_rate':
        return FaComments;
      case 'activity_count':
        return FaChartBar;
      case 'conversion_rate':
        return FaExchangeAlt;
      case 'opportunity_score':
        return FaHandshake;
      case 'page views':
      case 'total clicks':
      case 'unique clicks':
        return FaMousePointer;
      case 'booking.created':
      case 'booking_created':
        return FaCalendarCheck;
      case 'booking.count':
        return FaCalendarAlt;
      case 'booking':
        return FaBook;
      case 'quote.submit':
        return FaMoneyBillWave;
      default:
        return FaChartLine;
    }
  };

  // Function to get a human-readable label for a metric
  const getMetricLabel = (metricKey) => {
    // Special cases for specific metrics
    const lowerKey = metricKey.toLowerCase();
    if (lowerKey === 'booking.created') {
      return 'Total Booking Value';
    }
    if (lowerKey === 'booking.count') {
      return 'Booking Count';
    }
    if (lowerKey === 'page views') {
      return 'Total Clicks';
    }
    if (lowerKey === 'total clicks') {
      return 'Total Clicks';
    }
    if (lowerKey === 'unique clicks') {
      return 'Unique Clicks';
    }
    if (lowerKey === 'quote.submit') {
      return 'Quote Submissions';
    }
    
    return metricKey
      .split(/[_.]/) // Split by underscores or dots
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Function to format metric value
  const formatMetricValue = (metric) => {
    const metricKey = metric.metric_key.toLowerCase();
    let bookingValue;
    
    switch (metricKey) {
      case 'engagement_rate':
      case 'response_rate':
      case 'conversion_rate':
        return `${Math.round(metric.average_value * 100)}%`;
      case 'opportunity_score':
        return metric.average_value.toFixed(1);
      case 'booking.created':
      case 'booking_created':
        // Format as Pounds instead of dollars
        bookingValue = metric.total_value || metric.value || 0;
        return new Intl.NumberFormat('en-GB', { 
          style: 'currency', 
          currency: 'GBP',
          minimumFractionDigits: 2
        }).format(bookingValue);
      case 'booking.count':
        // Return the count directly for booking count
        return String(metric.display_value || metric.total_count || metric.count || 0);
      case 'page views':
      case 'total clicks':
      case 'unique clicks':
        // Return the exact value for click/view metrics
        return String(Math.round(metric.total_value || metric.value || 0));
      case 'quote.submit':
        // Return the count rather than formatting as currency
        return String(metric.count || metric.total_count || 0);
      default:
        if (metric.total_count !== undefined) {
          return metric.total_count.toString();
        }
        if (metric.total_value !== undefined) {
          return metric.total_value.toFixed(0);
        }
        if (metric.value !== undefined) {
          return metric.value.toFixed(0);
        }
        return '0';
    }
  };

  // Function to get color scheme for metric
  const getMetricColorScheme = (metricKey) => {
    switch (metricKey.toLowerCase()) {
      case 'email_count':
        return 'blue';
      case 'call_count':
        return 'green';
      case 'meeting_count':
        return 'purple';
      case 'engagement_rate':
        return 'teal';
      case 'response_rate':
        return 'orange';
      case 'activity_count':
        return 'cyan';
      case 'conversion_rate':
        return 'pink';
      case 'opportunity_score':
        return 'yellow';
      case 'page views':
      case 'total clicks':
        return 'blue';
      case 'unique clicks':
        return 'teal';
      case 'booking.created':
      case 'booking_created':
      case 'booking':
        return 'red';
      case 'booking.count':
        return 'orange';
      case 'quote.submit':
        return 'green';
      default:
        return 'gray';
    }
  };

  // Check if this is a 404 error (endpoint not implemented)
  const isEndpointNotImplemented = error?.response?.status === 404;
  
  // Check if this is a date format error
  const isDateFormatError = error?.response?.status === 400 && 
    (error.response.data?.error?.includes('date format') || 
     error.response.data?.message?.includes('date format'));

  // Get metrics to display (from either summaries or metric_series)
  const metricsToDisplay = getMetricsToDisplay();
  
  // Sort metrics to ensure related metrics are displayed next to each other
  const sortedMetrics = useMemo(() => {
    if (!metricsToDisplay || metricsToDisplay.length === 0) {
      return [];
    }
    
    // Create a copy of the metrics array
    const metricsCopy = [...metricsToDisplay];
    
    // Define metric priority order for display (metrics not in this list will be at the end)
    const metricPriority = {
      'Total Clicks': 1,
      'Unique Clicks': 2,
      'total clicks': 1,
      'unique clicks': 2,
      'Page Views': 1, // In case it's still named this way
      'Email Count': 3,
      'Call Count': 4,
      'Meeting Count': 5,
      'Engagement Rate': 6,
      'Response Rate': 7,
      'Quote Submissions': 8,
      'Total Booking Value': 9,
      'Booking Count': 10,
    };
    
    // Sort metrics by priority
    return metricsCopy.sort((a, b) => {
      const aKey = a.metric_key.toLowerCase();
      const bKey = b.metric_key.toLowerCase();
      
      // Get display labels
      const aLabel = getMetricLabel(a.metric_key);
      const bLabel = getMetricLabel(b.metric_key);
      
      // Get priority values (default to 999 if not in the priority list)
      const aPriority = metricPriority[aLabel] || metricPriority[aKey] || 999;
      const bPriority = metricPriority[bLabel] || metricPriority[bKey] || 999;
      
      // Sort by priority
      return aPriority - bPriority;
    });
  }, [metricsToDisplay]);
  
  // Check if we have any data to display
  const hasDataToDisplay = metricsToDisplay.length > 0;

  // If finished loading without error but no data available, do not render anything
  if (!error && !hasDataToDisplay) {
    return null;
  }

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={3}>
        <Text fontSize="md" fontWeight="semibold">
          Deal Metrics
        </Text>
      </Flex>
      
      {error ? (
        isEndpointNotImplemented ? (
          <Alert status="info" borderRadius="md" variant="subtle">
            <AlertIcon />
            <Box>
              <AlertTitle>Feature Coming Soon</AlertTitle>
              <AlertDescription>
                Deal metrics are not available yet. This feature is under development.
              </AlertDescription>
            </Box>
          </Alert>
        ) : isDateFormatError ? (
          <Alert status="warning" borderRadius="md" variant="subtle">
            <AlertIcon />
            <Box>
              <AlertTitle>Date Format Issue</AlertTitle>
              <AlertDescription>
                There was an issue with the date format. Please make sure dates are valid.
              </AlertDescription>
            </Box>
          </Alert>
        ) : (
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            <Box>
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                {error.message || "Failed to load metrics data"}
              </AlertDescription>
            </Box>
          </Alert>
        )
      ) : (
        <Flex gap={3} flexWrap="wrap">
          {sortedMetrics.map((metric) => (
            <MetricTag
              key={metric.metric_key}
              icon={getMetricIcon(metric.metric_key)}
              label={getMetricLabel(metric.metric_key)}
              value={formatMetricValue(metric)}
              colorScheme={getMetricColorScheme(metric.metric_key)}
              firstDate={metric.first_date}
              lastDate={metric.last_date}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

DealMetricsSection.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dealId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default DealMetricsSection; 