import { Box, useBreakpointValue } from "@chakra-ui/react";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarProvider, useSidebar } from "contexts/SidebarContext";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes.js";
import { STYLE_CONSTANTS } from "components/sidebar/sidebarConstants";
import React from "react";

// Separate component for the dashboard content
function DashboardContent(props) {
  const { visibleRoutes, isExpanded, setIsExpanded } = useSidebar();
  const isMobile = useBreakpointValue({ base: true, xl: false });

  // Handle mobile state
  React.useEffect(() => {
    if (
      isMobile &&
      isExpanded &&
      !localStorage.getItem("lysto-sidebar-expanded")
    ) {
      setIsExpanded(false);
    }
  }, [isMobile, isExpanded, setIsExpanded]);

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.path}
            key={key}
            element={<prop.component {...props} type={prop.type} />}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  // Calculate content width based on sidebar state and screen size
  const contentWidth = isMobile
    ? `calc(100% - ${STYLE_CONSTANTS.COLLAPSED_WIDTH})`
    : `calc(100% - ${
        isExpanded
          ? STYLE_CONSTANTS.EXPANDED_WIDTH
          : STYLE_CONSTANTS.COLLAPSED_WIDTH
      })`;

  return (
    <>
      <Sidebar
        routes={routes.filter((route) => visibleRoutes[route.name])}
        {...props}
      />
      <Box
        float="right"
        minHeight="100vh"
        height="100%"
        overflow="auto"
        position="relative"
        maxHeight="100%"
        w={contentWidth}
        maxWidth={contentWidth}
        ml={STYLE_CONSTANTS.COLLAPSED_WIDTH}
        transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
        transitionDuration=".2s, .2s, .35s"
        transitionProperty="top, bottom, width"
        transitionTimingFunction="linear, linear, ease"
      >
        {getRoute() ? (
          <Box w="100%" minH="100vh" pt="12px">
            <Routes>
              {getRoutes(routes)}
              <Route path="/" element={<Navigate to="main" />} />
            </Routes>
          </Box>
        ) : null}
      </Box>
    </>
  );
}

export default function Dashboard(props) {
  document.documentElement.dir = "ltr";
  return (
    <SidebarProvider>
      <Box>
        <DashboardContent {...props} />
      </Box>
    </SidebarProvider>
  );
}
