import {
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalCloseButton, ModalBody, Text, Button, 
  Flex, VStack, Box
} from "@chakra-ui/react";
import React from 'react';

function ReviewTemplateModal({ isOpen, onClose, campaignTitle, campaignDescription }) {
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg="white">
        <ModalHeader textAlign="center" pb={4}>
          {campaignTitle}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text fontSize="sm" textAlign="center">
              {campaignDescription}
            </Text>
            <Box 
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="md"
              p={3}
              overflowY="auto"
              maxHeight="50vh"
              dangerouslySetInnerHTML={{ __html: campaignDescription || '' }}
              sx={{
                p: { marginBottom: '0.5em' },
                ul: { paddingLeft: '20px', marginBottom: '0.5em' },
                ol: { paddingLeft: '20px', marginBottom: '0.5em' },
                li: { marginBottom: '0.2em' },
              }}
            />
          </VStack>
          <Flex mt={4} mb={2} justifyContent="center">
            <Button
              variant="solid" 
              bg="b_red.200" 
              color="white" 
              justifyContent="center"
              _hover={{ bg: "b_orange.200" }}
            >
              Customize Template
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ReviewTemplateModal;
