// src/components/Shared/GoBackHeader.jsx

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Flex,
  Heading,
  useColorModeValue,
  Box,
  Button,
  Icon,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronLeft } from "lucide-react";
import { Edit2 } from "lucide-react";
import { FaPaperPlane, FaEnvelope, FaSlack } from "react-icons/fa";
import { ChevronDownIcon } from "@chakra-ui/icons";
import EditDealModal from "../Deals/EditDealModal";
import { useNavigate } from "react-router-dom";

/**
 * GoBackHeader Component
 * Renders a header with a back button and a title.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onGoBack - Function to call when the back button is clicked
 * @param {string} props.title - The title to display next to the back button
 * @param {Object} [props.deal] - Optional deal object for editing
 * @param {Object} [props.contact] - Optional contact object for editing
 * @param {Object} [props.account] - Optional account object for editing
 * @param {Function} [props.onEdit] - Optional function to call when edit button is clicked (for contacts and accounts)
 * @param {Array} [props.connectedSlackChannels] - Optional array of connected Slack channels
 * @param {boolean} [props.hasEmailActivity] - Optional flag indicating if there's email activity
 * @returns {JSX.Element}
 */
const GoBackHeader = ({
  onGoBack,
  title,
  deal,
  contact,
  account,
  onEdit,
  connectedSlackChannels = [],
  hasEmailActivity = false,
}) => {
  const navigate = useNavigate();
  const textColor = useColorModeValue("#56595E", "white");
  const buttonBg = useColorModeValue("white", "gray.700");
  const buttonHoverBg = useColorModeValue("gray.50", "gray.600");
  const buttonBorderColor = useColorModeValue("gray.300", "gray.500");
  const popoverBg = useColorModeValue("white", "gray.700");
  const popoverBorderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.100", "gray.600");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  const handleEmailClick = () => {
    if (deal && deal.id) {
      onClose();

      // Navigate to email compose view with deal info
      navigate(`/admin/deals/${deal.id}/compose-email`, {
        state: {
          dealData: deal,
          fromDeal: true,
        },
      });
    }
  };

  const handleSlackClick = () => {
    if (deal && deal.id) {
      onClose();

      // Navigate to Slack message compose view with deal info
      navigate(`/admin/deals/${deal.id}/compose-slack`, {
        state: {
          dealData: deal,
          fromDeal: true,
        },
      });
    }
  };

  const getEditButtonText = () => {
    if (contact) return "Edit Contact";
    if (account) return "Edit Account";
    return "Edit Deal";
  };

  // Check if we should show the Message button based on available communication channels
  const hasSlackChannel = connectedSlackChannels.length > 0;
  const shouldShowMessageButton = hasEmailActivity || hasSlackChannel;

  // If only one option is available, determine if we should show a popup menu or a direct button
  const showSingleActionButton =
    (hasEmailActivity && !hasSlackChannel) ||
    (!hasEmailActivity && hasSlackChannel);

  return (
    <>
      <Flex align="center" mb={4} justify="space-between">
        <Flex align="center">
          <Box
            as="button"
            onClick={onGoBack}
            borderRadius="md"
            w="32px"
            h="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={3}
            bg={buttonBg}
            color="gray.600"
            borderWidth="1px"
            borderColor={buttonBorderColor}
            _hover={{
              bg: buttonHoverBg,
            }}
            transition="all 0.2s"
          >
            <ChevronLeft size={20} />
          </Box>
          <Heading as="h1" size="md" color={textColor} fontWeight="600">
            {title}
          </Heading>
        </Flex>

        {(deal || contact || account) && (
          <HStack spacing={3}>
            {deal && (
              <Button
                leftIcon={<Icon as={Edit2} size={16} />}
                variant="outline"
                bg={buttonBg}
                borderRadius="md"
                borderColor={buttonBorderColor}
                color={textColor}
                _hover={{
                  bg: buttonHoverBg,
                }}
                onClick={contact || account ? onEdit : handleOpenEditModal}
              >
                {getEditButtonText()}
              </Button>
            )}
            {deal && shouldShowMessageButton && (
              <>
                {showSingleActionButton ? (
                  // Direct action button for single communication channel
                  <Button
                    leftIcon={
                      <Icon
                        as={hasEmailActivity ? FaEnvelope : FaSlack}
                        size={16}
                      />
                    }
                    colorScheme="blue"
                    borderRadius="md"
                    _hover={{
                      opacity: 0.9,
                    }}
                    onClick={
                      hasEmailActivity ? handleEmailClick : handleSlackClick
                    }
                  >
                    {hasEmailActivity ? "Send Email" : "Send Slack"}
                  </Button>
                ) : (
                  // Dropdown menu for multiple communication channels
                  <Popover
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    placement="bottom-end"
                  >
                    <PopoverTrigger>
                      <Button
                        leftIcon={<Icon as={FaPaperPlane} size={16} />}
                        rightIcon={<ChevronDownIcon />}
                        colorScheme="blue"
                        borderRadius="md"
                        _hover={{
                          opacity: 0.9,
                        }}
                      >
                        Message
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      bg={popoverBg}
                      borderColor={popoverBorderColor}
                      boxShadow="lg"
                      _focus={{ outline: "none" }}
                      width="200px"
                    >
                      <PopoverBody p={0}>
                        <VStack spacing={0} align="stretch">
                          {hasEmailActivity && (
                            <Box
                              px={4}
                              py={3}
                              cursor="pointer"
                              _hover={{ bg: hoverBg }}
                              onClick={handleEmailClick}
                              borderTopRadius={!hasSlackChannel ? "md" : "0"}
                              borderBottomRadius={hasSlackChannel ? "0" : "md"}
                            >
                              <Flex align="center">
                                <Icon as={FaEnvelope} color="blue.500" mr={3} />
                                <Text fontWeight="medium">Send Email</Text>
                              </Flex>
                            </Box>
                          )}
                          {hasSlackChannel && (
                            <Box
                              px={4}
                              py={3}
                              cursor="pointer"
                              _hover={{ bg: hoverBg }}
                              onClick={handleSlackClick}
                              borderTopRadius={!hasEmailActivity ? "md" : "0"}
                              borderBottomRadius="md"
                            >
                              <Flex align="center">
                                <Icon as={FaSlack} color="purple.500" mr={3} />
                                <Text fontWeight="medium">Send Slack</Text>
                              </Flex>
                            </Box>
                          )}
                        </VStack>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
              </>
            )}
            {(contact || account) && (
              <Button
                leftIcon={<Icon as={Edit2} size={16} />}
                variant="outline"
                bg={buttonBg}
                borderRadius="md"
                borderColor={buttonBorderColor}
                color={textColor}
                _hover={{
                  bg: buttonHoverBg,
                }}
                onClick={onEdit}
              >
                {getEditButtonText()}
              </Button>
            )}
          </HStack>
        )}
      </Flex>

      {deal && (
        <EditDealModal
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          deal={deal}
        />
      )}
    </>
  );
};

// Define PropTypes for type checking
GoBackHeader.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  deal: PropTypes.object,
  contact: PropTypes.object,
  account: PropTypes.object,
  onEdit: PropTypes.func,
  connectedSlackChannels: PropTypes.array,
  hasEmailActivity: PropTypes.bool,
};

export default GoBackHeader;
