import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { 
  Box, 
  useToast, 
  Alert, 
  AlertIcon, 
  Text, 
  Center
} from '@chakra-ui/react';
import { useAuthData } from 'auth-context';
import SlackMessageDetail from './SlackMessageDetail';
import PageHeader from '../Components/PageHeader';
import GoBackHeader from '../Shared/GoBackHeader';
import useFetchDealSlackChannel from '../hooks/useFetchDealSlackChannel';
import useSendSlackMessage from '../hooks/useSendSlackMessage';

const noop = () => { /* no operation */ };

const SlackMessagePage = () => {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const userData = useAuthData();
  const toast = useToast();
  const { account_ID, user_ID, getAccessToken } = userData;
  const [dealWithActivities, setDealWithActivities] = useState(null);
  
  // Initialize the send message mutation
  const sendSlackMessageMutation = useSendSlackMessage();

  // Fetch deal details
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['deal', account_ID, dealId],
    queryFn: async () => {
      const target = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';
      const response = await axios.get(
        `${target}/api/accounts/${account_ID}/deals/${dealId}`
      );
      return response.data;
    },
    enabled: !!account_ID && !!dealId,
  });
  
  // Fetch Slack channel separately
  const { channels } = useFetchDealSlackChannel(account_ID, dealId);
  
  // When data or channels are updated, update the deal with activities state
  useEffect(() => {
    if (data) {
      const updatedDeal = { ...data };
      
      // If there's a connected Slack channel, add it to the deal data
      if (channels && channels.length > 0) {
        // Extract the channel data with the name_normalized field
        const channelData = channels[0];
        const normalizedChannelData = {
          ...channelData,
          name_normalized: channelData.metadata?.name_normalized || channelData.metadata?.name || 'unknown-channel'
        };
        
        updatedDeal.slack_channel = normalizedChannelData;
      }
      
      setDealWithActivities(updatedDeal);
    }
  }, [data, channels]);

  // Handle navigation back to the deal detail page
  const handleGoBack = () => {
    // Use browser history to go back instead of direct navigation
    // This maintains proper navigation stack
    navigate(-1);
  };

  // Send the Slack message using the mutation hook
  const handleSendMessage = async (messageData) => {
    try {
      await sendSlackMessageMutation.mutateAsync({
        accountId: account_ID,
        dealId: dealId,
        userId: user_ID,
        channelId: messageData.channel_id,
        content: messageData.content,
        templateType: messageData.template_type
      });
      
      toast({
        title: 'Message Sent',
        description: 'Your Slack message has been successfully sent',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      
      return true;
    } catch (error) {
      // Failed to send message
      toast({
        title: 'Message Failed',
        description: error.response?.data?.message || 'Failed to send Slack message',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      throw error;
    }
  };

  // Generate a message using AI
  const handleGenerateMessage = async (templateType, guidingPrompt) => {
    try {
      const accessToken = await getAccessToken();
      const target = process.env.REACT_APP_API_BASE_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      
      // Using the same endpoint as email generation but with a different template type
      const response = await axios.post(
        `${target}/api/accounts/${account_ID}/deals/${dealId}/generate-content`,
        {
          template_type: templateType,
          content_type: 'slack_message',
          guiding_prompt: guidingPrompt
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      return response.data;
    } catch (error) {
      console.error('Error generating Slack message:', error);
      toast({
        title: 'Generation Failed',
        description: error.response?.data?.message || 'Failed to generate Slack message',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      throw error;
    }
  };

  // Show error if the deal can't be loaded
  if (isError || (!dealWithActivities && !isLoading)) {
    return (
      <Box px={4}>
        <PageHeader
          title="Compose Slack Message"
          showSearch={false}
          isDetailView
        />
        <Box mb={2}>
          <GoBackHeader
            onGoBack={handleGoBack}
            title="Error"
          />
        </Box>
        <Center mt={8}>
          <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <Text mt={4} mb={1} fontSize="lg">
              {isError ? "Error Loading Deal" : "Deal Not Found"}
            </Text>
            <Text fontSize="sm">
              {isError 
                ? (error?.message || 'Failed to load deal details. Please try again later.')
                : "The requested deal could not be found. It may have been deleted or you may not have permission to view it."
              }
            </Text>
          </Alert>
        </Center>
      </Box>
    );
  }

  // Get the channel name for the title
  const channelName = dealWithActivities?.slack_channel?.name_normalized || 'Unknown Channel';

  return (
    <Box px={4}>
      {/* Main Header */}
      <PageHeader
        title="Compose Slack Message"
        segmentOptions={[]}
        selectedSegment=""
        onSegmentChange={noop}
        onSearchChange={noop}
        showSearch={false}
        isDetailView
      />

      {/* Go Back Header with Deal Title and Channel Name */}
      <Box mb={2}>
        <GoBackHeader
          onGoBack={handleGoBack}
          title={`Slack Message for ${dealWithActivities?.deal_name || dealWithActivities?.name || 'Deal'} in #${channelName}`}
        />
      </Box>

      {/* Slack Message Detail Component */}
      <SlackMessageDetail
        deal={dealWithActivities}
        onSendMessage={handleSendMessage}
        onGoBack={handleGoBack}
        onGenerateMessage={handleGenerateMessage}
      />
    </Box>
  );
};

export default SlackMessagePage; 