import React from 'react';
import PropTypes from 'prop-types';
import { Box, VStack, Flex, Text, Alert, AlertIcon, Skeleton, HStack, Button, IconButton } from '@chakra-ui/react';
import axios from 'axios';
import { useAuthData } from '../../../auth-context';
import { useQueryClient } from '@tanstack/react-query'; 
import { 
  FaEnvelope, 
  FaFilter, 
  FaFlag,
  FaMapMarker,
  FaLinkedin,
  FaUsers,
  FaStar,
  FaGlobe,
} from 'react-icons/fa';
import { ExternalLink } from 'lucide-react';
import { OverviewTag } from '../Shared/Tags';
import BaseTag from '../Shared/BaseTag';
import AccountContactsSection from './AccountContactsSection';
import AccountDealsSection from './AccountDealsSection';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ActivityItem } from '../Activities';

const AccountDetail = ({ account }) => {
  const { getAccessToken, account_ID: userAccountID } = useAuthData();
  const queryClient = useQueryClient();
  const [dealActivities, setDealActivities] = React.useState([]);
  const [loadingActivities, setLoadingActivities] = React.useState(false);
  const [errorActivities, setErrorActivities] = React.useState(null);
  const ITEMS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    const fetchDealActivities = async () => {
      if (!account.deals || account.deals.length === 0) return;
      setLoadingActivities(true);
      setErrorActivities(null);
      
      const startDate = '2022-01-01';
      const endDate = new Date().toISOString().split('T')[0];
      
      try {
        const accessToken = await getAccessToken();
        const target = process.env.REACT_APP_API_BASE_URL || 
                      "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
                      
        // Create a mapping of deal IDs to deal names for future reference
        const dealNameMap = new Map();
        account.deals.forEach(deal => {
          const dealId = deal.id;
          const dealName = deal.deal_name || deal.name || `Deal ${dealId}`;
          dealNameMap.set(dealId, dealName);
        });
        
        const activitiesPromises = account.deals.map(async (deal) => {
          const dealId = deal.id;
          // Use deal name from the mapping
          const dealName = dealNameMap.get(dealId);
          const queryCacheKey = ["dealActivities", userAccountID, dealId, startDate, endDate];
          
          const cachedData = queryClient.getQueryData(queryCacheKey);
          if (cachedData) {
            return cachedData.map(activity => ({
              ...activity,
              account_id: userAccountID,
              deal_id: dealId,
              deal_name: dealName
            }));
          }
          
          try {
            const response = await axios.get(
              `${target}/api/accounts/${userAccountID}/deals/${dealId}/activities`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
                params: {
                  start_date: startDate,
                  end_date: endDate,
                },
              }
            );
            
            const processedActivities = (response.data || [])
              .filter((activity) => {
                if (activity.activity_type?.toLowerCase() === "email") {
                  const emailBody = activity.activity_data?.data?.body;
                  return emailBody && emailBody.trim() !== "";
                }
                return true;
              })
              .map((activity) => {
                const normalizedType = activity.activity_type
                  ? activity.activity_type.charAt(0).toUpperCase() +
                    activity.activity_type.slice(1).toLowerCase()
                  : "Unknown";

                return {
                  ...activity,
                  account_id: userAccountID,
                  deal_id: dealId,
                  deal_name: dealName,
                  activity_type: normalizedType,
                  owner_id: activity.owner_id || activity.user_id,
                  created_by_id: activity.created_by_id || activity.user_id,
                  is_internal: activity.is_internal || false,
                  // Add defaults for required fields
                  status: activity.status || "completed",
                  timestamp: activity.timestamp || activity.created_at || activity.activity_date,
                };
              });
            
            queryClient.setQueryData(queryCacheKey, processedActivities);
            
            return processedActivities;
          } catch (dealError) {
            return [];
          }
        });
        
        try {
          const results = await Promise.all(activitiesPromises);
          
          let allActivities = [];
          results.forEach((actList) => {
            if (Array.isArray(actList)) {
              allActivities = allActivities.concat(actList);
            }
          });
          
          // Deduplicate activities with the same content and merge deal information
          const activityContentMap = new Map();
          const titleTimeMap = new Map(); // Add a secondary map for title+time deduplication
          
          allActivities.forEach(activity => {
            // Create a unique content key for deduplication
            // For emails, use the body content, for meetings, use title and date
            let contentKey;
            
            if (activity.activity_type === 'Email' && activity.activity_data?.data?.body) {
              // For emails, use the body as content key
              contentKey = `${activity.activity_type}-${activity.activity_data.data.body.substring(0, 100)}`;
            } else if (activity.activity_type === 'Meeting') {
              // For meetings, use title and date as content key
              contentKey = `${activity.activity_type}-${activity.title}-${activity.activity_date}`;
            } else {
              // For other activity types, use title and description
              contentKey = `${activity.activity_type}-${activity.title}-${activity.description?.substring(0, 50) || ''}`;
            }
            
            // Fallback: if we couldn't create a content key, use ID
            if (!contentKey || contentKey === `${activity.activity_type}--`) {
              contentKey = `id-${activity.id}`;
            }
            
            // Create title+time based key for secondary deduplication
            // This will help catch activities with the same title and timestamp
            // that might have minor differences in content
            const titleTimeKey = `${activity.activity_type}-${activity.title}-${activity.activity_date}-${activity.completed_date_time || ''}`;
            
            // Check if we already have an activity with this title+time combination
            if (titleTimeMap.has(titleTimeKey) && titleTimeKey !== `${activity.activity_type}---`) {
              const existingActivityId = titleTimeMap.get(titleTimeKey);
              const existingActivity = activityContentMap.get(existingActivityId);
              
              if (existingActivity) {
                // If the existing activity and this one are from different deals, merge them
                if (activity.deal_id && existingActivity.deal_id && activity.deal_id !== existingActivity.deal_id) {
                  // Set up associated deals if not already done
                  if (!existingActivity.associated_deals) {
                    // Normalize deal ID to number
                    const dealId = typeof existingActivity.deal_id === 'string' ? 
                      parseInt(existingActivity.deal_id, 10) : existingActivity.deal_id;
                      
                    existingActivity.associated_deals = [{
                      deal_id: dealId,
                      deal_name: existingActivity.deal_name || dealNameMap.get(dealId) || `Deal ${dealId}`
                    }];
                  }
                  
                  // Add the new deal to associated deals if not already included
                  // Normalize deal ID to number
                  const dealId = typeof activity.deal_id === 'string' ? 
                    parseInt(activity.deal_id, 10) : activity.deal_id;
                    
                  const alreadyIncludesDeal = existingActivity.associated_deals.some(
                    deal => deal.deal_id === dealId
                  );
                  
                  if (!alreadyIncludesDeal) {
                    existingActivity.associated_deals.push({
                      deal_id: dealId,
                      deal_name: activity.deal_name || dealNameMap.get(dealId) || `Deal ${dealId}`
                    });
                  }
                }
                
                // Skip adding this activity as a new one since we've merged it
                return;
              }
            }
            
            // Check if we already have an activity with this content
            if (activityContentMap.has(contentKey)) {
              const existingActivity = activityContentMap.get(contentKey);
              
              // If this activity is from a different deal than what we've seen,
              // add this deal to the list of associated deals
              if (activity.deal_id && existingActivity.deal_id && activity.deal_id !== existingActivity.deal_id) {
                if (!existingActivity.associated_deals) {
                  // Normalize deal ID to number
                  const dealId = typeof existingActivity.deal_id === 'string' ? 
                    parseInt(existingActivity.deal_id, 10) : existingActivity.deal_id;
                    
                  existingActivity.associated_deals = [{
                    deal_id: dealId,
                    deal_name: existingActivity.deal_name || dealNameMap.get(dealId) || `Deal ${dealId}`
                  }];
                }
                
                // Add this deal to associated deals if not already included
                // Normalize deal ID to number
                const dealId = typeof activity.deal_id === 'string' ? 
                  parseInt(activity.deal_id, 10) : activity.deal_id;
                  
                const alreadyIncludesDeal = existingActivity.associated_deals.some(
                  deal => deal.deal_id === dealId
                );
                
                if (!alreadyIncludesDeal) {
                  existingActivity.associated_deals.push({
                    deal_id: dealId,
                    deal_name: activity.deal_name || dealNameMap.get(dealId) || `Deal ${dealId}`
                  });
                }
              }
            } else {
              // This is a new unique activity content
              activityContentMap.set(contentKey, activity);
              // Store reference in the title+time map if it's a valid key
              if (titleTimeKey !== `${activity.activity_type}---`) {
                titleTimeMap.set(titleTimeKey, contentKey);
              }
            }
          });
          
          // After content-based deduplication, deduplicate by ID to avoid rendering duplicates
          const finalActivityMap = new Map();
          
          for (const activity of activityContentMap.values()) {
            if (!finalActivityMap.has(activity.id)) {
              finalActivityMap.set(activity.id, activity);
            } else {
              // If there's a duplicate ID but we reached here, they had different content
              // Merge the two to preserve all information
              const existingActivity = finalActivityMap.get(activity.id);
              
              // Merge associated_deals
              if (activity.associated_deals && !existingActivity.associated_deals) {
                existingActivity.associated_deals = [...activity.associated_deals];
              } else if (activity.associated_deals && existingActivity.associated_deals) {
                // Combine deals, avoiding duplicates
                for (const deal of activity.associated_deals) {
                  const alreadyExists = existingActivity.associated_deals.some(
                    existingDeal => existingDeal.deal_id === deal.deal_id
                  );
                  
                  if (!alreadyExists) {
                    existingActivity.associated_deals.push(deal);
                  }
                }
              }
              
              // Add deal_name and deal_id if the existing one doesn't have it
              if (activity.deal_name && !existingActivity.deal_name) {
                existingActivity.deal_name = activity.deal_name;
                existingActivity.deal_id = activity.deal_id;
              }
            }
          }
          
          setDealActivities(Array.from(finalActivityMap.values()));
        } catch (promiseError) {
          setErrorActivities(`Error processing activities: ${promiseError.message}`);
        }
      } catch (error) {
        setErrorActivities(`Error fetching deal activities: ${error.message || 'Unknown error'}`);
      } finally {
        setLoadingActivities(false);
      }
    };

    fetchDealActivities();
  }, [account.deals, userAccountID, getAccessToken, queryClient]);

  const combinedActivities = React.useMemo(() => {
    // Create mapping of deal IDs to names for lookup
    const dealNameMap = new Map();
    if (account.deals && account.deals.length > 0) {
      account.deals.forEach(deal => {
        dealNameMap.set(deal.id, deal.deal_name || deal.name || `Deal ${deal.id}`);
      });
    }
    
    // Map account activities and ensure they have account_id
    const accountActs = (account.activities || []).map(activity => {
      // Check if this activity has a deal_id but no deal_name, add name from mapping
      let dealName = activity.deal_name;
      if (activity.deal_id && !dealName && dealNameMap.has(activity.deal_id)) {
        dealName = dealNameMap.get(activity.deal_id);
      }
      
      // Normalize account activities to have the same structure as deal activities
      // This helps ensure consistent rendering and handling in ActivityItem
      const normalizedType = activity.activity_type
        ? activity.activity_type.charAt(0).toUpperCase() +
          activity.activity_type.slice(1).toLowerCase()
        : "Unknown";
        
      return {
        ...activity,
        // Ensure account_id is a number
        account_id: typeof activity.account_id === 'string' ? parseInt(activity.account_id, 10) : (activity.account_id || userAccountID),
        activity_type: normalizedType,
        // Add required fields that might be missing
        deal_id: activity.deal_id || null,
        deal_name: dealName || null,
        status: activity.status || "completed", // Default status for backward compatibility
        timestamp: activity.timestamp || activity.created_at || activity.activity_date,
        // Add other fields that might be needed for consistency
        owner_id: activity.owner_id || activity.user_id || null,
        created_by_id: activity.created_by_id || activity.user_id || null,
        is_internal: activity.is_internal || false,
        // Ensure activity_data has consistent structure
        activity_data: activity.activity_data ? {
          ...activity.activity_data,
          // Normalize activity_data.activity_type to match expectations
          activity_type: activity.activity_type?.toLowerCase() === 'meeting' ? 'email' : (activity.activity_data.activity_type || 'email')
        } : { activity_type: 'email' }
      };
    });
    
    // Process deal activities to ensure they have consistent structure
    const processedDealActivities = dealActivities.map(activity => {
      // Ensure account_id is a number
      const accountId = typeof activity.account_id === 'string' 
        ? parseInt(activity.account_id, 10) 
        : (activity.account_id || userAccountID);
        
      // Check if this activity has a deal_id but no deal_name, add name from mapping
      let dealName = activity.deal_name;
      if (activity.deal_id && !dealName && dealNameMap.has(activity.deal_id)) {
        dealName = dealNameMap.get(activity.deal_id);
      }
        
      return {
      ...activity,
        account_id: accountId,
        deal_name: dealName || activity.deal_name,
        // Add required fields that might be missing
        status: activity.status || "completed", // Default status for backward compatibility
        timestamp: activity.timestamp || activity.created_at || activity.activity_date,
        // Ensure activity_data has consistent structure
        activity_data: activity.activity_data ? {
          ...activity.activity_data,
          // Normalize activity_data.activity_type to match expectations
          activity_type: activity.activity_type?.toLowerCase() === 'meeting' ? 'email' : (activity.activity_data.activity_type || 'email')
        } : { activity_type: 'email' }
      };
    });
    
    // Create a Map to deduplicate activities by ID
    const activityMap = new Map();
    const titleTimeMap = new Map(); // For title+time based deduplication
    
    // Process all activities and deduplicate by ID
    [...accountActs, ...processedDealActivities].forEach(activity => {
      const activityId = activity.id;
      
      // Ensure deal_id is a number if present
      if (activity.deal_id && typeof activity.deal_id === 'string') {
        activity.deal_id = parseInt(activity.deal_id, 10);
      }
      
      // Create title+time based key for secondary deduplication
      const titleTimeKey = `${activity.activity_type}-${activity.title}-${activity.activity_date}-${activity.completed_date_time || ''}`;
      
      // Check if we already have an activity with this title+time combination
      if (titleTimeMap.has(titleTimeKey) && titleTimeKey !== `${activity.activity_type}---`) {
        // Skip adding this activity as a new one since we've found a duplicate
        // But merge deal information
        if (activityMap.has(activityId)) {
          const existingActivity = activityMap.get(activityId);
          
          // If the existing activity and this one are from different deals, merge them
          if (activity.deal_id && existingActivity.deal_id && activity.deal_id !== existingActivity.deal_id) {
            // Set up associated deals if not already done
            if (!existingActivity.associated_deals) {
              // Normalize deal ID to number
              const dealId = typeof existingActivity.deal_id === 'string' ? 
                parseInt(existingActivity.deal_id, 10) : existingActivity.deal_id;
                
              existingActivity.associated_deals = [{
                deal_id: dealId,
                deal_name: existingActivity.deal_name || dealNameMap.get(dealId) || `Deal ${dealId}`
              }];
            }
            
            // Add the new deal to associated deals if not already included
            // Normalize deal ID to number
            const dealId = typeof activity.deal_id === 'string' ? 
              parseInt(activity.deal_id, 10) : activity.deal_id;
              
            const alreadyIncludesDeal = existingActivity.associated_deals.some(
              deal => deal.deal_id === dealId
            );
            
            if (!alreadyIncludesDeal) {
              existingActivity.associated_deals.push({
                deal_id: dealId,
                deal_name: activity.deal_name || dealNameMap.get(dealId) || `Deal ${dealId}`
              });
            }
          }
          
          // Merge associated_deals from both activities
          if (activity.associated_deals && existingActivity) {
            if (!existingActivity.associated_deals) {
              existingActivity.associated_deals = [...activity.associated_deals];
            } else {
              // Add any deals from activity.associated_deals that aren't already in existingActivity.associated_deals
              activity.associated_deals.forEach(deal => {
                const alreadyExists = existingActivity.associated_deals.some(
                  existingDeal => existingDeal.deal_id === deal.deal_id
                );
                
                if (!alreadyExists) {
                  existingActivity.associated_deals.push(deal);
                }
              });
            }
          }
          
          return; // Skip adding this activity
        }
      }
      
      // If this activity ID is already in the map, merge deal information
      if (activityMap.has(activityId)) {
        const existingActivity = activityMap.get(activityId);
        
        // If activity has deal info and the existing one doesn't, add it
        if (activity.deal_id && activity.deal_name && (!existingActivity.deal_id || !existingActivity.deal_name)) {
          existingActivity.deal_id = activity.deal_id;
          existingActivity.deal_name = activity.deal_name;
        }
        
        // If both have different deal IDs, set up associated_deals
        if (activity.deal_id && existingActivity.deal_id && activity.deal_id !== existingActivity.deal_id) {
          if (!existingActivity.associated_deals) {
            // Normalize deal ID to number
            const dealId = typeof existingActivity.deal_id === 'string' ? 
              parseInt(existingActivity.deal_id, 10) : existingActivity.deal_id;
              
            existingActivity.associated_deals = [{
              deal_id: dealId,
              deal_name: existingActivity.deal_name || dealNameMap.get(dealId) || `Deal ${dealId}`
            }];
          }
          
          // Add this deal to associated deals if not already included
          // Normalize deal ID to number
          const dealId = typeof activity.deal_id === 'string' ? 
            parseInt(activity.deal_id, 10) : activity.deal_id;
            
            const alreadyIncludesDeal = existingActivity.associated_deals.some(
              deal => deal.deal_id === dealId
            );
            
            if (!alreadyIncludesDeal) {
              existingActivity.associated_deals.push({
                deal_id: dealId,
                deal_name: activity.deal_name || dealNameMap.get(dealId) || `Deal ${dealId}`
              });
            }
        }
        
        // Merge associated_deals
        if (activity.associated_deals && !existingActivity.associated_deals) {
          existingActivity.associated_deals = [...activity.associated_deals];
        } else if (activity.associated_deals && existingActivity.associated_deals) {
          // Combine deals, avoiding duplicates
          for (const deal of activity.associated_deals) {
            const alreadyExists = existingActivity.associated_deals.some(
              existingDeal => existingDeal.deal_id === deal.deal_id
            );
            
            if (!alreadyExists) {
              existingActivity.associated_deals.push(deal);
            }
          }
        }
      } else {
        // This is a new activity, add it to the map
        activityMap.set(activityId, activity);
        // Store ID reference in the title+time map if it's a valid key
        if (titleTimeKey !== `${activity.activity_type}---`) {
          titleTimeMap.set(titleTimeKey, activityId);
        }
      }
    });
    
    // Convert map back to array and sort
    const allUniqueActivities = Array.from(activityMap.values());
    
    // Sort activities by date (newest first)
    const sorted = allUniqueActivities.sort((a, b) => {
      const dateA = new Date(a.activity_date);
      const dateB = new Date(b.activity_date);
      if (dateA.getTime() !== dateB.getTime()) {
        return dateB - dateA;
      }
      const completedA = a.completed_date_time ? new Date(a.completed_date_time) : null;
      const completedB = b.completed_date_time ? new Date(b.completed_date_time) : null;
      if (!completedA && !completedB) return 0;
      if (!completedA) return 1;
      if (!completedB) return -1;
      return completedB - completedA;
    });
    
    return sorted;
  }, [account.activities, account.deals, userAccountID, dealActivities]);

  // Calculate total pages
  const totalPages = React.useMemo(() => {
    return Math.ceil(combinedActivities.length / ITEMS_PER_PAGE) || 1;
  }, [combinedActivities.length]);

  // Ensure currentPage is valid when activities or total pages change
  React.useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage]);

  // Get current page's activities
  const currentActivities = React.useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const paginatedActivities = combinedActivities.slice(startIndex, endIndex);
    return paginatedActivities;
  }, [combinedActivities, currentPage, totalPages]);

  // Check if ANY activities have multiple deals, not just the current page
  const hasMultipleDeals = React.useMemo(() => {
    // Get all unique deal IDs from ALL activities, not just the current page
    const dealIds = new Set();
    
    // Loop through ALL activities
    combinedActivities.forEach(activity => {
      // Add primary deal ID if it exists
      if (activity.deal_id) {
        dealIds.add(typeof activity.deal_id === 'string' ? parseInt(activity.deal_id, 10) : activity.deal_id);
      }
      
      // Add any associated deal IDs
      if (activity.associated_deals && activity.associated_deals.length > 0) {
        activity.associated_deals.forEach(deal => {
          if (deal.deal_id) {
            dealIds.add(typeof deal.deal_id === 'string' ? parseInt(deal.deal_id, 10) : deal.deal_id);
          }
        });
      }
    });
    
    // We have multiple deals if there's more than one unique deal ID
    return dealIds.size > 1;
  }, [combinedActivities]);

  // Pagination handlers - match DealActivity implementation
  const handlePreviousPage = () => {
    setCurrentPage(prev => {
      const newPage = Math.max(1, prev - 1);
      return newPage;
    });
  };

  const handleNextPage = () => {
    setCurrentPage(prev => {
      const newPage = Math.min(totalPages, prev + 1);
      return newPage;
    });
  };

  const handlePageSelect = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const SimplePaginationControls = ({ currentPage, totalPages, onPrevious, onNext, onPageSelect }) => {
    // Helper function to generate page numbers to display
    const getPageNumbers = () => {
      const pageNumbers = [];
      const maxPagesToShow = 5;
      
      if (totalPages <= maxPagesToShow) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        
        let start = Math.max(2, currentPage - 1);
        let end = Math.min(totalPages - 1, currentPage + 1);
        
        if (start > 2) {
          pageNumbers.push('...');
        }
        
        for (let i = start; i <= end; i++) {
          pageNumbers.push(i);
        }
        
        if (end < totalPages - 1) {
          pageNumbers.push('...');
        }
        
        pageNumbers.push(totalPages);
      }
      
      return pageNumbers;
    };

    return (
      <Box width="100%" py={4}>
        <HStack spacing={1} justify="center" width="300px" margin="0 auto">
          <Box width="40px" textAlign="center">
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={onPrevious}
              isDisabled={currentPage === 1}
              aria-label="Previous page"
              size="sm"
            />
          </Box>
          
          <Box flex="1" textAlign="center">
            <HStack spacing={1} justify="center">
              {getPageNumbers().map((pageNum, index) => (
                pageNum === '...' ? (
                  <Text key={`ellipsis-${index}`} color="gray.500" px={2} minWidth="32px">
                    {pageNum}
                  </Text>
                ) : (
                  <Button
                    key={pageNum}
                    onClick={() => onPageSelect(pageNum)}
                    variant={currentPage === pageNum ? 'solid' : 'ghost'}
                    colorScheme={currentPage === pageNum ? 'blue' : 'gray'}
                    size="sm"
                    minWidth="32px"
                  >
                    {pageNum}
                  </Button>
                )
              ))}
            </HStack>
          </Box>

          <Box width="40px" textAlign="center">
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={onNext}
              isDisabled={currentPage === totalPages}
              aria-label="Next page"
              size="sm"
            />
          </Box>
        </HStack>
      </Box>
    );
  };

  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      <VStack spacing={6} align="stretch" height="100%">
        {/* Combined Overview, Contacts, and Deals Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            {/* Overview Section */}
            <Box>
              <Text fontSize="md" fontWeight="semibold" mb={3}>
                Overview
              </Text>
              <Flex gap={3} flexWrap="wrap">
                {account.website && (
                  <BaseTag
                    icon={FaGlobe}
                    hoverIcon={ExternalLink}
                    colorScheme="blue"
                    tooltip="Open Website"
                    onClick={() => {
                      const websiteUrl = account.website?.includes('http') 
                        ? account.website 
                        : `http://${account.website}`;
                      window.open(websiteUrl, '_blank');
                    }}
                  >
                    <Text fontSize="sm" fontWeight="medium">
                      Website
                    </Text>
                  </BaseTag>
                )}
                {account.email && (
                  <OverviewTag
                    icon={FaEnvelope}
                    label="Email"
                    value={account.email}
                    colorScheme="green"
                    account={account}
                    fieldToFocus="email"
                  />
                )}
                {account.linkedIn && (
                  <a
                    href={account.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <BaseTag
                      icon={FaLinkedin}
                      hoverIcon={ExternalLink}
                      colorScheme="linkedin"
                      tooltip="Open LinkedIn Profile"
                    >
                      <Text fontSize="sm" fontWeight="medium">
                        LinkedIn
                      </Text>
                    </BaseTag>
                  </a>
                )}
                {account.address && (
                  <OverviewTag
                    icon={FaMapMarker}
                    label="Address"
                    value={account.address}
                    colorScheme="yellow"
                    account={account}
                    fieldToFocus="address"
                  />
                )}
                {account.city && (
                  <OverviewTag
                    icon={FaMapMarker}
                    label="City"
                    value={account.city}
                    colorScheme="purple"
                    account={account}
                    fieldToFocus="city"
                  />
                )}
                {account.state && (
                  <OverviewTag
                    icon={FaMapMarker}
                    label="State"
                    value={account.state}
                    colorScheme="orange"
                    account={account}
                    fieldToFocus="state"
                  />
                )}
                {account.country && (
                  <OverviewTag
                    icon={FaFlag}
                    label="Country"
                    value={account.country}
                    colorScheme="teal"
                    account={account}
                    fieldToFocus="country"
                  />
                )}
                {account.stage && (
                  <OverviewTag
                    icon={FaStar}
                    label="Stage"
                    value={account.stage}
                    colorScheme="red"
                    account={account}
                    fieldToFocus="stage"
                  />
                )}
                {account.type && (
                  <OverviewTag
                    icon={FaFilter}
                    label="Type"
                    value={account.type}
                    colorScheme="red"
                    account={account}
                    fieldToFocus="type"
                  />
                )}
                {account.size && (
                  <OverviewTag
                    icon={FaUsers}
                    label="Company Size"
                    value={account.size.toString()}
                    colorScheme="cyan"
                    account={account}
                    fieldToFocus="size"
                  />
                )}
              </Flex>
            </Box>

            {/* Contacts Section */}
            <AccountContactsSection contacts={account.contacts || []} />

            {/* Deals Section */}
            <AccountDealsSection deals={account.deals || []} />
          </VStack>
        </Box>

        {/* Activity Section with aggregated account and deal activities */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <Text fontSize="md" fontWeight="bold" mb={4}>Account Activities</Text>
          
          {loadingActivities ? (
            <VStack spacing={4} align="stretch">
              {[...Array(3)].map((_, i) => (
                <Skeleton key={i} height="100px" borderRadius="md" />
              ))}
            </VStack>
          ) : errorActivities ? (
            <Alert status="error">
              <AlertIcon />
              {errorActivities}
            </Alert>
          ) : combinedActivities.length === 0 ? (
            <Text color="gray.500" textAlign="center" py={8}>
              No activities found.
            </Text>
          ) : (
            <>
              <VStack spacing={4} align="stretch" mb={4}>
                {currentActivities.map((activity) => (
                  <ActivityItem 
                    key={activity.id} 
                    activity={activity}
                    showDealLabels={hasMultipleDeals}
                  />
                ))}
              </VStack>
              
              {totalPages > 1 && (
                <SimplePaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPrevious={handlePreviousPage}
                  onNext={handleNextPage}
                  onPageSelect={handlePageSelect}
                />
              )}
            </>
          )}
        </Box>
      </VStack>
    </Box>
  );
};

AccountDetail.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    website: PropTypes.string,
    email: PropTypes.string,
    linkedIn: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    stage: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    contacts: PropTypes.array,
    deals: PropTypes.array,
    activities: PropTypes.array,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default AccountDetail; 