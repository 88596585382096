import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { queryClient } from "./queryClient";
import { useAuthData } from "../../../auth-context";
import { parseISO, format } from "date-fns";

/**
 * Hook to fetch metrics for a specific deal
 * @param {number} accountId - The account ID
 * @param {number} dealId - The deal ID
 * @param {object} options - Configuration options
 * @param {string} options.startDate - Start date in ISO format (optional)
 * @param {string} options.endDate - End date in ISO format (optional)
 * @param {string} options.metricKey - Filter by specific metric (optional)
 * @param {string} options.aggregation - Time aggregation (day, week, month) (optional)
 * @param {string} options.timezone - Timezone for aggregation (optional)
 * @param {boolean} options.summary - Request summary data (optional, defaults to true)
 * @param {boolean} options.unique - Request unique count metrics (optional)
 * @param {boolean} options.debug - Enable debug logging (optional)
 * @returns {object} Query result containing metrics data
 */
const useFetchDealMetrics = (accountId, dealId, options = {}) => {
  const toast = useToast();
  const [queryParams, setQueryParams] = useState(options);
  const { getAccessToken } = useAuthData();

  // Helper function to format date as YYYY-MM-DD
  const formatDateForAPI = (dateString) => {
    if (!dateString) return undefined;

    try {
      // Convert ISO date to YYYY-MM-DD format
      const parsedDate =
        typeof dateString === "string"
          ? parseISO(dateString)
          : new Date(dateString);

      // For the current date, ensure we're getting today's date in local timezone
      if (dateString === new Date().toISOString()) {
        return format(new Date(), "yyyy-MM-dd");
      }

      return format(parsedDate, "yyyy-MM-dd");
    } catch (error) {
      return undefined;
    }
  };

  const fetchDealMetrics = async () => {
    if (!accountId || !dealId) {
      throw new Error("Account ID and Deal ID are required");
    }

    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const params = new URLSearchParams();

      // Format dates as YYYY-MM-DD as required by the API
      const formattedStartDate = formatDateForAPI(queryParams.startDate);
      const formattedEndDate = formatDateForAPI(queryParams.endDate);

      if (formattedStartDate) params.append("start_date", formattedStartDate);
      if (formattedEndDate) params.append("end_date", formattedEndDate);
      if (queryParams.metricKey)
        params.append("metric_key", queryParams.metricKey);
      if (queryParams.aggregation)
        params.append("aggregation", queryParams.aggregation);
      if (queryParams.timezone) params.append("timezone", queryParams.timezone);

      // Add view_type parameter for formatting, defaulting to 'sum'
      params.append("view_type", queryParams.viewType || "sum");

      // Always request summary data to get metric totals unless explicitly set to false
      if (queryParams.summary !== false) {
        params.append("summary", "true");
      }

      // Add unique parameter if requested
      if (queryParams.unique) {
        params.append("unique", "true");
      }

      // Add debug parameter if enabled
      if (queryParams.debug) {
        params.append("debug", "true");
      }

      // Log the actual URL being called for debugging purposes
      const requestUrl = `${target}/api/accounts/${accountId}/deals/${dealId}/metrics${
        params.toString() ? `?${params.toString()}` : ""
      }`;

      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // If we got a response with null metric_series, log a warning but don't fail
      if (response.data && response.data.metric_series === null) {
        // If summaries are also missing, add an empty array
        if (!response.data.summaries) {
          response.data.summaries = [];
        }
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching deal metrics:", error);

      // Enhanced error reporting - extract the specific message from the server
      let errorMessage = error.message || "Unknown error";
      if (error.response) {
        // Server responded with an error status
        const serverErrorMessage =
          error.response.data?.error ||
          error.response.data?.message ||
          error.response.statusText;
        errorMessage = `Server returned ${error.response.status}: ${serverErrorMessage}`;

        if (error.response.status === 404) {
          errorMessage =
            "Deal metrics endpoint not found. The API might not be implemented yet.";
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          errorMessage = "Authentication error. Please log in again.";
        } else if (
          error.response.status === 400 &&
          serverErrorMessage.includes("date format")
        ) {
          errorMessage = serverErrorMessage;
        }
      } else if (error.request) {
        // Request was made but no response received
        errorMessage =
          "No response received from server. Please check your connection.";
      }

      toast({
        title: "Error fetching deal metrics",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      throw error;
    }
  };

  // Updated to use @tanstack/react-query v5 syntax with retry logic
  const queryResult = useQuery({
    queryKey: ["dealMetrics", accountId, dealId, queryParams],
    queryFn: fetchDealMetrics,
    enabled: !!accountId && !!dealId,
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      // Don't retry on 404 errors (endpoint might not exist)
      if (error.response?.status === 404) return false;
      // Don't retry on 400 errors with invalid date format
      if (
        error.response?.status === 400 &&
        (error.response.data?.error?.includes("date format") ||
          error.response.data?.message?.includes("date format"))
      ) {
        return false;
      }
      // Retry other errors up to 2 times
      return failureCount < 2;
    },
  });

  // Function to update query parameters
  const updateQueryParams = (newParams) => {
    setQueryParams((prev) => ({ ...prev, ...newParams }));
  };

  // Function to manually refetch metrics data
  const refetchMetrics = () => {
    return queryResult.refetch();
  };

  // Function to invalidate cache
  const invalidateMetricsCache = () => {
    queryClient.invalidateQueries({
      queryKey: ["dealMetrics", accountId, dealId],
    });
  };

  return {
    ...queryResult,
    updateQueryParams,
    refetchMetrics,
    invalidateMetricsCache,
  };
};

export default useFetchDealMetrics;
