import { MdEmail, MdPhone, MdEvent, MdTask, MdRestaurant } from "react-icons/md";
import { FaSlack } from "react-icons/fa";

/**
 * Helper function to format dates in "Friday Oct. 11, 2024" format
 */
export const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);

  const weekday = date.toLocaleDateString(undefined, { weekday: "long" });
  const month = date.toLocaleDateString(undefined, { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();

  const monthWithPeriod = month.endsWith(".") ? month : `${month}.`;

  return `${weekday} ${monthWithPeriod} ${day}, ${year}`;
};

export const formatTime = (dateString) => {
  if (!dateString) return "N/A";
  
  // Create a formatter that will use the user's local timezone
  const formatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short'
  });

  // Parse the date normally to use local timezone
  const date = new Date(dateString);
  return formatter.format(date);
};

/**
 * Determine activity category and icon
 */
export const getActivityConfig = (activity) => {
  const type = activity.activity_type?.toLowerCase() || "";

  switch (type) {
    case "email":
      return {
        icon: MdEmail,
        category: "email",
        color: "blue.500",
      };
    case "meeting":
      return {
        icon: MdEvent,
        category: "meeting",
        color: "purple.500",
      };
    case "call":
      return {
        icon: MdPhone,
        category: "call",
        color: "green.500",
      };
    case "booking":
      return {
        icon: MdRestaurant,
        category: "booking",
        color: "red.500",
      };
    case "slack digest":
      return {
        icon: FaSlack,
        category: "slack digest",
        color: "teal.500",
      };
    case "task":
    default:
      return {
        icon: MdTask,
        category: "task",
        color: "orange.500",
      };
  }
};