import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

const PAGE_SIZE = 50; // Number of items per page

/**
 * Custom hook to fetch all pipeline data for account ID 7 with pagination
 * @returns {Object} Query result with data, loading state, and error information
 */
const usePipelineOverviewData = () => {
  const { getAccessToken } = useAuthData();

  return useQuery({
    queryKey: ["pipelineOverview", 7],
    queryFn: async () => {
      try {
        const accessToken = await getAccessToken();
        const target = process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        let allDeals = [];
        let currentPage = 1;
        let hasMore = true;

        // Fetch all pages until no more data
        while (hasMore) {
          const response = await axios.get(
            `${target}/api/accounts/7/deals?page=${currentPage}&page_size=${PAGE_SIZE}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (!response.data || !Array.isArray(response.data.items)) {
            console.error('Invalid response data:', response.data);
            break;
          }

          const { items, has_more, total_count } = response.data;
          allDeals = [...allDeals, ...items];
          hasMore = has_more;
          currentPage++;

          // If we've fetched all items, break the loop
          if (allDeals.length >= total_count) {
            break;
          }
        }

        return allDeals;
      } catch (error) {
        console.error("Error fetching pipeline data:", error);
        throw error;
      }
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

/**
 * Process the deals data into the format needed for the charts
 * @param {Array} deals - Array of deal objects from the API
 * @returns {Object} Processed data for the current pipeline and conversion rates charts
 */
export const processDealsData = (deals) => {
  if (!deals || !Array.isArray(deals) || deals.length === 0) {
    return {
      currentPipeline: [],
      conversionRates: [],
    };
  }

  // Count deals by stage for the current pipeline graph
  const stageCounts = deals.reduce((acc, deal) => {
    const stageName = deal.stage_name || "Unknown";
    if (!acc[stageName]) {
      acc[stageName] = 0;
    }
    acc[stageName]++;
    return acc;
  }, {});

  // Dynamically extract all unique stage names from the deals data
  const allStages = [
    ...new Set(deals.map((deal) => deal.stage_name || "Unknown")),
  ];

  // Define stage categories and their priority
  const stagePriority = {
    Interest: 1,
    Preparation: 2,
    Qualification: 3,
    "Product Innovation": 4,
    "Closed Won": 998, // Put at the end, but before Closed Lost
    "Closed Lost": 999, // Always at the end
    Unknown: 1000, // After everything else
  };

  // Sort stages by priority (or alphabetically if priority not defined)
  const stageOrder = allStages.sort((a, b) => {
    const priorityA = stagePriority[a] || 500; // Default priority for unknown stages
    const priorityB = stagePriority[b] || 500;

    if (priorityA === priorityB) {
      return a.localeCompare(b); // Alphabetical sort for same priority
    }

    return priorityA - priorityB;
  });

  // Create current pipeline data for the bar chart
  const currentPipeline = stageOrder
    .filter((stage) => stageCounts[stage]) // Only include stages that have deals
    .map((stageName) => ({
      stageName,
      count: stageCounts[stageName] || 0,
    }));

  // Calculate conversion rates between stages
  // For this calculation, deals in later stages are counted as having passed through earlier stages

  // Count the total number of deals for baseline
  const totalDeals = deals.length || 1; // Avoid division by zero

  // Get the progressive stages (exclude Closed Won/Lost)
  const progressionStages = stageOrder.filter(
    (stage) => !["Closed Won", "Closed Lost", "Unknown"].includes(stage)
  );

  // Calculate the stage weights (higher stage = higher weight)
  const stageWeights = {};
  progressionStages.forEach((stage, index) => {
    stageWeights[stage] = index + 1;
  });

  // Count deals at each stage of the funnel (including deals from later stages)
  const funnelCounts = {};

  // Initialize all progression stages with zero counts
  progressionStages.forEach((stage) => {
    funnelCounts[stage] = 0;
  });

  // Count deals for each stage in the funnel
  deals.forEach((deal) => {
    const dealStage = deal.stage_name || "Unknown";
    const dealStageWeight = stageWeights[dealStage] || 0;

    // For each progression stage, check if this deal is at or past that stage
    progressionStages.forEach((stage) => {
      const stageWeight = stageWeights[stage];

      // If the deal is at this stage or a later stage, count it for this stage
      if (dealStageWeight >= stageWeight || dealStage === stage) {
        funnelCounts[stage]++;
      }
    });
  });

  // Create conversion rates data
  const conversionRates = progressionStages.map((stageName) => {
    const count = funnelCounts[stageName];
    const conversionRate = (count / totalDeals) * 100;

    return {
      stageName,
      count,
      conversionRate: Math.round(conversionRate),
    };
  });

  return {
    currentPipeline,
    conversionRates,
  };
};

export default usePipelineOverviewData;
