// src/hooks/useFetchDealsHistory.js
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";
import {
  startOfQuarter,
  endOfQuarter,
  parseISO,
  isWithinInterval,
} from "date-fns";

const processMonthlyChanges = (history, year) => {
  // Filter changes for selected year and get unique months
  const months = [
    ...new Set(
      history.map((change) => {
        const date = parseISO(change.created_date);
        if (date.getFullYear() === year) {
          return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
            2,
            "0"
          )}`;
        }
        return null;
      })
    ),
  ]
    .filter(Boolean)
    .sort();

  // Initialize monthly data structure
  const monthlyData = months.reduce((acc, month) => {
    acc[month] = {
      created: 0,
      closeDatePulledIn: 0,
      closeDatePushedOut: 0,
      dealSizeIncreased: 0,
      dealSizeDecreased: 0,
      closedLost: 0,
      closedWon: 0,
    };
    return acc;
  }, {});

  // Process each change
  history.forEach((change) => {
    const date = parseISO(change.created_date);
    if (date.getFullYear() !== year) return;

    const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}`;
    const amount = Number(change.amount) || 0;
    const prevAmount = Number(change.prev_amount) || 0;
    const amountChange = amount - prevAmount;

    if (!change.prev_amount && amount > 0) {
      monthlyData[month].created += amount;
    } else if (change.close_date !== change.prev_close_date) {
      const newDate = new Date(change.close_date);
      const oldDate = change.prev_close_date
        ? new Date(change.prev_close_date)
        : new Date();
      if (newDate < oldDate) {
        monthlyData[month].closeDatePulledIn += amount;
      } else {
        monthlyData[month].closeDatePushedOut -= amount;
      }
    } else if (amountChange > 0) {
      monthlyData[month].dealSizeIncreased += amountChange;
    } else if (amountChange < 0) {
      monthlyData[month].dealSizeDecreased -= Math.abs(amountChange);
    }

    if (change.stage_name === "Closed Lost") {
      monthlyData[month].closedLost -= amount;
    } else if (change.stage_name === "Closed-Paid") {
      monthlyData[month].closedWon += amount;
    }
  });

  // Format data for chart
  return {
    months: months.map((m) => {
      const [, month] = m.split("-");
      const monthName = new Date(year, parseInt(month) - 1).toLocaleString(
        "default",
        { month: "short" }
      );
      return monthName;
    }),
    series: [
      {
        name: "Created",
        data: months.map((m) => monthlyData[m].created / 1000),
      },
      {
        name: "Close Date Pulled In",
        data: months.map((m) => monthlyData[m].closeDatePulledIn / 1000),
      },
      {
        name: "Close Date Pushed Out",
        data: months.map((m) => monthlyData[m].closeDatePushedOut / 1000),
      },
      {
        name: "Deal Size Increased",
        data: months.map((m) => monthlyData[m].dealSizeIncreased / 1000),
      },
      {
        name: "Deal Size Decreased",
        data: months.map((m) => monthlyData[m].dealSizeDecreased / 1000),
      },
      {
        name: "Closed Lost",
        data: months.map((m) => monthlyData[m].closedLost / 1000),
      },
      {
        name: "Closed Won",
        data: months.map((m) => monthlyData[m].closedWon / 1000),
      },
    ],
  };
};

const processPipelineData = (history, year) => {
  const today = new Date();
  const quarterStart = startOfQuarter(today);
  const quarterEnd = endOfQuarter(today);

  // Calculate initial pipeline (deals that were open at start of quarter)
  const initialPipeline = history.reduce((sum, deal) => {
    const dealDate = parseISO(deal.created_date);
    if (dealDate < quarterStart && !deal.is_closed) {
      return sum + (Number(deal.amount) || 0);
    }
    return sum;
  }, 0);

  const quarterChanges = history.filter((change) => {
    const changeDate = parseISO(change.created_date);
    return isWithinInterval(changeDate, {
      start: quarterStart,
      end: quarterEnd,
    });
  });

  let pipelineFlow = {
    openPipeline: initialPipeline,
    newPipeline: 0,
    closeDatePulledIn: 0,
    closeDatePushedOut: 0,
    dealSizeIncreased: 0,
    dealSizeDecreased: 0,
    closedLost: 0,
    closedWon: 0,
  };

  quarterChanges.forEach((change) => {
    const amount = Number(change.amount) || 0;
    const prevAmount = Number(change.prev_amount) || 0;
    const amountChange = amount - prevAmount;

    if (change.stage_name === "Closed-Paid") {
      pipelineFlow.closedWon += amount;
    } else if (change.stage_name === "Closed Lost") {
      pipelineFlow.closedLost += amount;
    } else if (!prevAmount && amount > 0) {
      pipelineFlow.newPipeline += amount;
    } else if (change.close_date !== change.prev_close_date) {
      const newDate = new Date(change.close_date);
      const oldDate = change.prev_close_date
        ? new Date(change.prev_close_date)
        : new Date();
      if (newDate < oldDate) {
        pipelineFlow.closeDatePulledIn += amount;
      } else {
        pipelineFlow.closeDatePushedOut += amount;
      }
    } else if (amountChange !== 0) {
      if (amountChange > 0) {
        pipelineFlow.dealSizeIncreased += amountChange;
      } else {
        pipelineFlow.dealSizeDecreased += Math.abs(amountChange);
      }
    }
  });

  const monthlyChanges = processMonthlyChanges(history, year);

  // Consistently scale all values by 1000 for K representation
  return {
    pipelineFlowData: [
      {
        label: "Open Pipeline\nStart of Quarter",
        value: pipelineFlow.openPipeline / 1000,
      },
      {
        label: "New Pipeline\nCreated",
        value: pipelineFlow.newPipeline / 1000,
      },
      {
        label: "Close Date\nPulled In",
        value: pipelineFlow.closeDatePulledIn / 1000,
      },
      {
        label: "Close Date\nPushed Out",
        value: pipelineFlow.closeDatePushedOut / 1000,
      },
      {
        label: "Deal Size\nIncreased",
        value: pipelineFlow.dealSizeIncreased / 1000,
      },
      {
        label: "Deal Size\nDecreased",
        value: -pipelineFlow.dealSizeDecreased / 1000,
      },
      {
        label: "Closed Lost",
        value: -pipelineFlow.closedLost / 1000,
      },
      {
        label: "Closed Won",
        value: pipelineFlow.closedWon / 1000,
      },
    ],
    monthlyChanges,
  };
};

export function useFetchDealsHistory(accountID, selectedYear) {
  const { getAccessToken } = useAuthData();

  const fetchDealHistory = async () => {
    if (!accountID || !selectedYear) {
      return {
        dealsHistory: [],
        pipelineAnalysis: {
          pipelineFlowData: [],
          monthlyChanges: { months: [], series: [] },
        },
      };
    }

    const accessToken = await getAccessToken();
    const startDate = `${selectedYear}-01-01`;
    const endDate = `${selectedYear}-12-31`;

    const target =
      process.env.REACT_APP_API_BASE_URL ||
      "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

    const response = await axios.get(
      `${target}/api/accounts/${accountID}/deals/history`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }
    );

    const history = response.data || [];
    const pipeline = processPipelineData(history, selectedYear);

    return {
      dealsHistory: history,
      pipelineAnalysis: pipeline,
    };
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["dealsHistory", accountID, selectedYear],
    queryFn: fetchDealHistory,
    enabled: !!accountID && !!selectedYear,
  });

  return {
    dealsHistory: data?.dealsHistory || [],
    pipelineAnalysis: data?.pipelineAnalysis || {
      pipelineFlowData: [],
      monthlyChanges: { months: [], series: [] },
    },
    isLoading,
    error,
  };
}
