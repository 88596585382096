import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Box,
  VStack,
  useToast,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Alert,
  AlertIcon,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Select,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ReactComponent as LystoLogo } from "assets/img/lysto-logo-round.svg";
import OverviewSection from "./OverviewSection";
import EmailDetailsSection from "./EmailDetailsSection";
import MessageInput from "./MessageInput";
import ReferenceActivitySection from "./ReferenceActivitySection";
import DealActivity from "../Deals/DealActivity";
import { FaPaperPlane, FaMagic, FaPencilAlt } from "react-icons/fa";
import { useQueryClient } from '@tanstack/react-query';

// Helper function to format date for display
const formatDate = (dateString) => {
  if (!dateString) return "Unknown date";
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

// Template options for email generation
const TEMPLATE_OPTIONS = [
  { value: "general", label: "General Message" },
  { value: "introduction", label: "Introduction" },
  { value: "follow_up", label: "Email Follow-up" },
  { value: "meeting_follow_up", label: "Meeting Follow-up" },
  { value: "pricing", label: "Pricing Information" },
  { value: "proposal", label: "Proposal" },
  { value: "meeting_request", label: "Meeting Request" },
  { value: "thank_you", label: "Thank You" },
];

const MotionBox = motion(Box);

const LoadingOverlay = ({ status }) => {
  if (status === "sending") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          animate={{
            scale: [1, 1.1, 1],
            opacity: [1, 0.7, 1]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            filter="drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium">Sending your email...</Text>
      </Center>
    );
  }

  if (status === "success") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", duration: 0.5 }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            filter="drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium">Email sent successfully!</Text>
        <Text fontSize="sm" mt={2}>Redirecting to deal details...</Text>
      </Center>
    );
  }

  if (status === "error") {
    return (
      <Center flexDirection="column" color="white">
        <MotionBox
          animate={{ 
            rotate: [-5, 5, -5, 0],
          }}
          transition={{ 
            duration: 0.5,
            ease: "easeInOut",
          }}
        >
          <Box 
            as={LystoLogo} 
            width="100px" 
            height="100px" 
            mb={4}
            opacity={0.7}
            filter="drop-shadow(0 0 8px rgba(255, 0, 0, 0.3))"
          />
        </MotionBox>
        <Text fontSize="lg" fontWeight="medium" color="red.200">Failed to send email</Text>
      </Center>
    );
  }

  return null;
};

const EmailComposeDetail = ({
  deal,
  onSendMessage,
  onGoBack,
  onGenerateEmail,
  selectedReferenceActivity,
  setSelectedReferenceActivity
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  // Track if we've received content from generation
  const generatedContentRef = useRef(false);
  // Track the current content for timeout handling
  const appliedContentRef = useRef('');

  // Initialize state with empty values
  const [message, setMessage] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [selectedSender, setSelectedSender] = useState(null);
  const [guidingPrompt, setGuidingPrompt] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("general");
  const [isSending, setIsSending] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [sendStatus, setSendStatus] = useState(""); // 'sending', 'success', or 'error'
  const [hasGeneratedEmail, setHasGeneratedEmail] = useState(false);
  const [isSendConfirmOpen, setIsSendConfirmOpen] = useState(false);
  const [recentActivities, setRecentActivities] = useState([]);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [willCreateNewThread, setWillCreateNewThread] = useState(true);
  const [hasMeetingActivity, setHasMeetingActivity] = useState(false); // Add state for meeting activity
  const [quillKey, setQuillKey] = useState(Date.now()); // Add key state

  // Prefetch activities data
  useEffect(() => {
    if (deal?.account_id && deal?.id) {
      queryClient.prefetchQuery({
        queryKey: ['dealActivities', deal.account_id, deal.id],
        queryFn: () => null,
        staleTime: 0
      });
    }
  }, [deal?.account_id, deal?.id, queryClient]);

  // Fetch recent email activities to extract subject and recipients
  useEffect(() => {
    const fetchRecentEmailActivities = async () => {
      if (!deal?.id || !deal?.account_id) return;
      
      setIsLoadingActivities(true);
      let foundMeetingActivity = false; // Flag to track if a meeting activity is found
      try {
        // In a real implementation, this would be an API call
        // For now, we'll check if there are activities in the deal
        if (deal.activities && Array.isArray(deal.activities)) {
          // First, check for any meeting activity
          foundMeetingActivity = deal.activities.some(
            activity => activity.type?.toLowerCase() === 'meeting' || activity.activity_type?.toLowerCase() === 'meeting'
          );

          // Filter for email activities - look for multiple indicators that this is an email
          const emailActivities = deal.activities
            .filter(activity => {
              // Check different properties that might indicate this is an email
              const isEmailType = activity.activity_type?.toLowerCase() === 'email' || 
                activity.type?.toLowerCase() === 'email';
              
              // Check if activity has email data
              const hasEmailData = activity.activity_data?.data?.subject || 
                activity.activity_data?.data?.body ||
                activity.activity_data?.data?.recipients;
              
              // Check if activity has email-related properties
              const hasEmailProps = activity.subject || 
                activity.recipients || 
                (activity.to && activity.to.includes('@'));
              
              // Look at description for email indicators
              const hasEmailDesc = activity.description && 
                (activity.description.includes('Email:') || 
                 activity.description.includes('Subject:') || 
                 activity.description.includes('To:'));
              
              return isEmailType || hasEmailData || hasEmailProps || hasEmailDesc;
            })
            .sort((a, b) => {
              // Sort by date descending
              const dateA = new Date(a.created_at || a.date || a.activity_date || 0);
              const dateB = new Date(b.created_at || b.date || b.activity_date || 0);
              return dateB - dateA;
            });
          
          // Add an activity index for display purposes
          const activities = emailActivities;
          activities.forEach((activity, index) => {
            activity.displayIndex = index + 1;
          });
          
          setRecentActivities(activities);
          
          // Set the most recent email activity as the default reference 
          // if activities exist, no reference is selected, and no email has been generated yet.
          if (activities.length > 0 && !selectedReferenceActivity && !hasGeneratedEmail) {
            setSelectedReferenceActivity(activities[0]);
            
            // Apply data from the selected activity
            applyDataFromActivity(activities[0]);
          }
        } else {
          // If no activities array is present, just set an empty array
          setRecentActivities([]);
        }
      } catch (error) {
        // Display error message to user
        toast({
          title: "Error Loading Activities",
          description: "There was a problem retrieving email history",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setRecentActivities([]);
      } finally {
        setIsLoadingActivities(false);
        setHasMeetingActivity(foundMeetingActivity); // Set the state based on the check
      }
    };
    
    fetchRecentEmailActivities();
  }, [deal, hasGeneratedEmail, toast]);

  // Effect to determine if a new thread should be created
  useEffect(() => {
    if (!selectedReferenceActivity) {
      setWillCreateNewThread(true); // No reference = new thread
    } else if (selectedReferenceActivity.activity_type?.toLowerCase() !== 'email') {
      setWillCreateNewThread(true); // Referencing non-email = new thread
    } else {
      // Referencing an email - default to replying in same thread
      // TODO: Add subject change check later if needed
      setWillCreateNewThread(false); 
    }
  }, [selectedReferenceActivity]); // Depend on the prop passed from parent

  // Function to apply data from a selected activity
  const applyDataFromActivity = (activity) => {
    if (!activity) return;
    
    // Extract subject from the activity (check multiple potential sources)
    let extractedSubject = null;
    
    // Check activity_data.data first (most structured)
    if (activity.activity_data?.data?.subject) {
      extractedSubject = activity.activity_data.data.subject;
    } 
    // Then check direct subject property
    else if (activity.subject) {
      extractedSubject = activity.subject;
    }
    // Try to extract from description if present
    else if (activity.description) {
      const subjectMatch = activity.description.match(/Subject:\s*([^\n]+)/i);
      if (subjectMatch && subjectMatch[1]) {
        extractedSubject = subjectMatch[1].trim();
      }
    }
    
    // Apply subject if found
    if (extractedSubject) {
      const reSubject = extractedSubject.startsWith('Re:') 
        ? extractedSubject 
        : `Re: ${extractedSubject}`;
      setEmailSubject(reSubject);
    }
    
    // Extract recipients from the activity (check multiple potential sources)
    let extractedRecipients = [];
    
    // Check activity_data.data.recipients first (most structured)
    if (activity.activity_data?.data?.recipients && Array.isArray(activity.activity_data.data.recipients)) {
      extractedRecipients = activity.activity_data.data.recipients.map(r => ({
        name: r.name || '',
        email: r.email,
        recipient_type: r.recipient_type || r.type || 'to',
      }));
    } 
    // Then check direct recipients property
    else if (activity.recipients && Array.isArray(activity.recipients)) {
      extractedRecipients = activity.recipients.map(r => ({
        name: r.name || '',
        email: r.email,
        recipient_type: r.recipient_type || r.type || 'to',
      }));
    }
    // Check for to/cc/bcc properties
    else if (activity.to && activity.to.includes('@')) {
      extractedRecipients.push({
        name: activity.to_name || '',
        email: activity.to,
        recipient_type: 'to',
      });
      
      // Add CC recipients if available
      if (activity.cc && activity.cc.includes('@')) {
        extractedRecipients.push({
          name: activity.cc_name || '',
          email: activity.cc,
          recipient_type: 'cc',
        });
      }
      
      // Add BCC recipients if available
      if (activity.bcc && activity.bcc.includes('@')) {
        extractedRecipients.push({
          name: activity.bcc_name || '',
          email: activity.bcc,
          recipient_type: 'bcc',
        });
      }
    }
    // Try to extract from description if present
    else if (activity.description) {
      const toMatch = activity.description.match(/To:\s*([^\n]+)/i);
      if (toMatch && toMatch[1] && toMatch[1].includes('@')) {
        // Simple email extraction - in real implementation would need more robust parsing
        const emails = toMatch[1].match(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g);
        if (emails) {
          emails.forEach(email => {
            extractedRecipients.push({
              name: '',
              email: email,
              recipient_type: 'to',
            });
          });
        }
      }
    }
    
    // Apply recipients
    setEmailRecipients(extractedRecipients);
  };

  // Handle navigation and overlay display based on send status
  useEffect(() => {
    let timer;
    if (sendStatus) {
      setShowLoadingOverlay(true);
      if (sendStatus === 'success') {
        // On success, wait a bit then navigate back
        timer = setTimeout(() => {
          onGoBack();
          // Reset status after navigation (or potential cancellation)
          // Note: onGoBack might unmount this component, so resetting status
          // might not be strictly necessary but is good practice if it doesn't.
          setSendStatus(""); 
        }, 1500);
      } else if (sendStatus === 'error') {
        // On error, show the error message for a few seconds then hide overlay
        timer = setTimeout(() => {
          setShowLoadingOverlay(false);
          setSendStatus(""); // Reset status after hiding overlay
        }, 3000); // Keep error message visible for 3 seconds
      }
    } else {
      // If sendStatus becomes empty (e.g., reset), hide the overlay immediately
      setShowLoadingOverlay(false);
    }
    
    // Cleanup function to clear the timer if the component unmounts
    // or if sendStatus changes before the timer finishes
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [sendStatus, onGoBack]); // Added setSendStatus to dependency array if it's used inside

  // Optionally prepopulate recipients if deal has associated contacts and no recent email activity
  useEffect(() => {
    if (deal?.contacts && deal.contacts.length > 0 && !hasGeneratedEmail && emailRecipients.length === 0 && !selectedReferenceActivity) {
      const contactRecipients = deal.contacts
        .filter(contact => contact.email)
        .map(contact => ({
          name: contact.name || contact.full_name,
          email: contact.email,
          recipient_type: "to"
        }));
      
      if (contactRecipients.length > 0) {
        setEmailRecipients(contactRecipients);
      }
    }
  }, [deal, hasGeneratedEmail, emailRecipients.length, selectedReferenceActivity]);

  const handleSend = () => {
    if (!selectedSender) {
      toast({
        title: "Sender Required",
        description: "Please select an email account to send from.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    if (!emailSubject.trim()) {
      toast({
        title: "Subject Required",
        description: "Please add a subject line to your email.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    if (emailRecipients.length === 0) {
      toast({
        title: "Recipients Required",
        description: "Please add at least one recipient to your email.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    // Open the send confirmation modal
    setIsSendConfirmOpen(true);
  };

  // Function to confirm and execute the SEND action
  const confirmSend = async () => {
    setIsSendConfirmOpen(false); // Close modal first
    setIsSending(true);          // Set sending state
    setSendStatus("sending");
    try {      
      // Call the onSendMessage prop with the necessary data
      await onSendMessage({
        content: message,
        subject: emailSubject,
        email_recipients: emailRecipients,
        send_as_email: selectedSender.send_as_email,
        send_as_user_id: selectedSender.send_as_user_id,
        deal_id: deal?.id,
        template_type: selectedTemplate, // Optional: pass template type if needed by send API
        reference_activity_id: selectedReferenceActivity?.activity_id,
        is_new_thread: willCreateNewThread,
      });
      // If successful:
      setSendStatus("success");
      toast({
        title: "Message Sent",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // If error:
      setSendStatus("error");
      toast({
        title: "Failed to Send",
        description: error.message || "An error occurred while sending.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      // Always reset sending state
      setIsSending(false);
    }
  };

  // Function to handle the GENERATE action
  const handleGenerateEmail = async () => {
    if (!deal?.id) {
      toast({ title: "Missing Deal Information", status: "error", duration: 3000, isClosable: true });
      return;
    }

    setIsGenerating(true);
    setHasGeneratedEmail(true); // Mark that generation has been attempted
    try {
      let activityContext = null;
      // Determine activity context based on template and selected activity
      if (selectedTemplate === 'meeting_follow_up' && selectedReferenceActivity?.activity_type?.toLowerCase() === 'meeting') {
        activityContext = selectedReferenceActivity; // Use the meeting itself
      } else if (selectedTemplate === 'follow_up' && selectedReferenceActivity?.activity_type?.toLowerCase() === 'email') {
        activityContext = selectedReferenceActivity; // Use the email itself for follow-up
      } // Add other conditions if needed

      // Call the onGenerateEmail prop
      const generatedData = await onGenerateEmail(selectedTemplate, guidingPrompt, activityContext);

      // Log raw content received
      // console.log("Raw generated content received by Detail:", JSON.stringify(generatedData.content)); // Removed

      // --- HTML Cleaning Logic ---
      let cleanedContent = generatedData.content || '';
      if (cleanedContent) {
        // 1. Trim whitespace
        cleanedContent = cleanedContent.trim();

        // 2. Replace intermediate paragraph tags with <br>
        // Ensures we match </p>, optional whitespace (including newline), then <p>
        cleanedContent = cleanedContent.replace(/<\/p>\s*<p>/gi, '<br>');

        // 3. Remove the initial <p> tag if it exists
        if (cleanedContent.toLowerCase().startsWith('<p>')) {
          cleanedContent = cleanedContent.slice(3);
        }

        // 4. Remove the final </p> tag if it exists
        if (cleanedContent.toLowerCase().endsWith('</p>')) {
          cleanedContent = cleanedContent.slice(0, -4);
        }

        // 5. Wrap the result in a single <p> tag
        cleanedContent = `<p>${cleanedContent}</p>`;

        // 6. Collapse multiple <br>s (optional cleanup)
        cleanedContent = cleanedContent.replace(/(<br\s*\/?>\s*){2,}/gi, '<br>');

        // 7. Final trim
        cleanedContent = cleanedContent.trim();
      }
      // --- End HTML Cleaning Logic ---

      // console.log("Cleaned content before setting state:", JSON.stringify(cleanedContent)); // Removed

      // Reset the key only if content actually changes significantly
      if (cleanedContent !== message) { // Compare with existing state
        setQuillKey(Date.now());
      }

      // Process the response if successful (using cleaned content)
      if (cleanedContent) {
        setMessage(cleanedContent);
        appliedContentRef.current = cleanedContent;
        generatedContentRef.current = true;

        // Update subject ONLY if we are NOT replying to a specific email thread
        const isReplyingToEmailThread = selectedReferenceActivity?.activity_type?.toLowerCase() === 'email';
        if (!isReplyingToEmailThread && generatedData.subject) {
          setEmailSubject(generatedData.subject);
        }

        if (generatedData.email_recipients?.length > 0) {
          const formattedRecipients = generatedData.email_recipients.map(r => ({ ...r, recipient_type: r.recipient_type || r.type || 'to' }));
          formattedRecipients.forEach(r => delete r.type);
          setEmailRecipients(formattedRecipients);
        }

        // Only show success toast if content was actually generated
        toast({ title: "Email Generated", status: "success", duration: 3000, isClosable: true });

      } else {
        // Handle case where generation resulted in empty content after cleaning
        // You might want to keep the old message or show a specific toast
         console.warn("Generated content was empty after cleaning.");
         toast({ title: "Generated Content Empty", description: "The generated email content was empty after processing.", status: "warning", duration: 4000, isClosable: true });
      }

    } catch (error) {
      console.error("Error during email generation/handling in Detail:", error);
      toast({
        title: "Generation Failed",
        description: error.message || "Could not generate email content.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsGenerating(false); // Ensure loading state is turned off
    }
  };

  const handleEmailSubjectChange = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleEmailRecipientsChange = (recipients) => {
    setEmailRecipients(recipients);
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleSelectActivity = (activity) => {
    setSelectedReferenceActivity(activity);
    applyDataFromActivity(activity);
    
    toast({
      title: "Activity Reference Changed",
      description: `Using context from ${activity.activity_type || 'activity'} from ${formatDate(activity.created_at || activity.date)}`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleClearActivity = () => {
    setSelectedReferenceActivity(null);
    
    // Explicitly set willCreateNewThread to true when clearing the reference
    setWillCreateNewThread(true);

    // Clear subject but keep any recipients that might have been added
    setEmailSubject("");
    
    toast({
      title: "Activity Reference Cleared",
      description: "No longer referencing a previous activity",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <>
      <Box
        bg="transparent"
        p={0}
        borderRadius="md"
        boxShadow="none"
        mb={4}
        height="100%"
      >
        <VStack spacing={6} align="stretch" height="100%">
          {/* Card 1: Overview and Email Composition */}
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <VStack spacing={6} align="stretch">
              {/* Overview Section */}
              <Box>
                <Text fontSize="md" fontWeight="semibold" mb={3}>
                  Overview
                </Text>
                <Box>
                  <OverviewSection
                    account={deal?.account_name || deal?.accounts?.[0]?.name}
                    taskType="Email Compose"
                    champion={deal?.champion || "N/A"}
                    lastEngagementDate={deal?.last_activity_date}
                    dealName={deal?.deal_name || deal?.name}
                    dealId={deal?.id}
                    showOwner={false}
                  />
                </Box>
              </Box>

              {/* Email Composition Section */}
              <Box>
                <VStack spacing={4} align="stretch"> 
                  <Text fontSize="md" fontWeight="semibold" mb={0}>
                    Compose Email
                  </Text>
                  
                  <ReferenceActivitySection
                    recentActivities={recentActivities}
                    selectedReferenceActivity={selectedReferenceActivity}
                    onSelectActivity={handleSelectActivity}
                    onClearActivity={handleClearActivity}
                    isLoadingActivities={isLoadingActivities}
                  />
                  
                  <Box mt={0}>
                    <EmailDetailsSection
                      emailSubject={emailSubject}
                      onSubjectChange={handleEmailSubjectChange}
                      recipients={emailRecipients}
                      onRecipientsChange={handleEmailRecipientsChange}
                      accountID={deal?.account_id}
                      selectedSender={selectedSender}
                      onSenderChange={setSelectedSender}
                    />
                  </Box>

                  <MessageInput
                    key={quillKey}
                    message={message}
                    setMessage={setMessage}
                    isSending={isSending}
                  />
                </VStack>
              </Box>

              {/* Template Selection and Action Row */}
              <VStack spacing={4} align="stretch">
                <HStack spacing={4} align="flex-end">
                  <FormControl flex="1">
                    <FormLabel fontSize="sm" fontWeight="medium">
                      Email Template
                    </FormLabel>
                    <Select
                      value={selectedTemplate}
                      onChange={handleTemplateChange}
                      size="md"
                      borderWidth="1px"
                      borderColor="gray.300"
                      borderRadius="lg"
                    >
                      {TEMPLATE_OPTIONS.map(option => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.value === "meeting_follow_up" && !hasMeetingActivity} // Disable meeting follow-up if no meeting activity
                        >
                          {option.label}{option.value === "meeting_follow_up" && !hasMeetingActivity ? " (No meetings found)" : ""}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl flex="2">
                    <FormLabel fontSize="sm" fontWeight="medium">
                      Additional Instructions
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="gray.500"
                      >
                        <FaPencilAlt />
                      </InputLeftElement>
                      <Input
                        type="text"
                        value={guidingPrompt}
                        onChange={(e) => setGuidingPrompt(e.target.value)}
                        placeholder="E.g., 'Make it formal', 'Include pricing', 'Reference last meeting'"
                        size="md"
                        borderWidth="1px"
                        borderColor="gray.300"
                        borderRadius="lg"
                      />
                    </InputGroup>
                  </FormControl>

                  {/* Generate Button */}
                  <Button
                    leftIcon={<FaMagic />}
                    colorScheme="purple"
                    onClick={handleGenerateEmail}
                    isLoading={isGenerating}
                    loadingText="Generating"
                    isDisabled={isSending || isGenerating || !deal?.id}
                    size="md"
                    title="Generate email content"
                    borderRadius="md"
                  >
                    Generate
                  </Button>

                  {/* Send Button */}
                  <Button
                    leftIcon={<FaPaperPlane />}
                    colorScheme="blue"
                    onClick={handleSend}
                    isLoading={isSending}
                    loadingText="Sending"
                    isDisabled={!message.trim() || isSending || isGenerating}
                    size="md"
                    title="Send this email"
                    borderRadius="md"
                  >
                    Send
                  </Button>
                </HStack>
              </VStack>

            </VStack>
          </Box>

          {/* Card 2: Related Activities */}
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <VStack spacing={6} align="stretch">
              <DealActivity 
                accountID={deal?.account_id} 
                dealID={deal?.id}
                title="Related Activities"
              />
            </VStack>
          </Box>
        </VStack>
      </Box>

      {/* Loading Overlay Modal */}
      <Modal 
        isOpen={showLoadingOverlay} 
        closeOnOverlayClick={false} 
        isCentered
      >
        <ModalOverlay backdropFilter="blur(4px)" bg="blackAlpha.700" />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalBody>
            <LoadingOverlay status={sendStatus} />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Send Confirmation Modal */}
      <Modal isOpen={isSendConfirmOpen} onClose={() => setIsSendConfirmOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Send</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to send this email?
            {willCreateNewThread && (
              <Alert status="info" mt={3} borderRadius="md">
                <AlertIcon />
                <Text>
                  {selectedReferenceActivity 
                    ? `This email will start a new thread because it references a non-email activity (${selectedReferenceActivity.activity_type}).`
                    : "This email will start a new conversation thread."
                  }
                </Text>
              </Alert>
            )}
            {!willCreateNewThread && selectedReferenceActivity && (
              <Alert status="success" mt={3} borderRadius="md">
                  <AlertIcon />
                  <Text>
                    This email will reply in the existing thread from {formatDate(selectedReferenceActivity.created_at || selectedReferenceActivity.date)}.
                  </Text>
              </Alert>
            )} 
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={() => setIsSendConfirmOpen(false)}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={confirmSend} isLoading={isSending}>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

EmailComposeDetail.propTypes = {
  deal: PropTypes.object.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onGenerateEmail: PropTypes.func.isRequired,
  selectedReferenceActivity: PropTypes.object,
  setSelectedReferenceActivity: PropTypes.func,
};

export default EmailComposeDetail; 