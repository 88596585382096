// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Meetings/index.jsx
// MeetingsView.jsx
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  Box,
  Text,
  SimpleGrid,
  Icon,
  HStack,
  useColorModeValue,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  FormControl,
  FormLabel,
  CheckboxGroup,
  Checkbox,
  VStack,
  Stack,
  Spinner,
  Portal
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthData } from 'auth-context';
import { Video, MapPin, CalendarDays, ChevronDown, ChevronUp, ExternalLink } from 'lucide-react';
import { useDebounce } from 'use-debounce';
import { FaExpandAlt } from 'react-icons/fa';
import { DateTime } from 'luxon';
import { useFilterState } from '../hooks/useFilterState';
import { FiFilter } from 'react-icons/fi';
import axios from 'axios';

import PageHeader from '../Shared/PageHeader';
import DataTable from '../Shared/DataTable';
import PaginationControls from '../Shared/PaginationControls';
import HoverBox from '../Shared/HoverBox';
import TableSkeleton from '../Shared/TableSkeleton';
import useFetchMeetings from '../hooks/useFetchMeetings';
import UpNextCard from './UpNextCard';

const ITEMS_PER_PAGE_DEFAULT = 10;
const ITEMS_PER_PAGE_WITH_UPNEXT = 9;

const MeetingsView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const { getAccessToken } = useAuthData();

  // State management
  const [currentPage, setCurrentPage] = useFilterState('meetings-current-page', 1);
  const [selectedSegment, setSelectedSegment] = useFilterState('meetings-segment-filter', 'schedule');
  const [searchQuery, setSearchQuery] = useFilterState('meetings-search-query', '');
  const [sortConfig, setSortConfig] = useState({
    key: 'startTime',
    direction: 'ascending'
  });
  const [dynamicFilters, setDynamicFilters] = useFilterState('meetings-dynamic-filters', {});

  // Add a ref to prevent pagination reset when changing pages
  const skipResetRef = useRef(false);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [isLoadingHistorical, setIsLoadingHistorical] = useState(false);
  const [hasLoadedHistorical, setHasLoadedHistorical] = useState(false);
  
  // State for attendee tooltips
  const [hoveredAttendee, setHoveredAttendee] = useState(null);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  
  // New state for the up next meeting card
  const [upNextMeetingIndex, setUpNextMeetingIndex] = useState(0);

  // After state declarations, add useLocation and useEffect to restore previous page
  useEffect(() => {
    if (location.state && location.state.previousPage) {
      setCurrentPage(location.state.previousPage);
    }
  }, []);

  // Add handler for segment changes
  const handleSegmentChange = useCallback((newSegment) => {
    setSelectedSegment(newSegment);
    // Reset historical data
    setHasLoadedHistorical(false);
    // Reset filters when switching segments
    setDynamicFilters({});
    // Reset page to 1
    setCurrentPage(1);
  }, [setSelectedSegment, setDynamicFilters, setCurrentPage]);

  // Debounce search query
  const [debouncedSearchQuery] = useDebounce(searchQuery, 800);

  // Fetch meetings using the custom hook
  const {
    allMeetings,
    loading: isFetching,
    error: fetchError
  } = useFetchMeetings(
    accountID,
    userID,
    selectedSegment,
    currentPage,
    debouncedSearchQuery.trim(),
    dynamicFilters
  );

  // Helper functions for filtering
  const getColumnType = (meetings, columnName) => {
    if (!meetings || meetings.length === 0) return null;
    
    // Special handling for attendees
    if (columnName === 'attendees') return 'option';
    if (columnName === 'duration') return 'number';
    if (columnName === 'startTime' || columnName === 'endTime') return 'date';
    
    const sampleValue = meetings.find(meeting => meeting[columnName] != null)?.[columnName];
    
    if (columnName.toLowerCase().includes('date')) return 'date';
    if (typeof sampleValue === 'number') return 'number';
    return 'option';
  };

  const getColumnValues = (meetings, columnName) => {
    // Special handling for attendees
    if (columnName === 'attendees') {
      const attendeeMap = new Map();
      meetings.forEach(meeting => {
        meeting.attendees?.forEach(att => {
          if (att.email) {
            attendeeMap.set(att.email, (attendeeMap.get(att.email) || 0) + 1);
          }
        });
      });

      const entries = Array.from(attendeeMap.entries());
      if (entries.length < 2) return [];

      return entries
        .sort((a, b) => b[1] - a[1])
        .map(([email, count]) => ({
          value: email,
          count,
          label: `${email} (${count})`
        }));
    }

    // Regular column handling
    const frequencyMap = meetings.reduce((acc, meeting) => {
      const value = meeting[columnName];
      if (value) {
        acc[value] = (acc[value] || 0) + 1;
      } else {
        acc["None"] = (acc["None"] || 0) + 1;
      }
      return acc;
    }, {});

    const entries = Object.entries(frequencyMap);
    const nonNoneEntries = entries.filter(([value]) => value !== "None");
    
    if (nonNoneEntries.length < 2 || !nonNoneEntries.some(([, count]) => count >= 3)) {
      return [];
    }

    return entries
      .filter(([value, count]) => value === "None" ? count >= 3 : true)
      .sort((a, b) => {
        const countDiff = b[1] - a[1];
        if (countDiff !== 0) return countDiff;
        if (a[0] === "None") return 1;
        if (b[0] === "None") return -1;
        return a[0].localeCompare(b[0]);
      })
      .map(([value, count]) => ({
        value,
        count,
        label: `${value} (${count})`
      }));
  };

  const getColumnRange = (meetings, columnName) => {
    const values = meetings.map(meeting => meeting[columnName]).filter(value => typeof value === 'number');
    if (values.length === 0) return null;

    const uniqueValues = new Set(values);
    if (uniqueValues.size < 3) return null;

    return {
      min: Math.min(...values),
      max: Math.max(...values)
    };
  };

  const titleCase = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // Function to capitalize first letter
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Update the handleViewDetails function to include previousPage in navigation state
  const handleViewDetails = useCallback((meeting) => {
    navigate(`/admin/meetings/${meeting.id}`, { state: { previousPage: currentPage } });
  }, [navigate, currentPage]);

  // Format location with Zoom support
  const renderLocation = useCallback((row) => {
    const isZoom = row.location?.toLowerCase().includes('zoom') || 
                  row.description?.toLowerCase().includes('zoom');
    const hasLink = row.location?.includes('http') || row.hangoutLink;

    // Custom HoverBox for location with teal color scheme
    const LocationHoverBox = ({ children, icon, hoverIcon, onClick, tooltipLabel }) => {
      const iconColor = useColorModeValue("teal.700", "teal.300");
      const hoverIconColor = useColorModeValue("teal.800", "teal.400");
      const iconBgColor = useColorModeValue("teal.50", "teal.700");
      const hoverIconBgColor = useColorModeValue("teal.100", "teal.800");
      const tooltipBg = useColorModeValue("gray.700", "white");
      const tooltipTextColor = useColorModeValue("white", "gray.700");
      
      const [isHovered, setIsHovered] = React.useState(false);
      const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 });
      
      const handleMouseEnter = () => setIsHovered(true);
      const handleMouseLeave = () => setIsHovered(false);
      const handleMouseMove = (e) => setMousePos({ x: e.clientX, y: e.clientY });
      
      return (
        <>
          <Box
            display="inline-flex"
            alignItems="center"
            borderRadius="lg"
            cursor="pointer"
            position="relative"
            transition="all 0.2s"
            role="group"
            width="100%"
            height="24px"
            _hover={{
              "& .default-icon": { opacity: 0 },
              "& .hover-icon": { opacity: 1 },
            }}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
          >
            {icon && (
              <Box
                className="default-icon"
                mr={2}
                position="absolute"
                transition="opacity 0.2s"
                opacity={1}
                flexShrink={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="md"
                bg={iconBgColor}
                width="24px"
                height="24px"
                left={0}
                top="50%"
                transform="translateY(-50%)"
                overflow="hidden"
                borderWidth="1px"
                borderColor="teal.200"
              >
                <Icon as={icon} color={iconColor} boxSize="16px" />
              </Box>
            )}
            {hoverIcon && (
              <Box
                className="hover-icon"
                mr={2}
                position="absolute"
                transition="opacity 0.2s"
                opacity={0}
                flexShrink={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="md"
                bg={hoverIconBgColor}
                width="24px"
                height="24px"
                left={0}
                top="50%"
                transform="translateY(-50%)"
                overflow="hidden"
                borderWidth="1px"
                borderColor="teal.300"
              >
                <Icon as={hoverIcon} color={hoverIconColor} boxSize="16px" />
              </Box>
            )}
            <Box
              as="span"
              display="flex"
              alignItems="center"
              textAlign="left"
              width="100%"
              pl={icon ? "32px" : "0"}
              pr="4px"
              height="100%"
            >
              {children}
            </Box>
          </Box>
          {isHovered && tooltipLabel && (
            <Portal>
              <Box
                position="fixed"
                left={mousePos.x + 10}
                top={mousePos.y + 10}
                bg={tooltipBg}
                color={tooltipTextColor}
                px="8px"
                py="4px"
                fontSize="sm"
                borderRadius="md"
                zIndex="tooltip"
                pointerEvents="none"
                boxShadow="sm"
              >
                {tooltipLabel}
              </Box>
            </Portal>
          )}
        </>
      );
    };

    if (hasLink || isZoom) {
      const meetingLink = row.location?.includes('http') ? 
        row.location : 
        (row.hangoutLink || row.location);
        
      return (
        <LocationHoverBox
          icon={Video}
          hoverIcon={ExternalLink}
          onClick={() => window.open(meetingLink, '_blank', 'noopener,noreferrer')}
          tooltipLabel="Join Meeting"
        >
          <Text>{isZoom ? 'Zoom Meeting' : 'Google Meet'}</Text>
        </LocationHoverBox>
      );
    }

    if (row.location) {
      // Create a Google Maps search URL for the physical address
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(row.location)}`;
      
      return (
        <LocationHoverBox
          icon={MapPin}
          hoverIcon={ExternalLink}
          onClick={() => window.open(googleMapsUrl, '_blank', 'noopener,noreferrer')}
          tooltipLabel={row.location} // Show full address in tooltip
        >
          <Text>Google Maps</Text>
        </LocationHoverBox>
      );
    }

    return <Text>No location</Text>;
  }, []);

  // Get row icon based on meeting type
  const getRowIcon = useCallback(() => {
    return CalendarDays;
  }, []);

  // Function to handle mouse events for attendee tooltips
  const handleAttendeeMouseEnter = (attendee, e) => {
    setHoveredAttendee(attendee);
    setMousePos({ x: e.clientX, y: e.clientY });
  };

  const handleAttendeeMouseLeave = () => {
    setHoveredAttendee(null);
  };

  const handleAttendeeMouseMove = (e) => {
    setMousePos({ x: e.clientX, y: e.clientY });
  };

  // Add a truncateText function similar to the one in Inbox
  const truncateText = (text, maxLength = 60) => {
    if (!text || text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  // Table columns configuration
  const columns = useMemo(() => {
    const baseColumns = [
      {
        key: 'title',
        label: 'Meeting',
        minWidth: '200px',
        maxWidth: '40%',
        sortable: true,
        isFirst: true,
        render: (row) => {
          const truncatedTitle = truncateText(row.title, 55);
          
          return (
            <Box 
              width="100%" 
              display="flex" 
              alignItems="center"
              position="relative"
            >
              <HoverBox
                icon={CalendarDays}
                hoverIcon={FaExpandAlt}
                onClick={() => handleViewDetails(row)}
                tooltipLabel={row.title} // Show full title in tooltip
                width="100%"
                tooltipProps={{
                  placement: "top",
                  hasArrow: true,
                  gutter: 8
                }}
              >
                <Box width="100%" display="flex" alignItems="center">
                  <Text isTruncated flex="1">
                    {truncatedTitle}
                  </Text>
                </Box>
              </HoverBox>
            </Box>
          );
        }
      },
      {
        key: 'location',
        label: 'Location',
        minWidth: '120px',
        maxWidth: '20%',
        render: renderLocation
      },
      {
        key: 'attendees',
        label: 'Attendees',
        minWidth: '150px',
        maxWidth: '25%',
        render: (row) => {
          // Sort attendees: accepted first, then others
          const sortedAttendees = [...(row.attendees || [])].sort((a, b) => {
            const getResponsePriority = (response) => {
              switch (response?.toLowerCase()) {
                case 'accepted': return 0;
                case 'tentative': return 1;
                case 'declined': return 2;
                default: return 3;
              }
            };
            return getResponsePriority(a.response) - getResponsePriority(b.response);
          });

          return (
            <HStack spacing={2} wrap="wrap">
              {sortedAttendees.map((att, index) => {
                const email = att.email;
                const response = att.response?.toLowerCase() || 'needsaction';
                const name = att.name || email?.split('@')[0];
                const displayName = capitalizeFirstLetter(name);
                
                // Check if this attendee is the organizer
                const isOrganizer = att.organizer || email === row.organizerEmail;
                
                const role = isOrganizer
                  ? 'Organizer'
                  : att.optional
                  ? 'Optional'
                  : 'Attendee';
                
                if (!email) return null;

                const formatResponse = (resp) => {
                  switch(resp) {
                    case 'needsaction': return 'Needs Action';
                    default: return capitalizeFirstLetter(resp);
                  }
                };
                
                // Create a composite attendee object for tooltip data
                const attendeeData = {
                  ...att,
                  email,
                  response_status: response,
                  role,
                  displayName,
                  tooltipText: `${role} • ${formatResponse(response)} • ${email}`
                };
                
                return (
                  <Box 
                    key={index}
                    onMouseEnter={(e) => handleAttendeeMouseEnter(attendeeData, e)}
                    onMouseLeave={handleAttendeeMouseLeave}
                    onMouseMove={handleAttendeeMouseMove}
                  >
                    <Box
                      width="24px"
                      height="24px"
                      borderRadius="md"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      fontSize="xs"
                      fontWeight="bold"
                      bg={
                        response === 'accepted'
                          ? 'blue.50'
                          : response === 'tentative'
                          ? 'purple.50'
                          : response === 'declined'
                          ? 'red.50'
                          : 'gray.50'
                      }
                      color={
                        response === 'accepted'
                          ? 'blue.700'
                          : response === 'tentative'
                          ? 'purple.700'
                          : response === 'declined'
                          ? 'red.700'
                          : 'gray.700'
                      }
                      borderWidth="1px"
                      borderColor={
                        response === 'accepted'
                          ? 'blue.200'
                          : response === 'tentative'
                          ? 'purple.200'
                          : response === 'declined'
                          ? 'red.200'
                          : 'gray.200'
                      }
                    >
                      {displayName.charAt(0).toUpperCase()}
                    </Box>
                  </Box>
                );
              })}
            </HStack>
          );
        }
      }
    ];

    // Add time/date column based on segment
    if (selectedSegment === 'schedule') {
      baseColumns.push({
        key: 'startTime',
        label: 'Meeting Time',
        minWidth: '180px',
        sortable: true,
        render: (row) => {
          const startTime = DateTime.fromISO(row.startTime);
          const endTime = DateTime.fromISO(row.endTime);
          const isToday = startTime.hasSame(DateTime.now(), 'day');
          const isTomorrow = startTime.hasSame(DateTime.now().plus({ days: 1 }), 'day');
          
          let dateDisplay;
          if (isToday) {
            dateDisplay = 'Today';
          } else if (isTomorrow) {
            dateDisplay = 'Tomorrow';
          } else {
            dateDisplay = startTime.toFormat('LLL d, yyyy');
          }
          
          // Single row display
          const singleRowDisplay = `${dateDisplay} • ${startTime.toFormat('h:mm a')} - ${endTime.toFormat('h:mm a')}`;
          
          return (
            <Box>
              {/* For larger screens (single row) */}
              <Text 
                display={{ base: 'none', md: 'block' }} 
                isTruncated
                fontSize="md"
              >
                {singleRowDisplay}
              </Text>
              
              {/* For smaller screens (two rows) */}
              <Box display={{ base: 'block', md: 'none' }}>
                <Text fontSize="md">{dateDisplay}</Text>
                <Text fontSize="sm" color="gray.600">
                  {startTime.toFormat('h:mm a')} - {endTime.toFormat('h:mm a')}
                </Text>
              </Box>
            </Box>
          );
        }
      });
    } else {
      baseColumns.push({
        key: 'startTime',
        label: 'Meeting Time',
        minWidth: '180px',
        sortable: true,
        render: (row) => {
          const startTime = DateTime.fromISO(row.startTime);
          const endTime = DateTime.fromISO(row.endTime);
          
          // Single row display for recordings
          const singleRowDisplay = `${startTime.toFormat('LLL d, yyyy')} • ${startTime.toFormat('h:mm a')} - ${endTime.toFormat('h:mm a')}`;
          
          return (
            <Box>
              {/* For larger screens (single row) */}
              <Text 
                display={{ base: 'none', md: 'block' }} 
                isTruncated
                fontSize="md"
              >
                {singleRowDisplay}
              </Text>
              
              {/* For smaller screens (two rows) */}
              <Box display={{ base: 'block', md: 'none' }}>
                <Text fontSize="md">{startTime.toFormat('LLL d, yyyy')}</Text>
                <Text fontSize="sm" color="gray.600">
                  {startTime.toFormat('h:mm a')} - {endTime.toFormat('h:mm a')}
                </Text>
              </Box>
            </Box>
          );
        }
      });
    }

    return baseColumns;
  }, [selectedSegment, renderLocation, handleViewDetails]);

  // Get upcoming meetings for the "Up Next" card
  const upcomingMeetings = useMemo(() => {
    if (!allMeetings || selectedSegment !== 'schedule') return [];
    
    const now = DateTime.now();
    const tenMinutesAgo = now.minus({ minutes: 10 });
    
    // Find meetings that are upcoming or started less than 10 minutes ago
    const upcoming = allMeetings.filter(meeting => {
      const meetingTime = DateTime.fromISO(meeting.startTime);
      // Include meetings that haven't started yet or started less than 10 minutes ago
      return meetingTime > tenMinutesAgo;
    });
    
    // Sort by start time (earliest first)
    upcoming.sort((a, b) => {
      return DateTime.fromISO(a.startTime) < DateTime.fromISO(b.startTime) ? -1 : 1;
    });
    
    // Group meetings by exact start time
    const groupedByStartTime = upcoming.reduce((groups, meeting) => {
      const startTime = meeting.startTime;
      if (!groups[startTime]) {
        groups[startTime] = [];
      }
      groups[startTime].push(meeting);
      return groups;
    }, {});
    
    // Get the earliest group (first upcoming meeting time)
    const earliestStartTime = Object.keys(groupedByStartTime).sort()[0];
    if (!earliestStartTime) return [];
    
    return groupedByStartTime[earliestStartTime];
  }, [allMeetings, selectedSegment]);

  // Filtered meetings that exclude the ones shown in the up next card
  const filteredMeetings = useMemo(() => {
    if (!allMeetings) return [];
    let filtered = allMeetings;
    
    // Filter by search query
    if (debouncedSearchQuery.trim()) {
      const searchLower = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter(meeting => 
        meeting.title?.toLowerCase().includes(searchLower) ||
        meeting.description?.toLowerCase().includes(searchLower) ||
        meeting.attendees?.some(att => 
          att.name?.toLowerCase().includes(searchLower) ||
          att.email?.toLowerCase().includes(searchLower)
        )
      );
    }

    // Apply dynamic filters
    if (Object.keys(dynamicFilters).length > 0) {
      Object.entries(dynamicFilters).forEach(([column, filter]) => {
        if (!filter) return;
        
        if (column === 'attendees') {
          if (filter.values && filter.values.length > 0) {
            filtered = filtered.filter(meeting => 
              meeting.attendees?.some(att => 
                filter.values.includes(att.email)
              )
            );
          }
          return;
        }

        if (column === 'meetingType') {
          if (filter.values && filter.values.length > 0) {
            filtered = filtered.filter(meeting => {
              const isZoom = meeting.location?.toLowerCase().includes('zoom');
              const isGoogleMeet = !!meeting.hangoutLink;
              const isInPerson = meeting.location && !isZoom && !isGoogleMeet;
              
              return filter.values.some(value => {
                switch(value) {
                  case 'zoom': return isZoom;
                  case 'meet': return isGoogleMeet;
                  case 'inPerson': return isInPerson;
                  default: return false;
                }
              });
            });
          }
          return;
        }

        const type = getColumnType(allMeetings, column);
        
        if (type === 'date' && filter.startDate && filter.endDate) {
          const start = DateTime.fromISO(filter.startDate).startOf('day');
          const end = DateTime.fromISO(filter.endDate).endOf('day');
          filtered = filtered.filter(meeting => {
            const value = meeting[column];
            if (!value) return false;
            const meetingDate = DateTime.fromISO(value);
            return meetingDate >= start && meetingDate <= end;
          });
        }
        
        if (type === 'number' && (filter.min !== undefined || filter.max !== undefined)) {
          filtered = filtered.filter(meeting => {
            const value = meeting[column];
            if (!value) return false;
            if (filter.min !== undefined && value < filter.min) return false;
            if (filter.max !== undefined && value > filter.max) return false;
            return true;
          });
        }
        
        if (type === 'option' && filter.values && filter.values.length > 0) {
          filtered = filtered.filter(meeting => {
            const value = meeting[column];
            return filter.values.includes(value || "None");
          });
        }
      });
    }

    return filtered;
  }, [allMeetings, debouncedSearchQuery, dynamicFilters, selectedSegment, getColumnType]);

  // Compute total pages based on filteredMeetings
  const computedTotalPages = useMemo(() => {
    // Dynamically determine items per page based on UpNextCard visibility
    const itemsPerPage = selectedSegment === 'schedule' && upcomingMeetings.length > 0 
      ? ITEMS_PER_PAGE_WITH_UPNEXT 
      : ITEMS_PER_PAGE_DEFAULT;
      
    return Math.ceil(filteredMeetings.length / itemsPerPage) || 1;
  }, [filteredMeetings, selectedSegment, upcomingMeetings.length]);

  // Get current page's meetings from filteredMeetings
  const currentMeetings = useMemo(() => {
    // Dynamically determine items per page based on UpNextCard visibility
    const itemsPerPage = selectedSegment === 'schedule' && upcomingMeetings.length > 0 
      ? ITEMS_PER_PAGE_WITH_UPNEXT 
      : ITEMS_PER_PAGE_DEFAULT;
      
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredMeetings.slice(startIndex, endIndex);
  }, [currentPage, filteredMeetings, selectedSegment, upcomingMeetings.length]);

  // Update pagination handlers
  const handlePreviousPage = () => {
    skipResetRef.current = true; // Skip page reset
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    skipResetRef.current = true; // Skip page reset
    setCurrentPage(prev => Math.min(prev + 1, computedTotalPages));
  };

  const handlePageSelect = (pageNum) => {
    skipResetRef.current = true; // Skip page reset
    setCurrentPage(pageNum);
  };

  // Reset up next meeting index when upcoming meetings change
  useEffect(() => {
    setUpNextMeetingIndex(0);
  }, [upcomingMeetings]);

  // Effect to update sort direction when segment changes
  useEffect(() => {
    setSortConfig(() => ({
      key: 'startTime',
      direction: selectedSegment === 'schedule' ? 'ascending' : 'descending'
    }));
  }, [selectedSegment]);

  // Generate search suggestions based on allMeetings
  const searchSuggestions = useMemo(() => {
    const filteredItems = debouncedSearchQuery.trim() !== ""
      ? allMeetings.filter(item => 
          item.title && item.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
        )
      : allMeetings;

    const uniqueSubjectsMap = new Map();
    filteredItems.forEach((item) => {
      if (item.title && !uniqueSubjectsMap.has(item.title)) {
        uniqueSubjectsMap.set(item.title, item.id);
      }
    });

    return Array.from(uniqueSubjectsMap.entries()).map(([title, id]) => ({
      id,
      label: title,
    }));
  }, [allMeetings, debouncedSearchQuery]);

  // Add filterableColumns computation
  const filterableColumns = useMemo(() => {
    if (!allMeetings || allMeetings.length === 0) return { date: [], number: [], option: [] };
    
    const columns = { date: [], number: [], option: [] };
    const blacklist = [
      "id",
      "description",
      "hangoutLink",
      "hangoutlink",
      "recordingUrl",
      "timezone",
      "status",
      "title",
      "summary"
    ];
    
    // Add attendees as a special case
    columns.option.push({
      name: 'attendees',
      label: 'Attendees',
      values: getColumnValues(allMeetings, 'attendees')
    });

    // Add meeting type filter
    const meetingTypes = {
      zoom: allMeetings.filter(m => m.location?.toLowerCase().includes('zoom')).length,
      meet: allMeetings.filter(m => m.hangoutLink).length,
      inPerson: allMeetings.filter(m => m.location && !m.location.toLowerCase().includes('zoom') && !m.hangoutLink).length
    };

    if (Object.values(meetingTypes).some(count => count > 0)) {
      columns.option.push({
        name: 'meetingType',
        label: 'Meeting Type',
        values: [
          { value: 'zoom', label: `Zoom Meeting (${meetingTypes.zoom})`, count: meetingTypes.zoom },
          { value: 'meet', label: `Google Meet (${meetingTypes.meet})`, count: meetingTypes.meet },
          { value: 'inPerson', label: `In Person (${meetingTypes.inPerson})`, count: meetingTypes.inPerson }
        ].filter(type => type.count > 0)
      });
    }
    
    // Process other columns
    const sampleMeeting = allMeetings[0];
    Object.keys(sampleMeeting).forEach(column => {
      if (blacklist.includes(column.toLowerCase())) return;
      
      const type = getColumnType(allMeetings, column);
      
      if (type === 'date') {
        // Skip date columns as we're removing the date filter
        return;
      } else if (type === 'number') {
        const range = getColumnRange(allMeetings, column);
        if (range) {
          columns.number.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ").replace(/([A-Z])/g, ' $1').trim()),
            range
          });
        }
      } else if (type === 'option' && column !== 'attendees' && column !== 'meetingType') {
        const values = getColumnValues(allMeetings, column);
        if (values.length > 0) {
          columns.option.push({
            name: column,
            label: titleCase(column.replace(/_/g, " ").replace(/([A-Z])/g, ' $1').trim()),
            values
          });
        }
      }
    });
    
    return columns;
  }, [allMeetings]);

  // Add state for expanded sections and loading
  const [expandedSections, setExpandedSections] = useState({});
  const [loadingSections, setLoadingSections] = useState({});

  // Add toggle section handler
  const toggleSection = (sectionName) => {
    setExpandedSections(prev => {
      const newExpanded = { ...prev, [sectionName]: !prev[sectionName] };
      if (newExpanded[sectionName]) {
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: true }));
        setTimeout(() => {
          setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
        }, 300);
      } else {
        setLoadingSections(prevLoad => ({ ...prevLoad, [sectionName]: false }));
      }
      return newExpanded;
    });
  };

  // Add filter reset handler
  const handleResetFilters = () => {
    setDynamicFilters({});
  };

  // Add hasActiveFilters computation
  const hasActiveFilters = useMemo(() => {
    return Object.values(dynamicFilters).some(filter => {
      if (!filter) return false;
      if (filter.startDate || filter.endDate) return true;
      if (filter.min !== undefined || filter.max !== undefined) return true;
      if (filter.values && filter.values.length > 0) return true;
      return false;
    });
  }, [dynamicFilters]);

  // Function to fetch historical data
  const fetchHistoricalData = useCallback(async () => {
    if (!accountID || !userID || hasLoadedHistorical) return;

    try {
      setIsLoadingHistorical(true);
      const accessToken = await getAccessToken();
      const baseUrl =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
          : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Fetch a larger page size for filter data
      await axios.get(
        `${baseUrl}/api/accounts/${accountID}/users/${userID}/calendar/events`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: {
            page_number: 1,
            items_per_page: 100, // Fetch more items for filter data
            start_time: new Date().toISOString(),
            status: "confirmed",
            time_range: selectedSegment === "recordings" ? "past" : "future",
            include_recorded: selectedSegment === "recordings",
            recordings_only: selectedSegment === "recordings",
          }
        }
      );

      setHasLoadedHistorical(true);
    } catch (err) {
      console.error("Error fetching historical data:", err);
    } finally {
      setIsLoadingHistorical(false);
    }
  }, [accountID, userID, hasLoadedHistorical, getAccessToken, selectedSegment]);

  // Add effect to fetch historical data when filter opens
  useEffect(() => {
    if (isFilterOpen && !hasLoadedHistorical && !isLoadingHistorical) {
      fetchHistoricalData();
    }
  }, [isFilterOpen, hasLoadedHistorical, isLoadingHistorical, fetchHistoricalData]);

  // Define a new handler for search change that resets currentPage
  const handleSearchChange = (newQuery) => {
    setSearchQuery(newQuery);
    setCurrentPage(1);
  };

  return (
    <Box 
      px={4}
      mb={20}
    >
      <PageHeader
        title="Meetings"
        segmentOptions={[
          { label: "Upcoming", value: "schedule" },
          { label: "Recordings", value: "recordings" }
        ]}
        selectedSegment={selectedSegment}
        onSegmentChange={handleSegmentChange}
        searchPlaceholder="Search meetings..."
        onSearchChange={handleSearchChange}
        searchQuery={searchQuery}
        searchSuggestions={searchSuggestions}
        onSuggestionSelect={handleViewDetails}
        showSearch={true}
        additionalControls={
          <Popover 
            placement="bottom-end" 
            isOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          >
            <PopoverTrigger>
              <Button
                leftIcon={<Icon as={FiFilter} />}
                rightIcon={<Icon as={isFilterOpen ? ChevronUp : ChevronDown} />}
                variant="outline"
                bg="white"
                borderRadius="md"
                borderColor="gray.300"
                color={useColorModeValue("gray.700", "gray.200")}
                _hover={{
                  bg: "gray.50",
                }}
                onClick={() => setIsFilterOpen(true)}
              >
                Filters {hasActiveFilters && `(${Object.values(dynamicFilters).filter(Boolean).length})`}
              </Button>
            </PopoverTrigger>
            <PopoverContent 
              width="400px" 
              maxH="calc(100vh - var(--chakra-space-48))"
              position="relative"
              boxShadow="lg"
              mt={2}
              display="flex"
              flexDirection="column"
            >
              <PopoverHeader 
                fontWeight="semibold" 
                position="sticky"
                top={0}
                bg="white"
                zIndex={1}
                borderBottomWidth="1px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text>Advanced Filters</Text>
                {(isFetching || isLoadingHistorical) && <Spinner size="sm" ml={2} />}
                <Text fontSize="sm" color="gray.500">
                  {filteredMeetings.length} meetings
                </Text>
              </PopoverHeader>
              <PopoverBody 
                flex="1" 
                overflowY="auto"
                py={4}
              >
                <VStack spacing={4} align="stretch">
                  {/* Option Filters */}
                  {filterableColumns.option && filterableColumns.option.length > 0 && (
                    filterableColumns.option.map((column) => (
                      <FormControl key={column.name}>
                        <Button
                          variant="unstyled"
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() => toggleSection(column.name)}
                          mb={2}
                          _hover={{ bg: "gray.50" }}
                          p={2}
                          borderRadius="md"
                        >
                          <FormLabel fontWeight="medium" mb={0}>
                            {column.label}
                            {dynamicFilters[column.name]?.values?.length > 0 && (
                              <Text as="span" ml={2} color="gray.500" fontSize="sm">
                                ({dynamicFilters[column.name].values.length} filter{dynamicFilters[column.name].values.length === 1 ? '' : 's'})
                              </Text>
                            )}
                          </FormLabel>
                          <Icon
                            as={expandedSections[column.name] ? ChevronUp : ChevronDown}
                            boxSize={5}
                          />
                        </Button>
                        {expandedSections[column.name] && (
                          loadingSections[column.name] ? (
                            <Box p={4} display="flex" justifyContent="center" alignItems="center">
                              <Spinner />
                            </Box>
                          ) : (
                            <Box 
                              maxH="150px"
                              overflowY="auto"
                              borderWidth="1px"
                              borderColor="gray.200"
                              borderRadius="md"
                              bg="gray.50"
                              _hover={{ borderColor: "gray.300" }}
                              sx={{
                                '&::-webkit-scrollbar': {
                                  width: '8px',
                                  borderRadius: '8px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                  borderRadius: '8px',
                                },
                              }}
                            >
                              <CheckboxGroup 
                                value={dynamicFilters[column.name]?.values?.map(String) || []}
                                onChange={(values) => {
                                  if (values.length === 0) {
                                    const newFilters = { ...dynamicFilters };
                                    delete newFilters[column.name];
                                    setDynamicFilters(newFilters);
                                  } else {
                                    setDynamicFilters(prev => ({
                                      ...prev,
                                      [column.name]: { values: values.map(String) }
                                    }));
                                  }
                                }}
                              >
                                <Stack spacing={2} p={3}>
                                  {column.values.map((item) => (
                                    <Checkbox key={item.value} value={item.value}>
                                      {item.label}
                                    </Checkbox>
                                  ))}
                                </Stack>
                              </CheckboxGroup>
                            </Box>
                          )
                        )}
                      </FormControl>
                    ))
                  )}
                </VStack>
              </PopoverBody>
              <PopoverFooter
                position="sticky"
                bottom={0}
                bg="white"
                zIndex={1}
                borderTopWidth="1px"
                py={3}
              >
                <HStack justify="flex-end" spacing={2}>
                  <Button size="sm" onClick={handleResetFilters}>Reset</Button>
                  <Button size="sm" colorScheme="blue" onClick={() => setIsFilterOpen(false)}>Apply</Button>
                </HStack>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        }
      />

      {/* Up Next Meeting Card - only show in schedule segment */}
      {selectedSegment === 'schedule' && upcomingMeetings.length > 0 && (
        <UpNextCard
          meetings={upcomingMeetings}
          onViewDetails={handleViewDetails}
          currentIndex={upNextMeetingIndex}
          setCurrentIndex={setUpNextMeetingIndex}
        />
      )}

      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box 
          borderWidth="1px" 
          borderRadius="lg" 
          overflow="hidden" 
          p={4} 
          bg={useColorModeValue("white", "gray.800")}
        >
          {isFetching && (!allMeetings || allMeetings.length === 0) ? (
            <TableSkeleton columns={columns} />
          ) : fetchError ? (
            <Text color="red.500" textAlign="center" py={4}>Error: {fetchError?.message || String(fetchError)}</Text>
          ) : filteredMeetings.length === 0 ? (
            <Text textAlign="center" py={8} color="gray.500">
              No meetings available.
            </Text>
          ) : (
            <>
              <DataTable
                data={currentMeetings}
                columns={columns}
                getRowIcon={getRowIcon}
                sortConfig={sortConfig}
                onSort={(key) => {
                  setSortConfig(prevConfig => ({
                    key,
                    direction: prevConfig.key === key && prevConfig.direction === 'ascending' 
                      ? 'descending' 
                      : 'ascending'
                  }));
                }}
                loading={isFetching}
              />
              {filteredMeetings.length > 0 && (
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={computedTotalPages}
                  onPrevious={handlePreviousPage}
                  onNext={handleNextPage}
                  disableNext={currentPage === computedTotalPages}
                  disablePrevious={currentPage === 1}
                  onPageSelect={handlePageSelect}
                />
              )}
            </>
          )}
        </Box>
      </SimpleGrid>

      {/* Portal for tooltip */}
      {hoveredAttendee && (
        <Portal>
          <Box
            position="fixed"
            left={mousePos.x + 10}
            top={mousePos.y + 10}
            bg={useColorModeValue("gray.700", "white")}
            color={useColorModeValue("white", "gray.700")}
            px="8px"
            py="4px"
            fontSize="sm"
            borderRadius="md"
            zIndex="tooltip"
            pointerEvents="none"
            boxShadow="sm"
          >
            {hoveredAttendee.tooltipText}
          </Box>
        </Portal>
      )}
    </Box>
  );
};

export default MeetingsView;