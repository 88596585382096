import React from "react";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

const fetchDealsHistory = async (
  accountId,
  startDate,
  endDate,
  accessToken
) => {
  const target =
    process.env.REACT_APP_API_BASE_URL ||
    "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

  const response = await axios.get(
    `${target}/api/accounts/${accountId}/deals/history`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

  return response.data;
};

const fetchDeals = async (accountId, accessToken) => {
  const target =
    process.env.REACT_APP_API_BASE_URL ||
    "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

  const response = await axios.get(
    `${target}/api/accounts/${accountId}/deals`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
};

const mergeDealData = (dealHistory, deals) => {
  if (!Array.isArray(dealHistory) || !Array.isArray(deals)) {
    return [];
  }

  // Create a map of deal_id to No_of_Locations__c
  const dealLocationsMap = deals.reduce((acc, deal) => {
    acc[deal.id] = deal.extra_fields?.No_of_Locations__c;
    return acc;
  }, {});

  // Merge the location data into deal history
  const mergedData = dealHistory.map((historyItem) => {
    const locations = dealLocationsMap[historyItem.deal_id];
    return {
      ...historyItem,
      no_of_locations: locations !== undefined ? locations : null,
    };
  });

  return mergedData;
};

const filterBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  const filteredDeals = deals.filter((deal) => {
    const locations = deal.no_of_locations;
    if (locations === null || locations === undefined) {
      return false;
    }

    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });

  return filteredDeals;
};

export const usePipelineAnalysisData = ({
  accountId,
  selectedYear,
  selectedPeriod = "year",
  selectedQuarter = null,
  selectedSegment = "All",
}) => {
  const { getAccessToken } = useAuthData();

  // Calculate start and end dates based on selected period
  let startDate, endDate;

  if (selectedPeriod === "quarter" && selectedQuarter) {
    const quarterStartMonth = (selectedQuarter - 1) * 3;
    startDate = format(
      new Date(selectedYear, quarterStartMonth, 1),
      "yyyy-MM-dd"
    );
    endDate = format(
      new Date(selectedYear, quarterStartMonth + 3, 0),
      "yyyy-MM-dd"
    );
  } else {
    startDate = `${selectedYear}-01-01`;
    endDate = `${selectedYear}-12-31`;
  }

  // Fetch deals history
  const dealHistoryQuery = useQuery({
    queryKey: ["dealHistory", accountId, startDate, endDate],
    queryFn: async () => {
      if (!accountId) return null;
      const accessToken = await getAccessToken();
      return fetchDealsHistory(accountId, startDate, endDate, accessToken);
    },
    enabled: !!accountId && !!selectedYear,
  });

  // Fetch all deals
  const dealsQuery = useQuery({
    queryKey: ["deals", accountId],
    queryFn: async () => {
      if (!accountId) return null;
      const accessToken = await getAccessToken();
      return fetchDeals(accountId, accessToken);
    },
    enabled: !!accountId,
  });

  // Process and merge data when both queries are successful
  const processedData = React.useMemo(() => {
    if (dealHistoryQuery.isSuccess && dealsQuery.isSuccess) {
      const mergedData = mergeDealData(dealHistoryQuery.data, dealsQuery.data);
      const filteredData = filterBySegment(mergedData, selectedSegment);

      return filteredData;
    }
    return null;
  }, [dealHistoryQuery.data, dealsQuery.data, selectedSegment]);

  const result = {
    data: processedData,
    isLoading: dealHistoryQuery.isLoading || dealsQuery.isLoading,
    isError: dealHistoryQuery.isError || dealsQuery.isError,
    error: dealHistoryQuery.error || dealsQuery.error,
  };

  return result;
};
