import React from "react";
import {
  Box,
  VStack,
  Text,
  Heading,
  useColorModeValue,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ActivityPropType } from "./types";
import { formatDate, formatTime } from "./utils";

const SlackDigestDisplay = ({ activity }) => {
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const headingColor = useColorModeValue("gray.700", "gray.200");
  const contentBgColor = useColorModeValue("white", "gray.800");
  const dateColor = useColorModeValue("gray.500", "gray.400");
  
  // Adjust the description to display properly
  const description = activity.description || "";
  
  // Format date and time
  const formattedDate = formatDate(activity.completed_date_time);
  const formattedTime = formatTime(activity.completed_date_time);

  return (
    <Box 
      p={4} 
      bg={bgColor} 
      borderRadius="md" 
      borderWidth="1px"
      borderColor={borderColor}
      width="100%"
    >
      <VStack align="stretch" spacing={4}>
        {/* Header Section */}
        <VStack align="stretch" spacing={2}>
          <HStack align="center" spacing={2}>
            <Text>
              <Text as="span" fontWeight="semibold" color={headingColor}>
                Slack Conversation Digest
              </Text>
            </Text>
            <Spacer />
            <Text fontSize="sm" color={dateColor}>
              {formattedDate} at {formattedTime}
            </Text>
          </HStack>

         </VStack>
      

        {/* Content Section with improved styling */}
        <Box
          className="slack-digest-content"
          p={4}
          borderRadius="md"
          borderWidth="1px"
          borderColor={borderColor}
          bg={contentBgColor}
        >
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={{
              // Make all headings bold without numbering
              h1: ({...props}) => (
                <Heading as="h3" size="md" my={3} fontWeight="bold" {...props} />
              ),
              h2: ({...props}) => (
                <Heading as="h4" size="sm" my={2} fontWeight="bold" {...props} />
              ),
              h3: ({...props}) => (
                <Heading as="h5" size="xs" my={2} fontWeight="bold" {...props} />
              ),
              // Properly style paragraphs
              p: ({ ...props }) => (
                <Text mb={3} {...props} />
              ),
              // Style unordered lists with proper indentation
              ul: ({ ...props }) => {
                // Ensure we're not passing boolean props to DOM elements
                return <Box as="ul" pl={6} mb={3} {...props} />;
              },
              // Style ordered lists 
              ol: ({ ...props }) => {
                // Ensure we're not passing boolean props to DOM elements
                return <Box as="ol" pl={6} mb={3} {...props} />;
              },
              // Style list items with proper spacing
              li: ({ ...props }) => {
                // Remove the ordered prop entirely to avoid React warnings
                const safeProps = {...props};
                return <Box as="li" mb={2} {...safeProps} />;
              },
            }}
          >
            {/* Replace heading numbers with plain headings */}
            {description
              .replace(/### \d+\. /g, "### ")
              .replace(/## \d+\. /g, "## ")
              .replace(/# \d+\. /g, "# ")}
          </ReactMarkdown>
        </Box>
      </VStack>
    </Box>
  );
};

SlackDigestDisplay.propTypes = {
  activity: ActivityPropType,
};

export default SlackDigestDisplay; 