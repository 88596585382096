import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Hook to fetch available contact fields for export
 * @param {string} accountId - The ID of the account
 * @returns {Object} - The query result with contact fields for export
 */
const useFetchContactExportFields = (accountId) => {
  const { getAccessToken } = useAuthData();

  // Function to export contacts
  const exportContacts = async (selectedFields, dealIds, fileFormat) => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const payload = {
        selected_fields: selectedFields,
        deal_ids: dealIds,
        file_format: fileFormat,
      };

      const response = await axios.post(
        `${target}/api/accounts/${accountId}/contacts/export`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error exporting contacts:", error);
      throw error;
    }
  };

  return {
    ...useQuery({
      queryKey: ["contactExportFields", accountId],
      queryFn: async () => {
        const accessToken = await getAccessToken();
        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
        const response = await axios.get(
          `${target}/api/accounts/${accountId}/contacts/export-fields`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Return the fields organized by category
        return response.data;
      },
      enabled: !!accountId,
    }),
    exportContacts,
  };
};

export default useFetchContactExportFields;
