// auth-context.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
  } = useAuth0();
  const [userData, setUserData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const getUserAndOrgMetadata = async () => {
      if (isAuthenticated) {
        try {
          const [claims, token] = await Promise.all([
            getIdTokenClaims(),
            getAccessTokenSilently(),
          ]);

          const { org_id, account_ID, flow_ID } = claims;
          setUserData({
            ...user,
            org_id,
            account_ID,
            flow_ID,
          });
          setAccessToken(token);
        } catch (error) {
          // Check if the error is a missing refresh token error
          if (
            error.message &&
            (error.message.includes("Missing Refresh Token") ||
              error.message.includes("login_required"))
          ) {
            // Clear any stale auth state
            localStorage.removeItem("auth0.is.authenticated");
            // Perform a complete logout to clear all auth state instead of a simple redirect
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }
        }
      }
    };

    getUserAndOrgMetadata();
  }, [isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims, logout]);

  const getAccessToken = async () => {
    if (accessToken) return accessToken;

    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      return token;
    } catch (error) {
      // Check if the error is a missing refresh token error
      if (
        error.message &&
        (error.message.includes("Missing Refresh Token") ||
          error.message.includes("login_required"))
      ) {
        // Clear any stale auth state
        localStorage.removeItem("auth0.is.authenticated");
        // Perform a complete logout to clear all auth state instead of a simple redirect
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }
      throw error;
    }
  };

  const contextValue = {
    ...userData,
    getAccessToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthData = () => useContext(AuthContext);
