import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to fetch and enhance pipelines with stages and active deals count
 * @param {string} accountId - The account ID
 * @returns {Object} Enhanced pipeline data and loading state
 */
export const useEnhancedPipelines = (accountId) => {
  const { getAccessToken } = useAuthData();

  // Use a default account ID if none is provided
  const effectiveAccountId = accountId || 1; // Default to account ID 1 if none provided

  // Fetch pipelines
  const {
    data: pipelines,
    isLoading: isPipelinesLoading,
    error: pipelinesError,
  } = useQuery({
    queryKey: ["pipelines", effectiveAccountId],
    queryFn: async () => {
      if (!effectiveAccountId) {
        return [];
      }

      try {
        const accessToken = await getAccessToken();
        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${target}/api/accounts/${effectiveAccountId}/pipelines`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        return response.data.pipelines || [];
      } catch (error) {
        console.error("Error fetching pipelines:", error);
        throw error;
      }
    },
    enabled: !!effectiveAccountId,
  });

  // Fetch stages for all pipelines
  const {
    data: allStages,
    isLoading: isStagesLoading,
    error: stagesError,
  } = useQuery({
    queryKey: ["allPipelineStages", effectiveAccountId, pipelines],
    queryFn: async () => {
      if (!effectiveAccountId || !pipelines || pipelines.length === 0) {
        return {};
      }

      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Create an object to store stages for each pipeline
      const stagesByPipeline = {};

      // Fetch stages for each pipeline in parallel
      await Promise.all(
        pipelines.map(async (pipeline) => {
          try {
            const response = await axios.get(
              `${target}/api/accounts/${effectiveAccountId}/pipelines/${pipeline.id}/stages`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            const data = response.data;
            stagesByPipeline[pipeline.id] = Array.isArray(data)
              ? data
              : data.stages || [];
          } catch (error) {
            console.error(
              `Error fetching stages for pipeline ${pipeline.id}:`,
              error
            );
            stagesByPipeline[pipeline.id] = [];
          }
        })
      );

      return stagesByPipeline;
    },
    enabled: !!effectiveAccountId && !!pipelines && pipelines.length > 0,
  });

  // Fetch active deals count for all pipelines
  const {
    data: allDealCounts,
    isLoading: isDealCountsLoading,
    error: dealCountsError,
  } = useQuery({
    queryKey: ["allPipelineDealCounts", effectiveAccountId, pipelines],
    queryFn: async () => {
      if (!effectiveAccountId || !pipelines || pipelines.length === 0) {
        return {};
      }

      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Create an object to store deal counts for each pipeline
      const dealCountsByPipeline = {};

      // Fetch deal counts for each pipeline in parallel
      await Promise.all(
        pipelines.map(async (pipeline) => {
          try {
            const response = await axios.get(
              `${target}/api/accounts/${effectiveAccountId}/pipelines/${pipeline.id}/deal-count`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            dealCountsByPipeline[pipeline.id] = response.data.count || 0;
          } catch (error) {
            console.error(
              `Error fetching deal count for pipeline ${pipeline.id}:`,
              error
            );
            dealCountsByPipeline[pipeline.id] = 0;
          }
        })
      );

      return dealCountsByPipeline;
    },
    enabled: !!effectiveAccountId && !!pipelines && pipelines.length > 0,
  });

  /**
   * Function to get the deal count for a specific stage in a pipeline
   * @param {string|number} pipelineId - The pipeline ID
   * @param {string|number} stageId - The stage ID
   * @returns {Promise<number>} The count of deals in the stage
   */
  const getStageDealCount = async (pipelineId, stageId) => {
    if (!effectiveAccountId || !pipelineId || !stageId) {
      return 0;
    }

    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.get(
        `${target}/api/accounts/${effectiveAccountId}/pipelines/${pipelineId}/deal-count`,
        {
          params: { stage_id: stageId },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data.count || 0;
    } catch (error) {
      console.error(
        `Error fetching deal count for stage ${stageId} in pipeline ${pipelineId}:`,
        error
      );
      return 0;
    }
  };

  // Combine pipeline data with stages and deals
  const enhancedPipelines = useMemo(() => {
    if (!pipelines) {
      return [];
    }

    return pipelines.map((pipeline) => {
      const stages = allStages?.[pipeline.id] || [];
      const dealCount = allDealCounts?.[pipeline.id] || 0;

      return {
        ...pipeline,
        stages,
        active_deals: dealCount,
        status: pipeline.is_active ? "active" : "archived",
        description: `Pipeline with ${stages.length} stages and ${dealCount} active deals`,
      };
    });
  }, [pipelines, allStages, allDealCounts]);

  return {
    pipelines: enhancedPipelines,
    isLoading: isPipelinesLoading || isStagesLoading || isDealCountsLoading,
    error: pipelinesError || stagesError || dealCountsError,
    rawPipelines: pipelines,
    stagesByPipeline: allStages,
    dealCounts: allDealCounts,
    getStageDealCount, // Expose the new function
  };
};
