// src/constants/inboxMappings.js

export const STATUS_MAP = {
  "to do": { label: "To Do", color: "yellow" },
  completed: { label: "Completed", color: "green" },
  // Add more statuses as needed
};

export const TASK_MAP = {
  "Reply": { label: "Reply", color: "blue" },
  "Followup": { label: "Followup", color: "green" },
  // Add more tasks as needed
};
