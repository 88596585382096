import React from "react";
import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";
import { CHART_COLORS } from "../Dashboard/constants";
import { usePipelines } from "./usePipelines";

export const useDealCountByStageAndSource = (
  accountID,
  selectedYear,
  selectedQuarter,
  selectedSegment,
  toast
) => {
  const [selectedLeadSource, setSelectedLeadSource] = useState("All");
  const [selectedPipelineId, setSelectedPipelineId] = useState("");
  const { getAccessToken } = useAuthData();

  // Use the pipelines hook
  const { pipelines, isPipelinesLoading, pipelinesError } = usePipelines(
    accountID,
    selectedPipelineId
  );

  // Set default pipeline if none is selected and pipelines are available
  React.useEffect(() => {
    if (!selectedPipelineId && pipelines && pipelines.length > 0) {
      setSelectedPipelineId(pipelines[0].id);
    }
  }, [pipelines, selectedPipelineId, setSelectedPipelineId]);

  const fetchData = async () => {
    if (!accountID || !selectedPipelineId) return null;

    const accessToken = await getAccessToken();
    const target =
      process.env.REACT_APP_API_URL ||
      "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
    const url = `${target}/api/accounts/${accountID}/deal/deal-count-by-stage-and-source`;

    const params = {
      days: 7,
      pipeline_id: selectedPipelineId,
      period: selectedQuarter ? "quarter" : "year",
      year: selectedYear,
      quarter: selectedQuarter,
      segment: selectedSegment !== "All" ? selectedSegment : undefined,
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await axios.get(url, { headers, params });
    return response.data;
  };

  const {
    data: rawData,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      "dealCountByStageAndSource",
      accountID,
      selectedYear,
      selectedQuarter,
      selectedSegment,
      selectedPipelineId,
    ],
    queryFn: fetchData,
    enabled: !!accountID && !!selectedPipelineId,
  });

  const leadSourceOptions = useMemo(() => {
    if (!rawData?.leadSources) return ["All"];

    const leadSources = rawData.leadSources;
    const leadSourceLabels = Object.keys(leadSources).filter((source) => {
      const total = leadSources[source].reduce(
        (acc, stage) => acc + (stage.team.count || 0),
        0
      );
      return total > 0;
    });

    return ["All", ...leadSourceLabels];
  }, [rawData]);

  const processedData = useMemo(() => {
    if (!rawData?.leadSources) return null;

    try {
      const { leadSources } = rawData;

      // Extract unique stages with their display_order
      const stagesMap = new Map();
      Object.values(leadSources).forEach((sourceArray) => {
        sourceArray.forEach((stageObj) => {
          if (!stagesMap.has(stageObj.stage)) {
            stagesMap.set(stageObj.stage, stageObj.display_order);
          }
        });
      });

      // Sort stages by display_order
      const sortedStages = Array.from(stagesMap.entries())
        .sort((a, b) => a[1] - b[1])
        .map((entry) => entry[0]);

      const activeStages = sortedStages;

      // Compute counts per stage
      const countsPerStage = activeStages.map((stage) => {
        let totalForStage = 0;
        if (selectedLeadSource === "All") {
          // Sum counts over all lead sources
          Object.values(leadSources).forEach((sourceArray) => {
            const stageObj = sourceArray.find((s) => s.stage === stage);
            if (stageObj) {
              totalForStage += stageObj.team.count || 0;
            }
          });
        } else {
          // Use data only for selected lead source
          const sourceArray = leadSources[selectedLeadSource];
          if (sourceArray) {
            const stageObj = sourceArray.find((s) => s.stage === stage);
            if (stageObj) {
              totalForStage = stageObj.team.count || 0;
            }
          }
        }
        return totalForStage;
      });

      // Filter out stages with total 0, except "Connecting"
      const filteredStages = [];
      const filteredCounts = [];

      activeStages.forEach((stage, index) => {
        const total = countsPerStage[index];
        if (total > 0 || stage.toLowerCase() === "connecting") {
          filteredStages.push(stage.charAt(0).toUpperCase() + stage.slice(1));
          filteredCounts.push(total);
        }
      });

      // Check if there is any data to display
      const hasData = filteredStages.length > 0;

      if (!hasData) {
        toast({
          title: "No Data Available",
          description: "There are no deals with non-zero counts.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        return null;
      }

      // Prepare bar data
      return {
        title: "Active Deal Pipeline",
        labels: filteredStages,
        datasets: [
          {
            label: "Total Deals",
            data: filteredCounts,
            backgroundColor: CHART_COLORS.slice(0, filteredStages.length),
          },
        ],
      };
    } catch (err) {
      console.error("Error processing deal count data:", err);
      toast({
        title: "Error",
        description: "Failed to process deal count data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  }, [rawData, selectedLeadSource, toast]);

  return {
    data: processedData,
    loading: isLoading || isPipelinesLoading,
    error: error || pipelinesError,
    leadSourceOptions,
    selectedLeadSource,
    setSelectedLeadSource,
    selectedPipelineId,
    setSelectedPipelineId,
    pipelines,
  };
};
