import { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";
import { DateTime } from "luxon";
import { GetUserTimezone } from "../Meetings/utils";

const useFetchMeetings = (
  accountID,
  userID,
  selectedSegment = "schedule",
  currentPage = 1,
  searchQuery = "",
  dynamicFilters = {}
) => {
  const { getAccessToken } = useAuthData();
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  // Transform meetings data
  const transformMeetingsData = useCallback((events) => {
    if (!events) return []; // Add null check for events
    const userTimezone = GetUserTimezone();

    const transformed = events.map((event) => {
      const eventTimezone = event.startTimezone || "UTC";
      const startTime = DateTime.fromISO(event.startTime, {
        zone: eventTimezone,
      });
      const endTime = DateTime.fromISO(event.endTime, { zone: eventTimezone });

      const userStartTime = startTime.setZone(userTimezone);
      const userEndTime = endTime.setZone(userTimezone);

      const attendees =
        event.attendees?.map((attendee) => {
          const parts = attendee.split(", ");

          const email =
            parts
              .find((p) => p.startsWith("Email: "))
              ?.replace("Email: ", "") || "";
          const name =
            parts.find((p) => p.startsWith("Name: "))?.replace("Name: ", "") ||
            "";
          const response =
            parts
              .find((p) => p.startsWith("Response: "))
              ?.replace("Response: ", "") || "needsAction";

          // Check if this attendee is the organizer
          const isOrganizer = email === event.organizerEmail;

          const attendeeObj = {
            name: name || email.split("@")[0],
            email,
            response: response.toLowerCase(),
            organizer: isOrganizer, // Set organizer flag
          };

          return attendeeObj;
        }) || [];

      return {
        id: event.id,
        title: event.summary || "Untitled Meeting",
        description: event.description || "",
        startTime: userStartTime.toISO(),
        endTime: userEndTime.toISO(),
        location: event.location || "",
        hangoutLink: event.hangoutLink || "",
        attendees: attendees,
        status: event.status || "confirmed",
        timezone: eventTimezone,
        recorded: event.isRecorded || false,
        recordingUrl: event.recordingUrl || null,
        organizerEmail: event.organizerEmail || "", // Store the organizer email
      };
    });

    return transformed;
  }, []);

  const fetchMeetingsPage = useCallback(async () => {
    if (!accountID || !userID) {
      return null;
    }

    try {
      const accessToken = await getAccessToken();
      const baseUrl =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "https://lysto-staging-eyf9ejcwf8h3awbw.eastus2-01.azurewebsites.net"
          : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      // Map new segment values to API parameters
      const timeRange = selectedSegment === "schedule" ? "future" : "past";
      const includeRecorded = selectedSegment === "recordings";

      const params = {
        page_number: 1, // Always fetch first page with more items
        start_time: new Date().toISOString(),
        items_per_page: 100, // Fetch more items to handle client-side filtering
        status: "confirmed",
        time_range: timeRange,
        include_recorded: includeRecorded,
        recordings_only: includeRecorded,
      };

      // Only add search parameter if there's a non-empty search query
      const trimmedQuery = String(searchQuery || "").trim();
      if (trimmedQuery !== "") {
        params.search = trimmedQuery;
      }

      // Add dynamic filters to params
      if (Object.keys(dynamicFilters).length > 0) {
        const formattedFilters = {};

        const mapFilterValue = (filter) => {
          if (!filter.values) {
            console.warn("Filter has no values array:", filter);
            return [];
          }
          return filter.values.map((v) => String(v));
        };

        Object.entries(dynamicFilters).forEach(([column, filter]) => {
          if (!filter) return;

          if (column === "meetingType") {
            return;
          } else if (column === "startTime") {
            if (filter.startDate || filter.endDate) {
              // Check if we have valid dates before formatting
              const hasValidStartDate =
                filter.startDate &&
                filter.startDate.match(/^\d{4}-\d{2}-\d{2}$/);
              const hasValidEndDate =
                filter.endDate && filter.endDate.match(/^\d{4}-\d{2}-\d{2}$/);

              // Only proceed if at least one valid date is present
              if (hasValidStartDate || hasValidEndDate) {
                // Format the start date/time if available
                let startDateTime = undefined;
                if (hasValidStartDate) {
                  startDateTime = filter.startTime
                    ? `${filter.startDate}T${filter.startTime}:00`
                    : `${filter.startDate}T00:00:00`;
                }

                // Format the end date/time if available
                let endDateTime = undefined;
                if (hasValidEndDate) {
                  endDateTime = filter.endTime
                    ? `${filter.endDate}T${filter.endTime}:00`
                    : `${filter.endDate}T23:59:59`;
                }

                // Add the date range filter only if we have at least one valid date
                formattedFilters.start_time_range = {
                  start: startDateTime,
                  end: endDateTime,
                };

                // Also add as date_range for backwards compatibility
                formattedFilters.date_range = {
                  start_date: startDateTime,
                  end_date: endDateTime,
                };
              }
            }
          } else if (column === "recorded") {
            formattedFilters.recorded = mapFilterValue(filter);
          } else {
            if (filter.values) {
              formattedFilters[column] = mapFilterValue(filter);
            }
          }
        });

        params.dynamic_filters = JSON.stringify(formattedFilters);
      }

      const response = await axios.get(
        `${baseUrl}/api/accounts/${accountID}/users/${userID}/calendar/events`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params,
        }
      );

      const data = response.data;
      const transformedMeetings = transformMeetingsData(data?.items || []);

      // Apply client-side filtering for meetingType
      let filteredMeetings = transformedMeetings;
      if (dynamicFilters.meetingType?.values?.length > 0) {
        filteredMeetings = filteredMeetings.filter((meeting) => {
          const isZoom = meeting.location?.toLowerCase().includes("zoom");
          const isGoogleMeet = !!meeting.hangoutLink;
          const isInPerson = meeting.location && !isZoom && !isGoogleMeet;

          return dynamicFilters.meetingType.values.some((value) => {
            switch (value) {
              case "zoom":
                return isZoom;
              case "meet":
                return isGoogleMeet;
              case "inPerson":
                return isInPerson;
              default:
                return false;
            }
          });
        });
      }

      setMeetings(filteredMeetings);
      setTotalCount(filteredMeetings.length);
      setHasMore(currentPage * 10 < filteredMeetings.length);
      setError(null);
      return data;
    } catch (err) {
      console.error("Error fetching meetings:", err);
      setError("Failed to load meetings");
      setMeetings([]);
      setHasMore(false);
      setTotalCount(0);
      throw err;
    }
  }, [
    accountID,
    userID,
    getAccessToken,
    selectedSegment,
    searchQuery,
    transformMeetingsData,
    dynamicFilters,
  ]);

  useEffect(() => {
    const loadMeetings = async () => {
      if (!accountID || !userID) {
        setLoading(false);
        setMeetings([]);
        return;
      }

      try {
        setLoading(true);
        await fetchMeetingsPage();
      } finally {
        setLoading(false);
      }
    };

    loadMeetings();
  }, [accountID, userID, selectedSegment, fetchMeetingsPage]);

  // Get paginated meetings
  const paginatedMeetings = useMemo(() => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    return meetings.slice(startIndex, endIndex);
  }, [meetings, currentPage]);

  return {
    meetings: paginatedMeetings,
    allMeetings: meetings,
    loading,
    error,
    hasMore,
    totalCount,
    refetch: fetchMeetingsPage,
  };
};

export default useFetchMeetings;
