import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

export function useFetchContactActivities(
  accountId,
  contactId,
  startDate,
  endDate
) {
  const { getAccessToken } = useAuthData();

  return useQuery({
    queryKey: ["contactActivities", accountId, contactId, startDate, endDate],
    queryFn: async () => {
      if (!accountId || !contactId) {
        return [];
      }

      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      const response = await axios.get(
        `${target}/api/accounts/${accountId}/contacts/${contactId}/activities`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }
      );

      // Process both types of activities
      const processActivitiesArray = (activitiesArray) => {
        if (!activitiesArray) return [];
        
        return activitiesArray
          .filter((activity) => {
            // Filter out email activities with empty bodies
            if (activity.activity_type?.toLowerCase() === "email") {
              const emailBody = activity.activity_data?.data?.body;
              return emailBody && emailBody.trim() !== "";
            }
            // Keep all other types of activities
            return true;
          })
          .map((activity) => {
            // Normalize activity type
            const normalizedType = activity.activity_type
              ? activity.activity_type.charAt(0).toUpperCase() +
                activity.activity_type.slice(1).toLowerCase()
              : "Unknown";

            return {
              ...activity,
              activity_type: normalizedType,
              owner_id: activity.owner_id || activity.user_id,
              created_by_id: activity.created_by_id || activity.user_id,
              is_internal: activity.is_internal || false,
              activity_data: activity.activity_data || null,
            };
          });
      };

      // Get activities from both arrays and combine them
      const crmAccountActivities = processActivitiesArray(response.data.crm_account_activities);
      const associatedActivities = processActivitiesArray(response.data.associated_activities);
      
      // Combine both arrays
      const combinedActivities = [...crmAccountActivities, ...associatedActivities];

      return combinedActivities;
    },
    enabled: Boolean(accountId) && Boolean(contactId),
  });
}
